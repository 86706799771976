import React, { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Grid, Text } from "@chakra-ui/react";
import UserContext from "../../../contexts/UserContext";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import { useHistory } from "react-router-dom";
import { DataStore } from "aws-amplify/datastore";
import { Bill, SubscriptionPlans, User } from "../../../models";
import moment from "moment/moment";

export default function CheckoutForm({ id }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user, updateUser } = useContext(UserContext);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [chargeData, setChargeData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setStatus(paymentIntent.status);
      setChargeData(paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Gracias por su Pago!");
          break;
        case "processing":
          setMessage("El Pago esta en proceso.");
          break;
        case "requires_payment_method":
          setMessage("Pago Incompleto, por favor, intenta de nuevo.");
          break;
        default:
          setMessage("Opps, Algo ha salido mal.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/#/admin/profile/payment/${id}`,
        receipt_email: user.email,
        payment_method_data: {
          billing_details: {
            email: user.email,
            name: user.name + " " + user.lastname,
          },
        },
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("Error Inesperado.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    if (status && message && chargeData) {
      onConfirmHandler();
    }
  }, [status, message, chargeData]);

  const onConfirmHandler = async () => {
    const bill = await DataStore.query(Bill, (c) =>
      c.tokenId.eq(chargeData.id)
    );
    const sub = await DataStore.query(SubscriptionPlans, id);
    if (bill.length === 0) {
      await DataStore.save(
        new Bill({
          amount: (chargeData.amount / 100).toString(),
          status: chargeData.status,
          userID: user.id,
          email: user.email,
          subscriptionId: id,
          plan: sub.plan,
          chargeData,
          durationPlan: sub.duration,
          paymentType: "STRIPE",
          tokenId: chargeData.id,
        })
      ).then(async (res) => {
        switch (res.status) {
          case "succeeded":
            const currentDate = moment();
            let newExpirationDate;
            if (
              !user.expirationDate ||
              currentDate.isAfter(moment(user.expirationDate))
            ) {
              newExpirationDate = currentDate
                .add(sub.duration, "months")
                .format("YYYY-MM-DD");
            } else {
              newExpirationDate = moment(user.expirationDate)
                .add(sub.duration, "months")
                .format("YYYY-MM-DD");
            }
            await DataStore.query(User, user.id).then(async (res) => {
              await DataStore.save(
                User.copyOf(res, (updated) => {
                  updated.expirationDate = newExpirationDate;
                  updated.isSubscribed = true;
                  updated.subscrptionType = sub.plan;
                })
              ).then((res) => {
                updateUser(res);
                window.history.replaceState(
                  {},
                  document.title,
                  window.location.pathname
                );
                setTimeout(() => {
                  history.push("/admin/profile/my-profile");
                }, 5000);
              });
            });
            // todo actualizar usuario y redireccionar a la pantalla de perfil
            break;
          case "processing":
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            );
            setTimeout(() => {
              history.push("/admin/profile/my-profile");
            }, 5000);
            setMessage("El Pago esta en proceso.");
            break;
          case "requires_payment_method":
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            );
            setTimeout(() => {
              history.push("/admin/profile/my-profile");
            }, 5000);
            setMessage("Pago Incompleto, por favor, intenta de nuevo.");
            break;
          default:
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            );
            setTimeout(() => {
              history.push("/admin/profile/my-profile");
            }, 5000);
            setMessage("Opps, Algo ha salido mal.");
            break;
        }
      });
    } else {
      window.history.replaceState({}, document.title, window.location.pathname);

      setTimeout(() => {
        history.push("/admin/profile/my-profile");
        // Aquí puedes colocar el código que quieres que se ejecute después del retraso
      }, 5000);
      setMessage(
        "Este pago ya ha sido aplicado, si tiene algun inconveniente, favor de comunicarse con soporte!!"
      );
    }
  };

  if (status || message || chargeData) {
    return (
      <Grid justifyItems={"center"}>
        <Text>{message}</Text>
      </Grid>
    );
  } else {
    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <Grid>
          <button
            style={{
              color: "white",
              marginBottom: 5,
              marginTop: 15,
              borderWidth: 1,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 15,
              backgroundColor: "blue",
            }}
            disabled={isLoading || !stripe || !elements}
            id="submit"
          >
            <span id="button-text">
              {isLoading ? "Procesando Pago" : "Pagar"}
            </span>
          </button>
        </Grid>

        {/* Show any error or success messages */}
        {message && (
          <Grid justifyItems={"center"}>
            <Card>
              <CardHeader>{message}</CardHeader>
            </Card>
            <div
              style={{
                marginTop: 15,
                color: message === "Gracias por su Pago!" ? "green" : "red",
                justifyContent: "center",
                borderWidth: 1,
                paddingTop: 10,
                paddingRight: 20,
                paddingLeft: 20,
                paddingBottom: 10,
                borderRadius: 15,
                borderColor:
                  message === "Gracias por su Pago!" ? "green" : "red",
              }}
              id="payment-message"
            >
              {message}
            </div>
          </Grid>
        )}
      </form>
    );
  }
}
