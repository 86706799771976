import { Text, View } from "@react-pdf/renderer";
import usePdfStyles from "../../hooks/usePdfstyles";

const PdfEntries = ({ title, children, column2, column1, column3 = false }) => {
  const styles = usePdfStyles();

  const parseData = (data, type) => {
    function roundAwayFromZero(num) {
      console.log(num);
      return num < 0 ? Math.floor(num) : Math.ceil(num);
    }
    switch (type) {
      case "separated":
        // Redondeamos el número para eliminar los decimales
        const numeroRedondeado = Math.round(data);

        // Usamos toLocaleString para formatear el número con comas
        return numeroRedondeado.toLocaleString("en-US");
      case "text":
        return data;
      case "time":
        return parseFloat(data).toFixed(2);
      case "temp":
        return parseFloat(data).toFixed(2);
      case "weight":
        return parseFloat(data).toFixed(3);
      case "int":
        return parseInt(data);
      case "zero":
        return roundAwayFromZero(parseFloat(data));

      case "":
        return parseFloat(data).toFixed(3);
    }
  };
  return (
    <>
      <Text style={styles.header}>{title}</Text>
      <View style={styles.view}>
        <View>
          {column1?.map((data, index) => {
            if (data.label) {
              return (
                <Text
                  style={styles.text}
                  key={`${title}-${index}-${data.label}`}
                >
                  {data.label}:{" "}
                  <Text>
                    {parseData(data.value, data.type)} {data.addOn}
                  </Text>
                </Text>
              );
            } else {
              return (
                <Text
                  style={styles.text}
                  key={`${title}-${index}-${data.label}`}
                >
                  {" "}
                </Text>
              );
            }
          })}
        </View>
        <View>
          {column2?.map((data, index) => {
            if (data.label) {
              return (
                <Text
                  style={styles.text}
                  key={`${title}-${index}-${data.label}`}
                >
                  {data.label}:{" "}
                  <Text>
                    {parseData(data.value, data.type)} {data.addOn}
                  </Text>
                </Text>
              );
            } else {
              return <Text
                style={styles.text}
                key={`${title}-${index}-${data.label}`}
              >
                {" "}
              </Text>;
            }
          })}
        </View>
        <View>
          {column3 &&
            column3?.map((data, index) => {
              return (
                <Text
                  style={styles.text}
                  key={`${title}-${index}-${data.label}`}
                >
                  {data.label}:{" "}
                  <Text>
                    {parseData(data.value, data.type)} {data.addOn}
                  </Text>
                </Text>
              );
            })}
        </View>
      </View>
    </>
  );
};

export default PdfEntries;
