import {
  inMm,
  inSecMmsec,
  inSquareMmSquare,
  ppsiToPbar,
} from "../../../hooks/useUomTransform";

export const dataResults = (data) => [
  {
    label: "Área de Tornillo",
    value: data?.screwArea,
    type: "weight",
    addOn: inSquareMmSquare(data?.header?.uom),
  },
  {
    label: "Área de Pistón",
    value: data?.pistonArea,
    addOn: inSquareMmSquare(data?.header?.uom),
    type: "weight",
  },
  {
    label: "Tamaño Total de Disparo",
    value: data?.overallShotSize,
    addOn: inMm(data?.header?.uom),
    type: "weight",
  },
  {
    label: "Razón de Intensificación",
    value: data?.intRatio,
    addOn: ":1",
    type: "temp",
  },
  {
    label: "Máxima Presión Plástica",
    value: data?.maxPlasticPress,
    addOn: ppsiToPbar(data?.header?.uom),
    type: "pressure",
  },
  {
    label: "Intervalos de Velocidad",
    value: data?.speedIntervalUnit,
    addOn: inSecMmsec(data?.header?.uom),
    type: "weight",
  },
  {
    label: "MAX Presión Requerida",
    value: data?.maxPressReq,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
  },
];
