import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es'; // Asegúrate de importar el locale español

const useFormattedDate = () => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    moment.locale('es'); // Establece el locale a español
    const currentDate = moment().format('D [de] MMMM [de] YYYY'); // Formatea la fecha actual
    setFormattedDate(currentDate); // Guarda la fecha formateada en el estado
  }, []);

  return formattedDate; // Retorna la fecha formateada
};

export default useFormattedDate;
