import { useState } from "react";

export const useVCheckRepeatabilityHooks = () => {
  const [showChart, setShowChart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return {
    showChart,
    setShowChart,
    isLoading,
    setIsLoading
  };
};
