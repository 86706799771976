import React from "react";

import CustomGrid from "./CustomGrid";
import DataDisplayBadge from "../../../../components/DataDisplay/DatadisplayBadge";
import { badgeColor } from "../../project-overview/components/utilities/variables";
import { Grid } from "@chakra-ui/react";

interface DataItem {
  label: string;
  value: number | string; // Asumiendo que value pueda ser número o string
  addOn: string;
  type: string; // Puedes restringir esto a valores específicos si es necesario, como "pressure" | "time"
}

interface ShowResultsProps {
  data: DataItem[];
}

const textColor = "gray.600";
const ShowResults = ({ data, repeat = 4 }) => {
  const verificarValoresLlenos = (datos) => {
    for (const item of datos) {
      if (
        item.value === undefined ||
        item.value === null ||
        item.value === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const todosLosValoresLlenos = verificarValoresLlenos(data);
  if (todosLosValoresLlenos) {
    return (
      <Grid templateColumns={`repeat(${repeat}, 1fr)`} gap={6} width={"80%"} my={5}>
        {data?.map((data, index) => {
          return (
            <DataDisplayBadge
              key={`result-${index}`}
              size={"sm"}
              value={data.value}
              type={data.type}
              rightAddon={data.addOn}
              color={textColor}
              title={data.label}
              badgeColorScheme={data.badgeColor}
            />
          );
        })}
      </Grid>
    );
  } else {
    return <></>;
  }
};

export default ShowResults;
