export const headerImage =
  "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=348,h=270,fit=crop/A3QENvNaewUXORzD/gintec-blank-A0xeEo4wRNiBK1Gq.jpg";

export const videoSrc =
  "https://drive.google.com/uc?id=1GFxyVGvzgqvR2c1O0SI55P9jPW9hfBm9";

export const lat = 29.100346473535197;

export const lan = -110.98861416150231;

export const contactEmail = "elfenokierefiesta@gmail.com"