import React, { useContext, useState } from "react";
import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  DarkMode,
  Badge,
  Image,
  Grid,
} from "@chakra-ui/react";

import tecPlas from "./../../../assets/img/collaborators/tp/tcplFooter.png";

import avatar11 from "assets/img/avatars/avatar11.png";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Element } from "react-scroll";

import UserContext from "../../../contexts/UserContext";
import { DataStore } from "aws-amplify/datastore";
import { User } from "../../../models";
import imageSrc from "../../../assets/img/collaborators/tp/tcplFooter.png";
import HelmetComponent from "../../../components/HelmetComponent";
import {metaDescription, metaKeywords, metaTitle} from "./metaVariables";

function MyProfile() {
  const { user, updateUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitBasicUserData = async () => {
    setIsLoading(true);
    const u = await DataStore.query(User, user.id);
    await DataStore.save(
      User.copyOf(u, (updated) => {
        updated.name = user.name;
        updated.lastname = user.lastname;
        updated.city = user.city;
        updated.country = user.country;
        updated.state = user.state;
      })
    )
      .then((res) => {
        updateUser(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeToggle = async (e, type) => {
    e.preventDefault();

    const u = await DataStore.query(User, user.id);
    await DataStore.save(
      User.copyOf(u, (updated) => {
        updated[type] = e.target.checked;
      })
    )
      .then((res) => {
        updateUser(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <HelmetComponent description={metaDescription} keywords={metaKeywords} title={metaTitle} />
      <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
        {user.subscrptionType === "TECNICAS_PLASTICAS" && (
          <Flex
            mt={200}
            position="absolute"
            w="100%"
            h="100vh" // Altura del contenedor para que ocupe toda la altura de la ventana
            justify="center"
            align="center"
          >
            <Image
              src={imageSrc}
              alt="Molding Process"
              objectFit="cover"
              w="75%"
              h="auto" // La altura se ajustará automáticamente para mantener la proporción de la imagen
              opacity="0.2"
              zIndex="-1"
            />
          </Flex>
        )}
        <Stack direction="column" spacing="24px" mt="40px" w="100%">
          <Card opacity={0.95}>
            <Element>
              <CardBody>
                <Grid
                  templateColumns={`repeat(${
                    user.subscrptionType === "TECNICAS_PLASTICAS"
                      ? 4
                      : user.isSubscribed
                        ? 3
                        : 2
                  }, 1fr)`}
                  gap={1}
                  alignItems={"center"}
                >
                  <Grid>
                    <Avatar
                      src={
                        user.subscrptionType === "TECNICAS_PLASTICAS"
                          ? tecPlas
                          : avatar11
                      }
                      w="80px"
                      h="80px"
                      borderRadius={50}
                    />
                  </Grid>

                  <Grid ml={-10}>
                    <Text color="#fff" fontWeight="bold" fontSize="lg">
                      {user.name ?? user.id}
                    </Text>
                    <Text color="gray.400" fontWeight="500" fontSize="sm">
                      {user.email && user.email}
                    </Text>
                  </Grid>

                  {user.isSubscribed && (
                    <Grid ml={-10} justifyItems={"center"} alignItems={"center"}>
                      <Text color="#fff" fontWeight="bold" fontSize="lg">
                        Tipo de Subscripcion
                      </Text>
                      <Badge
                        py={1}
                        px={3}
                        colorScheme={"whatsapp"}
                        borderRadius={15}
                        fontWeight="bold"
                        fontSize="sm"
                      >
                        {user.subscrptionType &&
                          user.subscrptionType.replace(/_/g, " ")}
                      </Badge>
                    </Grid>
                  )}
                  {user.subscrptionType === "TECNICAS_PLASTICAS" && (
                    <Grid >
                      <Text color="#fff" fontWeight="bold" fontSize="lg">
                        TECNICAS PLASTICAS
                      </Text>
                      <Text
                        color={"#fff"}
                        fontSize="xs"
                        fontStyle={"italic"}
                      >
                        Esta suscripción ha sido posible gracias a nuestros amigos
                        de Técnicas Plásticas. Nos enorgullece colaborar con ellos
                        para brindarte esta oferta exclusiva. ¡Gracias por unirte
                        a nosotros y disfruta de los beneficios que esta alianza
                        te ofrece!
                      </Text>
                    </Grid>
                  )}
                </Grid>
              </CardBody>
            </Element>
          </Card>
          {/* Basic Info Card*/}
          <Card opacity={0.95}>
            <Element id="info" name="info">
              <CardHeader mb="40px">
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Informacion Basica
                </Text>
              </CardHeader>
              <CardBody>
                <Stack direction="column" spacing="20px" w="100%">
                  <Stack direction="row" spacing={{ sm: "24px", lg: "30px" }}>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#fff"
                        fontSize="xs"
                        mb="10px"
                      >
                        Nombre
                      </FormLabel>
                      <Input
                        onChange={(e) =>
                          updateUser({ ...user, name: e.target.value })
                        }
                        value={user.name}
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        placeholder="eg. Michael"
                        fontSize="xs"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#fff"
                        fontSize="xs"
                        mb="10px"
                      >
                        Apellido
                      </FormLabel>
                      <Input
                        onChange={(e) =>
                          updateUser({ ...user, lastname: e.target.value })
                        }
                        value={user.lastname}
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        placeholder="eg. Jackson"
                        fontSize="xs"
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={{ sm: "24px", lg: "30px" }}>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#fff"
                        fontSize="xs"
                        mb="10px"
                      >
                        Correo Electronico
                      </FormLabel>
                      <Input
                        value={user.email}
                        disabled
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        placeholder="eg. esthera@address.com"
                        fontSize="xs"
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={{ sm: "24px", lg: "30px" }}>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#fff"
                        fontSize="xs"
                        mb="10px"
                      >
                        Ciudad
                      </FormLabel>
                      <Input
                        value={user.city}
                        onChange={(e) =>
                          updateUser({ ...user, city: e.target.value })
                        }
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        placeholder="eg. Hermosillo"
                        fontSize="xs"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#fff"
                        fontSize="xs"
                        mb="10px"
                      >
                        Estado
                      </FormLabel>
                      <Input
                        value={user.state}
                        onChange={(e) =>
                          updateUser({ ...user, state: e.target.value })
                        }
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        placeholder="eg. Sonora"
                        fontSize="xs"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#fff"
                        fontSize="xs"
                        mb="10px"
                      >
                        País
                      </FormLabel>
                      <Input
                        value={user.country}
                        onChange={(e) =>
                          updateUser({ ...user, country: e.target.value })
                        }
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        placeholder="eg. México"
                        fontSize="xs"
                      />
                    </FormControl>
                  </Stack>
                  <Flex justifyContent={"space-between"} mt={5}>
                    <Button
                      variant="brand"
                      w={"100%"}
                      onClick={onSubmitBasicUserData}
                      isLoading={isLoading}
                    >
                      <Text fontSize="10px" color="#fff" fontWeight="bold">
                        ACTUALIZAR DATOS
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </CardBody>
            </Element>
          </Card>

          {/* Notifications Card */}
          <Card pb="30px" opacity={0.95}>
            <Element to="notifications" name="notifications">
              <CardHeader mb="40px">
                <Flex direction="column">
                  <Text color="#fff" fontSize="lg" fontWeight="bold" mb="4px">
                    Notificaciones
                  </Text>
                  <Text color="gray.400" fontWeight="400" fontSize="sm">
                    Aquí puedes manejar las notificaciones.
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th
                        color="gray.400"
                        fontSize="sm"
                        borderColor="gray.500"
                        fontWeight="500"
                        ps="0px"
                        textTransform="capitalise"
                      >
                        Activity
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="sm"
                        borderColor="gray.500"
                        fontWeight="500"
                        textTransform="capitalise"
                      >
                        Email
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td borderColor="gray.500" ps="0px" minW={{ sm: "300px" }}>
                        <Flex direction="column">
                          <Text
                            color="#fff"
                            fontWeight="500"
                            fontSize="sm"
                            mb="4px"
                          >
                            Actualizaciones
                          </Text>
                          <Text color="gray.400" fontSize="xs" fontWeight="400">
                            Notificame cuando este disponible una nueva
                            actualizacion de alguna herramienta
                          </Text>
                        </Flex>
                      </Td>
                      <Td borderColor="gray.500">
                        <DarkMode>
                          <Switch
                            defaultValue={user.notifications}
                            colorScheme="brand"
                            onChange={(e) => onChangeToggle(e, "notifications")}
                          />
                        </DarkMode>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td borderColor="gray.500" ps="0px" minW={{ sm: "300px" }}>
                        <Flex direction="column">
                          <Text
                            color="#fff"
                            fontWeight="500"
                            fontSize="sm"
                            mb="4px"
                          >
                            NewsLetter
                          </Text>
                          <Text color="gray.400" fontSize="xs" fontWeight="400">
                            Notificame de las noticias u ofertas
                          </Text>
                        </Flex>
                      </Td>
                      <Td borderColor="gray.500">
                        <DarkMode>
                          <Switch
                            defaultValue={user.notifications}
                            colorScheme="brand"
                            onChange={(e) => onChangeToggle(e, "newsLetter")}
                          />
                        </DarkMode>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </CardBody>
            </Element>
          </Card>
        </Stack>
      </Flex>
    </>

  );
}

export default MyProfile;
