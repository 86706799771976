import { Button, Grid, Text } from "@chakra-ui/react";
import InputCustomFieldProjectRigthAddon from "../../../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../../contexts/ProjectContext";
import { borderColor, textColor } from "../../../../utilities/variables";

const LimitForm = ({ showData, setShowData, onSubmitHandler }) => {
  const { project, handleInputChange, isLoading } = useContext(ProjectContext);

  const {
    inj_Press_Required_Limit,
    fill_Time_Limit,
    cycle_Time_Limit,
    cushion_Limit,
    recovery_Time_Limit,
    weight_Limit,
  } = project;

  return (
    <Grid
      mt={5}
      p={5}
      mx={5}
      borderWidth={1}
      borderRadius={15}
      borderColor={borderColor}
      color={textColor}
    >
      <Grid templateColumns="repeat(1, 1fr)" justifyItems={"center"} mb={5}>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Limites
        </Text>
      </Grid>
      <Grid
        templateColumns="repeat(3, 1fr)"
        templateRows="repeat(2, 1fr)"
        gap={6}
        justifyItems={"center"}
      >
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          value={inj_Press_Required_Limit}
          disabled={showData}
          mx={5}
          id={"inj_Press_Required_Limit"}
          addOn={"%"}
          label={"Rango Presion de Inyección"}
          type={inj_Press_Required_Limit}
          onChange={handleInputChange}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          value={fill_Time_Limit}
          disabled={showData}
          mx={5}
          id={"fill_Time_Limit"}
          addOn={"%"}
          label={"Rango Tiempo de Llenado"}
          type={fill_Time_Limit}
          onChange={handleInputChange}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          disabled={showData}
          value={cycle_Time_Limit}
          mx={5}
          id={"cycle_Time_Limit"}
          addOn={"%"}
          label={"Rango Tiempo de Ciclo"}
          type={cycle_Time_Limit}
          onChange={handleInputChange}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          value={cushion_Limit}
          disabled={showData}
          mx={5}
          id={"cushion_Limit"}
          addOn={"%"}
          label={"Rango Posición de Colchon"}
          type={cushion_Limit}
          onChange={handleInputChange}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          value={recovery_Time_Limit}
          disabled={showData}
          mx={5}
          id={"recovery_Time_Limit"}
          addOn={"%"}
          label={"Rango Tiempo de Recuperación"}
          type={recovery_Time_Limit}
          onChange={handleInputChange}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          value={weight_Limit}
          disabled={showData}
          mx={5}
          id={"weight_Limit"}
          addOn={"%"}
          label={"Rango Peso Real"}
          type={weight_Limit}
          onChange={handleInputChange}
        />
      </Grid>
      <Button
        colorScheme={"facebook"}
        isLoading={isLoading}
        disabled={
          !weight_Limit ||
          !inj_Press_Required_Limit ||
          !fill_Time_Limit ||
          !cycle_Time_Limit ||
          !cushion_Limit ||
          !recovery_Time_Limit
        }
        mt={5}
        onClick={showData ? () => setShowData(false) : () => onSubmitHandler()}
      >
        {showData ? "Editar" : "Guardar"}
      </Button>
    </Grid>
  );
};

export default LimitForm;
