import { initialStateHeader } from "../../../utilities/variabled";
import {tableInitialState} from "./tableInitialState";

export const initialState = {
  header: initialStateHeader,
  screwDiameter: "",
  pistonDiameter: "",
  realMeltTemp: "",
  maxInjectionSpeed: "",
  shotSizePosition: "",
  cutoffPosition: "",
  decompressionSize: "",
  cushionPositionTheoric: "",
  shotWeightFirstStage: "",
  specificWeight: "",
  immMaxHPress: "",
  maxMachineUse: "",
  processEfficence: "",

  screwArea: "",
  pistonArea: "",
  overallShotSize: "",
  intRatio: "",
  maxPlasticPress: "",
  speedIntervalUnit: "",
  maxPressReq: "",
  selectedSequence: "",
  table: tableInitialState,
};



export const keysForForm = [
  "screwDiameter",
  "pistonDiameter",
  "realMeltTemp",
  "maxInjectionSpeed",
  "shotSizePosition",
  "cutoffPosition",
  "decompressionSize",
  "cushionPositionTheoric",
  "shotWeightFirstStage",
  "specificWeight",
  "immMaxHPress",
  "maxMachineUse",
  "processEfficence",

];
export const keysForResult = [
  "screwArea",
  "pistonArea",
  "overallShotSize",
  "intRatio",
  "maxPlasticPress",
  "speedIntervalUnit",
  "maxPressReq",
  "selectedSequence",
];




