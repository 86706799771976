import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const CollaboratorsMap = withScriptjs(withGoogleMap(({lat, lan, zoom, marker}) =>{
    return (
        <GoogleMap
            defaultZoom={zoom ? zoom : 17}
            defaultCenter={{ lat: lat, lng: lan }}
        >
            {marker && <Marker position={{ lat: lat, lng: lan }} />}
        </GoogleMap>
    )
}
    ))

export default CollaboratorsMap
