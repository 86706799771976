import bodyImage1 from "./../../../assets/img/collaborators/asaclean/asaclean-body-1.jpg";
import bodyImage2 from "./../../../assets/img/collaborators/asaclean/asaclean-body-2.jpg";
import bodyImage3 from "./../../../assets/img/collaborators/asaclean/asaclean-body-3.jpg";
import bodyImage4 from "./../../../assets/img/collaborators/asaclean/asaclean-body-4.jpg";
import bodyImage5 from "./../../../assets/img/collaborators/asaclean/asaclean-part.png";

export const contactEmail = "elfenokierefiesta@gmail.com"

export const headerImage =
  "https://assets.zyrosite.com/A3QENvNaewUXORzD/asaclean-logo-png-ALpBkbXgJXi4lkDv.svg";

export const videoSrc =
  "https://drive.google.com/uc?id=1GFxyVGvzgqvR2c1O0SI55P9jPW9hfBm9";

export const lat = 19.420450720475745;

export const lan = -99.14568964984835;

export const bodyContent1 = {
  image: bodyImage1,
  title: "Cambios de Color y Contaminación",
  content:
    "Los cambios de color pueden llevar una cantidad significativa de tiempo, especialmente cuando necesita cambiar de colores oscuros a colores más claros. La contaminación del color se produce cuando las capas de resinas anteriores dentro de su máquina se desprenden en piezas futuras. Los cambios de color y la contaminación del color son dos de los desafíos de procesamiento más comunes con los que nos enfrentamos. Frecuentes o no, siguen siendo un gran dolor de cabeza.",
  footer:
    "En promedio, los clientes de Asaclean® ahorran un 47% en cambios de color",
  url:
    "https://www.asaclean.com/es/compuestos-de-purga/sobre-compuestos-de-purga",
};
export const bodyContent2 = {
  image: bodyImage2,
  title: "Extracciones de Tornillo",
  content:
    "\n" +
    "Extracciones de Tornillo\n" +
    "Las extracciones de tornillos (o empujes de tornillos en la extrusión) son uno de los mayores dolores de cabeza que enfrentan los procesadores y los equipos de mantenimiento. Si no está utilizando compuestos de purga para ayudar con las extracciones de tornillos, su producción se detiene en seco y su equipo puede dedicar innumerables horas de producción y de personal a un aspecto de la producción. Esto descarrila su potencial de ganancias. Echemos un vistazo más profundo a cómo Asaclean® puede ayudarlo a transformar la forma en que su planta maneja las extracciones / empujes de tornillos.",
  footer: "",
  url: "https://www.asaclean.com/es/soluciones/extraccion-de-tornillos",
};

export const bodyContent3 = {
  image: bodyImage3,
  title: "Contaminación (Carbón y Puntos Negros)",
  content:
    "Todos los procesadores han batallado con la contaminación por carbón y puntos negros. Geles, puntos negros, manchas y otra forma de contaminación cuando las resinas de procesamiento comienzan a degradarse dentro de su máquina. Una vez que note defectos en sus piezas, debe abordar el problema de inmediato o su merma comenzará a acumularse.\n" +
    "\n" +
    "Ya sea que experimente manchas negras al inicio o durante su ciclo de producción, Asaclean® lo ayuda a resolver sus problemas de carbón y volver a procesar piezas buenas en poco tiempo.",
  footer: "",
  url: "https://www.asaclean.com/es/soluciones/carbon-contaminacion",
};

export const bodyContent4 = {
  image: bodyImage4,
  title: "Apagado y Sellado",
  content:
    "Es hora de parar el fin de semana. ¿Qué hace? Muchos procesadores simplemente vacían el tornillo y el barril, apagan las resistencias y se van a casa durante el fin de semana. Al comenzar de nuevo el lunes por la mañana, las tasas de desperdicio son altas y parece que lleva una eternidad iniciar la producción. Este es un error costoso. Hemos estado allí y sabemos lo frustrante que puede ser esto. Estamos aquí para ayudar. Esta sección explica cómo tener la puesta en marcha más sencilla posible.",
  footer: "",
  url: "https://www.asaclean.com/es/soluciones/paro-y-sello",
};

export const bodyContent5 = {
  image: bodyImage5,
  title: "Purgando Resinas de Temperaturas Altas y Bajas",
  content:
    'Asaclean® está en una posición única para ayudarlo a evitar la contaminación relacionada con la temperatura. Ya sea que utilice resinas de ingeniería como ULTEM y PEEK o resinas básicas de baja temperatura como FPVC y TPU, tenemos productos y recomendaciones para ayudarlo a eliminar el carbón y otros problemas de contaminación. Muchos procesadores también luchan durante los cambios de material en los que deben "reducir" o "aumentar" sus temperaturas para su próximo material de producción. No te preocupes. Te tenemos cubierto, allí, también.',
  footer: "",
  url:
    "https://www.asaclean.com/es/soluciones/cambios-de-alta-y-baja-temperatura",
};
