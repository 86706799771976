import { useState, useEffect } from 'react';
import {DataStore} from "aws-amplify/datastore";
import {Machine} from "../../../../models";

const useMachineCount = () => {
  const [machineCount, setMachineCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMachineCount = async () => {
      try {
        const machines = await DataStore.query(Machine);
        setMachineCount(machines.length); // Usa la longitud de la lista
      } catch (error) {
        console.error('Error al obtener el conteo de maquinas:', error);
      }
      setIsLoading(false);
    };

    fetchMachineCount();
  }, []);

  return { machineCount, isLoading };
};

export default useMachineCount;
