import { useIndividualHooks } from "../hooks/useIndividualHooks";
import IndividualFooter from "../../../components/IndividualFooter";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { keysForForm } from "../utilities/variables";
import React from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import ShowResults from "../../../components/ShowResults";
import { dataResults } from "../utilities/dataToShow";
import Form from "./Form";
import PressureLossChart from "../../../../project-overview/components/tools/pressure-loss/components/PressureLossChart";
import { lineChartOptionsPressureLoss } from "../utilities/chartOptions";
import { validateResultsFilled } from "../utilities/validateResults";
import { Grid } from "@chakra-ui/react";

const IndividualForm = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    lineChartDataPressureLoss,
    chartRef,
  } = useIndividualHooks(setIsDisabled);

  const isDisabled = !useVerifyForm(data, keysForForm);
  const isFormDisabled = !isObjectEmpty(result);

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <Form
          onChange={onChangeHandler}
          data={data}
          disabled={isFormDisabled}
        />
        {validateResultsFilled(data) && (
          <>
            <ShowResults repeat={4} data={dataResults(data)} />
          </>
        )}
        {validateResultsFilled(data) && (
          <Grid width={"100%"}>
            <div ref={chartRef}>
              <PressureLossChart
                lineChartDataPressureLoss={lineChartDataPressureLoss}
                lineChartOptionsPressureLoss={lineChartOptionsPressureLoss}
              />
            </div>
          </Grid>
        )}
        <IndividualFooter
          result={result}
          url={"loss-pressure"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={isDisabled}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualForm;
