import {useContext, useState} from "react";
import { initialMachineStateRadio } from "../utilities/variables";
import { DataStore } from "aws-amplify/datastore";
import {Machine} from "../../../../models";
import UserContext from "../../../../contexts/UserContext";

export const machineHooks = () => {
  const [machine, setMachine] = useState(initialMachineStateRadio);
  const [skills, setSkills] = useState([]);
  const { user } = useContext(UserContext);
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setSkills([...skills, e.target.value]);
      e.target.value = "";
    }
  };

  const onChangeHandler = (e) => {
    if (e.target.type === "number") {
      const val = parseFloat(e.target.value);
      setTool({ ...tool, [e.target.name]: val });
    } else {
      setTool({ ...tool, [e.target.name]: e.target.value });
    }
  };

  const parseFinalState = (too, values) => {

    let finalState = {
      ...too,
      ...values,
    };
    let {
      machineTonnage,
      pistonDiameter,
      screwDiameter ,
      maxHydraulicPressure,
      hopperCapacity,
      maxShotSize,
      LDratio,
      maxInjectionSpeed,
      machineMaxEjection,
      tieBarVerticalDistance,
      tieBarHorizontalDistance,
      maxOpenClamp,
      minStackMoldSize,
      maxStackMoldSize,
      maxMachineEjection,
      minMachineEjection,
      uom,
    } = finalState;
    machineTonnage = parseFloat(machineTonnage);
    pistonDiameter = parseFloat(pistonDiameter);
    screwDiameter = parseFloat(screwDiameter);
    maxHydraulicPressure = parseFloat(maxHydraulicPressure);
    hopperCapacity = parseFloat(hopperCapacity);
    maxShotSize = parseFloat(maxShotSize);
    LDratio = parseFloat(LDratio);

    maxInjectionSpeed = parseFloat(maxInjectionSpeed);
    machineMaxEjection = parseFloat(machineMaxEjection);
    tieBarVerticalDistance = parseFloat(tieBarVerticalDistance);
    tieBarHorizontalDistance = parseFloat(tieBarHorizontalDistance);
    maxOpenClamp = parseFloat(maxOpenClamp);
    minStackMoldSize = parseFloat(minStackMoldSize);
    maxStackMoldSize = parseFloat(maxStackMoldSize);
    maxMachineEjection = parseFloat(maxMachineEjection);
    minMachineEjection = parseFloat(minMachineEjection);
    const div = uom === "INGLES" ? 1 : 1000

    finalState = {
      ...finalState,
      machineTonnage,
      pistonDiameter,
      screwDiameter,
      maxHydraulicPressure,
      hopperCapacity,
      maxShotSize,
      LDratio,
      barrelVolume: parseFloat(screwDiameter*screwDiameter*0.785*maxShotSize) / div ,
      maxInjectionSpeed,
      machineMaxEjection,
      tieBarVerticalDistance,
      tieBarHorizontalDistance,
      maxOpenClamp,
      minStackMoldSize,
      maxStackMoldSize,
      maxMachineEjection,
      minMachineEjection,
      userID: user.id
    };

    return finalState;
  };

  const onSubmitHandler = async (finalState) => {
    return await DataStore.save(new Machine(finalState));
  };

  return {
    parseFinalState,
    skills,
    keyPress,
    machine,
    setSkills,
    onChangeHandler,
    setMachine,
    onSubmitHandler,
  };
};
