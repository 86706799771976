import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import CustomGrid from "../../views/projects/individual/components/CustomGrid";
import React from "react";
import HorizontalDivider from "../dividers/HorizontalDivider";

const DescriptionAndInstructionAcordeon = ({ description, instructions }) => {
  const textColor = "gray.600";
  return (
    <>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <HorizontalDivider mt={5} />
        <Accordion
          allowToggle
          w={{ sm: "300px", md: "650px", xl: "930px" }}

        >
          <AccordionItem color={"white"} border="none">
            <AccordionButton _focus="none" _hover="none" p="0px 0px 00px 0px">
              <Box flex="1">
                <Text color={textColor} fontWeight="bold" fontSize="lg">
                  DESCRICPICIÓN
                </Text>
              </Box>
              <AccordionIcon color="#000" />
            </AccordionButton>
            <AccordionPanel >
              <Text
                color={textColor}
                fontSize="md"
                textAlign="left"
                alignSelf="flex-start"
                justifySelf="flex-start"
              >
                {description.map((item, index) => {
                  return (
                    <>
                      <Text
                        key={`description-${index}`}
                        color={textColor}
                        fontSize="md"
                        textAlign="left"
                        alignSelf="flex-start"
                        justifySelf="flex-start"
                      >
                        {item}
                      </Text>
                      <br />
                    </>
                  );
                })}
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <HorizontalDivider mt={5} />
          <AccordionItem color={"white"} border="none">
            <AccordionButton _focus="none" _hover="none" p="0px 0px 00px 0px" >
              <Box flex="1">
                <Text color={textColor} fontWeight="bold" fontSize="lg">
                  INSTRUCCIONES
                </Text>
              </Box>
              <AccordionIcon color="#000" />
            </AccordionButton>
            <AccordionPanel >
              {instructions.map((item, index) => {
                return (
                  <>
                    <Text
                      key={`instructions-${index}`}
                      color={textColor}
                      fontSize="md"
                      textAlign="left"
                      alignSelf="flex-start"
                      justifySelf="flex-start"
                    >
                      {item}
                    </Text>
                    <br />
                  </>
                );
              })}
            </AccordionPanel>
          </AccordionItem>
          <HorizontalDivider mt={5} />
        </Accordion>
      </Flex>
    </>
  );
};

export default DescriptionAndInstructionAcordeon;
