import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import { useContext, useEffect, useRef, useState } from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { DataStore } from "aws-amplify/datastore";
import { Melt3030 } from "../../../../../../models";
import { initialState } from "../utilities/variables";
import { useAlert } from "../../../../../../contexts/useAlert";
import html2canvas from "html2canvas";
import { delay } from "../../../hooks/useDelay";
import { uploadData } from "aws-amplify/storage";
import UserContext from "../../../../../../contexts/UserContext";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";

export const useIndividualMelt3030Hooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();

  const [data, setData] = useState(initialState);
  const [showData, setshowData] = useState(false);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chartRef = useRef();
  const { user } = useContext(UserContext);

  const { showSuccessAlert, showErrorAlert } = useAlert();

  const createImages = async () => {
    await delay(500);
    const canvas = await html2canvas(chartRef.current);

    // Convertir el canvas a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Falló la conversión de canvas a blob"));
        }
      }, "image/png");
    });
  };

  const [lineChartDataMelt, setLineChartDataMelt] = useState([
    {
      name: "Maximo",
      data: [0, 0, 0, 0, 0],
      color: "#FF0000",
    },
    {
      name: "Minimo",
      data: [0, 0, 0, 0, 0],
      color: "#FF0000",
    },
    {
      name: "Real",
      data: [0, 0, 0, 0, 0],
      color: "#FF0000",
    },
  ]);

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;

    // Verifica si el ID tiene un formato que indica una propiedad anidada (por ejemplo, 'dryTime.min')
    if (id.includes(".")) {
      const [outerKey, innerKey] = id.split(".");

      // Actualiza solo la propiedad anidada relevante dentro del objeto
      setData((prevData) => ({
        ...prevData,
        [outerKey]: {
          ...prevData[outerKey],
          [innerKey]: value,
        },
      }));
    } else {
      // Maneja la actualización para propiedades no anidadas
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    setshowData(true);
    const image = await createImages();

    const res = await uploadData({
      key: `check-valve-individual/${Date.now()}.png`,
      data: image,
    }).result;
    try {
      const response = await DataStore.save(
        new Melt3030({
          ...data,
          chartImage: res.key,
          userID: user.id,
        })
      );
      let text = "";
      let icon = "success";

      if (parseFloat(data?.meltTempMax) < parseFloat(data?.realMeltTemp)) {
        text += "<li>La temperatura de Masa Real es Mayor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.meltTempMin) > parseFloat(data?.realMeltTemp)) {
        text += "<li>La temperatura de Masa Real es Menor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.nozzleTempMax) < parseFloat(data?.realNozzleTemp)) {
        text += "<li>La temperatura Real de Nariz es Mayor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.nozzleTempMin) > parseFloat(data?.realNozzleTemp)) {
        text += "<li>La temperatura Real de Nariz es Menor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.frontTempMax) < parseFloat(data?.realFrontTemp)) {
        text += "<li>La temperatura Frontal Real es Mayor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.frontTempMin) > parseFloat(data?.realFrontTemp)) {
        text += "<li>La temperatura Frontal Real es Menor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.midTempMax) < parseFloat(data?.realMidTemp)) {
        text += "<li>La temperatura Central Real es Mayor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.midTempMin) > parseFloat(data?.realMidTemp)) {
        text += "<li>La temperatura Central Real es Menor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.backTempMax) < parseFloat(data?.realBackTemp)) {
        text += "<li>La temperatura Trasera Real es Mayor a la recomendada.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.backTempMin) > parseFloat(data?.realBackTemp)) {
        text += "<li>La temperatura Trasera Real es Menor a la recomendada.</li>";
        icon = "warning";
      }

      setResult(response);
      conditionalsAlert(icon, text);
    } catch (error) {
      showErrorAlert({
        error,
      });
    }
    setIsLoading(false);
  };

  const checkIsValidForm = () => {
    const state = data;
    // Definir las claves que deben estar llenas y las que deben estar vacías
    const keysToBeFilled = [
      "header",
      "dryerID",
      "scrapRisk",
      "prodQuantity",
      "dryerCapacity",
      "dryTempMin",
      "dryTime",
      "maxMoisture",
      "numberOfCavities",
      "shotWeight100",
      "cycleTime",
    ];
    const keysToBeEmpty = [
      "pcsPerHour",
      "cycleTimeMin",
      "dryTimeMin",
      "reqShotsForProd",
      "dryerCapacityOz",
      "reqResinForProd",
      "resinFlowPerMin",
      "productionDuration",
      "resinTroughDryer",
      "dryerSupplying",
      "minDryerReload",
      "reloadsReq",
    ];

    // Verificar si las claves que deben estar llenas lo están
    for (const key of keysToBeFilled) {
      if (!state[key] || state[key] === "") {
        return false; // Retorna falso si alguna clave necesaria está vacía
      }
    }

    // Verificar si las claves que deben estar vacías lo están
    for (const key of keysToBeEmpty) {
      if (state[key]) {
        return false; // Retorna falso si alguna clave que debe estar vacía no lo está
      }
    }

    return true; // Retorna verdadero si todas las condiciones se cumplen
  };

  useEffect(() => {
    setLineChartDataMelt([
      {
        name: "Maximo",
        data: [
          parseFloat(data?.meltTempMax).toFixed(2),
          parseFloat(data?.nozzleTempMax).toFixed(2),
          parseFloat(data?.frontTempMax).toFixed(2),
          parseFloat(data?.midTempMax).toFixed(2),
          parseFloat(data?.backTempMax).toFixed(2),
        ],
      },
      {
        name: "Minimo",
        data: [
          parseFloat(data?.meltTempMin).toFixed(2),
          parseFloat(data?.nozzleTempMin).toFixed(2),
          parseFloat(data?.frontTempMin).toFixed(2),
          parseFloat(data?.midTempMin).toFixed(2),
          parseFloat(data?.backTempMin).toFixed(2),
        ],
      },
      {
        name: "Real",
        data: [
          parseFloat(data?.realMeltTemp).toFixed(2),
          parseFloat(data?.realNozzleTemp).toFixed(2),
          parseFloat(data?.realFrontTemp).toFixed(2),
          parseFloat(data?.realMidTemp).toFixed(2),
          parseFloat(data?.realBackTemp).toFixed(2),
        ],
      },
    ]);
  }, [data]);

  return {
    data,
    uom,
    setData,
    header,
    machineName,
    productName,
    resinName,
    toolName,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    checkIsValidForm,
    lineChartDataMelt,
    showData,
    chartRef,
  };
};
