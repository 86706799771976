import { post } from "aws-amplify/api";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";

export const useSendEmailHooks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("success");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const sendEmail = async (destination) => {
    setIsLoading(true);
    try {
      const restOperation = post({
        path: "/send-email-to-collaborators",
        apiName: "sendEmailCollaborators",
        options: {
          body: {
            email,
            message,
            name,
            company,
            destination,
          },
        },
      });
      await restOperation.response;
      onOpen();
      setEmail("");
      setName("");
      setMessage("");
      setCompany("");
    } catch (error) {
      console.log(error.message);
      setType("error");
      onOpen();
    }
    setIsLoading(false);
  };

  return {
    sendEmail,
    setEmail,
    email,
    setCompany,
    company,
    onOpen,
    isOpen,
    onClose,
    isLoading,
    message,
    setMessage,
    name,
    setName,
    type,
  };
};
