import {initialStateHeader, initialStateMinMax} from "../../../utilities/variabled";

export const initialState = {
  header: initialStateHeader,
  dryerID: "",
  scrapRisk: "",
  prodQuantity: "",
  dryerCapacity: "",
  dryTempMin: "",
  dryTime: initialStateMinMax,
  maxMoisture: "",
  numberOfCavities: "",
  shotWeight100: "",
  pcsPerHour: "",
  cycleTimeMin: "",
  dryTimeMin: "",
  reqShotsForProd: "",
  cycleTime: "",
  dryerCapacityOz: "",
  reqResinForProd: "",
  resinFlowPerMin: "",
  productionDuration: "",
  resinTroughDryer: "",
  dryerSupplying: "",
  minDryerReload: "",
  reloadsReq: "",

}