import { Text} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const Desviation = ({ data, item, show }) => {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    if (data && data.length > 0) {
      const values = data.map(subArray => parseFloat(subArray[5].desviation)).filter(val => !isNaN(val));
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);
      const averageValue = values.reduce((sum, value) => sum + value, 0) / values.length;

      const findClosestValue = (array, target) => {
        return array.reduce((closest, value) =>
            Math.abs(value - target) < Math.abs(closest - target) ? value : closest,
          array[0]
        );
      };

      const closestValue = findClosestValue(values, averageValue);

      const isLight = (r, g, b) => {
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186;
      };

      const getColor = (value, minValue, averageValue, maxValue, closestValue) => {
        if (value === minValue) {
          return {
            bgColor: 'rgb(0, 255, 0)',
            textColor: 'black'
          };
        } else if (value === maxValue) {
          return {
            bgColor: 'rgb(255, 0, 0)',
            textColor: 'white'
          };
        } else if (value === closestValue) {
          return {
            bgColor: 'rgb(255, 255, 0)',
            textColor: isLight(255, 255, 0) ? 'black' : 'white'
          };
        } else if (value < averageValue) {
          const scale = (value - minValue) / (averageValue - minValue);
          const red = scale * 255;
          const green = 255;
          return {
            bgColor: `rgb(${red}, ${green}, 0)`,
            textColor: isLight(red, green, 0) ? 'black' : 'white'
          };
        } else {
          const scale = (value - averageValue) / (maxValue - averageValue);
          const red = 255;
          const green = 255 - scale * 255;
          return {
            bgColor: `rgb(${red}, ${green}, 0)`,
            textColor: isLight(red, green, 0) ? 'black' : 'white'
          };
        }
      };

      const { bgColor, textColor } = getColor(parseFloat(item.desviation), minValue, averageValue, maxValue, closestValue);
      setBgColor(bgColor);
      setTextColor(textColor);
    }
  }, [data, item]);

  return (
    <Text borderRadius={5} px={2} py={1} justifyContent={"center"} size={"xs"} style={show ? { backgroundColor: bgColor, color: textColor } : {}}>
      {show ? parseFloat(item.desviation).toFixed(2) + "%" : ""}
    </Text>
  );
}

export default Desviation;
