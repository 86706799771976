import { inMm, lbKg, ozGr, temp } from "../../../hooks/useUomTransform";

export const inputData = (data) => {
  const { uom } = data?.header;
  return [
    {
      label: "Peso Específico",
      value: data?.specificWeight,
      id: "specificWeight",
      addOn: ":1",
      error:
        parseFloat(data?.specificWeight) <= 0 &&
        "El Peso Especifico debe ser mayor que 0.",
    },
    {
      label: "Diámetro de Tornillo",
      value: data?.screwDiameter,
      id: "screwDiameter",
      addOn: inMm(uom),
      error:
        parseFloat(data?.screwDiameter) <= 0 &&
        "El Diámetro de Tornillo debe ser mayor que 0.",
    },
    {
      label: "Diámetro de Pistón",
      value: data?.pistonDiameter,
      id: "pistonDiameter",
      addOn: inMm(uom),
      error:
        parseFloat(data?.pistonDiameter) < parseFloat(data?.screwDiameter) &&
        "El Diámetro del Pistón debe ser mayor o igual al Diámetro de Tornillo.",
    },

    {
      label: "Máximo Tamaño de Disparo",
      value: data?.maxShotSize,
      id: "maxShotSize",
      addOn: inMm(uom),
      error:
        parseFloat(data?.maxShotSize) <= 0 &&
        "El Máximo Tamaño de Disparo debe ser mayor que 0.",
    },
    {
      label: "Mínima Posición de Colchón",
      value: data?.minCushion,
      id: "minCushion",
      addOn: inMm(uom),
      error:
        parseFloat(data?.minCushion) <= 0 &&
        "La Mínima Posición de Colchón debe ser mayor que 0.",
    },
    {
      label: "Máxima Posición de Colchón",
      value: data?.maxCushion,
      id: "maxCushion",
      addOn: inMm(uom),
      error:
        parseFloat(data?.maxCushion) <= parseFloat(data?.minCushion) &&
        "La Máxima Posición de Colchón debe ser mayor que la Mínima Posición de Colchón.",
    },
    {
      label: "Temperatura de Masa Real",
      value: data?.realMeltTemp,
      id: "realMeltTemp",
      addOn: temp(uom),
      error:
        parseFloat(data?.realMeltTemp) <= 0 &&
        "La Temperatura de Masa Real debe ser mayor que 0.",
    },
    {
      label: "L/D Ratio de Máquina",
      value: data?.LDratio,
      id: "LDratio",
      addOn: ":1",
      error:
        parseFloat(data?.LDratio) <= 0 &&
        "El L/D Ratio de Máquina debe ser mayor que 0.",
    },
    {
      label: "Mínimo Uso de Barril",
      value: data?.minBarrelUse,
      id: "minBarrelUse",
      addOn: "%",
      error:
        parseFloat(data?.minBarrelUse) <= 0 &&
        "El Mínimo Uso de Barril debe ser mayor que 0.",
    },
    {
      label: "Máximo Uso de Barril",
      value: data?.maxBarrelUse,
      id: "maxBarrelUse",
      addOn: "%",
      error:
        parseFloat(data?.maxBarrelUse) <= parseFloat(data?.minBarrelUse)
          ? "El Máximo Uso de Barril debe ser mayor que el Mínimo Uso de Barril."
          : parseFloat(data?.maxBarrelUse) > 100 &&
            "El Máximo Uso de Barril no puede ser mayor a 100.",
    },
    {
      label: "Tamaño de Descompresión",
      value: data?.decompressionSize,
      id: "decompressionSize",
      addOn: inMm(uom),
      error:
        parseFloat(data?.decompressionSize) < 0 &&
        "El Tamaño de Descompresión debe ser mayor que 0.",
    },
    {
      label: "Peso de Disparo al 100%",
      value: data?.shotWeight100,
      id: "shotWeight100",
      addOn: ozGr(uom),
      error:
        parseFloat(data?.shotWeight100) <= 0 &&
        "El Peso de Disparo al 100% debe ser mayor que 0.",
    },
    {
      label: "Peso de Runner",
      value: data?.runnerWeight,
      id: "runnerWeight",
      addOn: ozGr(uom),
      error:
        parseFloat(data?.runnerWeight) >=
          parseFloat(data?.shotWeight100) &&
        "El Peso de Runner debe ser menor que el Peso de Disparo al 100%.",
    },
    {
      label: "Cantidad de Cavidades",
      value: data?.cavityQuantity,
      id: "cavityQuantity",
      addOn: "pzs",
      error:
        parseFloat(data?.cavityQuantity) < 1 &&
        "La Cantidad de Cavidades debe ser mayor que 1.",
    },
    {
      label: "Posición de Transferencia V/P",
      value: data?.cutoffPosX100,
      id: "cutoffPosX100",
      addOn: "%",
      error:
        parseFloat(data?.cutoffPosX100) < 95
          ? "La Posición de Transferencia V/P no puede ser menor que 95."
          : parseFloat(data?.cutoffPosX100) > 99 &&
            "La Posición de Transferencia V/P no puede ser mayor que 99.",
    },

    {
      label: "Porcentaje de Colchón",
      value: data?.cushionPosX100,
      id: "cushionPosX100",
      addOn: "%",
      error:
        parseFloat(data?.cushionPosX100) <= 0 &&
        "El Porcentaje de Colchón debe ser mayor que 0.",
    },
  ];
};
