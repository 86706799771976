import {Text} from "@chakra-ui/react";
import {inMm, inSecMmsec, ozGr} from "../../../../hooks/useUomTransform";

const HeaderFirst = ({header}) => {
  return (
    <>
      <Text>Secuencia</Text>
      <Text>Velocidad {inSecMmsec(header?.uom)}</Text>
      <Text>Tiempo de Llenado sec</Text>
      <Text>Máx Presión</Text>
      <Text>Peso de Disparo {ozGr(header?.uom)}</Text>
      <Text>Transferencia V/P {inMm(header?.uom)}</Text>
    </>
  )
}

export default HeaderFirst