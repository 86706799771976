import { Document, Page, Text } from "@react-pdf/renderer";
import PdfHeader from "../PdfHeader";
import usePdfStyles from "../../hooks/usePdfstyles";
import React from "react";
import mvpsLogo from "../../../../../variables/s3-images";

const PdfBase = ({ data, children, name, image = false }) => {
  const styles = usePdfStyles();
  console.log(mvpsLogo);
  return (
    <Document
      pageLayout={"twoColumnLeft"}
      creator={"MVPS"}
      subject={`${name} ${data?.header?.productName}`}
      title={`${name} ${data?.header?.productName}`}
      author={"https://molding-vps.com"}
    >
      <Page bookmark={"MVPS"} size="LETTER">
        <PdfHeader data={data} title={name} />
        {children}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            <>
              <Text style={styles.pageNumber}>https://molding-vps.com</Text>
            </>
          )}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PdfBase;
