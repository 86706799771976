import {
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ldImg from "../../../../assets/img/collaborators/ld/ld-mold.jpg";
import ldImg2 from "../../../../assets/img/collaborators/ld/ld-mold-2.jpg";
import HorizontalDivider from "../../../../components/dividers/HorizontalDivider";
const LdBody = ({ textColor }) => {
  const metallicGrayGradient = useColorModeValue(
    "linear(to-r, #95a5a6, #bdc3c7, #95a5a6)", // Gradiente para el modo claro
    "linear(to-r, #7f8c8d, #99a3a4, #7f8c8d)" // Gradiente para el modo oscuro (opcional)
  );
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      borderRadius={15}
      bgGradient={metallicGrayGradient}
      justifyItems={"center"}
      gap={6}
      templateColumns="repeat(5, 1fr)"
    >
      <GridItem colSpan={3}>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"} mb={5}>
          Contamos con amplio equipo de maquinado para la fabricación de
          nuestros moldes y herramientas:
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>2 CNC 5 Axis ( 3100 x 2000 x 1000 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>3 CNC High Speed ( 3200 x 2100 x 1300 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>1 CNC High Speed ( 3200 x 2000 x 1350 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>1 CNC High Speed ( 2500 x 2000 x 1200 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>1 CNC High Speed ( 2500 x 1800 x 1000 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>1 CNC High Speed ( 2000 x 1000 x 900 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>2 CNC High Speed ( 1800 x 900 x 900 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>1 CNC High Speed ( 1650 x 800 x 900 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>1 CNC High Speed ( 1200 x 1000 x 900 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>8 EDM (1800 x 1600 x 1200 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>2 EDM ( 1300 x 1200 x 1000 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>3 EDM ( 800 x 650 x 600 )</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>1 Fitting Press 300 Ton</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>2 Deep Drilling</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>6 Maquinas de Moldeo por Inyección de 80 a 470 Ton</li>
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          <li>Y mas...</li>
        </Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Image src={ldImg} borderRadius={15} />
        </Flex>
      </GridItem>
      <GridItem colSpan={5}>
        <HorizontalDivider mt={5} />
      </GridItem>
      <GridItem colSpan={2}>
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Image src={ldImg2} borderRadius={15} />
        </Flex>
      </GridItem>
      <GridItem colSpan={3}>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"} mb={5}>
          Proceso de Inyección Científico
        </Text>
        <Text color={textColor} mb={5} fontSize={"xl"}>
          Analizamos cada etapa del proceso de moldeo por inyección y operamos
          cada paso estrictamente de acuerdo con los requisitos del cliente,
          verificando cada detalle y funcionabilidad, logrando productos de la
          mayor calidad.
        </Text>
        <Text color={textColor} fontSize={"xl"} fontWeight={"bold"}>
          Prueba de moldes, servicio de producción de plástico
        </Text>
        <Text color={textColor} fontSize={"xl"}>
          Contamos con nuestro propio departamento de inyección con maquinas de
          hasta 500 toneladas, realizamos pruebas de moldes y producción de
          muestras para nuestros clientes internamente. Esto significa más
          control de costos y mejor servicio de soporte para cumplir los
          requisitos del cliente.
        </Text>
      </GridItem>
    </Grid>
  );
};

export default LdBody;
