import {
  FormControl,
  FormLabel,
  Input,
  InputGroup, InputLeftAddon,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { inputBorderColor, textColor } from "../utilities/variabled";

const CustomInput = ({
  label,
  id,
  onChange,
  value,
  size = "sm",
  borderRadius = 5,
  addOnText,
  disabled = false,
  placeholder,
  name,
  borderColor = "",
  error,
                       addOnLeft,
  ...props
}) => {
  if (addOnLeft && addOnText) {
    return (
      <FormControl size={size} color={error ? "red.600" : "black"}>
        <FormLabel color={textColor} fontSize={"sm"}>
          {label}
        </FormLabel>
        <InputGroup size={size} borderColor={borderColor}>
          <InputLeftAddon
            children={addOnLeft}
            borderRadius={borderRadius}
            borderColor={inputBorderColor}
          />
          <Input
            name={name}
            disabled={disabled}
            backgroundColor={"white"}
            borderRadius={borderRadius}
            placeholder={label ? label : placeholder}
            borderColor={inputBorderColor}
            id={id}
            value={value}
            onChange={onChange}
          />
          <InputRightAddon
            children={addOnText}
            borderRadius={borderRadius}
            borderColor={inputBorderColor}
          />
        </InputGroup>
        {error && (
          <Text mt={2} color={"red.600"}>
            {error}
          </Text>
        )}
      </FormControl>
    );
  } else if (addOnText) {
    return (
      <FormControl size={size} color={error ? "red.600" : "black"}>
        <FormLabel color={textColor} fontSize={"sm"}>
          {label}
        </FormLabel>
        <InputGroup size={size} borderColor={borderColor}>
          <Input
            name={name}
            disabled={disabled}
            backgroundColor={"white"}
            borderRadius={borderRadius}
            placeholder={label ? label : placeholder}
            borderColor={inputBorderColor}
            id={id}
            value={value}
            onChange={onChange}
          />
          <InputRightAddon
            children={addOnText}
            borderRadius={borderRadius}
            borderColor={inputBorderColor}
          />
        </InputGroup>
        {error && (
          <Text mt={2} color={"red.600"}>
            {error}
          </Text>
        )}
      </FormControl>
    );
  } else if (addOnLeft) {
    return (
      <FormControl size={size} color={error ? "red.600" : "black"}>
        <FormLabel color={textColor} fontSize={"sm"}>
          {label}
        </FormLabel>
        <InputGroup size={size} borderColor={borderColor}>
          <InputLeftAddon
            children={addOnLeft}
            borderRadius={borderRadius}
            borderColor={inputBorderColor}
          />
          <Input
            name={name}
            disabled={disabled}
            backgroundColor={"white"}
            borderRadius={borderRadius}
            placeholder={label ? label : placeholder}
            borderColor={inputBorderColor}
            id={id}
            value={value}
            onChange={onChange}
          />

        </InputGroup>
        {error && (
          <Text mt={2} color={"red.600"}>
            {error}
          </Text>
        )}
      </FormControl>
    );
  } else {
    return (
      <FormControl size={size} color={error ? "red.600" : "black"}>
        <FormLabel color={textColor} fontSize={"sm"}>
          {label}
        </FormLabel>
        <Input
          name={name}
          disabled={disabled}
          borderRadius={borderRadius}
          size={size}
          backgroundColor={"white"}
          placeholder={label ? label : placeholder}
          borderColor={inputBorderColor}
          id={id}
          value={value}
          onChange={onChange}
        />
        {error && <Text color={"red.600"}>{error}</Text>}
      </FormControl>
    );
  }
};

export default CustomInput;
