import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import BannerLayout from "layouts/Banner.js";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
import { UserProvider } from "./contexts/UserContext";
import "./custom.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import { formFields, components } from "./loginVariabled";
import { ProjectProvider } from "./contexts/ProjectContext";
import { AlertProvider } from "./contexts/useAlert";
I18n.putVocabularies(translations);
I18n.setLanguage("es");

ReactDOM.render(
  <Router>
    <UserProvider>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/banner" component={() => {
          return (
            <AlertProvider>
              <BannerLayout />
            </AlertProvider>

          )
        }} />
        <Route
          path={`/admin`}
          component={() => {
            return (
              <Authenticator
                components={components}
                formFields={formFields}
                className={"myAuthenticatorClass"}
              >
                {({ user, signOut }) => (
                  <ProjectProvider>
                    <AlertProvider>
                      <AdminLayout user={user} signOut={signOut} />
                    </AlertProvider>
                  </ProjectProvider>
                )}
              </Authenticator>
            );
          }}
        />
        <Redirect from={`/`} to="/banner/pages/landing" />
      </Switch>
    </UserProvider>
  </Router>,
  document.getElementById("root")
);
