import {inCubeMmCube, inMm, inSquareMmSquare, ozGr, ppsiToPbar} from "../../../../../hooks/useUomTransform";

export const dataOutputs1 = data => {
  return [
    {
      label: "Presión de Disparo al Aire",
      value: data?.pressAirShotPpsi,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "separated",
    },
    {
      label: "Presión al Corredor",
      value: data?.pressToRunnerPpsi,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "separated",
    },
    {
      label: "Presión a la Compuerta",
      value: data?.pressToGatePpsi,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "separated",
    },
    {
      label: "Presión Entrada de Cavidad",
      value: data?.pressToPostGatePpsi,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "separated",
    },
    {
      label: "Presión al Final de la Primera Etapa",
      value: data?.pressTo1stStageEndPpsi,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "separated",
    },
    {
      label: "Máxima Presión Plástica",
      value: data?.maxPlasticPressure,
      type: "separated",
      addOn: ppsiToPbar(data?.header?.uom)
    },
  ];
}

export const dataOutputs2 = data => {
  return [
    {
      label: "Barril a Nariz",
      value: data?.pressLostBarrelToNozzle,
      type: "separated",
      addOn: ppsiToPbar(data?.header?.uom),
    },
    {
      label: "Nariz a Corredor",
      value: data?.pressLostNozzleToRunner,
      type: "separated",
      addOn: ppsiToPbar(data?.header?.uom),
    },
    {
      label: "Corredor a Compuerta",
      value: data?.pressLostRunnerToGate,
      type: "separated",
      addOn: ppsiToPbar(data?.header?.uom),
    },
    {
      label: "Compuerta a Entrada",
      value: data?.pressLostGateToPostGate,
      type: "separated",
      addOn: ppsiToPbar(data?.header?.uom),
    },
    {
      label: "Entrada a Fin de Primera Etapa",
      value: data?.pressLostPostGateToEnd,
      type: "separated",
      addOn: ppsiToPbar(data?.header?.uom),
    },
  ];
}