import {post} from "aws-amplify/api";
import {useState} from "react";

export const useRunnerHooks = (data, setData, setIsLoading) => {

  const [runnerErrors, setRunnerErrors] = useState([])
  const addRunner = () => {
    const newRunners = [
      ...data.runners,
      { form: "", diameter: "", cavities: "", base: "", height: "" },
    ];
    setData({ ...data, runners: newRunners });
  };

  const removeRunner = () => {
    const newRunners = data.runners.slice(0, -1);
    setData({ ...data, runners: newRunners });
  };

  const isLastRunnerComplete = () => {
    if (!data.runners || data.runners.length === 0) return false;

    const lastRunner = data.runners[data.runners.length - 1];
    if (!lastRunner.form) return false;

    if (lastRunner.form === "CIRCULO") {
      return lastRunner.diameter !== "" && lastRunner.cavities !== "";
    }
    return (
      lastRunner.base !== "" &&
      lastRunner.height !== "" &&
      lastRunner.cavities !== ""
    );
  };
  const finishRunners = async () => {
    setIsLoading(true);

    const restOperation = post({
      apiName: "projectedAreaNew",
      path: "/projected-area",
      options: {
        body: data.runners,
      },
    });

    const rest = await restOperation.response;
    const body = await rest.body.json();
    const b = body.body;

    const { area } = b;
    setData({...data, runnerArea: area.toString()})
    setIsLoading(false)
  };

  const handleInputRunnerChange = (index, field, value) => {
    const isValidFloat = (value) => /^\d*\.?\d*$/.test(value); // Permitir cadena vacía y números flotantes
    const isValidInteger = (value) => /^\d*$/.test(value); // Permitir cadena vacía y números enteros

    let isValid = true;
    if (field === "cavities") {
      isValid = isValidInteger(value);
    } else if (field !== "form") {
      isValid = isValidFloat(value);
    }

    if (isValid) {
      const updatedRunners = [...data.runners];
      updatedRunners[index] = { ...updatedRunners[index], [field]: value };
      setData({ ...data, runners: updatedRunners });
    }

    setRunnerErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: isValid ? "" : "Valor no válido",
      };
      return updatedErrors;
    });
  };


  return {
    addRunner,
    removeRunner,
    isLastRunnerComplete,
    finishRunners,
    handleInputRunnerChange,
    setRunnerErrors,
    runnerErrors
  }
}