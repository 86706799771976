import React, { useContext, useEffect } from "react";
import { Button, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import CardBody from "../../components/Card/CardBody";
import { projectColumnsData } from "./utilities/variables";
import ProjectIndexTable from "./tables/ProjectTableIndex";
import ProjectContext from "../../contexts/ProjectContext";
import CustomGrid from "./individual/components/CustomGrid";
import HelmetComponent from "../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";

const MyProyects = () => {
  const { projects, updateProjects } = useContext(ProjectContext);
  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    updateProjects();
  };

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <CustomGrid
        mt={"150px"}
        templateColumns="repeat(1, 1fr)"
        alignItems={"center"}
        justifyItems={"center"}
        gap={6}
        borderWidth={1}
        mb={5}
        p={5}
        borderRadius={15}
      >
        <ProjectIndexTable
          title={"Tabla de mis Proyectos"}
          tableData={projects}
          columnsData={projectColumnsData}
        />
      </CustomGrid>
    </>
  );
};

export default MyProyects;
