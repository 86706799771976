import React, { useContext } from "react";
import { Button, Grid, GridItem } from "@chakra-ui/react";
import { useEnduranceHooks } from "./hooks/useEnduranceHooks";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import EditBase from "../../Cards/EditBase";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { badgeColor, borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";

const EditEnduranceTime = () => {
  const { project, isLoading, handleInputChange } = useContext(ProjectContext);

  const {
    speedSelected,
    best_Hold_Time_Select,
    cooling_Time_Select,
    shotsInBarrel,
    clamp_and_Op_Times,
    protect_Time,
    recovery_Time,
    total_Cycle_Time_Real,
    residence_by_Cycle,
    rotate_Delay_Suggested,
    total_Residence_Time_of_Shot,
  } = project;

  const { setShowData, showData, onSubmitHandler } = useEnduranceHooks();

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <EditBase tittleButton={"Terminar"} title={"Tiempo de Residencia"}>
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            borderColor={borderColor}
            templateColumns="repeat(4, 1fr)"
            gap={4}
            p={5}
            mx={5}
            borderRadius={15}
            borderWidth={1}
          >
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              type={"time"}
              rightAddon={"seg"}
              colSpan={2}
              fontSize={"sm"}
              subType={""}
              fix={5}
              title={"Tiempo de Inyección"}
              value={speedSelected?.inyectionTime}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              type={"time"}
              rightAddon={"seg"}
              colSpan={2}
              fontSize={"sm"}
              subType={""}
              fix={5}
              title={"Tiempo de Sostenimiento"}
              value={best_Hold_Time_Select}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              type={"time"}
              rightAddon={"seg"}
              colSpan={2}
              fontSize={"sm"}
              subType={""}
              fix={5}
              title={"Tiempo de Enfriamiento"}
              value={cooling_Time_Select}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              rightAddon={"shots"}
              colSpan={2}
              fontSize={"sm"}
              subType={""}
              fix={5}
              title={"Capacidad de Disparos"}
              value={shotsInBarrel}
            />
          </Grid>
          <Grid
            mt={5}
            p={5}
            mx={5}
            borderRadius={15}
            borderWidth={1}
            gap={6}
            borderColor={borderColor}
          >
            <Grid
              justifyItems={"center"}
              templateColumns="repeat(3, 1fr)"
              gap={4}
            >
              <InputCustomFieldProjectRigthAddon
                borderColor={borderColor}
                addOn={"seg"}
                color={textColor}
                mx={3}
                label="Tiempo de Prensa y Operación"
                value={clamp_and_Op_Times}
                onChange={handleInputChange}
                id="clamp_and_Op_Times"
              />
              <InputCustomFieldProjectRigthAddon
                addOn={"seg"}
                borderColor={borderColor}
                color={textColor}
                mx={3}
                label="Tiempo de Protección"
                value={protect_Time}
                onChange={handleInputChange}
                id="protect_Time"
              />
              <InputCustomFieldProjectRigthAddon
                addOn={"seg"}
                borderColor={borderColor}
                color={textColor}
                mx={3}
                label="Tiempo de Recuperación"
                value={recovery_Time}
                onChange={handleInputChange}
                id="recovery_Time"
              />
            </Grid>
            <Button
              colorScheme={"facebook"}
              isLoading={isLoading}
              disabled={!clamp_and_Op_Times || !protect_Time || !recovery_Time}
              onClick={
                showData ? () => setShowData(false) : () => onSubmitHandler()
              }
            >
              {showData ? "Editar" : "Guardar"}
            </Button>
          </Grid>
          {showData && (
            <Grid
              mx={5}
              p={5}
              borderWidth={1}
              borderRadius={15}
              mt={5}
              templateColumns="repeat(2, 1fr)"
              templateRows="repeat(2, 1fr)"
              gap={6}
              borderColor={borderColor}
            >
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"time"}
                rightAddon={"seg"}
                fontSize={"sm"}
                subType={""}
                fix={5}
                title={"Tiempo de Ciclo Real"}
                value={total_Cycle_Time_Real}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"time"}
                rightAddon={"seg"}
                fontSize={"sm"}
                subType={""}
                fix={3}
                title={"Tiempo de Retraso de Carga Sugerido"}
                value={rotate_Delay_Suggested}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"time"}
                rightAddon={"seg"}
                fontSize={"sm"}
                subType={""}
                fix={3}
                title={"Tiempo de Residencia por ciclo"}
                value={residence_by_Cycle}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"time"}
                rightAddon={"seg"}
                fontSize={"sm"}
                subType={""}
                fix={3}
                title={"Tiempo de Residencia Total de Disparo"}
                value={total_Residence_Time_of_Shot}
              />
            </Grid>
          )}
        </GridItem>
      </EditBase>
    </>
  );
};

export default EditEnduranceTime;
