import { useContext, useEffect, useRef, useState } from "react";
import ProjectContext from "../../contexts/ProjectContext";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { Project } from "../../models";

export const useGapHooks = () => {
  const isMounted = useRef(true);
  const { project, updateProject, updateLoading } = useContext(ProjectContext);

  const [errors, setErrors] = useState([]);
  const [showGapArea, setShowGapArea] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (project.gaps) {
      setErrors(
        project.gaps.map(() => ({
          form: "",
          diameter: "",
          cavities: "",
          base: "",
          height: "",
        }))
      );
    }
  }, [project.gaps]);

  const customStyles = {
    clearIndicator: (styles) => ({
      ...styles,
      color: "red", // Cambia 'red' por el color que prefieras
    }),
    option: (provided, state) => ({
      ...provided,
      color: "gray.700",
      backgroundColor: state.isSelected ? "gray.200" : "white",
    }),
  };

  const addGap = () => {
    const newGap = [
      ...project.gaps,
      { form: "", diameter: "", cavities: "", base: "", height: "" },
    ];
    updateProject({ ...project, gaps: newGap });
  };

  const removeGap = () => {
    const newGaps = project.gaps.slice(0, -1);
    updateProject({ ...project, gaps: newGaps });
  };

  const isLastGapComplete = () => {
    if (!project.gaps || project.gaps.length === 0) return false;

    const lastGap = project.gaps[project.gaps.length - 1];
    if (!lastGap.form) return false;

    if (lastGap.form === "CIRCULO") {
      return lastGap.diameter !== "" && lastGap.cavities !== "";
    }
    return (
      lastGap.base !== "" && lastGap.height !== "" && lastGap.cavities !== ""
    );
  };

  const handleInputChange = async (index, field, value) => {
    const isValidFloat = (value) => /^\d*\.?\d*$/.test(value); // Permitir cadena vacía y números flotantes
    const isValidInteger = (value) => /^\d*$/.test(value); // Permitir cadena vacía y números enteros

    let isValid = true;
    if (field === "cavities") {
      isValid = isValidInteger(value);
    } else if (field !== "form") {
      isValid = isValidFloat(value);
    }

    if (!value && field === "form") {
      const updatedGaps = project.gaps.map((gap, idx) =>
        idx === index
          ? {
              ...gap,
              form: "",
              cavities: "",
              diameter: "",
              height: "",
              base: "",
            }
          : gap
      );

      // Actualiza el estado del proyecto con los nuevos corredores
      const updatedProject = { ...project, gaps: updatedGaps };

      // Asegúrate de que la operación de actualización del estado se haya completado
      await updateProject(updatedProject);

      const p = await DataStore.query(Project, project.id);
      const result = await DataStore.save(
        Project.copyOf(p, (updated) => {
          updated.gaps = updatedProject.gaps;
          updated.gapArea = null;
          updated.insuranceFactor = "";
          updated.minTonSug = "0";
          updated.nomTonSug = "0";
          updated.maxTonSug = "0";
        })
      );
      updateProject(result);
    } else {
      if (isValid) {
        const updatedGaps = [...project.gaps];
        updatedGaps[index] = { ...updatedGaps[index], [field]: value };
        updateProject({ ...project, gaps: updatedGaps });
      }
    }

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: isValid ? "" : "Valor no válido",
      };
      return updatedErrors;
    });
  };

  const finishGaps = async () => {
    updateLoading(true);
    const restOperation = post({
      apiName: "projectedAreaNew",
      path: "/projected-area",
      options: {
        body: project.gaps,
      },
    });

    const rest = await restOperation.response;
    const body = await rest.body.json();
    const b = body.body;

    const { area } = b;

    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.gaps = project.gaps;
        updated.gapArea = area.toString();
        updated.insuranceFactor = "";
        updated.minTonSug = "0";
        updated.nomTonSug = "0";
        updated.maxTonSug = "0";
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => console.log(err))
      .finally(() => updateLoading(false));
  };

  return {
    customStyles,
    addGap,
    isLastGapComplete,
    removeGap,
    handleInputChange,
    errors,
    finishGaps,
    showGapArea,
    setShowGapArea,
  };
};
