import React from "react";
import { Flex, Grid, Heading, Image, Text } from "@chakra-ui/react";
import mvpsLogo from "./../../assets/img/logos/mvpslogo1.PNG";
import HorizontalDivider from "../../components/dividers/HorizontalDivider";
import {Helmet} from "react-helmet";
function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Términos y Condiciones | MVPS</title>
        <link rel="canonical" href="https://molding-vps.com/banner/pages/terms-and-conditions"/>

        <meta
          name="description"
          content={
            "Consulta los términos y condiciones de uso de MVPS para comprender las reglas y regulaciones que rigen el acceso y uso de nuestros servicios y sitio web."
          }
        />
        <meta
          name="keywords"
          content={
            "términos y condiciones, MVPS, regulaciones de uso, política de privacidad, derechos de usuario, mvps"
          }
        />
      </Helmet>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Grid
          mt={"150px"}
          p={5}
          mx={20}
          borderWidth={1}
          color={"white"}
          borderRadius={15}
          alignItems={"center"}
          justifyItems={"center"}
          opacity={0.9}
        >
          <Heading>MOLDING VALIDATION PROCESS SOLUTIONS</Heading>
          <Image src={mvpsLogo} mt={5} width={"50%"} />
          <Text fontWeight={"bold"} fontSize={"xl"}>
            Somos la solución a tus problemas de moldeo
          </Text>

          <Grid
            borderWidth={1}
            py={5}
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            borderRadius={15}
          >
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Términos y Condiciones
            </Text>
            <br />
            <Text fontSize={"xl"}>Bienvenido a moldingvps.com!</Text>
            <br />
            <Text fontSize={"md"}>
              Estos términos y condiciones describen las reglas y regulaciones
              para el uso del sitio web de MVPS, ubicado en
              https://moldingvps.com.{" "}
            </Text>
            <Text fontSize={"md"}>
              Al marcar y aceptar la casilla de Términos y Condiciones de este
              sitio web, asumimos que acepta estos términos y condiciones. No
              continúe usando moldingvps.com si no acepta todos los términos y
              condiciones establecidos en esta página.
            </Text>
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Cookies
            </Text>
            <Text fontSize={"md"}>
              El sitio web utiliza cookies para ayudar a personalizar su
              experiencia en línea. Al acceder a moldingvps.com, acepta utilizar
              las cookies requeridas.
            </Text>
            <Text fontSize={"md"}>
              Una cookie es un archivo de texto que un servidor de páginas web
              coloca en su disco duro. Las cookies no se pueden utilizar para
              ejecutar programas ni enviar virus a su computadora. Las cookies se
              le asignan exclusivamente a usted y solo pueden ser leídas por un
              servidor web en el dominio que le emitió la cookie.
            </Text>
            <Text fontSize={"md"}>
              Podemos utilizar cookies para recopilar, almacenar y rastrear
              información con fines estadísticos o de marketing para operar
              nuestro sitio web. Tiene la posibilidad de aceptar o rechazar las
              Cookies. Hay algunas cookies requeridas que son necesarias para el
              funcionamiento de nuestro sitio web. Estas cookies no requieren su
              consentimiento ya que siempre funcionan. Tenga en cuenta que al
              aceptar las Cookies requeridas, también acepta las Cookies de
              terceros, que podrían usarse a través de servicios proporcionados
              por terceros si utiliza dichos servicios en nuestro sitio web, por
              ejemplo, una ventana de visualización de video proporcionada por
              terceros e integrada. en nuestro sitio web.
            </Text>

            <Text fontWeight={"bold"} fontSize={"xl"}>
              Licencia
            </Text>
            <Text fontSize={"md"}>
              A menos que se indique lo contrario, MVPS y/o sus licenciantes
              poseen los derechos de propiedad intelectual de todo el material en
              moldingvps.com. Todos los derechos de propiedad intelectual están
              reservados. Puede acceder a esto desde moldingvps.com para su uso
              personal sujeto a las restricciones establecidas en estos términos y
              condiciones.
            </Text>
            <Text fontWeight={"bold"} fontSize={"xl"}>
              No debes
            </Text>
            <li>Copiar o publicar material de moldingvps.com</li>
            <li>Vender, alquilar o sublicenciar material de mouldingvps.com</li>
            <li>Reproducir, duplicar o copiar material de moldingvps.com</li>
            <li>Redistribuir contenido de moldingvps.com</li>
            <li>Este acuerdo comenzará en la presente fecha</li>
            <hr />
            <HorizontalDivider mt={5} />
            <Text fontSize={"md"}>
              Partes de este sitio web ofrecen a los usuarios la oportunidad de
              publicar e intercambiar opiniones e información en determinadas
              áreas del sitio web. MVPS no filtra, edita, publica ni revisa los
              Comentarios antes de su presencia en el sitio web. Los comentarios
              no reflejan los puntos de vista ni las opiniones de MVPS, sus
              agentes y/o afiliados. Los comentarios reflejan los puntos de vista
              y opiniones de la persona que publica sus puntos de vista y
              opiniones. En la medida permitida por las leyes aplicables, MVPS no
              será responsable de los Comentarios ni de ninguna responsabilidad,
              daño o gasto causado y/o sufrido como resultado de cualquier uso,
              publicación y/o aparición de los Comentarios en este sitio web.
            </Text>
            <br />
            <Text fontSize={"md"}>
              MVPS se reserva el derecho de monitorear todos los Comentarios y
              eliminar cualquier Comentario que pueda considerarse inapropiado,
              ofensivo o que infrinja estos Términos y condiciones.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Usted garantiza y declara que
            </Text>
            <li>
              Tiene derecho a publicar Comentarios en nuestro sitio web y tiene
              todas las licencias y consentimientos necesarios para hacerlo
            </li>
            <li>
              Los Comentarios no invaden ningún derecho de propiedad intelectual,
              incluidos, entre otros, derechos de autor, patentes o marcas
              comerciales de terceros
            </li>
            <li>
              Los Comentarios no contienen ningún material difamatorio,
              calumnioso, ofensivo, indecente o ilegal, lo cual constituye una
              invasión de la privacidad
            </li>
            <li>
              Los Comentarios no se utilizarán para solicitar o promover negocios
              o costumbres o presentar actividades comerciales o actividades
              ilegales.
            </li>
            <HorizontalDivider mt={5} />
            <Text fontSize={"md"}>
              Por la presente, otorga a MVPS una licencia no exclusiva para usar,
              reproducir, editar y autorizar a otros a usar, reproducir y editar
              cualquiera de sus Comentarios en todas y cada una de las formas,
              formatos o medio
            </Text>
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Hipervínculos a nuestro contenido:
            </Text>
            <br />
            <Text fontSize={"md"}>
              Las siguientes organizaciones pueden vincularse a nuestro sitio web
              sin aprobación previa por escrito:
            </Text>
            <li>Agencias gubernamentales</li>
            <li>Los motores de búsqueda</li>
            <li>Organizaciones de noticias</li>
            <li>
              Los distribuidores de directorios en línea pueden vincularse a
              nuestro sitio web de la misma manera que lo hacen a los sitios web
              de otras empresas enumeradas
            </li>

            <li>
              Empresas acreditadas en todo el sistema, excepto las que solicitan
              organizaciones sin fines de lucro, centros comerciales benéficos y
              grupos de recaudación de fondos benéficos que no pueden tener
              hipervínculos a nuestro sitio web
            </li>
            <Text fontSize={"sm"} fontStyle={"italic"}>
              Estas organizaciones pueden vincularse a nuestra página de inicio, a
              publicaciones u otra información del sitio web siempre que el
              vínculo: (a) no sea de ninguna manera engañoso; (b) no implica
              falsamente patrocinio, respaldo o aprobación de la parte vinculadora
              y sus productos y/o servicios; y (c) se ajusta al contexto del sitio
              de la parte vinculadora.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Podemos considerar y aprobar otras solicitudes de enlaces de los
              siguientes tipos de organizaciones
            </Text>
            <br />
            <li>
              Fuentes de información comúnmente conocidas sobre consumidores y/o
              empresas; Sitios comunitarios ejemplo.com
            </li>
            <li>
              Asociaciones u otros grupos que representan organizaciones benéficas
            </li>
            <li>Distribuidores de directorios en línea</li>
            <li>Portales de Internet</li>
            <li>Firmas de contabilidad, derecho y consultoría</li>
            <li>Instituciones educativas y asociaciones comerciales</li>
            <br />
            <Text fontSize={"sm"} fontStyle={"italic"}>
              Aprobaremos las solicitudes de enlace de estas organizaciones si
              decidimos que: (a) el enlace no nos hará vernos desfavorablemente a
              nosotros mismos o a nuestras empresas acreditadas; (b) la
              organización no tiene ningún registro negativo con nosotros; (c) el
              beneficio para nosotros de la visibilidad del hipervínculo compensa
              la ausencia de MVPS; y (d) el enlace está en el contexto de
              información de recursos generales
            </Text>
            <Text fontSize={"sm"} fontStyle={"italic"}>
              Estas organizaciones pueden vincularse a nuestra página de inicio
              siempre que el vínculo: (a) no sea de ninguna manera engañoso; (b)
              no implica falsamente patrocinio, respaldo o aprobación de la parte
              vinculadora y sus productos o servicios; y (c) se ajusta al contexto
              del sitio de la parte vinculadora
            </Text>
            <Text fontSize={"sm"} fontStyle={"italic"}>
              Si usted es una de las organizaciones enumeradas en el párrafo 2
              anterior y está interesado en vincularse a nuestro sitio web, debe
              informarnos enviando un correo electrónico a
              contacto@moldingvps.com. Incluya su nombre, el nombre de su
              organización, información de contacto, así como la URL de su sitio,
              una lista de las URL desde las que desea vincular a nuestro sitio
              web y una lista de las URL de nuestro sitio a las que le gustaría
              acceder. enlace. Espere de 2 a 5 días naturales para recibir una
              respuesta.
            </Text>
            <Text fontWeight={"bold"} fontSize={"sm"}>
              Las organizaciones aprobadas pueden hacer hipervínculos a nuestro
              sitio web de la siguiente manera
            </Text>
            <li>Por el uso de nuestro nombre corporativo</li>
            <li>
              Mediante el uso del localizador uniforme de recursos al que se
              vincula
            </li>
            <li>
              Usar cualquier otra descripción de nuestro sitio web al que se
              vincula que tenga sentido dentro del contexto y formato del
              contenido del sitio de la parte vinculadora.{" "}
            </li>
            <HorizontalDivider mt={5} />
            <Text fontWeight={"bold"} fontSize={"md"}>
              No se permitirá el uso del logotipo de MVPS ni de otras
              ilustraciones para vincular sin un acuerdo de licencia de marca
              registrada.
            </Text>
            <br />
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Responsabilidad de Contenido
            </Text>
            <Text fontSize={"md"}>
              No seremos responsables de ningún contenido que aparezca en su sitio
              web. Usted acepta protegernos y defendernos contra todos los
              reclamos que se planteen en su sitio web. No deben aparecer enlaces
              en ningún sitio web que puedan interpretarse como difamatorios,
              obscenos o criminales, o que infrinjan, violen de otro modo o
              defiendan la infracción u otra violación de derechos de terceros.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Reserva de Derechos
            </Text>
            <Text fontSize={"md"}>
              Nos reservamos el derecho de solicitarle que elimine todos los
              enlaces o cualquier enlace en particular a nuestro sitio web. Usted
              aprueba eliminar inmediatamente todos los enlaces a nuestro sitio
              web si así lo solicita. También nos reservamos el derecho de
              modificar estos términos y condiciones y su política de vinculación
              en cualquier momento. Al vincularse continuamente a nuestro sitio
              web, usted acepta estar sujeto y seguir estos términos y condiciones
              de vinculación.
            </Text>
            <Text fontSize={"md"}>
              Eliminación de enlaces de nuestro sitio web: Si encuentra algún
              enlace en nuestro sitio web que sea ofensivo por cualquier motivo,
              puede contactarnos e informarnos en cualquier momento.
              Consideraremos las solicitudes para eliminar enlaces, pero no
              estamos obligados a hacerlo ni a responderle directamente.
            </Text>
            <Text fontSize={"md"}>
              No garantizamos que la información contenida en este sitio web sea
              correcta. No garantizamos su integridad o exactitud, ni prometemos
              garantizar que el sitio web permanezca disponible o que el material
              del sitio web se mantenga actualizado.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontSize={"xl"} fontWeight={"bold"}>
              Disclaimer
            </Text>
            <br />
            <Text fontSize={"md"}>
              En la medida máxima permitida por la ley aplicable, excluimos todas
              las representaciones, garantías y condiciones relacionadas con
              nuestro sitio web y el uso de este sitio web. Nada en este descargo
              de responsabilidad:
            </Text>
            <li>
              Limitar o excluir nuestra o su responsabilidad por muerte o lesiones
              personales
            </li>
            <li>
              Limitar o excluir nuestra responsabilidad o la suya por fraude o
              tergiversación fraudulenta
            </li>
            <li>
              Limitar cualquiera de nuestras responsabilidades o las suyas de
              cualquier forma que no esté permitida por la ley aplicable
            </li>
            <li>
              Excluir cualquiera de nuestras responsabilidades o las suyas que no
              puedan estar excluidas según la ley aplicable
            </li>
            <br />
            <Text fontStyle={"italic"} fontSize={"sm"}>
              Las limitaciones y prohibiciones de responsabilidad establecidas en
              esta Sección y en otras partes de este descargo de responsabilidad:
              (a) están sujetas al párrafo anterior; y (b) regir todas las
              responsabilidades que surjan bajo la exención de responsabilidad,
              incluidas las responsabilidades que surjan en el contrato, en
              agravio y por incumplimiento del deber legal.
            </Text>
            <Text fontStyle={"italic"} fontSize={"sm"}>
              Siempre que el sitio web y la información y los servicios del sitio
              web se proporcionen de forma gratuita, no seremos responsables de
              ninguna pérdida o daño de cualquier naturaleza.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Política de Privacidad{" "}
            </Text>
            <Text fontSize={"md"}>
              www.moldingvps.com El sitio web es propiedad de MVPS, que es un
              controlador de datos de sus datos personales. Hemos adoptado esta
              política de privacidad, que determina cómo estamos procesando la
              información recopilada por www.moldingvps.com, que también
              proporciona las razones por las que debemos recopilar ciertos datos
              personales sobre usted. Por lo tanto, debe leer esta política de
              privacidad antes de usar el sitio web www.moldingvps.com. Nos
              ocupamos de sus datos personales y nos comprometemos a garantizar su
              confidencialidad y seguridad.
            </Text>
            <Text fontSize={"md"} fontWeight={"bold"}>
              Información personal que recopilamos
            </Text>
            <Text fontSize={"md"}>
              Cuando visita www.moldingvps.com, recopilamos automáticamente cierta
              información sobre su dispositivo, incluida información sobre su
              navegador web, dirección IP, zona horaria y algunas de las cookies
              instaladas en su dispositivo. Además, a medida que navega por el
              sitio, recopilamos información sobre las páginas o productos web
              individuales que ve, qué sitios web o términos de búsqueda lo
              remitieron al Sitio y cómo interactúa con el Sitio. Nos referimos a
              esta información recolectada automáticamente como "información del
              dispositivo". Además, podríamos recopilar los datos personales que
              nos proporciona (incluidos, entre otros, el nombre, el apellido, la
              dirección, la información de pago, etc.) durante el registro para
              poder cumplir con el acuerdo.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontSize={"xl"} fontWeight={"bold"}>
              Por qué procesamos sus datos?
            </Text>
            <Text fontSize={"md"}>
              Nuestra máxima prioridad es la seguridad de los datos del cliente y,
              como tal, podemos procesar solo datos de usuario mínimos, solo tanto
              como es absolutamente necesario mantener el sitio web. La
              información recopilada automáticamente se utiliza solo para
              identificar posibles casos de abuso y establecer información
              estadística sobre el uso del sitio web. Esta información estadística
              no se agrega de tal manera que identifique a cualquier usuario en
              particular del sistema.
            </Text>
            <Text fontSize={"md"}>
              Puede visitar el sitio web sin decirnos quién es o revelar cualquier
              información, por la cual alguien podría identificarlo como un
              individuo específico e identificable. Sin embargo, si desea utilizar
              algunas de las funciones del sitio web, o desea recibir nuestro
              boletín o proporcionar otros detalles al completar un formulario,
              puede proporcionarnos datos personales, como su correo electrónico,
              primer nombre, apellido, ciudad. de residencia, organización, número
              de teléfono. Puede elegir no proporcionarnos sus datos personales,
              pero es posible que no pueda aprovechar algunas de las funciones del
              sitio web. Por ejemplo, no podrá recibir nuestro boletín o
              contactarnos directamente desde el sitio web. Los usuarios que no
              están seguros sobre qué información es obligatoria puede
              contactarnos a través de contacto@moldingvps.com.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontSize={"xl"} fontWeight={"bold"}>
              Enlaces a otros sitios web
            </Text>
            <Text fontSize={"md"}>
              Nuestro sitio web puede contener enlaces a otros sitios web que no
              son propiedad o controlados por nosotros. Tenga en cuenta que no
              somos responsables de dichos otros sitios web o prácticas de
              privacidad de terceros. Le recomendamos que tenga en cuenta cuando
              salga de nuestro sitio web y lea las declaraciones de privacidad de
              cada sitio web que pueden recopilar información personal.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontSize={"xl"} fontWeight={"bold"}>
              Seguridad de información
            </Text>
            <Text fontSize={"md"}>
              Aseguramos la información que proporciona en servidores de
              computadora en un entorno controlado y seguro, protegido del acceso,
              uso o divulgación no autorizados. Mantenemos salvaguardas
              administrativas, técnicas y físicas razonables para proteger contra
              el acceso no autorizado, el uso, la modificación y la divulgación de
              datos personales en su control y custodia. Sin embargo, no se puede
              garantizar la transmisión de datos a través de Internet o la red
              inalámbrica.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontSize={"xl"} fontWeight={"bold"}>
              Divulgación legal
            </Text>
            <Text fontSize={"md"}>
              Discaltaremos cualquier información que recopilemos, usemos o
              recibamos si lo requiere o lo permite la ley, como para cumplir con
              una citación o un proceso legal similar, y cuando creemos de buena
              fe que la divulgación es necesaria para proteger nuestros derechos,
              proteger su seguridad o La seguridad de los demás, investiga fraude
              o responde a una solicitud del gobierno.
            </Text>
            <HorizontalDivider mt={5} />
            <Text fontSize={"xl"} fontWeight={"bold"}>
              Información del contacto
            </Text>
            <Text fontSize={"md"}>
              Si desea contactarnos para comprender más sobre esta política o
              desea contactarnos con respecto a cualquier asunto relacionado con
              los derechos individuales y su información personal, puede enviar un
              correo electrónico a contacto@moldingvps.com.
            </Text>
            <HorizontalDivider mt={5} />
          </Grid>
        </Grid>
      </Flex>
    </>

  );
}

export default TermsAndConditions;
