import {hpsiToHbar, inMm, ozGr, ppsiToPbar, temp} from "../../../../../hooks/useUomTransform";

export const dataEntries1 = (data) => {
  return [
    {
      label: "Razón de Intensificación",
      value: data?.intRatio,
      type: "int",
      addOn: ":1"
    },
    {
      label: "Máxima Presión Hidráulica",
      value: data?.immMaxHPress,
      type: "separated",
      addOn: hpsiToHbar(data?.header?.uom)
    },
    {
      label: "Presión de Disparo al Aire",
      value: data?.pressAirShot,
      type: "separated",
      addOn: hpsiToHbar(data?.header?.uom)
    },
    {
      label: "Presión a la Compuerta",
      value: data?.pressToGate,
      type: "separated",
      addOn: hpsiToHbar(data?.header?.uom)
    },

  ]
}

export const dataEntries2 = data => {
  return [
    {
      label: "Temperatura Real de Masa",
      value: data?.realMeltTemp,
      type: "temp",
      addOn: temp(data?.header?.uom)
    },

    {
      label: "Presión al Corredor",
      value: data?.pressToRunner,
      type: "separated",
      addOn: hpsiToHbar(data?.header?.uom)
    },
    {
      label: "Presión Entrada de Cavidad",
      value: data?.pressToPostGate,
      type: "separated",
      addOn: hpsiToHbar(data?.header?.uom)
    },
    {
      label: "Presión al Final de la Primera Etapa",
      value: data?.pressTo1stStageEnd,
      type: "separated",
      addOn: hpsiToHbar(data?.header?.uom)
    },
  ]
}