import {hpsiToHbar, inMm, inSecMmsec, lbKg, ozGr, ppsiToPbar, temp} from "../../../hooks/useUomTransform";

export const inputData = data => {
  const {uom} = data?.header
  return [
    {
      label: "Razón de Intensificación",
      value: data?.intRatio,
      id: "intRatio",
      addOn: ":1",
      error: parseFloat(data?.intRatio) < 1 && "El valor debe de ser mayor a 1"
    },
    {
      label: "Temperatura Real de Masa",
      value: data?.realMeltTemp,
      id: "realMeltTemp",
      addOn: temp(uom),
      error: parseFloat(data?.realMeltTemp) <= 0 && "El valor debe de ser mayor a 0."
    },
    {
      label: "Máxima Presión Hidráulica",
      value: data?.immMaxHPress,
      id: "immMaxHPress",
      addOn: hpsiToHbar(uom),
      error: parseFloat(data?.immMaxHPress) <= 0 && "El valor debe de ser mayor a 0."
    },
    {
      label: "Velocidad de Inyección Test ",
      value: data?.injectionSpeedTest,
      id: "injectionSpeedTest",
      addOn: inSecMmsec(uom),
      error: parseFloat(data?.injectionSpeedTest) <= 0 && "El valor debe de ser mayor a 0."
    },
    {
      label: "Presión de Disparo al Aire",
      value: data?.pressAirShot,
      id: "pressAirShot",
      addOn: hpsiToHbar(uom),
      error: parseFloat(data?.pressAirShot) <= 0 && "El valor debe de ser mayor a 0."
    },
    {
      label: "Presión al Corredor",
      value: data?.pressToRunner,
      id: "pressToRunner",
      addOn: hpsiToHbar(uom),
      error: parseFloat(data?.pressToRunner) <= parseFloat(data?.pressAirShot) && "El valor debe de ser mayor a la Presión de Disparo al Aire."
    },
    {
      label: "Presión a la Compuerta",
      value: data?.pressToGate,
      id: "pressToGate",
      addOn: hpsiToHbar(uom),
      error: parseFloat(data?.pressToGate) <= parseFloat(data?.pressToRunner) && "El valor debe de ser mayor a la Presión al Corredor."

    },
    {
      label: "Presión Entrada de Cavidad",
      value: data?.pressToPostGate,
      id: "pressToPostGate",
      addOn: hpsiToHbar(uom),
      error: parseFloat(data?.pressToPostGate) <= parseFloat(data?.pressToGate) && "El valor debe de ser mayor a la Presión a la Compuerta."

    },
    {
      label: "Presión al Final de la Primera Etapa",
      value: data?.pressTo1stStageEnd,
      id: "pressTo1stStageEnd",
      addOn: hpsiToHbar(uom),
      error: parseFloat(data?.pressTo1stStageEnd) <= parseFloat(data?.pressToPostGate) && "El valor debe de ser mayor a la Presión Entrada de Cavidad."

    },
  ]
}

