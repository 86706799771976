import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import {
  areAllFloats,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { Button, Grid, GridItem } from "@chakra-ui/react";
import ShotSizeForm from "./components/ShotSizeForm";
import ShotSizeChart from "./components/ShotSizeChart";
import ShotSizeData from "./components/ShotSizeData";
import { useShotSizeHooks } from "../../../../../../hooks/shot-size/useShotSizeHooks";
import { borderColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";

const EditShotSize = () => {
  const { id } = useParams();
  const { project, updateProject, isLoading, onFinish } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    showChart,
    setShowChart,
    onSubmitHandler,
    barChartOptions2,
    barChartData2,
    barChartOptions,
    barChartData,
  } = useShotSizeHooks();

  const {
    cutOffPositionPercentage_95_98,
    cushionPositionPercentage,
    decompressionSize,
  } = project;

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        keywords={metaKeywords}
        title={metaTitle}
      />
      <EditBase
        disabled={!showChart}
        isLoading={isLoading}
        onFinish={onFinish}
        title={"Tamaño de Disparo"}
      >
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={6}
            borderColor={borderColor}
          >
            <ShotSizeForm showChart={showChart} />
            <Grid
              borderColor={borderColor}
              p={5}
              borderWidth={1}
              borderRadius={15}
              templateColumns="repeat(1, 1fr)"
              gap={6}
            >
              <Button
                colorScheme={"facebook"}
                isLoading={isLoading}
                onClick={
                  showChart
                    ? () => setShowChart(false)
                    : () => onSubmitHandler()
                }
                disabled={
                  !areAllFloats([
                    cushionPositionPercentage,
                    decompressionSize,
                    cutOffPositionPercentage_95_98,
                  ]) || isLoading
                }
              >
                {showChart ? "Editar" : "Calcular y Graficar"}
              </Button>
              {showChart && (
                <ShotSizeChart
                  barChartData={barChartData}
                  barChartData2={barChartData2}
                  barChartOptions={barChartOptions}
                  barChartOptions2={barChartOptions2}
                />
              )}
            </Grid>
          </Grid>
        </GridItem>

        {showChart && (
          <GridItem colSpan={4}>
            <ShotSizeData />
          </GridItem>
        )}
      </EditBase>
    </>
  );
};
export default EditShotSize;
