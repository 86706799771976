export const geaInitialState = {
  geaUserID: "",
  phase: 1,
  geaCompany: "",
  geaUdM: "",
  geadate: "",
  geaProduct: "",
  geaPartWeight: "",
  geaMaterial: "",
  geaRegrindPercent: "",
  geaSampleSize: "",
  geaEinarPercent: "",
  geaOtherAdditives: "",
  geaCavities: "",
  //second
  geaVirginResin: "",
  geaVirginPartWeight: "",
  geaVirginLotWeight: "",
  geaRegrindResin: "",
  geaRegrindPartWeight: "",
  geaRegrindLotWeight: "",
  geaAdditivePartWeight: "",
  geaAdditiveLotWeight: "",
  geaEinarPartWeight: "",
  geaEinarLotWeight: "",
  totalPerPiece: "",
  totalPerLote: "",
  total: "",
  // third
  geaRearBefore: "",
  geaRearAfter: "",
  geaRearDelta: "",
  geaCenterBefore: "",
  geaCenterAfter: "",
  geaCenterDelta: "",
  geaFrontBefore: "",
  geaFrontAfter: "",
  geaFrontDelta: "",
  geaNozzleBefore: "",
  geaNozzleAfter: "",
  geaNozzleDelta: "",
  geaShotSizeBefore: "",
  geaShotSizeAfter: "",
  geaShotSizeDelta: "",
  geaTransferBefore: "",
  geaTransferAfter: "",
  geaTransferDelta: "",
  geaRpmBefore: "",
  geaRpmAfter: "",
  geaRpmDelta: "",
  geaBackpressBefore: "",
  geaBackpressAfter: "",
  geaBackpressDelta: "",
  geaInjSpeedBefore: "",
  geaInjSpeedAfter: "",
  geaInjSpeedDelta: "",
  geaInjPressBefore: "",
  geaInjPressAfter: "",
  geaInjPressDelta: "",
  geaTempMeltBefore: "",
  geaTempMeltAfter: "",
  geaTempMeltDelta: "",
  geaCushionBefore: "",
  geaCushionAfter: "",
  geaCushionDelta: "",
  geaMaxPressBefore: "",
  geaMaxPressAfter: "",
  geaMaxPressDelta: "",
  geaRecoveryTimeBefore: "",
  geaRecoveryTimeAfter: "",
  geaRecoveryTimeDelta: "",
  geaFillTimeBefore: "",
  geaFillTimeAfter: "",
  geaFillTimeDelta: "",
  geaCycleTimeBefore: "",
  geaCycleTimeAfter: "",
  geaCycleTimeDelta: "",
  geaRpmAmpBefore: "",
  geaRpmAmpAfter: "",
  geaRpmAmpDelta: "",
  geaInjectionAmpBefore: "",
  geaInjectionAmpAfter: "",
  geaInjectionAmpDelta: "",
  geaShotWeightBefore: "",
  geaShotWeightAfter: "",
  geaShotWeightDelta: "",
  geaMoldReleaseBefore: "MALA",
  geaMoldReleaseAfter: "REGULAR",
  geaMoldReleaseDelta: "",
  geaAntiDustBefore: "MALA",
  geaAntiDustAfter: "REGULAR",
  geaAntiDustDelta: "",
  geaAntiStaticBefore: "MALA",
  geaAntiStaticAfter: "REGULAR",
  geaAntiStaticDelta: "",
  geaDenestingBefore: "MALA",
  geaDenestingAfter: "REGULAR",
  geaDenestingDelta: "",
  analisisComments: "",

  // fourth
  currency: "MXN",
  exchange: "",
  geaVirginResinCost: "",
  geaRegrindPartWeight_2: "",
  geaRegrindResinCost: "",
  geaOtherAdditivesCost: "",
  geaEinarPercentCost: "",
  totalCost: "",
  totalCostWithOutEinar: "",
  totalCostWithEinar: "",
  MoldReleaseCost: "",
  ProdHourCost: "",
  kwCost: "",
  geaPartCost: "",
  geaPartCostEinar: "",
  geaPartCostDelta: "",
  geaProdTimeBefore: "",
  geaProdTimeAfter: "",
  geaProdTimeDelta: "",
  geaMoldReleaseQtyBefore: "",
  geaMoldReleaseQtyAfter: "",
  geaMoldReleaseQtyDelta: "",
  geaDownTimeBefore: "",
  geaDownTimeAfter: "",
  geaDownTimeDelta: "",
  geaScrapBefore: "",
  geaScrapAfter: "",
  geaScrapDelta: "",
  geaKwCostBefore: "",
  geaKwCostAfter: "",
  geaKwCostDelta: "",
  geaTotalCostDelta: "",
  finalComments: "",
  // last
  geaPoints: "",
  cycleTimeOptimization: "",
  prodTimeOptimization: "",
  pieceSaveCost: "",
  lotSaveCost: "",
  investmentRequired: "",
  costPerKg: "",
  capitalRecovery: "",

  // Uom
  addUomTemp: "F",
  addUomLong: "in",
  addUomPress: "bar",
  addUomRotationSpeed: "RPM",
  addUomSpeed: "in/seg",
};
