import { Grid } from "@chakra-ui/react";
import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../utilities/variables";
import {
  hpsiToHbar,
  ppsiToPbar,
  temp,
} from "../../../../../individual/hooks/useUomTransform";

const PressureLossData = () => {
  const { project, machine } = useContext(ProjectContext);
  const {
    intRatio,
    realMeltTemp,
    maxPlasticPressure,
    pr_Air_Shot_Ppsi,
    pr_Press_to_Runner_Ppsi,
    pr_Press_to_Gate_Ppsi,
    pr_Press_to_PostGate_Ppsi,
    pr_Press_to_1st_Stage_End_Ppsi,
    pressLost_Barrel_to_Nozzle,
    pressLost_Nozzle_to_Runner,
    pressLost_Runner_to_Gate,
    pressLost_Gate_to_PostGate,
    pressLost_PostGate_to_End,
    loss_Pressure_Preformance,
  } = project;

  const { maxHydraulicPressure } = machine;

  return (
    <Grid
      p={5}
      borderWidth={1}
      borderRadius={15}
      gap={6}
      mt={5}
      borderColor={borderColor}
    >
      <Grid templateColumns="repeat(3, 4fr)" gap={6}>
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={":1"}
          type={"temp"}
          size={"sm"}
          fix={2}
          title={"Razon de Intensificación"}
          value={intRatio}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"temp"}
          rightAddon={temp(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Temperatura de Masa Ideal"}
          value={realMeltTemp}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"pressure"}
          rightAddon={hpsiToHbar(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Máxima Presión de la Máquina"}
          value={maxHydraulicPressure}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Presión Plastica Máxima"}
          value={maxPlasticPressure}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            parseInt(project?.maxPlasticPressure) <
            parseInt(project?.pr_Air_Shot_Ppsi)
              ? "red"
              : parseInt(project?.maxPlasticPressure) ===
                parseInt(project?.pr_Air_Shot_Ppsi)
              ? "orange"
              : badgeColor
          }
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Disparo al Aire"}
          value={pr_Air_Shot_Ppsi}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            parseInt(project?.maxPlasticPressure) <
            parseInt(project?.pr_Press_to_Runner_Ppsi)
              ? "red"
              : parseInt(project?.maxPlasticPressure) ===
                parseInt(project?.pr_Press_to_Runner_Ppsi)
              ? "orange"
              : badgeColor
          }
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Presión al Runner"}
          value={pr_Press_to_Runner_Ppsi}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            parseInt(project?.maxPlasticPressure) <
            parseInt(project?.pr_Press_to_Gate_Ppsi)
              ? "red"
              : parseInt(project?.maxPlasticPressure) ===
                parseInt(project?.pr_Press_to_Gate_Ppsi)
              ? "orange"
              : badgeColor
          }
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Presión a la Compuerta"}
          value={pr_Press_to_Gate_Ppsi}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            parseInt(project?.maxPlasticPressure) <
            parseInt(project?.pr_Press_to_PostGate_Ppsi)
              ? "red"
              : parseInt(project?.maxPlasticPressure) ===
                parseInt(project?.pr_Press_to_PostGate_Ppsi)
              ? "orange"
              : badgeColor
          }
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Presión Post-Gate"}
          value={pr_Press_to_PostGate_Ppsi}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            parseInt(project?.maxPlasticPressure) <
            parseInt(project?.pr_Press_to_1st_Stage_End_Ppsi)
              ? "red"
              : parseInt(project?.maxPlasticPressure) ===
                parseInt(project?.pr_Press_to_1st_Stage_End_Ppsi)
              ? "orange"
              : badgeColor
          }
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Presión Final de Primera Etapa"}
          value={pr_Press_to_1st_Stage_End_Ppsi}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Barril a Nariz"}
          value={pressLost_Barrel_to_Nozzle}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Nariz a Runner"}
          value={pressLost_Nozzle_to_Runner}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Runner a Gate"}
          value={pressLost_Runner_to_Gate}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Gate a Post-Gate"}
          value={pressLost_Gate_to_PostGate}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ppsiToPbar(project?.uom)}
          type={"pressure"}
          size={"sm"}
          fix={2}
          title={"Post-Gate a Final"}
          value={pressLost_PostGate_to_End}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"%"}
          type={"percentaje"}
          size={"sm"}
          title={"Utilización de la Máquina"}
          value={loss_Pressure_Preformance}
        />
      </Grid>
    </Grid>
  );
};

export default PressureLossData;
