import { Box, Flex } from "@chakra-ui/react";
import LineChart from "../../../../../../../components/Charts/LineChart";
import React from "react";

const PressureLossChart = ({
  lineChartOptionsPressureLoss,
  lineChartDataPressureLoss,
}) => {
  return (
    <Flex color={"white"} direction={"column"} width={"100%"} minHeight={"300px"}>
      <Box width="100%" minH={{ sm: "300px" }}>
        <LineChart
          chartOptions={lineChartOptionsPressureLoss}
          chartData={lineChartDataPressureLoss}
        />
      </Box>
    </Flex>
  );
};

export default PressureLossChart;
