import { createContext, useState } from "react";
const UserContext = createContext([]);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState([]);

  const updateUser = (user) => {
    setUser(user);
  };


  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext as default };
