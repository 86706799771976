import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Tag,
  Text,
  Image,
} from "@chakra-ui/react";

import imageSrc from "./../../../assets/img/collaborators/tp/tcplFooter.png";

import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { DataStore } from "aws-amplify/datastore";
import { SubscriptionPlans } from "../../../models";
import { Divider } from "@aws-amplify/ui-react";
import { useHistory, useLocation } from "react-router-dom";
import HelmetComponent from "../../../components/HelmetComponent";
import { metaDescription, metaKeywords, metaTitle } from "./metaVariables";

function Pricing() {
  const bgTimesIcon = "#424563";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
  const [tecnicas, setTecnicas] = useState(false);
  const [planNames, setPlanNames] = useState("BASICO");

  const handlePlanChange = (planId) => {
    const plan = planes.find((p) => p.id === planId);
    setSelectedPlanId(planId);
    setSelectedPlanDetails({
      monthlyCost: plan.price / plan.duration,
      totalCost: plan.price,
      description: plan.description,
    });
  };

  const location = useLocation();

  useEffect(() => {
    const pathContainsTecnicasPlasticas = location.pathname.includes(
      "tecnicas-plasticas"
    );
    if (pathContainsTecnicasPlasticas) {
      setPlanNames("TECNICAS_PLASTICAS");
    }

    setTecnicas(pathContainsTecnicasPlasticas);
  }, [location]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [basicPlans, setBasicPlans] = useState([]);
  const [proPlans, setProPlans] = useState([]);

  const [planes, setPlanes] = useState([]);

  const obtenerPlanes = async () => {
    try {
      if (planNames === "BASICO") {
        const planesBasicos = await DataStore.query(SubscriptionPlans, (c) =>
          c.plan.eq("BASICO")
        );
        const planesPro = await DataStore.query(SubscriptionPlans, (c) =>
          c.plan.eq("AVANZADO")
        );
        setPlanes([...planesBasicos, ...planesPro]);
      } else {
        const planesBasicos = await DataStore.query(SubscriptionPlans, (c) =>
          c.plan.eq("BASICO")
        );
        setPlanes([...planesBasicos]);
      }
    } catch (error) {
      console.error("Error al obtener los planes:", error);
    }
  };

  useEffect(() => {
    obtenerPlanes();
  }, [tecnicas]);

  const selectPlanType = (type) => {
    setPlanNames(type);
    openModal(); // Asegúrate de abrir la modal después de establecer el tipo
  };

  const basePricePerMonth = 99;
  const basePricePerMonthPro = 139;

  const history = useHistory();
  const m = !tecnicas ? "BASICO" : "TECNICAS_PLASTICAS";
  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Modal isOpen={isModalOpen} onClose={closeModal} size={"xl"}>
          <ModalOverlay />
          <ModalContent borderRadius="15px" maxW="900px">
            <ModalHeader>Subscripcion a la plataforma MVPS</ModalHeader>
            <ModalBody pb={6}>
              <Text fontSize="sm" mb={4}>
                Elige un período de facturación y finaliza el proceso de compra
              </Text>
              <RadioGroup onChange={handlePlanChange} value={selectedPlanId}>
                <Stack spacing={4}>
                  {planes
                    .filter((plan) => plan.plan === planNames)
                    .map((plan) => {
                      const monthlyCost = plan.price / plan.duration;
                      const discount =
                        ((basePricePerMonth - monthlyCost) /
                          basePricePerMonth) *
                        100;
                      const proDiscount =
                        ((basePricePerMonthPro - monthlyCost) /
                          basePricePerMonthPro) *
                        100;
                      const totalCost = monthlyCost * plan.duration; // Calcular el costo total

                      return (
                        <Box
                          key={plan.id}
                          p={4}
                          borderWidth="1px"
                          borderRadius="lg"
                          boxShadow="base"
                        >
                          <Radio value={plan.id} size="lg">
                            <Grid
                              templateColumns="repeat(3, 1fr)"
                              gap={6}
                              justifyItems={"center"}
                              w={"100%"}
                            >
                              <Text fontWeight="bold">
                                {plan.duration}{" "}
                                {plan.duration === 1 ? "mes" : "meses"}
                              </Text>
                              {/* Texto tachado para Precio Normal */}
                              <Text
                                fontStyle={"italic"}
                                textDecoration="line-through"
                              >
                                Precio Normal: $
                                {planNames === "AVANZADO"
                                  ? basePricePerMonthPro.toFixed(2)
                                  : basePricePerMonth.toFixed(2)}
                              </Text>
                              <Text fontWeight={"bold"}>
                                AHORRA{" "}
                                {planNames === "AVANZADO"
                                  ? proDiscount.toFixed(0)
                                  : discount.toFixed(0)}
                                %
                              </Text>
                            </Grid>
                            {/* Precio por mes y costo total */}
                            <Grid
                              mt={5}
                              templateColumns="repeat(2, 1fr)"
                              gap={6}
                              justifyItems={"center"}
                              w={"100%"}
                            >
                              <Text color="purple.500">
                                {monthlyCost.toFixed(2)} MXN/mes{" "}
                              </Text>
                              <Text color="purple.500">
                                (Total: ${totalCost.toFixed(2)} MXN)
                              </Text>
                            </Grid>
                          </Radio>
                        </Box>
                      );
                    })}
                </Stack>
              </RadioGroup>
              {selectedPlanId && (
                <Box mt={4}>
                  <Text fontWeight="bold">
                    Total: {selectedPlanDetails.totalCost} MXN
                  </Text>
                  <Text>
                    Al pagar, estás de acuerdo con nuestros{" "}
                    <Text as="u">Términos de servicio</Text> y confirmas que has
                    leído nuestra <Text as="u">Política de privacidad</Text>.
                  </Text>
                </Box>
              )}
            </ModalBody>
            <Divider />
            <Box mt={6} mx={6} mb={6}>
              {" "}
              {/* Agregar margen alrededor del contenedor de la tarjeta */}
              <Button
                disabled={!selectedPlanId}
                w={"100%"}
                variant={"brand"}
                onClick={() =>
                  history.push(`/admin/profile/payment/${selectedPlanId}`)
                }
              >
                Pagar
              </Button>
            </Box>
          </ModalContent>
        </Modal>
        <Box
          position="absolute"
          minH={{ base: "70vh", xl: "60vh" }}
          w={{ sm: "calc(100vw - 25px)", md: "calc(100vw - 25px)" }}
          borderRadius={{ sm: "15px" }}
          left="0"
          right="0"
          bgRepeat="no-repeat"
          overflow="hidden"
          zIndex="-1"
          top="0"
          bgSize="cover"
          mx={{ sm: "auto" }}
          mt={{ sm: "14px" }}
        />
        {tecnicas && (
          <Flex
            mt={400}
            position="absolute"
            w="100%"
            h="100vh" // Altura del contenedor para que ocupe toda la altura de la ventana
            justify="center"
            align="center"
          >
            <Image
              src={imageSrc}
              alt="Molding Process"
              objectFit="cover"
              w="75%"
              h="auto" // La altura se ajustará automáticamente para mantener la proporción de la imagen
              opacity="0.4"
              zIndex="-1"
            />
          </Flex>
        )}

        <Flex
          direction="column"
          textAlign="center"
          justifyContent="center"
          align="center"
          mt="6.5rem"
          mb="54px"
        >
          <Card opacity={"0.8"}>
            <CardHeader>
              <Grid w={"100%"} justifyItems={"center"}>
                <Text fontSize="32px" color="white" fontWeight="bold">
                  Mira Nuestros Precios
                </Text>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid w={"100%"} justifyItems={"center"}>
                <Text
                  fontSize="md"
                  fontWeight={"bold"}
                  color="white"
                  mt="20px"
                  mb="38px"
                >
                  Optimiza tus procesos de moldeo con MoldingVPS. Este plan te
                  da acceso completo a herramientas avanzadas para la creación y
                  gestión de resinas, máquinas y moldes. Experimenta con
                  diferentes combinaciones en tus proyectos y aprovecha nuestra
                  tecnología para estudiar detalladamente la ventana de proceso.
                  Calcula áreas, presiones y genera gráficas con precisión.
                  Ideal para profesionales que buscan excelencia y precisión en
                  el moldeo. ¡Aprovecha nuestras promociones de Lanzamiento,
                  Suscríbete ahora y lleva tus proyectos al siguiente nivel!"
                </Text>
              </Grid>
            </CardBody>
          </Card>

          {/* Pricing Cards */}
          <Stack
            direction={{ sm: "column", lg: "row" }}
            spacing="20px"
            mt="50px"
            mb="80px"
          >
            <Card
              style={{ opacity: 0.9 }}
              boxShadow={"0px 2px 5.5px rgba(0, 0, 0, 0.1)"}
              w={{ sm: "250px", md: "650px", lg: "250px" }}
            >
              <CardHeader mb="30px">
                <Flex direction="column" w="100%" align="center">
                  <Tag
                    px={3}
                    py={1}
                    mb={5}
                    fontSize="15px"
                    bg="#ec7c26"
                    color="#000000"
                    size="sm"
                    borderRadius={15}
                  >
                    FREE
                  </Tag>
                  <Flex>
                    <Text color="#fff">USUARIO REGISTRADO</Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text color="#fff" fontSize="46px" fontWeight="bold">
                      {"$00 "}
                    </Text>
                    <Text color="#fff" fontSize="sm" fontWeight="bold">
                      {".00 MXN"}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text color="#fff">Al mes.</Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody>
                <Stack direction="column" spacing="16px">
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="#fff"
                    />
                    <Text color="#fff" fontSize="sm">
                      {"1 Usuario"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="#fff"
                    />
                    <Text color="#fff" fontSize="sm">
                      {"Edición de Perfil"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="#fff"
                    />
                    <Text color="#fff" fontSize="sm">
                      {"Acceso Limitado"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="#fff"
                    />
                    <Text color="#fff" fontSize="sm">
                      {"2 Resinas"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="#fff"
                    />
                    <Text color="#fff" fontSize="sm">
                      {"2 Moldes"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="#fff"
                    />
                    <Text color="#fff" fontSize="sm">
                      {"2 Maquinas"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="#fff"
                    />
                    <Text color="#fff" fontSize="sm">
                      {"2 Proyectos"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaTimesCircle}
                      mr="8px"
                      color={bgTimesIcon}
                    />
                    <Text color="#fff" fontSize="sm">
                      {"Edición de Proyectos"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaTimesCircle}
                      mr="8px"
                      color={bgTimesIcon}
                    />
                    <Text color="#fff" fontSize="sm">
                      {"Descarga de Resultados"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaTimesCircle}
                      mr="8px"
                      color={bgTimesIcon}
                    />
                    <Text color="#fff" fontSize="sm">
                      {"Interacción de Ejercicios"}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaTimesCircle}
                      mr="8px"
                      color={bgTimesIcon}
                    />
                    <Text color="#fff" fontSize="sm">
                      {"Catacitación Grupal"}
                    </Text>
                  </Flex>
                  <Button disabled onClick={() => openModal()}>
                    Ver Opciones
                  </Button>
                </Stack>
              </CardBody>
            </Card>
            <Card
              style={{ opacity: 0.9 }}
              boxShadow={"0px 2px 5.5px rgba(0, 0, 0, 0.1)"}
              w={{ sm: "250px", md: "650px", lg: "250px" }}
            >
              <CardHeader mb="30px">
                <Flex direction="column" w="100%" align="center">
                  <Tag
                    px={3}
                    py={1}
                    mb={5}
                    fontSize="15px"
                    bg="#65E426"
                    color="#000000"
                    size="sm"
                    borderRadius={15}
                  >
                    BASICA
                  </Tag>
                  <Flex>
                    <Text color="#fff">Desde</Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text color="#fff" fontSize="46px" fontWeight="bold">
                      {"$49 "}
                    </Text>
                    <Text color="#fff" fontSize="sm" fontWeight="bold">
                      {".00 MXN"}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text color="#fff">Al mes.</Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody>
                <Stack direction="column" spacing="16px">
                  {planes
                    .filter((plan) => plan.plan === m)[0]
                    ?.benefits.map((benefit, index) => (
                      <Flex key={index} align="center">
                        <Icon
                          w="20px"
                          h="20px"
                          as={FaCheckCircle}
                          mr="8px"
                          color="#fff"
                        />
                        <Text color="#fff" fontSize="sm">
                          {benefit}
                        </Text>
                      </Flex>
                    ))}
                  {planes
                    .filter((plan) => plan.plan === m)[0]
                    ?.limitations.map((limitation, index) => (
                      <Flex key={index} align="center">
                        <Icon
                          w="20px"
                          h="20px"
                          as={FaTimesCircle}
                          mr="8px"
                          color={bgTimesIcon}
                        />
                        <Text color="#fff" fontSize="sm">
                          {limitation}
                        </Text>
                      </Flex>
                    ))}
                  <Button onClick={() => selectPlanType(m)}>
                    Ver Opciones
                  </Button>
                </Stack>
              </CardBody>
            </Card>
            <Card
              style={{ opacity: 0.9 }}
              boxShadow={"0px 2px 5.5px rgba(0, 0, 0, 0.1)"}
              w={{ sm: "250px", md: "650px", lg: "250px" }}
            >
              <CardHeader mb="30px">
                <Flex direction="column" w="100%" align="center">
                  <Tag
                    px={3}
                    py={1}
                    mb={5}
                    fontSize="15px"
                    bg="#057BEA"
                    color="#000000"
                    size="sm"
                    borderRadius={15}
                  >
                    PRO
                  </Tag>
                  <Flex>
                    <Text color="#fff">Desde</Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text color="#fff" fontSize="46px" fontWeight="bold">
                      {"$94 "}
                    </Text>
                    <Text color="#fff" fontSize="sm" fontWeight="bold">
                      {".00 MXN"}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text color="#fff">Al mes.</Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody>
                <Stack direction="column" spacing="16px">
                  {planes
                    .filter((plan) => plan.plan === "AVANZADO")[0]
                    ?.benefits.map((benefit, index) => (
                      <Flex key={index} align="center">
                        <Icon
                          w="20px"
                          h="20px"
                          as={FaCheckCircle}
                          mr="8px"
                          color="#fff"
                        />
                        <Text color="#fff" fontSize="xs">
                          {benefit}
                        </Text>
                      </Flex>
                    ))}
                  {planes
                    .filter((plan) => plan.plan === "AVANZADO")[0]
                    ?.limitations.map((limitation, index) => (
                      <Flex key={index} align="center">
                        <Icon
                          w="20px"
                          h="20px"
                          as={FaTimesCircle}
                          mr="8px"
                          color={bgTimesIcon}
                        />
                        <Text color="#fff" fontSize="xs">
                          {limitation}
                        </Text>
                      </Flex>
                    ))}
                  <Button onClick={() => selectPlanType("AVANZADO")}>
                    Ver Opciones
                  </Button>
                </Stack>
              </CardBody>
            </Card>
          </Stack>
          {/*
          <Flex direction='column' mb='110px' justify='center' align='center'>
          <Text
            color='#fff'
            fontWeight='bold'
            fontSize='md'
            mb='32px'
            maxW={{ sm: "250px", md: "100%" }}
            textAlign='center'>
            More than 50+ brands trust Vision UI PRO
          </Text>

        <SimpleGrid
          columns={{ sm: 2, md: 3, lg: 6 }}
          rows={{ sm: 3, md: 2, lg: 1 }}
          spacingX={{ sm: "65px", lg: "40px", xl: "65px" }}
          spacingY={{ sm: "30px" }}>
          <Image src={googleLogo} alignSelf='center' justifySelf='center' />
          <Image src={msnLogo} alignSelf='center' justifySelf='center' />
          <Image
            src={microsoftLogo}
            alignSelf='center'
            justifySelf='center'
          />
          <Image src={zohoLogo} alignSelf='center' justifySelf='center' />
          <Image src={georgiaLogo} alignSelf='center' justifySelf='center' />
          <Image src={deloitteLogo} alignSelf='center' justifySelf='center' />
        </SimpleGrid>
      </Flex>
      <Flex direction='column' mb={{ sm: "20px", lg: "60px" }}>

        <Flex direction='column' align='center' justify='center'>
          <Text
            color='#fff'
            fontWeight='bold'
            fontSize={{ sm: "3xl", md: "32px" }}
            mb='12px'>
            Frequently Asked Questions
          </Text>
          <Text color='#fff' fontSize='md'>
            A lot of people don't appreciate the moment until it’s passed.{" "}
            <br /> I'm not trying my hardest, and I'm not trying to do
          </Text>
        </Flex>
      </Flex>
      <Accordion
        allowToggle
        w={{ sm: "300px", md: "650px", xl: "930px" }}
        mb='16px'>
        <AccordionItem border='none'>
          <AccordionButton _focus='none' _hover='none' p='40px 0px 20px 0px'>
            <Box flex='1' textAlign='left'>
              <Text color='#fff' fontWeight='bold' fontSize='lg'>
                How do I order?
              </Text>
            </Box>
            <AccordionIcon color='#fff' />
          </AccordionButton>
          <AccordionPanel p='18px 0px 40px 0px'>
            <Text
              color='gray.400'
              fontSize='md'
              textAlign='left'
              alignSelf='flex-start'
              justifySelf='flex-start'>
              We’re not always in the position that we want to be at. We’re
              constantly growing. We’re constantly making mistakes. We’re
              constantly trying to express ourselves and actualize our dreams.
              If you have the opportunity to play this game of life you need
              to appreciate every moment. A lot of people don’t appreciate the
              moment until it’s passed.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my='12px' />
        <AccordionItem border='none'>
          <AccordionButton _focus='none' _hover='none' p='40px 0px 20px 0px'>
            <Box flex='1' textAlign='left'>
              <Text color='#fff' fontWeight='bold' fontSize='lg'>
                How can i make the payment?
              </Text>
            </Box>
            <AccordionIcon color='#fff' />
          </AccordionButton>
          <AccordionPanel p='18px 0px 40px 0px'>
            <Text
              color='gray.400'
              fontSize='md'
              textAlign='left'
              alignSelf='flex-start'
              justifySelf='flex-start'>
              It really matters and then like it really doesn’t matter. What
              matters is the people who are sparked by it. And the people who
              are like offended by it, it doesn’t matter. Because it's about
              motivating the doers. Because I’m here to follow my dreams and
              inspire other people to follow their dreams, too. We’re not
              always in the position that we want to be at. We’re constantly
              growing. We’re constantly making mistakes. We’re constantly
              trying to express ourselves and actualize our dreams. If you
              have the opportunity to play this game of life you need to
              appreciate every moment. A lot of people don’t appreciate the
              moment until it’s passed.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my='12px' />
        <AccordionItem border='none'>
          <AccordionButton _focus='none' _hover='none' p='40px 0px 20px 0px'>
            <Box flex='1' textAlign='left'>
              <Text color='#fff' fontWeight='bold' fontSize='lg'>
                How much time does it take to receive the order?
              </Text>
            </Box>
            <AccordionIcon color='#fff' />
          </AccordionButton>
          <AccordionPanel p='18px 0px 40px 0px'>
            <Text
              color='gray.400'
              fontSize='md'
              textAlign='left'
              alignSelf='flex-start'
              justifySelf='flex-start'>
              The time is now for it to be okay to be great. People in this
              world shun people for being great. For being a bright color. For
              standing out. But the time is now to be okay to be the greatest
              you. Would you believe in what you believe in, if you were the
              only one who believed it? If everything I did failed - which it
              doesn't, it actually succeeds - just the fact that I'm willing
              to fail is an inspiration. People are so scared to lose that
              they don't even try. Like, one thing people can't say is that
              I'm not trying, and I'm not trying my hardest, and I'm not
              trying to do the best way I know how.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my='12px' />
        <AccordionItem border='none'>
          <AccordionButton _focus='none' _hover='none' p='40px 0px 20px 0px'>
            <Box flex='1' textAlign='left'>
              <Text color='#fff' fontWeight='bold' fontSize='lg'>
                Can I resell the products?
              </Text>
            </Box>
            <AccordionIcon color='#fff' />
          </AccordionButton>
          <AccordionPanel p='18px 0px 40px 0px'>
            <Text
              color='gray.400'
              fontSize='md'
              textAlign='left'
              alignSelf='flex-start'
              justifySelf='flex-start'>
              I always felt like I could do anything. That’s the main thing
              people are controlled by! Thoughts- their perception of
              themselves! They're slowed down by their perception of
              themselves. If you're taught you can’t do anything, you won’t do
              anything. I was taught I could do everything. If everything I
              did failed - which it doesn't, it actually succeeds - just the
              fact that I'm willing to fail is an inspiration. People are so
              scared to lose that they don't even try. Like, one thing people
              can't say is that I'm not trying, and I'm not trying my hardest,
              and I'm not trying to do the best way I know how.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my='12px' />
        <AccordionItem border='none'>
          <AccordionButton _focus='none' _hover='none' p='40px 0px 20px 0px'>
            <Box flex='1' textAlign='left'>
              <Text color='#fff' fontWeight='bold' fontSize='lg'>
                Where do I find the shipping details?
              </Text>
            </Box>
            <AccordionIcon color='#fff' />
          </AccordionButton>
          <AccordionPanel p='18px 0px 40px 0px'>
            <Text
              color='gray.400'
              fontSize='md'
              textAlign='left'
              alignSelf='flex-start'
              justifySelf='flex-start'>
              There’s nothing I really wanted to do in life that I wasn’t able
              to get good at. That’s my skill. I’m not really specifically
              talented at anything except for the ability to learn. That’s
              what I do. That’s what I’m here for. Don’t be afraid to be wrong
              because you can’t learn anything from a compliment. I always
              felt like I could do anything. That’s the main thing people are
              controlled by! Thoughts- their perception of themselves! They're
              slowed down by their perception of themselves. If you're taught
              you can’t do anything, you won’t do anything. I was taught I
              could do everything.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my='12px' />
      </Accordion>
           */}
        </Flex>
      </Flex>
    </>
  );
}

export default Pricing;
