export const viscoscityStudyDataMatrixInitialState = [
  {
    sequence: 1,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 2,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 3,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 4,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 5,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 6,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 7,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 8,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 9,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
  {
    sequence: 10,
    intervalSpeed: "",
    fillingTime: "",
    maxPressureHpsi: "",
    shotWeight: "",
    vpCuttingPosition: "",
    maxPressurePpsi: "",
    flowRate: "",
    realSpeedMachine: "",
    speedEfficiency: "",
    shearRate: "",
    relativeViscocity: ""

  },
];

export const selectOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]