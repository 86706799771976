export const celsius = "°C";

export const fahrenheit = "°F";

export const inches = "in";

export const millimeters = "mm";

export const time = "hr";

export const percentage = "%";

export const bar = "bar";

export const psi = "psi";

export const RPM = "RPM";

export const REF = "REF";

export const TON = "TON";

export const NEWTON = "Nw";

export const RELATION = ":1";

export const CENTIMETERS = "cm";

export const SEG = "seg";

export const SEC = "sec";

export const KG = "Kg";

export const LB = "Lb";




