const instructions = [
  "1. Después de haber definido los parámetros de la Primera Etapa de Llenado (95-98%) y haber completado el Ejercicio de Presión de Sostenimiento, debemos elegir el máximo tiempo de sostenimiento que queremos evaluar para el ejercicio, así como considerar la velocidad de sostenimiento, ya que es un Parámetro de Entrada poco utilizado pero que puede afectar el rendimiento de la Etapa de Sostenimiento.",
  "2. La Mínima Presión de Sostenimiento Requerida será definida en base al Ejercicio de Presión de Sostenimiento y la sugerencia del fabricante de la Resina. En este punto podrás decidir si se usara el limite sugerido o hacer un ajuste para reducir la Ventana de Proceso. Introduce el valor de Mínima Presión de Sostenimiento Requerida dentro de la casilla en blanco.",
  "3. Ejecute las muestras de moldeo conforme el Tiempo de Sostenimiento establecido en cada Secuencia e Disparo y anote la información requerida en cada fila, Peso de Disparo, Posición de Colchón y Tiempo de Ciclo.",
  "4. Seleccione el Tiempo de Sostenimiento optimo, basado en la estabilidad del Peso de la Pieza y en las Características Cosméticas de las Piezas Moldeadas.",
  "5. La Máxima Presión Deseada estará definida en la segunda parte del estudio, en esta parte podemos reducir la Presión de Sostenimiento Sugerida por el ejercicio, para ajustar la Ventana de Proceso.",
  "6. Ejecute las muestras de moldeo conforme el Tiempo de Sostenimiento establecido en cada Secuencia e Disparo y anote la información requerida en cada fila, Peso de Disparo, Posición de Colchón y Tiempo de Ciclo.",
  "7. Seleccione el Tiempo de Sostenimiento optimo, basado en la estabilidad del Peso de la Pieza y en las Características Cosméticas de las Piezas Moldeadas.",
  "Una vez establecidos el Tiempo de Sostenimiento en los dos ejercicios, es necesario analizar que este sea el mismo para ambos experimentos. Así podremos tener la Mínima y Máxima Presión de Sostenimiento con un mismo Tiempo de Sostenimiento. De esta manera tendremos evaluada la Ventana de Presión de Sostenimiento para un mismo tiempo.",
  "Nota 1: Si el experimento no resulta exitoso será necesario evaluar proceso con una Presión de Sostenimiento distinta, dependiendo de los resultados obtenidos.",
  "Nota 2: Si la posición de Colchón se reduce aunque el peso se mantiene estable, será necesario revisar la condición de la Máquina de Moldeo por posible retorno de material por falla de Válvula Check o desgaste mecánico, fuga de material en el Bebedero y el molde por posible fuga interna en caso de Corredor Caliente, entre otros."
];

export default instructions;
