import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { DryerSupply } from "../../../../../../models";
import { PDFViewer } from "@react-pdf/renderer";
import "moment/locale/es";
import PdfBase from "../../../components/Pdf/PdfBase";
import PdfEntries from "../../../components/Pdf/PdfEntries";
import { lbKg, ozGr, ozMinGrMin, temp } from "../../../hooks/useUomTransform";
const IndividualDryerSupplyPdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(DryerSupply, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  const dataEntries1 = [
    {
      label: "ID de Secador",
      value: data?.dryerID,
      type: "text",
      addOn: "",
    },
    {
      label: "Cantidad de Producción",
      value: data?.prodQuantity,
      type: "separated",
      addOn: "Pcs",
    },
    {
      label: "Mínima Temperatura de Secado",
      value: data?.dryTempMin,
      type: "temp",
      addOn: temp(data?.header?.uom),
    },
    {
      label: "Máximo Tiempo de Secado",
      value: data?.dryTime?.max,
      type: "time",
      addOn: "Hr",
    },
    {
      label: "Número de Cavidades",
      value: data?.numberOfCavities,
      type: "separated",
      addOn: "Pcs",
    },
    {
      label: "Peso de Disparo al 100%",
      value: data?.shotWeight100,
      type: "weight",
      addOn: ozGr(data?.header?.uom),
    },
  ];

  const dataEntries2 = [
    {
      label: "Riesgo de Scrap",
      value: data?.scrapRisk,
      type: "text",
      addOn: "%",
    },
    {
      label: "Capacidad de Secador",
      value: data?.dryerCapacity,
      type: "weight",
      addOn: lbKg(data?.header?.uom),
    },
    {
      label: "Mínimo Tiempo de Secado",
      value: data?.dryTime?.min,
      type: "time",
      addOn: "Hrs",
    },
    {
      label: "Máximo Contenido de Humedad",
      value: data?.maxMoisture,
      type: "time",
      addOn: "%",
    },
    {
      label: "Tiempo de Ciclo Estimado",
      value: data?.cycleTime,
      type: "separated",
      addOn: "sec",
    },
  ];

  console.log(data);

  const dataOutPuts1 = [
    {
      label: "Piezas Por Hora",
      value: data?.pcsPerHour,
      type: "separated",
      addOn: "Pcs/Hr",
    },
    {
      label: "Mínimo Tiempo de Secado",
      value: data?.dryTimeMin,
      type: "time",
      addOn: "min",
    },
    {
      label: "Capacidad de Secador",
      value: data?.dryerCapacityOz,
      type: "separated",
      addOn: ozGr(data?.header?.uom),
    },
    {
      label: "Flujo de Resina",
      value: data?.resinFlowPerMin,
      type: "time",
      addOn: ozMinGrMin(data?.header?.uom),
    },
    {
      label: "Recorrido de Resina",
      value: data?.resinTroughDryer,
      type: "time",
      addOn: "Hr",
    },
    {
      label: "Mínima Recarga de Resina",
      value: data?.minDryerReload,
      type: "weight",
      addOn: lbKg(data?.header?.uom),
    },
  ];

  const dataOutPuts2 = [
    {
      label: "Tiempo de Ciclo Estimado",
      value: data?.cycleTimeMin,
      type: "time",
      addOn: "min",
    },
    {
      label: "Disparos Requeridos",
      value: data?.reqShotsForProd,
      type: "separated",
      addOn: "shots",
    },
    {
      label: "Resina Requerida",
      value: data?.reqResinForProd,
      type: "weight",
      addOn: lbKg(data?.header?.uom),
    },
    {
      label: "Tiempo de Producción",
      value: data?.productionDuration,
      type: "time",
      addOn: "Hr",
    },
    {
      label: "Abastecimiento de Secador",
      value: data?.dryerSupplying,
      type: "separated",
      addOn: "Pcs",
    },
    {
      label: "Recargas Requeridas",
      value: data?.reloadsReq,
      type: "separated",
      addOn: "times",
    },
  ];

  const MyDocument = ({ data }) => (
    <PdfBase data={data} name={"Abastecimiento de Secador"}>
      <PdfEntries
        title={"Entradas"}
        column1={dataEntries1}
        column2={dataEntries2}
      />

      <PdfEntries
        title={"Salidas"}
        column1={dataOutPuts1}
        column2={dataOutPuts2}
      />
    </PdfBase>
  );

  return (
    <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
      <MyDocument data={data} />
    </PDFViewer>
  );
  // Luego, usarías este `id` para obtener tus datos, probablemente con un useEffect y fetch o alguna llamada a una API.
};

export default IndividualDryerSupplyPdf;
