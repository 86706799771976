import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

const CustomInputField = ({
  label,
  value,
  onChange,
  id,
  mx,
  disabled,
  onBlur,
  error,
  color = "gray.200",
  borderRadius = 15,
  borderColor = "",
  ...props
}) => (
  <FormControl isInvalid={error} mx={mx} color={color} {...props}>
    <FormLabel htmlFor={id} alignSelf={"center"}>
      {label}
    </FormLabel>
    <Input
      {...props}
      borderColor={borderColor}
      size={"sm"}
      borderRadius={borderRadius}
      disabled={disabled}
      placeholder={label}
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    {error && <FormErrorMessage>{error}</FormErrorMessage>}
  </FormControl>
);

export default CustomInputField;
