import {hpsiToHbar, inMm, inSecMmsec, ozGr, temp} from "../../../hooks/useUomTransform";

export const inputData = data => {
  const {uom} = data?.header
  return [
    {
      label: "Diámetro de Tornillo",
      value: data?.screwDiameter,
      id: "screwDiameter",
      addOn: inMm(uom)
    },
    {
      label: "Diámetro de Pistón",
      value: data?.pistonDiameter,
      id: "pistonDiameter",
      addOn: inMm(uom)
    },
    {
      label: "Temperatura de Masa Real",
      value: data?.realMeltTemp,
      id: "realMeltTemp",
      addOn: temp(uom)
    },
    {
      label: "Máxima Velocidad de Inyección",
      value: data?.maxInjectionSpeed,
      id: "maxInjectionSpeed",
      addOn: inSecMmsec(uom)
    },
    {
      label: "Tamaño de Disparo",
      value: data?.shotSizePosition,
      id: "shotSizePosition",
      addOn: inMm(uom)
    },
    {
      label: "Posición de Transferencia V/P",
      value: data?.cutoffPosition,
      id: "cutoffPosition",
      addOn: inMm(uom)
    },
    {
      label: "Tamaño de Descompresión",
      value: data?.decompressionSize,
      id: "decompressionSize",
      addOn: inMm(uom)
    },
    {
      label: "Posición Teórica de Colchón",
      value: data?.cushionPositionTheoric,
      id: "cushionPositionTheoric",
      addOn: inMm(uom)
    },
    {
      label: "Peso de Primera Etapa",
      value: data?.shotWeightFirstStage,
      id: "shotWeightFirstStage",
      addOn: ozGr(uom)
    },
    {
      label: "Peso Específico",
      value: data?.specificWeight,
      id: "specificWeight",
      addOn: ":1"
    },
    {
      label: "Máxima Presión de Inyección",
      value: data?.immMaxHPress,
      id: "immMaxHPress",
      addOn: hpsiToHbar(uom)
    },
    {
      label: "Uso Deseado de Máquina",
      value: data?.maxMachineUse,
      id: "maxMachineUse",
      addOn: "%"
    },
    {
      label: "Eficiencia Requerida",
      value: data?.processEfficence,
      id: "processEfficence",
      addOn: "%"
    },
  ]
}

