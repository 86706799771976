import { Flex } from "@chakra-ui/react";
import React from "react";
import RediassaContact from "./components/RediassaContact";
import RediassaHeaderComponent from "./components/RediassaHeaderComponent";
import RediasaBodyComponent from "./components/RediasaBodyComponent";
import RediassaMapComponent from "./components/RediassaMapComponent";
import { useHistory } from "react-router-dom";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionRediasa,
  metaKeywordsRediasa,
  metaTitleRediasa,
} from "../utilities/metaVariables";

const Rediassa = () => {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");
  const headerBgColor = "#b2c2d9";
  return (
    <>
      <HelmetComponent
        title={metaTitleRediasa}
        keywords={metaKeywordsRediasa}
        description={metaDescriptionRediasa}
      />
      <Flex
        direction="column"
        pt={{ sm: "125px", lg: "125" }}
        width={isBannerPath ? "80%" : "auto"} // Aquí aplicamos el ancho condicionalmente
        mx={isBannerPath ? "auto" : "0"}
      >
        <RediassaHeaderComponent bgColor={headerBgColor} />
        <RediasaBodyComponent />
        <RediassaMapComponent />
        <RediassaContact bgColor={headerBgColor} collaborator={"Rediasa"} />
      </Flex>
    </>
  );
};

export default Rediassa;
