export const initialState = {
  gaps:[
    { form: "", diameter: "", cavities: "", base: "", height: "" },
  ],
  cavities: [
    { form: "", diameter: "", cavities: "", base: "", height: "" },
  ],
  runners: [
    { form: "", diameter: "", cavities: "", base: "", height: "" },
  ],
  projectedArea: "",
  minTonnageFactor: "",
  maxTonnageFactor: "",
  safeFactor: "",
  minSuggestedClampForce: "",
  maxSuggestedClampForce: "",
  suggestedClampForce: "",
  maxMachineClampForce: "",
  runnerArea: "0",
  cavityArea: "0",
  gapArea: "0",
  header:{}
}