import { useHeader } from "../../../../../contexts/IndividualContext/HeaderContext";
import { useContext, useEffect, useState } from "react";
import { initialState } from "./utilities/variables";
import {
  isFloatStringPositive,
  isObjectEmpty,
} from "../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { DataStore } from "aws-amplify/datastore";
import { DryerSupply } from "../../../../../models";
import UserContext from "../../../../../contexts/UserContext";
import conditionalsAlert from "../../../hooks/ConditionalsAlert";

export const useIndividualDryerSupplyHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();
  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  const onChangeHandler = (e, test) => {
    const { id, value } = e.target;
    if (isFloatStringPositive(value) || test) {
      if (id.includes(".")) {
        const [outerKey, innerKey] = id.split(".");

        setData((prevData) => ({
          ...prevData,
          [outerKey]: {
            ...prevData[outerKey],
            [innerKey]: value,
          },
        }));
      } else {
        // Maneja la actualización para propiedades no anidadas
        setData((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      }
    }
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      try {
        const restOperation = post({
          path: "/dryer-supply-individual",
          apiName: "dryerSupply",
          options: {
            body: {
              ...data,
            },
          },
        });
        const rest = await restOperation.response;
        const body = await rest.body.json();
        const bodyToString = await Object.entries(body).reduce(
          (newObj, [key, value]) => {
            newObj[key] = value.toString();
            return newObj;
          },
          {}
        );

        const response = await DataStore.save(
          new DryerSupply({
            ...data,
            ...bodyToString,
            userID: user.id,
          })
        );
        let text = "";
        let icon = "success";
        if (parseFloat(response.resinTroughDryer) < parseFloat(response.dryTime.min)) {
          text += `El tiempo de recorrido de la resina no garantiza un secado apropiado.`;
          icon = "warning";
        }
        if (parseFloat(response.resinTroughDryer) > parseFloat(response.dryTime.max)) {
          text += `El tiempo de recorrido de la resina es demasiado alto y puede afectar a sus propiedades.`;
          icon = "warning";
        }

        setResult(response);
        conditionalsAlert(icon, text);
      } catch (error) {
        console.log(error);
      }
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  const checkIsValidForm = () => {
    const state = data;
    // Definir las claves que deben estar llenas y las que deben estar vacías
    const keysToBeFilled = [
      "header",
      "dryerID",
      "scrapRisk",
      "prodQuantity",
      "dryerCapacity",
      "dryTempMin",
      "dryTime",
      "maxMoisture",
      "numberOfCavities",
      "shotWeight100",
      "cycleTime",
    ];
    const keysToBeEmpty = [
      "pcsPerHour",
      "cycleTimeMin",
      "dryTimeMin",
      "reqShotsForProd",
      "dryerCapacityOz",
      "reqResinForProd",
      "resinFlowPerMin",
      "productionDuration",
      "resinTroughDryer",
      "dryerSupplying",
      "minDryerReload",
      "reloadsReq",
    ];

    // Verificar si las claves que deben estar llenas lo están
    for (const key of keysToBeFilled) {
      if (key === "dryTime") {
        if (
          !state[key].min ||
          state[key].min === "" ||
          state[key].min === "." ||
          !state[key].max ||
          state[key].max === "" ||
          state[key].max === "."
        ) {
          return false;
        }
      }
      if (!state[key] || state[key] === "" || state[key] === ".") {
        return false; // Retorna falso si alguna clave necesaria está vacía
      }
    }

    // Verificar si las claves que deben estar vacías lo están
    for (const key of keysToBeEmpty) {
      if (state[key]) {
        return false; // Retorna falso si alguna clave que debe estar vacía no lo está
      }
    }

    return true; // Retorna verdadero si todas las condiciones se cumplen
  };

  return {
    data,
    uom,
    setData,
    header,
    machineName,
    productName,
    resinName,
    toolName,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    checkIsValidForm,
  };
};
