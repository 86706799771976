import { useContext } from "react";
import ProjectContext from "../../../../../../../../../contexts/ProjectContext";

export const updateDimension = () => {
  const { project, updateProject } = useContext(ProjectContext);
  const { dimensionTestTable } = project;

  function updateDimensionData(index, field, value) {
    let updatedTable = [...dimensionTestTable];

    let updatedItem = { ...updatedTable[index] };

    updatedItem[field] = value;

    if (
      field === "nomDimension" ||
      field === "maxTolerance" ||
      field === "minTolerance"
    ) {
      const nomDimension = parseFloat(updatedItem.nomDimension) || 0;
      const maxTolerance = parseFloat(updatedItem.maxTolerance) || 0;
      const minTolerance = parseFloat(updatedItem.minTolerance) || 0;

      if (!isNaN(nomDimension) && nomDimension !== 0) {
        if (field === "maxTolerance" || field === "nomDimension") {
          updatedItem.maxToleranceResult = nomDimension + maxTolerance;
        }
        if (field === "minTolerance" || field === "nomDimension") {
          updatedItem.minToleranceResult = nomDimension - minTolerance;
        }
      }
    }

    updatedTable[index] = updatedItem;
    updateProject({
      ...project,
      dimensionTestTable: updatedTable,
    });
  }

  function handleInputChange(index, key, value) {
    // Crear una copia del arreglo
    const updatedTable = [...dimensionTestTable];

    // Crear una copia del objeto en el índice específico
    const updatedItem = { ...updatedTable[index] };

    // Actualizar 'data', 'final' o 'check' dentro del objeto copiado
    if (updatedItem.data && key in updatedItem.data) {
      updatedItem.data = { ...updatedItem.data, [key]: value };
    } else if (updatedItem.final && key in updatedItem.final) {
      updatedItem.final = { ...updatedItem.final, [key]: value };
    } else if (updatedItem.check && key in updatedItem.check) {
      updatedItem.check = { ...updatedItem.check, [key]: value };
    }

    // Reemplazar el objeto en el índice correspondiente con el objeto actualizado
    updatedTable[index] = updatedItem;

    // Actualizar el estado global
    updateProject({
      ...project,
      dimensionTestTable: updatedTable,
    });
  }

  function handleFinalChange(index, key, value) {
    // Crear una copia del arreglo
    const updatedTable = [...dimensionTestTable];

    // Crear una copia del objeto en el índice específico
    const updatedItem = { ...updatedTable[index] };

    // Asegurarse de que estamos trabajando con el objeto correcto que tiene 'final'
    if (updatedItem.final) {
      // Actualizar el valor específico dentro de 'final'
      updatedItem.final = {
        ...updatedItem.final,
        [key]: value,
      };

      // Reemplazar el objeto en el índice correspondiente con el objeto actualizado
      updatedTable[index] = updatedItem;

      // Actualizar el estado global
      updateProject({
        ...project,
        dimensionTestTable: updatedTable,
      });
    }
  }

  function handleCheckChange(index, key, value) {
    // Crear una copia del arreglo
    const updatedTable = [...dimensionTestTable];

    // Crear una copia del objeto en el índice específico
    const updatedItem = { ...updatedTable[index] };

    // Asegurarse de que estamos trabajando con el objeto correcto que tiene 'check'
    if (updatedItem.check) {
      // Actualizar el valor específico dentro de 'check'
      updatedItem.check = {
        ...updatedItem.check,
        [key]: value,
      };

      // Reemplazar el objeto en el índice correspondiente con el objeto actualizado
      updatedTable[index] = updatedItem;

      // Actualizar el estado global
      updateProject({
        ...project,
        dimensionTestTable: updatedTable,
      });
    }
  }

  function validateDimensionTestTableData() {
    if (project && project.dimensionTestTable) {
      for (const item of dimensionTestTable) {
        if (item.data) {
          for (const key in item.data) {
            if (item.data[key] === "" || isNaN(Number(item.data[key]))) {
              return true;
            }
          }

          const campos = [
            "maxTolerance",
            "maxToleranceResult",
            "minToleranceResult",
            "minTolerance",
            "nomDimension",
          ];
          for (const campo of campos) {
            if (item[campo] === "" || isNaN(Number(item[campo]))) {
              return true;
            }
          }
        }

        if (item.hasOwnProperty("final")) {
          if (item.final) {
            for (const key in item.final) {
              if (item.final[key] === "") {
                return true;
              }
            }
          } else {
            return true;
          }
        }

        if (item.hasOwnProperty("check")) {
          if (item.check) {
            for (const key in item.check) {
              if (item.check[key] === "") {
                return true;
              }
            }
          } else {
            return true;
          }
        }
      }
    }

    return false;
  }

  return {
    updateDimensionData,
    handleInputChange,
    handleCheckChange,
    handleFinalChange,
    validateDimensionTestTableData,
  };
};
