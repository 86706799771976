import { useContext, useEffect, useState } from "react";
import { fillingTimeInitialState } from "../utilities/variables";
import ProjectContext from "../../../../../../../contexts/ProjectContext";

export const useFillingTimeHooks = () => {
  const [fillingTime, setFillingTime] = useState(fillingTimeInitialState);
  const [fillingTimeAvg, setFillingTimeAvg] = useState("Faltan Datos");
  const [fillingTimeMin, setFillingTimeMin] = useState("Faltan Datos");
  const [fillingTimeMax, setFillingTimeMax] = useState("Faltan Datos");
  const [fillingTimeDiff, setFillingTimeDiff] = useState("Faltan Datos");
  const [fillingTimeArray, setFillingTimeArray] = useState([]);

  const { project } = useContext(ProjectContext);
  useEffect(() => {
    calculate();
  }, [fillingTime]);

  useEffect(() => {
    if (project.vcheckFillingTime) {
      setFillingTime(project.vcheckFillingTime);
    }
  }, [project]);

  const calculate = async () => {
    const {
      fillingTime1,
      fillingTime2,
      fillingTime3,
      fillingTime4,
      fillingTime5,
      fillingTime6,
      fillingTime7,
      fillingTime8,
      fillingTime9,
      fillingTime10,
    } = fillingTime;
    if (
      fillingTime1 &&
      fillingTime2 &&
      fillingTime3 &&
      fillingTime4 &&
      fillingTime5 &&
      fillingTime6 &&
      fillingTime7 &&
      fillingTime8 &&
      fillingTime9 &&
      fillingTime10 &&
      fillingTime1 > 0 &&
      fillingTime2 > 0 &&
      fillingTime3 > 0 &&
      fillingTime4 > 0 &&
      fillingTime5 > 0 &&
      fillingTime6 > 0 &&
      fillingTime7 > 0 &&
      fillingTime8 > 0 &&
      fillingTime9 > 0 &&
      fillingTime10 > 0 &&
      fillingTime1 !== "" &&
      fillingTime2 !== "" &&
      fillingTime3 !== "" &&
      fillingTime4 !== "" &&
      fillingTime5 !== "" &&
      fillingTime6 !== "" &&
      fillingTime7 !== "" &&
      fillingTime8 !== "" &&
      fillingTime9 !== "" &&
      fillingTime10 !== ""
    ) {
      const data = [
        fillingTime1,
        fillingTime2,
        fillingTime3,
        fillingTime4,
        fillingTime5,
        fillingTime6,
        fillingTime7,
        fillingTime8,
        fillingTime9,
        fillingTime10,
      ];

      await setFillingTimeArray(data);
      await setMinAndMax(data);
    } else {
      setFillingTimeArray([]);
      setFillingTimeMax("Faltan Datos");
      setFillingTimeMin("Faltan Datos");
      setFillingTimeDiff("Faltan Datos");
      setFillingTimeAvg("Faltan Datos");
    }
  };

  const setMinAndMax = async (data) => {
    setFillingTimeMax(Math.max(...data).toFixed(5));
    setFillingTimeMin(Math.min(...data).toFixed(5));
  };

  useEffect(() => {
    if ((fillingTimeMin, fillingTimeMax)) {
      setFillingTimeDiff(Math.abs(fillingTimeMin - fillingTimeMax).toFixed(5));
    } else {
      setFillingTimeDiff("Faltan Datos");
    }
  }, [fillingTimeMin, fillingTimeMax]);

  useEffect(() => {
    if (fillingTimeArray.length === 10) {
      let values = fillingTimeArray;
      let sum = 0;
      values.map((item, current) => (sum += item));
      let avg = sum / values.length;
      setFillingTimeAvg(avg.toFixed(5));
    } else {
      setFillingTimeAvg("Faltan Datos");
    }
  }, [fillingTimeArray]);

  const lineChartOptionsFillingTime = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      show:false,
      theme: "dark",
      fixed: {
        show: false,
        enabled: false,
        position: "bottomLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 130,
        offsetX: 160,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "#008FFB",
        },
        labels: {
          show: false,
          style: {
            colors: "#008FFB",
            fontSize: "12px",
          },
        },
        title: {
          show: false,
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    ],
    legend: {
      show: false,
      color: "white",
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      borderColor: "#FEB019",
    },
    colors: ["#008FFB", "#FFFFFF", "#FEB019"],
  };

  const lineChartDataFillingTime = [
    {
      name: "Tiempo de Llenado",
      type: "line",
      data: fillingTimeArray,
    },
  ];

  return {
    fillingTime,
    setFillingTime,
    fillingTimeAvg,
    fillingTimeMin,
    fillingTimeMax,
    fillingTimeDiff,
    fillingTimeArray,
    lineChartOptionsFillingTime,
    lineChartDataFillingTime,
  };
};
