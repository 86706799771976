import { Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import LdHeader from "./components/LdHeader";
import LdBody from "./components/LdBody";
import LdBody2 from "./components/LdBody2";
import LdMapComponent from "./components/LdMapComponent";
import LdContactComponent from "./components/LdContactComponent";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionLdPlasticSolutions,
  metaKeywordsLdPlasticSolutions,
  metaTitleLdPlasticSolutions,
} from "../utilities/metaVariables";

const LdPlasticSolutions = () => {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");

  const textColor = "#04498b";

  return (
    <>
      <HelmetComponent
        description={metaDescriptionLdPlasticSolutions}
        keywords={metaKeywordsLdPlasticSolutions}
        title={metaTitleLdPlasticSolutions}
      />
      <Flex
        direction="column"
        pt={{ sm: "125px", lg: "125" }}
        width={isBannerPath ? "80%" : "auto"} // Aquí aplicamos el ancho condicionalmente
        mx={isBannerPath ? "auto" : "0"}
      >
        <LdHeader textColor={textColor} />
        <LdBody textColor={textColor} />
        <LdBody2 textColor={textColor} />
        <LdMapComponent />
        <LdContactComponent collaborator={"Ld Plastic Solutions"} />
      </Flex>
    </>
  );
};

export default LdPlasticSolutions;
