export const metaTitleIndex =
  "Gestión de Resinas para Moldeo por Inyección: Vista General | MVPS";
export const metaDescriptionIndex =
  "Controla y administra tu inventario de resinas para moldeo por inyección desde un único lugar. Accede a detalles, especificaciones para optimizar tus procesos de producción.";

export const metaKeywordsIndex =
  "gestión de resinas, moldeo por inyección, optimización de producción, mvps";
export const metaTitleCreate =
  "Añadir Nueva Resina al Sistema de Moldeo por Inyección | MVPS";
export const metaDescriptionCreate =
  "Facilita la incorporación de nuevas resinas a tu sistema de moldeo por inyección con nuestro formulario de alta. Define las propiedades y especificaciones necesarias para asegurar la compatibilidad y rendimiento óptimo.";

export const metaKeywordsCreate =
  "alta de resina, sistema de moldeo, especificaciones de resina, rendimiento de producción, incorporación de materiales, mvps";
export const metaTitleConvert =
  "Conversión de Unidades de Resinas para Moldeo por Inyección | MVPS";
export const metaDescriptionConvert =
  "Adapta las unidades de medida de tus resinas para la compatibilidad con diversos sistemas de moldeo por inyección. Nuestra herramienta de conversión facilita la precisión y la estandarización en tus procesos productivos.";

export const metaKeywordsConvert =
  "conversión de unidades, medidas de resinas, estandarización de procesos, compatibilidad de moldeo, herramienta de conversión, mvps";
