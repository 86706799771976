import CustomGrid from "../../../components/CustomGrid";
import CustomInput from "../../../components/CustomInput";
import { inputData } from "../utilities/inputData";
import { Button, GridItem } from "@chakra-ui/react";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { keysForForm } from "../utilities/variables";
import { KeysForCheck } from "../../shot-size/utilities/variables";

const Form = ({ onChange, data, disabled, onSubmit, isLoading }) => {
  const isDisabled = !useVerifyForm(data, keysForForm);
  const isComplete = useVerifyForm(data, KeysForCheck);
  return (
    <CustomGrid repeat={4}>
      {inputData(data)?.map((item, index) => {
        return (
          <CustomInput
            disabled={disabled}
            key={`${item.id}-${index}-${item.addOn}`}
            addOnText={item.addOn}
            label={item.label}
            value={item.value}
            id={item.id}
            onChange={onChange}
          />
        );
      })}
      <GridItem colSpan={4}>
        <Button
          isLoading={isLoading}
          onClick={() => onSubmit()}
          colorScheme={"facebook"}
          disabled={isDisabled || isLoading}
        >
          {isComplete ? "Editar" : "Continuar"}
        </Button>
      </GridItem>
    </CustomGrid>
  );
};

export default Form;
