import { useIndividualShotSizeHooks } from "../hooks/useIndividualShotSizeHooks";
import IndividualFooter from "../../../components/IndividualFooter";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { KeysForCheck, keysForForm } from "../utilities/variables";
import React from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import ShowResults from "../../../components/ShowResults";
import { dataResults } from "../utilities/dataToShow";
import Form from "./Form";
import { Box, Flex, Grid } from "@chakra-ui/react";
import BarChart from "../../../../../../components/Charts/BarChart";

const IndividualShotSizeForm = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    barChartData,
    barChartData2,
    barChartOptions,
    barChartOptions2,
    chartRef,
  } = useIndividualShotSizeHooks(setIsDisabled);

  const isDisabled = !useVerifyForm(data, keysForForm);
  const isFormDisabled = !isObjectEmpty(result);
  const isCompleteData = useVerifyForm(data, KeysForCheck);

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <Form
          onChange={onChangeHandler}
          data={data}
          disabled={isFormDisabled}
        />
        {isCompleteData && (
          <>
            <ShowResults data={dataResults(data)} />
            <Grid width={"100%"}>
              <div ref={chartRef}>
                <Flex
                  color={"white"}
                  direction={"column"}
                  w={"85%"}
                  minHeight={"250px"}
                >
                  <Flex
                    color={"white"}
                    direction={"column"}
                    w={"85%"}
                    borderColor={"green"}
                    position={"absolute"}
                  >
                    <Box w="100%" minH={{sm: "300px"}}>
                      <BarChart
                        chartOptions={barChartOptions}
                        chartData={barChartData}
                      />
                    </Box>
                  </Flex>
                  <Flex
                    color={"white"}
                    direction={"column"}
                    w={"85%"}
                    borderColor={"green"}
                    position={"absolute"}
                  >
                    <Box w="100%" minH={{sm: "300px"}}>
                      <BarChart
                        chartOptions={barChartOptions2}
                        chartData={barChartData2}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </div>
            </Grid>

          </>
        )}
        <IndividualFooter
          result={result}
          url={"shot-size"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={isDisabled}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualShotSizeForm;

//banner/projects/pdf/intensification-ratio/1e91863a-8c54-4f8b-b84f-5d5302be0694
