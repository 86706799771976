import { DataStore } from "aws-amplify/datastore";
import {Machine, Project, Resin, Tool} from "../../../models";
import { useContext } from "react";
import ProjectContext from "../../../contexts/ProjectContext";

export const useGetProjectByIdHooks = () => {
  const { updateProject, updateMachine, updateTool, updateResin, resin, tool, machine } = useContext(ProjectContext);
  const getProject = async (id) => {
    try {
      await DataStore.query(Project, id).then(async (res) => {
        updateProject(res)
        await DataStore.query(Resin, res.projectResinId).then(res => {
          updateResin(res)
        }).catch(err => console.log(err))
        await DataStore.query(Machine, res.projectMachineId).then(res => updateMachine(res)).catch(err => console.log(err))
        await DataStore.query(Tool, res.projectToolId).then(res => updateTool(res)).catch(err => console.log(err))
      });
    } catch (err) {
      console.log(err);
    }
  }

  return {
    getProject,
    resin,
    tool,
    machine
  };
};
