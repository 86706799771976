import DataDisplayBadge from "../../../../components/DataDisplay/DatadisplayBadge";
import {
  inMm,
  inSquareSecMmSquareSec,
  ppsiToPbar,
  psiTobar,
  temp,
  tonInKnMm,
} from "../../../projects/individual/hooks/useUomTransform";
import React from "react";
import { inches, millimeters } from "../../../../variables/uomVariables";

const Data = ({ item, textColor = "gray.600" }) => {
  return (
    <>
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Sistema de medición"}
        color={textColor}
        value={item.uom}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"ID de Resina"}
        color={textColor}
        value={item.resinName}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tipo de Resina"}
        color={textColor}
        value={item.resinType}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Estructura Quimica"}
        color={textColor}
        value={item.chemicalStructure}
        type={"text"}
      />

      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Costo de Resina"}
        color={textColor}
        value={item.resinCost}
        type={"time"}
        rightAddon={"$"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Melt Mínima"}
        color={textColor}
        value={item.meltTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Melt Máxima"}
        color={textColor}
        value={item.meltTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura de Nariz Mínima"}
        color={textColor}
        value={item.nozzleTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura de Nariz Máxima"}
        color={textColor}
        value={item.nozzleTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Frontal Mínima"}
        color={textColor}
        value={item.FrontTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Frontal Máxima"}
        color={textColor}
        value={item.FrontTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Central Mínima"}
        color={textColor}
        value={item.midTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Central Máxima"}
        color={textColor}
        value={item.midTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Trasera Mínima"}
        color={textColor}
        value={item.backTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Trasera Máxima"}
        color={textColor}
        value={item.backTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Molde Lado A Mínima"}
        color={textColor}
        value={item.toolAsideTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Molde Lado A Máxima"}
        color={textColor}
        value={item.toolAsideTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Molde Lado B Mínima"}
        color={textColor}
        value={item.toolBsideTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura Molde Lado B Máxima"}
        color={textColor}
        value={item.toolBsideTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />

      {/*  todo */}
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Difusividad Termica"}
        color={textColor}
        value={item.thermalDiffusion}
        type={"dif"}
        rightAddon={inSquareSecMmSquareSec(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura de Desmoldeo"}
        color={textColor}
        value={item.demoldingTemperature}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura de Secado Mínima"}
        color={textColor}
        value={item.dryTempMin}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Temperatura de Secado Máxima"}
        color={textColor}
        value={item.dryTempMax}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tipo de Secador Recomendado"}
        color={textColor}
        value={item.dryerType}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Dew Point"}
        color={textColor}
        value={item.dewPoint}
        type={"temp"}
        rightAddon={temp(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Porcentaje Máximo de Humedad"}
        color={textColor}
        value={item.maxMoisture}
        type={"percentage"}
        rightAddon={"%"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Encogimiento Máximo"}
        color={textColor}
        value={item.maximumShrinkage}
        type={"weight"}
        rightAddon={
          item?.uom === "INTERNACIONAL"
            ? `${millimeters}/${millimeters}`
            : `${inches}/${inches}`
        }
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Peso Específico"}
        color={textColor}
        value={item.specificWeight}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Contrapresión Mínima"}
        color={textColor}
        value={item.backPressureMin}
        type={"pressure"}
        rightAddon={psiTobar(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Contrapresión Máxima"}
        color={textColor}
        value={item.backPressureMax}
        type={"pressure"}
        rightAddon={psiTobar(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"RPM Mínima"}
        color={textColor}
        value={item.rpmMin}
        type={"pressure"}
        rightAddon={"RPM"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"RPM Máxima"}
        color={textColor}
        value={item.rpmMax}
        type={"pressure"}
        rightAddon={"RPM"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Máxima Presión de Inyección"}
        color={textColor}
        value={item.resinInjectionPressureMax}
        type={"pressure"}
        rightAddon={ppsiToPbar(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Mínima Presión de Empaque"}
        color={textColor}
        value={item.minimumPackingPressure}
        type={"percentaje"}
        rightAddon={"%"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Ocupación de Barril Mínimo"}
        color={textColor}
        value={item.barrelOcupationMin}
        type={"percentaje"}
        rightAddon={"%"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Ocupación de Barril Máximo"}
        color={textColor}
        value={item.barrelOcupationMax}
        type={"percentaje"}
        rightAddon={"%"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Factor de Tonelaje Mínimo"}
        color={textColor}
        value={item.tonnageFactorMin}
        type={"weight"}
        rightAddon={tonInKnMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Factor de Tonelaje Máximo"}
        color={textColor}
        value={item.tonnageFactorMax}
        type={"weight"}
        rightAddon={tonInKnMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Posición de Colchón Mínimo"}
        color={textColor}
        value={item.cushionPositionMin}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Posición de Colchón Máxima"}
        color={textColor}
        value={item.cushionPositionMax}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Relación L/D de Husillo Mínima"}
        color={textColor}
        value={item.minimumScrewLDRatio}
        type={"int"}
        rightAddon={":1"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Relación L/D de Husillo Máxima"}
        color={textColor}
        value={item.maxScrewLDRatio}
        type={"int"}
        rightAddon={":1"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Profundidad de Venteo Mínima (DEPTH)"}
        color={textColor}
        value={item.minimumVentDepth}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Longitud de Venteo (LAND)"}
        color={textColor}
        value={item.ventLong}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Ancho de Venteo (WIDTH)"}
        color={textColor}
        value={item.ventWidth}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Profundidad de Relief"}
        color={textColor}
        value={item.reliefDeep}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Porcentaje de Remolido Máximo"}
        color={textColor}
        value={item.regrindPercentageMax}
        type={"percentaje"}
        rightAddon={"%"}
      />
        <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tiempo de Secado Mínimo"}
        color={textColor}
        value={item.dryTimeMin}
        type={"time"}
        rightAddon={"Hr"}
      />
        <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tiempo de Secado Máximo"}
        color={textColor}
        value={item.dryTimeMax}
        type={"time"}
        rightAddon={"Hr"}
      />
    </>
  );
};

export default Data;
