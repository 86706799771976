import {Flex} from "@chakra-ui/react";
import React from "react";

const HorizontalDivider = ({mb, mt}) => {
  return (
    <Flex
      mt={mt ? mt : ""}
      h="1px"
      w="100%"
      bg="linear-gradient(90deg, rgba(176, 177, 179, 0) 0%, #b0b1b3 47.22%, rgba(176, 177, 179, 0.15625) 94.44%)"
      mb={mb ? mb : "20px"}
    />
  )
}

export default HorizontalDivider