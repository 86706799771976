import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faEnvelope,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import ldLogo from "../../../../assets/img/collaborators/ld/ld-logo.jpg";
import pdf from "../../../../assets/pdf/ld-mexico.pdf";
import React from "react";
import { useSendEmailHooks } from "../../hooks/useSendEmailHooks";
import SendEmailConfirmationModal from "../../components/SendEmailConfirmationModal";
import { contactEmail } from "../utilities";

const LdContactComponent = ({ collaborator }) => {
  const {
    sendEmail,
    setEmail,
    email,
    setCompany,
    company,
    onOpen,
    isOpen,
    onClose,
    isLoading,
    message,
    setMessage,
    name,
    setName,
    type,
  } = useSendEmailHooks();

  const textColor = "black";

  const descargarPDF = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name; // Puedes personalizar el nombre del archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid
      mt={5}
      borderRadius={15}
      backgroundColor={"white"}
      mx={5}
      p={5}
      justifyContent={"center"}
      templateColumns="repeat(2, 1fr)"
      gap={6}
    >
      <SendEmailConfirmationModal
        type={type}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        collaborator={collaborator}
      />
      <Grid gap={6}>
        <Text fontWeight={"bold"} fontSize={"2xl"} color={textColor}>
          Dejanos un mensaje
        </Text>
        <FormControl width="100%">
          <FormLabel>Nombre</FormLabel>
          <InputGroup size="md" borderColor="gray.600">
            <Input
              value={name}
              placeholder="ej: Juan Perez"
              onChange={(e) => setName(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <FontAwesomeIcon color={"gray"} icon={faUser} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl width="100%">
          <FormLabel>Empresa</FormLabel>
          <InputGroup size="md" borderColor="gray.600">
            <Input
              value={company}
              placeholder="ej: Ld Plastic Solutions"
              onChange={(e) => setCompany(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <FontAwesomeIcon color={"gray"} icon={faBriefcase} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl width="100%">
          <FormLabel>Email</FormLabel>
          <InputGroup size="md" borderColor="gray.600">
            <Input
              value={email}
              type={"email"}
              placeholder="ej: ejemplo@ejemplo.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <FontAwesomeIcon color={"gray"} icon={faEnvelope} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="message">
          <FormLabel>Mensaje </FormLabel>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Dejanos tu mensaje."
            minH="120px"
            fontSize="xs"
            borderWidth={1}
            borderColor="gray.600"
            color="gray.600"
            borderRadius={15}
          />
        </FormControl>
        <Button
          isLoading={isLoading}
          colorScheme={"facebook"}
          disabled={!name || !email || !message || !company}
          onClick={() => sendEmail(contactEmail)}
        >
          Enviar
        </Button>
      </Grid>
      <Grid justifyItems={"center"}>
        <Image src={ldLogo} width={"80%"} />
        <Button
          mr={5}
          colorScheme={"facebook"}
          onClick={() => descargarPDF(pdf, "ld-mexico.pdf")}
        >
          Descargar Presentación
        </Button>
      </Grid>
    </Grid>
  );
};

export default LdContactComponent;
