const instructions = [
  "1. Dibuja el área proyectada tratando de dividirla en figuras geométricas básicas, de acuerdo a las 5 mostradas en la imagen.",
  "2. Elige la opción Runner como figura rectangular, la base (B) será igual al grosor y la altura (H) será igual a la longitud del corredor. Si es necesario, y el Corredor tiene reducción de grosor en diferentes secciones, agrega las filas necesarias de Runner para mejorar el resultado.",

  "3. Divide la forma de las cavidades en figuras geométricas simples.",
  "4. Selecciona cada Figura Geométrica en la tabla y anota las dimensiones requeridas para el cálculo de cada área",
  "5. Si las cavidades tienen Huecos Formadores, estos deberán ser restados del Área Proyectada, estos deberán ser medidos de la misma manera y anotados en las filas correspondientes a Huecos Formadores, para poder hacer la resta de cada uno de ellos.",
  "6. Selecciona el número de cavidades que tenga el molde. En el caso del Runner solo necesitará poner 1 Cavidad, ya que se contempla todo el recorrido del mismo."
];

export default instructions;
