export const validateForms = () => {
  const validateString = (value) => {
    let error;
    if (!value) {
      error = "Este campo es requerido";
    }
    return error;
  };

  const validateFloat = (value) => {
    let error;

    // Primero, verifica si el valor es una cadena vacía o no está definido
    if (typeof value === "string" && value.trim() === "") {
      error = "Este campo es requerido";
    } else if (!/^\d*\.?\d+$/.test(value) || parseFloat(value) <= 0) {
      // Usamos una expresión regular para validar si el valor es un número flotante válido
      // y luego verificamos si es mayor que 0
      error =
        "Este campo tiene que ser un número mayor que 0, ej: 0.00005 o .00005";
    }

    return error;
  };

  const validateFloatPosNeg = (value) => {
    let error;

    // Primero, verifica si el valor es una cadena vacía o no está definido
    if (typeof value === "string" && value.trim() === "") {
      error = "Este campo es requerido";
    } else if (!/^-?\d*\.?\d+$/.test(value)) {
      // Usamos una expresión regular para validar si el valor es un número flotante válido
      // La expresión regular ahora acepta números negativos
      error = "Este campo tiene que ser un número, ej: 0.00005, .00005 o -0.00005";
    }

    return error;
  };

  const validateDewPoint = (value) => {
    let error;
    if (value >= 0) {
      error = "Este campo tiene que ser menor que 0, ej: -0.00005";
    } else if (typeof value === "string" && value.trim() === "") {
      error = "Este campo es requerido";
    }
    return error;
  };

  return {
    validateString,
    validateFloat,
    validateDewPoint,
    validateFloatPosNeg
  };
};
