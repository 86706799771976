import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import { Grid } from "@chakra-ui/react";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../utilities/variables";
import {
  inMm,
  inSecMmsec,
  inSquareMmSquare,
  ppsiToPbar,
  temp,
} from "../../../../../individual/hooks/useUomTransform";

const ViscosityStudyData = () => {
  const { project, machine } = useContext(ProjectContext);
  const {
    screwArea,
    shotSizePositionTheoric,
    decompressionSize,
    cutOffPositionTheoric,
    overallShotSize,
    realMeltTemp,
    intRatio,
    maxPlasticPressure,
    cushionPositionTheoric,
    speed_Interval_Unit,
    max_ppsi_used,
  } = project;

  const { maxInjectionSpeed } = machine;
  return (
    <Grid
      mx={5}
      templateColumns="repeat(4, 1fr)"
      templateRows="repeat(3, 1fr)"
      gap={3}
      mt={5}
      borderWidth={1}
      borderRadius={15}
      p={5}
      borderColor={borderColor}
    >
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        title={"Area de Husillo"}
        value={screwArea}
        rightAddon={inSquareMmSquare(project?.uom)}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={inMm(project?.uom)}
        title={"Posición de Carga"}
        value={shotSizePositionTheoric}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={inMm(project?.uom)}
        title={"Descompresión"}
        value={decompressionSize}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={inMm(project?.uom)}
        title={"Transferencia V/P"}
        value={cutOffPositionTheoric}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={inMm(project?.uom)}
        title={"Longitud Total de Disparo"}
        value={overallShotSize}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        type={"temp"}
        title={"Temperatura Melt"}
        rightAddon={temp(project?.uom)}
        value={realMeltTemp}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={inSecMmsec(project?.uom)}
        title={"Máxima velocidad de la Máquina"}
        value={maxInjectionSpeed}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={":1"}
        title={"Razón de Intensificación"}
        value={intRatio}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={ppsiToPbar(project?.uom)}
        type={"pressure"}
        title={"Presión Plastica Máxima"}
        value={maxPlasticPressure}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={inMm(project?.uom)}
        title={"Posicion de Colchon"}
        value={cushionPositionTheoric}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        rightAddon={inSecMmsec(project?.uom)}
        title={"Intervalos de Velocidad"}
        value={speed_Interval_Unit}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        size={"sm"}
        type={"pressure"}
        rightAddon={ppsiToPbar(project?.uom)}
        title={"Máxima Presion Plastica Deseada"}
        value={max_ppsi_used}
      />
    </Grid>
  );
};

export default ViscosityStudyData;
