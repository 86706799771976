export const lineChartOptionsWeight = {
  chart: {
    height: 350,
    type: "line",
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
    fixed: {
      show: false,
      enabled: false,
      position: "bottomLeft", // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 130,
      offsetX: 160,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: [
    {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
        color: "#008FFB",
      },
      labels: {
        show: false,
        style: {
          colors: "#008FFB",
          fontSize: "12px",
        },
      },
      title: {
        show: false,
        text: "",
        style: {
          color: "#008FFB",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  ],
  legend: {
    show: false,
    color: "white",
  },
  grid: {
    show: false,
    strokeDashArray: 0,
    borderColor: "#FEB019",
  },
  colors: ["#FEB019", "#FFFFFF", "#FEB019"],
};

export const lineChartOptionsFillingTime = {
  chart: {
    height: 350,
    type: "line",
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    show:false,
    theme: "dark",
    fixed: {
      show: false,
      enabled: false,
      position: "bottomLeft", // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 130,
      offsetX: 160,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: [
    {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
        color: "#008FFB",
      },
      labels: {
        show: false,
        style: {
          colors: "#008FFB",
          fontSize: "12px",
        },
      },
      title: {
        show: false,
        text: "",
        style: {
          color: "#008FFB",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  ],
  legend: {
    show: false,
    color: "white",
  },
  grid: {
    show: false,
    strokeDashArray: 0,
    borderColor: "#FEB019",
  },
  colors: ["#008FFB", "#FFFFFF", "#FEB019"],
};

export const lineChartOptionsCushion = {
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  chart: {
    height: 350,
    type: "line",
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    show: false,
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    labels: {
      style: {
        colors: "#050505",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: [
    {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
        color: "#008FFB",
      },
      labels: {
        show: false,
        style: {
          colors: "#008FFB",
          fontSize: "12px",
        },
      },
      title: {
        show: false,
        text: "",
        style: {
          color: "#008FFB",
        },
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },
  ],
  legend: {
    show: false,
    color: "#203764",
  },
  grid: {
    show: false,
    strokeDashArray: 0,
    borderColor: "#FEB019",
  },
  colors: ["#203764", "#FFFFFF", "#FEB019"],
};