import {keysForResult} from "./variables";

export const validateResultsFilled = (data) => {
  // Asume que `keysForResult` es un array con las claves que quieres verificar en `data`
  return keysForResult.every(key => {
    // Verifica que la clave exista en `data` y su valor no sea vacío
    const value = data[key];
    return value !== undefined && value !== "";
  });
};
