import { useContext, useEffect, useState } from "react";
import { cushionInitialState } from "../utilities/variables";
import ProjectContext from "../../../../../../../contexts/ProjectContext";

export const useCushionHooks = () => {
  const [cushion, setCushion] = useState(cushionInitialState);
  const [cushionAvg, setCushionAvg] = useState("Faltan Datos");
  const [cushionMin, setCushionMin] = useState("Faltan Datos");
  const [cushionMax, setCushionMax] = useState("Faltan Datos");
  const [cushionDiff, setCushionDiff] = useState("Faltan Datos");
  const [cushionArray, setCushionArray] = useState([]);

  const { project } = useContext(ProjectContext);
  useEffect(() => {
    calculate();
  }, [cushion]);

  useEffect(() => {
    if (project.vcheckCushion) {
      setCushion(project.vcheckCushion);
    }
  }, [project]);

  const calculate = async () => {
    const {
      cushion1,
      cushion2,
      cushion3,
      cushion4,
      cushion5,
      cushion6,
      cushion7,
      cushion8,
      cushion9,
      cushion10,
    } = cushion;
    if (
      cushion1 &&
      cushion2 &&
      cushion3 &&
      cushion4 &&
      cushion5 &&
      cushion6 &&
      cushion7 &&
      cushion8 &&
      cushion9 &&
      cushion10 &&
      cushion1 > 0 &&
      cushion2 > 0 &&
      cushion3 > 0 &&
      cushion4 > 0 &&
      cushion5 > 0 &&
      cushion6 > 0 &&
      cushion7 > 0 &&
      cushion8 > 0 &&
      cushion9 > 0 &&
      cushion10 > 0 &&
      cushion1 !== "" &&
      cushion2 !== "" &&
      cushion3 !== "" &&
      cushion4 !== "" &&
      cushion5 !== "" &&
      cushion6 !== "" &&
      cushion7 !== "" &&
      cushion8 !== "" &&
      cushion9 !== "" &&
      cushion10 !== ""
    ) {
      const data = [
        cushion1,
        cushion2,
        cushion3,
        cushion4,
        cushion5,
        cushion6,
        cushion7,
        cushion8,
        cushion9,
        cushion10,
      ];

      await setCushionArray(data);
      await setMinAndMax(data);
    } else {
      setCushionArray([]);
      setCushionMax("Faltan Datos");
      setCushionMin("Faltan Datos");
      setCushionDiff("Faltan Datos");
      setCushionAvg("Faltan Datos");
    }
  };

  const setMinAndMax = async (data) => {
    setCushionMax(Math.max(...data).toFixed(5));
    setCushionMin(Math.min(...data).toFixed(5));
  };

  useEffect(() => {
    if ((cushionMin, cushionMax)) {
      setCushionDiff(Math.abs(cushionMin - cushionMax).toFixed(5));
    } else {
      setCushionDiff("Faltan Datos");
    }
  }, [cushionMin, cushionMax]);

  useEffect(() => {
    if (cushionArray.length === 10) {
      let values = cushionArray;
      let sum = 0;
      values.map((item, current) => (sum += item));
      let avg = sum / values.length;
      setCushionAvg(avg.toFixed(5));
    } else {
      setCushionAvg("Faltan Datos");
    }
  }, [cushionArray]);

  const lineChartOptionsCushion = {
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      show: false,
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      labels: {
        style: {
          colors: "#000080",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "#008FFB",
        },
        labels: {
          show: false,
          style: {
            colors: "#008FFB",
            fontSize: "12px",
          },
        },
        title: {
          show: false,
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: false,
          show: false,
        },
      },
    ],
    legend: {
      show: false,
      color: "white",
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      borderColor: "#FEB019",
    },
    colors: ["#1f4e78", "#FFFFFF", "#FEB019"],
  };
  //colors: ["#56577A", "#008FFB", "#FEB019"],

  const lineChartDataCushion = [
    {
      name: "Colchon",
      type: "line",
      data: cushionArray,
    },
  ];

  return {
    cushion,
    setCushion,
    cushionAvg,
    cushionMin,
    cushionMax,
    cushionDiff,
    cushionArray,
    lineChartOptionsCushion,
    lineChartDataCushion,
  };
};
