const instructions = [
  "1. Con la información previamente cargada del proyecto obtendremos la mayoría de la información necesaria para iniciar el ejercicio. Recargar todos los Parámetros de Entrada conforme los resultados previos.",
  "2. Capturar el rango menor y mayor para la Temperatura de Molde o Temperatura de Masa Fundida, según sea el caso.",
  "3. Establecer los Limites Inferiores y Superiores deseados para los Valores de Salida de Proceso.",
  "4. Verificar el orden aleatorio para ejecutar las muestras de moldeo.",
  "5. Configurar los Parámetros de Ventana Proceso según lo indique el Orden #1, realizar un Arranque Seguro de Proceso, esperar por lo menos 5 disparos para tomar las Piezas Moldeadas de Muestra.",
  "6. Anotar los Valores de Salida de Proceso en la fila correspondiente.",
  "7. Repetir pasos 5 y 6 para cada Muestra de Moldeo.",
  "8. Una vez terminadas las Muestras de Moldeo medir las Dimensiones Criticas de Funcionamiento y verificar que estén libres de Defectos Cosméticos.",
  "9. Analizar resultados de la Ventana de Proceso.",
  "10. Evaluar resultados y anotar comentarios.",
  "NOTA: Si los resultados no son exitosos, se deberá evaluar la posibilidad de Rediseñar el Experimento.",
];

export default instructions;
