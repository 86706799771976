import {post} from "aws-amplify/api";
import {useState} from "react";

export const useCavityHooks = (data, setData, setIsLoading) => {

  const [cavityErrors, setCavityErrors] = useState([])
  const addCavity = () => {
    const newCavities = [
      ...data.cavities,
      { form: "", diameter: "", cavities: "", base: "", height: "" },
    ];
    setData({ ...data, cavities: newCavities });
  };

  const removeCavity = () => {
    const newCavities = data.cavities.slice(0, -1);
    setData({ ...data, cavities: newCavities });
  };

  const isLastCavityComplete = () => {
    if (!data.cavities || data.cavities.length === 0) return false;

    const lastCavity = data.cavities[data.cavities.length - 1];
    if (!lastCavity.form) return false;

    if (lastCavity.form === "CIRCULO") {
      return lastCavity.diameter !== "" && lastCavity.cavities !== "";
    }
    return (
      lastCavity.base !== "" &&
      lastCavity.height !== "" &&
      lastCavity.cavities !== ""
    );
  };
  const finishCavities = async () => {
    setIsLoading(true);

    const restOperation = post({
      apiName: "projectedAreaNew",
      path: "/projected-area",
      options: {
        body: data.cavities,
      },
    });

    const rest = await restOperation.response;
    const body = await rest.body.json();
    const b = body.body;

    const { area } = b;
    setData({...data, cavityArea: area.toString()})
    setIsLoading(false)
  };

  const handleInputCavityChange = (index, field, value) => {
    const isValidFloat = (value) => /^\d*\.?\d*$/.test(value); // Permitir cadena vacía y números flotantes
    const isValidInteger = (value) => /^\d*$/.test(value); // Permitir cadena vacía y números enteros

    let isValid = true;
    if (field === "cavities") {
      isValid = isValidInteger(value);
    } else if (field !== "form") {
      isValid = isValidFloat(value);
    }

    if (isValid) {
      const updatedCavities = [...data.cavities];
      updatedCavities[index] = { ...updatedCavities[index], [field]: value };
      setData({ ...data, cavities: updatedCavities });
    }

    setCavityErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: isValid ? "" : "Valor no válido",
      };
      return updatedErrors;
    });
  };



  return {
    addCavity,
    removeCavity,
    isLastCavityComplete,
    finishCavities,
    handleInputCavityChange,
    cavityErrors,
    setCavityErrors
  }
}