import { useContext, useEffect, useRef, useState } from "react";
import ProjectContext from "../../contexts/ProjectContext";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { Project } from "../../models";

export const useRunnersHooks = () => {
  const isMounted = useRef(true);
  const { project, updateProject, isLoading, updateLoading } = useContext(
    ProjectContext
  );

  const [errors, setErrors] = useState([]);
  const [showRunnerArea, setShowRunnerArea] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (project.runners) {
      setErrors(
        project.runners.map(() => ({
          form: "",
          diameter: "",
          cavities: "",
          base: "",
          height: "",
        }))
      );
    }
  }, [project.runners]);

  const customStyles = {
    clearIndicator: (styles) => ({
      ...styles,
      color: 'red' // Cambia 'red' por el color que prefieras
    }),
    option: (provided, state) => ({
      ...provided,
      color: "gray.700",
      backgroundColor: state.isSelected ? "gray.200" : "white",
    }),
  };

  const addRunner = () => {
    const newRunners = [
      ...project.runners,
      { form: "", diameter: "", cavities: "", base: "", height: "" },
    ];
    updateProject({ ...project, runners: newRunners });
  };

  const removeRunner = () => {
    const newRunners = project.runners.slice(0, -1);
    updateProject({ ...project, runners: newRunners });
  };

  const isLastRunnerComplete = () => {
    if (!project.runners || project.runners.length === 0) return false;

    const lastRunner = project.runners[project.runners.length - 1];
    if (!lastRunner.form) return false;

    if (lastRunner.form === "CIRCULO") {
      return lastRunner.diameter !== "" && lastRunner.cavities !== "";
    }
    return (
      lastRunner.base !== "" &&
      lastRunner.height !== "" &&
      lastRunner.cavities !== ""
    );
  };

  const handleInputChange = async (index, field, value) => {
    const isValidFloat = (value) => /^\d*\.?\d*$/.test(value); // Permitir cadena vacía y números flotantes
    const isValidInteger = (value) => /^\d*$/.test(value); // Permitir cadena vacía y números enteros

    let isValid = true;
    if (field === "cavities") {
      isValid = isValidInteger(value);
    } else if (field !== "form") {
      isValid = isValidFloat(value);
    }

    if (!value && field === "form") {
      const updatedRunners = project.runners.map((runner, idx) =>
        idx === index
          ? {
              ...runner,
              form: "",
              cavities: "",
              diameter: "",
              height: "",
              base: "",
            }
          : runner
      );

      // Actualiza el estado del proyecto con los nuevos corredores
      const updatedProject = { ...project, runners: updatedRunners };

      // Asegúrate de que la operación de actualización del estado se haya completado
      await updateProject(updatedProject);

      const p = await DataStore.query(Project, project.id);
      const result = await DataStore.save(
        Project.copyOf(p, (updated) => {
          updated.runners = updatedProject.runners;
          updated.runnerArea = null;
          updated.insuranceFactor = "";
          updated.minTonSug = "0";
          updated.nomTonSug = "0";
          updated.maxTonSug = "0";
        })
      );
      updateProject(result);
    } else {
      if (isValid) {
        const updatedRunners = [...project.runners];
        updatedRunners[index] = { ...updatedRunners[index], [field]: value };
        updateProject({ ...project, runners: updatedRunners });
      }
    }

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: isValid ? "" : "Valor no válido",
      };
      return updatedErrors;
    });
  };

  const finishRunners = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "projectedAreaNew",
      path: "/projected-area",
      options: {
        body: project.runners,
      },
    });

    const rest = await restOperation.response;
    const body = await rest.body.json();
    const b = body.body;

    const { area } = b;
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.runners = project.runners;
        updated.runnerArea = area.toString();
        updated.insuranceFactor = "";
        updated.minTonSug = "0";
        updated.nomTonSug = "0";
        updated.maxTonSug = "0";
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => console.log(err))
      .finally(() => updateLoading(false));
  };

  return {
    customStyles,
    addRunner,
    isLastRunnerComplete,
    removeRunner,
    handleInputChange,
    errors,
    finishRunners,
    showRunnerArea,
    setShowRunnerArea,
  };
};
