import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import { borderColor as bgColor } from "../../views/projects/project-overview/components/utilities/variables";
import { textColor } from "../../views/users/molds/utilities/variables";

const InputCustomFieldProjectRigthAddon = ({
  my = 2,
  fontSize = "sm",
  fontWeight = "bold",
  fontStyle = "",
  color = textColor,
  type = "text",
  addOn = "",
  label,
  value,
  onChange,
  id,
  mx,
  mb = 2,
  disabled,
  onBlur,
  error,
  min = "",
  max = "",
  addOnColor = textColor,
  borderColor = bgColor,
  borderRadius = 5,
  backgroundColor = "white",
}) => {
  return (
    <FormControl isInvalid={error} mx={mx} mb={mb} my={my}>
      <FormLabel
        color={color}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        fontSize={fontSize}
        htmlFor={id}
      >
        {label}
      </FormLabel>
      <InputGroup borderColor={borderColor} size={"sm"}>
        <Input
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
          min={min}
          max={max}
          color={color}
          type={type}
          disabled={disabled}
          placeholder={label}
          id={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <InputRightAddon
          borderRadius={borderRadius}
          borderColor={borderColor}
          children={addOn}
          color={addOnColor}
        />
      </InputGroup>

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputCustomFieldProjectRigthAddon;
