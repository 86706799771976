import { useEffect, useState } from "react";
import {
  convertInCubeCmCube,
  convertInCubeMmCube,
  convertInMm, convertInSecMmSec,
  convertLbKg,
  convertPsiBar, convertSquareInchToMmSquare,
  convertTonKn,
} from "../../../projects/individual/hooks/useConvertUom";
import { DataStore } from "aws-amplify/datastore";
import {Machine, Tool} from "../../../../models";
import { useAlert } from "../../../../contexts/useAlert";

export const useConvertToolsHooks = () => {
  const [uom, setUom] = useState("");
  const [items, setItems] = useState([]);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (uom) {
      getItems();
    }
  }, [uom]);

  const getItems = async () => {
    await DataStore.query(Tool, (c) => c.uom.eq(uom))
      .then((res) => {
        setItems(res);
      })
      .catch((err) => {
        showErrorAlert("Ha ocurrido un error al cargar los moldes");
      });
  };

  const onSelectItem = (value) => {
    setItem(value);
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const data = convertItem(item);

      const savedItem = await DataStore.save(
        new Tool({
          ...data,
        })
      );
      setItem(savedItem);
      showSuccessAlert();
      setIsComplete(true);
    } catch (err) {
      showErrorAlert(
        err?.message
          ? err.message
          : "Ha ocurrido un error, favor de contactar con el administrador"
      );
    }
    setIsLoading(false);
  };

  const selectOptions = [
    {
      label: "INGLES",
      value: "INGLES",
    },
    {
      label: "INTERNACIONAL",
      value: "INTERNACIONAL",
    },
  ];
  const convertItem = (item) => {
    const { uom } = item;
    const { createdAt, updatedAt, ...allItem } = item;
    return {
      ...allItem,
      uom: uom === "INGLES" ? "INTERNACIONAL" : "INGLES",
      horizontalMeasurement: convertInMm(uom, item.horizontalMeasurement),
      verticalMeasurement: convertInMm(uom, item.verticalMeasurement),
      stackMold: convertInMm(uom, item.stackMold),
      shootVolume: convertInCubeCmCube(uom, item.shootVolume),
      cavityVolume: convertInCubeCmCube(uom, item.cavityVolume),
      runnerVolume: convertInCubeCmCube(uom, item.runnerVolume),
      gateSize: convertSquareInchToMmSquare(uom, item.gateSize),
      maxMoldEject: convertInMm(uom, item.maxMoldEject),
      gateDiameter: convertInMm(uom, item.gateDiameter),
      widthGate: convertInMm(uom, item.widthGate),
      heightGate: convertInMm(uom, item.heightGate),
      deepGate: convertInMm(uom, item.deepGate),
    };
  };

  return {
    selectOptions,
    item,
    setUom,
    setItem,
    isLoading,
    items,
    isComplete,
    setIsComplete,
    onSelectItem,
    onSubmitHandler,
  };
};
