import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import { useContext, useEffect, useRef, useState } from "react";
import {
  isFloatString,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { initialState } from "../utilities/variables";
import { useAlert } from "../../../../../../contexts/useAlert";
import html2canvas from "html2canvas";
import { useVerifyForm } from "./useVerifyForm";
import { CheckValveRepeatibility } from "../../../../../../models";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import UserContext from "../../../../../../contexts/UserContext";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";

export const useIndividualHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();
  const { user } = useContext(UserContext);

  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const isDisabled = !useVerifyForm(data);
  const formRef = useRef(null);
  const resultRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const createImages = async () => {
    await delay(500);
    const canvas = await html2canvas(chartRef.current);

    // Convertir el canvas a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Falló la conversión de canvas a blob"));
        }
      }, "image/png");
    });
  };

  const onChangeHandler = (e) => {
    const { name, value, id } = e.target;
    const [field, index] = name.split("-");
    if (isFloatString(value)) {
      setData((prevData) => {
        if (Array.isArray(prevData[field])) {
          const updatedField = [...prevData[field]];

          if (updatedField[index]) {
            // Verifica si el índice existe
            updatedField[index] = { ...updatedField[index], value: value };
          }

          return { ...prevData, [field]: updatedField };
        } else {
          return { ...prevData, [id]: value };

          //return prevData;
        }
      });
    }
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const restOperation = post({
        path: "/vcheck-repeatibility-individual",
        apiName: "vcheckRepeatability",
        options: {
          body: {
            ...data,
          },
        },
      });
      const rest = await restOperation.response;
      const body = await rest.body.json();
      await setData({ ...data, ...body });

      // Espera a que la imagen sea creada antes de continuar
      const image = await createImages();

      const res = await uploadData({
        key: `check-valve-individual/${Date.now()}.png`,
        data: image,
      }).result;

      const response = await DataStore.save(
        new CheckValveRepeatibility({
          ...data,
          ...body,
          chartImage: res.key,
          userID: user.id,
        })
      );
      let icon = "success";
      let text = "";
      if (parseFloat(response?.checkValveRepeatibility) > 3) {
        icon = "warning";
        text +=
          "<li>el porcentaje de repetibilidad es mayor al 3% recomendado por MVPS. </li>";
      }
      conditionalsAlert(icon, text);
      setResult(response);
      setIsLoading(false);
    } catch (error) {
      const e = error.message ? error.message : error;
      showErrorAlert(e);
      setIsLoading(false);
    }
  };

  return {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    isDisabled,
    formRef,
    resultRef,
    chartRef,
  };
};
