import React from "react";
import IndividualBase from "../../components/IndividualBase";
import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IndividualShotSizeForm from "./components/IndividualShotSizeForm";
import HelmetComponent from "../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import { GridItem } from "@chakra-ui/react";
import CustomGrid from "../../components/CustomGrid";
import instructions from "./utilities/instructions";
import description from "./utilities/description";
const IndividualShotSize = () => {
  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <HeaderProvider>
        <GridItem width={"100%"} mt={"120px"}>
          <CustomGrid>
            <IndividualBase
              instructions={instructions}
              description={description}
              title={"Tamaño de Disparo"}
            >
              <IndividualShotSizeForm />
            </IndividualBase>
          </CustomGrid>
        </GridItem>
      </HeaderProvider>
    </>
  );
};

export default IndividualShotSize;
