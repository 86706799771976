import React, { useContext, useState } from "react";
import { Button, Grid, GridItem, Input, Text } from "@chakra-ui/react";
import ProjectContext from "../../../../../../../../contexts/ProjectContext";
import { updateDimension } from "./helpers/updateDimensionData";
import { useFinalFunction } from "./helpers/finalFunction";
import { DataStore } from "aws-amplify/datastore";
import { Project } from "../../../../../../../../models";
import Select from "react-select";
import { borderColor, textColor } from "../../../../utilities/variables";
import { inMm } from "../../../../../../individual/hooks/useUomTransform";

const DimensionTestTable = () => {
  const { project, updateProject, updateLoading, isLoading } = useContext(
    ProjectContext
  );
  const [saved, setSaved] = useState(false);
  useFinalFunction();
  const { dimensionTestTable } = project;
  const order = [
    "MIN-MIN",
    "MIN-NOM",
    "MIN-MAX",
    "NOM-MIN",
    "NOM-NOM",
    "NOM-MAX",
    "MAX-MIN",
    "MAX-NOM",
    "MAX-MAX",
  ];

  const selectOptions = [
    { value: "PASA", label: "PASA" },
    { value: "NO PASA", label: "NO PASA" },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      borderColor: "gray.200",
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "gray.100" : isFocused ? "gray.50" : null,
        color: "black",
        cursor: "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: isSelected ? "gray.100" : "gray.50",
        },
      };
    },
    singleValue: (styles) => ({ ...styles, color: "gray.700" }),
  };

  const {
    updateDimensionData,
    handleInputChange,
    handleCheckChange,
    handleFinalChange,
  } = updateDimension();

  const saveDimensionTestTable = async () => {
    updateLoading(true);
    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.dimensionTestTable = project.dimensionTestTable;
      })
    )
      .then(async (res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  return (
    <Grid
      borderWidth={1}
      p={5}
      borderRadius={15}
      mx={5}
      borderColor={borderColor}
    >
      <Grid justifyItems={"center"} mb={5}>
        <Text alignSelf={"center"} fontSize={"xl"} fontWeight={"bold"}>
          DIMENSION TEST TABLE
        </Text>
      </Grid>
      <Grid
        templateColumns="repeat(8, 1fr)"
        templateRows="repeat(13, 1fr)"
        gap={0}
      >
        <GridItem
          borderColor={borderColor}
          borderLeftWidth={1}
          borderTopWidth={1}
          borderTopLeftRadius={15}
        />
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"} fontWeight={"bold"}>
            DOE
          </Text>
        </Grid>
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"} fontWeight={"bold"}>
            {`DIMENSION 1 (${inMm(project?.uom)})`}
          </Text>
        </Grid>
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"} fontWeight={"bold"}>
            {`DIMENSION 2 (${inMm(project?.uom)})`}
          </Text>
        </Grid>
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"} fontWeight={"bold"}>
            {`DIMENSION 3 (${inMm(project?.uom)})`}
          </Text>
        </Grid>
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"} fontWeight={"bold"}>
            {`DIMENSION 4 (${inMm(project?.uom)})`}
          </Text>
        </Grid>
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"} fontWeight={"bold"}>
            COSMETICO / FUNCIONAL
          </Text>
        </Grid>
        <Grid
          borderWidth={1}
          borderTopRightRadius={15}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"} fontWeight={"bold"}>
            TOALES
          </Text>
        </Grid>
        <GridItem borderLeftWidth={1} borderColor={borderColor} />
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"}>
            Dimensión Nominal
          </Text>
        </Grid>
        {dimensionTestTable?.map((item, index) => {
          if (item.dimension) {
            return (
              <>
                <Grid
                  borderWidth={1}
                  px={1}
                  borderColor={borderColor}
                  justifyItems={"center"}
                >
                  <Input
                    onChange={(e) =>
                      updateDimensionData(index, "nomDimension", e.target.value)
                    }
                    value={item.nomDimension}
                    size={"sm"}
                    placeholder={"ej: 0.5340"}
                    borderWidth={0}
                  />
                </Grid>
              </>
            );
          }
        })}
        <GridItem />
        <GridItem borderRightWidth={1} borderColor={borderColor} />
        <Grid
          borderLeftWidth={1}
          borderRightWidth={1}
          px={2}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text fontSize={"sm"} fontWeight={"bold"} alignSelf={"center"}>
            Dimension Test
          </Text>
        </Grid>
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"}>
            Tolerancia Mínima
          </Text>
        </Grid>
        {dimensionTestTable?.map((item, index) => {
          if (item.dimension) {
            return (
              <>
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  borderWidth={1}
                  px={1}
                  borderColor={borderColor}
                  justifyContent={"space-between"}
                >
                  <Input
                    alignSelf={"center"}
                    p={0}
                    onChange={(e) =>
                      updateDimensionData(index, "minTolerance", e.target.value)
                    }
                    value={item.minTolerance}
                    size={"sm"}
                    placeholder={"ej: 0.5340"}
                    borderWidth={0}
                  />
                  <Text
                    color={textColor}
                    fontSize="xs"
                    borderWidth={0}
                    isTruncated
                    alignSelf={"center"}
                  >
                    {parseFloat(item.minToleranceResult).toFixed(5)}
                  </Text>
                </Grid>
              </>
            );
          }
        })}
        <GridItem />
        <GridItem borderRightWidth={1} borderColor={borderColor} />
        <Grid
          borderLeftWidth={1}
          borderRightWidth={1}
          borderBottomWidth={1}
          px={2}
          borderColor={borderColor}
        />
        <Grid
          borderWidth={1}
          px={1}
          borderColor={borderColor}
          justifyItems={"center"}
        >
          <Text alignSelf={"center"} fontSize={"xs"}>
            Tolerancia Máxima
          </Text>
        </Grid>
        {dimensionTestTable?.map((item, index) => {
          if (item.dimension) {
            return (
              <>
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  borderWidth={1}
                  px={1}
                  borderColor={borderColor}
                  justifyContent={"space-between"}
                >
                  <Input
                    alignSelf={"center"}
                    p={0}
                    onChange={(e) =>
                      updateDimensionData(index, "maxTolerance", e.target.value)
                    }
                    value={item.maxTolerance}
                    size={"sm"}
                    placeholder={"ej: 0.5340"}
                    borderWidth={0}
                  />
                  <Text
                    color={textColor}
                    fontSize="xs"
                    borderWidth={0}
                    isTruncated
                    alignSelf={"center"}
                  >
                    {parseFloat(item.maxToleranceResult).toFixed(5)}
                  </Text>
                </Grid>
              </>
            );
          }
        })}
        <GridItem borderBottomWidth={1} borderColor={borderColor} />
        <GridItem
          borderRightWidth={1}
          borderBottomWidth={1}
          borderColor={borderColor}
        />
        {order.map((or, index) => {
          return (
            <>
              <Grid
                px={2}
                borderWidth={1}
                borderColor={borderColor}
                borderBottomLeftRadius={index === 8 ? 15 : 0}
                justifyItems={"center"}
                alignItems={"center"}
              >
                {index + 1}
              </Grid>
              <Grid
                justifyItems={"center"}
                alignItems={"center"}
                borderWidth={1}
                borderColor={borderColor}
              >
                {or}
              </Grid>
              {dimensionTestTable?.map((item, i) => {
                if (item.dimension) {
                  return (
                    <GridItem
                      borderColor={borderColor}
                      borderWidth={1}
                      key={`${or}-${i}-${index}-${item.dimension}`}
                    >
                      <Input
                        value={item.data[or]}
                        placeholder={or}
                        borderWidth={0}
                        onChange={(e) =>
                          handleInputChange(i, or, e.target.value)
                        }
                      />
                    </GridItem>
                  );
                } else if (item.final) {
                  return (
                    <>
                      <GridItem
                        borderWidth={1}
                        key={`check-${or}`}
                        borderColor={borderColor}
                      >
                        <Select
                          placeholder={"Seleccionar opcion..."}
                          styles={customStyles}
                          options={selectOptions}
                          value={selectOptions.find(
                            (option) => option.value === item.check[or]
                          )}
                          onChange={(selectedOption) =>
                            handleCheckChange(4, or, selectedOption.value)
                          }
                        />
                      </GridItem>
                      <GridItem
                        borderWidth={1}
                        borderBottomRightRadius={or === "MAX-MAX" && 15}
                        key={`final-${or}`}
                        borderColor={borderColor}
                      >
                        <Input
                          value={item.final[or]}
                          borderWidth={0}
                          onChange={(e) =>
                            handleFinalChange(4, or, e.target.value)
                          }
                        />
                      </GridItem>
                    </>
                  );
                }
                return null; // Retorna null si no se cumple la condición
              })}
            </>
          );
        })}
      </Grid>
      <Button
        colorScheme={"facebook"}
        mt={5}
        isLoading={isLoading}
        onClick={() => {
          saveDimensionTestTable();
          setSaved(true);
        }}
      >
        {saved ? "Editar Tabla Dimension Test" : "Guardar Tabla Dimension Test"}
      </Button>
    </Grid>
  );
};

export default DimensionTestTable;
