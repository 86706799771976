import { Grid, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { cardsData, MetallicCard } from "../utilities";

const Body = () => {
  const bgColor = useColorModeValue(
    "linear(to-r, #d49506, #e6ac00)",
    "linear(to-r, #4A5568, #2D3748)"
  );

  return (
    <Grid
      p={5}
      mx={5}
      mt={5}
      borderRadius={15}
      backgroundColor={"#d49506"}
      gap={6}
      justifyItems={"center"}
      bgGradient={bgColor} // Aplicar el degradado aquí
    >
      <Text color={"white"} fontSize={"2xl"} fontWeight={"bold"}>
        Todos nuestros resultados los logramos por medio de nuestro sistema de 5
        R's
      </Text>
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        {cardsData.map((card, index) => (
          <MetallicCard key={index}>
            <Text fontSize="xl" fontWeight="bold" color="black">
              {card.title}
            </Text>
            <Text color="black">{card.content}</Text>
          </MetallicCard>
        ))}
      </Grid>
    </Grid>
  );
};

export default Body;
