import Card from "../../../../../components/Card/Card";
import { colors } from "../../../../../theme/colors";
import CardHeader from "../../../../../components/Card/CardHeader";
import { cardHeaderStyles } from "../../../../../theme/styles";
import HorizontalDivider from "../../../../../components/dividers/HorizontalDivider";
import { Button, Flex, GridItem, Heading } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ProjectContext from "../../../../../contexts/ProjectContext";
import CustomGrid from "../../../individual/components/CustomGrid";
import GoBackButton from "../../../individual/components/Buttons/GoBackButton";

const EditBase = ({
  title,
  children,
  tittleButton = "Terminar",
  onFinish,
  disabled = false,
}) => {
  const history = useHistory();
  const { isLoading, project } = useContext(ProjectContext);

  return (
    <CustomGrid repeat={4} mt={{ base: "150px", md: "100px" }}>
      <GridItem colSpan={4}>
        <Heading as={"h1"} color={"gray.600"}>
          {title}
        </Heading>
      </GridItem>
      <GridItem colSpan={4}>
        <GoBackButton />
      </GridItem>
      {children}
      <GridItem colSpan={4}>
        <Button
          colorScheme={"facebook"}
          mx={5}
          isLoading={isLoading}
          onClick={
            onFinish
              ? onFinish
              : () => history.push(`/admin/project/${project.id}`)
          }
          disabled={disabled || isLoading}
        >
          {tittleButton}
        </Button>
      </GridItem>
    </CustomGrid>
  );
};

export default EditBase;
