import { Grid, GridItem, Image, Text } from "@chakra-ui/react";
import img1 from "./../../../../assets/img/collaborators/shelter/shelter-body-10.jpg";
import img2 from "./../../../../assets/img/collaborators/shelter/shelter-body-11.jpg";
import img3 from "./../../../../assets/img/collaborators/shelter/shelter-body-12.jpg";
import img4 from "./../../../../assets/img/collaborators/shelter/shelter-body-13.jpg";

const ShelterBody2 = ({ textColor }) => {
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      borderRadius={15}
      backgroundColor={"white"}
      justifyItems={"center"}
      justifyContent={"center"}
      gap={6}
    >
      <Text fontSize={"5xl"} fontWeight={"bold"} color={textColor}>
        HACEMOS QUE LAS COSAS SUCEDAN
      </Text>
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <GridItem colSpan={2}>
          <Grid>
            <Text fontWeight={"bold"} fontSize={"4xl"}>
              Stands y Producción
            </Text>
            <Text fontWeight={"bold"} fontSize={"xl"} color={textColor}>
              Diseñamos, producimos e instalamos stands promocionales en
              Exposiciones y Convenciones. Producimos una gran variedad de
              material promocional.
            </Text>
          </Grid>
        </GridItem>
        <Image src={img1} borderRadius={15} />
        <Image src={img2} borderRadius={15} />
        <GridItem colSpan={2}>
          <Image src={img3} borderRadius={15} />
        </GridItem>
        <GridItem colSpan={2}>
          <Grid>
            <Text fontWeight={"bold"} fontSize={"4xl"}>
              Manejo de Medios
            </Text>
            <Text fontWeight={"bold"} fontSize={"xl"} color={textColor}>
              Desarrollamos, coordinamos y supervisamos tus campañas de medios
              de acuerdo a la estrategia o plan de mercadotecnia de tu marca.
            </Text>
          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <Grid>
            <Text fontWeight={"bold"} fontSize={"4xl"}>
              Soluciones Digitales
            </Text>
            <Text fontWeight={"bold"} fontSize={"xl"} color={textColor}>
              Diseñamos e implementamos soluciones digitales como páginas web,
              virtualización de negocios y promoción digital.
            </Text>
          </Grid>
        </GridItem>
        <GridItem
          colSpan={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="desiredHeight"
          width="100%"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "15px",
              backgroundImage: `url(${img4})`,
              backgroundPosition: "center", // Ajusta según necesites
              backgroundSize: "cover", // Cubre el div completamente, ajusta según necesites
              backgroundRepeat: "no-repeat",
            }}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default ShelterBody2;
