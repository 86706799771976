import {isFloatString} from "../../../../utilities/functions";

export const validateHeaderField = (field, value) => {
  console.log(field, value);
  if (analisisFloatData.includes(field)) {
    // Si el campo está incluido en analisisFloatData, aplicar la lógica para números flotantes
    return isFloatString(value) ? "" : "Este campo debe ser un número válido.";
  }

  if (analisisData.includes(field)) {
    return value === "MALA" || value === "REGULAR" || value === "BUENA" ? "" : "El valor debe de ser BUENA, MALA o REGULAR"
  }

  if (field === "addUomTemp" || field === "addUomLong" || field === "addUomRotationSpeed" || field === "addUomPress" || field === "addUomSpeed") {

  }

  switch (field) {
    case "addUomTemp":
      return value === "F" || value === "C"
        ? ""
        : "Unidad de Medida de Temperatura debe ser 'F' o 'C'.";
    case "geaUdM":
      return value === "Kgs" || value === "Lbs"
        ? ""
        : "Unidad de Medida de Peso debe ser 'Kgs' o 'Lbs'.";
    case "geaRegrindPercent":
    case "geaOtherAdditives":
      const percent = Number(value);
      return percent >= 0 && percent <= 100
        ? ""
        : `Este campo debe estar entre 0 y 100.`;
    case "geaEinarPercent":
      const einarPercent = Number(value);
      return einarPercent >= 0.3 && einarPercent <= 1
        ? ""
        : "Porcentaje Einar debe estar entre 0.3 y 1.";
    case "geaCavities":
    case "geaSampleSize":
      const isInteger = Number.isInteger(Number(value));
      return isInteger ? "" : `Este Campo debe ser un número entero.`;
    case "geaPartWeight":
      return isFloatString(value) ? "" : "Peso de Pieza debe ser un número válido.";
    case "analisisComments":
      return value.length > 250 ? "" : "Los Comentarios no pueden tener mas de 250 caracteres"
    case "currency":
      return value === "MXN" || value === "USD" ? "" : "El valor debe de ser MXN o USD"
    case "geaVirginResinCost":
      return isFloatString(value) ? "" : "El valor del costo debe de ser un numero valido"
    default:
      return value ? "" : "Este campo es requerido.";
  }
};

// Define una lista de campos que deseas verificar
const camposHeader = [
  "geaUserID",
  "phase",
  "geaCompany",
  "geaUdM",
  "geadate",
  "geaProduct",
  "geaPartWeight",
  "geaMaterial",
  "geaRegrindPercent",
  "geaSampleSize",
  "geaEinarPercent",
  "geaOtherAdditives",
  "geaCavities",
];

// Modifica la función isAllHeaderValid para que solo verifique los campos relevantes
export const isAllHeaderValid = (geaDataInput, errors) => {
  // Filtra y verifica si todos los campos relevantes han sido llenados
  const allFieldsFilled = camposHeader.every(campo => geaDataInput[campo] !== '');

  // Filtra y verifica si no hay errores en los campos relevantes
  const noErrors = camposHeader.every(campo => !errors[campo] || errors[campo] === '');

  return allFieldsFilled && noErrors;
};

const analisisFloatData = [
  "geaRearBefore",
  "geaRearAfter",
  "geaCenterBefore",
  "geaCenterAfter",
  "geaFrontBefore",
  "geaFrontAfter",
  "geaNozzleBefore",
  "geaNozzleAfter",
  "geaShotSizeBefore",
  "geaShotSizeAfter",
  "geaTransferBefore",
  "geaTransferAfter",
  "geaRpmBefore",
  "geaRpmAfter",
  "geaBackpressBefore",
  "geaBackpressAfter",
  "geaInjSpeedBefore",
  "geaInjSpeedAfter",
  "geaInjPressBefore",
  "geaInjPressAfter",
  "geaTempMeltBefore",
  "geaTempMeltAfter",
  "geaCushionBefore",
  "geaCushionAfter",
  "geaMaxPressBefore",
  "geaMaxPressAfter",
  "geaRecoveryTimeBefore",
  "geaRecoveryTimeAfter",
  "geaFillTimeBefore",
  "geaFillTimeAfter",
  "geaCycleTimeBefore",
  "geaCycleTimeAfter",
  "geaRpmAmpBefore",
  "geaRpmAmpAfter",
  "geaInjectionAmpBefore",
  "geaInjectionAmpAfter",
  "geaShotWeightBefore",
  "geaShotWeightAfter",
  "MoldReleaseCost",
  "ProdHourCost",
  "kwCost",
]

const analisisData = [
  "geaMoldReleaseBefore",
  "geaMoldReleaseAfter",
  "geaAntiDustBefore",
  "geaAntiDustAfter",
  "geaAntiStaticBefore",
  "geaAntiStaticAfter",
  "geaDenestingBefore",
  "geaDenestingAfter",
]
