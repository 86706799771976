import React from "react";
import IndividualBase from "../../components/IndividualBase";
import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IndividualMelt3030Form from "./components/IndividualMelt3030Form";
import HelmetComponent from "../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import { GridItem } from "@chakra-ui/react";
import CustomGrid from "../../components/CustomGrid";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
const IndividualMelt3030 = () => {
  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <HeaderProvider>
        <GridItem width={"100%"} mt={"120px"}>
          <CustomGrid>
            <IndividualBase
              title={"Melt 30/30"}
              description={description}
              instructions={instructions}
            >
              <IndividualMelt3030Form />
            </IndividualBase>
          </CustomGrid>
        </GridItem>
      </HeaderProvider>
    </>
  );
};

export default IndividualMelt3030;
