import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { Button, Grid, GridItem, Text } from "@chakra-ui/react";
import Select from "react-select";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { useSustainingPressureHooks } from "./hooks/useSustainingPressureHooks";
import { badgeColor, borderColor, textColor } from "../../utilities/variables";
import { ppsiToPbar } from "../../../../individual/hooks/useUomTransform";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";

const EditSustainingPressure = () => {
  const { id } = useParams();
  const { project, updateProject, isLoading, resin } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    min_Hold_Press_Ppsi,
    max_Hold_Press_Ppsi,
    inj_Press_Required,
    max_Pack_Press,
  } = project;

  const { minimumPackingPressure } = resin;
  const {
    selectOptions,
    onChangeSelect,
    onSubmit,
    pass,
    setPass,
  } = useSustainingPressureHooks();

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <EditBase tittleButton={"Terminar"} title={"Presión de Sostenimiento"}>
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            mx={5}
            p={5}
            borderWidth={1}
            borderRadius={15}
            borderColor={borderColor}
          >
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={4}
              justifyItems={"center"}
            >
              <GridItem h="10">
                <DataDisplayBadge
                  type={"percentaje"}
                  color={textColor}
                  badgeColorScheme={badgeColor}
                  rightAddon={"%"}
                  fix={2}
                  value={minimumPackingPressure}
                  title={"Minimo de Presion de Empaque"}
                />
              </GridItem>
              <GridItem h="10">
                <DataDisplayBadge
                  type={"pressure"}
                  color={textColor}
                  badgeColorScheme={badgeColor}
                  rightAddon={ppsiToPbar(project?.uom)}
                  value={inj_Press_Required}
                  title={"Presion de Inyección Requerida"}
                />
              </GridItem>
            </Grid>
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={4}
              justifyItems={"center"}
            >
              <GridItem h="10">
                <DataDisplayBadge
                  type={"pressure"}
                  color={textColor}
                  badgeColorScheme={badgeColor}
                  rightAddon={
                    min_Hold_Press_Ppsi ? ppsiToPbar(project?.uom) : ""
                  }
                  value={min_Hold_Press_Ppsi}
                  title={"Presión Sostenimiento Minima"}
                />
              </GridItem>
              <GridItem h="10">
                <DataDisplayBadge
                  type={"pressure"}
                  color={textColor}
                  badgeColorScheme={badgeColor}
                  rightAddon={
                    min_Hold_Press_Ppsi ? ppsiToPbar(project?.uom) : ""
                  }
                  value={max_Hold_Press_Ppsi}
                  title={"Presion de Sostenimiento Maxima"}
                />
              </GridItem>
            </Grid>
          </Grid>
          <Grid
            mx={5}
            p={5}
            borderWidth={1}
            borderRadius={15}
            gap={6}
            mt={5}
            borderColor={borderColor}
          >
            <Grid justifyItems={"center"} gap={6}>
              <Text color={textColor} fontWeight={"bold"}>
                Maxima Presion de Empaque
              </Text>
              <Select
                isDisabled={pass}
                onChange={(e) => onChangeSelect(e)}
                defaultValue={max_Pack_Press}
                options={selectOptions}
                isLoading={isLoading}
                isSearchable={true}
                placeholder={"Selecciona un elemento"}
              />
            </Grid>
            <Button
              colorScheme={"facebook"}
              disabled={!minimumPackingPressure || !max_Pack_Press}
              isLoading={isLoading}
              onClick={pass ? () => setPass() : () => onSubmit()}
            >
              {pass ? "Editar" : "Guardar Datos"}
            </Button>
          </Grid>
        </GridItem>
      </EditBase>
    </>
  );
};

export default EditSustainingPressure;
