
interface UomProps {
  uom: "INGLES" | "INTERNACIONAL"
}
export const temp = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "°F"
  } else {
    return "°C"
  }
}

export const ozGr = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "Oz"
  } else {
    return "gr"
  }
}

export const lbKg = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "Lb"
  } else {
    return "Kg"
  }
}

export const ozMinGrMin = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "oz/min"
  } else {
    return "gr/min"
  }
}
export const lbHrKgHr = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "lb/Hr"
  } else {
    return "Kg/Hr"
  }
}

export const inMm = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "in"
  } else {
    return "mm"
  }
}

export const inSquareMmSquare = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "in²"
  } else {
    return "mm²"
  }
}

export const inSquareSecMmSquareSec = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "in²/sec"
  } else {
    return "mm²/seg"
  }
}
export const inCubeMmCube = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "in³"
  } else {
    return "mm³"
  }
}
export const inCubeCmCube = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "in³"
  } else {
    return "cm³"
  }
}

export const hpsiToHbar = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "Hpsi"
  } else {
    return "Hbar"
  }
}
export const ppsiToPbar = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "Ppsi"
  } else {
    return "Pbar"
  }
}

export const psiTobar = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "psi"
  } else {
    return "bar"
  }
}

export const inSecMmsec = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "in/seg"
  } else {
    return "mm/seg"
  }
}

export const tonKn = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "TON"
  } else {
    return "kN"
  }
}

export const tonInKnMm = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "TON/in"
  } else {
    return "kN/mm"
  }
}
export const tonInSquareKnMmSquare = (uom: UomProps) => {
  if (uom === "INGLES") {
    return "TON/in²"
  } else {
    return "kN/mm²"
  }
}

