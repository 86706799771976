export const marginBottomDivider = 5;

export const textColor = "gray.600"

export const initialToolStateRadio = {
  toolHydraulicSystem: "SI",
  toolPneumaticSystem: "SI",
  toolVacummSystem: "SI",
  uom: "INGLES",
  toolType: "HORIZONTAL",
  gateForm: "CIRCULAR",
  toolGrade: "TIPO_101"
};

export const initialToolState = {
  toolId: "",
  serialNumber: "",
  numberOfCavities: 0,
  horizontalMeasurement: 0,
  verticalMeasurement: 0,
  stackMold: 0,
  shootVolume: 0,
  runnerVolume: 0,
  cycleTime: 0,
  gateSize: 0,
  gateDiameter: 0,
  widthGate: 0,
  heightGate: 0,
  maxMoldEject: 0,
  deepGate: 0,
};

export const moldColumnsData = [
  {
    Header: "Unidad de Medida",
    accessor: "uom",
    type: "radio",
  },
  {
    Header: "ID del molde",
    accessor: "toolId",
    type: "string",
  },
  {
    Header: "Numero de Serie",
    accessor: "serialNumber",
    type: "string",
  },
  {
    Header: "ID del producto",
    accessor: "product",
    type: "string",
  },
  {
    Header: "Numero de Cavidaddes",
    accessor: "numberOfCavities",
    type: "string",
  },
  /*
  {
    Header: "Medida Horizontal",
    accessor: "horizontalMeasurement",
    type: "string",
  },
  {
    Header: "Medida Vertical",
    accessor: "verticalMeasurement",
    type: "string",
  },
  {
    Header: "Altura del molde (STACK)",
    accessor: "stackMold",
    type: "string",
  },
  {
    Header: "Volumen de disparo",
    accessor: "shootVolume",
    type: "string",
  },
  {
    Header: "Volumen de cavidad",
    accessor: "cavityVolume",
    type: "string",
  },
  {
    Header: "Volumen de runner",
    accessor: "runnerVolume",
    type: "string",
  },
  {
    Header: "Tiempo de ciclo",
    accessor: "cycleTime",
    type: "string",
  },
  {
    Header: "Tamaño de compuerta",
    accessor: "gateSize",
    type: "string",
  },
  {
    Header: "Forma de la compuerta",
    accessor: "gateForm",
    type: "radio",
  },
  {
    Header: "Diametro de la compuerta",
    accessor: "gateDiameter",
    type: "string",
  },
  {
    Header: "Base de la compuerta",
    accessor: "widthGate",
    type: "string",
  },
  {
    Header: "Altura de la compuerta",
    accessor: "heightGate",
    type: "string",
  },
  {
    Header: "Grosor de la compuerta",
    accessor: "deepGate",
    type: "string",
  },
  {
    Header: "Expulsión de molde maxima",
    accessor: "maxMoldEject",
    type: "string",
  },
  {
    Header: "Sistema Hidraulico",
    accessor: "toolHydraulicSystem",
    type: "radio",
  },
  {
    Header: "Sistema Neumatico",
    accessor: "toolPneumaticSystem",
    type: "radio",
  },
  {
    Header: "Sistema Vacio",
    accessor: "toolVacummSystem",
    type: "radio",
  },

   */
];
