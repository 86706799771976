import React from "react";
import { Text, Flex } from "@chakra-ui/react";

const DataDisplayBadge = ({
  title,
  value,
  badgeColorScheme = "cyan",
  rightAddon = "",
  type,
  size = "md",
  color = "gray.200",
  mx = "",
  my = "",
  justifyContent = "space-between",
}) => {
  const formatValue = (value, type) => {
    if (value === null || value === undefined) return "N/A";
    switch (type) {
      case "weight":
        return parseFloat(value).toFixed(3);
      case "long":
        return parseFloat(value).toFixed(3);
      case "percentaje":
        return parseFloat(value).toFixed(2);
      case "text":
        return value;
      case "int":
        return parseFloat(value).toFixed(0);
      case "time":
        return parseFloat(value).toFixed(2);
      case "pressure":
        return parseFloat(value)
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      case "temp":
        return parseFloat(value).toFixed(1);
      case "quantity":
        return parseFloat(value).toFixed(0);
      case "volume":
        return parseFloat(value).toFixed(3);
      case "dif":
        return parseFloat(value).toFixed(5);
      default:
        return parseFloat(value).toFixed(3);
    }
  };
  const formattedValue = formatValue(value, type);

  return (
    <Flex justifyContent={justifyContent} alignItems={"center"} mx={mx} my={my}>
      <Text
        fontSize={size}
        fontWeight="bold"
        fontStyle="italic"
        mx={2}
        color={color}
      >
        {title}
      </Text>
      <Flex
        alignItems="center"
        borderWidth={2}
        bgColor={badgeColorScheme && `${badgeColorScheme}.100`}
        variant={"outline"}
        borderColor={value ? badgeColorScheme : "red"}
        fontSize={"xs"}
        fontWeight={"bold"}
        borderRadius={15}
        px={2}
        py={1}
        color={color}
      >
        {type === "text" ? (
          <Text>{value}</Text>
        ) : (
          <>
            <Text>{formattedValue}</Text>
            {rightAddon && <Text ml={1}>{rightAddon}</Text>}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default DataDisplayBadge;
