import {useContext, useEffect, useState} from "react";
import ProjectContext from "../../contexts/ProjectContext";
import { DataStore } from "aws-amplify/datastore";
import { Project } from "../../models";

export const useEditProjectedAreaHooks = () => {
  const { project, updateLoading, updateProject, resin } = useContext(ProjectContext);

  const [showCavityArea, setShowCavityArea] = useState(false);
  const [showRunnerArea, setShowRunnerArea] = useState(false);
  const [showGapArea, setShowGapArea] = useState(false);
  const [showData, setShowData] = useState(false);
  const [canFinish, setCanFinish] = useState(false);
  const [finish, setFinish] = useState(false)

  useEffect(() => {
    const newMinTonSug =
      parseFloat(project.projectedArea) *
      parseFloat(resin.tonnageFactorMin) *
      (parseFloat(project.insuranceFactor) / 100 + 1);
    const newMaxTonSug =
      parseFloat(project.projectedArea) *
      parseFloat(resin.tonnageFactorMax) *
      (parseFloat(project.insuranceFactor) / 100 + 1);
    const newNomTonSug =
      (parseFloat(newMinTonSug) + parseFloat(newMaxTonSug)) / 2;
    updateProject({
      ...project,
      minTonSug: newMinTonSug,
      maxTonSug: newMaxTonSug,
      nomTonSug: newNomTonSug,
    });
  }, [project.insuranceFactor]);

  const isLastElementComplete = (element) => {
    if (!element.form) return false;

    if (element.form === "CIRCULO") {
      return element.diameter !== "" && element.cavities !== "";
    }
    return (
      element.base !== "" && element.height !== "" && element.cavities !== ""
    );
  };

  const isLastRunnerComplete = () => {
    const lastRunner = project.runners[project.runners.length - 1];
    return (
      project.runners.length === 0 ||
      (project.runners.length === 1 && !lastRunner.form) ||
      isLastElementComplete(lastRunner)
    );
  };

  const isLastGapComplete = () => {
    const lastGap = project.gaps[project.gaps.length - 1];
    return (
      project.gaps.length === 0 ||
      (project.gaps.length === 1 && !lastGap.form) ||
      isLastElementComplete(lastGap)
    );
  };

  const isLastCavityComplete = () => {
    return (
      project.cavities.length === 0 ||
      isLastElementComplete(project.cavities[project.cavities.length - 1])
    );
  };

  const isButtonDisabled = () => {
    const cavityAreaValid = project.cavityArea > 0;

    return (
      !cavityAreaValid ||
      !isLastGapComplete() ||
      !isLastRunnerComplete() ||
      !isLastCavityComplete()
    );
  };

  const handleClick = async () => {
    // Asegurarse de que runnerArea y gapArea sean números (0 si son null o undefined)
    const adjustedRunnerArea = project.runnerArea ?? 0;
    const adjustedGapArea = project.gapArea ?? 0;

    // Actualizar el proyecto con runnerArea y gapArea ajustados si es necesario
    if (
      project.runnerArea !== adjustedRunnerArea ||
      project.gapArea !== adjustedGapArea
    ) {
      await updateProject({
        ...project,
        runnerArea: adjustedRunnerArea,
        gapArea: adjustedGapArea,
      });
    }

    updateLoading(true);
    const p = await DataStore.query(Project, project.id);
    const area =
      parseFloat(adjustedRunnerArea) +
      parseFloat(project.cavityArea) -
      parseFloat(adjustedGapArea);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.projectedArea = area.toString();
        updated.cavityArea = project.cavityArea;
        updated.gapArea = adjustedGapArea.toString();
        updated.runnerArea = adjustedRunnerArea.toString();
      })
    )
      .then(async (res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowData(true);
        updateLoading(false);
      });
  };
  //tonnageFactorMin - tonnageFactorMax

  const isFloatString = (str) => {
    if (typeof str !== "string") return false;

    return /^-?\d+(\.\d+)?$/.test(str);
  };

  const onSaveInsuranceFactor = async () => {
    updateLoading(true);
    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.insuranceFactor = project.insuranceFactor.toString();
        updated.minTonSug = project.minTonSug.toString();
        updated.nomTonSug = project.nomTonSug.toString();
        updated.maxTonSug = project.maxTonSug.toString();
        updated.phase = "2";
      })
    )
      .then((res) => {
        setFinish(true);
        updateProject(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        updateLoading(false);
        setCanFinish(true);
      });
  };

  const disabled = isButtonDisabled();

  return {
    showCavityArea,
    showRunnerArea,
    showGapArea,
    setShowCavityArea,
    setShowRunnerArea,
    setShowGapArea,
    disabled,
    handleClick,
    isFloatString,
    onSaveInsuranceFactor,
    canFinish,
    showData,
    setShowData,
    finish,
    setFinish
  };
};
