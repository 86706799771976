import {Button, Heading, Image, Text, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";

import React from "react";
import {useHistory} from "react-router-dom";
import {Grid, Link} from "@chakra-ui/react";
import mvpsLogo from "./variables/s3-images";

export const components = {


  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Amplify logo" src={mvpsLogo} width={"250px"} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <>
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={"white"}>
            moldingvps.com &copy; Todos los derechos reservados
          </Text>
        </View>
      </>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <>
          <Heading
            padding={`${tokens.space.medium} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Bienvenido a moldingvps
          </Heading>
        </>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Restablecer contraseña
          </Button>
        </View>
      );
    },
  },
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.medium} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Recupera tu contraseña
        </Heading>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <>
          <Heading
            padding={`${tokens.space.medium} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Bienvenido a moldingvps
          </Heading>
          <Text padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={2}>
            Aquí encontraras una gran variedad de funciones y ejercicios que te
            ayudarán a desarrollar, verificar y mejorar tus Procesos de Moldeo.
            Regístrate Gratis para acceder y conocer todos los beneficios de
            nuestra página!!
          </Text>
        </>
      );
    },
    Footer() {
      const history = useHistory();
      const { toSignIn } = useAuthenticator();

      const originalPath = window.location.origin;
      console.log(history, originalPath);


      return (
        <View textAlign="center">
         <Grid>
           <Text>
             Al registrarse, acepta estar de acuerdo con los terminos y condiciones de moldingvps.com
           </Text>
           <Link href={`${originalPath}/banner/pages/terms-and-conditions`} isExternal>Ver los Terminos y condiciones.</Link>
           <br />
           <Button
             fontWeight="normal"
             onClick={toSignIn}
             size="small"
             variation="link"
           >
             Regresar al inicio de sesion
           </Button>
         </Grid>

        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Ingresa el Codigo:
        </Heading>
      );
    },
    Footer() {
      return (
        <Text>
          Revisa tu correo electronico e ingresa el código de confirmación
        </Text>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.medium} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Introduce el código de verificacion
        </Heading>
      );
    },
  },
};

export const formFields = {
  forgotPassword: {
    username: {
      placeholder: "Introduce tu correo electronico:",
    },
  },
};