import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { formaOptions } from "../utilities/variables";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import { useCavityHooks } from "../../../../../../../hooks/projected-area-forms/useCavityHooks";
import { inMm } from "../../../../../individual/hooks/useUomTransform";

const CavityForm = ({ showCavityArea, setShowCavityArea }) => {
  const { project, isLoading } = useContext(ProjectContext);
  const {
    finishCavities,
    removeCavity,
    addCavity,
    isLastCavityComplete,
    customStyles,
    errors,
    handleInputChange,
  } = useCavityHooks();

  const textColor = "gray.600";
  const borderColor = "gray.400";
  return (
    <GridItem
      gap={5}
      color={"gray.700"}
      borderWidth={1}
      borderRadius={15}
      borderColor={"gray.600"}
    >
      {!showCavityArea ? (
        <>
          {project.cavities.map((cavity, index) => (
            <Box p={5} key={index} width="100%">
              <Text
                fontSize={"xl"}
                fontWeight={"bold"}
                color={textColor}
                mb={2}
              >
                Cavidad {index + 1}
              </Text>
              <FormControl
                isInvalid={errors[index] && !!errors[index].form}
                mb={2}
              >
                <FormLabel>
                  <Text color={textColor}>Forma</Text>
                </FormLabel>
                <Select
                  isClearable={true}
                  styles={customStyles}
                  color={"gray.600"}
                  options={formaOptions}
                  value={formaOptions.find(
                    (option) => option.value === cavity.form
                  )}
                  onChange={(selectedOption) =>
                    handleInputChange(index, "form", selectedOption?.value)
                  }
                />
                <FormErrorMessage>
                  {errors[index] && errors[index].form}
                </FormErrorMessage>
              </FormControl>
              {cavity.form && (
                <>
                  {cavity.form === "CIRCULO" ? (
                    <>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].diameter}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor}>Diámetro</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            borderColor={borderColor}
                            color={textColor}
                            value={cavity.diameter}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "diameter",
                                e.target.value
                              )
                            }
                            placeholder="Diámetro"
                          />
                          <InputRightAddon children={inMm(project?.uom)} />
                        </InputGroup>
                        <FormErrorMessage>
                          {errors[index] && errors[index].diameter}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].cavities}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor}>Cavidades</Text>
                        </FormLabel>
                        <Input
                          backgroundColor={"white"}
                          borderColor={borderColor}
                          color={textColor}
                          value={cavity.cavities}
                          onChange={(e) =>
                            handleInputChange(index, "cavities", e.target.value)
                          }
                          placeholder="Cavidades"
                        />
                        <FormErrorMessage>
                          {errors[index] && errors[index].cavities}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].base}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor}>Base</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            borderColor={borderColor}
                            color={textColor}
                            value={cavity.base}
                            onChange={(e) =>
                              handleInputChange(index, "base", e.target.value)
                            }
                            placeholder="Base"
                          />
                          <InputRightAddon children={inMm(project?.uom)} />
                        </InputGroup>
                        <FormErrorMessage>
                          {errors[index] && errors[index].base}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].height}
                      >
                        <FormLabel>
                          <Text color={textColor}>Altura</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            borderColor={borderColor}
                            color={textColor}
                            value={cavity.height}
                            onChange={(e) =>
                              handleInputChange(index, "height", e.target.value)
                            }
                            placeholder="Altura"
                          />
                          <InputRightAddon children={inMm(project?.uom)} />
                        </InputGroup>

                        <FormErrorMessage>
                          {errors[index] && errors[index].height}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].cavities}
                      >
                        <FormLabel>
                          <Text color={textColor}>Cavidades</Text>
                        </FormLabel>
                        <Input
                          backgroundColor={"white"}
                          borderColor={borderColor}
                          color={textColor}
                          value={cavity.cavities}
                          onChange={(e) =>
                            handleInputChange(index, "cavities", e.target.value)
                          }
                          placeholder="Cavidades"
                        />
                        <FormErrorMessage>
                          {errors[index] && errors[index].cavities}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}
                </>
              )}
            </Box>
          ))}
          <Flex p={5} justifyContent={"space-between"} direction={"column"}>
            <Flex justifyContent={"space-between"} mb={1}>
              <Button
                colorScheme={"whatsapp"}
                mx={1}
                w={
                  project.cavities && project.cavities.length > 1
                    ? "50%"
                    : "100%"
                }
                onClick={addCavity}
                disabled={!isLastCavityComplete()}
              >
                Añadir Cavidad
              </Button>
              {project.cavities && project.cavities.length > 1 && (
                <Button colorScheme={"red"} mx={1} onClick={removeCavity}>
                  Remover Cavidad
                </Button>
              )}
            </Flex>
          </Flex>
        </>
      ) : (
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={"blue"}
          title={" Área de la Cavidad"}
          value={project.cavityArea}
          my={2}
          mx={5}
        />
      )}
      <Flex
        px={5}
        pb={5}
        justifyContent={"space-between"}
        mt={1}
        direction={"column"}
      >
        <Button
          colorScheme={"facebook"}
          isLoading={isLoading}
          disabled={!isLastCavityComplete()}
          onClick={() => {
            if (showCavityArea) {
              setShowCavityArea(false);
            } else {
              finishCavities();
              setShowCavityArea(true);
            }
          }}
        >
          {showCavityArea ? "Editar" : "Terminar"}
        </Button>
      </Flex>
    </GridItem>
  );
};

export default CavityForm;
