import {
  Grid,
  GridItem,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import img1 from "./../../../../assets/img/collaborators/shelter/body-1.jpg";
import img2 from "./../../../../assets/img/collaborators/shelter/body-2.jpg";
import img3 from "./../../../../assets/img/collaborators/shelter/body-3.jpg";
import img4 from "./../../../../assets/img/collaborators/shelter/body-4.jpg";
import img5 from "./../../../../assets/img/collaborators/shelter/body-5.jpg";
const ShelterBody = ({ textColor }) => {
  //e8f1fc
  const metallicGrayGradient = useColorModeValue(
    "linear(to-r, #e8f1fc, #bdc3c7, #e8f1fc)", // Gradiente para el modo claro
    "linear(to-r, #bdc3c7, #99a3a4, #bdc3c7)" // Gradiente para el modo oscuro (opcional)
  );
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      borderRadius={15}
      bgGradient={metallicGrayGradient}
      justifyItems={"center"}
      gap={6}
    >
      <Grid templateColumns="repeat(6, 1fr)" gap={6} justifyItems={"center"}>
        <GridItem colSpan={3}>
          <Text color={textColor} fontSize={"4xl"} fontWeight={"bold"}>
            Nuestra Metodología
          </Text>
        </GridItem>
        <GridItem colSpan={3} />
        <GridItem>
          <Text color={textColor} fontSize={"2xl"} fontWeight={"bold"}>
            Análisis
          </Text>
        </GridItem>
        <Image src={img1} width={"50%"} />
        <GridItem colSpan={4}>
          <Text color={textColor} fontSize={"2xl"}>
            Hacemos una labor consultiva para entender las necesidades de marca
            y de tu negocio.
          </Text>
        </GridItem>
        <GridItem>
          <Text color={textColor} fontSize={"2xl"} fontWeight={"bold"}>
            Propuesta
          </Text>
        </GridItem>
        <Image src={img2} width={"50%"} />
        <GridItem colSpan={4}>
          <Text color={textColor} fontSize={"2xl"}>
            Desarrollamos una propuesta acorde a las necesidades detectadas y
            estrategia a ejecutar.
          </Text>
        </GridItem>
        <GridItem>
          <Text color={textColor} fontSize={"2xl"} fontWeight={"bold"}>
            Presupuesto
          </Text>
        </GridItem>
        <Image src={img3} width={"50%"} />
        <GridItem colSpan={4}>
          <Text color={textColor} fontSize={"2xl"}>
            Realizamos una propuesta económica y definimos los tiempos en los
            que entregaremos la solución personalizada.
          </Text>
        </GridItem>
        <GridItem>
          <Text color={textColor} fontSize={"2xl"} fontWeight={"bold"}>
            Ejecución
          </Text>
        </GridItem>
        <Image src={img4} width={"50%"} />
        <GridItem colSpan={4}>
          <Text color={textColor} fontSize={"2xl"}>
            Llevamos a cabo el desarrollo, producción e implementación en los
            tiempos y presupuesto acordados.
          </Text>
        </GridItem>
        <GridItem>
          <Text color={textColor} fontSize={"2xl"} fontWeight={"bold"}>
            Lanzamiento
          </Text>
        </GridItem>
        <Image src={img5} width={"50%"} />
        <GridItem colSpan={4}>
          <Text color={textColor} fontSize={"2xl"}>
            Realizamos la entrega formal del proyecto y servicio listo para ser
            utilizado, así como la evidencia de la conclusión del mismo.
          </Text>
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default ShelterBody;
