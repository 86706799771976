export const initialState = {
  maxInjectPressure: "",
  pistonDiameter: "",
  screwDiameter: "",
  pistonArea: "",
  screwArea: "",
  intensificationRatio: "",
  maxPlasticPressure: "",
}

export const keysForForm = ["maxInjectPressure", "pistonDiameter", "screwDiameter" ]

export const intRatioColumnsData = [
  {
    Header: "Maquina",
    accessor: "header.machineName",
    type: "string",
  },
  {
    Header: "Resina",
    accessor: "header.resinName",
    type: "string",
  },
  {
    Header: "Molde",
    accessor: "header.toolName",
    type: "string",
  },
  {
    Header: "Producto",
    accessor: "header.productName",
    type: "string",
  },
  {
    Header: "Fecha de Creación",
    accessor: "createdAt",
    type: "string",
  },
];