import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { HooperCapacity } from "../../../../../../../models";
import { PDFViewer } from "@react-pdf/renderer";
import "moment/locale/es";
import PdfBase from "../../../../components/Pdf/PdfBase";
import PdfEntries from "../../../../components/Pdf/PdfEntries";
import {
  lbHrKgHr,
  lbKg,
  ozGr,
  ozMinGrMin,
} from "../../../../hooks/useUomTransform";
const IndividualHooperCapacityPdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(HooperCapacity, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  const dataEntries1 = [
    {
      label: "Cantidad de Producción",
      value: data?.prodQuantity,
      type: "text",
      addOn: "Pcs",
    },
    {
      label: "Número de Cavidades",
      value: data?.numberOfCavities,
      type: "separated",
      addOn: "Pcs",
    },
    {
      label: "Tiempo de Ciclo",
      value: data?.estCycleTime,
      type: "time",
      addOn: "sec",
    },
  ];

  const dataEntries2 = [
    {
      label: "Riesgo de Scrap",
      value: data?.scrapRisk,
      type: "text",
      addOn: "%",
    },
    {
      label: "Capacidad de Tolva",
      value: data?.hopperCapacity,
      type: "weight",
      addOn: lbKg(data?.header?.uom),
    },
    {
      label: "Peso de Disparo al 100%",
      value: data?.shotWeight100,
      type: "weight",
      addOn: ozGr(data?.header?.uom),
    },
  ];

  const dataOutPuts1 = [
    {
      label: "Piezas Por Hora",
      value: data?.pcsPerHour,
      type: "separated",
      addOn: "Pcs/Hr",
    },
    {
      label: "Tiempo de Ciclo",
      value: data?.cycleTimeMin,
      type: "time",
      addOn: "min",
    },
    {
      label: "Consumo de Resina",
      value: data?.consumeLbPerHr,
      type: "time",
      addOn: lbHrKgHr(data?.header?.uom),
    },
    {
      label: "Duración de la Producción",
      value: data?.productionDuration,
      type: "time",
      addOn: "Hr",
    },
    {
      label: "Tiempo de Recarga",
      value: data?.reloadTime,
      type: "separated",
      addOn: "min",
    },
    {
      label: "Recargas despues de Inicio",
      value: data?.reloadReqAfterStart,
      type: "zero",
      addOn: "times",
    },
  ];

  const dataOutPuts2 = [
    {
      label: "Disparos Requeridos",
      value: data?.reqShotsForProd,
      type: "separated",
      addOn: "shots",
    },
    {
      label: "Consumo de Resina",
      value: data?.resinFlowPerMin,
      type: "time",
      addOn: ozMinGrMin(data?.header?.uom),
    },

    {
      label: "Capacidad de Tolva",
      value: data?.hopperCapacityPcs,
      type: "separated",
      addOn: "Pcs",
    },
    {
      label: "Resina Requerida",
      value: data?.reqResinForProd,
      type: "weight",
      addOn: lbKg(data?.header?.uom),
    },
    {
      label: "Tamaño de Recarga",
      value: data?.reloadSize,
      type: "weight",
      addOn: lbKg(data?.header?.uom),
    },
    {
      label: "Recorrido de Resina",
      value: data?.resinTroughHopper,
      type: "separated",
      addOn: "min",
    },
  ];

  const MyDocument = ({ data }) => (
    <PdfBase data={data} name={"Capacidad de la tolva"}>
      <PdfEntries
        title={"Entradas"}
        column1={dataEntries1}
        column2={dataEntries2}
      />

      <PdfEntries
        title={"Salidas"}
        column1={dataOutPuts1}
        column2={dataOutPuts2}
      />
    </PdfBase>
  );

  return (
    <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
      <MyDocument data={data} />
    </PDFViewer>
  );
  // Luego, usarías este `id` para obtener tus datos, probablemente con un useEffect y fetch o alguna llamada a una API.
};

export default IndividualHooperCapacityPdf;
