import { useState, useEffect, useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";

const useColorScale = () => {
  const [colorMap, setColorMap] = useState({});
  const { project } = useContext(ProjectContext);

  useEffect(() => {
    if (!project.windowProcess || !project.minimumAcceptablePercentage) {
      return;
    }

    const { windowProcess, minimumAcceptablePercentage } = project;

    const getColor = (value, minAcceptable) => {
      if (value < minAcceptable) {
        return "red";
      }
      const ratio = (value - minAcceptable) / (100 - minAcceptable);
      const red = Math.round(255 * (1 - ratio));
      const green = 255;

      return `rgb(${red}, ${green}, 0)`;
    };

    const minPercentage = Number(minimumAcceptablePercentage);
    let result = {};
    for (const key in windowProcess) {
      const value = Number(windowProcess[key]);
      result[key] = getColor(value, minPercentage);
    }

    setColorMap(result);
  }, [project.windowProcess, project.minimumAcceptablePercentage]);

  return colorMap;
};

export default useColorScale;
