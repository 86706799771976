import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { useCavityBalanceHooks } from "./hooks/useCavityBalanceHooks";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import Select from "react-select";
import LineChart from "../../../../../../components/Charts/LineChart";
import CavityBalanceTable from "./components/CavityBalanceTable";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { badgeColor, borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import { ozGr, ppsiToPbar } from "../../../../individual/hooks/useUomTransform";

const EditCavityBalance = () => {
  const { id } = useParams();
  const { project, updateProject, isLoading } = useContext(ProjectContext);
  const { getProject } = useGetProjectByIdHooks();

  const { shotWeight100, shotWeight1stStage, maxPlasticPressure } = project;

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    onChangeHandler,
    onChangeCavityWeight,
    dataChart,
    lineChartOptions,
    onSubmitHandler,
    showValues,
    setShowValues,
    onChangeSelect,
    options,
  } = useCavityBalanceHooks();

  return (
    <>
      <HelmetComponent
        title={metaTitle}
        keywords={metaKeywords}
        description={metaDescription}
      />
      <EditBase title={"Balance de Cavidades"} tittleButton={"Terminar"}>
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            borderColor={borderColor}
            templateColumns="repeat(3, 1fr)"
            gap={6}
            borderWidth={1}
            borderRadius={15}
            mx={5}
            p={5}
            mb={5}
          >
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              title={"Peso de disparo 100%"}
              value={shotWeight100}
              rightAddon={ozGr(project?.uom)}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              title={"Peso Primera Etapa"}
              value={shotWeight1stStage}
              rightAddon={ozGr(project?.uom)}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              title={"Máx Presión Plástica"}
              type={"pressure"}
              value={maxPlasticPressure}
              rightAddon={ppsiToPbar(project?.uom)}
            />
          </Grid>
        </GridItem>
        <GridItem colSpan={4}>
          <CavityBalanceTable
            onChangeHandler={onChangeHandler}
            onChangeCavityWeight={onChangeCavityWeight}
            onSubmitHandler={onSubmitHandler}
            setShowValues={setShowValues}
            showValues={showValues}
          />
          {showValues && (
            <Grid
              mt={5}
              p={5}
              mx={5}
              borderWidth={1}
              borderRadius={15}
              borderColor={borderColor}
            >
              <Grid
                mb={5}
                justifyItems="center"
                alignItems="center"
                templateRows="repeat(2, 1fr)"
                color={textColor}
              >
                <Text>Selecciona una Velocidad</Text>
                <Select
                  value={options[parseInt(project?.speedSelected?.label) - 1]}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  placeholder={"Selecciona Una Velocidad"}
                  onChange={(e) => onChangeSelect(e)}
                  options={options}
                />
              </Grid>

              <Box w="100%" minH={{ sm: "300px" }}>
                <LineChart
                  chartData={dataChart}
                  chartOptions={lineChartOptions}
                />
              </Box>
            </Grid>
          )}
        </GridItem>
      </EditBase>
    </>
  );
};

export default EditCavityBalance;
