import React from "react";
import { Flex, Grid, Image } from "@chakra-ui/react";
import { headerImage } from "./utilities";
import GintecContact from "./components/GintecContact";
import NuestrasFabricaciones from "./components/NuestrasFabricaciones";
import GintecPresentacion from "./components/GintecPresentacion";
import GintecContactForm from "./components/GintecContactForm";
import { useHistory } from "react-router-dom";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionGintec,
  metaKeywordsGintec,
  metaTitleGintec,
} from "../utilities/metaVariables";

const Gintec = () => {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");
  const textColor = "#04499b";
  return (
    <>
      <HelmetComponent
        title={metaTitleGintec}
        keywords={metaKeywordsGintec}
        description={metaDescriptionGintec}
      />
      <Flex
        direction="column"
        pt={{ sm: "110px", lg: "125" }}
        width={isBannerPath ? "80%" : "auto"} // Aquí aplicamos el ancho condicionalmente
        mx={isBannerPath ? "auto" : "0"}
      >
        <Grid
          justifyContent={"center"}
          mt={5}
          mx={5}
          p={5}
          borderWidth={1}
          borderRadius={15}
          opacity={0.9}
          backgroundColor={"white"}
        >
          <Image src={headerImage} />
        </Grid>
        <GintecPresentacion textColor={textColor} />
        <NuestrasFabricaciones />
        <GintecContact />
        <GintecContactForm textColor={textColor} collaborator={"G-Intec"} />
      </Flex>
    </>
  );
};

export default Gintec;
