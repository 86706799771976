import { Grid, Link, Text } from "@chakra-ui/react";
import CollaboratorsMap from "../../../../components/collaborators/Map";
import { lan, lat } from "../utilities";
import { googleMapURL } from "../../../../variables/general";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { InstagramIcon } from "../../../../components/Icons/InstagramIcon";

const AsaCleanMapcomponent = () => {
  return (
    <Grid
      backgroundColor={"white"}
      templateColumns="repeat(2, 1fr)"
      gap={1}
      mx={5}
      p={5}
      mt={5}
      borderWidth={1}
      borderRadius={15}
    >
      <Grid>
        <CollaboratorsMap
          lat={lat}
          lan={lan}
          marker={false}
          zoom={13}
          isMarkerShown={false}
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Grid>
      <Grid justifyItems={"center"}>
        <Text fontSize={"4xl"} fontWeight={"bold"}>
          Conócenos
        </Text>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Horario de Atención
        </Text>
        <Text fontSize={"xl"}>Lunes - Viernes</Text>
        <Text fontSize={"xl"}>8:00 am - 5:00 pm</Text>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Contacto
        </Text>
        <Text fontSize={"xl"}>
          <Link href={"https://www.asaclean.com/es/"}>www.asaclean.com</Link>
        </Text>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Redes Sociales
        </Text>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          <Link href={"https://www.facebook.com/"} isExternal>
            <FontAwesomeIcon color={"#3b5998"} icon={faFacebook} size={"2xl"} />
          </Link>
          <Link href={"https://www.instagram.com/"} isExternal>
            <InstagramIcon />
          </Link>
          <Link href={"https://www.linkedin.com/"} isExternal>
            <FontAwesomeIcon color={"#0077b5"} icon={faLinkedin} size={"2xl"} />
          </Link>
          <Link href={"https://twitter.com/"} isExternal>
            <FontAwesomeIcon icon={faXTwitter} size={"2xl"} />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AsaCleanMapcomponent;
