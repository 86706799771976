export function convertTonKn(uom, value) {
  // Constante de conversión
  const kN_a_Toneladas = 0.10197;
  const Toneladas_a_kN = 9.80665;

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // De toneladas a kilonewtons
    return value * Toneladas_a_kN;
  } else {
    // De kilonewtons a toneladas
    return value * kN_a_Toneladas;
  }
}

export function convertInMm(uom, value) {
  // Constante de conversión
  const pulgadasAMilimetros = 25.4;
  const milimetrosAPulgadas = 1 / pulgadasAMilimetros;

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // De pulgadas a milímetros
    return value * pulgadasAMilimetros;
  } else {
    // De milímetros a pulgadas
    return value * milimetrosAPulgadas;
  }
}

export function convertPsiBar(uom, value) {
  // Constantes de conversión
  const psiABar = 0.0689475729; // 1 psi a Bar
  const barAPsi = 14.503773773; // 1 Bar a psi

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de psi a Bar
    return value * psiABar;
  } else {
    // Asumimos cualquier otra cosa como internacional, convertir de Bar a psi
    return value * barAPsi;
  }
}

export function convertLbKg(uom, value) {
  // Constantes de conversión
  const librasAKilogramos = 0.45359237; // 1 libra a kilogramos
  const kilogramosALibras = 2.20462262185; // 1 kilogramo a libras

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de libras a kilogramos
    return value * librasAKilogramos;
  } else {
    // Asumimos cualquier otra cosa como internacional, convertir de kilogramos a libras
    return value * kilogramosALibras;
  }
}

export function convertInCubeMmCube(uom, value) {
  // Constante de conversión
  const pulgadasCubicasAMilimetrosCubicos = 16387.064; // 1 pulgada cúbica a milímetros cúbicos
  const milimetrosCubicosAPulgadasCubicas = 1 / pulgadasCubicasAMilimetrosCubicos; // 1 milímetro cúbico a pulgadas cúbicas

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de pulgadas cúbicas a milímetros cúbicos
    return value * pulgadasCubicasAMilimetrosCubicos;
  } else {
    // Asumimos cualquier otra cosa como internacional, convertir de milímetros cúbicos a pulgadas cúbicas
    return value * milimetrosCubicosAPulgadasCubicas;
  }
}

export function convertInCubeCmCube(uom, value) {
  const pulgadasCubicasACentimetrosCubicos = 16.387064; // 1 pulgada cúbica a centímetros cúbicos
  const centimetrosCubicosAPulgadasCubicas = 1 / pulgadasCubicasACentimetrosCubicos; // 1 centímetro cúbico a pulgadas cúbicas

  if (uom === "INGLES") {
    return value * pulgadasCubicasACentimetrosCubicos;
  } else {
    return value * centimetrosCubicosAPulgadasCubicas;
  }
}


export function convertInSecMmSec(uom, value) {
  const pulgadasSegundoAMilimetrosSegundo = 25.4; // 1 pulgada/segundo a milímetros/segundo
  const milimetrosSegundoAPulgadasSegundo = 1 / pulgadasSegundoAMilimetrosSegundo; // 1 milímetro/segundo a pulgadas/segundo

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de pulgadas/segundo a milímetros/segundo
    return value * pulgadasSegundoAMilimetrosSegundo;
  } else {
    // Asumimos cualquier otra cosa como internacional, convertir de milímetros/segundo a pulgadas/segundo
    return value * milimetrosSegundoAPulgadasSegundo;
  }
}

export function convertSquareInchToMmSquare(uom, value) {
  // Constante de conversión
  const pulgadasCuadradasAMilimetrosCuadrados = 645.16; // 1 pulgada cuadrada a milímetros cuadrados
  const milimetrosCuadradosAPulgadasCuadradas = 1 / pulgadasCuadradasAMilimetrosCuadrados; // 1 milímetro cuadrado a pulgadas cuadradas

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de pulgadas cuadradas a milímetros cuadrados
    return value * pulgadasCuadradasAMilimetrosCuadrados;
  } else {
    // Asumimos cualquier otra cosa como internacional, convertir de milímetros cuadrados a pulgadas cuadradas
    return value * milimetrosCuadradosAPulgadasCuadradas;
  }
}

export function convertTemperature(uom, value) {
  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de Fahrenheit a Celsius
    return (value - 32) * (5/9);
  } else {
    // Convertir de Celsius a Fahrenheit
    return (value * (9/5)) + 32;
  }
}

export function convertAreaPerSecondInMm(uom, value) {
  // Constante de conversión
  const inchesSquaredToMillimetersSquaredPerSecond = 645.16; // 1 pulgada cuadrada por segundo a milímetros cuadrados por segundo
  const millimetersSquaredToInchesSquaredPerSecond = 1 / inchesSquaredToMillimetersSquaredPerSecond; // 1 milímetro cuadrado por segundo a pulgadas cuadradas por segundo

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de pulgadas cuadradas por segundo a milímetros cuadrados por segundo
    return value * inchesSquaredToMillimetersSquaredPerSecond;
  } else {
    // Convertir de milímetros cuadrados por segundo a pulgadas cuadradas por segundo
    return value * millimetersSquaredToInchesSquaredPerSecond;
  }
}

export function convertPressureOrStress(uom, value) {
  // Constantes de conversión
  const tonToKilonewton = 9.80665; // 1 tonelada a kilonewtons
  const inchToMillimeter = 25.4; // 1 pulgada a milímetros
  const conversionFactor = tonToKilonewton / (inchToMillimeter * inchToMillimeter); // Factor de conversión entre toneladas/pulgada^2 a kN/mm^2

  // Verificar UOM y realizar la conversión adecuada
  if (uom === "INGLES") {
    // Convertir de toneladas sobre pulgadas a kilonewtons sobre milímetros
    return value * conversionFactor;
  } else if (uom === "INTERNACIONAL") {
    // Convertir de kilonewtons sobre milímetros a toneladas sobre pulgadas
    return value / conversionFactor;
  } else {
    // En caso de que se proporcione un UOM no soportado, podríamos devolver el valor original o lanzar un error
    console.error("UOM no soportado. Utilice 'INGLES' para toneladas sobre pulgadas o 'INTERNACIONAL' para kilonewtons sobre milímetros.");
    return value; // O bien, lanzar un error dependiendo de la necesidad
  }
}

