import { Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { textColor } from "../../../utilities/variables";

const LinearityStudyDataTable = () => {
  const { project } = useContext(ProjectContext);

  const { lineavilityTable, low_Limit, high_Limit } = project;
  return (
    <Grid
      mt={5}
      fontSize={"xs"}
      fontColor={"white"}
      templateColumns="repeat(12, 1fr)"
      templateRows="repeat(11)"
      color={textColor}
    >
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>SECUENCIA DE DISPARO</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>VELOCIDAD DE INTERVALOS</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>POSICION DE CORTE V/P</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>TIEMPO DE LLENADO</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>VELOCIDAD REAL DE LA MAQUINA</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>SHARE RATE (1/seg)</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>VISCOSIDAD RELATIVA</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>PRESION MAXIMA</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>REOLOGIA PLASTICA</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>LINEALIDAD</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"}>
        <Text>PESO DE DISPARO</Text>
      </GridItem>
      <GridItem colSpan={1} fontWeight={"bold"} mb={"33px"}>
        <Text>MAX SHARE RATE</Text>
      </GridItem>
      {lineavilityTable &&
        lineavilityTable.map((item) => {
          return (
            <>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {item.sequence}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.intervalSpeed).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.vpCuttingPosition).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.fillingTime).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.realSpeedMachine).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.shearRate).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.relativeViscocity).toFixed(2)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.maxPressurePpsi).toFixed(2)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.plasticRheology).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.linearity).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.shotWeight).toFixed(4)}
              </GridItem>
              <GridItem
                py={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? 1
                    : ""
                }
                width={"100%"}
                justifyItems={"center"}
                fontStyle={"italic"}
                color={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "white"
                    : ""
                }
                bgColor={
                  parseInt(low_Limit) <= item.sequence &&
                  parseInt(high_Limit) >= item.sequence
                    ? "green"
                    : ""
                }
              >
                {parseFloat(item.maxShearRate).toFixed(2)}
              </GridItem>
            </>
          );
        })}
    </Grid>
  );
};

export default LinearityStudyDataTable;
