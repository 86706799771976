import { useContext, useEffect, useRef, useState } from "react";
import ProjectContext from "../../contexts/ProjectContext";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { Project } from "../../models";

export const useCavityHooks = () => {
  const isMounted = useRef(true);
  const { project, updateProject, updateLoading } = useContext(ProjectContext);

  const [errors, setErrors] = useState([]);
  const [showCavityArea, setShowCavityArea] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (project.cavities) {
      setErrors(
        project.cavities.map(() => ({
          form: "",
          diameter: "",
          cavities: "",
          base: "",
          height: "",
        }))
      );
    }
  }, [project.cavities]);

  const customStyles = {
    clearIndicator: (styles) => ({
      ...styles,
      color: 'red' // Cambia 'red' por el color que prefieras
    }),
    option: (provided, state) => ({
      ...provided,
      color: "gray.700",
      backgroundColor: state.isSelected ? "gray.200" : "white",
    }),
  };

  const addCavity = () => {
    const newCavities = [
      ...project.cavities,
      { form: "", diameter: "", cavities: "", base: "", height: "" },
    ];
    updateProject({ ...project, cavities: newCavities });
  };

  const removeCavity = () => {
    const newCavities = project.cavities.slice(0, -1);
    updateProject({ ...project, cavities: newCavities });
  };

  const isLastCavityComplete = () => {
    if (!project.cavities || project.cavities.length === 0) return false;

    const lastCavity = project.cavities[project.cavities.length - 1];
    if (!lastCavity.form) return false;

    if (lastCavity.form === "CIRCULO") {
      return lastCavity.diameter !== "" && lastCavity.cavities !== "";
    }
    return (
      lastCavity.base !== "" &&
      lastCavity.height !== "" &&
      lastCavity.cavities !== ""
    );
  };

  const handleInputChange = async (index, field, value) => {
    const isValidFloat = (value) => /^\d*\.?\d*$/.test(value); // Permitir cadena vacía y números flotantes
    const isValidInteger = (value) => /^\d*$/.test(value); // Permitir cadena vacía y números enteros

    let isValid = true;
    if (field === "cavities") {
      isValid = isValidInteger(value);
    } else if (field !== "form") {
      isValid = isValidFloat(value);
    }

    if (!value && field === "form") {
      const updatedCavities = project.cavities.map((cavity, idx) =>
        idx === index
          ? {
            ...cavity,
            form: "",
            cavities: "",
            diameter: "",
            height: "",
            base: "",
          }
          : cavity
      );

      // Actualiza el estado del proyecto con los nuevos corredores
      const updatedProject = { ...project, cavities: updatedCavities };

      // Asegúrate de que la operación de actualización del estado se haya completado
      await updateProject(updatedProject);

      const p = await DataStore.query(Project, project.id);
      const result = await DataStore.save(
        Project.copyOf(p, (updated) => {
          updated.cavities = updatedProject.cavities;
          updated.cavityArea = null;
          updated.insuranceFactor = "";
          updated.minTonSug = "0";
          updated.nomTonSug = "0";
          updated.maxTonSug = "0";
        })
      );
      updateProject(result);
    } else {
      if (isValid) {
        const updatedCavities = [...project.cavities];
        updatedCavities[index] = { ...updatedCavities[index], [field]: value };
        updateProject({ ...project, cavities: updatedCavities });
      }
    }



    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: isValid ? "" : "Valor no válido",
      };
      return updatedErrors;
    });
  };

  const finishCavities = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "projectedAreaNew",
      path: "/projected-area",
      options: {
        body: project.cavities,
      },
    });

    const rest = await restOperation.response;
    const body = await rest.body.json();
    const b = body.body;

    const { area } = b;

    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.cavities = project.cavities;
        updated.cavityArea = area.toString();
        updated.insuranceFactor = "";
        updated.minTonSug = "0";
        updated.nomTonSug = "0";
        updated.maxTonSug = "0";
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => console.log(err))
      .finally(() => updateLoading(false));
  };

  return {
    customStyles,
    addCavity,
    isLastCavityComplete,
    removeCavity,
    handleInputChange,
    errors,
    finishCavities,
    showCavityArea,
    setShowCavityArea,
  };
};
