import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import {
  isFloatString,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { useWeightHooks } from "./hooks/useWeightHooks";
import { useCushionHooks } from "./hooks/useCushionHooks";
import { useFillingTimeHooks } from "./hooks/useFillingTimeHooks";
import { Project } from "../../../../../../models";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import LineChart from "../../../../../../components/Charts/LineChart";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { useVCheckRepeatabilityHooks } from "./hooks/useVCheckRepeatabilityHooks";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { badgeColor, borderColor, textColor } from "../../utilities/variables";
import ConditionalsAlert from "../../../../hooks/ConditionalsAlert";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import {
  inMm,
  ozGr,
  psiTobar,
} from "../../../../individual/hooks/useUomTransform";

const EditVCheckRepeatability = () => {
  const { id } = useParams();
  const [showData, setShowData] = useState(false);
  const [errorMinMax, setErrorMinMax] = useState("");
  const [errorPress, setErrorPress] = useState("");
  const {
    project,
    updateProject,
    isLoading,
    updateLoading,
    handleInputChange,
    resin,
  } = useContext(ProjectContext);
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    cutOffPositionTheoric,
    shotWeight1stStage,
    backPress,
    rpmSpeed,
  } = project;

  const { rpmMax, rpmMin, backPressureMax, backPressureMin } = resin;

  const {
    weightDiff,
    weightAvg,
    weightMax,
    weightMin,
    weight,
    setWeight,
    lineChartOptionsWeight,
    lineChartDataWeight,
    weightArray,
  } = useWeightHooks();

  const {
    cushionDiff,
    cushionAvg,
    cushionMax,
    cushionMin,
    cushion,
    setCushion,
    lineChartDataCushion,
    lineChartOptionsCushion,
    cushionArray,
  } = useCushionHooks();

  const {
    fillingTime,
    fillingTimeAvg,
    fillingTimeDiff,
    fillingTimeMax,
    fillingTimeMin,
    setFillingTime,
    lineChartOptionsFillingTime,
    lineChartDataFillingTime,
    fillingTimeArray,
  } = useFillingTimeHooks();

  const { showChart, setShowChart } = useVCheckRepeatabilityHooks();

  const onSubmitHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "vcheckRepeatability",
      path: "/v-check-repeatability",
      options: {
        body: {
          weightDiff,
          weightAvg,
        },
      },
    });

    const rest = await restOperation.response;
    const res = await rest.body.json();
    const { vckeckRepeatability } = res;

    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.vcheckWeight = weight;
        updated.vcheckFillingTime = fillingTime;
        updated.vcheckCushion = cushion;
        updated.vckeckRepeatability = vckeckRepeatability.toString();
        updated.phase = "8";
      })
    )
      .then(async (res) => {
        let icon = "success";
        let text = "";
        if (
          parseFloat(backPress) > backPressureMax ||
          parseFloat(backPress) < backPressureMin
        ) {
          icon = "warning";
          text += `<li>${
            errorPress !== ""
              ? errorPress
              : parseFloat(backPress) > parseFloat(backPressureMax)
              ? `La Contrapresión está por encima de las recomendadas por la resina (${parseFloat(
                  backPressureMax
                ).toFixed(2)}).`
              : `La Contrapresión está por debajo de las recomendadas por la resina (${parseFloat(
                  backPressureMin
                ).toFixed(2)}).`
          }</li>`;
        }
        if (parseFloat(rpmSpeed) > rpmMax || parseFloat(rpmSpeed) < rpmMin) {
          icon = "warning";
          text += `<li>${
            errorMinMax
              ? errorMinMax
              : parseFloat(rpmSpeed) > parseFloat(rpmMax)
              ? `Las RPM están por encima de las recomendadas por la resina (${parseFloat(
                  rpmMax
                ).toFixed(2)}).`
              : `Las RPM están por debajo de las recomendadas por la resina (${parseFloat(
                  rpmMin
                ).toFixed(2)}).`
          }</li>`;
        }
        if (parseFloat(res.vckeckRepeatability) > 3) {
          icon = "warning";
          text += `<li>Un resultado aceptable del estudio debe de ser menor de 3%. De lo contrario debemos deducir que existe algún tipo de desgaste en la Unidad de Inyección o alguno de sus componentes.</li>`;
          text += `<li>Repetibilidad de V-Check Actual ${parseFloat(
            res.vckeckRepeatability
          ).toFixed(2)}</li>`;
        }
        ConditionalsAlert(icon, text);
        await updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateLoading(false);
        setShowChart(true);
      });
  };

  const onSaveChanges = async () => {
    updateLoading(true);

    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.backPress = backPress.toString();
        updated.rpmSpeed = rpmSpeed.toString();
      })
    )
      .then(async (res) => {
        await updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateLoading(false);
        setShowData(true);
      });
  };

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        keywords={metaKeywords}
        title={metaTitle}
      />
      <EditBase
        isLoading={isLoading}
        title={"Repetibilidad de V-Check"}
        disabled={!showChart}
      >
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            templateColumns="repeat(2, 1fr)"
            borderColor={borderColor}
            gap={6}
            borderWidth={1}
            borderRadius={15}
            p={5}
            mb={5}
          >
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              type={"long"}
              rightAddon={inMm(project?.uom)}
              title={"Posición de Corte Teórico"}
              value={cutOffPositionTheoric}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={badgeColor}
              rightAddon={ozGr(project?.uom)}
              type={"weight"}
              title={"Peso de Primera Etapa"}
              value={shotWeight1stStage}
            />
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              disabled={showData}
              error={errorPress}
              onChange={(e) => {
                setErrorPress("");
                isFloatString(e.target.value) && handleInputChange(e);
              }}
              onBlur={(e) => {
                if (parseFloat(e.target.value) > backPressureMax) {
                  setErrorPress(
                    `La Contrapresión está por encima de las recomendadas por la resina (${parseFloat(
                      backPressureMax
                    ).toFixed(2)}).`
                  );
                } else if (
                  parseFloat(e.target.value) < parseFloat(backPressureMin)
                ) {
                  setErrorPress(
                    `Las Contrapresión está por debajo de las recomendadas por la resina (${parseFloat(
                      backPressureMin
                    ).toFixed(2)}).`
                  );
                } else {
                  setErrorPress("");
                }
              }}
              id={"backPress"}
              label={"Contrapresión"}
              value={backPress}
              addOn={psiTobar(project?.uom)}
            />
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              disabled={showData}
              onChange={(e) => {
                setErrorMinMax("");
                isFloatString(e.target.value) && handleInputChange(e);
              }}
              error={errorMinMax}
              onBlur={(e) => {
                if (parseFloat(e.target.value) > rpmMax) {
                  setErrorMinMax(
                    `Las RPM están por encima de las recomendadas por la resina (${parseFloat(
                      rpmMax
                    ).toFixed(2)}).`
                  );
                } else if (parseFloat(e.target.value) < parseFloat(rpmMin)) {
                  setErrorMinMax(
                    `Las RPM están por debajo de las recomendadas por la resina (${parseFloat(
                      rpmMin
                    ).toFixed(2)}).`
                  );
                } else {
                  setErrorMinMax("");
                }
              }}
              type={"number"}
              id={"rpmSpeed"}
              min={rpmMin}
              max={rpmMax}
              label={"Velocidad de Rotación"}
              value={rpmSpeed}
              addOn={"RPM"}
            />

            <GridItem colSpan={2}>
              <Grid>
                <Button
                  colorScheme={"facebook"}
                  onClick={
                    showData ? () => setShowData(false) : () => onSaveChanges()
                  }
                  children={showData ? "Editar" : "Continuar"}
                  disabled={!backPress || !rpmSpeed}
                  isLoading={isLoading}
                />
              </Grid>
            </GridItem>
          </Grid>
        </GridItem>

        {showData && (
          <GridItem colSpan={4} width={"100%"}>
            <Grid
              width="100%"
              gap={6}
              justifyItems={"center"}
              borderWidth={1}
              borderRadius={15}
              alignSelf={"center"}
              borderColor={borderColor}
            >
              <Text
                mt={5}
                fontSize="3xl"
                fontWeight="bold"
                color={textColor}
                justifyItems={"center"}
              >
                Matriz de Datos Fija
              </Text>
              <Grid
                templateColumns="repeat(4, 8fr)"
                templateRows="repeat(11, 8fr)"
                gap={4}
                color={textColor}
                justifyItems={"center"}
                borderRadius={15}
              >
                <Text fontSize={"xs"} fontWeight={"bold"}>
                  DISPARO
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"}>
                  PESO
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"}>
                  TIEMPO DE LLENADO
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"}>
                  TRANSFERENCIA VP
                </Text>
                <Text alignSelf={"inherit"}>1</Text>
                <InputGroup
                  size={"xs"}
                  borderColor={borderColor}
                  borderRadius={15}
                >
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight1}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight1: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>

                <InputGroup
                  size={"xs"}
                  borderColor={borderColor}
                  borderRadius={15}
                >
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime1}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime1: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup
                  size={"xs"}
                  borderColor={borderColor}
                  borderRadius={10}
                >
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion1}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion1: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>

                <Text>2</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight2}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight2: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>

                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime2}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime2: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>

                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion2}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion2: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>3</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight3}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight3: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>

                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime3}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime3: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>

                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion3}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion3: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>4</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight4}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight4: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime4}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime4: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion4}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion4: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>5</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight5}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight5: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime5}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime5: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion5}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion5: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>6</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight6}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight6: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime6}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime6: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion6}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion6: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>7</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight7}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight7: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime7}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime7: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion7}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion7: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>8</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight8}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight8: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime8}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime8: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion8}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion8: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>9</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight9}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight9: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime9}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime9: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion9}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion9: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
                <Text>10</Text>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={weight.weight10}
                    onChange={(e) =>
                      setWeight({
                        ...weight,
                        weight10: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={ozGr(project?.uom)}
                  />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={fillingTime.fillingTime10}
                    onChange={(e) =>
                      setFillingTime({
                        ...fillingTime,
                        fillingTime10: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon children={"sec"} borderRadius={5} />
                </InputGroup>
                <InputGroup size={"xs"} borderColor={borderColor}>
                  <Input
                    borderRadius={5}
                    borderColor={borderColor}
                    disabled={showChart}
                    type={"number"}
                    size={"xs"}
                    value={cushion.cushion10}
                    onChange={(e) =>
                      setCushion({
                        ...cushion,
                        cushion10: parseFloat(e.target.value),
                      })
                    }
                  />
                  <InputRightAddon
                    borderRadius={5}
                    children={inMm(project?.uom)}
                  />
                </InputGroup>
              </Grid>
              <Button
                colorScheme={"facebook"}
                w={"60%"}
                px={5}
                mx={5}
                my={5}
                isLoading={isLoading}
                disabled={
                  cushionArray.length !== 10 ||
                  weightArray.length !== 10 ||
                  fillingTimeArray.length !== 10
                }
                onClick={
                  showChart
                    ? () => setShowChart(false)
                    : () => onSubmitHandler()
                }
              >
                {showChart ? "Editar" : "Calcular y Graficar"}
              </Button>
            </Grid>
            {showChart && (
              <Grid
                borderColor={borderColor}
                justifyItems={"center"}
                mt={5}
                templateColumns="repeat(4, 1fr)"
                gap={6}
                p={5}
                borderWidth={1}
                borderRadius={15}
                color={textColor}
              >
                <Text>Disparo</Text>
                <Text>Peso</Text>
                <Text>T Llenado</Text>
                <Text>Colchon</Text>
                <Text fontSize="sm" fontWeight="bold">
                  Promedio
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(weightAvg).toFixed(3)} ${ozGr(project?.uom)}`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(fillingTimeAvg).toFixed(3)} seg`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(cushionAvg).toFixed(3)} ${inMm(project?.uom)}`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  Minimo
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(weightMin).toFixed(3)} ${ozGr(project?.uom)}`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(fillingTimeMin).toFixed(3)} seg`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(cushionMin).toFixed(3)} ${inMm(project?.uom)}`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  Máximo
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(weightMax).toFixed(3)} ${ozGr(project?.uom)}`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(fillingTimeMax).toFixed(3)} seg`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(cushionMax).toFixed(3)} ${inMm(project?.uom)}`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  Diferencia
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(weightDiff).toFixed(3)} ${ozGr(project?.uom)}`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(fillingTimeDiff).toFixed(3)} seg`}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {`${parseFloat(cushionDiff).toFixed(3)} ${inMm(
                    project?.uom
                  )}`}
                </Text>
              </Grid>
            )}

            {project.vckeckRepeatability && showChart && (
              <Grid
                borderColor={borderColor}
                color={textColor}
                justifyContent={"left"}
                mt={5}
                p={5}
                borderWidth={1}
                borderRadius={15}
              >
                <DataDisplayBadge
                  color={textColor}
                  rightAddon={"%"}
                  justifyContent={"left"}
                  title={"Repetibilidad de V-Check"}
                  badgeColorScheme={
                    parseFloat(project.vckeckRepeatability) < 3
                      ? "green"
                      : "red"
                  }
                  value={project.vckeckRepeatability}
                />
                {parseFloat(project.vckeckRepeatability) < 3 ? (
                  <></>
                ) : (
                  <Flex my={5}>
                    <Text
                      fontSize={"sm"}
                      fontStyle={"italic"}
                      color={"red.700"}
                    >
                      Un resultado aceptable del estudio debe de ser menor de
                      3%. De lo contrario debemos deducir que existe algún tipo
                      de desgaste en la Unidad de Inyección o alguno de sus
                      componentes.
                    </Text>
                  </Flex>
                )}
                <Grid mt={5} templateColumns="repeat(3, 1fr)" gap={6}>
                  <Text color={"#FEB019"} fontWeight={"bold"}>
                    Peso
                  </Text>
                  <Text color={"#008FFB"} fontWeight={"bold"} mx={10}>
                    Tiempo de Llenado
                  </Text>
                  <Text color={"#1f4e78"} fontWeight={"bold"}>
                    Transferencia VP
                  </Text>
                </Grid>
                <Grid justifyContent={"left"}>
                  <Flex direction={"column"}>
                    <Flex direction={"column"} w={"100%"}>
                      <Flex color={"white"} direction={"column"} w={"100%"}>
                        <Flex
                          mt={5}
                          color={"white"}
                          direction={"column"}
                          w={"100%"}
                          minHeight={"300px"}
                        >
                          <Flex
                            direction={"row"}
                            mb={10}
                            justifyContent={"center"}
                            alignItems={"center"}
                          ></Flex>
                          <Flex
                            color={"white"}
                            direction={"column"}
                            w={"82%"}
                            borderColor={"green"}
                            position={"absolute"}
                          >
                            <Box w="100%" minH={{ sm: "300px" }}>
                              <LineChart
                                chartOptions={lineChartOptionsWeight}
                                chartData={lineChartDataWeight}
                              />
                            </Box>
                          </Flex>
                          <Flex
                            color={"white"}
                            direction={"column"}
                            w={"82%"}
                            borderColor={"green"}
                            position={"absolute"}
                          >
                            <Box w="100%" minH={{ sm: "300px" }}>
                              <LineChart
                                chartOptions={lineChartOptionsFillingTime}
                                chartData={lineChartDataFillingTime}
                              />
                            </Box>
                          </Flex>
                          <Flex
                            color={"white"}
                            direction={"column"}
                            w={"82%"}
                            borderColor={"green"}
                            position={"absolute"}
                          >
                            <Box w="100%" minH={{ sm: "300px" }}>
                              <LineChart
                                chartOptions={lineChartOptionsCushion}
                                chartData={lineChartDataCushion}
                              />
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Grid>
              </Grid>
            )}
          </GridItem>
        )}
      </EditBase>
    </>
  );
};

export default EditVCheckRepeatability;
