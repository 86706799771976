import {
  Button,
  Grid,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
} from "@chakra-ui/react";
import CavityBalanceHeader from "./CavityBalanceHeader";
import { isObjectEmpty } from "../../../../../../../utilities/functions";
import Desviation from "./Desviation";
import Unbalance from "./Unbalance";
import { checkValues } from "../utilities/functions";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { borderColor, textColor } from "../../../utilities/variables";
import { inSecMmsec } from "../../../../../individual/hooks/useUomTransform";

const CavityBalanceTable = ({
  showValues,
  onChangeCavityWeight,
  onChangeHandler,
  onSubmitHandler,
  setShowValues,
}) => {
  const { project, isLoading } = useContext(ProjectContext);
  const { cavityBalanceTable, speedSelected } = project;
  return (
    <Grid
      p={5}
      mx={5}
      borderWidth={1}
      borderRadius={15}
      borderColor={borderColor}
      justifyItems={"center"}
    >
      <Table variant="unstyled" color={textColor}>
        <CavityBalanceHeader />
        <Tbody>
          <Tr>
            <Th>Velocidad de Inyección</Th>
            {cavityBalanceTable &&
              cavityBalanceTable?.injectionSpeed &&
              cavityBalanceTable.injectionSpeed[0] &&
              cavityBalanceTable.injectionSpeed?.map((item, index) => {
                return (
                  <Td fontSize={"xs"}>
                    {cavityBalanceTable
                      ? `${parseFloat(
                          cavityBalanceTable?.injectionSpeed[index]?.value
                        ).toFixed(3)} ${inSecMmsec(project?.uom)}`
                      : ""}
                  </Td>
                );
              })}
            <Td></Td>
          </Tr>
          <Tr>
            <Th>Máxima Presion de Inyección (Ppsi)</Th>
            {cavityBalanceTable &&
              cavityBalanceTable?.maxInjectionPressure &&
              cavityBalanceTable.maxInjectionPressure[0] &&
              cavityBalanceTable.maxInjectionPressure?.map((item, index) => {
                return (
                  <Td
                    bgColor={
                      item?.label.toString() === speedSelected?.label && "green"
                    }
                    color={
                      item?.label.toString() === speedSelected?.label && "white"
                    }
                  >
                    <Input
                      borderColor={borderColor}
                      disabled={showValues}
                      type={"number"}
                      value={
                        cavityBalanceTable?.maxInjectionPressure[index]?.value
                      }
                      onChange={(e) => onChangeHandler(e, item.label)}
                      name={"maxInjectionPressure"}
                      borderRadius={2}
                      size={"xs"}
                      placeholder={"Máxima velocidad de Inyección"}
                    />
                  </Td>
                );
              })}
            <Td></Td>
          </Tr>
          <Tr>
            <Th>Tiempo de Inyección (seg)</Th>
            {cavityBalanceTable &&
              cavityBalanceTable?.inyectionTime &&
              cavityBalanceTable.inyectionTime[0] &&
              cavityBalanceTable.inyectionTime?.map((item, index) => {
                return (
                  <Td
                    bgColor={
                      item?.label.toString() === speedSelected?.label && "green"
                    }
                    color={
                      item?.label.toString() === speedSelected?.label && "white"
                    }
                  >
                    <Input
                      borderColor={borderColor}
                      disabled={showValues}
                      type={"number"}
                      value={cavityBalanceTable?.inyectionTime[index]?.value}
                      onChange={(e) => onChangeHandler(e, item.label)}
                      name={"inyectionTime"}
                      borderRadius={2}
                      size={"xs"}
                      placeholder={"Tiempo de Inyección"}
                    />
                  </Td>
                );
              })}
            <Td></Td>
          </Tr>
          <Tr>
            <Th>Peso del Runner (Oz)</Th>
            {cavityBalanceTable &&
              cavityBalanceTable?.runnersWeight &&
              cavityBalanceTable.runnersWeight[0] &&
              cavityBalanceTable.runnersWeight?.map((item, index) => {
                return (
                  <Td
                    bgColor={
                      item?.label.toString() === speedSelected?.label && "green"
                    }
                    color={
                      item?.label.toString() === speedSelected?.label && "white"
                    }
                  >
                    <Input
                      borderColor={borderColor}
                      disabled={showValues}
                      type={"number"}
                      value={cavityBalanceTable?.runnersWeight[index]?.value}
                      onChange={(e) => onChangeHandler(e, item.label)}
                      name={"runnersWeight"}
                      borderRadius={2}
                      size={"xs"}
                      placeholder={"Peso del Runner"}
                    />
                  </Td>
                );
              })}
            <Td></Td>
          </Tr>
          {!isObjectEmpty(project) &&
            cavityBalanceTable &&
            cavityBalanceTable?.cavityWeight &&
            cavityBalanceTable.cavityWeight[0] &&
            cavityBalanceTable.cavityWeight?.map((item, index) => {
              return (
                <Tr key={`cavity-weight-${index + 1}`}>
                  <Th>Peso de cavidad {index + 1} (Oz)</Th>

                  {Array.isArray(item) &&
                    item?.map((i, ind) => {
                      return (
                        <Td
                          justifyContent={"center"}
                          alignItems={"center"}
                          bgColor={
                            i?.label.toString() === speedSelected?.label &&
                            "green"
                          }
                          color={
                            i?.label.toString() === speedSelected?.label
                              ? "white"
                              : ""
                          }
                        >
                          {i?.label === 6 ? (
                            <Desviation
                              data={cavityBalanceTable.cavityWeight}
                              item={i}
                              show={showValues}
                            />
                          ) : (
                            <Input
                              borderColor={borderColor}
                              disabled={showValues}
                              type={"text"}
                              onChange={(e) =>
                                onChangeCavityWeight(e, index, ind)
                              }
                              name={"cavityWeight"}
                              value={i.value}
                              borderRadius={2}
                              size={"xs"}
                              placeholder={"ej: 0.298"}
                            />
                          )}
                        </Td>
                      );
                    })}
                </Tr>
              );
            })}
          {showValues && (
            <>
              <Tr>
                <Th>Peso de Disparo</Th>
                {cavityBalanceTable &&
                  cavityBalanceTable?.shootingWeight &&
                  cavityBalanceTable.shootingWeight[0] &&
                  cavityBalanceTable.shootingWeight?.map((item, index) => {
                    return (
                      <Td
                        bgColor={
                          item?.label.toString() === speedSelected?.label &&
                          "green"
                        }
                        color={
                          item?.label.toString() === speedSelected?.label
                            ? "white"
                            : ""
                        }
                      >
                        <Text>
                          {cavityBalanceTable?.shootingWeight[index]?.value
                            ? parseFloat(
                                cavityBalanceTable?.shootingWeight[index]?.value
                              ).toFixed(3)
                            : ""}
                        </Text>
                      </Td>
                    );
                  })}
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Primera Etapa Peso de Cavidades</Th>
                {cavityBalanceTable &&
                  cavityBalanceTable?.firstStageCavitiesWeight &&
                  cavityBalanceTable.firstStageCavitiesWeight[0] &&
                  cavityBalanceTable.firstStageCavitiesWeight?.map(
                    (item, index) => {
                      return (
                        <Td
                          bgColor={
                            item?.label.toString() === speedSelected?.label &&
                            "green"
                          }
                          color={
                            item?.label.toString() === speedSelected?.label
                              ? "white"
                              : ""
                          }
                        >
                          <Text>
                            {cavityBalanceTable?.firstStageCavitiesWeight[index]
                              ?.value
                              ? parseFloat(
                                  cavityBalanceTable?.firstStageCavitiesWeight[
                                    index
                                  ]?.value
                                ).toFixed(3)
                              : ""}
                          </Text>
                        </Td>
                      );
                    }
                  )}
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Peso Menor</Th>
                {cavityBalanceTable &&
                  cavityBalanceTable?.lowerWeight &&
                  cavityBalanceTable.lowerWeight[0] &&
                  cavityBalanceTable.lowerWeight?.map((item, index) => {
                    return (
                      <Td
                        bgColor={
                          item?.label.toString() === speedSelected?.label &&
                          "green"
                        }
                        color={
                          item?.label.toString() === speedSelected?.label
                            ? "white"
                            : ""
                        }
                      >
                        <Text>
                          {cavityBalanceTable?.lowerWeight[index]?.value
                            ? parseFloat(
                                cavityBalanceTable?.lowerWeight[index]?.value
                              ).toFixed(3)
                            : ""}
                        </Text>
                      </Td>
                    );
                  })}
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Peso Mayor</Th>
                {cavityBalanceTable &&
                  cavityBalanceTable?.higherWeight &&
                  cavityBalanceTable.higherWeight[0] &&
                  cavityBalanceTable.higherWeight?.map((item, index) => {
                    return (
                      <Td
                        bgColor={
                          item?.label.toString() === speedSelected?.label &&
                          "green"
                        }
                        color={
                          item?.label.toString() === speedSelected?.label
                            ? "white"
                            : ""
                        }
                      >
                        <Text>
                          {cavityBalanceTable?.higherWeight[index]?.value
                            ? parseFloat(
                                cavityBalanceTable?.higherWeight[index]?.value
                              ).toFixed(3)
                            : ""}
                        </Text>
                      </Td>
                    );
                  })}
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Diferencia Max - Min</Th>
                {cavityBalanceTable &&
                  cavityBalanceTable?.maxMinDifference &&
                  cavityBalanceTable.maxMinDifference[0] &&
                  cavityBalanceTable.maxMinDifference?.map((item, index) => {
                    return (
                      <Td
                        bgColor={
                          item?.label.toString() === speedSelected?.label &&
                          "green"
                        }
                        color={
                          item?.label.toString() === speedSelected?.label
                            ? "white"
                            : ""
                        }
                      >
                        <Text>
                          {cavityBalanceTable?.maxMinDifference[index]?.value
                            ? parseFloat(
                                cavityBalanceTable?.maxMinDifference[index]
                                  ?.value
                              ).toFixed(3)
                            : ""}
                        </Text>
                      </Td>
                    );
                  })}
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Peso Promedio de Disparo</Th>
                {cavityBalanceTable &&
                  cavityBalanceTable?.averageShotWeight &&
                  cavityBalanceTable.averageShotWeight[0] &&
                  cavityBalanceTable.averageShotWeight?.map((item, index) => {
                    return (
                      <Td
                        bgColor={
                          item?.label.toString() === speedSelected?.label &&
                          "green"
                        }
                        color={
                          item?.label.toString() === speedSelected?.label
                            ? "white"
                            : ""
                        }
                      >
                        <Text>
                          {cavityBalanceTable?.averageShotWeight[index]?.value
                            ? parseFloat(
                                cavityBalanceTable?.averageShotWeight[index]
                                  ?.value
                              ).toFixed(3)
                            : ""}
                        </Text>
                      </Td>
                    );
                  })}
                <Td></Td>
              </Tr>
              <Tr borderRadius={5}>
                <Th>Desbalance</Th>
                {cavityBalanceTable &&
                  cavityBalanceTable?.unbalance &&
                  cavityBalanceTable.unbalance[0] &&
                  cavityBalanceTable.unbalance?.map((item, index) => {
                    return (
                      <Unbalance
                        item={item}
                        data={[
                          cavityBalanceTable.unbalance[0].value,
                          cavityBalanceTable.unbalance[1].value,
                          cavityBalanceTable.unbalance[2].value,
                          cavityBalanceTable.unbalance[3].value,
                          cavityBalanceTable.unbalance[4].value,
                        ]}
                      />
                    );
                  })}
                <Td></Td>
              </Tr>
            </>
          )}
        </Tbody>
      </Table>
      <Grid mt={5}>
        <Button
          colorScheme={"facebook"}
          isLoading={isLoading}
          width={"100%"}
          onClick={
            showValues ? () => setShowValues(false) : () => onSubmitHandler()
          }
          disabled={
            isLoading || cavityBalanceTable
              ? checkValues(cavityBalanceTable)
              : true
          }
        >
          {showValues ? "Editar" : "Calcular Resultados"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CavityBalanceTable;
