import React, { createContext, useContext, useState, useEffect } from "react";
import UserContext from "../UserContext";

const HeaderContext = createContext();

export const useHeader = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uom, setUom] = useState("");
  const [toolName, setToolName] = useState("");
  const [machineName, setMachineName] = useState("");
  const [resinName, setResinName] = useState("");
  const [productName, setProductName] = useState("");
  const [userID, setUserID] = useState("");

  const { user } = useContext(UserContext);
  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setUserID(user.id);
  }, [user]);

  const header = {
    uom,
    toolName,
    resinName,
    machineName,
    productName,
    name,
    email,
  };

  const clearHeader = () => {
    setName("");
    setIsLoading(false);
    setUom("");
  };

  return (
    <HeaderContext.Provider
      value={{
        name,
        setName,
        isLoading,
        setIsLoading,
        uom,
        setUom,
        clearHeader,
        userID,
        toolName,
        setToolName,
        machineName,
        setMachineName,
        resinName,
        setResinName,
        productName,
        setProductName,
        email,
        setEmail,
        header,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
