import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { ProjectedAreaAndTonnage } from "../../../../../../models";
import { Page, Text, View, Document, PDFViewer } from "@react-pdf/renderer";
import "moment/locale/es";

import PdfHeader from "../../../components/PdfHeader";
import usePdfStyles from "../../../hooks/usePdfstyles";

const ProjectedAreaPdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(ProjectedAreaAndTonnage, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  const styles = usePdfStyles();

  function isValidEntry(entry) {
    if (!entry.form) return false;

    if (entry.form === "CIRCULO") {
      return entry.diameter !== "" && entry.cavities !== "";
    } else {
      return entry.base !== "" && entry.height !== "" && entry.cavities !== "";
    }
  }

  function isValidEntries(entries) {
    if (!entries || entries.length === 0) return false;

    return entries.every(isValidEntry);
  }

  const MyDocument = ({ data }) => (
    <Document
      pdfVersion={1}
      pageLayout={"twoColumnLeft"}
      creator={"MVPS"}
      subject={`Projected Area And Tonnage ${data?.header?.productName}`}
      title={`Projected Area And Tonnage ${data?.header?.productName}`}
      author={"https://molding-vps.com"}
    >
      <Page bookmark={"MVPS"} size="LETTER" style={styles.page}>
        <PdfHeader data={data} title={"Área Proyectada y Tonelaje"} />
        {isValidEntries(data?.runners) && (
          <>
            <Text style={styles.header}>
              Runners -{" "}
              {`Area: ${parseFloat(data.runnerArea).toFixed(2)} ${
                data?.header?.uom === "INGLES" ? "in²" : "mm²"
              }`}
            </Text>
            <View style={styles.viewContainer}>
              {data?.runners.map((runner, index) => {
                if (runner.form === "CIRCULO") {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text style={styles.text}>{`Runner ${index + 1}`}</Text>
                      <Text style={styles.text}>{`Forma: ${runner.form}`}</Text>
                      <Text style={styles.text}>{`Diametro: ${
                        runner.diameter
                      } ${data?.header?.uom === "INGLES" ? "in" : "mm"}`}</Text>
                      <Text
                        style={styles.text}
                      >{`Cavidades: ${runner.cavities}`}</Text>
                    </View>
                  );
                } else {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text style={styles.text}>{`Runner ${index + 1}`}</Text>
                      <Text style={styles.text}>{`Forma: ${runner.form}`}</Text>
                      <Text style={styles.text}>{`Altura: ${runner.height} ${
                        data?.header?.uom === "INGLES" ? "in" : "mm"
                      }`}</Text>
                      <Text style={styles.text}>{`Base: ${runner.base} ${
                        data?.header?.uom === "INGLES" ? "in" : "mm"
                      }`}</Text>
                      <Text
                        style={styles.text}
                      >{`Cavidades: ${runner.cavities} `}</Text>
                    </View>
                  );
                }
              })}
            </View>
          </>
        )}
        {isValidEntries(data?.cavities) && (
          <>
            <Text style={styles.header}>
              Cavidades -{" "}
              {`Area: ${parseFloat(data.cavityArea).toFixed(2)} ${
                data?.header?.uom === "INGLES" ? "in²" : "mm²"
              }`}
            </Text>
            <View style={styles.viewContainer}>
              {data?.cavities.map((cavity, index) => {
                if (cavity.form === "CIRCULO") {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text style={styles.text}>{`Cavidad ${index + 1}`}</Text>
                      <Text style={styles.text}>{`Forma: ${cavity.form}`}</Text>
                      <Text style={styles.text}>{`Diametro: ${
                        cavity.diameter
                      } ${data?.header?.uom === "INGLES" ? "in" : "mm"}`}</Text>
                      <Text
                        style={styles.text}
                      >{`Cavidades: ${cavity.cavities} `}</Text>
                    </View>
                  );
                } else {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text style={styles.text}>{`Cavidad ${index + 1}`}</Text>
                      <Text style={styles.text}>{`Forma: ${cavity.form}`}</Text>
                      <Text style={styles.text}>{`Altura: ${cavity.height} ${
                        data?.header?.uom === "INGLES" ? "in" : "mm"
                      }`}</Text>
                      <Text style={styles.text}>{`Base: ${cavity.base} ${
                        data?.header?.uom === "INGLES" ? "in" : "mm"
                      }`}</Text>
                      <Text
                        style={styles.text}
                      >{`Cavidades: ${cavity.cavities}`}</Text>
                    </View>
                  );
                }
              })}
            </View>
          </>
        )}

        {isValidEntries(data?.gaps) && (
          <>
            <Text style={styles.header}>
              Huecos -{" "}
              {`Area: ${parseFloat(data?.gapArea).toFixed(2)} ${
                data?.header?.uom === "INGLES" ? "in²" : "mm²"
              }`}
            </Text>
            <View style={styles.viewContainer}>
              {data?.gaps.map((gap, index) => {
                if (gap.form === "CIRCULO") {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text style={styles.text}>{`Hueco ${index + 1}`}</Text>
                      <Text style={styles.text}>{`Forma: ${gap.form}`}</Text>
                      <Text style={styles.text}>{`Diametro: ${gap.diameter} ${
                        data?.header?.uom === "INGLES" ? "in" : "mm"
                      }`}</Text>
                      <Text
                        style={styles.text}
                      >{`Cavidades: ${gap.cavities} `}</Text>
                    </View>
                  );
                } else {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text style={styles.text}>{`Hueco ${index + 1}`}</Text>
                      <Text style={styles.text}>{`Forma: ${gap.form}`}</Text>
                      <Text style={styles.text}>{`Altura: ${gap.height} ${
                        data?.header?.uom === "INGLES" ? "in" : "mm"
                      }`}</Text>
                      <Text style={styles.text}>{`Base: ${gap.base} ${
                        data?.header?.uom === "INGLES" ? "in" : "mm"
                      }`}</Text>
                      <Text
                        style={styles.text}
                      >{`Cavidades: ${gap.cavities}`}</Text>
                    </View>
                  );
                }
              })}
            </View>
          </>
        )}

        <Text style={styles.header}>Entradas</Text>
        <View style={styles.view}>
          <View>
            <Text style={styles.text}>{`Mínimo Factor de Tonelaje: ${
              data?.minTonnageFactor
            } ${data?.header.uom === "INGLES" ? "Ton/in²" : "kN/mm²"}`}</Text>
            <Text style={styles.text}>{`Mínimo Factor de Tonelaje: ${
              data?.maxTonnageFactor
            } ${data?.header.uom === "INGLES" ? "Ton/in²" : "kN/mm²"}`}</Text>
          </View>
          <View>
            <Text style={styles.text}>{`Máximo Tonelaje de la maquina: ${
              data?.maxMachineClampForce
            } ${data?.header.uom === "INGLES" ? "Ton" : "kN"}`}</Text>
            <Text style={styles.text}>
              Factor de Seguridad:{" "}
              <Text style={styles.text}>{data?.safeFactor} %</Text>
            </Text>
          </View>
        </View>
        <Text style={styles.header}>Salidas</Text>
        <View style={styles.view}>
          <View>
            <Text style={styles.text}>{`Mínimo Tonelaje Sugerido: ${parseFloat(
              data?.minSuggestedClampForce
            ).toFixed(2)} ${
              data?.header.uom === "INGLES" ? "Ton" : "kN"
            }`}</Text>
            <Text style={styles.text}>{`Máximo Tonelaje Sugerido: ${parseFloat(
              data?.maxSuggestedClampForce
            ).toFixed(2)} ${
              data?.header.uom === "INGLES" ? "Ton" : "kN"
            }`}</Text>
          </View>
          <View>
            <Text style={styles.text}>{`Área Proyectada: ${parseFloat(
              data?.projectedArea
            ).toFixed(2)} ${
              data?.header.uom === "INGLES" ? "in²" : "mm²"
            }`}</Text>
            <Text style={styles.text}>{`Tonelaje Sugerido: ${parseFloat(
              data?.suggestedClampForce
            ).toFixed(2)} ${
              data?.header.uom === "INGLES" ? "Ton" : "kN"
            }`}</Text>
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            <>
              <Text style={styles.pageNumber}>https://molding-vps.com</Text>
            </>
          )}
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
      <MyDocument data={data} />
    </PDFViewer>
  );
  // Luego, usarías este `id` para obtener tus datos, probablemente con un useEffect y fetch o alguna llamada a una API.
};

export default ProjectedAreaPdf;
