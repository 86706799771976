import {
  inCubeCmCube,
  inCubeMmCube,
  inMm,
  inSquareMmSquare,
  lbHrKgHr,
  lbKg,
  ozGr,
  ozMinGrMin,
} from "../../../hooks/useUomTransform";
import { badgeColor } from "../../../../project-overview/components/utilities/variables";

export const dataResults = (data) => [
  {
    label: "Área de Pistón",
    value: data?.pistonArea,
    addOn: inSquareMmSquare(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Área de Tornillo",
    value: data?.screwArea,
    addOn: inSquareMmSquare(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Razón de Intensificación",
    value: data?.intRatio,
    addOn: ":1",
    type: "int",
    badgeColor: badgeColor,
  },
  {
    label: "Máximo Peso de Disparo",
    value: data?.barrelMaxWeight,
    addOn: ozGr(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Volumen Teórico de Barril",
    value: data?.barrelTheoricVolume,
    addOn: inCubeCmCube(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Volumen de Disparo",
    value: data?.shotVolume,
    addOn: inCubeCmCube(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Volumen del Runner",
    value: data?.runnerVolume,
    addOn: inCubeCmCube(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Peso de Primera Etapa con Runner",
    value: data?.shotWeightFirstStage,
    addOn: ozGr(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Peso de Cavidades al 100%",
    value: data?.cavitiesFullWeight,
    addOn: ozGr(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Peso de Cavidad al 100%",
    value: data?.singleCavityFullWeight,
    addOn: ozGr(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Peso de Cavidades 1a Etapa",
    value: data?.cavities1stStageWeight,
    addOn: ozGr(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Peso de Cavidad 1a Etapa",
    value: data?.cavity1stStageWeight,
    addOn: ozGr(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Tamaño Total de Disparo",
    value: data?.overallShotSize,
    addOn: inMm(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Disparos en el Barril",
    value: data?.shotsInBarrel,
    addOn: "shots",
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Posición Final de Runner",
    value: data?.runnerFinishPosition,
    addOn: inMm(data?.header?.uom),
    type: "weight",
    badgeColor: badgeColor,
  },
  {
    label: "Tamaño Teórico de Disparo",
    value: data?.shotPositionTheoric,
    addOn: inMm(data?.header?.uom),
    type: "weight",
    badgeColor:
      parseFloat(data?.shotPositionTheoric) <=
      parseFloat(data.maxShotSize) * (parseFloat(data.minBarrelUse) / 100)
        ? "red"
        : parseFloat(data?.shotPositionTheoric) >=
          parseFloat(data.maxShotSize) * (parseFloat(data.maxBarrelUse) / 100)
        ? "red"
        : "green",
  },
  {
    label: "Transferencia V/P Teórica",
    value: data?.cutoffPositionTheoric,
    addOn: inMm(data?.header?.uom),
    type: "weight",
    badgeColor:
      parseFloat(data?.cutoffPositionTheoric) <= parseFloat(data?.minCushion)
        ? "red"
        : parseFloat(data?.cutoffPositionTheoric) >=
          parseFloat(data?.maxCushion)
        ? "red"
        : "green",
  },
  {
    label: "Posición Teórica de Colchón",
    value: data?.cushionPosTheoric,
    addOn: inMm(data?.header?.uom),
    type: "weight",
    badgeColor:
      parseFloat(data?.cushionPosTheoric) <= parseFloat(data?.minCushion)
        ? "red"
        : parseFloat(data?.cushionPosTheoric) >= parseFloat(data?.maxCushion)
        ? "red"
        : "green",
  },
  {
    label: "Ocupación de Barril",
    value: data?.barrelUsage,
    addOn: "%",
    type: "percentage",
    badgeColor:
      parseFloat(data?.barrelUsage) <= parseFloat(data?.minBarrelUse)
        ? "red"
        : parseFloat(data?.barrelUsage) >= parseFloat(data?.maxBarrelUse)
        ? "red"
        : "green",
  },
];
