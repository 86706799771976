import {initialStateHeader} from "../../../utilities/variabled";

export const initialState = {
  header: initialStateHeader,
  prodQuantity: "",
  scrapRisk: "",
  numberOfCavities: "",
  hopperCapacity: "",
  estCycleTime: "",
  shotWeight100: "",
  pcsPerHour: "",
  reqShotsForProd: "",
  cycleTimeMin: "",
  resinFlowPerMin: "",
  consumeLbPerHr: "",
  hopperCapacityPcs: "",
  productionDuration: "",
  reqResinForProd: "",
  reloadTime: "",
  reloadSize: "",
  reloadReqAfterStart: "",
  resinTroughHopper: "",
}

export const keysForForm = ["prodQuantity", "scrapRisk", "numberOfCavities", "hopperCapacity", "estCycleTime", "shotWeight100"];