import { Grid } from "@chakra-ui/react";
import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../utilities/variables";
import { inMm, ozGr } from "../../../../../individual/hooks/useUomTransform";

const GateSealingGeneratedData = () => {
  const { project } = useContext(ProjectContext);

  const {
    min_Seal_Time_Total,
    max_Seal_Time_Total,
    min_Total_Weight_Obtained,
    max_Total_Weight_Obtained,
    min_Total_Weight_Ratio,
    max_Total_Weight_Ratio,
    min_Stages_Ratio,
    max_Stages_Ratio,
    maxGateSealingData,
  } = project;

  return (
    <Grid
      mt={5}
      p={5}
      borderWidth={1}
      borderRadius={15}
      mx={5}
      borderColor={borderColor}
    >
      <Grid
        templateRows="repeat(5, 1fr)"
        templateColumns="repeat(2, 1fr)"
        gap={6}
      >
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"seg"}
          type={"time"}
          value={min_Seal_Time_Total}
          title={"Tiempo Total de Sellado (Minimo)"}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"time"}
          rightAddon={"seg"}
          value={max_Seal_Time_Total}
          title={"Tiempo Total de Llenado (Maximo)"}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ozGr(project?.uom)}
          value={min_Total_Weight_Obtained}
          title={"Peso Final Obtenido (Minimo)"}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={ozGr(project?.uom)}
          value={max_Total_Weight_Obtained}
          title={"Peso Final Obtenido (Maximo)"}
        />
        <DataDisplayBadge
          type={"percentaje"}
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"%"}
          fix={2}
          value={min_Total_Weight_Ratio}
          title={"Porcentaje de Peso Final (Minimo)"}
        />
        <DataDisplayBadge
          type={"percentaje"}
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"%"}
          fix={2}
          value={max_Total_Weight_Ratio}
          title={"Porcentaje de Peso Final (Maximo)"}
        />
        <DataDisplayBadge
          type={"percentaje"}
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"%"}
          fix={2}
          value={min_Stages_Ratio}
          title={"Relacion 1ra y 2da Etapa (Minimo)"}
        />
        <DataDisplayBadge
          type={"percentaje"}
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"%"}
          fix={2}
          value={max_Stages_Ratio}
          title={"Relacion 1ra y 2da Etapa (Máximo)"}
        />
        <DataDisplayBadge
          type={"weight"}
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={inMm(project?.uom)}
          value={maxGateSealingData?.cushion}
          title={"Posicion de Colchon Real"}
        />
        <DataDisplayBadge
          type={"time"}
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"seg"}
          value={maxGateSealingData?.holdTime}
          title={"Mejor Tiempo de Sostenimiento Seleccionado"}
        />
      </Grid>
    </Grid>
  );
};

export default GateSealingGeneratedData;
