import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { useContext, useState } from "react";
import ProjectContext from "../../contexts/ProjectContext";
import { Project } from "../../models";
import ConditionalsAlert from "../../views/projects/hooks/ConditionalsAlert";

export const usePressureLossHooks = () => {
  const [showChart, setShowChart] = useState(false);

  const { project, updateProject, machine, updateLoading } = useContext(
    ProjectContext
  );

  const {
    intRatio,
    realMeltTemp,
    pr_Air_Shot,
    pr_Press_to_Runner,
    pr_Press_to_Gate,
    pr_Press_to_PostGate,
    pr_Press_to_1st_Stage_End,
    maxPlasticPressure,
    pressLost_Barrel_to_Nozzle,
    pressLost_Nozzle_to_Runner,
    pressLost_Runner_to_Gate,
    pressLost_Gate_to_PostGate,
    pressLost_PostGate_to_End,
  } = project;

  const { maxHydraulicPressure } = machine;

  const onSubmitHandler = async () => {
    updateLoading(true);
    const data = {
      intRatio,
      realMeltTemp,
      maxHydraulicPressure,
      maxPlasticPressure,
      pr_Air_Shot,
      pr_Press_to_Runner,
      pr_Press_to_Gate,
      pr_Press_to_PostGate,
      pr_Press_to_1st_Stage_End,
    };

    const restOperation = post({
      apiName: "pressureLoss",
      path: "/pressure-loss",
      options: {
        body: data,
      },
    });

    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);
    const {
      pr_Air_Shot_Ppsi,
      pr_Press_to_Runner_Ppsi,
      pr_Press_to_Gate_Ppsi,
      pr_Press_to_PostGate_Ppsi,
      pr_Press_to_1st_Stage_End_Ppsi,
      pressLost_Barrel_to_Nozzle,
      pressLost_Nozzle_to_Runner,
      pressLost_Runner_to_Gate,
      pressLost_Gate_to_PostGate,
      pressLost_PostGate_to_End,
      loss_Pressure_Preformance,
    } = res;

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.pr_Air_Shot_Ppsi = pr_Air_Shot_Ppsi.toString();
        updated.pr_Press_to_Runner_Ppsi = pr_Press_to_Runner_Ppsi.toString();
        updated.pr_Press_to_Gate_Ppsi = pr_Press_to_Gate_Ppsi.toString();
        updated.pr_Press_to_PostGate_Ppsi = pr_Press_to_PostGate_Ppsi.toString();
        updated.pr_Press_to_1st_Stage_End_Ppsi = pr_Press_to_1st_Stage_End_Ppsi.toString();
        updated.pressLost_Barrel_to_Nozzle = pressLost_Barrel_to_Nozzle.toString();
        updated.pressLost_Nozzle_to_Runner = pressLost_Nozzle_to_Runner.toString();
        updated.pressLost_Runner_to_Gate = pressLost_Runner_to_Gate.toString();
        updated.pressLost_Gate_to_PostGate = pressLost_Gate_to_PostGate.toString();
        updated.pressLost_PostGate_to_End = pressLost_PostGate_to_End.toString();
        updated.loss_Pressure_Preformance = loss_Pressure_Preformance.toString();
        updated.pr_Air_Shot = pr_Air_Shot.toString();
        updated.pr_Press_to_Runner = pr_Press_to_Runner.toString();
        updated.pr_Press_to_Gate = pr_Press_to_Gate.toString();
        updated.pr_Press_to_PostGate = pr_Press_to_PostGate.toString();
        updated.pr_Press_to_1st_Stage_End = pr_Press_to_1st_Stage_End.toString();
        updated.phase = "7";
      })
    )
      .then(async (res) => {
        const maxPlastic = parseInt(res.maxPlasticPressure);
        const shotAir = parseInt(res.pr_Air_Shot_Ppsi);
        const pressRunner = parseInt(res.pr_Press_to_Runner_Ppsi);
        const pressComp = parseInt(res.pr_Press_to_Gate_Ppsi);
        const pressPost = parseInt(res.pr_Press_to_PostGate_Ppsi);
        const pressFinal = parseInt(res.pr_Press_to_1st_Stage_End_Ppsi);
        let icon = "success";
        let text = "";
        if (maxPlastic < shotAir) {
          icon = "warning";
          text +=
            "<li>El <b>Disparo al Aire</b> es mayor que la <b>Presión Plastica Máxima.</b></li>";
        } else if (maxPlastic === shotAir) {
          icon = "warning";
          text +=
            "<li>El <b>Disparo al Aire</b> es igual a la <b>Presión Plastica Máxima.</b></li>";
        }
        if (maxPlastic < pressRunner) {
          icon = "warning";
          text +=
            "<li>La <b>Presión al Runner</b> es mayor que la <b>Presión Plastica Máxima.</b></li>";
        } else if (maxPlastic === pressRunner) {
          icon = "warning";
          text +=
            "<li>La <b>Presión al Runner</b> es igual a la <b>Presión Plastica Máxima.</b></li>";
        }
        if (maxPlastic < pressComp) {
          icon = "warning";
          text +=
            "<li>La <b>Presión a la Compuerta</b> es mayor que la <b>Presión Plastica Máxima.</b></li>";
        } else if (maxPlastic === pressComp) {
          icon = "warning";
          text +=
            "<li>La <b>Presión a la Compuerta</b> es igual a la <b>Presión Plastica Máxima.</b></li>";
        }
        if (maxPlastic < pressPost) {
          icon = "warning";
          text +=
            "<li>La <b>Presión Post-Gate</b> es mayor que la <b>Presión Plastica Máxima.</b></li>";
        } else if (maxPlastic === pressPost) {
          icon = "warning";
          text +=
            "<li>La <b>Presión Post-Gate</b> es igual a la <b>Presión Plastica Máxima.</b></li>";
        }
        if (maxPlastic < pressFinal) {
          icon = "warning";
          text +=
            "<li>La <b>Presión Final de Primera Etapa</b> es mayor que la <b>Presión Plastica Máxima.</b></li>";
        } else if (maxPlastic === pressFinal) {
          icon = "warning";
          text +=
            "<li>La <b>Presión Final de Primera Etapa</b> es igual a la <b>Presión Plastica Máxima.</b></li>";
        }
        ConditionalsAlert(icon, text);
        await updateProject(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        updateLoading(false);
        setShowChart(true);
      });
  };

  const lineChartOptionsPressureLoss = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Barril a Nariz",
        "Nariz a Runner",
        "Runner a Gate",
        "Gate a Post-Gate",
        "Post-Gate a Final",
      ],
      labels: {
        style: {
          colors: "#000000",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#000",
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#4267B2", "#4267B2"],
    },
    colors: ["#4267B2", "#4267B2"],
  };

  const lineChartDataPressureLoss = [
    {
      name: "Acumulado",
      type: "line",
      data: [
        parseInt(pressLost_Barrel_to_Nozzle),
        parseInt(pressLost_Nozzle_to_Runner) +
          parseInt(pressLost_Barrel_to_Nozzle),
        parseInt(pressLost_Nozzle_to_Runner) +
          parseInt(pressLost_Barrel_to_Nozzle) +
          parseInt(pressLost_Runner_to_Gate),
        parseInt(pressLost_Nozzle_to_Runner) +
          parseInt(pressLost_Barrel_to_Nozzle) +
          parseInt(pressLost_Runner_to_Gate) +
          parseInt(pressLost_Gate_to_PostGate),
        parseInt(pressLost_Nozzle_to_Runner) +
          parseInt(pressLost_Barrel_to_Nozzle) +
          parseInt(pressLost_Runner_to_Gate) +
          parseInt(pressLost_Gate_to_PostGate) +
          parseInt(pressLost_PostGate_to_End),
      ],
    },
    {
      name: "Perdida de Presión",
      type: "column",
      data: [
        parseInt(pressLost_Barrel_to_Nozzle),
        parseInt(pressLost_Nozzle_to_Runner),
        parseInt(pressLost_Runner_to_Gate),
        parseInt(pressLost_Gate_to_PostGate),
        parseInt(pressLost_PostGate_to_End),
      ],
    },
  ];

  return {
    showChart,
    setShowChart,
    onSubmitHandler,
    lineChartOptionsPressureLoss,
    lineChartDataPressureLoss,
  };
};
