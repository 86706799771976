import { useState, useContext, useEffect } from "react";
import validateForm from "./validateForm";
import ProjectContext from "../../../../../../../contexts/ProjectContext";

export const useGateSealingState = () => {
  const { project, updateProject } = useContext(ProjectContext);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const formIsValid = validateForm(
      project.minPackTable,
      project.maxPackTable
    );
    setIsButtonEnabled(formIsValid);
  }, [project.minPackTable, project.maxPackTable]);

  return {
    isButtonEnabled,
    showChart,
    setShowChart,
  };
};
