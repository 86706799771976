import { Flex, FormLabel } from "@chakra-ui/react";
import Select from "react-select";

const SelectionSection = ({
  title,
  data,
  onChange,
  selectOptions,
  isDisabled,
  name,
  color = "white",
}) => {
  return (
    <Flex direction={"column"} mx={10} minWidth={"250px"} color={"black"}>
      <FormLabel color={color}>{title}</FormLabel>
      <Select
        isDisabled={isDisabled}
        defaultValue={data?.shot ? selectOptions[parseInt(data.shot - 1)] : ""}
        value={data?.shot ? selectOptions[parseInt(data.shot - 1)] : ""}
        onChange={(e) => onChange(e, name)}
        options={selectOptions}
      />
    </Flex>
  );
};

export default SelectionSection;
