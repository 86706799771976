import { useContext, useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";

const generateInitialMinPackTable = () => {
  const initialMinPackTable = [];
  for (let i = 0; i < 10; i++) {
    initialMinPackTable.push({
      shot: i + 1,
      holdTime: "",
      shootingWeight: "",
      cushion: "",
      cycleTime: "",
    });
  }
  return initialMinPackTable;
};

const generateInitialMaxPackTable = () => {
  const initialMaxPackTable = [];
  for (let i = 0; i < 10; i++) {
    initialMaxPackTable.push({
      shot: i + 1,
      holdTime: "",
      shootingWeight: "",
      cushion: "",
      cycleTime: "",
    });
  }
  return initialMaxPackTable;
};

const useGateSealingHooks = () => {
  const {
    project,
    updateProject,
    handleInputChange,
    updateLoading,
  } = useContext(ProjectContext);
  const [showData, setShowData] = useState(false);
  const [final, setFinal] = useState(false);
  const {
    minPackTable,
    maxPackTable,
    speedSelected,
    maxGateSealingData,
    minGateSealingData,
    best_Hold_Time_Select,
    best_Hold_Press_Select,
  } = project;
  const [minPackSelectionError, setMinPackSelectionError] = useState("");
  const [maxPackSelectionError, setMaxPackSelectionError] = useState("");
  const [isMaxPackSelectionEnabled, setIsMaxPackSelectionEnabled] = useState(
    false
  );

  useEffect(() => {
    updateHoldTime();
  }, [project.max_Hold_Time]);

  const initialMinPackTable =
    project.minPackTable || generateInitialMinPackTable();
  const initialMaxPackTable =
    project.maxPackTable || generateInitialMaxPackTable();

  const saveMinPackTableChanges = (index, field, value) => {
    const updatedMinPackTable = [...minPackTable];
    updatedMinPackTable[index][field] = value;

    updateProject({ ...project, minPackTable: updatedMinPackTable });
  };

  const saveMaxPackTableChanges = (index, field, value) => {
    const updatedMaxPackTable = [...maxPackTable];
    updatedMaxPackTable[index][field] = value;

    updateProject({ ...project, maxPackTable: updatedMaxPackTable });
  };

  const updateHoldTime = () => {
    const newMinPackTable = initialMinPackTable.map((row, index) => ({
      ...row,
      holdTime: (index * (project.max_Hold_Time / 9)).toFixed(3),
    }));
    const newMaxPackTable = initialMaxPackTable.map((row, index) => ({
      ...row,
      holdTime: (index * (project.max_Hold_Time / 9)).toFixed(3),
    }));

    updateProject({
      ...project,
      minPackTable: newMinPackTable,
      maxPackTable: newMaxPackTable,
    });
  };

  const handleMinPackSelectionBlur = (e) => {
    const newMinPackSelection = parseFloat(e.target.value);
    const minHoldPressPpsi = parseFloat(project.min_Hold_Press_Ppsi);
    const maxHoldPressPpsi = parseFloat(project.max_Hold_Press_Ppsi);
    if (
      newMinPackSelection > minHoldPressPpsi &&
      newMinPackSelection < maxHoldPressPpsi
    ) {
      setMinPackSelectionError("");
    } else if (newMinPackSelection < minHoldPressPpsi) {
      setMinPackSelectionError(
        `La Presion Minima Requerida debe de ser mayor a ${project.min_Hold_Press_Ppsi}`
      );
    } else if (newMinPackSelection > maxHoldPressPpsi) {
      setMinPackSelectionError(
        `La Presion Minima Requerida debe de ser menor a ${project.max_Hold_Press_Ppsi}`
      );
    } else {
      setMinPackSelectionError("");
    }

    if (
      newMinPackSelection > minHoldPressPpsi &&
      newMinPackSelection < maxHoldPressPpsi
    ) {
      handleInputChange(e);
      setIsMaxPackSelectionEnabled(true);
    } else {
      setIsMaxPackSelectionEnabled(false);
    }
  };

  const handleMaxPackSelectionBlur = (e) => {
    const newMaxPackSelection = parseFloat(e.target.value);
    const currentMinPackSelection = parseFloat(project.min_Pack_Selection);
    const maxHoldPressPpsi = parseFloat(project.max_Hold_Press_Ppsi);

    if (
      newMaxPackSelection > currentMinPackSelection + 1 &&
      newMaxPackSelection <= maxHoldPressPpsi
    ) {
      handleInputChange(e);
      setMaxPackSelectionError("");
    } else if (newMaxPackSelection < currentMinPackSelection + 1) {
      setMaxPackSelectionError(
        `La Presión Máxima Requerida debe de ser mayor a ${project?.min_Pack_Selection}`
      );
    } else if (newMaxPackSelection >= maxHoldPressPpsi) {
      setMaxPackSelectionError(
        `La Presión Máxima Requerida debe de ser menor o igual a ${project?.max_Hold_Press_Ppsi}`
      );
    }
  };

  const onSubmitHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "gateSealing",
      path: "/gate-sealing",
      options: {
        body: {
          //speedSelected?.shootingWeight
          shotWeight100: project.shotWeight100,
          injectionSpeed: speedSelected?.inyectionTime,
          shootingWeight: speedSelected?.shootingWeight,
          maxGateSealingData,
          minGateSealingData,
        },
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();

    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.max_Best_Hold_Time = res.max_Best_Hold_Time.toString();
        updated.max_Seal_Time_Total = res.max_Seal_Time_Total.toString();
        updated.max_Stages_Ratio = res.max_Stages_Ratio.toString();
        updated.max_Total_Weight_Obtained = res.max_Total_Weight_Obtained.toString();
        updated.max_Total_Weight_Ratio = res.max_Total_Weight_Ratio.toString();
        updated.min_Best_Hold_Time = res.min_Best_Hold_Time.toString();
        updated.min_Seal_Time_Total = res.min_Seal_Time_Total.toString();
        updated.min_Stages_Ratio = res.min_Stages_Ratio.toString();
        updated.min_Total_Weight_Obtained = res.min_Total_Weight_Obtained.toString();
        updated.min_Total_Weight_Ratio = res.min_Total_Weight_Ratio.toString();
        updated.maxGateSealingData = maxGateSealingData;
        updated.minGateSealingData = minGateSealingData;
        updated.minPackTable = minPackTable;
        updated.maxPackTable = maxPackTable;
        updated.max_Hold_Time = project.max_Hold_Time.toString();
        updated.min_Pack_Selection = project.min_Pack_Selection.toString();
        updated.max_Pack_Selection = project.max_Pack_Selection.toString();
        updated.hold_Time_Intervals = (
          parseFloat(project.max_Hold_Time) / 9
        ).toString();
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowData(true);
        updateLoading(false);
      });
  };

  const onSavePackTable = async () => {
    updateLoading(true);
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.minPackTable = project.minPackTable;
        updated.maxPackTable = project.maxPackTable;
        updated.max_Hold_Time = project.max_Hold_Time;
        updated.min_Pack_Selection = project.min_Pack_Selection;
        updated.max_Pack_Selection = project.max_Pack_Selection;
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => console.log(err))
      .finally(() => updateLoading(false));
  };

  const onSubmitFinal = async () => {
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.best_Hold_Time_Select = best_Hold_Time_Select.toString();
        updated.best_Hold_Press_Select = best_Hold_Press_Select.toString();
        updated.phase = "13";
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateLoading(false);
        setShowData(true);
        setFinal(true);
      });
  };
  return {
    saveMinPackTableChanges,
    saveMaxPackTableChanges,
    handleMaxPackSelectionBlur,
    minPackSelectionError,
    handleMinPackSelectionBlur,
    maxPackSelectionError,
    isMaxPackSelectionEnabled,
    onSubmitHandler,
    showData,
    setShowData,
    onSubmitFinal,
    setFinal,
    final,
    onSavePackTable,
  };
};

export default useGateSealingHooks;
