export const tableInitialState = [
  {
    sequence: "1",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "2",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "3",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "4",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "5",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "6",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "7",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "8",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "9",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
  {
    sequence: "10",
    speed: "",
    fillTime: "",
    maxPress: "",
    shotWeight: "",
    vpTransfer: "",
    maxPressPpsi: "",
    flowRate: "",
    realSpeed: "",
    efficence: "",
    relVisc: "",
    shearRate: "",
  },
]