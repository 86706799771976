import {
  Button, FormLabel,
  Grid,
  GridItem,
  Heading,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import CustomInput from "../../../../../projects/individual/components/CustomInput";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { textColor } from "../../../../../users/molds/utilities/variables";
import { Label } from "@aws-amplify/ui-react";
import Select from "react-select";

const AnalisisForm = ({
  errors,
  geaDataInput,
  handleInputChange,
  setGeaDataInput,
                        onChangeSelect
}) => {
  const handleSubmit = async () => {
    if (geaDataInput.phase === 3) {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 4 }));
    } else {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 3 }));
    }
  };

  const tempOptions = [
    { value: "°F", label: "°F" },
    { value: "°C", label: "°C" },
  ];
  const longOptions = [
    { value: "in", label: "in" },
    { value: "mm", label: "mm" },
  ];
  const pressOptions = [
    { value: "bar", label: "bar" },
    { value: "psi", label: "psi" },
  ];
  const speedOptions = [
    { value: "in/seg", label: "in/seg" },
    { value: "mm/seg", label: "mm/seg" },
  ];
  const rotationOptions = [
    { value: "RPM", label: "RPM" },
    { value: "mm/seg", label: "mm/seg" },
    { value: "in/seg", label: "in/seg" },
  ];

  const options = [
    { value: "MALA", label: "MALA" },
    { value: "REGULAR", label: "REGULAR" },
    { value: "BUENA", label: "BUENA" },
  ];

  const {
    addUomTemp,
    addUomLong,
    addUomRotationSpeed,
    addUomPress,
    addUomSpeed,
  } = geaDataInput

  return (
    <>
      {geaDataInput.phase === 3 && (
        <>
          <GridItem colSpan={4} mb={5}>
            <Heading as={"h1"}>ANÁLISIS DE RESULTADOS</Heading>
          </GridItem>
          <GridItem colSpan={4} mb={5} width={"80%"}>
            <Text as={"h3"}>
              Completar los campos requeridos del formulario, para establecer un
              punto de referencia y comparación en la evaluación de resultados.
              Ingresar "0" en caso de que el campo no sea requerido. El mayor
              numero de resultados nos ayudara a tener un analisis de eficiencia
              mas certero.
            </Text>
          </GridItem>

          <GridItem colSpan={4} width={"80%"}>
            <Grid
              justifyItems={"center"}
              templateColumns="repeat(5, 1fr)"
              alignItems={"end"}
              gap={6}
              justifyContent={"center"}
              width={"100%"}
            >
              <div>
                <label>Unidad de Medida de Temperatura</label>
                <Select
                  onChange={(e) => onChangeSelect("addUomTemp", e.value)}
                  id={"addUomTemp"}
                  isSearchable
                  defaultValue={tempOptions[0]}
                  options={tempOptions}
                />
              </div>
              <div>
                <label>Unidad de Medida de Longitud</label>
                <Select
                  onChange={(e) => onChangeSelect("addUomLong", e.value)}
                  id={"addUomLong"}
                  isSearchable
                  defaultValue={longOptions[0]}
                  options={longOptions}
                />
              </div>
              <div>
                <label>Unidad de Medida de Rotacion</label>
                <Select
                  onChange={(e) => onChangeSelect("addUomRotationSpeed", e.value)}
                  id={"addUomRotationSpeed"}
                  isSearchable
                  defaultValue={rotationOptions[0]}
                  options={rotationOptions}
                />
              </div>
              <div>
                <label>Unidad de Medida de Presión</label>
                <Select
                  onChange={(e) => onChangeSelect("addUomPress", e.value)}
                  id={"addUomPress"}
                  isSearchable
                  defaultValue={pressOptions[0]}
                  options={pressOptions}
                />
              </div>
              <div>
                <label>Unidad de Medida de Velocidad</label>
                <Select
                  onChange={(e) => onChangeSelect("addUomSpeed", e.value)}
                  id={"addUomSpeed"}
                  isSearchable
                  defaultValue={speedOptions[0]}
                  options={speedOptions}
                />
              </div>
            </Grid>
            <Grid
              justifyItems={"center"}
              templateColumns="repeat(3, 1fr)"
              alignItems={"end"}
              gap={3}
              justifyContent={"center"}
              width={"100%"}
            >
              <GridItem colSpan={3}>
                <Heading as={"h2"} children={"Inputs de Proceso"}/>
              </GridItem>
              <Text as={"h2"} fontWeight={"bold"} children={"Sin Einar 201"}/>
              <Text as={"h2"} fontWeight={"bold"} children={"Con Einar 201"}/>
              <Text as={"h2"} fontWeight={"bold"} children={"Delta"}/>
              <CustomInput
                label={"Temperatura de Barril Zona Trasera"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaRearBefore"}
                value={geaDataInput.geaRearBefore}
                error={errors.geaRearBefore}
              />
              <CustomInput
                label={"Temperatura de Barril Zona Trasera"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaRearAfter"}
                value={geaDataInput.geaRearAfter}
                error={errors.geaRearAfter}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                rightAddon={addUomTemp}
                type={"temp"}
                value={geaDataInput.geaRearDelta}
                badgeColorScheme={
                  geaDataInput.geaRearDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                label={"Temperratura de Barril Zona Central"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaCenterBefore"}
                value={geaDataInput.geaCenterBefore}
                error={errors.geaCenterBefore}
              />
              <CustomInput
                label={"Temperratura de Barril Zona Central"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaCenterAfter"}
                value={geaDataInput.geaCenterAfter}
                error={errors.geaCenterAfter}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaCenterDelta}
                rightAddon={addUomTemp}
                badgeColorScheme={
                  geaDataInput.geaCenterDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                label={"Temperratura de Barril Zona Frontal"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaFrontBefore"}
                value={geaDataInput.geaFrontBefore}
                error={errors.geaFrontBefore}
              />
              <CustomInput
                label={"Temperratura de Barril Zona Frontal"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaFrontAfter"}
                value={geaDataInput.geaFrontAfter}
                error={errors.geaFrontAfter}
              />
              <DataDisplayBadge
                rightAddon={addUomTemp}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaFrontDelta}
                badgeColorScheme={
                  geaDataInput.geaFrontDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                label={"Temperatura de Barril Zona Nariz"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaNozzleBefore"}
                value={geaDataInput.geaNozzleBefore}
                error={errors.geaNozzleBefore}
              />
              <CustomInput
                label={"Temperatura de Barril Zona Nariz"}
                addOnText={addUomTemp}
                onChange={handleInputChange}
                id={"geaNozzleAfter"}
                value={geaDataInput.geaNozzleAfter}
                error={errors.geaNozzleAfter}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                rightAddon={addUomTemp}
                type={"temp"}
                value={geaDataInput.geaNozzleDelta}
                badgeColorScheme={
                  geaDataInput.geaNozzleDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                label={"Posición de Carga de Disparo"}
                addOnText={addUomLong}
                onChange={handleInputChange}
                id={"geaShotSizeBefore"}
                value={geaDataInput.geaShotSizeBefore}
                error={errors.geaShotSizeBefore}
              />
              <CustomInput
                label={"Posición de Carga de Disparo"}
                addOnText={addUomLong}
                onChange={handleInputChange}
                id={"geaShotSizeAfter"}
                value={geaDataInput.geaShotSizeAfter}
                error={errors.geaShotSizeAfter}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                rightAddon={addUomLong}
                type={"temp"}
                value={geaDataInput.geaShotSizeDelta}
                badgeColorScheme={
                  geaDataInput.geaShotSizeDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaTransferBefore"}
                value={geaDataInput.geaTransferBefore}
                error={errors.geaTransferBefore}
                label={"Posición Transferencia V/P"}
                addOnText={addUomLong}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaTransferAfter"}
                value={geaDataInput.geaTransferAfter}
                error={errors.geaTransferAfter}
                label={"Posición Transferencia V/P"}
                addOnText={addUomLong}
              />
              <DataDisplayBadge
                rightAddon={addUomLong}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaTransferDelta}
                badgeColorScheme={
                  geaDataInput.geaTransferDelta > 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaRpmBefore"}
                value={geaDataInput.geaRpmBefore}
                error={errors.geaRpmBefore}
                label={"Velocidad de Rotación"}
                addOnText={addUomRotationSpeed}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaRpmAfter"}
                value={geaDataInput.geaRpmAfter}
                error={errors.geaRpmAfter}
                label={"Velocidad de Rotación"}
                addOnText={addUomRotationSpeed}
              />
              <DataDisplayBadge
                rightAddon={addUomRotationSpeed}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaRpmDelta}
                badgeColorScheme={
                  geaDataInput.geaRpmDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaBackpressBefore"}
                value={geaDataInput.geaBackpressBefore}
                error={errors.geaBackpressBefore}
                label={"Contrapresión"}
                addOnText={addUomPress}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaBackpressAfter"}
                value={geaDataInput.geaBackpressAfter}
                error={errors.geaBackpressAfter}
                label={"Contrapresión"}
                addOnText={addUomPress}
              />
              <DataDisplayBadge
                rightAddon={addUomPress}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaBackpressDelta}
                badgeColorScheme={
                  geaDataInput.geaBackpressDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaInjSpeedBefore"}
                value={geaDataInput.geaInjSpeedBefore}
                error={errors.geaInjSpeedBefore}
                label={"Velocidad de Inyección"}
                addOnText={addUomSpeed}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaInjSpeedAfter"}
                value={geaDataInput.geaInjSpeedAfter}
                error={errors.geaInjSpeedAfter}
                label={"Velocidad de Inyección"}
                addOnText={addUomSpeed}
              />
              <DataDisplayBadge
                rightAddon={addUomSpeed}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaInjSpeedDelta}
                badgeColorScheme={
                  geaDataInput.geaInjSpeedDelta > 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaInjPressBefore"}
                value={geaDataInput.geaInjPressBefore}
                error={errors.geaInjPressBefore}
                label={"Presión de Inyección"}
                addOnText={addUomPress}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaInjPressAfter"}
                value={geaDataInput.geaInjPressAfter}
                error={errors.geaInjPressAfter}
                label={"Presión de Inyección"}
                addOnText={addUomPress}
              />
              <DataDisplayBadge
                rightAddon={addUomPress}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaInjPressDelta}
                badgeColorScheme={
                  geaDataInput.geaInjPressDelta < 0 ? "green" : "red"
                }
              />

              <GridItem colSpan={3}>
                <Heading as={"h2"} children={"Outputs de Proceso"}/>
              </GridItem>
              <Text as={"h2"} fontWeight={"bold"} children={"Sin Einar 201"}/>
              <Text as={"h2"} fontWeight={"bold"} children={"Con Einar 201"}/>
              <Text as={"h2"} fontWeight={"bold"} children={"Delta"}/>

              <CustomInput
                onChange={handleInputChange}
                id={"geaTempMeltBefore"}
                value={geaDataInput.geaTempMeltBefore}
                error={errors.geaTempMeltBefore}
                label={"Temperatura de Masa Fundida"}
                addOnText={addUomTemp}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaTempMeltAfter"}
                value={geaDataInput.geaTempMeltAfter}
                error={errors.geaTempMeltAfter}
                label={"Temperatura de Masa Fundida"}
                addOnText={addUomTemp}
              />
              <DataDisplayBadge
                rightAddon={addUomTemp}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaTempMeltDelta}
                badgeColorScheme={
                  geaDataInput.geaTempMeltDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaCushionBefore"}
                value={geaDataInput.geaCushionBefore}
                error={errors.geaCushionBefore}
                label={"Posición de Colchón"}
                addOnText={addUomLong}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaCushionAfter"}
                value={geaDataInput.geaCushionAfter}
                error={errors.geaCushionAfter}
                label={"Posición de Colchón"}
                addOnText={addUomLong}
              />
              <DataDisplayBadge
                rightAddon={addUomLong}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaCushionDelta}
                badgeColorScheme={
                  geaDataInput.geaCushionDelta > 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaMaxPressBefore"}
                value={geaDataInput.geaMaxPressBefore}
                error={errors.geaMaxPressBefore}
                label={"Máxima Presión de Inyección"}
                addOnText={addUomPress}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaMaxPressAfter"}
                value={geaDataInput.geaMaxPressAfter}
                error={errors.geaMaxPressAfter}
                label={"Máxima Presión de Inyección"}
                addOnText={addUomPress}
              />
              <DataDisplayBadge
                rightAddon={addUomPress}
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaMaxPressDelta}
                badgeColorScheme={
                  geaDataInput.geaMaxPressDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaRecoveryTimeBefore"}
                value={geaDataInput.geaRecoveryTimeBefore}
                error={errors.geaRecoveryTimeBefore}
                label={"Tiempo de Carga"}
                addOnText={"seg"}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaRecoveryTimeAfter"}
                value={geaDataInput.geaRecoveryTimeAfter}
                error={errors.geaRecoveryTimeAfter}
                label={"Tiempo de Carga"}
                addOnText={"seg"}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaRecoveryTimeDelta}
                badgeColorScheme={
                  geaDataInput.geaRecoveryTimeDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaFillTimeBefore"}
                value={geaDataInput.geaFillTimeBefore}
                error={errors.geaFillTimeBefore}
                label={"Tiempo de Inyección"}
                addOnText={"seg"}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaFillTimeAfter"}
                value={geaDataInput.geaFillTimeAfter}
                error={errors.geaFillTimeAfter}
                label={"Tiempo de Inyección"}
                addOnText={"seg"}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaFillTimeDelta}
                badgeColorScheme={
                  geaDataInput.geaFillTimeDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaCycleTimeBefore"}
                value={geaDataInput.geaCycleTimeBefore}
                error={errors.geaCycleTimeBefore}
                label={"Tiempo de Ciclo"}
                addOnText={"seg"}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaCycleTimeAfter"}
                value={geaDataInput.geaCycleTimeAfter}
                error={errors.geaCycleTimeAfter}
                label={"Tiempo de Ciclo"}
                addOnText={"seg"}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaCycleTimeDelta}
                badgeColorScheme={
                  geaDataInput.geaCycleTimeDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaRpmAmpBefore"}
                value={geaDataInput.geaRpmAmpBefore}
                error={errors.geaRpmAmpBefore}
                label={"Max Amperaje de Rotación"}
                addOnText={"amp"}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaRpmAmpAfter"}
                value={geaDataInput.geaRpmAmpAfter}
                error={errors.geaRpmAmpAfter}
                label={"Max Amperaje de Rotación"}
                addOnText={"amp"}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaRpmAmpDelta}
                badgeColorScheme={
                  geaDataInput.geaRpmAmpDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaInjectionAmpBefore"}
                value={geaDataInput.geaInjectionAmpBefore}
                error={errors.geaInjectionAmpBefore}
                label={"Máx Amperaje de Inyección"}
                addOnText={"amp"}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaInjectionAmpAfter"}
                value={geaDataInput.geaInjectionAmpAfter}
                error={errors.geaInjectionAmpAfter}
                label={"Máx Amperaje de Inyección"}
                addOnText={"amp"}
              />
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"temp"}
                value={geaDataInput.geaInjectionAmpDelta}
                badgeColorScheme={
                  geaDataInput.geaInjectionAmpDelta < 0 ? "green" : "red"
                }
              />

              <CustomInput
                onChange={handleInputChange}
                id={"geaShotWeightBefore"}
                value={geaDataInput.geaShotWeightBefore}
                error={errors.geaShotWeightBefore}
                label={"Peso Real de Pieza"}
                addOnText={geaDataInput.geaUdM}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaShotWeightAfter"}
                value={geaDataInput.geaShotWeightAfter}
                error={errors.geaShotWeightAfter}
                label={"Peso Real de Pieza"}
                addOnText={geaDataInput.geaUdM}
              />
              <DataDisplayBadge
                rightAddon={geaDataInput.geaUdM}
                title={"Delta"}
                color={textColor}
                type={"weight"}
                value={geaDataInput.geaShotWeightDelta}
                badgeColorScheme={
                  geaDataInput.geaShotWeightDelta < 0 ? "green" : "red"
                }
              />

              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Propiedad Desmoldante
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaMoldReleaseBefore", e.value)}
                  id={"addUomTemp"}
                  isSearchable
                  defaultValue={options[0]}
                  options={options}
                />
              </GridItem>
              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Propiedad Desmoldante
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaMoldReleaseAfter", e.value)}
                  id={"geaMoldReleaseAfter"}
                  isSearchable
                  defaultValue={options[1]}
                  options={options}
                />
              </GridItem>
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"text"}
                value={geaDataInput.geaMoldReleaseDelta}
                badgeColorScheme={
                  geaDataInput.geaMoldReleaseDelta === "Mejorado"
                    ? "green"
                    : "red"
                }
              />
              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Propiedad Anti Polvo
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaAntiDustBefore", e.value)}
                  id={"addUomTemp"}
                  isSearchable
                  defaultValue={options[0]}
                  options={options}
                />
              </GridItem>
              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Propiedad Anti Polvo
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaAntiDustAfter", e.value)}
                  id={"addUomTemp"}
                  isSearchable
                  defaultValue={options[1]}
                  options={options}
                />
              </GridItem>

              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"text"}
                value={geaDataInput.geaAntiDustDelta}
                badgeColorScheme={
                  geaDataInput.geaAntiDustDelta === "Mejorado" ? "green" : "red"
                }
              />

              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                 Propiedad Anti estatica
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaAntiStaticBefore", e.value)}
                  id={"geaAntiStaticBefore"}
                  isSearchable
                  defaultValue={options[0]}
                  options={options}
                />
              </GridItem>
              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Propiedad Anti estatica
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaAntiStaticAfter", e.value)}
                  id={"geaAntiStaticAfter"}
                  isSearchable
                  defaultValue={options[1]}
                  options={options}
                />
              </GridItem>
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"text"}
                value={geaDataInput.geaAntiStaticDelta}
                badgeColorScheme={
                  geaDataInput.geaAntiStaticDelta === "Mejorado"
                    ? "green"
                    : "red"
                }
              />

              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Propiedad Denesting
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaDenestingBefore", e.value)}
                  id={"geaDenestingBefore"}
                  isSearchable
                  defaultValue={options[0]}
                  options={options}
                />
              </GridItem>
              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Propiedad Denesting
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaDenestingAfter", e.value)}
                  id={"geaDenestingAfter"}
                  isSearchable
                  defaultValue={options[1]}
                  options={options}
                />
              </GridItem>
              <DataDisplayBadge
                title={"Delta"}
                color={textColor}
                type={"text"}
                value={geaDataInput.geaDenestingDelta}
                badgeColorScheme={
                  geaDataInput.geaDenestingDelta === "Mejorado"
                    ? "green"
                    : "red"
                }
              />
            </Grid>
          </GridItem>
          <GridItem colSpan={4} mb={5} width={"80%"} justifyItems={"center"}>
            <Grid>
              <Label my={1} fontWeight={"bold"}>
                <Text
                  color={`${
                    geaDataInput.analisisComments?.length > 500 && "red"
                  }`}
                >
                  {`Comentarios - ${geaDataInput.analisisComments?.length} / 500 caracteres`}
                </Text>
              </Label>
              <Textarea
                value={geaDataInput.analisisComments}
                mt={5}
                id={"analisisComments"}
                onChange={handleInputChange}
                type={"textarea"}
                bgColor={"white"}
                placeholder={"Escribe aqui tus comentarios..."}
              />
            </Grid>
          </GridItem>
        </>
      )}
      <GridItem colSpan={4} mb={5} width={"80%"} justifyItems={"center"}>
        <Grid>
          <Button
            onClick={() => handleSubmit()}
            children={
              geaDataInput.phase === 3 ? "Continuar" : "Editar Analisis"
            }
            colorScheme={"whatsapp"}
            width={"full"}
            mt={5}
          />
        </Grid>
      </GridItem>
    </>
  );
};

export default AnalisisForm;
