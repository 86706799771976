import React from "react";
import {Grid} from "@chakra-ui/react";

const CollaboratorsVideo = ({ videoSrc, autoplay = false }) => {
  return (
    <Grid borderRadius={15}>
      <video  autoPlay={autoplay} src={videoSrc} controls></video>
    </Grid>

  );
};

export default CollaboratorsVideo;
