import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import { useContext, useEffect, useRef, useState } from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { DataStore } from "aws-amplify/datastore";
import { LossPressure } from "../../../../../../models";
import { initialState } from "../utilities/variables";
import { useAlert } from "../../../../../../contexts/useAlert";
import html2canvas from "html2canvas";
import { delay } from "../../../hooks/useDelay";
import { uploadData } from "aws-amplify/storage";
import UserContext from "../../../../../../contexts/UserContext";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";

export const useIndividualHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();

  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const chartRef = useRef();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const createImages = async () => {
    await delay(500);
    const canvas = await html2canvas(chartRef.current);

    // Convertir el canvas a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Falló la conversión de canvas a blob"));
        }
      }, "image/png");
    });
  };

  const onChangeHandler = (e) => {
    const { id, value } = e.target;

    if (id.includes(".")) {
      const [outerKey, innerKey] = id.split(".");

      setData((prevData) => ({
        ...prevData,
        [outerKey]: {
          ...prevData[outerKey],
          [innerKey]: value,
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const lineChartDataPressureLoss = [
    {
      name: "Acumulado",
      type: "line",
      data: [
        parseInt(data?.pressLostBarrelToNozzle),
        parseInt(data?.pressLostNozzleToRunner) +
          parseInt(data?.pressLostBarrelToNozzle),
        parseInt(data?.pressLostNozzleToRunner) +
          parseInt(data?.pressLostBarrelToNozzle) +
          parseInt(data?.pressLostRunnerToGate),
        parseInt(data?.pressLostNozzleToRunner) +
          parseInt(data?.pressLostBarrelToNozzle) +
          parseInt(data?.pressLostRunnerToGate) +
          parseInt(data?.pressLostGateToPostGate),
        parseInt(data?.pressLostNozzleToRunner) +
          parseInt(data?.pressLostBarrelToNozzle) +
          parseInt(data?.pressLostRunnerToGate) +
          parseInt(data?.pressLostGateToPostGate) +
          parseInt(data?.pressLostPostGateToEnd),
      ],
    },
    {
      name: "Perdida de Presión",
      type: "column",
      data: [
        parseInt(data?.pressLostBarrelToNozzle),
        parseInt(data?.pressLostNozzleToRunner),
        parseInt(data?.pressLostRunnerToGate),
        parseInt(data?.pressLostGateToPostGate),
        parseInt(data?.pressLostPostGateToEnd),
      ],
    },
  ];

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const restOperation = post({
        path: "/pressure-loss-individual",
        apiName: "pressureLoss",
        options: {
          body: {
            ...data,
          },
        },
      });
      const rest = await restOperation.response;
      const body = await rest.body.json();

      await setData({ ...data, ...body });

      // Espera a que la imagen sea creada antes de continuar
      const image = await createImages();

      const res = await uploadData({
        key: `loss-pressure-individual/${Date.now()}.png`,
        data: image,
      }).result;

      const response = await DataStore.save(
        new LossPressure({
          ...data,
          ...body,
          chartImage: res.key,
          userID: user.id,
        })
      );
      let text = "";
      let icon = "success";

      if (
        parseFloat(response?.pressAirShotPpsi) >
        parseFloat(response?.maxPlasticPressure)
      ) {
        icon = "warning";
        text +=
          "<li>La Presión de Disparo al Aire Excede la máxima presión de la máquina.</li>";
      }

      if (
        parseFloat(response?.pressToRunnerPpsi) >
        parseFloat(response?.maxPlasticPressure)
      ) {
        icon = "warning";
        text +=
          "<li>La Presión al Corredor Excede la máxima presión de la máquina.</li>";
      }

      if (
        parseFloat(response?.pressToGatePpsi) >
        parseFloat(response?.maxPlasticPressure)
      ) {
        icon = "warning";
        text +=
          "<li>La Presión a la Compuerta Excede la máxima presión de la máquina.</li>";
      }

      if (
        parseFloat(response?.pressToPostGatePpsi) >
        parseFloat(response?.maxPlasticPressure)
      ) {
        icon = "warning";
        text +=
          "<li>La Presión a la Entrada de Cavidad Excede la máxima presión de la máquina.</li>";
      }

      if (
        parseFloat(response?.pressTo1stStageEndPpsi) >
        parseFloat(response?.maxPlasticPressure)
      ) {
        icon = "warning";
        text +=
          "<li>La Presión al Final de la Primera Etapa Excede la máxima presión de la máquina.</li>";
      }

      setResult(response);
      conditionalsAlert(icon, text);
    } catch (error) {
      const e = error.message ? error.message : error;
      showErrorAlert(e);
    }
    setIsLoading(false);
  };

  return {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    lineChartDataPressureLoss,
    chartRef,
  };
};
