import { ppsiToPbar } from "../../../hooks/useUomTransform";
import { badgeColor } from "../../../../project-overview/components/utilities/variables";

export const dataResults = (data) => [
  {
    label: "Uso de Maquina",
    value: data?.lossPressPreform,
    type: "percentaje",
    addOn: "%",
    badgeColor: badgeColor,
  },
  {
    label: "Máxima Presión Plástica",
    value: data?.maxPlasticPressure,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
    badgeColor: badgeColor,
  },
  {
    label: "Presión de Disparo al Aire",
    value: data?.pressAirShotPpsi,
    addOn: ppsiToPbar(data?.header?.uom),
    type: "pressure",
    badgeColor:
      parseFloat(data?.pressAirShotPpsi) < parseFloat(data?.maxPlasticPressure)
        ? "green"
        : "red",
  },
  {
    label: "Barril a Nariz",
    value: data?.pressLostBarrelToNozzle,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
    badgeColor: badgeColor,
  },
  {
    label: "Presión al Corredor",
    value: data?.pressToRunnerPpsi,
    addOn: ppsiToPbar(data?.header?.uom),
    type: "pressure",
    badgeColor:
      parseFloat(data?.pressToRunnerPpsi) < parseFloat(data?.maxPlasticPressure)
        ? "green"
        : "red",
  },
  {
    label: "Nariz a Corredor",
    value: data?.pressLostNozzleToRunner,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
    badgeColor: badgeColor,
  },
  {
    label: "Presión a la Compuerta",
    value: data?.pressToGatePpsi,
    addOn: ppsiToPbar(data?.header?.uom),
    type: "pressure",
    badgeColor:
      parseFloat(data?.pressToGatePpsi) < parseFloat(data?.maxPlasticPressure)
        ? "green"
        : "red",
  },
  {
    label: "Corredor a Compuerta",
    value: data?.pressLostRunnerToGate,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
    badgeColor: badgeColor,
  },
  {
    label: "Presión Entrada de Cavidad",
    value: data?.pressToPostGatePpsi,
    addOn: ppsiToPbar(data?.header?.uom),
    type: "pressure",
    badgeColor:
      parseFloat(data?.pressToPostGatePpsi) <
      parseFloat(data?.maxPlasticPressure)
        ? "green"
        : "red",
  },
  {
    label: "Compuerta a Entrada",
    value: data?.pressLostGateToPostGate,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
    badgeColor: badgeColor,
  },
  {
    label: "Presión al Final de la Primera Etapa",
    value: data?.pressTo1stStageEndPpsi,
    addOn: ppsiToPbar(data?.header?.uom),
    type: "pressure",
    badgeColor:
      parseFloat(data?.pressTo1stStageEndPpsi) <
      parseFloat(data?.maxPlasticPressure)
        ? "green"
        : "red",
  },
  {
    label: "Entrada a Fin de Primera Etapa",
    value: data?.pressLostPostGateToEnd,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
    badgeColor: badgeColor,
  },
];
