import { useContext, useState } from "react";
import { initialResinStateRadio } from "../utilities/variables";
import { DataStore } from "aws-amplify/datastore";
import { Resin } from "../../../../models";
import UserContext from "../../../../contexts/UserContext";

export const resinHooks = () => {
  const [resin, setResine] = useState(initialResinStateRadio);
  const { user } = useContext(UserContext);

  const onChangeHandler = (e) => {
    if (e.target.type === "number") {
      const val = parseFloat(e.target.value);
      setResine({ ...resin, [e.target.name]: val });
    } else {
      setResine({ ...resin, [e.target.name]: e.target.value });
    }
  };

  const parseFinalState = async (too, values) => {
    let finalState = {
      ...too,
      ...values,
    };

    let {
      resinCost,
      meltTempMin,
      meltTempMax,
      nozzleTempMin,
      nozzleTempMax,
      FrontTempMin,
      FrontTempMax,
      midTempMin,
      midTempMax,
      backTempMin,
      backTempMax,
      toolAsideTempMin,
      toolAsideTempMax,
      toolBsideTempMin,
      toolBsideTempMax,
      dryTempMin,
      dryTempMax,
      dryTimeMin,
      dryTimeMax,
      dewPoint,
      maxMoisture,
      specificWeight,
      backPressureMax,
      backPressureMin,
      rpmMax,
      rpmMin,
      resinInjectionPressureMax,
      barrelOcupationMin,
      barrelOcupationMax,
      tonnageFactorMin,
      tonnageFactorMax,
      cushionPositionMin,
      cushionPositionMax,
      ventLong,
      ventWidth,
      reliefDeep,
      regrindPercentageMax,
      maximumShrinkage,
      minimumPackingPressure,
      releaseTemperature,
      minimumScrewLDRatio,
      maxScrewLDRatio,
      thermalDiffusion,
      minimumVentDepth,
      demoldingTemperature,
    } = finalState;

    resinCost = parseFloat(resinCost);
    demoldingTemperature = parseFloat(demoldingTemperature);
    thermalDiffusion = parseFloat(thermalDiffusion);
    minimumVentDepth = parseFloat(minimumVentDepth);
    maxScrewLDRatio = parseFloat(maxScrewLDRatio);
    releaseTemperature = parseFloat(releaseTemperature);
    minimumScrewLDRatio = parseFloat(minimumScrewLDRatio);
    minimumPackingPressure = parseFloat(minimumPackingPressure);
    maximumShrinkage = parseFloat(maximumShrinkage);
    meltTempMin = parseFloat(meltTempMin);
    meltTempMax = parseFloat(meltTempMax);
    nozzleTempMin = parseFloat(nozzleTempMin);
    nozzleTempMax = parseFloat(nozzleTempMax);
    FrontTempMin = parseFloat(FrontTempMin);
    FrontTempMax = parseFloat(FrontTempMax);
    midTempMin = parseFloat(midTempMin);
    midTempMax = parseFloat(midTempMax);
    backTempMin = parseFloat(backTempMin);
    backTempMax = parseFloat(backTempMax);
    toolAsideTempMin = parseFloat(toolAsideTempMin);
    toolAsideTempMax = parseFloat(toolAsideTempMax);
    toolBsideTempMin = parseFloat(toolBsideTempMin);
    toolBsideTempMax = parseFloat(toolBsideTempMax);
    dryTempMin = parseFloat(dryTempMin);
    dryTempMax = parseFloat(dryTempMax);
    dryTimeMin = parseFloat(dryTimeMin);
    dryTimeMax = parseFloat(dryTimeMax);
    dewPoint = parseFloat(dewPoint);
    maxMoisture = parseFloat(maxMoisture);
    specificWeight = parseFloat(specificWeight);
    backPressureMax = parseFloat(backPressureMax);
    backPressureMin = parseFloat(backPressureMin);
    rpmMax = parseFloat(rpmMax);
    rpmMin = parseFloat(rpmMin);
    resinInjectionPressureMax = parseFloat(resinInjectionPressureMax);
    barrelOcupationMin = parseFloat(barrelOcupationMin);
    barrelOcupationMax = parseFloat(barrelOcupationMax);
    tonnageFactorMin = parseFloat(tonnageFactorMin);
    tonnageFactorMax = parseFloat(tonnageFactorMax);
    cushionPositionMin = parseFloat(cushionPositionMin);
    cushionPositionMax = parseFloat(cushionPositionMax);
    ventLong = parseFloat(ventLong);
    ventWidth = parseFloat(ventWidth);
    reliefDeep = parseFloat(reliefDeep);
    regrindPercentageMax = parseFloat(regrindPercentageMax);

    finalState = {
      ...finalState,
      resinCost,
      thermalDiffusion,
      demoldingTemperature,
      minimumVentDepth,
      maxScrewLDRatio,
      minimumScrewLDRatio,
      releaseTemperature,
      minimumPackingPressure,
      maximumShrinkage,
      meltTempMin,
      meltTempMax,
      nozzleTempMin,
      nozzleTempMax,
      FrontTempMin,
      FrontTempMax,
      midTempMin,
      midTempMax,
      backTempMin,
      backTempMax,
      toolAsideTempMin,
      toolAsideTempMax,
      toolBsideTempMin,
      toolBsideTempMax,
      dryTempMin,
      dryTempMax,
      dryTimeMin,
      dryTimeMax,
      dewPoint,
      maxMoisture,
      specificWeight,
      backPressureMax,
      backPressureMin,
      rpmMax,
      rpmMin,
      resinInjectionPressureMax,
      barrelOcupationMin,
      barrelOcupationMax,
      tonnageFactorMin,
      tonnageFactorMax,
      cushionPositionMin,
      cushionPositionMax,
      ventLong,
      ventWidth,
      reliefDeep,
      regrindPercentageMax,
      userID: user.id
    };

    return finalState;
  };

  const onSubmitHandler = async (finalState) => {
    return await DataStore.save(new Resin(finalState));
  };

  return {
    parseFinalState,
    resin,
    onChangeHandler,
    setResine,
    onSubmitHandler,
  };
};
