import { useContext, useState } from "react";
import ProjectContext from "../../contexts/ProjectContext";
import { Project } from "../../models";
import { post } from "aws-amplify/api";
import { DataStore } from "aws-amplify/datastore";
import ConditionalsAlert from "../../views/projects/hooks/ConditionalsAlert";

export const useHooperCapacityHooks = () => {
  const { project, tool, machine, updateLoading, updateProject } = useContext(
    ProjectContext
  );
  const [showData, setShowData] = useState(false);
  const onSubmitHandler = async () => {
    updateLoading(true);
    const {
      uom,
      shotWeight100,
      resinFlow_Oz_x_Min,
      reqResinForProduction,
    } = project;
    const { hopperCapacity } = machine;
    const { numberOfCavities } = tool;

    const restOperation = post({
      apiName: "hooperCapacity",
      path: "/hooper-capacity",
      options: {
        body: {
          uom,
          numberOfCavities,
          hopperCapacity,
          shotWeight100,
          resinFlow_Oz_x_Min,
          reqResinForProduction,
        },
      },
    });

    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.hopperCapacityPcs = res.hopperCapacityPcs.toString();
        updated.consumeLb_x_hr = res.consumeLb_x_hr.toString();
        updated.reloadTime = res.reloadTime.toString();
        updated.reloadSize = res.reloadSize.toString();
        updated.resinTroughHopper = res.resinTroughHopper.toString();
        updated.reloadReqAfterStart = res.reloadReqAfterStart.toString();
        updated.phase = "4";
      })
    )
      .then((res) => {
        let icon = "success";
        let text = "";
        if (res.reloadTime > 60) {
          icon = "warning";
          text =
            "<li><b>El Tiempo de Recarga de Tolva es muy elevado,</b> es probable que la Unidad de Inyección no pueda ser abastecida correctamente</li>";
        } else if (res.reloadTime < 10) {
          icon = "warning";
          text =
            "<li><b>El Tiempo de Recarga de Tolva es muy corto,</b> es probable que la Unidad de Inyección no pueda ser abastecida correctamente. </li>";
        }

        ConditionalsAlert(icon, text);
        updateProject(res);
        setShowData(true);
      })
      .catch((err) => console.log(err))
      .finally(() => updateLoading(false));
  };

  return {
    showData,
    onSubmitHandler,
  };
};
