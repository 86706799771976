import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { initialMachineState, paddingXinputs } from "./utilities/variables";
import { Field, Form, Formik } from "formik";
import {
  bar,
  inches,
  KG,
  LB,
  millimeters,
  NEWTON,
  psi,
  RELATION,
  SEC,
  SEG,
  TON,
} from "../../../variables/uomVariables";
import { machineHooks } from "./hooks/machineHooks";
import { validateForms } from "../../../variables/validateForms";
import { useHistory } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import useMachineCount from "./hooks/useMachineCount";
import CustomGrid from "../../projects/individual/components/CustomGrid";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionCreate,
  metaKeywordsCreate,
  metaTitleCreate,
} from "./utilities/metaVariables";

const CreateMachine = () => {
  const {
    onSubmitHandler,
    machine,
    setMachine,
    parseFinalState,
  } = machineHooks();
  const history = useHistory();

  const { machineCount, isLoading } = useMachineCount(); // Usar el hook aquí

  const { user } = useContext(UserContext);

  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  /*

  if (user && !user.isSubscribed) {
    if (machineCount >= 2) {
      return (
        <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
          <Text fontSize={"2xl"} fontWeight={"bold"}>Has Excedido el limite de creacion de maquinas para el plan gratuito (2).</Text>
          <Text fontSize={"xl"} fontWeight={"bold"}> Actualiza tu suscripcion para crear mas maquinas</Text>
          <Button color={"black"} onClick={() => history.push("/admin/profile/plans")}>Ver Planes</Button>
        </Grid>
      )
    }
  }
  if (user && user.isSubscribed){
    if (user.subscrptionType === "TECNICAS_PLASTICAS" || user.subscrptionType === "BASICA") {
      if (machineCount >= 5) {
        return (
          <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>Has Excedido el limite de creacion de maquinas para el plan {formatString(user.subscrptionType)} (5).</Text>
            <Text fontSize={"xl"} fontWeight={"bold"}> Actualiza tu suscripcion para crear mas maquinas</Text>
            <Button color={"black"} onClick={() => history.push("/admin/profile/plans")}>Ver Planes</Button>
          </Grid>
        )
      }
    }
  }

   */

  const { validateFloat, validateString } = validateForms();
  const bgColor = "white";
  return (
    <>
      <HelmetComponent
        keywords={metaKeywordsCreate}
        title={metaTitleCreate}
        description={metaDescriptionCreate}
      />
      <Grid mt={"180px"} color={"white"}>
        <Formik
          initialValues={initialMachineState}
          onSubmit={async (values, actions) => {
            const finalState = parseFinalState(machine, values);

            await onSubmitHandler(finalState)
              .then(() => {
                history.push("/admin/machine/index");
              })
              .catch((err) => {
                console.log(err);
              });

            actions.setSubmitting(false);
          }}
        >
          {(props) => (
            <Flex direction="column" mb="50px" justify="center" align="center">
              <Form
                style={{
                  width: "90%",
                  justifyContent: "justify-between",
                }}
              >
                <CustomGrid repeat={3} gap={6}>
                  <GridItem colSpan={3} mb={"50px"}>
                    <Heading>CREAR NUEVA MAQUINA</Heading>
                  </GridItem>
                  <FormControl
                    id={"uom"}
                    as="fieldset"
                    isRequired
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FormLabel as="legend" alignSelf={"center"}>
                      Unidad de medida
                    </FormLabel>
                    <RadioGroup
                      onChange={(e) => setMachine({ ...machine, uom: e })}
                      defaultValue="INGLES"
                      value={machine.uom}
                    >
                      <Flex justifyContent={"space-between"} mx={10}>
                        <Radio
                          bgColor={"white"}
                          value="INTERNACIONAL"
                          children={"Internacional"}
                        />
                        <Radio bgColor={"white"} value="INGLES" selected>
                          Ingles
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  </FormControl>
                  <Field name="machineId" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.machineId && form.touched.machineId
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="machineId">
                          ID de la maquina
                        </FormLabel>
                        <Input
                          bgColor={bgColor}
                          {...field}
                          id="machineId"
                          placeholder="ej. IMM-098"
                        />
                        <FormErrorMessage>
                          {form.errors.machineId}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="serialNumber" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.serialNumber && form.touched.serialNumber
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="serialNumber">
                          Numero de Serie
                        </FormLabel>
                        <Input
                          {...field}
                          bgColor={bgColor}
                          id="serialNumber"
                          placeholder="ej. 1010101010-CD1"
                        />
                        <FormErrorMessage>
                          {form.errors.serialNumber}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="brand" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.brand && form.touched.brand}
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="brand">Fabricante</FormLabel>
                        <Input
                          bgColor={bgColor}
                          {...field}
                          id="brand"
                          placeholder="ej. Fabricante"
                        />
                        <FormErrorMessage>{form.errors.brand}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="year" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.year && form.touched.year}
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="year">Año</FormLabel>
                        <Input
                          bgColor={bgColor}
                          {...field}
                          id="year"
                          placeholder="ej. 1990"
                        />
                        <FormErrorMessage>{form.errors.year}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="machineTonnage" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.machineTonnage &&
                          form.touched.machineTonnage
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="machineTonnage">
                          Fuerza de cierre
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="machineTonnage"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? `K${NEWTON}`
                                : TON
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.machineTonnage}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <FormControl
                    id={"machineType"}
                    as="fieldset"
                    mb={5}
                    isRequired
                    style={{ justifyContent: "center", alignItems: "center" }}
                    marginLeft={paddingXinputs}
                  >
                    <FormLabel as="legend">Tipo de la maquina</FormLabel>
                    <RadioGroup
                      onChange={(e) =>
                        setMachine({ ...machine, machineType: e })
                      }
                      defaultValue="VERTICAL"
                      value={machine.machineType}
                    >
                      <HStack spacing="18px">
                        <Radio bgColor={bgColor} value="VERTICAL">
                          Vertical
                        </Radio>
                        <Radio bgColor={bgColor} value="HORIZONTAL">
                          Horizontal
                        </Radio>
                        <Radio bgColor={bgColor} value="SHUTTLE">
                          Shuttle
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <Field name="pistonDiameter" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.pistonDiameter &&
                          form.touched.pistonDiameter
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="pistonDiameter">
                          Diametro del pistón
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="pistonDiameter"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.pistonDiameter}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="screwDiameter" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.screwDiameter &&
                          form.touched.screwDiameter
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="screwDiameter">
                          Diametro del Husillo
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="screwDiameter"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.screwDiameter}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maxHydraulicPressure" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxHydraulicPressure &&
                          form.touched.maxHydraulicPressure
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="maxHydraulicPressure">
                          Maxima Presión Hidraulica
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maxHydraulicPressure"
                            placeholder="ej. 0,000.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL" ? bar : psi
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxHydraulicPressure}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="hopperCapacity" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.hopperCapacity &&
                          form.touched.hopperCapacity
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="hopperCapacity">
                          Capacidad de la tolva
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="hopperCapacity"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={machine.uom === "INTERNACIONAL" ? KG : LB}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.hopperCapacity}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maxShotSize" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxShotSize && form.touched.maxShotSize
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="maxShotSize">
                          Máxima Longitud de Disparo
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maxShotSize"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxShotSize}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="LDratio" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.LDratio && form.touched.LDratio}
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="LDratio">
                          Relación L/D de Husillo
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="LDratio"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            children={RELATION}
                            color={"black"}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.LDratio}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="maxInjectionSpeed" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxInjectionSpeed &&
                          form.touched.maxInjectionSpeed
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="maxInjectionSpeed">
                          Máxima Velicidad de Inyección
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maxInjectionSpeed"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? `${millimeters} / ${SEG}`
                                : `${inches} / ${SEC}`
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxInjectionSpeed}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="machineMaxEjection" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.machineMaxEjection &&
                          form.touched.machineMaxEjection
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="machineMaxEjection">
                          Máxima Expulsión de la Máquina
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="machineMaxEjection"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.machineMaxEjection}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="tieBarVerticalDistance" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.tieBarVerticalDistance &&
                          form.touched.tieBarVerticalDistance
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="tieBarVerticalDistance">
                          Distancia Vertical entre Barras
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="tieBarVerticalDistance"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.tieBarVerticalDistance}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="tieBarHorizontalDistance"
                    validate={validateFloat}
                  >
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.tieBarHorizontalDistance &&
                          form.touched.tieBarHorizontalDistance
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="tieBarHorizontalDistance">
                          Distancia Horizontal entre Barras
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="tieBarHorizontalDistance"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.tieBarHorizontalDistance}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maxOpenClamp" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxOpenClamp && form.touched.maxOpenClamp
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="maxOpenClamp">
                          Máxima Apertura de Prensa
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maxOpenClamp"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxOpenClamp}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="minStackMoldSize" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.minStackMoldSize &&
                          form.touched.minStackMoldSize
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel htmlFor="minStackMoldSize">
                          Mínima Altura de Molde
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="minStackMoldSize"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.minStackMoldSize}
                        </FormErrorMessage>
                        <FormHelperText>(minStackMoldSize)</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maxStackMoldSize" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxStackMoldSize &&
                          form.touched.maxStackMoldSize
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel htmlFor="maxStackMoldSize">
                          Máxima Altura de Molde
                        </FormLabel>
                        <InputGroup>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maxStackMoldSize"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              machine.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxStackMoldSize}
                        </FormErrorMessage>
                        <FormHelperText>(maxStackMoldSize)</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <GridItem />
                  <FormControl
                    id={"machineHydraulicSystem"}
                    as="fieldset"
                    mb={5}
                    isRequired
                  >
                    <FormLabel as="legend">Sistema Hidraulico</FormLabel>
                    <RadioGroup
                      onChange={(e) =>
                        setMachine({ ...machine, machineHydraulicSystem: e })
                      }
                      defaultValue="SI"
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="SI">
                          Si
                        </Radio>
                        <Radio bgColor={bgColor} value="NO">
                          No
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    id={"machinPneumaticSystem"}
                    as="fieldset"
                    mb={5}
                    isRequired
                  >
                    <FormLabel as="legend">Sistema Neumatico</FormLabel>
                    <RadioGroup
                      onChange={(e) =>
                        setMachine({ ...machine, machinPneumaticSystem: e })
                      }
                      defaultValue="SI"
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="SI">
                          Si
                        </Radio>
                        <Radio bgColor={bgColor} value="NO">
                          No
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    id={"machineVacummSystem"}
                    as="fieldset"
                    mb={5}
                    isRequired
                  >
                    <FormLabel as="legend">Sistema Vacio</FormLabel>
                    <RadioGroup
                      onChange={(e) =>
                        setMachine({ ...machine, machineVacummSystem: e })
                      }
                      defaultValue="SI"
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="SI">
                          Si
                        </Radio>
                        <Radio bgColor={bgColor} value="NO">
                          No
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <Button
                    colorScheme="whatsapp"
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </Button>
                  <GridItem />
                  <Button
                    disabled
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                  >
                    Limpiar
                  </Button>
                </CustomGrid>
              </Form>
            </Flex>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default CreateMachine;
