import { useHistory, useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../hooks/useGetProjectByIdHooks";
import CardHeader from "../../../components/Card/CardHeader";
import { cardBodyStyles, cardHeaderStyles } from "../../../theme/styles";
import { Avatar, Button, Flex, Grid, Progress, Text } from "@chakra-ui/react";
import Card from "../../../components/Card/Card";
import HorizontalDivider from "../../../components/dividers/HorizontalDivider";
import CardBody from "../../../components/Card/CardBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileEdit } from "@fortawesome/free-solid-svg-icons";
import OverViewCard from "./components/Cards/OverViewCard";
import pressureLossImage from "./../../../assets/img/perdida_de_presion.png";
import intensificationRatioImage from "../../../assets/img/razon_Intensificacion.png";
import dryerSupplyImage from "../../../assets/img/abastecimiento_de_secador.png";
import viscosityImage from "../../../assets/img/Viscosidad.png";
import holdTimeImage from "../../../assets/img/Tiempo_Sostenimiento.png";
import windowProcess from "../../../assets/img/ventana_de_Proceso.png";
import hooperCapacityImage from "../../../assets/img/capacidad_de_Tolva.png";
import coolingTimeImage from "../../../assets/img/tiempo_Enfriamiento.png";
import shootSize from "../../../assets/img/tamaño_disparo.png";
import melt3030Image from "../../../assets/img/melt_30_30.png";
import sustainigPressureImage from "../../../assets/img/presion_Sostenimiento.png";
import vcheckRepeteability from "../../../assets/img/repetibilidad_v_check.png";
import cavityBalanceImage from "../../../assets/img/Balance_de_Cavidades.png";
import enduranceTimeImage from "../../../assets/img/tiempo_Residencia.png";
import projectedAreaImage from "../../../assets/img/area_Proyectada.png";
import linearityImage from "../../../assets/img/linealidad-1.png";
import bgImage from "../../../assets/img/bg-image-ia.png";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";

const ProjectOverView = () => {
  const { id } = useParams();
  const { project, updateProject } = useContext(ProjectContext);
  const history = useHistory();
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    getProject(id);
  }, [id]);

  return (
    <>
      <HelmetComponent
        keywords={metaKeywords}
        title={metaTitle}
        description={metaDescription}
      />
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card>
          <CardHeader {...cardHeaderStyles}>
            Proyecto {project?.projectName}
          </CardHeader>
          <HorizontalDivider mt={5} />
          <CardBody {...cardBodyStyles}>
            <Flex
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid
                justifyContent={"space-between"}
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 8fr)"
                gap={6}
              >
                <Card backgroundImage={bgImage} h={"300px"}>
                  <Flex
                    direction={"column"}
                    height="100%"
                    justifyContent="space-between"
                  >
                    <Flex justifyContent={"flex-start"}>
                      <CardHeader {...cardHeaderStyles} fontSize={"md"}>
                        Razón de Intensificación
                      </CardHeader>
                    </Flex>
                    <Flex
                      justifyContent={"center"}
                      alignItems={"center"}
                      mt={5}
                    >
                      <Avatar
                        src={intensificationRatioImage}
                        w="80px"
                        h="80px"
                      />
                    </Flex>
                    <Flex direction={"column"} justifyContent={"flex-end"}>
                      <Flex
                        mt={5}
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Text alignItems={"flex-end"}>COMPLETO</Text>
                        <Text alignItems={"flex-end"}>1 / 16</Text>
                      </Flex>
                      <Progress colorScheme="green" value={100} mt={2} />
                      <Flex
                        mx={5}
                        mt={5}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Button colorScheme={"yellow"}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Button colorScheme={"telegram"}>
                          <FontAwesomeIcon icon={faFileEdit} />
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
                <OverViewCard
                  logo={projectedAreaImage}
                  phase={project?.phase}
                  compare={"1"}
                  headerTitle={"Area Proyectada y Tonelaje"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/projected-area/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={dryerSupplyImage}
                  phase={project?.phase}
                  compare={"2"}
                  headerTitle={"Absatecimiento de Secador"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/dryer-supply/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={hooperCapacityImage}
                  phase={project?.phase}
                  compare={"3"}
                  headerTitle={"Capacidad de Tolva"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/hooper-capacity/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
              </Grid>
              <HorizontalDivider mt={5} />
              <Grid
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 6fr)"
                gap={6}
              >
                <OverViewCard
                  logo={melt3030Image}
                  phase={project?.phase}
                  compare={"4"}
                  headerTitle={"Melt 30/30"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/melt-3030/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={shootSize}
                  phase={project?.phase}
                  compare={"5"}
                  headerTitle={"Tamaño de Disparo"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/shot-size/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={pressureLossImage}
                  phase={project?.phase}
                  compare={"6"}
                  headerTitle={"Perdida de Presión"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/pressure-loss/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={vcheckRepeteability}
                  phase={project?.phase}
                  compare={"7"}
                  headerTitle={"Repetibilidad de V-Check"}
                  onEditClick={() =>
                    history.push(
                      `/admin/edit-project/vcheck-repeatability/${id}`
                    )
                  }
                  onEyeClick={() => console.log("eye")}
                />
              </Grid>
              <HorizontalDivider mt={5} />
              <Grid
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 1fr)"
                gap={6}
              >
                <OverViewCard
                  logo={viscosityImage}
                  phase={project?.phase}
                  compare={"8"}
                  headerTitle={"Estudio de Viscosidad"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/viscosity-study/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={linearityImage}
                  phase={project?.phase}
                  compare={"9"}
                  headerTitle={"Estudio de Linealidad"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/linearity-study/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={cavityBalanceImage}
                  phase={project?.phase}
                  compare={"10"}
                  headerTitle={"Balance de Cavidades"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/cavity-balance/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={sustainigPressureImage}
                  phase={project?.phase}
                  compare={"11"}
                  headerTitle={"Presión de Sostenimiento"}
                  onEditClick={() =>
                    history.push(
                      `/admin/edit-project/sustaining-pressure/${id}`
                    )
                  }
                  onEyeClick={() => console.log("eye")}
                />
              </Grid>
              <HorizontalDivider mt={5} />
              <Grid
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 1fr)"
                gap={6}
              >
                <OverViewCard
                  logo={holdTimeImage}
                  phase={project?.phase}
                  compare={"12"}
                  headerTitle={"Sellado de Compuerta"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/gate-sealing/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={coolingTimeImage}
                  phase={project?.phase}
                  compare={"13"}
                  headerTitle={"Tiempo de Enfriamiento"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/cooling-time/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={enduranceTimeImage}
                  phase={project?.phase}
                  compare={"14"}
                  headerTitle={"Tiempo de Residencia"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/endurance-time/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={windowProcess}
                  phase={project?.phase}
                  compare={"15"}
                  headerTitle={"Ventana de Proceso"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/window-process/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
              </Grid>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default ProjectOverView;
