import { headerImage } from "./utilities";
import React, { useState } from "react";
import { Flex, Grid, GridItem, Image, Link, Text } from "@chakra-ui/react";
import Temario from "./Temario";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import YouTube from "react-youtube";
import { googleMapURL } from "../../../variables/general";
import CollaboratorsMap from "../../../components/collaborators/Map";
import { lat, lan } from "./utilities";
import { post } from "aws-amplify/api";
import { useHistory } from "react-router-dom";
import TecnicasContact from "./TecnicasContact";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionTecnicas,
  metaKeywordsTecnicas,
  metaTitleTecnicas,
} from "../utilities/metaVariables";

const TecnicasPlasticas = () => {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const sendEmail = async () => {
    try {
      const restOperation = post({
        path: "/send-email-to-collaborators",
        apiName: "sendEmailCollaborators",
        options: {
          body: {
            email,
            message,
            name,
            company,
            destination: "elfenokierefiesta@gmail.com",
          },
        },
      });
      const response = await restOperation.response;
      console.log("GET call succeeded: ", response);
    } catch (error) {
      console.error("Error al enviar correo:", error);
    }
  };
  return (
    <>
      <HelmetComponent
        title={metaTitleTecnicas}
        keywords={metaKeywordsTecnicas}
        description={metaDescriptionTecnicas}
      />
      <Flex
        direction="column"
        pt={{ sm: "125px", lg: "125px" }}
        width={isBannerPath ? "80%" : "auto"} // Aquí aplicamos el ancho condicionalmente
        mx={isBannerPath ? "auto" : "0"}
      >
        <Grid
          borderWidth={1}
          borderRadius={15}
          mx={5}
          justifyContent={"center"}
          p={5}
          backgroundColor={"#f6e666"}
          opacity={0.9}
        >
          <Image
            width={"300px"}
            borderRadius={15}
            src={headerImage}
            alt={"Tecnicas Plasticas"}
          />
        </Grid>
        <Grid
          gap={6}
          justifyContent={"center"}
          mt={5}
          borderWidth={1}
          borderRadius={15}
          mx={5}
          p={5}
          backgroundColor={"white"}
          color={"black"}
        >
          <Text fontWeight={"bold"} fontSize={"xl"}>
            Ing. Manuel Ordóñez Ordúñez
          </Text>
          <Grid templateColumns={"repeat(4, 1fr)"}>
            <Link
              style={{ paddingRight: 15 }}
              href="https://www.facebook.com/TECNICASPLASTICAS?mibextid=LQQJ4d"
              isExternal
            >
              <FontAwesomeIcon
                color={"#1877f2"}
                icon={faFacebook}
                size={"2xl"}
              />{" "}
            </Link>
            <Link
              color={"#cd486b"}
              style={{ paddingLeft: 15, paddingRight: 15 }}
              href="https://www.instagram.com/tecnicasplasticas/"
              isExternal
            >
              <FontAwesomeIcon icon={faInstagram} size={"2xl"} />{" "}
            </Link>
            <Link
              color={"#0e76a8"}
              style={{ paddingLeft: 15, paddingRight: 15 }}
              href="https://www.linkedin.com/in/manuelordonezordunez"
              isExternal
            >
              <FontAwesomeIcon icon={faLinkedin} size={"2xl"} />{" "}
            </Link>
            <Link
              color={"#c4302b"}
              style={{ paddingLeft: 15 }}
              href="https://www.youtube.com/@Tecnicasplasticas"
              isExternal
            >
              <FontAwesomeIcon icon={faYoutube} size={"2xl"} />{" "}
            </Link>
          </Grid>
        </Grid>
        <Grid
          borderWidth={1}
          borderRadius={15}
          mx={5}
          justifyContent={"center"}
          p={5}
          backgroundColor={"white"}
          opacity={0.9}
          mt={5}
        >
          <Text>
            Técnicas Plásticas es una empresa de consultoría dedicada al
            asesoramiento y asistencia en todos los procesos y niveles de su
            empresa.
          </Text>

          <Text>
            Sin importar el tamaño de su empresa, nosotros le ofrecemos asesoría
            en medidas correctivas a corto plazo hasta el desarrollo de
            proyectos internacionales a largo plazo.
          </Text>
          <Text>
            Planificación, gestión de procesos, desarrollo empresarial o
            capacitación: le proporcionamos todo el apoyo y el asesoramiento
            necesarios, además de una atención personalizada en todas sus
            consultas y dudas empresariales.
          </Text>
          <Text>
            Agenda entrenamiento o asesoría con nosotros, entra al enlace del
            curso y completa el formulario.
          </Text>
          <Text>
            Revisa nuestras Fechas Disponibles y obtén descuentos por contrato
            anticipado!!
          </Text>
          <Text>
            Conozca más sobre los servicios que ofrecemos en{" "}
            <Link
              isExternal={true}
              href={"http://www.mistecnicasplasticas.com.mx"}
            >
              https://www.mistecnicasplasticas.com.mx
            </Link>
            /.
          </Text>
        </Grid>

        <Grid
          mt={5}
          borderWidth={1}
          borderRadius={15}
          mx={5}
          p={5}
          backgroundColor={"white"}
          color={"black"}
        >
          <Temario />
        </Grid>
        <Grid
          mx={5}
          borderWidth={1}
          borderRadius={15}
          gap={6}
          opacity={0.9}
          mt={5}
          backgroundColor={"#f6e666"}
          p={5}
        >
          <Grid justifyContent={"center"}>
            <Text color={"black"} fontWeight={"bold"} fontSize={"4xl"}>
              Visitanos en nuestro Canal de Youtube
            </Text>
          </Grid>
          <Grid justifyContent={"center"}>
            <FontAwesomeIcon
              icon={faYoutube}
              beatFade
              color={"#c4302b"}
              size={"2xl"}
            />
          </Grid>
          <Grid justifyContent={"center"}>
            <Text color={"black"} fontWeight={"bold"} fontSize={"xl"}>
              Aquí encontrarás consejos, capacitaciones y Master Class de
              distintos temas relacionados al Mundo del Plástico. Siguenos y
              compartenos tus comentarios.
            </Text>
          </Grid>
          <Grid gap={6} mt={5} justifyContent={"center"}>
            <Grid>
              <YouTube videoId="0z3KeKDbHSU" />
            </Grid>
            <Grid>
              <YouTube videoId="9txHCR5b7fM" />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          mx={5}
          mt={5}
          borderRadius={15}
          p={5}
          backgroundColor={"gray.200"}
          opacity={0.9}
          templateColumns="repeat(2, 1fr)"
          gap={6}
        >
          <CollaboratorsMap
            lat={lat}
            lan={lan}
            marker={true}
            zoom={12}
            isMarkerShown={false}
            googleMapURL={googleMapURL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400` }} />}
            mapElement={<div style={{ height: `100%`, width: "100%" }} />}
          />
          <Grid justifyContent={"center"}>
            <Text fontWeight={"bold"} fontSize={"4xl"} color={"black"}>
              Conocenos
            </Text>
            <Text fontWeight={"bold"} fontSize={"xl"} color={"black"}>
              Horario de Atención
            </Text>
            <Text fontSize={"md"} color={"black"}>
              Lunes - Viernes
            </Text>
            <Text fontSize={"md"} color={"black"}>
              8:00 am - 5:00 pm
            </Text>
            <GridItem />
            <Text fontWeight={"bold"} fontSize={"xl"} color={"black"}>
              Contacto:
            </Text>
            <a href="mailto:manuel@mistecnicasplasticas.com.mx">
              manuel@mistecnicasplasticas.com.mx
            </a>
            <Link href={"https://wa.me/525517772822"} isExternal>
              WhatsApp +52 551 777 2822
            </Link>
            <Link href={"http://www.mistecnicasplasticas.com.mx/"} isExternal>
              http://www.mistecnicasplasticas.com.mx/
            </Link>
          </Grid>
        </Grid>
        <TecnicasContact collaborator={"TCPL"} />
      </Flex>
    </>
  );
};

export default TecnicasPlasticas;
