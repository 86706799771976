import { Button, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

const GoBackButton = () => {
  const history = useHistory();
  return (
    <Button colorScheme={"facebook"} onClick={() => history.goBack()}>
      <Text>
        <FontAwesomeIcon icon={faChevronCircleLeft} pr={5} /> Regresar
      </Text>
    </Button>
  );
};

export default GoBackButton;
