export const metaTitleAsaClean =
  "Asaclean® Líder en Compuestos de Purga para la Industria Plástica | MVPS | Asaclean";
export const metaDescriptionAsaClean =
  "AsaClean ofrece soluciones avanzadas en compuestos de purga para optimizar la eficiencia y productividad de los procesos de producción plástica. Descubre cómo AsaClean puede mejorar tu operación.";

export const metaKeywordsClean =
  "Asaclean, purga, purgante, extrusora, reduccion de scrap, purgas para plasticos, material para purga de inyeccion, compuestos de purga, purgas para plásticos,asaclean mexico, asaclean sun plastech, eficiencia en producción plástica, soluciones de purga, AsaClean productos, limpieza de inyectoras, mvps";

export const metaTitleGea =
  "Innovación en Manejo de Materiales y Tratamiento de Agua con GEA | MVPS";
export const metaDescriptionGea =
  "Descubre la vanguardia en tecnologías sostenibles para el manejo de materiales y tratamiento de agua con GEA. Explora nuestros productos revolucionarios como Einar® 201, Eco One® y AquaSonic®, diseñados para mejorar la eficiencia, productividad y sostenibilidad en tus procesos industriales. Conoce cómo podemos ayudarte a lograr operaciones más ecológicas y rentables.";

export const metaKeywordsGea =
  "GEA, tratamiento de agua, manejo de materiales, tecnología verde, Einar 201, Eco One, AquaSonic, sostenibilidad industrial, innovación en procesos, mvps";

export const metaTitleGintec =
  "Soluciones de Ingeniería y Fabricación Avanzada con GINTEC | MVPS";
export const metaDescriptionGintec =
  "Explora las soluciones personalizadas de GINTEC en ingeniería y fabricación para la industria Aeroespacial, Automotriz, Médica y Energética. Descubre cómo nuestro enfoque en el diseño, fabricación, instalación y puesta a punto puede transformar tus operaciones, garantizando seguridad, calidad y satisfacción. Conoce más sobre nuestro equipo avanzado y experiencia en Hermosillo, Sonora.";

export const metaKeywordsGintec =
  "GINTEC, soluciones de ingeniería, fabricación avanzada, industria aeroespacial, automotriz, médica, energética, diseño personalizado, fabricación e instalación, Hermosillo Sonora, mvps";

export const metaTitleLdPlasticSolutions =
  "LD Plastic Solutions: Innovación en Fabricación de Moldes de Inyección | MVPS";
export const metaDescriptionLdPlasticSolutions =
  "Descubre cómo LD Plastic Solutions está redefiniendo la fabricación de moldes de inyección de plástico a nivel mundial. Con presencia en América, Europa y Asia, nos especializamos en automoción, moldes de alta precisión y moldes 2k, garantizando la mejor calidad y servicio. Conoce nuestras instalaciones en Querétaro y cómo podemos impulsar el desarrollo de tus proyectos.";

export const metaKeywordsLdPlasticSolutions =
  "LD Plastic Solutions, fabricantes de moldes de inyección, moldes de alta precisión, moldes 2k, fabricación de moldes, industria automotriz, Querétaro, mvps";

export const metaTitleRediasa =
  "Rediasa Piab: Soluciones Avanzadas de Automatización y EOAT | MVPS";
export const metaDescriptionRediasa =
  "Descubre Rediasa, el distribuidor autorizado de PIAB® en México, especializado en automatización y herramientas de fin de brazo de robot (EOAT). Ofrecemos soluciones integrales en diseño y suministro de componentes para la industria automotriz, industrial y electrónica. Explora nuestra gama de adaptadores, sujetadores y componentes para optimizar tus sistemas de producción.";

export const metaKeywordsRediasa =
  "Rediassa, rediasa, soluciones de automatización, EOAT, distribuidor PIAB, industria automotriz, electrónica, adaptadores de robot, sujetadores industriales, componentes de automatización, piab, mvps";

export const metaTitleShelter =
  "Somos Shelter: Soluciones Creativas e Innovadoras de Marketing | MVPS";
export const metaDescriptionShelter =
  "Descubre cómo Somos Shelter, una agencia de marketing líder, puede transformar tu negocio con soluciones creativas e innovadoras. Especializados en estrategias de marketing, publicidad y producción, nuestro equipo multidisciplinario te ayudará a establecerte en el mercado, crecer y alcanzar tus objetivos. Explora nuestras estrategias personalizadas y campañas impactantes para lograr resultados exitosos.";

export const metaKeywordsShelter =
  "Somos Shelter, soluciones de marketing, agencia de publicidad, estrategias creativas, producción de imagen, equipo multidisciplinario, campañas publicitarias, marketing innovador, mvps, shelter";

export const metaTitleTecnicas =
  "Capacitación y Consultoría en Procesos de Plásticos | Técnicas Plásticas | MVPS";
export const metaDescriptionTecnicas =
  "En Técnicas Plásticas, brindamos soluciones integrales en consultoría y capacitación para la industria del plástico, incluyendo arranque de procesos, mantenimiento de moldes, diseño y mucho más. Descubre cómo podemos mejorar tus procesos, incrementar la eficiencia y optimizar la calidad de tu producción con nuestros programas de formación especializada y asesoramiento experto.";

export const metaKeywordsTecnicas =
  "Técnicas Plásticas, capacitación en plásticos, consultoría industrial, mantenimiento de moldes, diseño de moldes, mejora de procesos, eficiencia de producción, formación en plásticos, mvps";
export const metaTitleIndex =
  "Colaboradores Expertos en Industria del Plástico y Más | MVPS";
export const metaDescriptionIndex =
  "Conoce a nuestros colaboradores expertos en la industria del plástico, moldeo, diseño y consultoría. Desde Técnicas Plásticas hasta Soluciones Digitales, cada uno aporta conocimientos especializados y servicios innovadores para impulsar tu negocio. Explora nuestro índice para encontrar el colaborador perfecto que se alinee con tus necesidades de capacitación, producción y estrategia.";

export const metaKeywordsIndex =
  "industria del plástico, colaboradores expertos, consultoría industrial, diseño de moldes, soluciones digitales, servicios de moldeo, capacitación especializada, índice de colaboradores, mvps";
