import {post} from "aws-amplify/api";
import {useState} from "react";

export const useGapHooks = (data, setData, setIsLoading) => {

  const [gapsErrors, setGapsErrors] = useState([])
  const addGap = () => {
    const newGaps = [
      ...data.gaps,
      { form: "", diameter: "", cavities: "", base: "", height: "" },
    ];
    setData({ ...data, gaps: newGaps });
  };

  const removeGaps = () => {
    const newGaps = data.gaps.slice(0, -1);
    setData({ ...data, gaps: newGaps });
  };

  const isLastGapComplete = () => {
    if (!data.gaps || data.gaps.length === 0) return false;

    const lastGap = data.gaps[data.gaps.length - 1];
    if (!lastGap.form) return false;

    if (lastGap.form === "CIRCULO") {
      return lastGap.diameter !== "" && lastGap.cavities !== "";
    }
    return (
      lastGap.base !== "" &&
      lastGap.height !== "" &&
      lastGap.cavities !== ""
    );
  };
  const finishGaps = async () => {
    setIsLoading(true);

    const restOperation = post({
      apiName: "projectedAreaNew",
      path: "/projected-area",
      options: {
        body: data.gaps,
      },
    });

    const rest = await restOperation.response;
    const body = await rest.body.json();
    const b = body.body;

    const { area } = b;
    setData({...data, gapArea: area.toString()})
    setIsLoading(false)
  };

  const handleInputGapChange = (index, field, value) => {
    const isValidFloat = (value) => /^\d*\.?\d*$/.test(value); // Permitir cadena vacía y números flotantes
    const isValidInteger = (value) => /^\d*$/.test(value); // Permitir cadena vacía y números enteros

    let isValid = true;
    if (field === "cavities") {
      isValid = isValidInteger(value);
    } else if (field !== "form") {
      isValid = isValidFloat(value);
    }

    if (isValid) {
      const updatedGaps = [...data.gaps];
      updatedGaps[index] = { ...updatedGaps[index], [field]: value };
      setData({ ...data, gaps: updatedGaps });
    }

    setGapsErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: isValid ? "" : "Valor no válido",
      };
      return updatedErrors;
    });
  };


  return {
    addGap,
    removeGaps,
    isLastGapComplete,
    finishGaps,
    handleInputGapChange,
    setGapsErrors,
    gapsErrors
  }
}