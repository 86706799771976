import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import React, { useEffect, useState } from "react";
import { initialState } from "../utilities/initialState";
import IndividualRunnerForm from "./forms/IndividualRunnerForm";
import { useRunnerHooks } from "../hooks/useRunnerHooks";
import IndividualCavityForm from "./forms/IndividualCavityForm";
import { useCavityHooks } from "../hooks/useCavityHooks";
import { useGapHooks } from "../hooks/useGapHooks";
import IndividualGapForm from "./forms/IndividualGapForm";
import { Button, Text, Grid } from "@chakra-ui/react";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import {
  isFloatString,
  isFloatStringPositive,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { textColor } from "../utilities/variabled";
import { DataStore } from "aws-amplify/datastore";
import { ProjectedAreaAndTonnage } from "../../../../../../models";
import { useAlert } from "../../../../../../contexts/useAlert";
import IndividualFooter from "../../../components/IndividualFooter";
import { badgeColor } from "../../../../project-overview/components/utilities/variables";
import { tonKn } from "../../../hooks/useUomTransform";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";

const ProjectedArea = ({ setIsDisabled }) => {
  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);

  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [showRunnerArea, setShowRunnerArea] = useState(false);
  const [showCavityArea, setShowCavityArea] = useState(false);
  const [showGapArea, setShowGapArea] = useState(false);
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
    userID,
  } = useHeader();
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  const isDisabled = !isObjectEmpty(result);

  const {
    addRunner,
    finishRunners,
    isLastRunnerComplete,
    removeRunner,
    handleInputRunnerChange,
    runnerErrors,
  } = useRunnerHooks(data, setData, setIsLoading);

  const {
    addCavity,
    finishCavities,
    isLastCavityComplete,
    removeCavity,
    handleInputCavityChange,
    cavityErrors,
  } = useCavityHooks(data, setData, setIsLoading);

  const {
    gapsErrors,
    handleInputGapChange,
    finishGaps,
    isLastGapComplete,
    removeGaps,
    addGap,
  } = useGapHooks(data, setData, setIsLoading);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const handleClick = async () => {
    // Asegurarse de que runnerArea y gapArea sean números (0 si son null o undefined)
    const adjustedRunnerArea = parseFloat(data.runnerArea) || 0;
    const adjustedGapArea = parseFloat(data.gapArea) || 0;

    const area =
      adjustedRunnerArea + parseFloat(data.cavityArea) - adjustedGapArea;

    setData({
      ...data,
      projectedArea: area.toString(),
      runnerArea: adjustedRunnerArea.toString(),
      gapArea: adjustedGapArea.toString(),
    });
    setShowData(true);
  };

  const onChangeData = (e) => {
    if (isFloatString(e.target.value)) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const isLastElementComplete = (elements, formType) => {
    if (!elements || elements.length === 0) return true;

    if (elements.length === 1 && isEmpty(elements[0])) return true;

    const lastElement = elements[elements.length - 1];
    if (!lastElement.form) return false;

    if (lastElement.form === formType) {
      return lastElement.diameter !== "" && lastElement.cavities !== "";
    }
    return (
      lastElement.base !== "" &&
      lastElement.height !== "" &&
      lastElement.cavities !== ""
    );
  };

  const isEmpty = (obj) => {
    return Object.values(obj).every((x) => x === "");
  };

  const isButtonDisabled = () => {
    const cavityAreaValid = data.cavityArea > 0;

    return (
      !cavityAreaValid ||
      !isLastElementComplete(data.gaps, "CIRCULO") ||
      !isLastElementComplete(data.runners, "CIRCULO") ||
      !isLastCavityComplete()
    );
  };

  const onSaveInsuranceFactor = async () => {
    setIsLoading(true);
    try {
      if (!header || !header.name) {
        setIsLoading(false);
        return;
      }
      const newMinTonSug =
        parseFloat(data.projectedArea) *
        parseFloat(data.minTonnageFactor) *
        (parseFloat(data.safeFactor) / 100 + 1);
      const newMaxTonSug =
        parseFloat(data.projectedArea) *
        parseFloat(data.maxTonnageFactor) *
        (parseFloat(data.safeFactor) / 100 + 1);
      const newNomTonSug =
        (parseFloat(newMinTonSug) + parseFloat(newMaxTonSug)) / 2;

      await setData({
        ...data,
        minSuggestedClampForce: newMinTonSug.toString(),
        maxSuggestedClampForce: newMaxTonSug.toString(),
        suggestedClampForce: newNomTonSug.toString(),
        header,
      });
      const response = await DataStore.save(
        new ProjectedAreaAndTonnage({
          ...data,
          userID,
          minSuggestedClampForce: newMinTonSug.toString(),
          maxSuggestedClampForce: newMaxTonSug.toString(),
          suggestedClampForce: newNomTonSug.toString(),
          header,
        })
      );
      let text = "";
      let icon = "success";
      if (
        parseInt(response.minSuggestedClampForce) >=
        parseInt(data.maxMachineClampForce)
      ) {
        text += `<li>El tonelaje de la Maquina (${data.maxMachineClampForce}), no es suficiente para el tonelaje mínimo sugerido</li>`;
        icon = "warning";
      }
      if (
        parseInt(response.suggestedClampForce) >=
        parseInt(data.maxMachineClampForce)
      ) {
        text += `<li>El tonelaje de la Maquina (${data.maxMachineClampForce}), no es suficiente para el tonelaje nominal sugerido</li>`;

        icon = "warning";
      }
      if (response.maxSuggestedClampForce >= data.maxMachineClampForce) {
        text += `<li>El tonelaje de la Maquina (${data.maxMachineClampForce}), no es suficiente para el tonelaje máximo sugerido</li>`;
        icon = "warning";
      }
      setResult(response);
      conditionalsAlert(icon, text);
    } catch (error) {
      showErrorAlert(error);
    }

    setIsLoading(false);
  };

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        {!showData && (
          <Grid templateColumns="repeat(3, 1fr)" gap={6} width={"100%"} mt={10}>
            <IndividualRunnerForm
              isLoading={isLoading}
              isLastRunnerComplete={isLastRunnerComplete}
              addRunner={addRunner}
              data={data}
              errors={runnerErrors}
              setShowRunnerArea={setShowRunnerArea}
              showRunnerArea={showRunnerArea}
              handleInputChange={handleInputRunnerChange}
              removeRunner={removeRunner}
              finishRunners={finishRunners}
            />
            <IndividualCavityForm
              data={data}
              isLoading={isLoading}
              errors={cavityErrors}
              handleInputChange={handleInputCavityChange}
              addCavity={addCavity}
              isLastCavityComplete={isLastCavityComplete}
              finishCavities={finishCavities}
              removeCavity={removeCavity}
              setShowCavityArea={setShowCavityArea}
              showCavityArea={showCavityArea}
            />
            <IndividualGapForm
              data={data}
              handleInputChange={handleInputGapChange}
              errors={gapsErrors}
              isLoading={isLoading}
              addGap={addGap}
              finishGaps={finishGaps}
              isLastGapComplete={isLastGapComplete}
              removeGap={removeGaps}
              setShowGapArea={setShowGapArea}
              showGapArea={showGapArea}
            />
          </Grid>
        )}
        <Grid mt={10} width={"100%"}>
          <Button
            width={"100%"}
            colorScheme={"facebook"}
            disabled={isButtonDisabled() || isDisabled}
            onClick={showData ? () => setShowData(false) : handleClick}
          >
            {showData ? "Editar" : "Continuar"}
          </Button>
        </Grid>
        {showData && (
          <>
            <Grid mt={5}>
              <DataDisplayBadge
                badgeColorScheme={badgeColor}
                color={"gray.600"}
                rightAddon={header.uom === "INGLES" ? "in²" : "mm²"}
                value={data.projectedArea}
                title={"Área Proyectada"}
              />
            </Grid>
            <Grid templateColumns="repeat(4, 1fr)" gap={10} mt={5}>
              <InputCustomFieldProjectRigthAddon
                borderColor={"gray.400"}
                color={"gray.600"}
                label={"Factor de tonelaje Mínimo"}
                id={"minTonnageFactor"}
                addOn={uom === "INGLES" ? "Ton/in²" : "kN/mm²"}
                value={data?.minTonnageFactor}
                onChange={(e) => onChangeData(e)}
                disabled={isDisabled}
              />
              <InputCustomFieldProjectRigthAddon
                borderColor={"gray.400"}
                color={"gray.600"}
                label={"Factor de tonelaje Máximo"}
                id={"maxTonnageFactor"}
                addOn={uom === "INGLES" ? "Ton/in²" : "kN/mm²"}
                value={data?.maxTonnageFactor}
                onChange={(e) => onChangeData(e)}
                disabled={isDisabled}
              />
              <InputCustomFieldProjectRigthAddon
                borderColor={"gray.400"}
                color={"gray.600"}
                label={"Máximo Tonelaje de Máquina"}
                id={"maxMachineClampForce"}
                addOn={uom === "INGLES" ? "Ton" : "kN"}
                value={data?.maxMachineClampForce}
                onChange={(e) => onChangeData(e)}
                disabled={isDisabled}
              />
              <InputCustomFieldProjectRigthAddon
                borderColor={"gray.400"}
                id={"safeFactor"}
                color={textColor}
                value={data.safeFactor}
                onChange={(e) => onChangeData(e)}
                type={data.safeFactor}
                addOn={"%"}
                label={"Factor de Aseguramiento"}
                disabled={isDisabled}
              />
            </Grid>
            {parseInt(data?.maxTonnageFactor) <
              parseInt(data?.minTonnageFactor) && (
              <Text color={"red.600"}>
                El Factor de tonelaje Máximo no puede ser menor al Factor de
                tonelaje Mínimo.
              </Text>
            )}

            <Grid gap={10} mt={5} width={"100%"}>
              <Button
                width={"100%"}
                colorScheme={"facebook"}
                isLoading={isLoading}
                onClick={() => onSaveInsuranceFactor()}
                disabled={
                  !isFloatString(data.safeFactor) ||
                  !data.safeFactor ||
                  !data.minTonnageFactor ||
                  !data.maxTonnageFactor ||
                  !data.maxMachineClampForce ||
                  isDisabled ||
                  parseInt(data?.maxTonnageFactor) <
                    parseInt(data?.minTonnageFactor) ||
                  data?.maxMachineClampForce <= 0
                }
                mt={5}
              >
                Calcular y Guardar
              </Button>
            </Grid>
          </>
        )}
        {showData &&
          data?.minSuggestedClampForce &&
          data?.suggestedClampForce &&
          data?.maxSuggestedClampForce && (
            <>
              <Grid templateColumns="repeat(3, 1fr)" gap={10} my={10}>
                <DataDisplayBadge
                  color={textColor}
                  title={"Tonelaje Mínimo Sugerido"}
                  value={data.minSuggestedClampForce}
                  rightAddon={tonKn(header?.uom)}
                  badgeColorScheme={
                    data.minSuggestedClampForce < data.maxMachineClampForce
                      ? "green"
                      : "red"
                  }
                />
                <DataDisplayBadge
                  color={textColor}
                  title={"Tonelaje Nominal Sugerido"}
                  value={data.suggestedClampForce}
                  rightAddon={tonKn(header?.uom)}
                  badgeColorScheme={
                    data.suggestedClampForce < data.maxMachineClampForce
                      ? "green"
                      : "red"
                  }
                />
                <DataDisplayBadge
                  color={textColor}
                  title={"Tonelaje Máximo Sugerido"}
                  value={data.maxSuggestedClampForce}
                  rightAddon={tonKn(header?.uom)}
                  badgeColorScheme={
                    data.maxSuggestedClampForce < data.maxMachineClampForce
                      ? "green"
                      : "red"
                  }
                />
              </Grid>
              {!isObjectEmpty(result) && (
                <IndividualFooter
                  url={"projected-area"}
                  result={result}
                  isLoading={isLoading}
                  onSubmitHandler={onSaveInsuranceFactor}
                  disabled={
                    !isFloatString(data.safeFactor) ||
                    !data.safeFactor ||
                    !data.minTonnageFactor ||
                    !data.maxTonnageFactor ||
                    !data.maxMachineClampForce
                  }
                />
              )}
            </>
          )}
      </>
    );
  } else {
    return <></>;
  }
};

export default ProjectedArea;
