import { useContext, useState } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";

export const useWindowProcessHooks = () => {
  const [showData, setShowData] = useState(false);
  const { project, resin, updateLoading, updateProject } = useContext(
    ProjectContext
  );
  const {
    weight_Limit,
    inj_Press_Required_Limit,
    fill_Time_Limit,
    cycle_Time_Limit,
    cushion_Limit,
    recovery_Time_Limit,
    inj_Press_Required,
    speedSelected,
    tool_B_Side_Real_Temp,
    realMeltTemp,
    cooling_Time_Select,
    total_Cycle_Time_Real,
    cushionPositionTheoric,
    recovery_Time,
    max_Total_Weight_Obtained,
    doeTable,
    min_Pack_Selection,
    max_Pack_Selection,
    best_Hold_Press_Select,
    maxGateSealingData,
  } = project;

  const { chemicalStructure } = resin;
  const onSubmitHandler = async () => {
    updateLoading(true);
    const data = {
      min_Pack_Selection,
      max_Pack_Selection,
      inyectionTime: speedSelected?.inyectionTime,
      weight_Limit,
      maxGateSealingData,
      inj_Press_Required_Limit,
      fill_Time_Limit,
      cycle_Time_Limit,
      cushion_Limit,
      recovery_Time_Limit,
      inj_Press_Required,
      chemicalStructure,
      tool_B_Side_Real_Temp,
      realMeltTemp,
      cooling_Time_Select,
      total_Cycle_Time_Real,
      cushionPositionTheoric,
      recovery_Time,
      max_Total_Weight_Obtained,
      doeTable,
      best_Hold_Press_Select,
    };

    const restOperation = post({
      apiName: "windowProcess",
      path: "/window-process",
      options: {
        body: data,
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.nom_Hold_Press_Ppsi = res.nom_Hold_Press_Ppsi.toString();
        updated.temperature_Range = res.temperature_Range.toString();
        updated.min_Window_Temp = res.min_Window_Temp.toString();
        updated.nom_Window_Temp = res.nom_Window_Temp.toString();
        updated.max_Window_Temp = res.max_Window_Temp.toString();
        updated.inj_Press_Required_Low_Limit = res.inj_Press_Required_Low_Limit.toString();
        updated.inj_Press_Required_High_Limit = res.inj_Press_Required_High_Limit.toString();
        updated.fill_Time_Low_Limit = res.fill_Time_Low_Limit.toString();
        updated.fill_Time_High_Limit = res.fill_Time_High_Limit.toString();
        updated.cycle_Time_Low_Limit = res.cycle_Time_Low_Limit.toString();
        updated.cycle_Time_High_Limit = res.cycle_Time_High_Limit.toString();
        updated.cushion_Low_Limit = res.cushion_Low_Limit.toString();
        updated.cushion_High_Limit = res.cushion_High_Limit.toString();
        updated.recovery_Time_Low_limit = res.recovery_Time_Low_limit.toString();
        updated.recovery_Time_Hig_limit = res.recovery_Time_Hig_limit.toString();
        updated.weight_Low_Limit = res.weight_Low_Limit.toString();
        updated.weight_High_Limit = res.weight_High_Limit.toString();
        updated.randomOrder = res.randomOrder;
        updated.inj_Press_Required_Limit = inj_Press_Required_Limit.toString();
        updated.fill_Time_Limit = fill_Time_Limit.toString();
        updated.cycle_Time_Limit = cycle_Time_Limit.toString();
        updated.cushion_Limit = cushion_Limit.toString();
        updated.recovery_Time_Limit = recovery_Time_Limit.toString();
        updated.weight_Limit = weight_Limit.toString();
        updated.min_Hold_Press_Ppsi = min_Pack_Selection.toString();
        updated.max_Hold_Press_Ppsi = max_Pack_Selection.toString();
        updated.doeTable = res.doeTable;
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowData(true);
        updateLoading(false);
      });
  };

  const handleInputChangeDoe = (index, field, value) => {
    const updatedItem = JSON.parse(JSON.stringify(doeTable[index]));
    updatedItem[field] = value;

    const updatedTable = [...doeTable];
    updatedTable[index] = updatedItem;

    updateProject({ ...project, doeTable: updatedTable });
  };

  return {
    onSubmitHandler,
    showData,
    setShowData,
    handleInputChangeDoe,
  };
};
