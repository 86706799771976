import { Grid, Image, Text } from "@chakra-ui/react";
import {
  faClipboardCheck,
  faGear,
  faHammer,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import gintecImg from "../../../../assets/img/collaborators/gintec/gintec-img.jpg";
import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const slowSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const slowFade = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const slowBounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

const SlowSpinningIcon = styled(FontAwesomeIcon)`
  animation: ${slowSpin} 10s infinite linear;
`;

const SlowFadeIcon = styled(FontAwesomeIcon)`
  animation: ${slowFade} 3s infinite;
`;

const SlowBounceIcon = styled(FontAwesomeIcon)`
  animation: ${slowBounce} 2s infinite;
`;
const GintecPresentacion = ({ textColor }) => {
  return (
    <Grid
      justifyContent={"center"}
      mt={5}
      mx={5}
      p={5}
      borderWidth={1}
      borderRadius={15}
      opacity={0.9}
      backgroundColor={"white"}
    >
      <Text
        as={"h1"}
        borderRadius={15}
        shadow={"dark-lg"}
        p={5}
        color={textColor}
        fontSize={"md"}
        fontWeight={"extrabold"}
      >
        Soluciones de Ingeniería y Fabricación Avanzada con GINTEC
      </Text>

      <Text
        borderRadius={15}
        shadow={"dark-lg"}
        p={5}
        color={textColor}
        fontSize={"md"}
        fontWeight={"extrabold"}
      >
        G-Intec | Desarrollamos proyectos para la industria de Manufactura, según las
        necesidades y especificaciones de nuestros clientes.
      </Text>
      <Grid
        mt={10}
        justifyItems={"center"}
        templateColumns="repeat(4, 1fr)"
        gap={3}
      >
        <Grid justifyContent={"center"} justifyItems={"center"}>
          <Text fontSize={"xl"} color={textColor} fontWeight={"bold"}>
            Diseño de la idea
          </Text>
          <SlowFadeIcon
            size={"8x"}
            icon={faLightbulb}
            fade
            color={textColor}
            style={{ "--fa-primary-color": "#63E6BE" }}
          />
        </Grid>

        <Grid justifyContent={"center"} justifyItems={"center"}>
          <SlowSpinningIcon icon={faGear} color={"#008848"} spin size={"8x"} />
          <Text fontSize={"xl"} color={"#008848"} fontWeight={"bold"}>
            Fabricación
          </Text>
        </Grid>
        <Grid justifyContent={"center"}>
          <Text fontSize={"xl"} color={textColor} fontWeight={"bold"}>
            Instalación
          </Text>
          <SlowFadeIcon
            size={"8x"}
            icon={faHammer}
            fade
            color={textColor}
            style={{ "--fa-primary-color": "#63E6BE" }}
            sx={{
              animationDuration: "1s", // Asegúrate de que este estilo se aplica correctamente
            }}
          />
        </Grid>
        <Grid justifyContent={"center"} justifyItems={"center"}>
          <SlowBounceIcon
            icon={faClipboardCheck}
            color={"#008848"}
            bounce
            size={"9x"}
          />
          <Text fontSize={"xl"} color={"#008848"} fontWeight={"bold"}>
            Puesta en marcha
          </Text>
        </Grid>
      </Grid>
      <Grid mt={5} p={5} gap={6}>
        <Text color={textColor} fontWeight={"bold"}>
          Nuestro proceso Desarrollo de Proyectos comprende diseño, fabricación,
          instalación y puesta a punto; con seguimiento de normativas y
          políticas aplicables para garantizar la seguridad, calidad y
          satisfacción de nuestros clientes, con disponibilidad 24/7.
        </Text>
        <Text color={textColor} fontWeight={"bold"}>
          Estamos ubicados en Hermosillo, Sonora y contamos con amplia
          experiencia con empresas de la región, desarrollando proyectos para la
          industria Aeroespacial, Automotriz, Medica y Energética.
        </Text>
      </Grid>
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={6}
        justifyItems={"center"}
        justifyContent={"center"}
        p={5}
        ml={5}
      >
        <Grid>
          <Text color={textColor} fontWeight={"bold"} mb={5}>
            Equipo:
          </Text>
          <li style={{ color: textColor, paddingLeft: 20 }}>
            CNC Matsuura 20" x 14"
          </li>
          <li style={{ color: textColor, paddingLeft: 20 }}>
            CNC Matsuura 40" x 20"
          </li>
          <li style={{ color: textColor, paddingLeft: 20 }}>
            Torno CNC Nakamura 10" x 18"
          </li>
          <li style={{ color: textColor, paddingLeft: 20 }}>
            Torno Uso General 14" x 40"
          </li>
          <li style={{ color: textColor, paddingLeft: 20 }}>
            Rectificadora 6" x 12"
          </li>
          <li style={{ color: textColor, paddingLeft: 20 }}>
            Equipos de Cortes Varios
          </li>
        </Grid>
        <Grid>
          <Image src={gintecImg} borderRadius={15} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GintecPresentacion;
