export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isFloatString = (str) => {
  if (str === "") return true; // Permite cadena vacía como válida
  if (str === null || str === undefined) return false;

  const regex = /^-?\d*\.?\d*$/; // Permite dígitos opcionales antes y después del punto decimal
  return regex.test(str);
};

export const isFloatStringPositive = (str) => {
  if (str === "") return true; // La cadena vacía ya no es válida
  if (str === null || str === undefined) return false;

  const regex = /^\d*\.?\d*$/;  // Solo permite dígitos positivos con al menos un dígito antes o después del punto decimal
  return regex.test(str);
};

export const validateMinMax = (str, min, max) => {
  if (str === "") return true; // Permite cadena vacía como válida
  if (str === null || str === undefined) return false;

  const regex = /^-?\d*\.?\d*$/; // Permite dígitos opcionales antes y después del punto decimal
  if (parseFloat(str) > parseFloat(max)) return false
  return regex.test(str);
}

export const areAllFloats = (values) => {
  return values.every(isFloatString);
};
