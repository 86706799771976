import { useEffect, useState } from "react";
import { geaInitialState } from "../gea-tool/variables";
import { validateHeaderField } from "../../utilities/utilitiesHeaderTool";
import { post } from "aws-amplify/api";
import { useSetGeaPoints } from "../gea-tool/useSetGeaPoints";

export const useGeaHooks = () => {
  const [geaDataInput, setGeaDataInput] = useState(geaInitialState);
  const [errors, setErrors] = useState({});
  const [id, setId] = useState("");
  const [geaPoints, setGeaPointsCount] = useState();

  const {
    geaRegrindPercent,
    geaOtherAdditives,
    geaEinarPercent,
    geaVirginResin,
    geaPartWeight,
    geaVirginPartWeight,
    geaSampleSize,
    geaRegrindResin,
    geaAdditivePartWeight,
    geaRegrindPartWeight,
    geaEinarPartWeight,
    geaVirginLotWeight,
    geaRegrindLotWeight,
    geaAdditiveLotWeight,
    geaEinarLotWeight,
    geaRearBefore,
    geaRearAfter,
    geaCenterBefore,
    geaCenterAfter,
    geaScrapAfter,
    geaScrapBefore,
    geaFrontBefore,
    geaFrontAfter,
    geaDownTimeAfter,
    geaDownTimeBefore,
    geaNozzleBefore,
    geaKwCostAfter,
    kwCost,
    geaKwCostBefore,
    geaNozzleAfter,
    geaShotSizeBefore,
    geaShotSizeAfter,
    geaTransferBefore,
    geaTransferAfter,
    geaRpmBefore,
    geaRpmAfter,
    geaBackpressBefore,
    geaBackpressAfter,
    geaInjSpeedBefore,
    geaInjSpeedAfter,
    geaInjPressBefore,
    geaInjPressAfter,
    geaTempMeltBefore,
    geaTempMeltAfter,
    geaCushionBefore,
    geaCushionAfter,
    geaMaxPressBefore,
    geaMaxPressAfter,
    geaRecoveryTimeBefore,
    geaRecoveryTimeAfter,
    geaFillTimeBefore,
    geaFillTimeAfter,
    geaCycleTimeBefore,
    geaCycleTimeAfter,
    geaRpmAmpBefore,
    geaRpmAmpAfter,
    geaPartCostDelta,
    geaProdTimeDelta,
    geaMoldReleaseQtyDelta,
    geaDownTimeDelta,
    geaScrapDelta,
    geaKwCostDelta,
    geaCavities,
    geaMoldReleaseQtyAfter,
    geaMoldReleaseQtyBefore,
    MoldReleaseCost,
    geaInjectionAmpBefore,
    geaInjectionAmpAfter,
    geaShotWeightBefore,
    geaShotWeightAfter,
    geaMoldReleaseBefore,
    geaMoldReleaseAfter,
    geaAntiDustBefore,
    geaAntiDustAfter,
    geaAntiStaticBefore,
    geaAntiStaticAfter,
    geaDenestingBefore,
    geaDenestingAfter,
    geaUdM,
    geaProdTimeAfter,
    geaProdTimeBefore,
    ProdHourCost,
    currency,
    exchange,
    geaRegrindPartWeight_2,
    geaVirginResinCost,
    geaRegrindResinCost,
    geaOtherAdditivesCost,
    geaEinarPercentCost,
    geaPartCostEinar,
    geaPartCost,
  } = geaDataInput;

  useEffect(() => {
    const po = useSetGeaPoints(geaDataInput);
    setGeaPointsCount(po);
  }, [geaDataInput]);

  useEffect(() => {

    if (geaRegrindPercent !== "" && geaOtherAdditives !== "" && geaEinarPercent !== "") {
      let data = "";
      if (geaRegrindPercent === "0") {
        data = parseFloat((data = 100 - geaOtherAdditives - geaEinarPercent));
      } else {
        data = parseFloat(100 - geaRegrindPercent - geaOtherAdditives);
      }

      setGeaDataInput((prevState) => ({
        ...prevState,
        geaVirginResin: data.toString(),
      }));
    }
    if (geaRegrindPercent !== "" && geaEinarPercent !== "") {
      let data = "";
      if (geaRegrindPercent === "0") {
        data = 0.0;
      } else {
        data = parseFloat(geaRegrindPercent - geaEinarPercent);
      }
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRegrindResin: data.toString(),
      }));
    }
  }, [geaRegrindPercent, geaOtherAdditives, geaEinarPercent]);

  useEffect(() => {

    if (geaVirginResin !== "" && geaPartWeight !== "") {
      const data = parseFloat(
        (parseFloat(geaVirginResin) * parseFloat(geaPartWeight)) / 100
      );
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaVirginPartWeight: data.toString(),
      }));
    }
  }, [geaVirginResin, geaPartWeight]);

  useEffect(() => {
    if (geaVirginPartWeight !== "" && geaSampleSize !== "") {
      const data = parseFloat(
        parseFloat(geaVirginPartWeight) * parseFloat(geaSampleSize)
      );
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaVirginLotWeight: data.toString(),
      }));
    }
  }, [geaVirginPartWeight, geaSampleSize]);

  useEffect(() => {
    if (geaRegrindResin !== "" && geaPartWeight !== "") {
      const data = parseFloat(
        (parseFloat(geaRegrindResin) * parseFloat(geaPartWeight)) / 100
      );
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRegrindPartWeight: data.toString(),
      }));
    }
  }, [geaRegrindResin, geaPartWeight]);

  useEffect(() => {
    if (geaRegrindPartWeight !== "" && geaSampleSize !== "") {
      const data = parseFloat(
        parseFloat(geaRegrindPartWeight) * parseFloat(geaSampleSize)
      );
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRegrindLotWeight: data.toString(),
      }));
    }

  }, [geaRegrindPartWeight, geaSampleSize]);

  useEffect(() => {
    if (geaPartWeight !== "" && geaOtherAdditives !== "") {
      const data = parseFloat(
        (parseFloat(geaOtherAdditives) * parseFloat(geaPartWeight)) / 100
      );
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaAdditivePartWeight: data.toString(),
      }));
    }
  }, [geaOtherAdditives, geaPartWeight]);

  useEffect(() => {
    if (geaAdditivePartWeight !== "" && geaSampleSize !== "") {
      const data =
        parseFloat(geaAdditivePartWeight) * parseFloat(geaSampleSize);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaAdditiveLotWeight: data.toString(),
      }));
    }
  }, [geaAdditivePartWeight, geaSampleSize]);

  useEffect(() => {
    if (geaEinarPercent !== "" && geaPartWeight !== "") {
      const data =
        (parseFloat(geaEinarPercent) * parseFloat(geaPartWeight)) / 100;
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaEinarPartWeight: data.toString(),
      }));
    }

  }, [geaEinarPercent, geaPartWeight]);

  useEffect(() => {
    if (geaEinarPartWeight !== "" && geaSampleSize !== "") {
      const data = parseFloat(geaEinarPartWeight) * parseFloat(geaSampleSize);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaEinarLotWeight: data,
        costPerKg: data,
      }));
    }
  }, [geaEinarPartWeight, geaSampleSize]);

  useEffect(() => {
    if (
      geaVirginResin !== "" &&
      geaRegrindResin !== "" &&
      geaEinarPercent !== "" &&
      geaOtherAdditives !== ""
    ) {
      const data =
        parseFloat(geaVirginResin) +
        parseFloat(geaRegrindResin) +
        parseFloat(geaEinarPercent) +
        parseFloat(geaOtherAdditives);
      setGeaDataInput((prevState) => ({
        ...prevState,
        total: data.toString(),
      }));
    }
  }, [geaVirginResin, geaRegrindResin, geaEinarPercent, geaOtherAdditives]);

  useEffect(() => {
    if (
      geaVirginPartWeight !== "" &&
      geaRegrindPartWeight !== "" &&
      geaAdditivePartWeight !== "" &&
      geaEinarPartWeight !== ""
    ) {
      const data =
        parseFloat(geaVirginPartWeight) +
        parseFloat(geaRegrindPartWeight) +
        parseFloat(geaAdditivePartWeight) +
        parseFloat(geaEinarPartWeight);
      setGeaDataInput((prevState) => ({
        ...prevState,
        totalPerPiece: data.toString(),
      }));
    }
  }, [
    geaVirginPartWeight,
    geaRegrindPartWeight,
    geaAdditivePartWeight,
    geaEinarPartWeight,
  ]);

  useEffect(() => {
    if (
      geaVirginLotWeight !== "" &&
      geaRegrindLotWeight !== "" &&
      geaAdditiveLotWeight !== "" &&
      geaEinarLotWeight !== ""
    ) {
      const data =
        parseFloat(geaVirginLotWeight) +
        parseFloat(geaRegrindLotWeight) +
        parseFloat(geaAdditiveLotWeight) +
        parseFloat(geaEinarLotWeight);
      setGeaDataInput((prevState) => ({
        ...prevState,
        totalPerLote: data.toString(),
      }));
    }
  }, [
    geaVirginLotWeight,
    geaRegrindLotWeight,
    geaAdditiveLotWeight,
    geaEinarLotWeight,
  ]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    const errorMsg = validateHeaderField(id, value);
    setErrors((prevErrors) => ({ ...prevErrors, [id]: errorMsg }));
    setGeaDataInput((prevState) => ({ ...prevState, [id]: value }));
  };

  const onChangeSelect = (id, value) => {
    setGeaDataInput((prevState) => ({ ...prevState, [id]: value }));
  }

  useEffect(() => {
    if (geaRearBefore !== "" && geaRearAfter !== "") {
      const data = parseFloat(geaRearAfter) - parseFloat(geaRearBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRearDelta: data.toString(),
      }));
    }
  }, [geaRearBefore, geaRearAfter]);

  useEffect(() => {
    if (geaCenterBefore !== "" && geaCenterAfter !== "") {
      const data = parseFloat(geaCenterAfter) - parseFloat(geaCenterBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaCenterDelta: data.toString(),
      }));
    }
  }, [geaCenterBefore, geaCenterAfter]);

  useEffect(() => {
    if (geaFrontBefore !== "" && geaFrontAfter !== "") {
      const data = parseFloat(geaFrontAfter) - parseFloat(geaFrontBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaFrontDelta: data.toString(),
      }));
    }
  }, [geaFrontBefore, geaFrontAfter]);

  useEffect(() => {
    if (geaNozzleBefore !== "" && geaNozzleAfter !== "") {
      const data = parseFloat(geaNozzleAfter) - parseFloat(geaNozzleBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaNozzleDelta: data.toString(),
      }));
    }
  }, [geaNozzleBefore, geaNozzleAfter]);

  useEffect(() => {
    if (geaShotSizeBefore !== "" && geaShotSizeAfter !== "") {
      const data = parseFloat(geaShotSizeAfter) - parseFloat(geaShotSizeBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaShotSizeDelta: data.toString(),
      }));
    }
  }, [geaShotSizeBefore, geaShotSizeAfter]);

  useEffect(() => {
    if (geaTransferBefore !== "" && geaTransferAfter !== "") {
      const data = parseFloat(geaTransferAfter) - parseFloat(geaTransferBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaTransferDelta: data.toString(),
      }));
    }
  }, [geaTransferBefore, geaTransferAfter]);

  useEffect(() => {
    if (geaRpmBefore !== "" && geaRpmAfter !== "") {
      const data = parseFloat(geaRpmAfter) - parseFloat(geaRpmBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRpmDelta: data.toString(),
      }));
    }
  }, [geaRpmBefore, geaRpmAfter]);

  useEffect(() => {
    if (geaBackpressBefore !== "" && geaBackpressAfter !== "") {
      const data =
        parseFloat(geaBackpressAfter) - parseFloat(geaBackpressBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaBackpressDelta: data.toString(),
      }));
    }
  }, [geaBackpressBefore, geaBackpressAfter]);

  useEffect(() => {
    if (geaInjSpeedBefore !== "" && geaInjSpeedAfter !== "") {
      const data = parseFloat(geaInjSpeedAfter) - parseFloat(geaInjSpeedBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaInjSpeedDelta: data.toString(),
      }));
    }
  }, [geaInjSpeedBefore, geaInjSpeedAfter]);

  useEffect(() => {
    if (geaInjPressBefore !== "" && geaInjPressAfter !== "") {
      const data = parseFloat(geaInjPressAfter) - parseFloat(geaInjPressBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaInjPressDelta: data.toString(),
      }));
    }
  }, [geaInjPressBefore, geaInjPressAfter]);

  useEffect(() => {
    if (geaTempMeltBefore !== "" && geaTempMeltAfter !== "") {
      const data = parseFloat(geaTempMeltAfter) - parseFloat(geaTempMeltBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaTempMeltDelta: data.toString(),
      }));
    }
  }, [geaTempMeltBefore, geaTempMeltAfter]);

  useEffect(() => {
    if (geaCushionBefore !== "" && geaCushionAfter !== "") {
      const data = parseFloat(geaCushionAfter) - parseFloat(geaCushionBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaCushionDelta: data.toString(),
      }));
    }
  }, [geaCushionBefore, geaCushionAfter]);

  useEffect(() => {
    if (geaMaxPressBefore !== "" && geaMaxPressAfter !== "") {
      const data = parseFloat(geaMaxPressAfter) - parseFloat(geaMaxPressBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaMaxPressDelta: data.toString(),
      }));
    }
  }, [geaMaxPressBefore, geaMaxPressAfter]);

  useEffect(() => {
    if (geaRecoveryTimeBefore !== "" && geaRecoveryTimeAfter !== "") {
      const data =
        parseFloat(geaRecoveryTimeAfter) - parseFloat(geaRecoveryTimeBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRecoveryTimeDelta: data.toString(),
      }));
    }
  }, [geaRecoveryTimeBefore, geaRecoveryTimeAfter]);

  useEffect(() => {
    if (geaFillTimeBefore !== "" && geaFillTimeAfter !== "") {
      const data = parseFloat(geaFillTimeAfter) - parseFloat(geaFillTimeBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaFillTimeDelta: data.toString(),
      }));
    }
  }, [geaFillTimeBefore, geaFillTimeAfter]);

  useEffect(() => {
    if (geaRpmAmpBefore !== "" && geaRpmAmpAfter !== "") {
      const data = parseFloat(geaRpmAmpAfter) - parseFloat(geaRpmAmpBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRpmAmpDelta: data.toString(),
      }));
    }
  }, [geaRpmAmpBefore, geaRpmAmpAfter]);

  useEffect(() => {
    if (geaInjectionAmpBefore !== "" && geaInjectionAmpAfter !== "") {
      const data =
        parseFloat(geaInjectionAmpAfter) - parseFloat(geaInjectionAmpBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaInjectionAmpDelta: data.toString(),
      }));
    }
  }, [geaInjectionAmpBefore, geaInjectionAmpAfter]);

  useEffect(() => {
    if (geaShotWeightBefore !== "" && geaShotWeightAfter !== "") {
      const data =
        parseFloat(geaShotWeightAfter) - parseFloat(geaShotWeightBefore);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaShotWeightDelta: data.toString(),
      }));
    }
  }, [geaShotWeightBefore, geaShotWeightAfter]);

  useEffect(() => {
    if (geaMoldReleaseBefore !== "" && geaMoldReleaseAfter !== "") {
      const after =
        geaMoldReleaseAfter === "BUENA"
          ? 1
          : geaMoldReleaseAfter === "REGULAR"
          ? 0
          : -1;
      const before =
        geaMoldReleaseBefore === "BUENA"
          ? 1
          : geaMoldReleaseBefore === "REGULAR"
          ? 0
          : -1;
      let data = "";
      if (before < after) {
        data = "Mejorado";
      } else {
        data = "Sin Mejora";
      }
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaMoldReleaseDelta: data,
      }));
    }
  }, [geaMoldReleaseBefore, geaMoldReleaseAfter]);

  useEffect(() => {
    if (geaAntiDustBefore !== "" && geaAntiDustAfter !== "") {
      const after =
        geaAntiDustAfter === "BUENA"
          ? 1
          : geaAntiDustAfter === "REGULAR"
          ? 0
          : -1;
      const before =
        geaAntiDustBefore === "BUENA"
          ? 1
          : geaAntiDustBefore === "REGULAR"
          ? 0
          : -1;
      let data = "";
      if (before < after) {
        data = "Mejorado";
      } else {
        data = "Sin Mejora";
      }
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaAntiDustDelta: data,
      }));
    }
  }, [geaAntiDustBefore, geaAntiDustAfter]);

  useEffect(() => {
    if (geaAntiStaticBefore !== "" && geaAntiStaticAfter !== "") {
      const after =
        geaAntiStaticAfter === "BUENA"
          ? 1
          : geaAntiStaticAfter === "REGULAR"
          ? 0
          : -1;
      const before =
        geaAntiStaticBefore === "BUENA"
          ? 1
          : geaAntiStaticBefore === "REGULAR"
          ? 0
          : -1;
      let data = "";
      if (before < after) {
        data = "Mejorado";
      } else {
        data = "Sin Mejora";
      }
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaAntiStaticDelta: data,
      }));
    }
  }, [geaAntiStaticBefore, geaAntiStaticAfter]);

  useEffect(() => {
    if (geaDenestingBefore !== "" && geaDenestingAfter !== "") {
      const after =
        geaDenestingAfter === "BUENA"
          ? 1
          : geaDenestingAfter === "REGULAR"
          ? 0
          : -1;
      const before =
        geaDenestingBefore === "BUENA"
          ? 1
          : geaDenestingBefore === "REGULAR"
          ? 0
          : -1;
      let data = "";
      if (before < after) {
        data = "Mejorado";
      } else {
        data = "Sin Mejora";
      }
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaDenestingDelta: data,
      }));
    }
  }, [geaDenestingBefore, geaDenestingAfter]);

  useEffect(() => {
    if (geaRegrindPartWeight !== "" && geaEinarPartWeight !== "") {
      const data =
        parseFloat(geaRegrindPartWeight) + parseFloat(geaEinarPartWeight);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaRegrindPartWeight_2: data,
      }));
    }
  }, [geaRegrindPartWeight, geaEinarPartWeight]);

  useEffect(() => {
    if (geaUdM !== "" && currency !== "" && exchange !== "") {
      if (currency === "USD") {
        if (geaUdM === "Kgs") {
          setGeaDataInput((prevState) => ({
            ...prevState,
            geaEinarPercentCost: 7.5,
          }));
        } else {
          setGeaDataInput((prevState) => ({
            ...prevState,
            geaEinarPercentCost: 3.4,
          }));
        }
      } else {
        if (geaUdM === "Kgs") {
          setGeaDataInput((prevState) => ({
            ...prevState,
            geaEinarPercentCost: 7.5 * parseFloat(exchange),
          }));
        } else {
          setGeaDataInput((prevState) => ({
            ...prevState,
            geaEinarPercentCost: 3.4 * parseFloat(exchange),
          }));
        }
      }
    }
  }, [geaUdM, currency, exchange]);

  useEffect(() => {
    if (
      geaVirginResin !== "" &&
      geaRegrindResin !== "" &&
      geaOtherAdditives !== "" &&
      geaEinarPercent !== ""
    ) {
      const data =
        parseFloat(geaVirginResin) +
        parseFloat(geaRegrindResin) +
        parseFloat(geaOtherAdditives) +
        parseFloat(geaEinarPercent);

      setGeaDataInput((prevState) => ({ ...prevState, totalCost: data }));
    }
  }, [geaVirginResin, geaRegrindResin, geaOtherAdditives, geaEinarPercent]);

  useEffect(() => {
    if (
      geaVirginPartWeight !== "" &&
      geaRegrindPartWeight_2 !== "" &&
      geaAdditivePartWeight !== ""
    ) {
      const data =
        parseFloat(geaVirginPartWeight) +
        parseFloat(geaRegrindPartWeight_2) +
        parseFloat(geaAdditivePartWeight);
      setGeaDataInput((prevState) => ({
        ...prevState,
        totalCostWithOutEinar: data,
      }));
    }
  }, [geaVirginPartWeight, geaRegrindPartWeight_2, geaAdditivePartWeight]);

  useEffect(() => {
    if (
      geaVirginPartWeight !== "" &&
      geaRegrindPartWeight !== "" &&
      geaAdditivePartWeight !== "" &&
      geaEinarPartWeight !== ""
    ) {
      const data =
        parseFloat(geaVirginPartWeight) +
        parseFloat(geaRegrindPartWeight) +
        parseFloat(geaAdditivePartWeight) +
        parseFloat(geaEinarPartWeight);
      setGeaDataInput((prevState) => ({
        ...prevState,
        totalCostWithEinar: data,
      }));
    }
  }, [
    geaVirginPartWeight,
    geaRegrindPartWeight,
    geaAdditivePartWeight,
    geaEinarPartWeight,
  ]);

  useEffect(() => {
    if (
      geaVirginPartWeight !== "" &&
      geaVirginResinCost !== "" &&
      geaRegrindPartWeight_2 !== "" &&
      geaRegrindResinCost !== "" &&
      geaAdditivePartWeight !== "" &&
      geaOtherAdditivesCost !== ""
    ) {
      const data =
        parseFloat(geaVirginPartWeight) * parseFloat(geaVirginResinCost) +
        parseFloat(geaRegrindPartWeight_2) * parseFloat(geaRegrindResinCost) +
        parseFloat(geaAdditivePartWeight) * parseFloat(geaOtherAdditivesCost);
      setGeaDataInput((prevState) => ({ ...prevState, geaPartCost: data }));
    }
  }, [
    geaVirginPartWeight,
    geaVirginResinCost,
    geaRegrindPartWeight_2,
    geaRegrindResinCost,
    geaAdditivePartWeight,
    geaOtherAdditivesCost,
  ]);

  useEffect(() => {
    if (
      geaVirginPartWeight !== "" &&
      geaVirginResinCost !== "" &&
      geaRegrindPartWeight !== "" &&
      geaRegrindResinCost !== "" &&
      geaAdditivePartWeight !== "" &&
      geaOtherAdditivesCost !== "" &&
      geaEinarPartWeight !== "" &&
      geaEinarPercentCost !== ""
    ) {
      const data =
        parseFloat(geaVirginPartWeight) * parseFloat(geaVirginResinCost) +
        parseFloat(geaRegrindPartWeight) * parseFloat(geaRegrindResinCost) +
        parseFloat(geaAdditivePartWeight) * parseFloat(geaOtherAdditivesCost) +
        parseFloat(geaEinarPartWeight) * parseFloat(geaEinarPercentCost);
      setTimeout(() => { // Iniciar el retardo de 0.5 segundos

        setGeaDataInput((prevState) => ({
          ...prevState,
          geaPartCostEinar: data,
        }));
      }, 500);


    }
  }, [
    geaVirginPartWeight,
    geaVirginResinCost,
    geaRegrindPartWeight,
    geaRegrindResinCost,
    geaAdditivePartWeight,
    geaOtherAdditivesCost,
    geaEinarPartWeight,
    geaEinarPercentCost,
  ]);

  useEffect(() => {
    if (geaPartCostEinar !== "" && geaPartCost !== "" && geaSampleSize !== "") {
      const data =
        (parseFloat(geaPartCostEinar) - parseFloat(geaPartCost)) *
        parseFloat(geaSampleSize);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaPartCostDelta: data,
      }));
    }
  }, [geaPartCostEinar, geaPartCost, geaSampleSize]);

  useEffect(() => {
    if (geaProdTimeAfter !== "" && geaProdTimeBefore !== "" && ProdHourCost !== "") {
      const data =
        (parseFloat(geaProdTimeAfter) - parseFloat(geaProdTimeBefore)) *
        parseFloat(ProdHourCost);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaProdTimeDelta: data,
      }));
    }
  }, [geaProdTimeAfter, geaProdTimeBefore, ProdHourCost]);

  useEffect(() => {
    if (geaSampleSize !== "" && geaCycleTimeAfter !== "" && geaCavities !== "") {
      setTimeout(() => { // Iniciar el retardo de 0.5 segundos
        const data =
          (parseFloat(geaSampleSize) * parseFloat(geaCycleTimeAfter)) /
          parseFloat(geaCavities) /
          3600;
        setGeaDataInput((prevState) => ({
          ...prevState,
          geaProdTimeAfter: data,
        }));
      }, 500); // 500 milisegundos de retardo

    }
    if (geaSampleSize !== "" && geaCycleTimeBefore !== "" && geaCavities !== "") {
      const data =
        (parseFloat(geaSampleSize) * parseFloat(geaCycleTimeBefore)) /
        parseFloat(geaCavities) /
        3600;

      setGeaDataInput((prevState) => ({
        ...prevState,
        geaProdTimeBefore: data,
      }));


    }
  }, [geaSampleSize, geaCycleTimeAfter, geaCavities, geaCycleTimeBefore]);

  useEffect(() => {
    // =(geaMoldReleaseQtyAfter-geaMoldReleaseQtyBefore)*MoldReleaseCost
    if (geaMoldReleaseQtyAfter !== "" && geaMoldReleaseQtyBefore !== "" && MoldReleaseCost !== "") {
      const data =
        (parseFloat(geaMoldReleaseQtyAfter) -
          parseFloat(geaMoldReleaseQtyBefore)) *
        parseFloat(MoldReleaseCost);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaMoldReleaseQtyDelta: data,
      }));
    }
  }, [geaMoldReleaseQtyAfter, geaMoldReleaseQtyBefore, MoldReleaseCost]);

  useEffect(() => {
    // =((geaDownTimeAfter-geaDownTimeBefore)/60)*ProdHourCost
    if (geaDownTimeAfter !== "" && geaDownTimeBefore !== "" && ProdHourCost !== "") {
      const data =
        ((parseFloat(geaDownTimeAfter) - parseFloat(geaDownTimeBefore)) / 60) *
        parseFloat(ProdHourCost);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaDownTimeDelta: data,
      }));
    }
  }, [geaDownTimeAfter, geaDownTimeBefore, ProdHourCost]);

  useEffect(() => {
    // =(geaPartCostEinar*geaScrapAfter)-(geaPartCost*geaScrapBefore)
    if (geaPartCostEinar !== "" && geaScrapAfter !== "" && geaPartCost !== "" && geaScrapBefore !== "") {
      const data =
        parseFloat(geaPartCostEinar) * parseFloat(geaScrapAfter) -
        parseFloat(geaPartCost) * parseFloat(geaScrapBefore);
      setGeaDataInput((prevState) => ({ ...prevState, geaScrapDelta: data }));
    }
  }, [geaPartCostEinar, geaScrapAfter, geaPartCost, geaScrapBefore]);

  useEffect(() => {
    // =(geaKwCostAfter*geaProdTimeAfter*kwCost)-(geaKwCostBefore*geaProdTimeBefore*kwCost)
    if (
      geaKwCostAfter !== "" &&
      geaProdTimeAfter !== "" &&
      kwCost !== "" &&
      geaKwCostBefore !== "" &&
      geaProdTimeBefore !== ""
    ) {

      const data =
        parseFloat(geaKwCostAfter) *
          parseFloat(geaProdTimeAfter) *
          parseFloat(kwCost) -
        parseFloat(geaKwCostBefore) *
          parseFloat(geaProdTimeBefore) *
          parseFloat(kwCost);
      if (geaDataInput.geaKwCostDelta !== data) {
        setGeaDataInput((prevState) => ({ ...prevState, geaKwCostDelta: data }));
      }

    }
  }, [
    geaKwCostAfter,
    geaProdTimeAfter,
    kwCost,
    geaKwCostBefore,
    geaProdTimeBefore,
  ]);

  useEffect(() => {
    if (
      geaPartCostDelta !== "" &&
      geaProdTimeDelta !== "" &&
      geaMoldReleaseQtyDelta !== "" &&
      geaDownTimeDelta !== "" &&
      geaScrapDelta !== "" &&
      geaKwCostDelta !== ""
    ) {
      const data =
        parseFloat(geaPartCostDelta) +
        parseFloat(geaProdTimeDelta) +
        parseFloat(geaMoldReleaseQtyDelta) +
        parseFloat(geaDownTimeDelta) +
        parseFloat(geaScrapDelta) +
        parseFloat(geaKwCostDelta);
      if (geaDataInput.geaKwCostDelta !== data) {
        setGeaDataInput((prevState) => ({ ...prevState, geaTotalCostDelta: data }));
      }

    }
  }, [
    geaPartCostDelta,
    geaProdTimeDelta,
    geaMoldReleaseQtyDelta,
    geaDownTimeDelta,
    geaScrapDelta,
    geaKwCostDelta,
  ]);

  useEffect(() => {

    if (geaDataInput?.geaTotalCostDelta !== "" && geaDataInput?.geaSampleSize !== "") {
      setTimeout(() => { // Iniciar el retardo de 0.5 segundos
        const data =
          ((parseFloat(geaDataInput.geaTotalCostDelta) /
            parseFloat(geaDataInput.geaSampleSize)) * -1).toFixed(4)
        if (geaDataInput.pieceSaveCost !== data) {
          setGeaDataInput((prevState) => ({
            ...geaDataInput,
            pieceSaveCost: data,
          }));
        }
      }, 500);

    }
  }, [geaDataInput.geaTotalCostDelta, geaDataInput.geaSampleSize]);

  useEffect(() => {
    if (geaDataInput?.geaTotalCostDelta !== "") {


      setTimeout(() => { // Iniciar el retardo de 0.5 segundos
        const data =
          (
            parseFloat(geaDataInput.geaTotalCostDelta) * -1
          ).toFixed(4)
        if (geaDataInput.lotSaveCost !== data) {
          setGeaDataInput((prevState) => ({
            ...prevState,
            lotSaveCost: data,
          }));
        }

      }, 500); // 500 milisegundos de retardo
    }
  }, [geaDataInput.geaTotalCostDelta]);

  useEffect(() => {
    // geaSampleSize !== "" && geaCycleTimeAfter !== "" && geaCavities !== ""
    if (
      geaCycleTimeBefore !== "" &&
      geaCycleTimeAfter !== "" &&
      geaSampleSize !== ""
    ) {
      const data = (
        ((parseFloat(geaCycleTimeBefore) -
          parseFloat(geaCycleTimeAfter)) *
          parseFloat(geaSampleSize)) /
        3600
      ).toFixed(2);
      setGeaDataInput((prevState) => ({
        ...geaDataInput,
        prodTimeOptimization: data,
      }));
    }
    if (geaCycleTimeBefore !== "" && geaCycleTimeAfter !== "") {
      const data =
        parseFloat(geaCycleTimeAfter) - parseFloat(geaCycleTimeBefore);
      const data2 =
        parseFloat(geaCycleTimeBefore) -
        parseFloat(geaCycleTimeAfter);
      setGeaDataInput((prevState) => ({
        ...prevState,
        geaCycleTimeDelta: data.toString(),
        cycleTimeOptimization: data2,
      }));
    }
  }, [
    geaCycleTimeBefore,
    geaCycleTimeAfter,
    geaSampleSize,
  ]);

  useEffect(() => {
    if (
      geaDataInput?.geaEinarPercentCost !== "" &&
      geaDataInput?.geaEinarPartWeight !== "" &&
      geaDataInput?.geaSampleSize !== ""
    ) {
      const data = (
        parseFloat(geaDataInput.geaEinarPercentCost) *
        parseFloat(geaDataInput.geaEinarPartWeight) *
        parseFloat(geaDataInput.geaSampleSize)
      ).toFixed(2);
      setGeaDataInput((prevState) => ({
        ...geaDataInput,
        investmentRequired: data,
      }));
    }
  }, [
    geaDataInput.geaEinarPercentCost,
    geaDataInput.geaEinarPartWeight,
    geaDataInput.geaSampleSize,
  ]);

  useEffect(() => {
    if (
      geaDataInput?.geaTotalCostDelta !== "" &&
      geaDataInput?.geaEinarPercentCost !== "" &&
      geaDataInput?.geaEinarPartWeight !== "" &&
      geaDataInput?.geaSampleSize !== ""
    ) {
      const data = (
        (parseFloat(geaDataInput.geaTotalCostDelta) +
          parseFloat(geaDataInput.geaEinarPercentCost) *
          parseFloat(geaDataInput.geaEinarPartWeight) *
          parseFloat(geaDataInput.geaSampleSize)) *
        -1
      ).toFixed(2)
      if (geaDataInput.capitalRecovery !== data) {
        setGeaDataInput((prevState) => ({
          ...prevState,
          capitalRecovery: data,
        }));
      }

    }
  }, [
    geaDataInput.geaTotalCostDelta,
    geaDataInput.geaEinarPercentCost,
    geaDataInput.geaEinarPartWeight,
    geaDataInput.geaSampleSize,
  ]);

  const convertValuesToStrings = (requestBody) => {
    const stringifiedObject = {};
    for (const [key, value] of Object.entries(requestBody)) {
      stringifiedObject[key] = value.toString();
    }
    return stringifiedObject;
  };

  const onSave = async () => {
    const requestBody = convertValuesToStrings(geaDataInput);
    const b = {...requestBody, geaPoints: geaPoints.toString()}
    try {
      const restOperation = post({
        apiName: "geaTool",
        path: "/gea-tool-post",
        options: {
          body: b,
        },
      });
      const response = await restOperation.response;
      const { data } = await response.body.json();
      setId(data.createGeaTool.id);
    } catch (e) {
      console.log(e);
      console.log("GET call failed: ", JSON.parse(e.response.body));
    }
  };

  return {
    geaDataInput,
    setErrors,
    setGeaDataInput,
    errors,
    handleInputChange,
    onSave,
    id,
    geaPoints,
    onChangeSelect
  };
};
