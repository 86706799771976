import { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { textColor } from "../../../utilities/variables";

export const useGateSealingChartHooks = (setShowChart) => {
  const {
    project,
    updateProject,
    min_Hold_Selection,
    max_Hold_Selection,
  } = useContext(ProjectContext);

  const {
    minPackTable,
    maxPackTable,
    minGateSealingData,
    maxGateSealingData,
  } = project;
  const transformChartData = (tableData, name, barPositionStr) => {
    let shot;
    if (name === "Min Pack Data") {
      shot = minGateSealingData?.shot ? minGateSealingData.shot : "";
    } else {
      shot = maxGateSealingData?.shot ? maxGateSealingData.shot : "";
    }
    const barPosition = parseInt(shot ? shot : barPositionStr, 10) - 1;
    const maxShootingWeight = Math.max(
      ...tableData.map((row) => parseFloat(row.shootingWeight))
    );

    const barData = Array(tableData.length).fill(null);
    barData[barPosition] = maxShootingWeight;

    return [
      {
        type: "line",
        name: name,
        data: tableData.map((row) => parseFloat(row.shootingWeight)),
      },
      {
        type: "bar",
        name: "Selected",
        data: barData,
      },
    ];
  };

  const transformChartOptions = (tableData) => {
    return {
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        show: false,
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: tableData.map((row) => row.holdTime),
        labels: {
          style: {
            colors: textColor,
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            show: true,
            style: {
              colors: textColor,
              fontSize: "12px",
            },
          },
          title: {
            show: false,
            text: "",
            style: {
              color: "#008FFB",
            },
          },
          tooltip: {
            enabled: false,
            show: false,
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "last",
          columnWidth: "5%",
        },
      },
      legend: {
        show: false,
        color: "white",
      },
      grid: {
        show: false,
        strokeDashArray: 0,
        borderColor: "#FEB019",
      },
      colors: ["#053ecc", "#00FF00"],
    };
  };

  const onChangeSelection = async (e) => {
    await setShowChart(false);
    await updateProject({
      ...project,
      min_Hold_Selection: e.value,
      max_Hold_Selection: e.value,
    });

    const selectedValue = (await parseInt(e.value, 10)) - 1; // Convertir a índice 0 basado

    const selectedRow = project.minPackTable[selectedValue];
    const newMinGateSealingData = {
      shot: e.value,
      holdTime: selectedRow.holdTime,
      shootingWeight: selectedRow.shootingWeight,
      cushion: selectedRow.cushion,
      cycleTime: selectedRow.cycleTime,
    };
    const selectedRow2 = project.maxPackTable[selectedValue];
    const newMaxGateSealingData = {
      shot: e.value,
      holdTime: selectedRow2.holdTime,
      shootingWeight: selectedRow2.shootingWeight,
      cushion: selectedRow2.cushion,
      cycleTime: selectedRow2.cycleTime,
    };

    updateProject({
      ...project,
      min_Hold_Selection: e.value,
      max_Hold_Selection: e.value,
      maxGateSealingData: newMaxGateSealingData,
      minGateSealingData: newMinGateSealingData,
    });
    setShowChart(true);
  };

  const minPackChartData = minPackTable
    ? transformChartData(minPackTable, "Min Pack Data", min_Hold_Selection)
    : [];
  const maxPackChartData = maxPackTable
    ? transformChartData(maxPackTable, "Max Pack Data", max_Hold_Selection)
    : [];

  const minPackChartOptions = minPackTable
    ? transformChartOptions(minPackTable)
    : {};
  const maxPackChartOptions = maxPackTable
    ? transformChartOptions(maxPackTable)
    : {};

  useEffect(() => {
    if (project.minGateSealingData?.shot) {
      transformChartData(
        minPackTable,
        "Min Pack Data",
        project.minGateSealingData.shot
      );
    }
  }, [project.minGateSealingData]);

  const onSaveTable = async () => {};

  return {
    transformChartData,
    transformChartOptions,
    onSaveTable,
    onChangeSelection,
    minPackChartData,
    maxPackChartData,
    minPackChartOptions,
    maxPackChartOptions,
  };
};
