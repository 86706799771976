import { Grid, GridItem, Link, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { InstagramIcon } from "../../../../components/Icons/InstagramIcon";
import CollaboratorsMap from "../../../../components/collaborators/Map";
import { lan, lat } from "../utilities";
import { googleMapURL } from "../../../../variables/general";

const GintecContact = () => {
  return (
    <Grid
      backgroundColor={"#808a99"}
      templateColumns="repeat(2, 1fr)"
      gap={1}
      mx={5}
      p={5}
      mt={5}
      borderWidth={1}
      borderRadius={15}
    >
      <Grid>
        <CollaboratorsMap
          zoom={17}
          marker={true}
          lat={lat}
          lan={lan}
          isMarkerShown={false}
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Grid>
      <Grid justifyItems={"center"}>
        <Text color={"white"} fontSize={"4xl"} fontWeight={"bold"}>
          Conócenos
        </Text>
        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Ubicación
        </Text>
        <Text color={"white"} fontSize={"md"}>
          Othon Almada #520
        </Text>
        <Text color={"white"} fontSize={"md"}>
          Hermosillo, Sonora, Mex
        </Text>
        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Horario
        </Text>
        <Text fontSize={"md"} color={"#c40404"}>
          24/7
        </Text>
        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Contacto
        </Text>
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Ing. Noé Palomares Meléndez
        </Text>
        <Text color={"white"} fontSize={"md"}>
          Cel.: +52 662 276 8215
        </Text>
        <Text color={"white"} fontSize={"md"}>
          n.palomares@g-intec.com.mx
        </Text>
        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Redes Sociales
        </Text>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <Link
            colorScheme={"facebook"}
            style={{ paddingRight: 15 }}
            href="https://facebook.com"
            isExternal
          >
            <FontAwesomeIcon color={"#3b5998"} icon={faFacebook} size={"2xl"} />{" "}
          </Link>
          <Link
            style={{ paddingRight: 15, paddingLeft: 15 }}
            href="https://www.instagram.com"
            isExternal
          >
            <InstagramIcon />
          </Link>
          <Link
            style={{ paddingLeft: 15 }}
            href="https://www.linkedin.com/?original_referer=https%3A%2F%2Fmoldingvps.com%2F"
            isExternal
          >
            <FontAwesomeIcon color={"#0A66C2"} icon={faLinkedin} size={"2xl"} />{" "}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GintecContact;
