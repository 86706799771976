import { Flex, Grid } from "@chakra-ui/react";
import React from "react";
import Body from "./components/Body";
import GeaProducts from "./components/GeaProducts";
import Contact from "./components/Contact";
import { useHistory } from "react-router-dom";
import GeaHeader from "./components/GeaHeader";
import GeaMapComponent from "./components/GeaMapComponent";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionGea,
  metaKeywordsGea,
  metaTitleGea,
} from "../utilities/metaVariables";

function Gea() {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");
  return (
    <>
      <HelmetComponent
        description={metaDescriptionGea}
        title={metaTitleGea}
        keywords={metaKeywordsGea}
      />
      <Flex
        direction="column"
        pt={{ sm: "110px", lg: "125" }}
        width={isBannerPath ? "80%" : "auto"} // Aquí aplicamos el ancho condicionalmente
        mx={isBannerPath ? "auto" : "0"}
      >
        <GeaHeader />
        <Body />
        <GeaProducts />
        <GeaMapComponent />
        <Grid></Grid>
        <Contact collaborator={"Gea"} />
      </Flex>
    </>
  );
}

export default Gea;
