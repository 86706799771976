import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";
import CustomGrid from "./CustomGrid";
import Select from "react-select";
import { customStyles } from "../utilities/selectStyles";
import useFormattedDate from "../hooks/useFormattedDate";
import { inputBorderColor, textColor } from "../utilities/variabled";
import GoBackButton from "./Buttons/GoBackButton";

const IndividualHeader = ({
  onSubmitHandler,
  setName,
  isLoading,
  name,
  user,
  selectOptions,
  machineName,
  setResinName,
  productName,
  resinName,
  setMachineName,
  setProductName,
  toolName,
  setToolName,
  setUom,
  isDisabled,
}) => {
  const currentDate = useFormattedDate();

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={6} mt={10} width={"100%"}>
      <FormControl scrollPadding={2}>
        <FormLabel color={textColor}>Nombre</FormLabel>
        <Input
          size={"sm"}
          borderRadius={15}
          borderColor={inputBorderColor}
          onChange={(e) => setName(e.target.value)}
          disabled={user.name || isDisabled}
          value={user.name ? user.name : name}
          placeholder="Nombre de Usuario"
        />
        {!user.name && (
          <Button
            disabled={!name}
            mt={5}
            colorScheme={"facebook"}
            onClick={() => onSubmitHandler()}
            isLoading={isLoading}
          >
            Guardar Nombre
          </Button>
        )}
      </FormControl>
      <FormControl>
        <FormLabel color={textColor}>Sistema de Medición</FormLabel>
        <Select
          isDisabled={isDisabled}
          onChange={(e) => setUom(e.value)}
          options={selectOptions}
          styles={customStyles}
          placeholder="Seleccionar Unidad de Medida"
          isClearable={false} // Permite limpiar la selección
          isSearchable={false} // Desactiva la búsqueda dentro del select
        />
      </FormControl>
      <FormControl>
        <FormLabel color={textColor}>Fecha</FormLabel>
        <Text color={textColor}>{currentDate}</Text>
      </FormControl>
      <FormControl>
        <FormLabel color={textColor}>Email: </FormLabel>
        <Text color={textColor}>{user?.email}</Text>
      </FormControl>
      <FormControl>
        <FormLabel color={textColor}>Molde</FormLabel>
        <Input
          disabled={isDisabled}
          size={"sm"}
          borderRadius={5}
          color={textColor}
          borderColor={inputBorderColor}
          value={toolName}
          onChange={(e) => setToolName(e.target.value)}
          placeholder={""}
          background={"white"}
        />
      </FormControl>
      <FormControl>
        <FormLabel color={textColor}>Máquina</FormLabel>
        <Input
          disabled={isDisabled}
          size={"sm"}
          borderRadius={5}
          color={textColor}
          borderColor={inputBorderColor}
          value={machineName}
          onChange={(e) => setMachineName(e.target.value)}
          placeholder={""}
          background={"white"}
        />
      </FormControl>
      <FormControl>
        <FormLabel color={textColor}>Resina</FormLabel>
        <Input
          disabled={isDisabled}
          size={"sm"}
          borderRadius={5}
          color={textColor}
          borderColor={inputBorderColor}
          value={resinName}
          onChange={(e) => setResinName(e.target.value)}
          placeholder={""}
          background={"white"}
        />
      </FormControl>
      <FormControl>
        <FormLabel color={textColor}>Producto</FormLabel>
        <Input
          disabled={isDisabled}
          size={"sm"}
          borderRadius={5}
          color={textColor}
          borderColor={inputBorderColor}
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          placeholder={""}
          background={"white"}
        />
      </FormControl>
    </Grid>
  );
};

export default IndividualHeader;
