const validateForm = (minPackTable, maxPackTable) => {
  const isNumberString = (value) => {
    return !isNaN(value) && typeof value === "string" && value.trim() !== "";
  };

  const isMinPackComplete = minPackTable
    ? minPackTable.every(
        (row) =>
          isNumberString(row.shootingWeight) &&
          isNumberString(row.cushion) &&
          isNumberString(row.cycleTime)
      )
    : false;
  const isMaxPackComplete = maxPackTable
    ? maxPackTable.every(
        (row) =>
          isNumberString(row.shootingWeight) &&
          isNumberString(row.cushion) &&
          isNumberString(row.cycleTime)
      )
    : false;

  return isMinPackComplete && isMaxPackComplete;
};

export default validateForm;
