const instructions = [
  "Además de efectuar la prueba Melt 30/30, también podremos perfilar la Temperatura del Barril para obtener un punto de partida para la elaboración del Proceso de Moldeo.",
  "1. En base a la Carta de Información de la Resina del fabricante estableceremos las temperaturas con las que configuraremos el calentamiento del Barril, aunque suele usarse las temperaturas nominales, la decisión y ajuste es responsabilidad de cada usuario, basándose en las características específicas de cada producto, sus necesidades y limitaciones.",
  "2. Cargar la Tolva de Alimentación con la Resina suficiente para la muestra. Es importante que el Barril no se vacíe mientras se está realizando los Disparos de muestra.",
  "3. Calentar y mantener caliente la Pipeta del Pirómetro, a la temperatura sugerida por el formulario. Que generalmente pueden utilizarse la temperatura de la nariz como referencia y aumentar 30°F.",
  "4. Realizar disparos al aire, los que sean necesarios para tener una masa abundante que pueda rodear completamente la Pipeta del Pirómetro.",
  "5. Introducir la Pipeta Metálica dentro de la masa de muestra y mantener en constante movimiento, sin separar la mas ni retirar la pipeta durante 30 seg.",
  "6. Registrar los valores obtenidos en las casillas en blanco para observar los resultados.",
  "Nota: El formulario nos mostrará la ventana de temperatura sugerida por el fabricante y los valores con los que estamos trabajando, estos valores mostraran una silueta en rojo y deben de permanecer dentro de los rangos preestablecidos por la Carta de Información de la Resina.",
];

export default instructions;
