import { keysForForm } from "../utilities/variables";

export const useVerifyForm = (data) => {
  for (const key of keysForForm) {
    if (Array.isArray(data[key])) {
      if (
        data[key].some(
          (item) =>
            item.value === "" ||
            item.value === undefined ||
            parseFloat(item.value) <= 0
        )
      ) {
        return false;
      }
    } else {
      if (
        data[key] === "" ||
        data[key] === undefined ||
        parseFloat(data[key]) <= 0
      ) {
        return false;
      }
    }
  }

  return true;
};
