export const useVerifyForm = (state, keys) => {
  // Iterar sobre el arreglo de claves a verificar
  for (const clave of keys) {
    const valor = state[clave];
    // Verificar si el valor es undefined, null o una cadena vacía
    if (valor === undefined || valor === null || valor === '') {
      return false; // Si alguno está vacío, retorna false
    }
  }
  return true; // Todos los valores están llenos
};