import { Button, Grid, GridItem, Text } from "@chakra-ui/react";
import CustomInput from "../../../../../projects/individual/components/CustomInput";
import React from "react";

const RequirementsForm = ({
  geaDataInput,
  setGeaDataInput,
  errors,
  handleInputChange,
}) => {

  const handleSubmit = async () => {
    if (geaDataInput.phase === 2) {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 3}))
    } else {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 2}))
    }
  };
  return (
    <>
      {
        geaDataInput.phase === 2 && (
          <GridItem colSpan={4} width={"80%"}>
            <Grid
              templateColumns="repeat(4, 1fr)"
              justifyItems={"center"}
              alignItems={"center"}
              width={"100%"}
              gap={3}
            >
              <GridItem colSpan={2}>
                <Text as={"h2"} fontWeight={"bold"}>
                  REQUERIMIENTO
                </Text>
              </GridItem>
              <Text as={"h2"} fontWeight={"bold"}>
                POR PIEZA
              </Text>
              <Text as={"h2"} fontWeight={"bold"}>
                POR LOTE
              </Text>
              <Text as={"h2"}>Resina Virgen</Text>
              <CustomInput
                disabled={true}
                addOnText={"%"}
                onChange={handleInputChange}
                id={"geaVirginResin"}
                value={parseFloat(geaDataInput.geaVirginResin).toFixed(2)}
                error={errors.geaVirginResin}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaVirginPartWeight"}
                value={parseFloat(geaDataInput.geaVirginPartWeight).toFixed(4)}
                error={errors.geaVirginPartWeight}
                addOnText={geaDataInput.geaUdM}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaVirginLotWeight"}
                value={parseFloat(geaDataInput.geaVirginLotWeight).toFixed(2)}
                error={errors.geaVirginLotWeight}
                addOnText={geaDataInput.geaUdM}
              />

              <Text as={"h2"}>Material Recuperado</Text>
              <CustomInput
                disabled={true}
                addOnText={"%"}
                onChange={handleInputChange}
                id={"geaRegrindResin"}
                value={parseFloat(geaDataInput.geaRegrindResin).toFixed(2)}
                error={errors.geaRegrindResin}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaRegrindPartWeight"}
                value={parseFloat(geaDataInput.geaRegrindPartWeight).toFixed(4)}
                error={errors.geaRegrindPartWeight}
                addOnText={geaDataInput.geaUdM}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaRegrindLotWeight"}
                value={parseFloat(geaDataInput.geaRegrindLotWeight).toFixed(2)}
                error={errors.geaRegrindLotWeight}
                addOnText={geaDataInput.geaUdM}
              />
              <Text as={"h2"}>Otros Aditivos</Text>
              <CustomInput
                disabled={true}
                addOnText={"%"}
                onChange={handleInputChange}
                id={"geaOtherAdditives"}
                value={parseFloat(geaDataInput.geaOtherAdditives).toFixed(2)}
                error={errors.geaOtherAdditives}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaAdditivePartWeight"}
                value={parseFloat(geaDataInput.geaAdditivePartWeight).toFixed(4)}
                error={errors.geaAdditivePartWeight}
                addOnText={geaDataInput.geaUdM}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaAdditiveLotWeight"}
                value={parseFloat(geaDataInput.geaAdditiveLotWeight).toFixed(2)}
                error={errors.geaAdditiveLotWeight}
                addOnText={geaDataInput.geaUdM}
              />

              <Text as={"h2"}>Aditivo Einar 201</Text>
              <CustomInput
                disabled={true}
                addOnText={"%"}
                onChange={handleInputChange}
                id={"geaEinarPercent"}
                value={parseFloat(geaDataInput.geaEinarPercent).toFixed(2)}
                error={errors.geaEinarPercent}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaEinarPartWeight"}
                value={parseFloat(geaDataInput.geaEinarPartWeight).toFixed(4)}
                error={errors.geaEinarPartWeight}
                addOnText={geaDataInput.geaUdM}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geaEinarLotWeight"}
                value={parseFloat(geaDataInput.geaEinarLotWeight).toFixed(2)}
                error={errors.geaEinarLotWeight}
                addOnText={geaDataInput.geaUdM}
              />

              <Text as={"h2"}>Total</Text>
              <CustomInput
                disabled={true}
                addOnText={"%"}
                onChange={handleInputChange}
                id={"total"}
                value={parseFloat(geaDataInput.total).toFixed(2)}
                error={errors.total}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"totalPerPiece"}
                value={parseFloat(geaDataInput.totalPerPiece).toFixed(4)}
                error={errors.totalPerPiece}
                addOnText={geaDataInput.geaUdM}
              />
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"totalPerLote"}
                value={parseFloat(geaDataInput.totalPerLote).toFixed(2)}
                error={errors.totalPerLote}
                addOnText={geaDataInput.geaUdM}
              />
            </Grid>
          </GridItem>
        )
      }


      <GridItem colSpan={4} width={"80%"} mt={5}>
        <Button
          onClick={() => handleSubmit()}
          width={"100%"}
          children={geaDataInput.phase === 2 ? "Continuar" : "Ver Requerimientos"}
          colorScheme={"whatsapp"}
        />
      </GridItem>
    </>
  );
};

export default RequirementsForm;
