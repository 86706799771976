import React, { useContext, useEffect } from "react";
import { Button, Grid, GridItem } from "@chakra-ui/react";
import { useCoolingTimeHooks } from "./hooks/useCoolingTimeHooks";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import EditBase from "../../Cards/EditBase";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { useParams } from "react-router-dom";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { badgeColor, borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import {
  inMm,
  inSquareSecMmSquareSec,
  temp,
} from "../../../../individual/hooks/useUomTransform";

const EditCoolingTime = () => {
  const { id } = useParams();
  const {
    project,
    updateProject,
    isLoading,
    handleInputChange,
    resin,
  } = useContext(ProjectContext);
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    onSubmitFinal,
    showData,
    onSubmitHandler,
    setShowData,
    setFinal,
    final,
  } = useCoolingTimeHooks();

  const { thermalDiffusion, demoldingTemperature } = resin;
  const {
    realMeltTemp,
    max_Wall_Thickness,
    tool_A_Side_Real_Temp,
    tool_B_Side_Real_Temp,
    ejection_Temp_Required,
    cooling_Time_Suggested,
    cooling_Time_Select,
  } = project;

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        keywords={metaKeywords}
        title={metaTitle}
      />
      <EditBase title={"Tiempo de Enfriamiento"} tittleButton={"Terminar"}>
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            borderColor={borderColor}
            mx={5}
            p={5}
            mb={5}
            borderRadius={15}
            borderWidth={1}
            templateColumns="repeat(3, 1fr)"
            gap={4}
            justifyItems={"center"}
          >
            <DataDisplayBadge
              type={"dif"}
              color={textColor}
              badgeColorScheme={badgeColor}
              rightAddon={inSquareSecMmSquareSec(project?.uom)}
              fontSize={"sm"}
              subType={""}
              fix={7}
              title={"Difusividad Térmica"}
              value={thermalDiffusion}
            />
            <DataDisplayBadge
              type={"temp"}
              color={textColor}
              badgeColorScheme={badgeColor}
              rightAddon={temp(project?.uom)}
              fix={1}
              fontSize={"sm"}
              subType={""}
              title={"Temperatura de Desmoldeo"}
              value={demoldingTemperature}
            />

            <DataDisplayBadge
              type={"temp"}
              color={textColor}
              badgeColorScheme={badgeColor}
              rightAddon={temp(project?.uom)}
              fontSize={"sm"}
              subType={""}
              title={"Temperatura Melt"}
              value={realMeltTemp}
              fix={1}
            />
          </Grid>
          <Grid
            mx={5}
            p={5}
            borderWidth={1}
            borderRadius={15}
            borderColor={borderColor}
          >
            <Grid
              justifyItems={"center"}
              templateColumns="repeat(2, 1fr)"
              templateRows="repeat(2, 1fr)"
              gap={4}
            >
              <InputCustomFieldProjectRigthAddon
                borderColor={borderColor}
                addOn={inMm(project?.uom)}
                color={textColor}
                mx={3}
                label="Maximo Espesor de Pared"
                value={max_Wall_Thickness}
                onChange={handleInputChange}
                id="max_Wall_Thickness"
              />
              <InputCustomFieldProjectRigthAddon
                addOn={temp(project?.uom)}
                color={textColor}
                borderColor={borderColor}
                mx={5}
                label="Temperatura de Moldeo Lado A"
                value={tool_A_Side_Real_Temp}
                onChange={handleInputChange}
                id="tool_A_Side_Real_Temp"
              />
              <InputCustomFieldProjectRigthAddon
                addOn={temp(project?.uom)}
                color={textColor}
                borderColor={borderColor}
                mx={3}
                label="Temperatura de Moldeo Lado B"
                value={tool_B_Side_Real_Temp}
                onChange={handleInputChange}
                id="tool_B_Side_Real_Temp"
              />
              <InputCustomFieldProjectRigthAddon
                addOn={temp(project?.uom)}
                color={textColor}
                borderColor={borderColor}
                mx={3}
                label="Temperatura de Expulsion Deseada"
                value={ejection_Temp_Required}
                onChange={handleInputChange}
                id="ejection_Temp_Required"
              />
            </Grid>
            <Button
              colorScheme={"facebook"}
              isLoading={isLoading}
              disabled={
                !max_Wall_Thickness ||
                !tool_A_Side_Real_Temp ||
                !tool_B_Side_Real_Temp ||
                !ejection_Temp_Required
              }
              mt={5}
              onClick={
                showData ? () => setShowData(false) : () => onSubmitHandler()
              }
            >
              {showData ? "Editar" : "Guardar"}
            </Button>
          </Grid>

          {showData && (
            <Grid
              mt={5}
              p={5}
              mx={5}
              borderWidth={1}
              borderRadius={15}
              borderColor={borderColor}
            >
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={4}
                justifyItems={"center"}
              >
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={badgeColor}
                  rightAddon={"seg"}
                  fontSize={"sm"}
                  subType={"seg"}
                  fix={2}
                  title={"Tiempo de Enfriamiento Sugerido"}
                  value={cooling_Time_Suggested}
                />
                <InputCustomFieldProjectRigthAddon
                  color={textColor}
                  badgeColorScheme={badgeColor}
                  addOn={"seg"}
                  mx={3}
                  label="Tiempo de Enfriamiento Elegido"
                  value={cooling_Time_Select}
                  onChange={handleInputChange}
                  id="cooling_Time_Select"
                />
              </Grid>
              <Button
                colorScheme={"facebook"}
                isLoading={isLoading}
                disabled={!cooling_Time_Select}
                mt={5}
                mx={5}
                onClick={final ? () => setFinal(false) : () => onSubmitFinal()}
              >
                {final ? "Editar" : "Guardar"}
              </Button>
            </Grid>
          )}
        </GridItem>
      </EditBase>
    </>
  );
};
export default EditCoolingTime;
