export const metaTitleIndex =
  "Gestión de Máquinas de Moldeo por Inyección: Vista General | MVPS";
export const metaDescriptionIndex =
  "Administra eficientemente tus máquinas de moldeo por inyección con nuestra plataforma. Visualiza, accede a detalles y ajusta configuraciones en un solo lugar. Ideal para optimizar procesos y mejorar la productividad.";

export const metaKeywordsIndex =
  "gestión de máquinas, moldeo por inyección, configuración de máquinas, optimización de procesos, productividad de moldeo, mvps";
export const metaTitleCreate =
  "Registrar Nueva Máquina de Moldeo por Inyección | MVPS";
export const metaDescriptionCreate =
  "Simplifica la incorporación de nuevas máquinas de moldeo por inyección a tu operación con nuestro formulario de alta. Asegura la correcta configuración desde el inicio para una integración sin contratiempos.";

export const metaKeywordsCreate =
  "alta de máquina, configuración de moldeo, registro de inyección, operación de moldeo, incorporación de máquinas, mvps";
export const metaTitleConvert =
  "Conversión de Sistema para Máquinas de Moldeo por Inyección | MVPS";
export const metaDescriptionConvert =
  "Convierte las especificaciones de tus máquinas de moldeo por inyección entre sistemas métrico e imperial fácilmente. Asegura la compatibilidad y precisión en tus procesos de moldeo, independientemente del sistema de medición.";

export const metaKeywordsConvert =
  "conversión de sistema, máquinas de moldeo, sistema métrico, sistema imperial, precisión en moldeo, adaptabilidad de máquinas, mvps";
