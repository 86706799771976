import {inCubeCmCube, inCubeMmCube, inMm, inSquareMmSquare, ozGr} from "../../../../../hooks/useUomTransform";

export const dataOutputs1 = data => {
  return [
    {
      label: "Área de Pistón",
      value: data?.pistonArea,
      type: "weight",
      addOn: inSquareMmSquare(data?.header?.uom),
    },
    {
      label: "Razón de Intensificación",
      value: data?.intRatio,
      type: "int",
      addOn: ":1",
    },
    {
      label: "Tamaño Total de Disparo",
      value: data?.overallShotSize,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Volumen de Disparo",
      value: data?.shotVolume,
      type: "weight",
      addOn: inCubeCmCube(data?.header?.uom),
    },
    {
      label: "Disparos en el Barril",
      value: data?.shotsInBarrel,
      type: "weight",
      addOn: "shots",
    },
    {
      label: "Posición de Disparo",
      value: data?.shotPercentage,
      type: "temp",
      addOn: "%",
    },
    {
      label: "Posición Total de Tornillo",
      value: data?.screwTotalPosition,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Ocupación de Barril",
      value: data?.barrelUsage,
      type: "temp",
      addOn: "%",
    },
  ];
}

//screwTotalPosition
// barrelUsage
//cushionPosTheoric

export const dataOutputs2 = data => {
  return [
    {
      label: "Área de Tornillo",
      value: data?.screwArea,
      type: "weight",
      addOn: inSquareMmSquare(data?.header?.uom),
    },
    {
      label: "Peso de Primera Etapa",
      value: data?.shotWeightFirstStage,
      type: "weight",
      addOn: ozGr(data?.header?.uom),
    },
    {
      label: "Máximo Peso Teórico de Barril",
      value: data?.barrelMaxWeight,
      type: "weight",
      addOn: ozGr(data?.header?.uom),
    },
    {
      label: "Volumen Teórico de Barril",
      value: data?.barrelTheoricVolume,
      type: "weight",
      addOn: inCubeCmCube(data?.header?.uom),
    },
    {
      label: "Tamaño Teórico de Disparo",
      value: data?.shotPositionTheoric,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Transferencia V/P Teórica",
      value: data?.cutoffPositionTheoric,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Posición Teórica de Colchón",
      value: data?.cushionPosTheoric,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
  ];
}