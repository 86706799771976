import React from "react";
import { Button, Grid, Image, Link, Text } from "@chakra-ui/react";

export const CollaboratorsBasicInfo2 = ({
  backGroungColor,
  image,
  text1,
  text2,
  text3,
  textColor,
  heading2,
  heading4,
  heading3,
  heading1,
  url,
  ariaLabel,
  isAuthenticated,
}) => {
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      mt={5}
      color={"white"}
      justifyContent={"center"}
      gap={6}
      borderWidth={1}
      mx={5}
      p={5}
      borderRadius={15}
      opacity={0.9}
      backgroundColor={backGroungColor}
    >
      <Grid>
        {text1 && <Text color={textColor}>{text1}</Text>}
        {text2 && <Text color={textColor}>{text2}</Text>}
        {text3 && <Text color={textColor}>{text3}</Text>}
      </Grid>
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <Grid justifyItems={"center"} gap={6}>
          {heading1 && (
            <Text fontSize={"lg"} color={textColor} fontWeight={"bold"}>
              {heading1}
            </Text>
          )}
          {heading2 && (
            <Text fontSize={"lg"} color={textColor} fontWeight={"bold"}>
              {heading2}
            </Text>
          )}
          {heading3 && (
            <Text fontSize={"lg"} color={textColor} fontWeight={"bold"}>
              {heading3}
            </Text>
          )}
          {heading4 && (
            <Text fontSize={"lg"} color={textColor} fontWeight={"bold"}>
              {heading4}
            </Text>
          )}
        </Grid>
        <Grid justifyItems={"center"} gap={6}>
          <Image src={image} w={"200px"} />
          <Link
            aria-label={ariaLabel}
            href={`/${
              isAuthenticated ? "admin" : "banner"
            }/collaborators/${url}`}
          >
            <Button colorScheme={"telegram"}>Conocenos</Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CollaboratorsBasicInfo2;
