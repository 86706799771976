export function validarDoeTable(project) {
  if (project && project.doeTable) {
    const { doeTable } = project;
    for (const item of doeTable) {
      const campos = [
        "cooling",
        "cushion",
        "cycleTime",
        "holdPress",
        "temp",
        "maxInjPress",
        "injTime",
        "recovery",
        "shootingWeight",
      ];

      for (const campo of campos) {
        if (item[campo] === "" || isNaN(Number(item[campo]))) {
          return true;
        }
      }
    }
  }

  return false;
}
