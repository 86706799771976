export const initialStateHeader = {
  name: "",
  email: "",
  machineName: "",
  productName: "",
  resinName: "",
  toolName: "",
  uom: "",
}

export const initialStateMinMax = {
  min: "",
  max: ""
}

export const textColor = "gray.600";
export const inputBorderColor = "gray.400";

export const individualColumnsData = [
  {
    Header: "Maquina",
    accessor: "header.machineName",
    type: "string",
  },
  {
    Header: "Resina",
    accessor: "header.resinName",
    type: "string",
  },
  {
    Header: "Molde",
    accessor: "header.toolName",
    type: "string",
  },
  {
    Header: "Producto",
    accessor: "header.productName",
    type: "string",
  },
  {
    Header: "Fecha de Creación",
    accessor: "createdAt",
    type: "string",
  },
];