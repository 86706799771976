import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import { Grid } from "@chakra-ui/react";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../utilities/variables";
import {inMm, ozGr, ppsiToPbar} from "../../../../../individual/hooks/useUomTransform";

const GateSealingData = () => {
  const { project } = useContext(ProjectContext);

  const {
    speedSelected,
    cushionPositionTheoric,
    min_Hold_Press_Ppsi,
    max_Hold_Press_Ppsi,
  } = project;

  return (
    <Grid
      mb={5}
      p={5}
      mx={5}
      borderWidth={1}
      borderRadius={15}
      templateColumns="repeat(3, 1fr)"
      gap={6}
      templateRows="repeat(2, 1fr)"
      borderColor={borderColor}
    >
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        rightAddon={"seg"}
        value={speedSelected?.inyectionTime}
        fontSize={"sm"}
        subType={""}
        fix={3}
        title={"Tiempo de Llenado"}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        rightAddon={inMm(project?.uom)}
        fontSize={"sm"}
        subType={""}
        title={"Posición de Colchón"}
        value={cushionPositionTheoric}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        rightAddon={ppsiToPbar(project?.uom)}
        fontSize={"sm"}
        subType={""}
        type={"pressure"}
        title={"Minima Presión Requerida"}
        value={min_Hold_Press_Ppsi}
        fix={1}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        rightAddon={ppsiToPbar(project?.uom)}
        type={"pressure"}
        fontSize={"sm"}
        subType={""}
        title={"Máxima Presión Requerida"}
        value={max_Hold_Press_Ppsi}
        fix={1}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        rightAddon={ozGr(project?.uom)}
        fontSize={"sm"}
        subType={""}
        title={"Peso de Primera Etapa"}
        value={speedSelected?.averageShotWeight}
      />
      <DataDisplayBadge
        color={textColor}
        badgeColorScheme={badgeColor}
        rightAddon={project?.max_Hold_Time ? "seg" : ""}
        fontSize={"sm"}
        subType={""}
        title={"Intervalos de Tiempo"}
        value={
          project?.hold_Time_Intervals
            ? project.hold_Time_Intervals
            : project?.max_Hold_Time
            ? parseFloat(project.max_Hold_Time) / 9
            : ""
        }
        fix={3}
      />
    </Grid>
  );
};

export default GateSealingData;
