import { useIndividualHooks } from "../hooks/useIndividualHooks";
import IndividualFooter from "../../../components/IndividualFooter";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { keysForForm } from "../utilities/variables";
import React from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import ShowResults from "../../../components/ShowResults";
import { dataResults } from "../utilities/dataToShow";
import Form from "./Form";
import CustomGrid from "../../../components/CustomGrid";
import PressureLossChart from "../../../../project-overview/components/tools/pressure-loss/components/PressureLossChart";
import { lineChartOptionsPressureLoss } from "../utilities/chartOptions";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import instructions from "../utilities/instructions";
import description from "../utilities/description";
import { validateResultsFilled } from "../utilities/validateResults";
import { KeysForCheck } from "../../shot-size/utilities/variables";
import {areTablePropertiesComplete} from "../utilities/areTablePropertiesComplete";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import CustomInput from "../../../components/CustomInput";
import HeaderFirst from "./table/HeaderFirst";
import {Text} from "@chakra-ui/react";

const IndividualForm = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    showDataTable, onChangeTable
  } = useIndividualHooks(setIsDisabled);


  const isDisabled = !useVerifyForm(data, keysForForm);
  const isFormDisabled = !isObjectEmpty(result);

  const isComplete = !useVerifyForm(data, KeysForCheck);


  const isTableFormComplete = areTablePropertiesComplete(data?.table);

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <DescriptionAndInstructionAcordeon
          instructions={instructions}
          description={description}
        />
        <Form
          isLoading={isLoading}
          onSubmit={onSubmitHandler}
          isDisabled={isDisabled}
          onChange={onChangeHandler}
          data={data}
          disabled={isFormDisabled}
        />

        <ShowResults repeat={4} data={dataResults(data)} />
        {
          showDataTable && (
            <CustomGrid repeat={isTableFormComplete ? 12 : 6}>
              <HeaderFirst header={data?.header} />
              {
                data?.table.map((item, index) => {
                  return (
                    <>
                      <Text key={`secuence-${index}`}>{item.sequence}</Text>
                      <Text key={`speed-${index}`}>{item.speed}</Text>
                      <CustomInput
                        key={`fill-${index}`}
                        name={`table.${index}.fillTime`}
                        id={`fillTime-${index}`}
                        value={item.fillTime}
                        onChange={onChangeTable}
                      />
                      <CustomInput
                        key={`max-press-${index}`}
                        name={`table.${index}.maxPress`}
                        id={`maxPress-${index}`}
                        value={item.maxPress}
                        onChange={onChangeTable}
                      />
                      <CustomInput
                        key={`shot-${index}`}
                        name={`table.${index}.shotWeight`}
                        id={`shotWeight-${index}`}
                        value={item.shotWeight}
                        onChange={onChangeTable}
                      />
                      <CustomInput
                        key={`vp-${index}`}
                        name={`table.${index}.vpTransfer`}
                        id={`vpTransfer-${index}`}
                        value={item.vpTransfer}
                        onChange={onChangeTable}
                      />
                    </>

                  )

                })
              }

            </CustomGrid>
          )

        }

        <IndividualFooter
          result={result}
          url={"loss-pressure"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={isDisabled}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualForm;
