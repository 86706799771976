// CustomCard.js
import React from "react";
import { Flex, Text, Progress, Button, Avatar } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileEdit } from "@fortawesome/free-solid-svg-icons";
import CardHeader from "../../../../../components/Card/CardHeader";
import Card from "../../../../../components/Card/Card";
import { cardHeaderStyles } from "../../../../../theme/styles";

import bgImage from "../../../../../assets/img/bg-image-ia.png";

const OverViewCard = ({
  overview = false,
  headerTitle,
  phase = 0,
  onEyeClick = () => console.log("eye"),
  onEditClick,
  compare,
  logo,
}) => {
  const phaseText =
    phase === compare
      ? "EN PROCESO"
      : phase > parseInt(compare)
      ? "COMPLETO"
      : "DESHABILITADO";
  const progressValue =
    phase === parseInt(compare) ? 50 : phase > parseInt(compare) ? 100 : 0;
  const progressText = `${parseInt(compare) + 1} / 16`;

  return (
    <Card backgroundImage={bgImage} h={"300px"}>
      <Flex direction={"column"} height="100%" justifyContent="space-between">
        <Flex justifyContent={"flex-start"}>
          <CardHeader {...cardHeaderStyles} fontSize={"sm"}>
            {headerTitle}
          </CardHeader>
        </Flex>

        <Flex justifyContent={"center"} alignItems={"center"} mt={4}>
          <Avatar src={logo} w="80px" h="80px" />
        </Flex>
        {/* Contenido alineado en la parte inferior */}
        <Flex direction={"column"} justifyContent={"flex-end"}>
          {!overview && (
            <>
              <Flex
                mt={5}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text alignItems={"flex-end"}>{phaseText}</Text>
                <Text>{progressText}</Text>
              </Flex>
              <Progress colorScheme="green" value={progressValue} mt={2} />
            </>
          )}
          <Flex
            direction={!overview ? "row" : "column"}
            mx={5}
            mt={5}

            justifyContent={overview ? "space-between" : "space-between"}
            alignItems={"center"}
          >
            <Button
              width={overview && "100%"}
              mb={overview && 2}
              colorScheme={"yellow"}
              disabled={phase < parseInt(compare)}
              onClick={onEyeClick}
            >
              {!overview ? (
                <FontAwesomeIcon icon={faEye} />
              ) : phase < parseInt(compare) ? (
                "Proximamente"
              ) : (
                "Index"
              )}
            </Button>

            <Button
              colorScheme={"telegram"}
              disabled={phase < parseInt(compare)}
              onClick={onEditClick}
              justifyItems={"center"}
              w={overview ? "100%" : ""}
            >
              {!overview ? (
                <FontAwesomeIcon icon={faFileEdit} />
              ) : phase < parseInt(compare) ? (
                "Proximamente"
              ) : (
                "Crear"
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default OverViewCard;
