import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import "moment/locale/es";
import usePdfStyles from "../hooks/usePdfstyles";
import mvpsLogo from "../../../../assets/img/mvpslogo.jpg";

const styles = usePdfStyles();

const PDFHeader = ({ title, data }) => {
  const date = moment(data?.createdAt)
    .tz("America/Mexico_City")
    .format("dddd D [de] MMMM [del] YYYY");

  return (
    <>
      <View
        fixed
        style={{
          position: "absolute",
          minWidth: "50%",
          alignSelf: "center",
          justifyContent: "center",
          top: "40%",
          display: "block",
          width: "50%",
          opacity: 0.05,
        }}
      >
        <Image src={mvpsLogo} />
      </View>

      <Image
        src={mvpsLogo}
        style={{
          marginTop: "20px",
          alignSelf: "center",
          width: "150px",
          justifyContent: "center",
          display: "block",
          marginBottom: "20px",
        }}
      />
      <Text style={styles.header}>{title}</Text>
      <View
        style={{
          width: "90%",
          paddingLeft: "10px",
          paddingRight: "10px",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: 5,
          justifyContent: "space-between",
        }}
      >
        <Text style={styles.author}>
          Creado por: {data?.header?.name} - {data?.header?.email}
        </Text>
        <Text style={styles.author}>Fecha de creación: {date}</Text>
      </View>
      <View style={styles.view}>
        <View>
          <Text style={styles.text}>
            Nombre de la Maquina:{" "}
            <Text style={styles.text}>{data?.header?.machineName}</Text>
          </Text>
          <Text style={styles.text}>
            Nombre del Molde:{" "}
            <Text style={styles.text}>{data?.header?.toolName}</Text>
          </Text>
          <Text style={styles.text}>
            Unidad de Medida:{" "}
            <Text style={styles.text}>{data?.header?.uom}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            Nombre de la Resina:{" "}
            <Text style={styles.text}>{data?.header?.resinName}</Text>
          </Text>
          <Text style={styles.text}>
            Nombre del Producto:{" "}
            <Text style={styles.text}>{data?.header?.productName}</Text>
          </Text>
        </View>
      </View>
    </>
  );
};

export default PDFHeader;
