import { useContext, useEffect, useState } from "react";
import { cavityBalalceInitialState } from "../utilities/variables";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";
import { isFloatString } from "../../../../../../../utilities/functions";
import { speedSelected } from "../../../../../new-project/utilities/variables";
import { textColor } from "../../../utilities/variables";
import { inSecMmsec } from "../../../../../individual/hooks/useUomTransform";

export const useCavityBalanceHooks = () => {
  const [selectedLineavility, setSelectedLineavility] = useState([]);
  const [showValues, setShowValues] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState({});

  const [lineChartOptions, setLineChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: true,
      labels: {
        color: textColor, // Ajusta los colores de las etiquetas de la leyenda
        useSeriesColors: false, // Desactiva el uso de los colores de las series para las etiquetas
      },
    },
    grid: {
      strokeDashArray: 5,
      borderColor: textColor,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
      },
      colors: [
        "#FF8000",
        "#2CD9FF",
        "#582CFF",
        "#e3c964",
        "#71d243",
        "#2CD9FF",
      ],
    },
    colors: [
      "#FF8000",
      "#2CD9FF",
      "#582CFF",
      "#e3c964",
      "#71d243",
      "#2CD9FF",
      "#ffffff",
    ],
  });

  const { project, updateProject, updateLoading, tool } = useContext(
    ProjectContext
  );

  const {
    low_Limit,
    high_Limit,
    lineavilityTable,
    cavityBalanceTable,
  } = project;

  useEffect(() => {
    if (showValues) {
      const labelsData = Array.from({ length: 5 }, () => []);

      cavityBalanceTable.cavityWeight.forEach((subArray) => {
        subArray.forEach((item) => {
          if (item.label >= 1 && item.label <= 5) {
            const labelIndex = item.label - 1;
            labelsData[labelIndex].push(parseFloat(item.value) || 0);
          }
        });
      });

      const newDataChart = labelsData.map((data, index) => ({
        name: `Velocidad ${index + 1}`,
        data: data,
      }));
      setDataChart(newDataChart);
    }
  }, [showValues]);

  useEffect(() => {
    if (cavityBalanceTable?.injectionSpeed) {
      const selectOptions = cavityBalanceTable.injectionSpeed.map((item) => ({
        value: item.label.toString(),
        label:
          `${parseFloat(item.value).toFixed(3).toString()}  ${inSecMmsec(
            project?.uom
          )}` || `Valor no definido para la Velicidad ${item.label}`, // Usar el value o un texto por defecto
      }));
      setOptions(selectOptions);
    }
  }, [cavityBalanceTable?.injectionSpeed]);

  useEffect(() => {
    // Crear un array con el color '#c8cfca' repetido tantas veces como elementos en dataChart
    const legendColors = new Array(dataChart.length).fill(textColor);

    // Actualizar el estado de lineChartOptions con los nuevos colores de la leyenda
    setLineChartOptions((prevOptions) => ({
      ...prevOptions,
      legend: {
        ...prevOptions.legend,
        labels: {
          ...prevOptions.legend.labels,
          colors: legendColors,
        },
      },
    }));
  }, [dataChart.length]);

  useEffect(() => {
    // Actualiza las opciones del gráfico cuando cambian las categorías
    setLineChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: categories,
      },
    }));
  }, [categories]);

  const onChangeSelect = async (e) => {
    updateLoading(true);

    const labelIndex = e.value - 1; // Resta 1 porque los arrays comienzan en índice 0

    const newSelectedSpeedData = {
      label: e.value,
      firstStageCavitiesWeight: cavityBalanceTable.firstStageCavitiesWeight[labelIndex]?.value || "",
      injectionSpeed:
        cavityBalanceTable.injectionSpeed[labelIndex]?.value || "",
      maxInjectionPressure:
        cavityBalanceTable.maxInjectionPressure[labelIndex]?.value || "",
      inyectionTime: cavityBalanceTable.inyectionTime[labelIndex]?.value || "",
      runnersWeight: cavityBalanceTable.runnersWeight[labelIndex]?.value || "",
      shootingWeight:
        cavityBalanceTable.shootingWeight[labelIndex]?.value || "",
      lowerWeight: cavityBalanceTable.lowerWeight[labelIndex]?.value || "",
      higherWeight: cavityBalanceTable.higherWeight[labelIndex]?.value || "",
      maxMinDifference:
        cavityBalanceTable.maxMinDifference[labelIndex]?.value || "",
      averageShotWeight:
        cavityBalanceTable.averageShotWeight[labelIndex]?.value || "",
      unbalance: cavityBalanceTable.unbalance[labelIndex]?.value || "",
      cavityWeight: cavityBalanceTable.cavityWeight.map(
        (row) => row[labelIndex]?.value || ""
      ),
    };

    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.speedSelected = newSelectedSpeedData;
        updated.phase = "11";
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  const onSubmitHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "cavityBalance",
      path: "/cavity-balance",
      options: {
        body: cavityBalanceTable,
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();

    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.cavityBalanceTable = res;
        updated.speedSelected = speedSelected;
      })
    )
      .then(async (res) => {
        await updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        const labelsData = Array.from({ length: 5 }, () => []);
        cavityBalanceTable.cavityWeight.forEach((subArray) => {
          subArray.forEach((item) => {
            if (item.label >= 1 && item.label <= 5) {
              const labelIndex = item.label - 1;
              labelsData[labelIndex].push(parseFloat(item.value) || 0);
            }
          });
        });

        const newDataChart = labelsData.map((data, index) => ({
          name: `Velocidad ${index + 1}`,
          data: data,
        }));
        const label1Count = cavityBalanceTable.cavityWeight.filter((subArray) =>
          subArray.some((item) => item.label === 1)
        ).length;

        const newCategories = Array.from({ length: label1Count }, (_, index) =>
          (index + 1).toString()
        );

        setCategories(newCategories);
        setDataChart(newDataChart);
        setShowValues(true);
        updateLoading(false);
      });
  };

  useEffect(() => {
    if (
      low_Limit &&
      high_Limit &&
      lineavilityTable &&
      !selectedLineavility.length
    ) {
      let data = [];
      lineavilityTable.map((item) => {
        if (
          parseInt(low_Limit) <= item.sequence &&
          parseInt(high_Limit) >= item.sequence
        ) {
          const a = { ...item };
          data.push(a);
          //return item;
        }
      });
      setSelectedLineavility(data);
      if (cavityBalanceTable) {
        if (!cavityBalanceTable.injectionSpeed[0]?.value) {
          setCavity(data);
        }
      }
    }
  }, [low_Limit, high_Limit]);

  const onChangeHandler = async (e, label) => {
    label = parseInt(label);

    const { name, value } = e.target;
    const data = await cavityBalanceTable[name].map((item) => {
      if (item.label !== label) {
        return item;
      } else {
        return {
          ...item,
          value,
        };
      }
    });

    const p = {
      ...project,
      cavityBalanceTable: {
        ...cavityBalanceTable,
        [name]: data,
      },
    };
    await updateProject(p);
  };

  function expandCavityWeight(N) {
    const createObject = (index) => {
      if (index === 5) {
        return { label: index + 1, deviation: "" };
      }
      return { label: index + 1, value: "" };
    };

    return new Array(N)
      .fill(null)
      .map(() =>
        new Array(6).fill(null).map((_, index) => createObject(index))
      );
  }

  const setCavity = async (data) => {
    const { numberOfCavities } = tool;
    const updatedCavityWeight = expandCavityWeight(parseInt(numberOfCavities));

    if (data) {
      const one = parseFloat(data[data.length - 1]?.intervalSpeed);
      const five = parseFloat(data[0]?.intervalSpeed);
      const two = (five - one) / 4 + one;
      const three = (five - one) / 4 + two;
      const four = (five - one) / 4 + three;

      const d = {
        ...cavityBalalceInitialState,
        cavityWeight: updatedCavityWeight,
        injectionSpeed: [
          { label: 1, value: one },
          { label: 2, value: two },
          { label: 3, value: three },
          { label: 4, value: four },
          { label: 5, value: five },
        ],
      };
      const p = await DataStore.query(Project, project.id);
      await DataStore.save(
        Project.copyOf(p, (updated) => {
          updated.cavityBalanceTable = d;
        })
      )
        .then(async (res) => {
          await updateProject(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeCavityWeight = async (e, index, ind) => {
    const value = e.target.value;
    const formattedValue = value.startsWith(".") ? `0${value}` : value;

    if (isFloatString(formattedValue) || e.target.value === "") {
      const a = cavityBalanceTable.cavityWeight.map((item, o) => {
        if (o === index) {
          return item.map((it) => {
            if (ind + 1 === it.label) {
              return {
                ...it,
                value: e.target.value,
              };
            } else {
              return it;
            }
          });
        } else {
          return item;
        }
      });
      await updateProject({
        ...project,
        cavityBalanceTable: {
          ...cavityBalanceTable,
          cavityWeight: a,
        },
      });
    }
  };

  return {
    onChangeHandler,
    onChangeCavityWeight,
    dataChart,
    lineChartOptions,
    onSubmitHandler,
    showValues,
    setShowValues,
    onChangeSelect,
    options,
  };
};
