import { Grid, Image } from "@chakra-ui/react";
import headerImage from "../../../../assets/img/collaborators/rediassa/rediasa-header.png";
import headerImage2 from "../../../../assets/img/collaborators/rediassa/rediasa-header-2.png";
import React from "react";

const RediassaHeaderComponent = ({ bgColor }) => {
  return (
    <Grid
      mt={5}
      p={5}
      mx={5}
      borderRadius={15}
      backgroundColor={bgColor}
      justifyItems={"center"}
    >
      <Image
        src={headerImage}
        width={"50%"}
        top="0" // Ajusta la posición superior según sea necesario.
        left="25%" // Centra la imagen ajustando la posición izquierda.
        zIndex="1" // Asegura que esta imagen se superponga a la siguiente.
      />
      <Image
        src={headerImage2}
        width={"10%"}
        position="absolute" // Posiciona la segunda imagen de manera absoluta dentro del Grid.
        top="320px" // Desplaza la segunda imagen hacia abajo para lograr la superposición.
        left="55%" // Centra la imagen ajustando la posición izquierda.
        zIndex="2" // Coloca esta imagen sobre la primera.
      />
    </Grid>
  );
};

export default RediassaHeaderComponent;
