// tp constants

export const tpText1 =
  "Somos profesionistas con amplia experiencia, dando soluciones integrales desde la causa raíz, ofreciendo soluciones mediante capacitación, formación, asesoría y fabricación de herramientas. tenemos una basta experiencia en el sector automotriz y en el sector de Moldeo por Inyección de Plásticos.";
export const tpText2 =
  "No damos cursos, integramos comportamientos y habilidades para optimizar y mejorar los Procesos de Moldeo.";
export const tpText3 =
  "Nuestras capacitaciones cuentan con validez profesional mediante la STPS y constancia DC-3.";
export const tpHead1 = "CAPACITACIÓN";
export const tpHead2 = "ENTRENAMIENTO";
export const tpHead3 = "ASISTENCIA";
export const tpHead4 = "FORMACIÓN";

// gintec constants

export const gintecText1 = "En GINTEC nos dedicamos al diseño y fabricación de partes de máquinas industriales y maquinados con proyectos en la región de Sonora.";
export const gintecText2 =
  "El proceso de fabricación comprende diseño, fabricación, instalación y puesta a punto; con seguimiento de normativas y políticas aplicables para garantizar la seguridad, calidad y satisfacción de nuestros clientes.";
export const gintecText3 = "Disponibilidad 24/7.";
export const gintecHead1 = "DISEÑO";
export const gintecHead2 = "AUTOMATIZACIÓN";
export const gintecHead3 = "INSTALACIÓN";
export const gintecHead4 = "PUESTA A PUNTO";



export const asaText1 = "Somos una empresa de clase mundial con más de 200 años de experiencia en el procesamiento de nuestros productos, con una amplia gama de soluciones para la industria para maximizar la rentabilidad de tu negocio.";
export const asaText2 = "Los compuestos de purga fueron introducidos desde 1990, llegando a ser en la actualidad el líder mundial de compuestos de purga con distribución en más de 70 países.";
export const asaText3 = "";
export const asaHead1 = "PURGA";
export const asaHead2 = "MECÁNICA";
export const asaHead3 = "QUÍMICA";
export const asaHead4 = "CONCENTRADOS";


export const geaText1 = "Ofrecemos una amplia gama de soluciones para la industria del plástico, de empaques, de envases y de embalaje.";
export const geaText2 = "Para nosotros, lo más importante es el cuidado y bienestar de nuestro planeta, así como la responsabilidad social ambiental basada en una correcta comunicación. Por esta razón, estas tres palabras nos Identifican:";
export const geaText3 = "";
export const geaHead1 = "ECOLOGÍA";
export const geaHead2 = "INNOVACIÓN";
export const geaHead3 = "SUSTENTABILIDAD";
export const geaHead4 = "";


export const rediasaText1 = "REDIASA somos un distribuidor autorizado de PIAB ®, dedicados a dar soluciones al mundo de la automatización, desarrollando diseños de EOAT y facilidades de transporte, sujeción y levantamiento de los componentes y productos en los sistemas de producción. ";
export const rediasaText2 = "Contamos con amplia experiencia en el ramo automotriz, industrial y electrónico en la región noroeste de México. ";
export const rediasaText3 = "";
export const rediasaHead1 = "EOAT";
export const rediasaHead2 = "PINZAS";
export const rediasaHead3 = "VENTOSAS";
export const rediasaHead4 = "ACCESORIOS";

export const shelterText1 = "Somos una agencia de Marketing que entrega soluciones de creatividad, imagen y producción a sus socios de negocio a través de un equipo multidisciplinario, talentoso y especializado en su área.";
export const shelterText2 = "Nuestra meta principal es ayudar a las compañías a establecerse en el mercado con nuestra experiencia en estrategias de marketing, permitiéndoles crecer y alcanzar sus objetivos. A través de un enfoque personalizado, nos aseguramos de comprender las necesidades y deseos del cliente, desarrollando estrategias efectivas que generen resultados positivos. Utilizamos las últimas tendencias y herramientas del mercado para crear campañas publicitarias impactantes y exitosas.";
export const shelterText3 = "";
export const shelterHead1 = "MARKETING";
export const shelterHead2 = "DISEÑO";
export const shelterHead3 = "MEDIA MANAGEMENT";
export const shelterHead4 = "STANDS";


export const ldText1 = "LD PLASTIC SOLUTION somos uno de los principales fabricantes de moldes de inyección de plástico del mundo.";
export const ldText2 = "Siempre hemos impulsando el desarrollo, estableciendo puntos de referencia en automoción, moldes de alta precisión, Moldes 2k y distintas áreas de diseño.";
export const ldText3 = "Con el apoyo de Administración de Proyectos, Diseño, Ingeniería, Maquinado y Fabricación de Prototipos trabajamos en conjunto con nuestros clientes para el desarrollo y lanzamiento de la mejor calidad de herramientas de la Industria.";
export const ldHead1 = "MOLDES Y FIXTURES";
export const ldHead2 = "DISEÑO";
export const ldHead3 = "FABRICACIÓN";
export const ldHead4 = "DESARROLLO";
