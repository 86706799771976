const instructions = [
  "1. Establezca el Proceso con llenado de la Primera Etapa de Llenado entre 95-98%.",
  "2. Reduzca el Tiempo de Sostenimiento y la Presión de Sostenimiento a Cero, para que no genere presión ni llenado no requerido.",

  "3. Haga 10 Disparos consecutivos sin ajustar ningún parámetro.",
  "4. Capture los datos de Peso de Disparo, Tiempo de Llenado y Colchón en la tabla.",
  "5. Evalué la confianza de la Unidad de Inyección. Si el porcentaje está sobre el 3%, el equipo requiere Mantenimiento Correctivo y/o Refacciones.",
];

export default instructions;
