import { Button, Grid } from "@chakra-ui/react";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import InputCustomFieldProjectRigthAddon from "../../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { borderColor, textColor } from "../../../utilities/variables";

const LinearityStudyForm = ({
  showChart,
  onSubmitHandler,
  setShowChart,
  setShowTable,
}) => {
  const { project, handleInputChange, isLoading } = useContext(ProjectContext);
  const { correct_Coef, resin_Max_Shear_Rate, lineavilityTable } = project;

  return (
    <Grid
      borderColor={borderColor}
      mx={5}
      p={5}
      mb={5}
      borderWidth={1}
      borderRadius={15}
      gap={6}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          addOn={"%"}
          value={correct_Coef}
          label={"Coeficiente de Corrección"}
          onChange={handleInputChange}
          id={"correct_Coef"}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          addOn={"Ppsi"}
          value={resin_Max_Shear_Rate}
          label={"Máximo Shear Rate de Resina"}
          onChange={handleInputChange}
          id={"resin_Max_Shear_Rate"}
        />
      </Grid>
      <Button
        colorScheme={"facebook"}
        onClick={
          showChart
            ? () => {
                setShowChart(false);
                setShowTable(false);
              }
            :  onSubmitHandler
        }
        isLoading={isLoading}
        disabled={
          !correct_Coef ||
          !resin_Max_Shear_Rate ||
          !lineavilityTable ||
          isLoading
        }
      >
        {showChart ? "Editar" : "Guardar Datos"}
      </Button>
    </Grid>
  );
};

export default LinearityStudyForm;
