import { initialStateHeader } from "../../../utilities/variabled";

export const initialState = {
  header: initialStateHeader,
  intRatio: "",
  realMeltTemp: "",
  immMaxHPress: "",
  maxPlasticPressure: "",
  pressAirShot: "",
  pressToRunner: "",
  pressToGate: "",
  pressToPostGate: "",
  pressTo1stStageEnd: "",
  injectionSpeedTest: "",

  pressLostBarrelToNozzle: "",
  pressLostNozzleToRunner: "",
  pressLostRunnerToGate: "",
  pressLostGateToPostGate: "",
  pressLostPostGateToEnd: "",

  pressAirShotPpsi: "",
  pressToRunnerPpsi: "",
  pressToGatePpsi: "",
  pressToPostGatePpsi: "",
  pressTo1stStageEndPpsi: "",
  lossPressPreform: "",
};

export const keysForForm = [
  "intRatio",
  "realMeltTemp",
  "immMaxHPress",
  "pressAirShot",
  "pressToRunner",
  "pressToGate",
  "pressToPostGate",
  "pressTo1stStageEnd",
  "injectionSpeedTest"

];
export const keysForResult = [
  "pressAirShotPpsi",
  "pressToRunnerPpsi",
  "pressToGatePpsi",
  "pressToPostGatePpsi",
  "pressTo1stStageEndPpsi",
  "maxPlasticPressure",
  "lossPressPreform"
];


