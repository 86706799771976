import { useContext, useState } from "react";
import { initialToolStateRadio } from "../utilities/variables";
import { DataStore } from "aws-amplify/datastore";
import { Tool } from "../../../../models";
import UserContext from "../../../../contexts/UserContext";

export const moldHooks = () => {
  const [tool, setTool] = useState(initialToolStateRadio);

  const { user } = useContext(UserContext);

  const onChangeHandler = (e) => {
    if (e.target.type === "number") {
      const val = parseFloat(e.target.value);
      setTool({ ...tool, [e.target.name]: val });
    } else {
      setTool({ ...tool, [e.target.name]: e.target.value });
    }
  };

  const parseFinalState = (too, values) => {
    if (too.gateForm === "CIRCULAR") {
      too.widthGate = 0;
      too.heightGate = 0;
      too.deepGate = 0;
    } else {
      too.gateDiameter = 0;
    }
    let finalState = {
      ...too,
      ...values,
    };
    let {
      gateDiameter,
      maxMoldEject,
      widthGate,
      heightGate,
      deepGate,
      cavityVolume,
      runnerVolume,
    } = finalState;
    let shootVolume = parseFloat(cavityVolume) + parseFloat(runnerVolume);
    let gateSize =
      too.gateForm === "CIRCULAR"
        ? Math.PI * Math.pow(parseFloat(gateDiameter) / 2, 2)
        : parseFloat(widthGate) * parseFloat(heightGate);
    gateSize = parseFloat(gateSize);
    gateDiameter = parseFloat(gateDiameter);
    maxMoldEject = parseFloat(maxMoldEject);
    widthGate = parseFloat(widthGate);
    heightGate = parseFloat(heightGate);
    deepGate = parseFloat(deepGate);
    finalState = {
      ...finalState,
      gateSize,
      gateDiameter,
      maxMoldEject,
      widthGate,
      heightGate,
      deepGate,
      shootVolume,
      userID: user.id,
    };

    return finalState;
  };

  const onSubmitHandler = async (finalState) => {
    return await DataStore.save(new Tool(finalState));
  };

  return {
    parseFinalState,
    tool,
    onChangeHandler,
    setTool,
    onSubmitHandler,
  };
};
