import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import {useEffect, useRef, useState} from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { DataStore } from "aws-amplify/datastore";
import {LossPressure} from "../../../../../../models";
import { initialState } from "../utilities/variables";
import { useAlert } from "../../../../../../contexts/useAlert";
import html2canvas from "html2canvas";
import {delay} from "../../../hooks/useDelay";
import {uploadData} from "aws-amplify/storage";
import {updateTableSpeeds} from "../utilities/updateTableSpeeds";

export const useIndividualHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();

  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDataTable, setShowDataTable] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const chartRef  = useRef();

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const createImages = async () => {
    await delay(500);
    const canvas = await html2canvas(chartRef.current);

    // Convertir el canvas a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Falló la conversión de canvas a blob"));
        }
      }, "image/png");
    });
  };

  const onChangeHandler = (e) => {
    const { id, value } = e.target;

    if (id.includes(".")) {
      const [outerKey, innerKey] = id.split(".");

      setData((prevData) => ({
        ...prevData,
        [outerKey]: {
          ...prevData[outerKey],
          [innerKey]: value,
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const lineChartDataPressureLoss = [
    {
      name: "Acumulado",
      type: "line",
      data: [
        parseInt(data?.pressLostBarrelToNozzle),
        parseInt(data?.pressLostNozzleToRunner) +
        parseInt(data?.pressLostBarrelToNozzle),
        parseInt(data?.pressLostNozzleToRunner) +
        parseInt(data?.pressLostBarrelToNozzle) +
        parseInt(data?.pressLostRunnerToGate),
        parseInt(data?.pressLostNozzleToRunner) +
        parseInt(data?.pressLostBarrelToNozzle) +
        parseInt(data?.pressLostRunnerToGate) +
        parseInt(data?.pressLostGateToPostGate),
        parseInt(data?.pressLostNozzleToRunner) +
        parseInt(data?.pressLostBarrelToNozzle) +
        parseInt(data?.pressLostRunnerToGate) +
        parseInt(data?.pressLostGateToPostGate) +
        parseInt(data?.pressLostPostGateToEnd),
      ],
    },
    {
      name: "Perdida de Presión",
      type: "column",
      data: [
        parseInt(data?.pressLostBarrelToNozzle),
        parseInt(data?.pressLostNozzleToRunner),
        parseInt(data?.pressLostRunnerToGate),
        parseInt(data?.pressLostGateToPostGate),
        parseInt(data?.pressLostPostGateToEnd),
      ],
    },
  ];

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const restOperation = post({
        path: "/viscosity-study-individual",
        apiName: "viscoscityStudy",
        options: {
          body: {
            ...data,
          },
        },
      });
      const rest = await restOperation.response;
      const body = await rest.body.json();
      console.log(body)
      const updatedTable = updateTableSpeeds(data.table, body.speedIntervalUnit); // Asegúrate de que esto es correcto
      console.log(updatedTable)

      await setData({ ...data, ...body, table: updatedTable });
      setShowDataTable(true)

    } catch (error) {
      console.log(error)
      const e = error.message ? error.message : error;
      showErrorAlert(e);
    }
    setIsLoading(false);
  };

  const onChangeTable = (e) => {
    const { name, value } = e.target;
    const [outerKey, index, innerKey] = name.split(".");

    if (innerKey) {
      // Manejar cambio para propiedades dentro de arrays (ej. data.table[0].speed)
      setData((prevData) => ({
        ...prevData,
        [outerKey]: prevData[outerKey].map((item, idx) =>
          idx.toString() === index ? { ...item, [innerKey]: value } : item
        ),
      }));
    } else {
      // Manejar cambio para propiedades de nivel superior
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    lineChartDataPressureLoss,
    chartRef,
    showDataTable,
    onChangeTable
  };
};
