export const marginBottomDivider = 5;

export const paddingXinputs = 1;

export const initialMachineStateRadio = {
  machineHydraulicSystem: "SI",
  machinPneumaticSystem: "SI",
  machineVacummSystem: "SI",
  uom: "INGLES",
  machineType: "VERTICAL",
};

export const textColor = "gray.600"

export const initialMachineState = {
  machineId: "",
  brand: "",
  year: "",
  serialNumber: "",
  machineTonnage: "",
  pistonDiameter: "",
  screwDiameter: "",
  maxHydraulicPressure: "",
  hopperCapacity: "",
  maxShotSize: "",
  LDratio: "",
  barrelVolume: "",
  maxInjectionSpeed: "",
  machineMaxEjection: "",
  tieBarVerticalDistance: "",
  tieBarHorizontalDistance: "",
  maxOpenClamp: "",
  minStackMoldSize: "",
  maxStackMoldSize: "",
  minMachineEjection: "",
};

export const machineColumnsData = [
  {
    Header: "Unidad de Medida",
    accessor: "uom",
    type: "radio",
  },
  {
    Header: "ID de maquina",
    accessor: "machineId",
    type: "string",
  },
  {
    Header: "Número de Serie",
    accessor: "serialNumber",
    type: "string",
  },
  {
    Header: "Marca",
    accessor: "brand",
    type: "string",
  },
  {
    Header: "Año",
    accessor: "year",
    type: "string",
  },
  /*
  {
    Header: "Fuerza de cierre",
    accessor: "machineTonnage",
    type: "string",
  },
  {
    Header: "tipo de maquina",
    accessor: "machineType",
    type: "radio",
  },

  {
    Header: "Diametro del piston",
    accessor: "pistonDiameter",
    type: "string",
  },
  {
    Header: "Diametro del Husillo",
    accessor: "screwDiameter",
    type: "string",
  },
  {
    Header: "Maxima Presion Hidraulica",
    accessor: "maxHydraulicPressure",
    type: "string",
  },
  {
    Header: "Capacidad de la tolva",
    accessor: "hopperCapacity",
    type: "string",
  },
  {
    Header: "Máxima Longitud de Disparo",
    accessor: "maxShotSize",
    type: "string",
  },
  {
    Header: "Relación L/D de Husillo",
    accessor: "LDratio",
    type: "string",
  },
  {
    Header: "Volumen del barril",
    accessor: "barrelVolume",
    type: "string",
  },
  {
    Header: "Velocidad Maxima de Inyeccion",
    accessor: "maxInjectionSpeed",
    type: "string",
  },
  {
    Header: "Máxima Expulsión de la Máquina",
    accessor: "machineMaxEjection",
    type: "string",
  },
  {
    Header: "Distancia Vertical entre Barras",
    accessor: "tieBarVerticalDistance",
    type: "string",
  },
  {
    Header: "Distancia Horizontal entre Barras",
    accessor: "tieBarHorizontalDistance",
    type: "string",
  },
  {
    Header: "Máxima Apertura de Prensa",
    accessor: "maxOpenClamp",
    type: "string",
  },
  {
    Header: "Mínima Altura de Molde",
    accessor: "minStackMoldSize",
    type: "string",
  },
  {
    Header: "Máxima Altura de Molde",
    accessor: "maxStackMoldSize",
    type: "string",
  },
  {
    Header: "Sistema Hidraulico",
    accessor: "machineHydraulicSystem",
    type: "radio",
  },
  {
    Header: "Sistema Neumatico",
    accessor: "machinPneumaticSystem",
    type: "radio",
  },
  {
    Header: "Sistema Vacio",
    accessor: "machineVacummSystem",
    type: "radio",
  },

   */

];
