import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import CustomGrid from "../../../../projects/individual/components/CustomGrid";
import geaLogo from "../../../../../assets/img/collaborators/gea/gea-logo.jpg";
import mvpsLogo from "../../../../../assets/img/logos/mvpslogo1.PNG";
import HorizontalDivider from "../../../../../components/dividers/HorizontalDivider";
import HeaderTool from "../../components/tool/forms/header-tool/HeaderTool";
import RequirementsForm from "../../components/tool/forms/RequirementsForm";
import AnalisisForm from "../../components/tool/forms/AnalisisForm";
import { useGeaHooks } from "../hooks/useGeaHooks";
import CostAnalisis from "../../components/tool/forms/CostAnalisis";

function GeaTool() {
  const {
    errors,
    setGeaDataInput,
    setErrors,
    geaDataInput,
    handleInputChange,
    onSave,
    id,
    geaPoints,
    onChangeSelect,
  } = useGeaHooks();

  return (
    <>
      <Flex direction="column" pt={{ sm: "110px", lg: "125" }}>
        <CustomGrid mx={20} repeat={4} px={20} alignItems={"center"}>
          <GridItem colSpan={4}>
            <Heading as={"h1"}>EVALUACIÓN DE USO DE ADITIVO</Heading>
          </GridItem>
          <GridItem colSpan={4}>
            <Grid
              templateColumns="repeat(2, 1fr)"
              justifyItems={"center"}
              alignItems={"center"}
              gap={10}
            >
              <Image src={geaLogo} width={"20%"} borderRadius={10} />
              <Image src={mvpsLogo} width={"40%"} borderRadius={10} />
            </Grid>
          </GridItem>
          {/* Primer formulario */}
          <HeaderTool
            onChangeSelect={onChangeSelect}
            handleInputChange={handleInputChange}
            errors={errors}
            setErrors={setErrors}
            geaDataInput={geaDataInput}
            setGeaDataInput={setGeaDataInput}
          />
          {geaDataInput.phase > 1 && (
            <>
              <GridItem colSpan={4} width={"100%"}>
                <HorizontalDivider mt={5} />
              </GridItem>
              <RequirementsForm
                handleInputChange={handleInputChange}
                errors={errors}
                setErrors={setErrors}
                geaDataInput={geaDataInput}
                setGeaDataInput={setGeaDataInput}
              />
            </>
          )}
          {/*  End Primer formulario */}
          {geaDataInput.phase > 2 && (
            <>
              <GridItem colSpan={4} width={"100%"}>
                <HorizontalDivider mt={5} />
              </GridItem>
              <AnalisisForm
                onChangeSelect={onChangeSelect}
                handleInputChange={handleInputChange}
                errors={errors}
                geaDataInput={geaDataInput}
                setGeaDataInput={setGeaDataInput}
              />
              <GridItem colSpan={4} width={"100%"}>
                <HorizontalDivider mt={5} />
              </GridItem>
            </>
          )}
          {geaDataInput.phase > 3 && (
            <>
              <CostAnalisis
                onChangeSelect={onChangeSelect}
                onSave={onSave}
                handleInputChange={handleInputChange}
                errors={errors}
                geaDataInput={geaDataInput}
                setGeaDataInput={setGeaDataInput}
              />
              <GridItem colSpan={4} width={"100%"}>
                <HorizontalDivider mt={5} />
              </GridItem>
              {geaDataInput.phase === 5 && id && (
                <>
                  <GridItem colSpan={4} width={"80%"} mx={10}>
                    <Link
                      href={`/banner/collaborators/gea/pdf/${id}`}
                      isExternal={true}
                    >
                      <Button
                        children={"Ver PDF"}
                        width={"100%"}
                        colorScheme={"whatsapp"}
                      />
                    </Link>
                    <HorizontalDivider mt={10} />
                    <Grid
                      justifyItems={"center"}
                      alignItems={"center"}
                      gap={3}
                      width={"100%"}
                    >
                      <Heading children={"Resumen"} mb={10} />
                      <Heading
                        as={"h3"}
                        fontSize={"xl"}
                        children={"Resultados de Muestra"}
                      />
                      <Text>
                        {`Tu muestra de Einar 201 al ${geaDataInput.geaEinarPercent}% muestra Resultados Satisfactorios en ${geaPoints} de los 23 puntos analizados`}
                      </Text>
                      <HorizontalDivider mt={5} />
                      <Heading
                        as={"h3"}
                        fontSize={"xl"}
                        children={"Eficiencia"}
                      />
                      <Text>{`Se obtuvo una optimiación de tiempo de ${geaDataInput.cycleTimeOptimization} segundos por ciclo.`}</Text>
                      <Text>{`Se obtuvo una optimiación de tiempo de ${parseFloat(
                        geaDataInput.prodTimeOptimization
                      ).toFixed(2)} horas para el lote de producción.`}</Text>
                      <Text>
                        {`Se obtuvo un ahorro de costo por pieza de $ ${parseFloat(
                          geaDataInput.pieceSaveCost
                        ).toFixed(4)} ${geaDataInput.currency}.`}
                      </Text>
                      <Text>
                        {`Se obtuvo un ahorro de costo por lote de $ ${parseFloat(
                          geaDataInput.lotSaveCost
                        ).toFixed(4)} ${geaDataInput.currency}.`}
                      </Text>
                      <Text>
                        {`Inversión requerida $ ${
                          geaDataInput.investmentRequired
                        } ${
                          geaDataInput.currency
                        } de aditivo Einar 201, con un total de ${parseFloat(
                          geaDataInput.costPerKg
                        ).toFixed(4)} kg para el lote de producción.`}
                      </Text>
                      <Text>
                        {`La Recuperación de Capital estimada para este lote de producción es de $ ${geaDataInput.capitalRecovery} ${geaDataInput.currency}, sobre la inversión del aditivo`}
                      </Text>
                      <Text mt={10} fontSize={"md"} fontStyle={"italic"}>
                        "Consulta a un distribuidor autorizado para mejorar la
                        evaluación de tu proceso, volumenes y costos. Envianos
                        los resultados y conoce nuestro software avanzado para
                        obtener mas detalles de tu Recuperacion de Inversión."{" "}
                      </Text>
                    </Grid>
                    <HorizontalDivider mt={5} />
                    <Link
                      href={`/banner/collaborators/gea/pdf/${id}`}
                      isExternal={true}
                    >
                      <Button
                        children={"Ver PDF"}
                        width={"100%"}
                        colorScheme={"whatsapp"}
                      />
                    </Link>
                  </GridItem>
                </>
              )}
            </>
          )}
        </CustomGrid>
      </Flex>
    </>
  );
}

export default GeaTool;
