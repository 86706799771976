export const updateTableSpeeds = (table, speedIntervalUnit) => {
  // Asegurarse de que table es realmente un arreglo
  if (!Array.isArray(table)) {
    console.error("Expected 'table' to be an array, received:", typeof table);
    return [];
  }

  // Crear una copia de la matriz para evitar la mutación directa
  const updatedTable = table.map((item, index) => ({
    ...item,
    // Calcular el nuevo valor de speed basándose en la secuencia inversa
    speed: speedIntervalUnit * (table.length - index),
  }));

  // Retorna la matriz actualizada
  return updatedTable;
};
