import { Helmet } from "react-helmet";
import {useHistory} from "react-router-dom";
import React from "react";

const HelmetComponent = ({ title, description, keywords }) => {
  const history = useHistory()
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={`https://molding-vps.com/${history.location.pathname}`}/>
      <meta name={"description"} content={description}/>
      <meta name="keywords" content={keywords}/>
    </Helmet>
  );
};

export default HelmetComponent;
