import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import {
  areAllFloats,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import PressureLossForm from "./components/PressureLossForm";
import PressureLossChart from "./components/PressureLossChart";
import PressureLossData from "./components/PressureLossData";
import { usePressureLossHooks } from "../../../../../../hooks/pressure-loss/usePressureLossHooks";
import { borderColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";

const EditPressureLoss = () => {
  const { id } = useParams();
  const { project, updateProject, isLoading, onFinish } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    showChart,
    setShowChart,
    onSubmitHandler,
    lineChartOptionsPressureLoss,
    lineChartDataPressureLoss,
  } = usePressureLossHooks();

  const {
    pr_Air_Shot,
    pr_Press_to_Runner,
    pr_Press_to_Gate,
    pr_Press_to_PostGate,
    pr_Press_to_1st_Stage_End,
  } = project;

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <EditBase
        title={"Perdida de Presión"}
        onFinish={onFinish}
        isLoading={isLoading}
        tittleButton={"Terminar"}
        disabled={!showChart}
      >
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <PressureLossForm showChart={showChart} />
            <Grid
              p={5}
              borderWidth={1}
              borderRadius={15}
              gap={6}
              borderColor={borderColor}
            >
              <Button
                colorScheme={"facebook"}
                isLoading={isLoading}
                onClick={
                  showChart
                    ? () => setShowChart(false)
                    : () => onSubmitHandler()
                }
                disabled={
                  !areAllFloats([
                    pr_Air_Shot,
                    pr_Press_to_Runner,
                    pr_Press_to_Gate,
                    pr_Press_to_PostGate,
                    pr_Press_to_1st_Stage_End,
                  ]) || isLoading
                }
              >
                <Flex direction={"row"}>
                  {showChart ? "Editar" : "Calcular y Graficar"}
                </Flex>
              </Button>
              {showChart && (
                <PressureLossChart
                  lineChartDataPressureLoss={lineChartDataPressureLoss}
                  lineChartOptionsPressureLoss={lineChartOptionsPressureLoss}
                />
              )}
            </Grid>
          </Grid>
        </GridItem>

        {showChart && (
          <GridItem colSpan={4}>
            <PressureLossData />
          </GridItem>
        )}
      </EditBase>
    </>
  );
};

export default EditPressureLoss;
