import Swal from "sweetalert2";

const ConditionalsAlert = (icon, text) => {
  let title = "LISTO";
  if (icon === "warning") {
    title = "ADVERTENCIA";
  } else if (icon === "error") {
    title = "ERROR";
  }

  text += "<li>Cambios Guardados Correctamente.</li>";
  const footer =
    "MVPSⓇ desarrolla experimentos y funciones requeridas para el desarrollo de tus procesos de moldeo. Los parámetros ingresados, las desiciones y el correcto uso y mantenimiento de los equipos intervenidos son responsabilidad del usuario";
  Swal.fire({
    title: title,
    html: text,
    icon: icon, // 'success' o 'error'
    confirmButtonText: "Aceptar",
    footer: footer,
  });
};

export default ConditionalsAlert;
