import { initialStateHeader } from "../../../utilities/variabled";

export const initialState = {
  header: initialStateHeader,
  realMeltTemp: "",
  meltTempMin: "",
  meltTempMax: "",
  realNozzleTemp: "",
  nozzleTempMin: "",
  nozzleTempMax: "",
  realFrontTemp: "",
  frontTempMin: "",
  frontTempMax: "",
  realMidTemp: "",
  midTempMin: "",
  midTempMax: "",
  realBackTemp: "",
  backTempMin: "",
  backTempMax: "",
  chartImage: ""
};

export const keysForForm = [
  "realMeltTemp",
  "meltTempMin",
  "meltTempMax",
  "realNozzleTemp",
  "nozzleTempMin",
  "nozzleTempMax",
  "realFrontTemp",
  "frontTempMin",
  "frontTempMax",
  "realMidTemp",
  "midTempMin",
  "midTempMax",
  "realBackTemp",
  "backTempMin",
  "backTempMax",
];
