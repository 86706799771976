export function validateResultsAndCheckValveFilled(state) {
  // Validar las variables que terminan en "Results"
  const areResultsFilled = Object.keys(state).filter(key => key.endsWith("Results")).every(key => {
    const result = state[key];
    return Object.values(result).every(value => value !== "");
  });

  // Validar que checkValveRepeatibility no esté vacía
  const isCheckValveRepeatibilityFilled = state.checkValveRepeatibility !== "";

  // Devolver true si todas las condiciones se cumplen
  return areResultsFilled && isCheckValveRepeatibilityFilled;
}
