import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import {
  FormControl,
  FormLabel, Grid,
  GridItem,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import CustomGrid from "../../../components/CustomGrid";
import CustomInput from "../../../components/CustomInput";
import React, { useContext, useEffect, useState } from "react";
import {
  isFloatString,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { DataStore } from "aws-amplify/datastore";
import { IntensificationRatio } from "../../../../../../models";
import { initialState, keysForForm } from "../utilities/variables";
import UserContext from "../../../../../../contexts/UserContext";
import { useAlert } from "../../../../../../contexts/useAlert";
import IndividualFooter from "../../../components/IndividualFooter";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "../utilities/description";
import instructions from "../utilities/instructions";
import HorizontalDivider from "../../../../../../components/dividers/HorizontalDivider";

const IntensificationRatioForm = ({ setIsDisabled }) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();
  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  const isComplete = !isObjectEmpty(result);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    if (isFloatString(value)) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const restOperation = post({
        apiName: "intensificationRatioInd",
        path: "/intensification-ratio-ind",
        options: {
          body: {
            maxHydraulicPressure: data.maxInjectPressure,
            pistonDiameter: data.pistonDiameter,
            screwDiameter: data.screwDiameter,
          },
        },
      });
      const rest = await restOperation.response;
      const body = await rest.body.json();

      let { pistonArea, intRatio, maxPlasticPressure, screwArea } = body;

      const response = await DataStore.save(
        new IntensificationRatio({
          ...data,
          pistonArea: pistonArea.toString(),
          intensificationRatio: intRatio.toString(),
          maxPlasticPressure: maxPlasticPressure.toString(),
          screwArea: screwArea.toString(),
          uom,
          toolName,
          machineName,
          resinName,
          productName,
          userID: user.id,
        })
      );
      setResult(response);

      setData({
        ...data,
        pistonArea,
        screwArea,
        intensificationRatio: intRatio,
        maxPlasticPressure,
      });
      showSuccessAlert();
    } catch (error) {
      showErrorAlert({ error });
    }
    setIsLoading(false);
  };

  const isDisabled = !useVerifyForm(data, keysForForm);

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <HorizontalDivider mt={5} />
        <Grid templateColumns="repeat(3, 1fr)" mb={5} gap={10}>
          <FormControl>
            <FormLabel>Máxima Presión de Inyección</FormLabel>
            <InputGroup>
              <CustomInput
                disabled={isComplete}
                id={"maxInjectPressure"}
                onChange={(e) => onChangeHandler(e)}
                value={data?.maxInjectPressure}
                placeholder={""}
                addOnText={uom === "INGLES" ? "Hpsi" : "Hbar"}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Diámetro del Pistón</FormLabel>
            <InputGroup>
              <CustomInput
                disabled={isComplete}
                id={"pistonDiameter"}
                onChange={(e) => onChangeHandler(e)}
                value={data?.pistonDiameter}
                placeholder={""}
                addOnText={uom === "INGLES" ? "in" : "mm"}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Diámetro del Tornillo</FormLabel>
            <InputGroup>
              <CustomInput
                disabled={isComplete}
                id={"screwDiameter"}
                onChange={(e) => onChangeHandler(e)}
                value={data?.screwDiameter}
                placeholder={""}
                addOnText={uom === "INGLES" ? "in" : "mm"}
              />
            </InputGroup>
          </FormControl>
        </Grid>
        {!isObjectEmpty(result) && (
          <Grid templateColumns="repeat(4, 1fr)" gap={10} my={5}>
            <GridItem>
              <Text>Área del Pistón</Text>
              <Text>
                {`${parseFloat(result?.pistonArea).toFixed(3)} ${
                  uom === "INGLES" ? "in²" : "mm²"
                }`}{" "}
              </Text>
            </GridItem>
            <GridItem>
              <Text>Área del Tornillo</Text>
              <Text>{`${parseFloat(result?.screwArea).toFixed(3)} ${
                uom === "INGLES" ? "in²" : "mm²"
              }`}</Text>
            </GridItem>
            <GridItem>
              <Text>Razón de Intensificación</Text>
              <Text>{`${parseInt(result?.intensificationRatio)} ${
                uom === "INGLES" ? ":1" : ":1"
              }`}</Text>
            </GridItem>
            <GridItem>
              <Text>Máxima Presión Plastica</Text>
              <Text>{`${parseFloat(result?.maxPlasticPressure).toFixed(3)} ${
                uom === "INGLES" ? "Ppsi" : "Pbar"
              }`}</Text>
            </GridItem>
          </Grid>
        )}
        <IndividualFooter
          result={result}
          url={"intensification-ratio"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={isDisabled}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IntensificationRatioForm;
