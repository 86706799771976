import React, { useEffect, useState } from "react";
import routes from "routes.js";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import { GoChevronDown } from "react-icons/go";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";

export default function AuthNavbar(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Verificar el estado de autenticación al montar el componente
    checkAuthState();

    // Escuchar los cambios de estado de autenticación
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        setIsAuthenticated(true);
      } else if (payload.event === "signOut") {
        setIsAuthenticated(false);
      }
    });
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };
  const {
    isOpen: isOpenPages,
    onOpen: onOpenPages,
    onClose: onClosePages,
  } = useDisclosure();

  const {
    isOpen: isOpenAuth,
    onOpen: onOpenAuth,
    onClose: onCloseAuth,
  } = useDisclosure();

  const {
    isOpen: isOpenApplication,
    onOpen: onOpenApplication,
    onClose: onCloseApplication,
  } = useDisclosure();

  const {
    isOpen: isOpenEcommerce,
    onOpen: onOpenEcommerce,
    onClose: onCloseEcommerce,
  } = useDisclosure();
  const { logo, logoText, secondary, ...rest } = props;

  let authObject = {};
  routes.forEach((route) => {
    if (route.items) {
      authObject = route.items.find((link) => link.name === "Mi Perfil");
    }
  });

  let applicationsObject = {};
  routes.forEach((route) => {
    if (route.items) {
      applicationsObject = route.items.find(
        (link) => link.name === "Mis Moldes"
      );
    }
  });

  let ecommerceObject = {};
  for (const route of routes) {
    if (route.items) {
      const found = route.items.find((link) => link.name === "Proyectos");
      if (found) {
        ecommerceObject = found;
        break; // Sale del bucle una vez que se encuentra el elemento
      }
    }
  }

  let extraArr = [];
  routes.forEach((route) => {
    route.items?.forEach((item) => {
      if (item.items && item.name === "Pages") {
        extraArr = item.items.filter((link) => !link.collapse);
      }
    });
  });

  let mainText = "#fff";
  let navbarBg =
    "linear-gradient(123.64deg, rgba(255, 255, 255, 0) -22.38%, rgba(255, 255, 255, 0.039) 70.38%)";
  let navbarBorder = "rgba(226, 232, 240, 0.3)";
  let navbarShadow = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none"
  );
  let navbarFilter = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
  );
  let navbarBackdrop = "blur(42px)";
  let bgButton = "#582CFF";
  let navbarPosition = "fixed";
  let colorButton = "#fff";
  if (props.secondary === true) {
    navbarBg = "none";
    navbarBorder = "none";
    navbarShadow = "initial";
    navbarFilter = "initial";
    navbarBackdrop = "none";
    mainText = "#fff";
    navbarPosition = "absolute";
  }
  const createPagesLinks = (routes) => {
    return routes.map((link) => {
      if (link.authIcon) {
        return (
          <Stack direction="column">
            <Stack
              direction="row"
              spacing="6px"
              align="center"
              mb="6px"
              cursor="default"
            >
              <IconBox bg="brand.200" color="white" h="30px" w="30px">
                {link.authIcon}
              </IconBox>
              <Text fontWeight="bold" fontSize="sm" color="#fff">
                {link.name}
              </Text>
            </Stack>
            {createPagesLinks(link.items)}
          </Stack>
        );
      } else {
        if (link.component) {
          const isCollaborator = link.path.includes("collaborators");
          const newLayout = isCollaborator ? "/banner" : link.layout;
          return (
            <NavLink
              to={
                isAuthenticated
                  ? link.layout + link.path
                  : newLayout + link.path
              }
            >
              <MenuItem
                ps="36px"
                py="0px"
                _hover={{ boxShadow: "none", bg: "none" }}
                borderRadius="12px"
              >
                <Text color="gray.400" fontSize="sm" fontWeight="500">
                  {link.name}
                </Text>
              </MenuItem>
            </NavLink>
          );
        } else {
          return <>{createPagesLinks(link.items)}</>;
        }
      }
    });
  };

  const createAuthLinks = (routes) => {
    return routes.map((link) => {
      if (link.authIcon) {
        return (
          <Stack direction="column">
            <Stack
              direction="row"
              spacing="6px"
              align="center"
              mb="6px"
              cursor="default"
            >
              <IconBox bg="brand.200" color="white" h="30px" w="30px">
                {link.authIcon}
              </IconBox>
              <Text fontWeight="700" fontSize="sm" color="#fff">
                {link.name}
              </Text>
            </Stack>
            {createAuthLinks(link.items)}
          </Stack>
        );
      } else {
        return (
          <NavLink to={link.layout + link.path}>
            <MenuItem
              ps="36px"
              py="0px"
              _hover={{ boxShadow: "none", bg: "none" }}
              borderRadius="12px"
            >
              <Text color="gray.400" fontSize="sm" fontWeight="500">
                {link.name}
              </Text>
            </MenuItem>
          </NavLink>
        );
      }
    });
  };

  const createEcommerceLinks = (routes) => {
    return routes?.map((link) => {
      if (link.hidden) {
        return;
      }
      if (link.authIcon) {
        return (
          <Stack direction="column">
            <Stack
              direction="row"
              spacing="6px"
              align="center"
              mb="6px"
              cursor="default"
            >
              <IconBox bg="brand.200" color="white" h="30px" w="30px">
                {link.authIcon}
              </IconBox>
              <Text fontWeight="bold" fontSize="sm" color="#fff">
                {link.name}
              </Text>
            </Stack>
            {createPagesLinks(link.items)}
          </Stack>
        );
      } else {
        if (link.component) {
          return (
            <NavLink to={link.layout + link.path}>
              <MenuItem
                ps="36px"
                py="0px"
                _hover={{ boxShadow: "none", bg: "none" }}
                borderRadius="12px"
              >
                <Text color="gray.400" fontSize="sm" fontWeight="500">
                  {link.name}
                </Text>
              </MenuItem>
            </NavLink>
          );
        } else {
          return <>{createPagesLinks(link.items)}</>;
        }
      }
    });
  };

  var brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/`}
      target="_blank"
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Box
        bg="linear-gradient(97.89deg, #FFFFFF 70.67%, rgba(117, 122, 140, 0) 108.55%)"
        bgClip="text"
        ms="12px"
      >
        <Text
          fontSize="sm"
          fontWeight="500"
          color="transparent"
          letterSpacing="0.18em"
        >
          {logoText}
        </Text>
      </Box>
    </Link>
  );

  const linksAuth = (
    <HStack display={{ sm: "none", lg: "flex" }} spacing="17px">
      <Stack
        direction="row"
        spacing="4px"
        align="center"
        color="#fff"
        fontWeight="bold"
        onMouseEnter={onOpenPages}
        onMouseLeave={onClosePages}
        cursor="pointer"
        position="relative"
      >
        <Text
          fontSize="10px"
          fontWeight="500"
          letterSpacing=".5px"
          color={mainText}
        >
          PAGINAS
        </Text>
        <Icon
          as={GoChevronDown}
          color={mainText}
          w="11px"
          h="11px"
          fontWeight="2000"
        />
        <Menu isOpen={isOpenPages}>
          <MenuList
            border="none"
            bg="#060B28"
            p="22px"
            minW="550px"
            cursor="default"
            borderRadius="15px"
            position="absolute"
            top="30px"
            left="-10px"
          >
            <Grid templateColumns="repeat(3, 1fr)" gap="16px">
              {createPagesLinks(routes)}
            </Grid>
          </MenuList>
        </Menu>
      </Stack>
      <Stack
        direction="row"
        spacing="4px"
        align="center"
        color="#fff"
        fontWeight="bold"
        onMouseEnter={onOpenEcommerce}
        onMouseLeave={onCloseEcommerce}
        cursor="pointer"
        position="relative"
      >
        <Text
          fontSize="10px"
          fontWeight="500"
          letterSpacing=".5px"
          color={mainText}
        >
          MIS PROYECTOS
        </Text>
        <Icon
          as={GoChevronDown}
          color={mainText}
          w="11px"
          h="11px"
          fontWeight="2000"
        />
        <Menu isOpen={isOpenEcommerce}>
          <MenuList
            border="none"
            bg="#060B28"
            p="22px"
            minW="350px"
            cursor="default"
            borderRadius="15px"
            position="absolute"
            top="30px"
            left="-10px"
          >
            <Grid templateColumns="repeat(2, 1fr)" gap="16px">
              {createEcommerceLinks(ecommerceObject?.items)}
            </Grid>
          </MenuList>
        </Menu>
      </Stack>
    </HStack>
  );
  return (
    <Flex
      position={navbarPosition}
      top="16px"
      left="50%"
      transform="translate(-50%, 0px)"
      background={navbarBg}
      border={props.secondary ? "none" : "2px solid"}
      borderColor={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderRadius="15px"
      px="16px"
      py="22px"
      mx="auto"
      width="1044px"
      maxW="90%"
      alignItems="center"
    >
      <Flex w="100%" justifyContent={{ sm: "start", lg: "space-between" }}>
        {brand}
        <Box ms={{ base: "auto", lg: "0px" }} display={{ lg: "none" }}>
          <SidebarResponsive
            logoText={props.logoText}
            secondary={props.secondary}
            routes={routes}
            {...rest}
          />
        </Box>
        {linksAuth}
        <Link href="/admin/profile/my-profile">
          <Button
            color="blue"
            fontSize="15px"
            fontWeight="bold"
            borderRadius="35px"
            px="30px"
            display={{
              sm: "none",
              lg: "flex",
            }}
          >
            {isAuthenticated ? "Dashboard" : "Iniciar Sesion"}
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
