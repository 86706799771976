import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import {useContext, useEffect, useState} from "react";
import {
  isFloatString,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { DataStore } from "aws-amplify/datastore";
import {  HooperCapacity } from "../../../../../../models";
import { initialState } from "../utilities/variables";
import { useAlert } from "../../../../../../contexts/useAlert";
import UserContext from "../../../../../../contexts/UserContext";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";

export const useIndividualHooperCapacityHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();

  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const {user} = useContext(UserContext)


  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;

    // Verifica si el ID tiene un formato que indica una propiedad anidada (por ejemplo, 'dryTime.min')
    if (id.includes(".")) {
      const [outerKey, innerKey] = id.split(".");

      // Actualiza solo la propiedad anidada relevante dentro del objeto
      setData((prevData) => ({
        ...prevData,
        [outerKey]: {
          ...prevData[outerKey],
          [innerKey]: value,
        },
      }));
    } else {
      // Maneja la actualización para propiedades no anidadas
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const restOperation = post({
        path: "/hopper-capacity-individual",
        apiName: "hooperCapacity",
        options: {
          body: {
            ...data,
          },
        },
      });
      const rest = await restOperation.response;
      const body = await rest.body.json();

      const response = await DataStore.save(
        new HooperCapacity({
          ...data,
          ...body,
          userID: user.id
        })
      );
      setResult(response);
      let icon = "success"
      let text = ""
      if (response.reloadTime <= 10) {

        icon = "warning"
        text = "El tiempo de recarga es demasiado corto y puede tener problemas de abastecimiento."
      } else if (response.reloadTime >= 60) {

        icon = "warning"
        text = "El tiempo de recarga es demasiado alto y puede tener problemas de perdida de secado de resina o alimentación a la garganta."
      }
      conditionalsAlert(icon, text)
    } catch (error) {
      showErrorAlert(error);
    }
    setIsLoading(false);
  };

  const checkIsValidForm = () => {
    const state = data;
    const keysToBeFilled = [
      "header",
      "dryerID",
      "scrapRisk",
      "prodQuantity",
      "dryerCapacity",
      "dryTempMin",
      "dryTime",
      "maxMoisture",
      "numberOfCavities",
      "shotWeight100",
      "cycleTime",
    ];
    const keysToBeEmpty = [
      "pcsPerHour",
      "cycleTimeMin",
      "dryTimeMin",
      "reqShotsForProd",
      "dryerCapacityOz",
      "reqResinForProd",
      "resinFlowPerMin",
      "productionDuration",
      "resinTroughDryer",
      "dryerSupplying",
      "minDryerReload",
      "reloadsReq",
    ];

    // Verificar si las claves que deben estar llenas lo están
    for (const key of keysToBeFilled) {
      if (!state[key] || state[key] === "") {
        return false; // Retorna falso si alguna clave necesaria está vacía
      }
    }

    // Verificar si las claves que deben estar vacías lo están
    for (const key of keysToBeEmpty) {
      if (state[key]) {
        return false; // Retorna falso si alguna clave que debe estar vacía no lo está
      }
    }

    return true; // Retorna verdadero si todas las condiciones se cumplen
  };

  return {
    data,
    uom,
    setData,
    header,
    machineName,
    productName,
    resinName,
    toolName,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    checkIsValidForm,
  };
};
