import React, { useContext, useEffect } from "react";
import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Select from "react-select";
import LineChart from "../../../../../../components/Charts/LineChart";
import DataLinearityStudy from "./components/DataLinearityStudy";
import LinearityStudyForm from "./components/LinearityStudyForm";
import { useLinearityStudyHooks } from "./hooks/useLinearityStudyHooks";
import LinearityStudyDataTable from "./components/LinearityStudyDataTable";
import { selectOptions } from "./utilities/variables";
import { borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import instructions from "./utilities/instructions";
import description from "./utilities/description";

const EditLinearityStudy = () => {
  const { id } = useParams();
  const { project, updateProject, isLoading, handleInputChange } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    chartOptions,
    chartData,
    onSubmitHandler,
    onChangeLowLimitHandler,
    setShowChart,
    showChart,
    showTable,
    setShowTable,
  } = useLinearityStudyHooks();

  const { low_Limit, high_Limit } = project;

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        keywords={metaKeywords}
        title={metaTitle}
      />
      <EditBase
        title={"Estudio de Linealidad"}
        disabled={!low_Limit || !high_Limit}
      >
        <GridItem colSpan={4}>
          <DescriptionAndInstructionAcordeon instructions={instructions} description={description} />
          <DataLinearityStudy />
          <LinearityStudyForm
            setShowTable={setShowTable}
            showChart={showChart}
            setShowChart={setShowChart}
            onSubmitHandler={onSubmitHandler}
          />
          {showTable && (
            <>
              <Grid
                borderColor={borderColor}
                gap={6}
                mx={5}
                p={5}
                mb={5}
                borderWidth={1}
                borderRadius={15}
              >
                <LinearityStudyDataTable />
              </Grid>
            </>
          )}

          {showChart && (
            <>
              <Grid
                borderColor={borderColor}
                borderWidth={1}
                borderRadius={15}
                mx={5}
                p={5}
              >
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <FormControl>
                    <FormLabel color={textColor}>
                      Seleccion de Rango Mínimo
                    </FormLabel>
                    <Select
                      defaultValue={low_Limit}
                      value={selectOptions[parseInt(low_Limit) - 1]}
                      onChange={(e) => onChangeLowLimitHandler(e, "min")}
                      options={selectOptions}
                      placeholder={"Selecciona un Rango Mínimo"}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel color={textColor}>
                      Seleccion de Rango Máximo
                    </FormLabel>
                    <Select
                      value={selectOptions[parseInt(high_Limit) - 1]}
                      defaultValue={high_Limit}
                      onChange={(e) => onChangeLowLimitHandler(e, "max")}
                      options={selectOptions}
                      placeholder={"Selecciona un Rango Máximo"}
                    />
                  </FormControl>
                </Grid>

                <Flex
                  mt={10}
                  color={textColor}
                  direction={"column"}
                  w={"80%"}
                  minHeight={"300px"}
                  alignSelf={"center"}
                >
                  <Flex
                    color={textColor}
                    direction={"column"}
                    w={"100%"}
                    borderColor={"green"}
                    position={"relative"}
                  >
                    <Box w="100%" minH={{ sm: "450px" }}>
                      <LineChart
                        chartOptions={chartOptions}
                        chartData={chartData}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Grid>
            </>
          )}
        </GridItem>
      </EditBase>
    </>
  );
};

export default EditLinearityStudy;
