import { Button, Grid, Image, Link, Text } from "@chakra-ui/react";

const AsaCleanBodyComponent1 = ({ content }) => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} justifyItems={"center"} my={10} justifyContent={"center"} alignItems={"center"}>
      <Grid position="relative">
        {" "}
        {/* Contenedor de la imagen con posición relativa */}
        <Image width={"auto"} src={content.image} borderRadius={15} />
        {/* Botón con posición absoluta */}
        <Link href={content.url} isExternal>
          <Button
            position="absolute" // Posición absoluta para el botón
            top="90%" // Centrado vertical
            left="20%" // Centrado horizontal
            transform="translate(-50%, -50%)" // Ajuste fino para centrar exactamente
            colorScheme="red"
          >
            Conozca Más
          </Button>
        </Link>
      </Grid>
      <Grid>
        {content && (
          <>
            <Text fontWeight={"bold"} fontSize={"2xl"}>
              {content.title}
            </Text>
            <Text>{content.content}</Text>
            <Text fontWeight={"bold"} fontSize={"2xl"}>
              {content.footer}
            </Text>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AsaCleanBodyComponent1;
