import { Box, Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useWindowProcessHooks } from "./hooks/useWindowProcessHooks";
import LimitData from "./components/data/LimitData";
import DoeTable from "./components/tables/DoeTable";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import ResinData from "./components/data/ResinData";
import LimitForm from "./components/forms/LimitForm";
import DimensionTestTable from "./components/tables/DimensionTestTable";
import { validarDoeTable } from "./components/tables/helpers/validateDoeTable";
import { updateDimension } from "./components/tables/helpers/updateDimensionData";
import { Project } from "../../../../../../models";
import useColorScale from "./hooks/useColorScale";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";

const EditWindowProcess = () => {
  const [
    minimumAcceptablePercentage,
    setMinimumAcceptablePercentage,
  ] = useState("");

  const [showWindowProcess, setShowWindowProcess] = useState(false);

  const { id } = useParams();
  const { project, updateProject, isLoading, updateLoading } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);

  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  useEffect(() => {
    if (minimumAcceptablePercentage !== project.minimumAcceptablePercentage) {
      setMinimumAcceptablePercentage(project.minimumAcceptablePercentage);
    }
  }, [project.minimumAcceptablePercentage]);

  const { showData, onSubmitHandler, setShowData } = useWindowProcessHooks();
  const { validateDimensionTestTableData } = updateDimension();
  const colorMap = useColorScale();

  const order = [
    "MIN-MAX",
    "NOM-MAX",
    "MAX-MAX",
    "MIN-NOM",
    "NOM-NOM",
    "MAX-NOM",
    "MIN-MIN",
    "NOM-MIN",
    "MAX-MIN",
  ];

  const handleClick = async () => {
    updateLoading(true);
    const body = { project };
    const restOperation = post({
      apiName: "windowProcess",
      path: "/window-process-final",
      options: {
        body,
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.windowProcess = res.windowProcess;
        updated.minimumAcceptablePercentage = minimumAcceptablePercentage;
        updated.phase = "16";
      })
    )
      .then(async (res) => {
        updateProject(res);
        setShowWindowProcess(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        keywords={metaKeywords}
        title={metaTitle}
      />
      <EditBase title={"Ventana de Proceso"} tittleButton={"Terminar"}>
        <GridItem colSpan={4}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <ResinData />
          <LimitForm
            showData={showData}
            setShowData={setShowData}
            onSubmitHandler={onSubmitHandler}
          />
          {showData && (
            <Grid color={textColor} gap={6}>
              <LimitData />
              <DoeTable />
              <DimensionTestTable />
              <Grid
                mx={5}
                p={5}
                borderWidth={1}
                borderRadius={15}
                gap={6}
                templateColumns="repeat(2, 1fr)"
                justifyItems={"center"}
                alignItems={"center"}
                borderColor={borderColor}
              >
                <InputCustomFieldProjectRigthAddon
                  color={textColor}
                  borderColor={borderColor}
                  addOn={"%"}
                  label={"Minimo Aceptable"}
                  onChange={(e) =>
                    setMinimumAcceptablePercentage(e.target.value)
                  }
                  value={minimumAcceptablePercentage}
                />
                <Button
                  colorScheme={"facebook"}
                  onClick={handleClick}
                  disabled={
                    validarDoeTable(project) ||
                    validateDimensionTestTableData() ||
                    !minimumAcceptablePercentage
                  }
                >
                  Generar Ventana de Proceso
                </Button>
              </Grid>
            </Grid>
          )}

          {project && project.windowProcess && showWindowProcess && (
            <Grid
              mt={5}
              mx={5}
              templateColumns="repeat(3, 1fr)"
              gap={1}
              templateRows={"repeat(3, 1fr)"}
            >
              {order.map((key) => {
                return (
                  <Box
                    w="100%"
                    h="100%"
                    key={key}
                    bg={colorMap[key]}
                    p={2}
                    borderTopRightRadius={key === "MAX-MAX" ? 15 : ""}
                    borderTopLeftRadius={key === "MIN-MAX" ? 15 : ""}
                    borderBottomLeftRadius={key === "MIN-MIN" ? 15 : ""}
                    borderBottomRightRadius={key === "MAX-MIN" ? 15 : ""}
                  >
                    <Flex
                      color={colorMap[key] === "red" ? "gray.200" : "gray.700"}
                      direction={"column"}
                      h="100%"
                      justifyContent={"center"}
                      alignItems="center"
                    >
                      <Text fontSize="lg" fontWeight="bold">
                        {key}
                      </Text>
                      <Text fontSize="4xl">{project.windowProcess[key]} %</Text>
                    </Flex>
                  </Box>
                );
              })}
            </Grid>
          )}
        </GridItem>
      </EditBase>
    </>
  );
};

export default EditWindowProcess;
