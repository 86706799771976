export const cavityBalalceInitialState = {
  injectionSpeed: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  maxInjectionPressure: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  inyectionTime: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  runnersWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  cavityWeight: [
    [
      { label: 1, value: "" },
      { label: 2, value: "" },
      { label: 3, value: "" },
      { label: 4, value: "" },
      { label: 5, value: "" },
      { label: 6, desviation: "" },
    ],
    [
      { label: 1, value: "" },
      { label: 2, value: "" },
      { label: 3, value: "" },
      { label: 4, value: "" },
      { label: 5, value: "" },
      { label: 6, desviation: "" },
    ],
  ],
  shootingWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  lowerWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  higherWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  maxMinDifference: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  averageShotWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  unbalance: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
};

const selectedSpeedData = {
  label: "",
  injectionSpeed: "",
  maxInjectionPressure: "",
  inyectionTime: "",
  runnersWeight: "",
  shootingWeight: "",
  lowerWeight: "",
  higherWeight: "",
  maxMinDifference: "",
  averageShotWeight: "",
  unbalance: "",
  cavityWeight: ["", ""],
};
