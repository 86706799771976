export const formaOptions = [
  { value: "CIRCULO", label: "Círculo" },
  { value: "RECTANGULO", label: "Rectángulo" },
  { value: "TRAPECIO", label: "Trapecio" },
  { value: "TRIANGULO", label: "Triángulo" },
];

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "gray.700",
    backgroundColor: state.isSelected ? "gray.700" : "gray.500",
  }),
  textColor: "gray.600"
};

export const textColor = "gray.600";