import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { ShotSize } from "../../../../../../../models";
import { Image, PDFViewer, Text, View } from "@react-pdf/renderer";
import "moment/locale/es";
import PdfBase from "../../../../components/Pdf/PdfBase";
import PdfEntries from "../../../../components/Pdf/PdfEntries";

import { dataEntries1, dataEntries2 } from "./utilities/dataEntries";
import { dataOutputs1, dataOutputs2 } from "./utilities/dataOutputs";
import { delay } from "../../../../hooks/useDelay";
import { getUrl } from "aws-amplify/storage";
import usePdfStyles from "../../../../hooks/usePdfstyles";
const IndividualShotSizePdf = () => {
  const { id } = useParams();
  const [href, setHref] = useState("");

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(ShotSize, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    url();
  }, [data]);

  const url = async () => {
    await delay(1000);
    const u = await getUrl({
      key: data?.chartImage,
      options: {
        accessLevel: "guest", // can be 'private', 'protected', or 'guest' but defaults to `guest`
      },
    });
    setHref(u.url.href);
  };

  const styles = usePdfStyles();

  const MyDocument = ({ data, chartImageUri }) => (
    <PdfBase data={data} name={"Shot Size"} image={chartImageUri}>
      <PdfEntries
        title={"Entradas"}
        column1={dataEntries1(data)}
        column2={dataEntries2(data)}
      />

      <PdfEntries
        title={"Salidas"}
        column1={dataOutputs1(data)}
        column2={dataOutputs2(data)}
      />
      <Text style={styles.header}>{"Graficas"}</Text>
      <View style={[styles.view, { justifyContent: "center" }]}>
        <Image src={href} style={[styles.image, {marginVertical: 15,}]} />
      </View>
    </PdfBase>
  );

  return (
    <>
      <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
        <MyDocument data={data} />
      </PDFViewer>
    </>
  );
};

export default IndividualShotSizePdf;
