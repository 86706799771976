import React from "react";
import IndividualBase from "../../components/IndividualBase";
import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IndividualHooperCapacityForm from "./components/IndividualHooperCapacityForm";
import HelmetComponent from "../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import { GridItem } from "@chakra-ui/react";
import CustomGrid from "../../components/CustomGrid";
import instructions from "./utilities/instructions";
import description from "./utilities/description";
const IndividualHooperCapacity = () => {
  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <HeaderProvider>
        <GridItem width={"100%"} mt={"120px"}>
          <CustomGrid>
            <IndividualBase instructions={instructions} description={description} title={"Capacidad de la Tolva"}>
              <IndividualHooperCapacityForm />
            </IndividualBase>
          </CustomGrid>
        </GridItem>
      </HeaderProvider>
    </>
  );
};

export default IndividualHooperCapacity;
