//import bgBody from "assets/img/background-body-admin.png";

export const bgBanner = {
  styles: {
    global: (props) => ({
      body: {
        bgColor: "#203764",

        bgSize: "cover",
        bgPosition: "center center",
      },
    }),
  },
};
