import styled from "styled-components";
import {Box} from "@chakra-ui/react";

export const contactEmail = "elfenokierefiesta@gmail.com"
export const headerImage =
  "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=570,h=570,fit=crop/A3QENvNaewUXORzD/logo-gea-Yle2oQ8qjXC42oKO.png";

export const videoSrc =
  "https://drive.google.com/uc?id=1GFxyVGvzgqvR2c1O0SI55P9jPW9hfBm9";

export const lat = 19.420450720475745;

export const lan = -99.14568964984835;

export const cardsData = [
  {
    title: "REDISEÑAMOS",
    content:
      "Tu producto con tecnologías innovadoras para que sea más amigable con el ambiente.",
  },
  {
    title: "REDUCIMOS",
    content:
      "El consumo de materias, de energía y del impacto ambiental CO2 Neutral y H2O neutral. ",
  },
  {
    title: "REUTILIZAMOS",
    content:
      "Materias dándoles una vida útil óptima, sin dejar de reciclar los materiales.",
  },
  {
    title: "RECUPERAMOS",
    content:
      "Los materiales conservando y mejorando las propiedades de los productos.",
  },
  {
    title: "REINTEGRAMOS",
    content: "Todos los productos posibles a la naturaleza.",
  },
];

export const MetallicCard = styled(Box)`
  background: linear-gradient(145deg, #e6e6e6, #ffffff, #cccccc, #b3b3b3);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;
