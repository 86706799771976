import { Grid, GridItem, Link, Text } from "@chakra-ui/react";
import { lan, lat } from "../utilities";
import { googleMapURL } from "../../../../variables/general";
import CollaboratorsMap from "../../../../components/collaborators/Map";
import React from "react";

const RediassaMapComponent = () => {
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      backgroundColor={"white"}
      borderRadius={15}
      templateColumns="repeat(2, 1fr)"
      gap={6}
    >
      <CollaboratorsMap
        lat={lat}
        lan={lan}
        marker={true}
        zoom={15}
        isMarkerShown={false}
        googleMapURL={googleMapURL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <Grid justifyItems={"center"} justifyContent={"center"} gap={1}>
        <Text fontWeight={"bold"} fontSize={"2xl"}>
          Conócenos
        </Text>
        <Text fontWeight={"bold"}>Ubicación</Text>
        <Text>Soyopa #43 Esquina con Tabasco</Text>
        <Text>Hermosillo, Sonora, Mex.</Text>
        <GridItem />
        <Text fontWeight={"bold"}>Horario</Text>
        <Text>Lunes - Viernes</Text>
        <Text>9am - 6pm</Text>
        <GridItem />
        <Text fontWeight={"bold"}>Contacto</Text>
        <Text>+52 662 216 5464</Text>
        <Text>juan.luna@rediasa.com</Text>
        <Link href={"https://www.rediasa.com/"} color={"red"} isExternal>
          www.rediasa.com
        </Link>
      </Grid>
    </Grid>
  );
};

export default RediassaMapComponent;
