import { Button, Grid, GridItem, Text } from "@chakra-ui/react";
import CustomInputField from "./CustomInputField";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import useGateSealingHooks from "../hooks/useGateSealingHooks";
import { borderColor, textColor } from "../../../utilities/variables";

const GateSealingTable = ({
  minPackSelectionError,
  maxPackSelectionError,
  showChart,
  isButtonEnabled,
  setShowChart,
  onSavePackTable,
  min_Hold_Selection,
}) => {
  const { project, isLoading } = useContext(ProjectContext);
  const {
    min_Pack_Selection,
    max_Pack_Selection,
    minPackTable,
    maxPackTable,
  } = project;

  const {
    saveMinPackTableChanges,
    saveMaxPackTableChanges,
  } = useGateSealingHooks();

  return (
    <Grid
      mb={5}
      p={5}
      borderWidth={1}
      borderRadius={15}
      mx={5}
      borderColor={borderColor}
    >
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={6}
        justifyItems={"center"}
        color={textColor}
      >
        <Text size={"xl"} fontWeight={"bold"}>
          Mínimo
        </Text>
        <Text size={"xl"} fontWeight={"bold"}>
          Máximo
        </Text>
      </Grid>
      <Grid color={textColor} templateColumns="repeat(2, 1fr)" gap={3} mt={5}>
        <Grid templateColumns="repeat(5, 1fr)" gap={1} justifyItems={"center"}>
          <Text fontSize={"sm"}>Disparo</Text>
          <Text fontSize={"sm"}>Hold Time</Text>
          <Text fontSize={"sm"}>Peso de Tiro</Text>
          <Text fontSize={"sm"}>Cushion</Text>
          <Text fontSize={"sm"}>Cycle Time</Text>
          {minPackTable &&
            minPackTable.map((row, index) => {
              return (
                <GridItem
                  borderRadius={10}
                  px={5}
                  colSpan={5}
                  bgColor={
                    index + 1 === parseInt(min_Hold_Selection)
                      ? "green.400"
                      : parseInt(project.minGateSealingData.shot) === index + 1
                      ? "green.400"
                      : ""
                  }
                >
                  <Grid
                    templateColumns="repeat(5, 1fr)"
                    gap={4}
                    alignItems={"center"}
                    justifyItems={"center"}
                  >
                    <Text>{index + 1}</Text>

                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        alignSelf={"center"}
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={true}
                        value={row.holdTime}
                        onChange={(e) => {
                          saveMinPackTableChanges(
                            index,
                            "holdTime",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={showChart}
                        value={row.shootingWeight}
                        onChange={(e) => {
                          saveMinPackTableChanges(
                            index,
                            "shootingWeight",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>

                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={showChart}
                        value={row.cushion}
                        onChange={(e) => {
                          saveMinPackTableChanges(
                            index,
                            "cushion",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>

                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={showChart}
                        value={row.cycleTime}
                        onChange={(e) => {
                          saveMinPackTableChanges(
                            index,
                            "cycleTime",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>
                  </Grid>
                </GridItem>
              );
            })}
        </Grid>
        <Grid templateColumns="repeat(5, 1fr)" gap={1} justifyItems={"center"}>
          <Text fontSize={"sm"}>Disparo</Text>
          <Text fontSize={"sm"}>Hold Time</Text>
          <Text fontSize={"sm"}>Peso de Tiro</Text>
          <Text fontSize={"sm"}>Cushion</Text>
          <Text fontSize={"sm"}>Cycle Time</Text>
          {maxPackTable &&
            maxPackTable.map((row, index) => {
              return (
                <GridItem
                  px={5}
                  borderRadius={10}
                  colSpan={5}
                  bgColor={
                    index + 1 === parseInt(min_Hold_Selection)
                      ? "green.400"
                      : parseInt(project.minGateSealingData.shot) === index + 1
                      ? "green.400"
                      : ""
                  }
                >
                  <Grid
                    templateColumns="repeat(5, 1fr)"
                    gap={4}
                    alignItems={"center"}
                    justifyItems={"center"}
                  >
                    <Text>{index + 1}</Text>
                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={true}
                        value={row.holdTime}
                        onChange={(e) => {
                          saveMaxPackTableChanges(
                            index,
                            "holdTime",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={showChart}
                        value={row.shootingWeight}
                        onChange={(e) => {
                          saveMaxPackTableChanges(
                            index,
                            "shootingWeight",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={showChart}
                        value={row.cushion}
                        onChange={(e) => {
                          saveMaxPackTableChanges(
                            index,
                            "cushion",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem alignSelf={"center"} pb={1}>
                      <CustomInputField
                        borderColor={borderColor}
                        color={textColor}
                        borderRadius={2}
                        disabled={showChart}
                        value={row.cycleTime}
                        onChange={(e) => {
                          saveMaxPackTableChanges(
                            index,
                            "cycleTime",
                            e.target.value
                          );
                        }}
                      />
                    </GridItem>
                  </Grid>
                </GridItem>
              );
            })}
        </Grid>
      </Grid>
      <Button
        colorScheme={"facebook"}
        mt={5}
        isLoading={isLoading}
        onClick={() => {
          onSavePackTable();
          setShowChart(!showChart);
        }}
        disabled={
          !project.max_Hold_Time ||
          !min_Pack_Selection ||
          !max_Pack_Selection ||
          !isButtonEnabled ||
          !max_Pack_Selection ||
          !min_Pack_Selection ||
          minPackSelectionError ||
          maxPackSelectionError
        }
      >
        {showChart ? "Editar" : "Guardar Datos y Graficar"}
      </Button>
    </Grid>
  );
};

export default GateSealingTable;
