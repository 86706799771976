import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IndividualBase from "../../components/IndividualBase";
import ProjectedArea from "./components/ProjectedArea";
import HelmetComponent from "../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import { GridItem } from "@chakra-ui/react";
import CustomGrid from "../../components/CustomGrid";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import React from "react";

const IndividualProjectedArea = () => {
  return (
    <>
      <HelmetComponent
        keywords={metaKeywords}
        title={metaTitle}
        description={metaDescription}
      />
      <HeaderProvider>
        <GridItem width={"100%"} mt={"120px"}>
          <CustomGrid>
            <IndividualBase instructions={instructions} description={description} title={"Área Proyectada y Tonelaje"}>
              <ProjectedArea />
            </IndividualBase>
          </CustomGrid>
        </GridItem>
      </HeaderProvider>
    </>
  );
};

export default IndividualProjectedArea;
