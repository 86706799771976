const instructions = [
  "Para encontrar el tiempo de enfriamiento optimo de la Pieza moldeada se utilizaran datos previamente cargados con la resina utilizada.",
  "1. Encontrar el máximo espesor de pared de la pieza, esta dimensión puede ser encontrada en el plano de la pieza, archivo de diseño 3D, midiendo fisicamente una pieza previamente moldeada o directamente en las cavidades del Molde",
  "2. Anotar la temperatura Melt utilizando nuevamente el ejercicio Melt 30/30 o si la toma acaba de ser registrada puede capturarse nuevamente en el espacio en blanco.",
  "3. Con ayuda de un pirometro de varilla tomar la temperatura de cavidad. Lo ideal es tomar esta temperatura después de por lo menos 5 ciclos consecutivos.",
  "4. Capturar la temperatura deseada con la que se quiere retirar la pieza del molde, esta debe ser menor que la Temperatura Melt y mayor que la Temperatura de Molde para lograr una mejor eficiencia del Proceso de Moldeo.",
  "El tiempo de enfriamiento recomendado se visualizará en la parte inferior derecha.",
];

export default instructions;
