import { useContext, useState } from "react";
import { Project } from "../../models";
import ProjectContext from "../../contexts/ProjectContext";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import ConditionalsAlert from "../../views/projects/hooks/ConditionalsAlert";

export const useDryerSupplyHooks = () => {
  const { project, tool, resin, updateLoading, updateProject } = useContext(
    ProjectContext
  );
  const { dryerId, quantityToProcess, scrapRisk, dryerCapacity, uom } = project;
  const [showData, setShowData] = useState(false);
  const onFinishDryerSupplyHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "dryerSupply",
      path: "/dryer-supply",
      options: {
        body: {
          dryTimeMin: resin.dryTimeMin,
          specificWeight: resin.specificWeight,
          shootVolume: tool.shootVolume,
          numberOfCavities: tool.numberOfCavities,
          cycleTime: tool.cycleTime,
          dryerId,
          quantityToProcess,
          scrapRisk,
          dryerCapacity,
          uom
        },
      },
    });

    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.dryerCapacity_Oz = res.dryerCapacity_Oz.toString();
        updated.dryTimeMin_min = res.dryTimeMin_min.toString();
        updated.cycleTime_min = res.cycleTime_min.toString();
        updated.shotWeight100 = res.shotWeight100.toString();
        updated.resinFlow_Oz_x_Min = res.resinFlow_Oz_x_Min.toString();
        updated.reqShotsForProd = res.reqShotsForProd.toString();
        updated.pcs_x_Hour = res.pcs_x_Hour.toString();
        updated.resinTroughDryer = res.resinTroughDryer.toString();
        updated.reqResinForProduction = res.reqResinForProduction.toString();
        updated.minimumDryerReload = res.minimumDryerReload.toString();
        updated.dryerSupplying = res.dryerSupplying.toString();
        updated.productionDuration = res.productionDuration.toString();
        updated.reloadsRequired = res.reloadsRequired.toString();
        updated.dryerCapacity = res.dryerCapacity.toString();
        updated.dryerId = res.dryerId.toString();
        updated.quantityToProcess = res.quantityToProcess.toString();
        updated.scrapRisk = res.scrapRisk.toString();
        updated.phase = "3";
      })
    )
      .then((res) => {
        let icon = "warning";
        let text = "";
        if (
          resin.dryTimeMax > res.resinTroughDryer &&
          resin.dryTimeMin < res.resinTroughDryer
        ) {
          icon = "success";
        } else {
          if (resin.dryTimeMax < res.resinTroughDryer) {
            text +=
              "<li>El recorrido de la resina es mayor al tiempo maximo de secado de esta. </li>";
          } else {
            text +=
              "<li>El recorrido de la resina es menor al tiempo minimo de secado de esta. </li>";
          }
        }

        ConditionalsAlert(icon, text);
        updateProject(res);
        setShowData(true);
      })
      .catch((err) => console.log(err))
      .finally(() => updateLoading(false));
  };

  return {
    showData,
    onFinishDryerSupplyHandler,
    setShowData,
  };
};
