// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar10 from "assets/img/avatars/avatar10.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar7 from "assets/img/avatars/avatar7.png";
import avatar8 from "assets/img/avatars/avatar8.png";
import avatar9 from "assets/img/avatars/avatar9.png";
// Custom icons
import {
  AdobexdLogo,
  AtlassianLogo,
  InvisionLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons.js";
import { AiOutlineExclamation } from "react-icons/ai";
import {
  FaArrowDown,
  FaArrowUp,
  FaBell,
  FaCreditCard,
  FaFilePdf,
  FaHtml5,
  FaShoppingCart,
} from "react-icons/fa";
import { SiDropbox } from "react-icons/si";

export const googleMapURL =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBIQt5fjqALUaHnBvNnpo-jVCwWCjR-x1c&v=3.exp&libraries=geometry,drawing,places";

export const timelineProjectsData1 = [
  {
    logo: FaBell,
    title: "$2400, Design changes",
    titleColor: "#fff",
    date: "22 DEC 7:20 PM",
    color: "brand.200",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "brand.200",
        titleTag: "DESIGN",
      },
    ],
  },
  {
    logo: FaHtml5,
    title: "New order #4219423",
    titleColor: "#fff",
    date: "21 DEC 11:21 PM",
    color: "orange",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "red.500",
        titleTag: "ORDER",
      },
      {
        bgTag: "red.500",
        titleTag: "#1832412",
      },
    ],
  },
  {
    logo: FaShoppingCart,
    title: "Server Payments for April",
    titleColor: "#fff",
    date: "21 DEC 9:28 PM",
    color: "blue.400",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "blue.400",
        titleTag: "SERVER",
      },
      {
        bgTag: "blue.400",
        titleTag: "PAYMENTS",
      },
    ],
  },
  {
    logo: FaCreditCard,
    title: "New card added for order #3210145",
    titleColor: "#fff",
    date: "20 DEC 3:52 PM",
    color: "orange.300",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "orange.300",
        titleTag: "CARD",
      },
      {
        bgTag: "orange.300",
        titleTag: "#1832412",
      },
      {
        bgTag: "orange.300",
        titleTag: "PRIORITY",
      },
    ],
  },
  {
    logo: SiDropbox,
    title: "Unlock packages for Development",
    titleColor: "#fff",
    date: "19 DEC 11:35 PM",
    color: "purple.400",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "purple.400",
        titleTag: "DEVELOPMENT",
      },
    ],
  },
  {
    logo: FaBell,
    title: "$2400, Design changes",
    titleColor: "#fff",
    date: "22 DEC 7:20 PM",
    color: "brand.200",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "brand.200",
        titleTag: "DESIGN",
      },
    ],
  },
  {
    logo: FaHtml5,
    title: "New order #4219423",
    titleColor: "#fff",
    date: "21 DEC 11:21 PM",
    color: "orange",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "red.500",
        titleTag: "ORDER",
      },
      {
        bgTag: "red.500",
        titleTag: "#1832412",
      },
    ],
  },
  {
    logo: FaShoppingCart,
    title: "Server Payments for April",
    titleColor: "#fff",
    date: "21 DEC 9:28 PM",
    color: "blue.400",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "blue.400",
        titleTag: "SERVER",
      },
      {
        bgTag: "blue.400",
        titleTag: "PAYMENTS",
      },
    ],
  },
  {
    logo: FaCreditCard,
    title: "New card added for order #3210145",
    titleColor: "#fff",
    date: "20 DEC 3:52 PM",
    color: "orange.300",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "orange.300",
        titleTag: "CARD",
      },
      {
        bgTag: "orange.300",
        titleTag: "#1832412",
      },
      {
        bgTag: "orange.300",
        titleTag: "PRIORITY",
      },
    ],
  },
];
export const timelineProjectsData2 = [
  {
    logo: FaBell,
    title: "$2400, Design changes",
    titleColor: "#fff",
    date: "22 DEC 7:20 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "#fff",
        titleTag: "DESIGN",
      },
    ],
  },
  {
    logo: FaHtml5,
    title: "New order #4219423",
    titleColor: "#fff",
    date: "21 DEC 11:21 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "red.500",
        titleTag: "ORDER",
      },
      {
        bgTag: "red.500",
        titleTag: "#1832412",
      },
    ],
  },
  {
    logo: FaShoppingCart,
    title: "Server Payments for April",
    titleColor: "#fff",
    date: "21 DEC 9:28 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "blue.400",
        titleTag: "SERVER",
      },
      {
        bgTag: "blue.400",
        titleTag: "PAYMENTS",
      },
    ],
  },
  {
    logo: FaCreditCard,
    title: "New card added for order #3210145",
    titleColor: "#fff",
    date: "20 DEC 3:52 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "orange.300",
        titleTag: "CARD",
      },
      {
        bgTag: "orange.300",
        titleTag: "#1832412",
      },
      {
        bgTag: "orange.300",
        titleTag: "PRIORITY",
      },
    ],
  },
  {
    logo: SiDropbox,
    title: "Unlock packages for Development",
    titleColor: "#fff",
    date: "19 DEC 11:35 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "purple.400",
        titleTag: "DEVELOPMENT",
      },
    ],
  },
  {
    logo: FaBell,
    title: "$2400, Design changes",
    titleColor: "#fff",
    date: "22 DEC 7:20 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "#fff",
        titleTag: "DESIGN",
      },
    ],
  },
  {
    logo: FaHtml5,
    title: "New order #4219423",
    titleColor: "#fff",
    date: "21 DEC 11:21 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "red.500",
        titleTag: "ORDER",
      },
      {
        bgTag: "red.500",
        titleTag: "#1832412",
      },
    ],
  },
  {
    logo: FaShoppingCart,
    title: "Server Payments for April",
    titleColor: "#fff",
    date: "21 DEC 9:28 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "blue.400",
        titleTag: "SERVER",
      },
      {
        bgTag: "blue.400",
        titleTag: "PAYMENTS",
      },
    ],
  },
  {
    logo: FaCreditCard,
    title: "New card added for order #3210145",
    titleColor: "#fff",
    date: "20 DEC 3:52 PM",
    color: "#fff",
    description:
      "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    tags: [
      {
        bgTag: "orange.300",
        titleTag: "CARD",
      },
      {
        bgTag: "orange.300",
        titleTag: "#1832412",
      },
      {
        bgTag: "orange.300",
        titleTag: "PRIORITY",
      },
    ],
  },
];

export const invoicesData = [
  {
    date: "March, 01, 2020",
    code: "#MS-415646",
    price: "$180",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "February, 10, 2020",
    code: "#RV-126749",
    price: "$250",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "April, 05, 2020",
    code: "#FB-212562",
    price: "$560",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "June, 25, 2019",
    code: "#QW-103578",
    price: "$120",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "March, 01, 2019",
    code: "#AR-803481",
    price: "$300",
    logo: FaFilePdf,
    format: "PDF",
  },
];

export const billingData = [
  {
    name: "Oliver Liam",
    company: "Viking Burrito",
    email: "oliver@burrito.com",
    number: "FRB1235476",
  },
  {
    name: "Lucas Harper",
    company: "Stone Tech Zone",
    email: "lucas@stone-tech.com",
    number: "FRB1235476",
  },
  {
    name: "Ethan James",
    company: "Fiber Notion",
    email: "ethan@fiber.com",
    number: "FRB1235476",
  },
];

export const newestTransactions = [
  {
    name: "Netflix",
    date: "27 March 2021, at 12:30 PM",
    price: "- $2,500",
    logo: FaArrowDown,
  },
  {
    name: "Apple",
    date: "27 March 2021, at 12:30 PM",
    price: "+ $2,500",
    logo: FaArrowUp,
  },
];

export const olderTransactions = [
  {
    name: "Stripe",
    date: "26 March 2021, at 13:45 PM",
    price: "+ $800",
    logo: FaArrowUp,
  },
  {
    name: "HubSpot",
    date: "26 March 2021, at 12:30 PM",
    price: "+ $1,700",
    logo: FaArrowUp,
  },
  {
    name: "Webflow",
    date: "26 March 2021, at 05:00 PM",
    price: "Pending",
    logo: AiOutlineExclamation,
  },
  {
    name: "Microsoft",
    date: "25 March 2021, at 16:30 PM",
    price: "- $987",
    logo: FaArrowDown,
  },
];
