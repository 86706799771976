import { Box, Flex, Text } from "@chakra-ui/react";
import { lineChartOptionsMelt } from "../utilities/variables";
import React from "react";
import LineChart from "../../../../../../../components/Charts/LineChart";

const MeltChart = ({ lineChartDataMelt }) => {
  return (
    <Flex
      color={"white"}
      borderWidth={1}
      borderRadius={10}
      direction={"column"}
      minHeight={"200px"}
      w={"100%"}
    >
        <Box w="100%" minH={{ sm: "300px" }} bgColor={"white"}>
          <LineChart
            chartData={lineChartDataMelt}
            chartOptions={lineChartOptionsMelt}
          />
        </Box>
    </Flex>
  );
};

export default MeltChart;
