import { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../../../../contexts/ProjectContext";

export const useFinalFunction = () => {
  const { project, updateProject } = useContext(ProjectContext);

  useEffect(() => {
    const nuevaDimensionTestTable = calcularNuevaDimensionTestTable();

    if (
      JSON.stringify(nuevaDimensionTestTable) !==
      JSON.stringify(project.dimensionTestTable)
    ) {
      updateProject({
        ...project,
        dimensionTestTable: nuevaDimensionTestTable,
      });
    }
  }, [project.doeTable, project.dimensionTestTable]); // Dependencias del useEffect

  const order = [
    "MIN-MIN",
    "MIN-NOM",
    "MIN-MAX",
    "NOM-MIN",
    "NOM-NOM",
    "NOM-MAX",
    "MAX-MIN",
    "MAX-NOM",
    "MAX-MAX",
  ];

  function calcularNuevaDimensionTestTable() {
    const resultadosValidacion = order.reduce((resultados, condicion) => {
      resultados[condicion] = "ACEPTADO";
      return resultados;
    }, {});
    if (project && project.doeTable) {
      for (const condition of order) {
        let validacionDoeTable = project.doeTable.some(
          (item) =>
            item.sustaining === condition.split("-")[0] &&
            item.tempStatus === condition.split("-")[1] &&
            Number(item.maxInjPress) >= project.inj_Press_Required_Low_Limit &&
            Number(item.maxInjPress) <= project.inj_Press_Required_High_Limit &&
            Number(item.cushion) >= project.cushion_Low_Limit &&
            Number(item.cushion) <= project.cushion_High_Limit &&
            Number(item.injTime) >= project.fill_Time_Low_Limit &&
            Number(item.injTime) <= project.fill_Time_High_Limit &&
            Number(item.recovery) >= project.recovery_Time_Low_limit &&
            Number(item.recovery) <= project.recovery_Time_Hig_limit &&
            Number(item.cycleTime) >= project.cycle_Time_Low_Limit &&
            Number(item.cycleTime) <= project.cycle_Time_High_Limit &&
            Number(item.shootingWeight) >= project.weight_Low_Limit &&
            Number(item.shootingWeight) <= project.weight_High_Limit
        );

        let validacionDimensionTestTable = true;
        for (let i = 0; i < 4; i++) {
          const item = project.dimensionTestTable[i];
          if (
            item.dimension &&
            item.data &&
            item.data.hasOwnProperty(condition)
          ) {
            const value = Number(item.data[condition]);
            const minResult = Number(item.minToleranceResult);
            const maxResult = Number(item.maxToleranceResult);

            if (isNaN(value) || value < minResult || value > maxResult) {
              validacionDimensionTestTable = false;
              break;
            }
          }
        }

        const ultimoElemento =
          project.dimensionTestTable[project.dimensionTestTable.length - 1];
        if (
          ultimoElemento.check &&
          ultimoElemento.check[condition] !== "PASA"
        ) {
          validacionDoeTable = false;
        }

        if (!validacionDoeTable || !validacionDimensionTestTable) {
          resultadosValidacion[condition] = "RECHAZADO";
        }
      }

      const nuevaDimensionTestTable = [...project.dimensionTestTable];
      const ultimoElementoIndex = nuevaDimensionTestTable.length - 1;
      const ultimoElemento = {
        ...nuevaDimensionTestTable[ultimoElementoIndex],
      };
      ultimoElemento.final = {
        ...ultimoElemento.final,
        ...resultadosValidacion,
      };

      nuevaDimensionTestTable[ultimoElementoIndex] = ultimoElemento;

      return nuevaDimensionTestTable;
    }
  }
};
