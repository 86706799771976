import {inMm, ozGr, temp} from "../../../../../hooks/useUomTransform";

export const dataEntries1 = (data) => {
  return [
    {
      label: "Peso Específico",
      value: data?.specificWeight,
      type: "temp",
      addOn: ":1",
    },
    {
      label: "Diámetro de Tornillo",
      value: data?.screwDiameter,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Mínima Posición de Colchón",
      value: data?.minCushion,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Temperatura de Masa Real",
      value: data?.realMeltTemp,
      type: "temp",
      addOn: temp(data?.header?.uom),
    },
    {
      label: "Mínimo Uso de Barril",
      value: data?.minBarrelUse,
      type: "time",
      addOn: "%",
    },
    {
      label: "Tamaño de Descompresión",
      value: data?.decompressionSize,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Posición de Transferencia V/P",
      value: data?.cutoffPosX100,
      type: "time",
      addOn: "%",
    },
  ]
}

export const dataEntries2 = data => {
  return [
    {
      label: "Diámetro de Pistón",
      value: data?.pistonDiameter,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Máximo Tamaño de Disparo",
      value: data?.maxShotSize,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Máxima Posición de Colchón",
      value: data?.maxCushion,
      type: "weight",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "L/D Ratio de Máquina",
      value: data?.LDratio,
      type: "int",
      addOn: ":1",
    },
    {
      label: "Máximo Uso de Barril",
      value: data?.maxBarrelUse,
      type: "time",
      addOn: "%",
    },
    {
      label: "Peso de Disparo al 100%",
      value: data?.shotWeight100,
      type: "weight",
      addOn: ozGr(data?.header?.uom),
    },
    {
      label: "Porcentaje de Colchón",
      value: data?.cushionPosX100,
      type: "time",
      addOn: "%",
    },
  ]
}