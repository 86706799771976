const instructions = [
  "Para realizar el Experimento de Tamaño de Disparo, previamente debemos conocer alguna información de la Máquina de Inyección y el Producto con los que vayamos a trabajar, así como la resina y sus propiedades.",
  "Información Requerida:",
  "1. Peso Completo del Disparo",
  "2. Tipo de Resina",
  "3. Peso Específico de Resina",
  "4. Ocupación de Barril sugerida por el Fabricante",
  "5. Colchón recomendado por el Fabricante",
  "6. Ri de Unidad de Inyección",
  "7. Dimensiones de la Barril y Husillo",
  "8. Capacidad Máxima de Barril en Onzas.",
  "Una vez recolectada esta información podemos llenar las casillas en blanco. En el apartado de Posición de Transferencia (V/P) anotamos el porcentaje deseado para la Primera Etapa de Llenado, entre el 96 y 98%. En el apartado de Posición de Colchón anotamos el porcentaje deseado de Colchón, dentro del rango sugerido.",
  "El Formulario nos permitirá conocer la Capacidad de Utilización del Barril, dependiendo de la información utilizada, podremos hacer ajustes teóricos para mejorar las Posiciones de Disparo, Corte y Colchón, como mejor convenga y mientras estén dentro de las áreas verdes, para lo cual nos ayudará la representación gráfica.",
  "Podremos evaluar si es posible producir un producto nuevo o hacer una transferencia a la Máquina de Inyección analizada en el experimento.",
];

export default instructions;
