import { useContext, useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { Machine, Project, Resin, Tool } from "../../../../models";
import {
  cavities,
  cavityBalanceTable,
  dimensionTestTable,
  doeTable,
  gaps,
  lineavilityTable,
  maxGateSealingData,
  maxPackTable,
  minGateSealingData,
  minPackTable,
  runners,
  speedSelected,
  vcheckCushion,
  vcheckFillingTime,
  vcheckWeight,
  viscoscityStudyDataMatrix,
} from "../utilities/variables";
import { isObjectEmpty } from "../../../../utilities/functions";
import ProjectContext from "../../../../contexts/ProjectContext";
import { getCurrentUser } from "aws-amplify/auth";

const errorsInitialState = {
  name: false,
  resin: false,
  machine: false,
  tool: false,
};
export const useNewProjectHooks = () => {
  const [resins, setResins] = useState([]);
  const [tools, setTools] = useState([]);
  const [machines, setMachines] = useState([]);
  const [resin, setResin] = useState({});
  const [tool, setTool] = useState({});
  const [machine, setMachine] = useState({});
  const [isLoadingResins, setIsLoadingResins] = useState(true);
  const [isLoadingMachines, setIsLoadingMachines] = useState(true);
  const [isLoadingTools, setIsLoadingTools] = useState(true);

  const [name, setName] = useState("");
  const [uom, setUom] = useState("INGLES");
  const [errors, setErrors] = useState(errorsInitialState);

  const { updateLoading, updateProject, project } = useContext(ProjectContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getData();
  }, [uom]);

  useEffect(() => {
    const getUser = async () => {
      const u = await getCurrentUser();
      setUser(u);
    };

    getUser();
  }, []);

  const getData = async () => {
    await DataStore.query(Resin, (c) => c.uom.eq(uom))
      .then((res) => {
        setIsLoadingResins(false);
        setResins([]);
        setResin([]);
        res.map((item) => {
          setResins((current) => [
            ...current,
            { value: item.id, label: item.resinName },
          ]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await DataStore.query(Tool, (c) => c.uom.eq(uom))
      .then((res) => {
        setIsLoadingTools(false);
        setTools([]);
        setTool([]);
        res.map((item) => {
          setTools((current) => [
            ...current,
            { value: item.id, label: item.product },
          ]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await DataStore.query(Machine, (c) => c.uom.eq(uom))
      .then((res) => {
        setIsLoadingMachines(false);
        setMachine([]);
        setMachines([]);
        res.map((item) => {
          setMachines((current) => [
            ...current,
            { value: item.id, label: item.machineId },
          ]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitHandler = async () => {
    updateLoading(true);
    let newErrors = { ...errors };
    if (isObjectEmpty(resin)) {
      newErrors.resin = true;
    }
    if (isObjectEmpty(tool)) {
      newErrors.tool = true;
    }
    if (isObjectEmpty(machine)) {
      newErrors.machine = true;
    }
    if (!name) {
      newErrors.name = true;
    }

    if (
      newErrors.resin ||
      newErrors.name ||
      newErrors.machine ||
      newErrors.tool
    ) {
      await setErrors(newErrors);

      updateLoading(false);
    } else {
      const getMachine = await DataStore.query(Machine, machine.value);
      const getTool = await DataStore.query(Tool, tool.value);
      const getResin = await DataStore.query(Resin, resin.value);

      const array = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      const randomOrder = shuffleArray(array);
      const data = {
        phase: "1",
        projectName: name,
        uom,
        resinName: resin.label,
        toolName: tool.label,
        machineName: machine.label,
        Machine: getMachine,
        Tool: getTool,
        Resin: getResin,
        runners,
        cavities,
        gaps,
        vcheckWeight,
        vcheckFillingTime,
        vcheckCushion,
        viscoscityStudyDataMatrix,
        lineavilityTable,
        cavityBalanceTable,
        speedSelected,
        minPackTable,
        maxPackTable,
        minGateSealingData,
        maxGateSealingData,
        randomOrder,
        doeTable,
        dimensionTestTable,
      };

      try {
        const restOperation = post({
          apiName: "intensificationRatioInd",
          path: "/intensification-ratio-ind",
          options: {
            body: {
              maxHydraulicPressure: getMachine.maxHydraulicPressure,
              pistonDiameter: getMachine.pistonDiameter,
              screwDiameter: getMachine.screwDiameter,
            },
          },
        });
        const rest = await restOperation.response;
        const body = await rest.body.json();

        let { pistonArea, intRatio, maxPlasticPressure, screwArea } = body;
        pistonArea = pistonArea.toString();
        intRatio = intRatio.toString();
        maxPlasticPressure = maxPlasticPressure.toString();
        screwArea = screwArea.toString();

        await DataStore.save(
          new Project({
            ...data,
            userID: user.userId,
            pistonArea,
            intRatio,
            maxPlasticPressure,
            screwArea,
          })
        )
          .then((res) => {
            updateProject(res);
          })
          .catch((err) => {
            console.log(err.message);
          })
          .finally(() => updateLoading(false));
      } catch (err) {
        console.log(err);
      }
    }
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const isSubmitDisabled =
    !name.trim() || !resin.value || !tool.value || !machine.value;

  const hasProjectData = () => project && Object.keys(project).length > 0;

  return {
    name,
    tools,
    resins,
    machines,
    errors,
    onSubmitHandler,
    tool,
    setMachine,
    setResin,
    setTool,
    resin,
    machine,
    errorsInitialState,
    setErrors,
    setName,
    isLoadingMachines,
    isLoadingResins,
    isLoadingTools,
    hasProjectData,
    isSubmitDisabled,
    setUom,
    uom,
  };
};
