import { Grid } from "@chakra-ui/react";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import useGateSealingHooks from "../hooks/useGateSealingHooks";
import InputCustomFieldProjectRigthAddon from "../../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { borderColor, textColor } from "../../../utilities/variables";
import { ppsiToPbar } from "../../../../../individual/hooks/useUomTransform";

const GateSealingForm = ({ showChart }) => {
  const { project, handleInputChange } = useContext(ProjectContext);
  const {
    handleMaxPackSelectionBlur,
    minPackSelectionError,
    handleMinPackSelectionBlur,
    maxPackSelectionError,
    isMaxPackSelectionEnabled,
  } = useGateSealingHooks();
  return (
    <Grid
      p={5}
      mx={5}
      borderWidth={1}
      mb={5}
      borderRadius={15}
      templateColumns="repeat(3, 1fr)"
      gap={6}
      borderColor={borderColor}
    >
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={"seg"}
        disabled={showChart}
        mx={3}
        label="Tiempo de Sostenimiento Máximo"
        value={project.max_Hold_Time}
        onChange={handleInputChange}
        id="max_Hold_Time"
      />
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={ppsiToPbar(project?.uom)}
        mx={5}
        disabled={showChart}
        label="Mínima Presión Requerida"
        value={project.min_Pack_Selection}
        onChange={handleInputChange}
        onBlur={handleMinPackSelectionBlur}
        id="min_Pack_Selection"
        error={minPackSelectionError}
      />
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={ppsiToPbar(project?.uom)}
        disabled={!isMaxPackSelectionEnabled || showChart}
        mx={3}
        label="Máxima Presión Requerida"
        value={project.max_Pack_Selection}
        onChange={handleInputChange}
        onBlur={handleMaxPackSelectionBlur}
        id="max_Pack_Selection"
        error={maxPackSelectionError}
      />
    </Grid>
  );
};

export default GateSealingForm;
