import { useContext, useState, useEffect } from "react";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import ProjectContext from "../../contexts/ProjectContext";
import { Project } from "../../models";
import ConditionalsAlert from "../../views/projects/hooks/ConditionalsAlert";

export const useShotSizeHooks = () => {
  const [showChart, setShowChart] = useState(false);

  const [first, setFirst] = useState(0);
  const [second, setSecond] = useState(0);
  const [third, setThird] = useState(0);
  const [fourth, setFourth] = useState(0);
  const [fifth, setFifth] = useState(0);

  const {
    updateProject,
    project,
    resin,
    tool,
    machine,
    updateLoading,
  } = useContext(ProjectContext);

  const {
    cutOffPositionPercentage_95_98,
    cushionPositionPercentage,
    decompressionSize,
    cushionPositionTheoric,
    shotSizePositionTheoric,
    shotWeight100,
    uom,
  } = project;
  const { shootVolume } = tool;
  const { maxShotSize, barrelVolume } = machine;
  const {
    barrelOcupationMin,
    barrelOcupationMax,
    cushionPositionMin,
    cushionPositionMax,
    specificWeight,
  } = resin;

  useEffect(() => {
    const data = parseFloat(parseFloat(cushionPositionMin).toFixed(4)).toFixed(
      2
    );
    setFirst(data);
  }, [cushionPositionMin]);

  useEffect(() => {
    const data = parseFloat(
      parseFloat(cushionPositionMax) - parseFloat(cushionPositionMin)
    ).toFixed(2);
    setSecond(data);
  }, [cushionPositionMax, cushionPositionMin]);

  useEffect(() => {
    const data = parseFloat(
      parseFloat(maxShotSize).toFixed(4) *
        (parseFloat(barrelOcupationMin).toFixed(4) / 100) -
        cushionPositionMax
    ).toFixed(2);
    setThird(data);
  }, [maxShotSize, barrelOcupationMin, cushionPositionMax]);

  useEffect(() => {
    const data = parseFloat(
      parseFloat(maxShotSize) * (parseFloat(barrelOcupationMax) / 100) -
        parseFloat(maxShotSize) * (parseFloat(barrelOcupationMin) / 100)
    ).toFixed(2);
    setFourth(data);
  }, [maxShotSize, barrelOcupationMax, barrelOcupationMin]);

  useEffect(() => {
    const data = parseFloat(
      parseFloat(maxShotSize) -
        parseFloat(
          parseFloat(maxShotSize) * (parseFloat(barrelOcupationMax) / 100)
        )
    ).toFixed(2);
    setFifth(data);
  }, [maxShotSize, barrelOcupationMax]);

  const barChartData = [
    {
      name: "Bad Zone",
      data: [first],
    },
    {
      name: "Good Zone",
      data: [second],
    },
    {
      name: "Bad Zone",
      data: [third],
    },
    {
      name: "Good Zone",
      data: [fourth],
    },
    {
      name: "Bad Zone",
      data: [fifth],
    },
  ];

  const barChartData2 = [
    {
      name: "",
      data: [parseFloat(cushionPositionTheoric).toFixed(4)],
    },
    {
      name: "Shot Zone",
      data: [
        parseFloat(
          parseFloat(shotSizePositionTheoric) -
            parseFloat(cushionPositionTheoric)
        ).toFixed(4),
      ],
    },
    {
      name: "",
      data: [
        parseFloat(
          parseFloat(maxShotSize) - parseFloat(shotSizePositionTheoric)
        ).toFixed(4),
      ],
    },
  ];

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "Plus Jakarta Display",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: ["Data"],
      show: false,
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "#fff",
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
    },
    grid: {
      show: false,
    },
    fill: {
      colors: ["#FF0000", "#00FF00", "#FF0000", "#00FF00", "#FF0000"],
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
  };

  const barChartOptions2 = {
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "Plus Jakarta Display",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: ["Data"],
      show: true,
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "#fff",
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
    },
    grid: {
      show: false,
    },
    fill: {
      colors: ["transparent", "#ffff00", "transparent"],
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "140px",
        borderRadius: 20,
        borderRadiusWhenStacked: true,
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 20,
            },
          },
        },
      },
    ],
  };

  const onSubmitHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "shotSize",
      path: "/shot-size",
      options: {
        body: {
          uom,
          shotWeight100,
          specificWeight,
          maxShotSize,
          barrelVolume,
          cutOffPositionPercentage_95_98,
          cushionPositionPercentage,
          shootVolume,
        },
      },
    });

    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);
    const {
      barrelTheoricVolume,
      barrelMaxWeight,
      overallShotSize,
      cushionPositionTheoric,
      shotSizePositionTheoric,
      shotPercentage,
      cutOffPositionTheoric,
      shotsInBarrel,
      barrelUsage,
      shotWeight1stStage,
    } = res;

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.shotWeight1stStage = shotWeight1stStage.toString();
        updated.barrelTheoricVolume = barrelTheoricVolume.toString();
        updated.barrelMaxWeight = barrelMaxWeight.toString();
        updated.overallShotSize = overallShotSize.toString();
        updated.cushionPositionTheoric = cushionPositionTheoric.toString();
        updated.shotSizePositionTheoric = shotSizePositionTheoric.toString();
        updated.shotPercentage = shotPercentage.toString();
        updated.cutOffPositionTheoric = cutOffPositionTheoric.toString();
        updated.shotsInBarrel = shotsInBarrel.toString();
        updated.barrelUsage = barrelUsage.toString();
        updated.cutOffPositionPercentage_95_98 = cutOffPositionPercentage_95_98.toString();
        updated.cushionPositionPercentage = cushionPositionPercentage.toString();
        updated.decompressionSize = decompressionSize.toString();
        updated.phase = "6";
      })
    )
      .then(async (res) => {
        let icon = "success";
        let text = "";
        if (parseFloat(res.barrelUsage) > resin.barrelOcupationMax) {
          icon = "warning";
          text +=
            "<li>La Ocupación de Barril es mayor a lo recomendado por el fabricante de la Resina</li>";
        } else if (parseFloat(res.barrelUsage) < resin.barrelOcupationMin) {
          icon = "warning";
          text +=
            "<li>La Ocupación de Barril es menor a lo recomendado por el fabricante de la Resina</li>";
        }
        if (res.cushionPositionTheoric < resin.cushionPositionMin) {
          icon = "warning";
          text +=
            "<li>La Posición de Colchón está por debajo del rango recomendado por el fabricante de la Resina.</li>";
        } else if (res.cushionPositionTheoric > resin.cushionPositionMax) {
          icon = "warning";
          text +=
            "<li>La Posición de Colchón está por encima del rango recomendado por el fabricante de la Resina.</li>";
        }

        if (res.cutOffPositionTheoric < resin.cushionPositionMin) {
          icon = "warning";
          text +=
            "<li>La <b>Posición de Corte</b> está por debajo del rango recomendado por el fabricante de la Resina.</li>";
        }
        if (validateCutOffPosition(res, resin, machine)) {
          icon = "warning";
          text +=
            "<li>La <b>Posición de Corte</b> está fuera el rango recomendado por el fabricante de la Resina, pero puede ser viable si la Posición de Colchón logra la posición recomendada.</li>";
        }

        if (!validateShotSizePosition(res, resin, machine)) {
          icon = "warning";
          text +=
            "<li>El <b>Tamaño de Disparo</b> está fuera el rango recomendado por el fabricante de la Resina</li>";
        }

        if (machine.maxShotSize < res.cushionPositionTheoric) {
          icon = "error";
          text +=
            "<li><b>Existe un error de información en el formulario,</b> la posición de colchon no puede ser mayor al máximo tamaño de disparo de la máquina.</li>";
        }
        if (res.cutOffPositionTheoric > machine.maxShotSize) {
          icon = "error";
          text +=
            "<li><b>Existe un error de información en el formulario,</b> la posición de corte no puede ser mayor al máximo tamaño de disparo de la máquina.</li>";
        }
        // barrelOcupationMin < barrelUsage && barrelOcupationMax > barrelUsage
        await updateProject(res);

        ConditionalsAlert(icon, text);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        updateLoading(false);
        setShowChart(true);
      });
  };

  const validateCutOffPosition = (res, resin, machine) => {
    const { cutOffPositionTheoric } = res;
    const { cushionPositionMax } = resin;
    const { maxShotSize } = machine;

    // Encuentra el valor mínimo y máximo entre cushionPositionMax y maxShotSize
    const minVal = Math.min(cushionPositionMax, maxShotSize);
    const maxVal = Math.max(cushionPositionMax, maxShotSize);

    // Verifica si cutOffPositionTheoric está dentro del rango definido por minVal y maxVal
    return cutOffPositionTheoric >= minVal && cutOffPositionTheoric <= maxVal;
  };

  const validateShotSizePosition = (res, resin, machine) => {
    const { shotSizePositionTheoric } = res;
    const { barrelOcupationMin, barrelOcupationMax } = resin;
    const { maxShotSize } = machine;

    // Calcula los límites del rango basado en el porcentaje de ocupación del barril
    const lowerLimit = maxShotSize * (barrelOcupationMin / 100);
    const upperLimit = maxShotSize * (barrelOcupationMax / 100);

    // Encuentra el valor mínimo y máximo entre los límites calculados
    const minVal = Math.min(lowerLimit, upperLimit);
    const maxVal = Math.max(lowerLimit, upperLimit);

    // Verifica si shotSizePositionTheoric está dentro del rango definido por minVal y maxVal
    return (
      shotSizePositionTheoric >= minVal && shotSizePositionTheoric <= maxVal
    );
  };

  return {
    showChart,
    setShowChart,
    onSubmitHandler,
    barChartOptions2,
    barChartData2,
    barChartOptions,
    barChartData,
    validateCutOffPosition,
    validateShotSizePosition,
  };
};
