import { useEffect, useState } from "react";
import {
  convertInCubeCmCube,
  convertInMm, convertInSecMmSec,
  convertLbKg,
  convertPsiBar,
  convertTonKn,
} from "../../../projects/individual/hooks/useConvertUom";
import { DataStore } from "aws-amplify/datastore";
import { Machine } from "../../../../models";
import { useAlert } from "../../../../contexts/useAlert";

export const useConvertMachineHooks = () => {
  const [uom, setUom] = useState("");
  const [items, setItems] = useState([]);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (uom) {
      getItems();
    }
  }, [uom]);

  const getItems = async () => {
    await DataStore.query(Machine, (c) => c.uom.eq(uom))
      .then((res) => {
        setItems(res);
      })
      .catch((err) => {
        showErrorAlert("Ha ocurrido un error al cargar las maquinas");
      });
  };

  const onSelectItem = (value) => {
    setItem(value);
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const data = convertItem(item);

      const savedItem = await DataStore.save(
        new Machine({
          ...data,
        })
      );
      setItem(savedItem);
      showSuccessAlert();
      setIsComplete(true);
    } catch (err) {
      showErrorAlert(
        err?.message
          ? err.message
          : "Ha ocurrido un error, favor de contactar con el administrador"
      );
    }
    setIsLoading(false);
  };

  const selectOptions = [
    {
      label: "INGLES",
      value: "INGLES",
    },
    {
      label: "INTERNACIONAL",
      value: "INTERNACIONAL",
    },
  ];
  const convertItem = (item) => {
    const { uom } = item;
    const { createdAt, updatedAt, ...allItem } = item;
    return {
      ...allItem,
      uom: uom === "INGLES" ? "INTERNACIONAL" : "INGLES",
      barrelVolume: convertInCubeCmCube(uom, item.barrelVolume),
      hopperCapacity: convertLbKg(uom, item.hopperCapacity),
      machineMaxEjection: convertInMm(uom, item.machineMaxEjection),
      machineTonnage: parseInt(convertTonKn(uom, item.machineTonnage)),
      maxHydraulicPressure: convertPsiBar(uom, item.maxHydraulicPressure),
      maxInjectionSpeed: convertInSecMmSec(uom, item.maxInjectionSpeed),
      maxOpenClamp: convertInMm(uom, item.maxOpenClamp),
      maxShotSize: convertInMm(uom, item.maxShotSize),
      maxStackMoldSize: convertInMm(uom, item.maxStackMoldSize),
      minStackMoldSize: convertInMm(uom, item.minStackMoldSize),
      pistonDiameter: convertInMm(uom, item.pistonDiameter),
      screwDiameter: convertInMm(uom, item.screwDiameter),
      tieBarHorizontalDistance: convertInMm(uom, item.tieBarHorizontalDistance),
      tieBarVerticalDistance: convertInMm(uom, item.tieBarVerticalDistance),
    };
  };

  return {
    selectOptions,
    item,
    setUom,
    setItem,
    isLoading,
    items,
    isComplete,
    setIsComplete,
    onSelectItem,
    onSubmitHandler,
  };
};
