export function checkValues(cavityBalalceTable) {
  const categoriesToIgnore = [
    "shootingWeight",
    "lowerWeight",
    "higherWeight",
    "maxMinDifference",
    "averageShotWeight",
    "unbalance",
  ];

  if (!cavityBalalceTable) {
    console.error("cavityBalalceTable is null");
    return false;
  }

  for (const [key, category] of Object.entries(cavityBalalceTable)) {
    if (categoriesToIgnore.includes(key)) continue;

    if (!Array.isArray(category)) {
      console.error(
        `Expected array for category ${key}, but received:`,
        category
      );
      continue;
    }

    const isInvalid = category.some((row) => {
      if (Array.isArray(row)) {
        return row.some(
          (item) =>
            item.hasOwnProperty("value") &&
            (item.value === "" || isNaN(parseFloat(item.value)))
        );
      } else {
        return row.value === "" || isNaN(parseFloat(row.value));
      }
    });

    if (isInvalid) {
      return true;
    }
  }
  return false;
}

function calculateStandardDeviation(arr) {
  const numbers = arr
    .map((item) => parseFloat(item.value))
    .filter((num) => !isNaN(num));

  if (numbers.length === 0) {
    return 0;
  }

  const mean = numbers.reduce((acc, val) => acc + val, 0) / numbers.length;
  const sumOfSquares = numbers.reduce((acc, val) => acc + (val - mean) ** 2, 0);

  return Math.sqrt(sumOfSquares / (numbers.length - 1));
}

export function calculateCavityWeightDeviation(cavityBalalceTable) {
  cavityBalalceTable.cavityWeight.forEach((subArray) => {
    const deviation = calculateStandardDeviation(subArray) * 100;

    const index = subArray.findIndex((item) => item.label === 6);
    if (index !== -1) {
      subArray[index] = { ...subArray[index], desviation: deviation };
    }
  });

  return cavityBalalceTable;
}

export function updateShootingWeightForCavityAndRunnersWeight(
  cavityBalalceTable
) {
  if (
    !cavityBalalceTable.shootingWeight ||
    !cavityBalalceTable.cavityWeight ||
    !cavityBalalceTable.runnersWeight
  ) {
    console.error("Required categories not found in the object");
    return cavityBalalceTable;
  }

  let shootingWeightCopy = cavityBalalceTable.shootingWeight.map((item) => ({
    ...item,
  }));

  for (let label = 1; label <= 5; label++) {
    let sum = 0;

    cavityBalalceTable.cavityWeight.forEach((subArray) => {
      const item = subArray.find((item) => item.label === label);
      if (item && !isNaN(parseFloat(item.value))) {
        sum += parseFloat(item.value);
      }
    });

    const runnersItem = cavityBalalceTable.runnersWeight.find(
      (item) => item.label === label
    );
    if (runnersItem && !isNaN(parseFloat(runnersItem.value))) {
      sum += parseFloat(runnersItem.value);
    }

    const shootingItem = shootingWeightCopy.find(
      (item) => item.label === label
    );
    if (shootingItem) {
      shootingItem.value = sum.toFixed(3); // Formatear a tres decimales
    }
  }

  cavityBalalceTable.shootingWeight = shootingWeightCopy;

  return cavityBalalceTable;
}

export function updateLowerWeightWithMinValues(cavityBalalceTable) {
  if (!cavityBalalceTable.lowerWeight || !cavityBalalceTable.cavityWeight) {
    console.error("Required categories not found in the object");
    return cavityBalalceTable;
  }

  let lowerWeightCopy = cavityBalalceTable.lowerWeight.map((item) => ({
    ...item,
  }));

  for (let label = 1; label <= 5; label++) {
    let minValues = [];

    cavityBalalceTable.cavityWeight.forEach((subArray) => {
      const item = subArray.find((item) => item.label === label);
      if (item && !isNaN(parseFloat(item.value))) {
        minValues.push(parseFloat(item.value));
      }
    });

    if (minValues.length > 0) {
      const minValue = Math.min(...minValues);

      const lowerWeightItem = lowerWeightCopy.find(
        (item) => item.label === label
      );
      if (lowerWeightItem) {
        lowerWeightItem.value = minValue.toFixed(3); // Formatear a tres decimales
      }
    }
  }

  cavityBalalceTable.lowerWeight = lowerWeightCopy;

  return cavityBalalceTable;
}

export function updateHigherWeightWithMaxValues(cavityBalalceTable) {
  if (!cavityBalalceTable.higherWeight || !cavityBalalceTable.cavityWeight) {
    console.error("Required categories not found in the object");
    return cavityBalalceTable;
  }

  let higherWeightCopy = cavityBalalceTable.higherWeight.map((item) => ({
    ...item,
  }));

  for (let label = 1; label <= 5; label++) {
    let maxValues = [];

    cavityBalalceTable.cavityWeight.forEach((subArray) => {
      const item = subArray.find((item) => item.label === label);
      if (item && !isNaN(parseFloat(item.value))) {
        maxValues.push(parseFloat(item.value));
      }
    });

    if (maxValues.length > 0) {
      const maxValue = Math.max(...maxValues);

      const higherWeightItem = higherWeightCopy.find(
        (item) => item.label === label
      );
      if (higherWeightItem) {
        higherWeightItem.value = maxValue.toFixed(3); // Formatear a tres decimales
      }
    }
  }

  cavityBalalceTable.higherWeight = higherWeightCopy;

  return cavityBalalceTable;
}

export function updateMaxMinDifference(cavityBalalceTable) {
  if (
    !cavityBalalceTable.maxMinDifference ||
    !cavityBalalceTable.cavityWeight
  ) {
    console.error("Required categories not found in the object");
    return cavityBalalceTable;
  }

  let maxMinDifferenceCopy = cavityBalalceTable.maxMinDifference.map(
    (item) => ({ ...item })
  );

  for (let label = 1; label <= 5; label++) {
    let values = [];

    cavityBalalceTable.cavityWeight.forEach((subArray) => {
      const item = subArray.find((item) => item.label === label);
      if (item && !isNaN(parseFloat(item.value))) {
        values.push(parseFloat(item.value));
      }
    });

    if (values.length > 0) {
      const maxValue = Math.max(...values);
      const minValue = Math.min(...values);
      const difference = maxValue - minValue;

      const maxMinDifferenceItem = maxMinDifferenceCopy.find(
        (item) => item.label === label
      );
      if (maxMinDifferenceItem) {
        maxMinDifferenceItem.value = difference.toFixed(3); // Formatear a tres decimales
      }
    }
  }

  cavityBalalceTable.maxMinDifference = maxMinDifferenceCopy;

  return cavityBalalceTable;
}

export function updateAverageShotWeight(cavityBalalceTable) {
  if (
    !cavityBalalceTable.averageShotWeight ||
    !cavityBalalceTable.cavityWeight
  ) {
    console.error("Required categories not found in the object");
    return cavityBalalceTable;
  }

  let averageShotWeightCopy = cavityBalalceTable.averageShotWeight.map(
    (item) => ({ ...item })
  );

  for (let label = 1; label <= 5; label++) {
    let sum = 0;
    let count = 0;

    cavityBalalceTable.cavityWeight.forEach((subArray) => {
      const item = subArray.find((item) => item.label === label);
      if (item && !isNaN(parseFloat(item.value))) {
        sum += parseFloat(item.value);
        count++;
      }
    });

    if (count > 0) {
      const average = sum / count;

      const averageShotWeightItem = averageShotWeightCopy.find(
        (item) => item.label === label
      );
      if (averageShotWeightItem) {
        averageShotWeightItem.value = average.toFixed(3); // Formatear a tres decimales
      }
    }
  }

  cavityBalalceTable.averageShotWeight = averageShotWeightCopy;

  return cavityBalalceTable;
}

export function updateUnbalance(cavityBalalceTable) {
  if (!cavityBalalceTable.unbalance || !cavityBalalceTable.cavityWeight) {
    console.error("Required categories not found in the object");
    return cavityBalalceTable;
  }

  let unbalanceCopy = cavityBalalceTable.unbalance.map((item) => ({ ...item }));

  for (let label = 1; label <= 5; label++) {
    let values = [];

    cavityBalalceTable.cavityWeight.forEach((subArray) => {
      const item = subArray.find((item) => item.label === label);
      if (item && !isNaN(parseFloat(item.value))) {
        values.push(parseFloat(item.value));
      }
    });

    if (values.length > 0) {
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);

      let result = (minValue / maxValue - 1) * 100;
      result = Math.abs(result);

      const unbalanceItem = unbalanceCopy.find((item) => item.label === label);
      if (unbalanceItem) {
        unbalanceItem.value = result.toFixed(3); // Formatear a tres decimales
      }
    }
  }

  cavityBalalceTable.unbalance = unbalanceCopy;

  return cavityBalalceTable;
}

export function updateCavityBalalceTable(cavityBalalceTable) {
  for (let label = 1; label <= 5; label++) {
    let cavityValues = cavityBalalceTable.cavityWeight
      .map((subArray) => subArray.find((item) => item.label === label).value)
      .filter((v) => !isNaN(parseFloat(v)))
      .map(parseFloat);
    let runnersValues = cavityBalalceTable.runnersWeight.find(
      (item) => item.label === label
    ).value;
    let shootingWeight = cavityValues.reduce(
      (acc, val) => acc + val,
      parseFloat(runnersValues)
    );
    let minValue = Math.min(...cavityValues);
    let maxValue = Math.max(...cavityValues);
    let difference = maxValue - minValue;
    let average =
      cavityValues.reduce((acc, val) => acc + val, 0) / cavityValues.length;
    let unbalance = Math.abs(minValue / maxValue - 1) * 100;

    cavityBalalceTable.shootingWeight.find(
      (item) => item.label === label
    ).value = shootingWeight;
    cavityBalalceTable.lowerWeight.find(
      (item) => item.label === label
    ).value = minValue;
    cavityBalalceTable.higherWeight.find(
      (item) => item.label === label
    ).value = maxValue;
    cavityBalalceTable.maxMinDifference.find(
      (item) => item.label === label
    ).value = difference;
    cavityBalalceTable.averageShotWeight.find(
      (item) => item.label === label
    ).value = average;
    cavityBalalceTable.unbalance.find(
      (item) => item.label === label
    ).value = unbalance;

    let deviation = calculateStandardDeviation(
      cavityBalalceTable.cavityWeight.map((subArray) =>
        subArray.find((item) => item.label === label)
      )
    );
    let deviationItem = cavityBalalceTable.cavityWeight.find((subArray) =>
      subArray.find((item) => item.label === 6)
    );
    if (deviationItem) {
      deviationItem.value = deviation * 100;
    }
  }
  return cavityBalalceTable;
}
