export const marginBottomDivider = 5;

export const textColor = "gray.600";
export const paddingXinputs = 2;

export const initialResinStateRadio = {
  uom: "INGLES",
  resinType: "PP",
  dryerType: "AIR_DRYER",
  chemicalStructure: "SEMICRISTALINO",
};

export const initialResinState = {
  maximumShrinkage: "",
  minimumPackingPressure: "",
  releaseTemperature: "",
  demoldingTemperature: "",
  minimumScrewLDRatio: "",
  maxScrewLDRatio: "",
  minimumVentDepth: "",
  resinName: "",
  resinCost: "",
  meltTempMin: "",
  thermalDiffusion: "",
  meltTempMax: "",
  nozzleTempMin: "",
  nozzleTempMax: "",
  FrontTempMin: "",
  FrontTempMax: "",
  midTempMin: "",
  midTempMax: "",
  backTempMin: "",
  backTempMax: "",
  toolAsideTempMin: "",
  toolAsideTempMax: "",
  toolBsideTempMin: "",
  toolBsideTempMax: "",
  dryTempMin: "",
  dryTempMax: "",
  dryTimeMin: "",
  dryTimeMax: "",
  dewPoint: "",
  maxMoisture: "",
  specificWeight: "",
  backPressureMax: "",
  backPressureMin: "",
  rpmMax: "",
  rpmMin: "",
  injectionSpeed: "",
  resinInjectionPressureMax: "",
  barrelOcupationMin: "",
  barrelOcupationMax: "",
  tonnageFactorMin: "",
  tonnageFactorMax: "",
  cushionPositionMin: "",
  cushionPositionMax: "",
  ventLong: "",
  ventWidth: "",
  reliefDeep: "",
  regrindPercentageMax: "",
};

export const resinColumnsData = [
  {
    Header: "Unidad de Medida",
    accessor: "uom",
    type: "radio",
  },
  {
    Header: "ID de Resina",
    accessor: "resinName",
    type: "string",
  },
  {
    Header: "Tipo de Resina",
    accessor: "resinType",
    type: "string",
  },
  {
    Header: "Estructura Quimica",
    accessor: "chemicalStructure",
    type: "radio",
  },

  {
    Header: "Costo de Resina",
    accessor: "resinCost",
    type: "string",
  },
  /*
  {
    Header: "Temperatura Melt Mínima",
    accessor: "meltTempMin",
    type: "string",
  },

  {
    Header: "Temperatura Melt Máxima",
    accessor: "meltTempMax",
    type: "string",
  },
  {
    Header: "Temperatura de Nariz Mínima",
    accessor: "nozzleTempMin",
    type: "string",
  },
  {
    Header: "Temperatura de Nariz Máxima",
    accessor: "nozzleTempMax",
    type: "string",
  },
  {
    Header: "Temperatura Frontal Mínima",
    accessor: "FrontTempMin",
    type: "string",
  },
  {
    Header: "Temperatura Frontal Máxima",
    accessor: "FrontTempMax",
    type: "string",
  },
  {
    Header: "Temperatura Central Mínima",
    accessor: "midTempMin",
    type: "string",
  },
  {
    Header: "Temperatura Central Máxima",
    accessor: "midTempMax",
    type: "string",
  },
  {
    Header: "Temperatura Trasera Mínima",
    accessor: "backTempMin",
    type: "string",
  },
  {
    Header: "Temperatura Trasera Máxima",
    accessor: "backTempMax",
    type: "string",
  },
  {
    Header: "Temperatura Molde Lado A Mínima",
    accessor: "toolAsideTempMin",
    type: "string",
  },
  {
    Header: "Temperatura Molde Lado A Máxima",
    accessor: "toolAsideTempMax",
    type: "string",
  },
  {
    Header: "Temperatura Molde Lado B Mínima",
    accessor: "toolBsideTempMin",
    type: "string",
  },
  {
    Header: "Temperatura Molde Lado B Máxima",
    accessor: "toolBsideTempMax",
    type: "string",
  },
  {
    Header: "Difusion Termica",
    accessor: "thermalDiffusion",
    type: "string",
  },
  {
    Header: "Temperatura de Desmoldeo",
    accessor: "demoldingTemperature",
    type: "string",
  },
  {
    Header: "Temperatura de Secado Mínima",
    accessor: "dryTempMin",
    type: "string",
  },
  {
    Header: "Temperatura de Secado Máxima",
    accessor: "dryTempMax",
    type: "string",
  },
  {
    Header: "Tiempo de Secado Mínimo",
    accessor: "dryTimeMin",
    type: "string",
  },
  {
    Header: "Tiempo de Secado Máximo",
    accessor: "dryTimeMax",
    type: "string",
  },
  {
    Header: "Tipo de Secador Recomendado",
    accessor: "dryerType",
    type: "radio",
  },
  {
    Header: "Dew Point",
    accessor: "dewPoint",
    type: "string",
  },
  {
    Header: "Porcentaje Máximo de Humedad",
    accessor: "maxMoisture",
    type: "string",
  },
  {
    Header: "Encogimiento Máximo",
    accessor: "maximumShrinkage",
    type: "string",
  },
  {
    Header: "Peso Específico de Proceso",
    accessor: "specificWeight",
    type: "string",
  },
  {
    Header: "Contrapresión Mínima",
    accessor: "backPressureMin",
    type: "string",
  },
  {
    Header: "Contrapresión Máxima",
    accessor: "backPressureMax",
    type: "string",
  },
  {
    Header: "RPM Mínima",
    accessor: "rpmMin",
    type: "string",
  },
  {
    Header: "RPM Máxima",
    accessor: "rpmMax",
    type: "string",
  },
  {
    Header: "Velocidad de Inyección",
    accessor: "injectionSpeed",
    type: "string",
  },
  {
    Header: "Máxima Presión de Inyección",
    accessor: "resinInjectionPressureMax",
    type: "string",
  },
  {
    Header: "Mínima Presión de Empaque",
    accessor: "minimumPackingPressure",
    type: "string",
  },
  {
    Header: "Ocupación de Barril Mínimo",
    accessor: "barrelOcupationMin",
    type: "string",
  },
  {
    Header: "Ocupacion de Barril Máximo",
    accessor: "barrelOcupationMax",
    type: "string",
  },
  {
    Header: "Factor de Tonelaje Mínimo",
    accessor: "tonnageFactorMin",
    type: "string",
  },
  {
    Header: "Factor de Tonelaje Máximo",
    accessor: "tonnageFactorMax",
    type: "string",
  },
  {
    Header: "Posición de Colchón Mínimo",
    accessor: "cushionPositionMin",
    type: "string",
  },
  {
    Header: "Posición de Colchón Máximo",
    accessor: "cushionPositionMax",
    type: "string",
  },
  {
    Header: "Relación L/D de Husillo Mínima",
    accessor: "minimumScrewLDRatio",
    type: "string",
  },
  {
    Header: "Relación L/D de Husillo Máxima",
    accessor: "maxScrewLDRatio",
    type: "string",
  },
  {
    Header: "Profundidad de Venteo Mínima (DEPTH)",
    accessor: "minimumVentDepth",
    type: "string",
  },
  {
    Header: "Longitud de Venteo (LAND)",
    accessor: "ventLong",
    type: "string",
  },
  {
    Header: "Ancho de Venteo (WIDTH)",
    accessor: "ventWidth",
    type: "string",
  },
  {
    Header: "Profundidad de Relief",
    accessor: "reliefDeep",
    type: "string",
  },
  {
    Header: "Porcentaje de Remolido Máximo",
    accessor: "regrindPercentageMax",
    type: "string",
  },
  {
    Header: "Temperatura de Desmoldeo",
    accessor: "releaseTemperature",
    type: "string",
  },

   */
];
