export const vcheckCushion = {
  cushion1: "",
  cushion2: "",
  cushion3: "",
  cushion4: "",
  cushion5: "",
  cushion6: "",
  cushion7: "",
  cushion8: "",
  cushion9: "",
  cushion10: "",
};

export const vcheckFillingTime = {
  fillingTime1: "",
  fillingTime2: "",
  fillingTime3: "",
  fillingTime4: "",
  fillingTime5: "",
  fillingTime6: "",
  fillingTime7: "",
  fillingTime8: "",
  fillingTime9: "",
  fillingTime10: "",
};

export const vcheckWeight = {
  weight1: "",
  weight2: "",
  weight3: "",
  weight4: "",
  weight5: "",
  weight6: "",
  weight7: "",
  weight8: "",
  weight9: "",
  weight10: "",
};
export const gaps = [
  { form: "", diameter: "", cavities: "", base: "", height: "" },
];

export const runners = [
  { form: "", diameter: "", cavities: "", base: "", height: "" },
];
export const cavities = [
  { form: "", diameter: "", cavities: "", base: "", height: "" },
];

export const viscoscityStudyDataMatrix = [
  {
    sequence: 1,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 2,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 3,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 4,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 5,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 6,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 7,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 8,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 9,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
  {
    sequence: 10,
    relativeViscocity: "",
    shearRate: "",
    speedEfficiency: "",
    shotWeight: "",
    flowRate: "",
    vpCuttingPosition: "",
    maxPressureHpsi: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
  },
];

export const lineavilityTable = [
  {
    sequence: 1,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 2,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 3,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 4,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 5,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 6,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 7,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 8,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 9,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
  {
    sequence: 10,
    relativeViscocity: "",
    shearRate: "",
    linearity: "",
    shotWeight: "",
    maxShearRate: "",
    vpCuttingPosition: "",
    realSpeedMachine: "",
    intervalSpeed: "",
    fillingTime: "",
    maxPressurePpsi: "",
    plasticRheology: "",
  },
];

export const cavityBalanceTable = {
  injectionSpeed: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  averageShotWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  inyectionTime: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  higherWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  unbalance: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  shootingWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  cavityWeight: [
    [
      { label: 1, value: "" },
      { label: 2, value: "" },
      { label: 3, value: "" },
      { label: 4, value: "" },
      { label: 5, value: "" },
      { label: 6, desviation: "" },
    ],
  ],
  maxInjectionPressure: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  maxMinDifference: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  runnersWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
  lowerWeight: [
    { label: 1, value: "" },
    { label: 2, value: "" },
    { label: 3, value: "" },
    { label: 4, value: "" },
    { label: 5, value: "" },
  ],
};

export const speedSelected = {
  injectionSpeed: "",
  averageShotWeight: "",
  inyectionTime: "",
  higherWeight: "",
  unbalance: "",
  shootingWeight: "",
  cavityWeight: [],
  maxInjectionPressure: "",
  label: "",
  maxMinDifference: "",
  runnersWeight: "",
  lowerWeight: "",
};

export const minPackTable = [
  {
    shot: 1,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 2,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 3,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 4,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 5,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 6,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 7,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 8,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 9,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 10,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
];
export const maxPackTable = [
  {
    shot: 1,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 2,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 3,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 4,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 5,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 6,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 7,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 8,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 9,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
  {
    shot: 10,
    cycleTime: "",
    cushion: "",
    shootingWeight: "",
    holdTime: "",
  },
];

export const minGateSealingData = {
  cycleTime: "",
  cushion: "",
  shootingWeight: "",
  holdTime: "",
  shot: "",
};
export const maxGateSealingData = {
  cycleTime: "",
  cushion: "",
  shootingWeight: "",
  holdTime: "",
  shot: "",
};

export const doeTable = [
  {
    shot: 1,
    sustaining: "MIN",
    tempStatus: "MIN",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 2,
    sustaining: "MIN",
    tempStatus: "NOM",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 3,
    sustaining: "MIN",
    tempStatus: "MAX",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 4,
    sustaining: "NOM",
    tempStatus: "MIN",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 5,
    sustaining: "NOM",
    tempStatus: "NOM",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 6,
    sustaining: "NOM",
    tempStatus: "MAX",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 7,
    sustaining: "MAX",
    tempStatus: "MIN",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 8,
    sustaining: "MAX",
    tempStatus: "NOM",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
  {
    shot: 9,
    sustaining: "MAX",
    tempStatus: "MAX",
    order: "",
    holdPress: "",
    temp: "",
    cooling: "",
    maxInjPress: "",
    cushion: "",
    injTime: "",
    recovery: "",
    cycleTime: "",
    shootingWeight: "",
  },
];

export const dimensionTestTable = [
  {
    dimension: 1,
    nomDimension: "",
    minTolerance: "",
    maxTolerance: "",
    minToleranceResult: "",
    maxToleranceResult: "",
    data: {
      "MIN-MIN": "",
      "MIN-NOM": "",
      "MIN-MAX": "",
      "NOM-MIN": "",
      "NOM-NOM": "",
      "NOM-MAX": "",
      "MAX-MIN": "",
      "MAX-NOM": "",
      "MAX-MAX": "",
    },
  },
  {
    dimension: 2,
    nomDimension: "",
    minTolerance: "",
    maxTolerance: "",
    minToleranceResult: "",
    maxToleranceResult: "",
    data: {
      "MIN-MIN": "",
      "MIN-NOM": "",
      "MIN-MAX": "",
      "NOM-MIN": "",
      "NOM-NOM": "",
      "NOM-MAX": "",
      "MAX-MIN": "",
      "MAX-NOM": "",
      "MAX-MAX": "",
    },
  },
  {
    dimension: 3,
    nomDimension: "",
    minTolerance: "",
    maxTolerance: "",
    minToleranceResult: "",
    maxToleranceResult: "",
    data: {
      "MIN-MIN": "",
      "MIN-NOM": "",
      "MIN-MAX": "",
      "NOM-MIN": "",
      "NOM-NOM": "",
      "NOM-MAX": "",
      "MAX-MIN": "",
      "MAX-NOM": "",
      "MAX-MAX": "",
    },
  },
  {
    dimension: 4,
    nomDimension: "",
    minTolerance: "",
    maxTolerance: "",
    minToleranceResult: "",
    maxToleranceResult: "",
    data: {
      "MIN-MIN": "",
      "MIN-NOM": "",
      "MIN-MAX": "",
      "NOM-MIN": "",
      "NOM-NOM": "",
      "NOM-MAX": "",
      "MAX-MIN": "",
      "MAX-NOM": "",
      "MAX-MAX": "",
    },
  },
  {
    check: {
      "MIN-MIN": "",
      "MIN-NOM": "",
      "MIN-MAX": "",
      "NOM-MIN": "",
      "NOM-NOM": "",
      "NOM-MAX": "",
      "MAX-MIN": "",
      "MAX-NOM": "",
      "MAX-MAX": "",
    },
    final: {
      "MIN-MIN": "",
      "MIN-NOM": "",
      "MIN-MAX": "",
      "NOM-MIN": "",
      "NOM-NOM": "",
      "NOM-MAX": "",
      "MAX-MIN": "",
      "MAX-NOM": "",
      "MAX-MAX": "",
    },
  },
];
