export const cushionInitialState = {
  cushion1: "",
  cushion2: "",
  cushion3: "",
  cushion4: "",
  cushion5: "",
  cushion6: "",
  cushion7: "",
  cushion8: "",
  cushion9: "",
  cushion10: "",
};

export const weightInitialState = {
  weight1: "",
  weight2: "",
  weight3: "",
  weight4: "",
  weight5: "",
  weight6: "",
  weight7: "",
  weight8: "",
  weight9: "",
  weight10: "",
};

export const fillingTimeInitialState = {
  fillingTime1: "",
  fillingTime2: "",
  fillingTime3: "",
  fillingTime4: "",
  fillingTime5: "",
  fillingTime6: "",
  fillingTime7: "",
  fillingTime8: "",
  fillingTime9: "",
  fillingTime10: "",
};
