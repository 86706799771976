import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faEnvelope,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import geaLogo from "../../../../assets/img/collaborators/gea/Logo-GEAB-Color.png";
import pdf from "../../../../assets/pdf/brochure-gea.pdf";
import { useSendEmailHooks } from "../../hooks/useSendEmailHooks";
import SendEmailConfirmationModal from "../../components/SendEmailConfirmationModal";
import {contactEmail} from "../utilities";

const Contact = ({ collaborator }) => {
  const {
    sendEmail,
    setEmail,
    email,
    setCompany,
    company,
    onOpen,
    isOpen,
    onClose,
    isLoading,
    message,
    setMessage,
    name,
    setName,
    type,
  } = useSendEmailHooks();

  const descargarPDF = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name; // Puedes personalizar el nombre del archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const textColor = "white";
  return (
    <Grid
      mt={5}
      borderRadius={15}
      backgroundColor={"#4d7dbf"}
      mx={5}
      p={5}
      justifyContent={"center"}
      templateColumns="repeat(2, 1fr)"
      gap={6}
    >
      <SendEmailConfirmationModal
        type={type}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        collaborator={collaborator}
      />
      <Grid gap={6}>
        <Text fontWeight={"bold"} fontSize={"2xl"} color={textColor}>
          Dejanos un mensaje
        </Text>
        <FormControl width="100%">
          <FormLabel color={textColor}>Nombre</FormLabel>
          <InputGroup size="md" borderColor="gray.600">
            <Input
              value={name}
              color="gray.200"
              placeholder="ej: Juan Perez"
              onChange={(e) => setName(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <FontAwesomeIcon color={textColor} icon={faUser} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl width="100%">
          <FormLabel color={textColor}>Empresa</FormLabel>
          <InputGroup size="md" borderColor="gray.600">
            <Input
              value={company}
              color="gray.200"
              placeholder={`ej: ${collaborator}`}
              onChange={(e) => setCompany(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <FontAwesomeIcon color={textColor} icon={faBriefcase} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl width="100%">
          <FormLabel color={textColor}>Email</FormLabel>
          <InputGroup size="md" borderColor="gray.600">
            <Input
              value={email}
              color="gray.200"
              type={"email"}
              placeholder="ej: ejemplo@ejemplo.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <FontAwesomeIcon color={textColor} icon={faEnvelope} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="message">
          <FormLabel color={textColor}>Mensaje </FormLabel>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Dejanos tu mensaje."
            minH="120px"
            fontSize="xs"
            borderWidth={1}
            borderColor="gray.600"
            color="gray.200"
            borderRadius={15}
          />
        </FormControl>
        <Button
          isLoading={isLoading}
          colorScheme={"facebook"}
          disabled={!name || !email || !message || !company}
          onClick={() => sendEmail(contactEmail)}
        >
          Enviar
        </Button>
      </Grid>
      <Grid alignItems={"center"} gap={6}>
        <Button
          mr={5}
          colorScheme={"facebook"}
          onClick={() =>
            descargarPDF(pdf, "presentacion-de-negocio-gintec.pdf")
          }
        >
          Descargar Catálogo
        </Button>
        <Grid justifyItems={"center"}>
          <Image src={geaLogo} width={"80%"} borderRadius={15} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
