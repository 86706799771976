// hooks/useMoldCount.js
import { useState, useEffect } from 'react';
import {DataStore} from "aws-amplify/datastore";
import {Tool} from "../../../../models";

const useMoldCount = () => {
  const [moldCount, setMoldCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMoldCount = async () => {
      try {
        const tools = await DataStore.query(Tool);
        setMoldCount(tools.length); // Usa la longitud de la lista
      } catch (error) {
        console.error('Error al obtener el conteo de moldes:', error);
      }
      setIsLoading(false);
    };

    fetchMoldCount();
  }, []);

  return { moldCount, isLoading };
};

export default useMoldCount;
