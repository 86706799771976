export const lineChartOptionsMelt = {
  chart: {
    toolbar: {
      show: false,
    },
  },

  tooltip: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: ["Melt", "Nariz", "Frontal", "Central", "Trasera"],
    labels: {
      style: {
        colors: "#000",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#aaa",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: true,
    labels: {
      colors: ['#000', '#000', '#000'], // Ajusta los colores de las etiquetas de la leyenda
      useSeriesColors: false // Desactiva el uso de los colores de las series para las etiquetas
    },
    // ... otras configuraciones de leyenda
  },
  grid: {
    strokeDashArray: 5,
    borderColor: "#000",
  },
  fill: {
    gradient: {
      shade: "dark",
      type: "vertical",
      shadeIntensity: 0,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#000", "#000"],
  },
  colors: ["#582CFE", "#582CFE", "#ff0000"],
};
