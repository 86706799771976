import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import Data from "../convert/Data";
import React from "react";
import { textColor } from "../utilities/variables";

const ShowModal = ({ isOpen, onClose, item }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"5xl"} p={0}>
      <ModalContent p={0} style={{ padding: 0 }}>
        <Grid
          templateColumns="repeat(4, 1fr)"
          px={10}
          gap={6}
          justifyItems={"space-between"}
          justifyContent={"center"}
        >
          <GridItem colSpan={4} justifyContent={"center"} mt={"50px"} mb={"30px"}>
            <Text fontSize={"2xl"}>Datos de la Máquina</Text>
          </GridItem>
          <Data textColor={textColor} item={item} />
          <GridItem colSpan={4} my={5} py={5} >
            <Grid templateColumns="repeat(1, 1fr)" >
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Cerrar
                </Button>
            </Grid>

          </GridItem>

        </Grid>
      </ModalContent>
    </Modal>
  );
};

export default ShowModal;
