import { Button, Grid, GridItem, Input, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useWindowProcessHooks } from "../../hooks/useWindowProcessHooks";
import ProjectContext from "../../../../../../../../contexts/ProjectContext";
import { DataStore } from "aws-amplify/datastore";
import { Project } from "../../../../../../../../models";
import { borderColor } from "../../../../utilities/variables";

const DoeTable = () => {
  const { project, updateLoading, isLoading, updateProject } = useContext(
    ProjectContext
  );

  const [saved, setSaved] = useState(false);
  const { handleInputChangeDoe } = useWindowProcessHooks();

  const saveDoeTable = async () => {
    updateLoading(true);
    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.doeTable = project.doeTable;
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  if (project) {
    return (
      <Grid
        borderWidth={1}
        p={5}
        borderRadius={15}
        mx={5}
        borderColor={borderColor}
      >
        <Grid justifyItems={"center"} mb={5}>
          <Text alignSelf={"center"} fontSize={"xl"} fontWeight={"bold"}>
            TABLA DOE
          </Text>
        </Grid>
        <Grid templateColumns="repeat(13, 1fr)" templateRows="repeat(11, 1fr)">
          <GridItem
            size={"xs"}
            px={2}
            borderTopLeftRadius={15}
            alignItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
            alignContent={"center"}
          >
            <Text
              textAlign={"center"}
              pt={3}
              fontSize={"xs"}
              fontWeight={"bold"}
            >
              MUESTRA
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              SOSTENIMIENTO
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              TEMPERATURA
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              ORDEN
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={1} fontWeight={"bold"}>
              HOLD PRESS
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              TEMPERATURA
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              COOLING
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={1} fontWeight={"bold"}>
              MAX. INJ. PRESS
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              CUSHION
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              INJ. TIME
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={3} fontWeight={"bold"}>
              RECOVERY
            </Text>
          </GridItem>
          <GridItem
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={1} fontWeight={"bold"}>
              CYCLE TIME
            </Text>
          </GridItem>
          <GridItem
            borderTopRightRadius={15}
            px={1}
            size={"xs"}
            justifyItems={"center"}
            borderWidth={1}
            borderColor={borderColor}
          >
            <Text fontSize={"xs"} pt={1} fontWeight={"bold"}>
              PESO DE DISPARO
            </Text>
          </GridItem>
          {project?.doeTable?.map((item, index) => {
            return (
              <React.Fragment>
                <GridItem
                  pt={"10%"}
                  pl={"45%"}
                  borderBottomLeftRadius={index === 8 ? 15 : ""}
                  size={"xs"}
                  alignItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Text fontSize={"xs"} fontWeight={"bold"}>
                    {item.shot}
                  </Text>
                </GridItem>
                <GridItem
                  pt={"10%"}
                  pl={"35%"}
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Text fontSize={"xs"} fontWeight={"bold"}>
                    {item.sustaining}
                  </Text>
                </GridItem>
                <GridItem
                  pt={"10%"}
                  pl={"35%"}
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Text fontSize={"xs"} fontWeight={"bold"}>
                    {item.tempStatus}
                  </Text>
                </GridItem>
                <GridItem
                  pt={"10%"}
                  pl={"40%"}
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Text fontSize={"xs"} fontWeight={"bold"}>
                    {item.order}
                  </Text>
                </GridItem>
                <GridItem
                  pt={"10%"}
                  pl={"20%"}
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Text fontSize={"xs"} fontWeight={"bold"}>
                    {item.holdPress}
                  </Text>
                </GridItem>
                <GridItem
                  pt={"10%"}
                  pl={"35%"}
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Text fontSize={"xs"} fontWeight={"bold"}>
                    {item.temp}
                  </Text>
                </GridItem>
                <GridItem
                  pt={"10%"}
                  pl={"35%"}
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Text fontSize={"xs"} fontWeight={"bold"}>
                    {item.cooling}
                  </Text>
                </GridItem>
                <GridItem
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Input
                    placeholder={"0.00"}
                    size={"xs"}
                    border={0}
                    value={item.maxInjPress}
                    onChange={(e) =>
                      handleInputChangeDoe(index, "maxInjPress", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Input
                    border={0}
                    placeholder={"0.00"}
                    size={"xs"}
                    value={item.cushion}
                    onChange={(e) =>
                      handleInputChangeDoe(index, "cushion", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Input
                    border={0}
                    placeholder={"0.00"}
                    size={"xs"}
                    value={item.injTime}
                    onChange={(e) =>
                      handleInputChangeDoe(index, "injTime", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Input
                    border={0}
                    placeholder={"0.00"}
                    size={"xs"}
                    value={item.recovery}
                    onChange={(e) =>
                      handleInputChangeDoe(index, "recovery", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Input
                    border={0}
                    placeholder={"0.00"}
                    size={"xs"}
                    value={item.cycleTime}
                    onChange={(e) =>
                      handleInputChangeDoe(index, "cycleTime", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem
                  borderBottomRightRadius={index === 8 ? 15 : ""}
                  size={"xs"}
                  justifyItems={"center"}
                  borderWidth={1}
                  borderColor={borderColor}
                >
                  <Input
                    border={0}
                    placeholder={"0.00"}
                    size={"xs"}
                    value={item.shootingWeight}
                    onChange={(e) =>
                      handleInputChangeDoe(
                        index,
                        "shootingWeight",
                        e.target.value
                      )
                    }
                  />
                </GridItem>
              </React.Fragment>
            );
          })}
        </Grid>
        <Button
          colorScheme={"facebook"}
          isLoading={isLoading}
          onClick={() => {
            saveDoeTable();
            setSaved(true);
          }}
        >
          {saved ? "Editar Tabla Doe" : "Guardar Tabla Doe"}
        </Button>
      </Grid>
    );
  }
};

export default DoeTable;
