import { lbKg, ozGr, temp } from "../../../hooks/useUomTransform";

export const inputData = (data) => {
  const temperature = temp(data?.header?.uom);
  console.log(
    "max: ",
    data?.meltTempMax,
    "min: ",
    data?.meltTempMin,
    "real: ",
    data?.realMeltTemp
  );
  return [
    {
      label: "Temperatura de Masa",
      value: data?.realMeltTemp,
      id: "realMeltTemp",
      addOn: temperature,
      error:
        parseFloat(data?.meltTempMax) < parseFloat(data?.realMeltTemp)
          ? "La Temperatura Real esta por encima de la Temperatura Máxima Recomendada."
          : parseFloat(data?.meltTempMin) > parseFloat(data?.realMeltTemp)
          ? "La Temperatura Real esta por debajo de la Temperatura Mínima Recomendada. "
          : "",
    },
    {
      label: "Mínima Temperatura de Masa",
      value: data?.meltTempMin,
      id: "meltTempMin",
      addOn: temperature,
      error:
        parseFloat(data?.meltTempMin) < 0 &&
        "La Temperatura Mínima tiene que ser mayor que 0.",
    },
    {
      label: "Máxima Temperatura de Masa",
      value: data?.meltTempMax,
      id: "meltTempMax",
      addOn: temperature,
      error:
        data?.meltTempMax &&
        parseFloat(data?.meltTempMax) <= parseFloat(data?.meltTempMin) &&
        "La Temperatura Máxima tiene que ser mayor que la mínima.",
    },
    {
      label: "Temperatura Real de Nariz",
      value: data?.realNozzleTemp,
      id: "realNozzleTemp",
      addOn: temperature,
      error:
        parseFloat(data?.nozzleTempMax) < parseFloat(data?.realNozzleTemp)
          ? "La Temperatura Real esta por encima de la Temperatura Máxima Recomendada."
          : parseFloat(data?.nozzleTempMin) > parseFloat(data?.realNozzleTemp)
          ? "La Temperatura Real esta por debajo de la Temperatura Mínima Recomendada. "
          : "",
    },
    {
      label: "Mínima Temperatura de Nariz",
      value: data?.nozzleTempMin,
      id: "nozzleTempMin",
      addOn: temperature,
      error:
        parseFloat(data?.nozzleTempMin) < 0 &&
        "La Temperatura Mínima tiene que ser mayor que 0.",
    },
    {
      label: "Máxima Temperatura de Nariz",
      value: data?.nozzleTempMax,
      id: "nozzleTempMax",
      addOn: temperature,
      error:
        data?.nozzleTempMax &&
        parseFloat(data?.nozzleTempMax) <= parseFloat(data?.nozzleTempMin) &&
        "La Temperatura Máxima tiene que ser mayor que la mínima.",
    },
    {
      label: "Temperatura Frontal Real",
      value: data?.realFrontTemp,
      id: "realFrontTemp",
      addOn: temperature,
      error:
        parseFloat(data?.frontTempMax) < parseFloat(data?.realFrontTemp)
          ? "La Temperatura Real esta por encima de la Temperatura Máxima Recomendada."
          : parseFloat(data?.frontTempMin) > parseFloat(data?.realFrontTemp)
          ? "La Temperatura Real esta por debajo de la Temperatura Mínima Recomendada. "
          : "",
    },
    {
      label: "Mínima Temperatura Frontal",
      value: data?.frontTempMin,
      id: "frontTempMin",
      addOn: temperature,
      error:
        parseFloat(data?.frontTempMin) < 0 &&
        "La Temperatura Mínima tiene que ser mayor que 0.",
    },
    {
      label: "Máxima Temperatura Frontal",
      value: data?.frontTempMax,
      id: "frontTempMax",
      addOn: temperature,
      error:
        data?.frontTempMax &&
        parseFloat(data?.frontTempMax) <= parseFloat(data?.frontTempMin) &&
        "La Temperatura Máxima tiene que ser mayor que la mínima.",
    },
    {
      label: "Temperatura Central Real",
      value: data?.realMidTemp,
      id: "realMidTemp",
      addOn: temperature,
      error:
        parseFloat(data?.midTempMax) < parseFloat(data?.realMidTemp)
          ? "La Temperatura Real esta por encima de la Temperatura Máxima Recomendada."
          : parseFloat(data?.midTempMin) > parseFloat(data?.realMidTemp)
          ? "La Temperatura Real esta por debajo de la Temperatura Mínima Recomendada. "
          : "",
    },
    {
      label: "Mínima Temperatura Central",
      value: data?.midTempMin,
      id: "midTempMin",
      addOn: temperature,
      error:
        parseFloat(data?.midTempMin) < 0 &&
        "La Temperatura Mínima tiene que ser mayor que 0.",
    },
    {
      label: "Máxima Temperatura Central",
      value: data?.midTempMax,
      id: "midTempMax",
      addOn: temperature,
      error:
        data?.midTempMax &&
        parseFloat(data?.midTempMax) <= parseFloat(data?.midTempMin) &&
        "La Temperatura Máxima tiene que ser mayor que la mínima.",
    },
    {
      label: "Temperatura Trasera Real",
      value: data?.realBackTemp,
      id: "realBackTemp",
      addOn: temperature,
      error:
        parseFloat(data?.backTempMax) < parseFloat(data?.realBackTemp)
          ? "La Temperatura Real esta por encima de la Temperatura Máxima Recomendada."
          : parseFloat(data?.backTempMin) > parseFloat(data?.realBackTemp)
          ? "La Temperatura Real esta por debajo de la Temperatura Mínima Recomendada. "
          : "",
    },
    {
      label: "Mínima Temperatura Trasera",
      value: data?.backTempMin,
      id: "backTempMin",
      addOn: temperature,
      error:
        parseFloat(data?.backTempMin) < 0 &&
        "La Temperatura Mínima tiene que ser mayor que 0.",
    },
    {
      label: "Máxima Temperatura Trasera",
      value: data?.backTempMax,
      id: "backTempMax",
      addOn: temperature,
      error:
        data?.backTempMax &&
        parseFloat(data?.backTempMax) <= parseFloat(data?.backTempMin) &&
        "La Temperatura Máxima tiene que ser mayor que la mínima.",
    },
  ];
};
