import { useContext, useEffect, useState } from "react";
import { weightInitialState } from "../utilities/variables";
import ProjectContext from "../../../../../../../contexts/ProjectContext";

export const useWeightHooks = () => {
  const [weight, setWeight] = useState(weightInitialState);
  const [weightAvg, setWeightAvg] = useState("Faltan Datos");
  const [weightMin, setWeightMin] = useState("Faltan Datos");
  const [weightMax, setWeightMax] = useState("Faltan Datos");
  const [weightDiff, setWeightDiff] = useState("Faltan Datos");
  const [weightArray, setWeightArray] = useState([]);

  const { project } = useContext(ProjectContext);

  useEffect(() => {
    if (project.vcheckWeight) {
      setWeight(project.vcheckWeight);
    }
  }, [project]);

  useEffect(() => {
    calculate();
  }, [weight]);

  const calculate = async () => {
    const {
      weight1,
      weight2,
      weight3,
      weight4,
      weight5,
      weight6,
      weight7,
      weight8,
      weight9,
      weight10,
    } = weight;
    if (
      weight1 &&
      weight2 &&
      weight3 &&
      weight4 &&
      weight5 &&
      weight6 &&
      weight7 &&
      weight8 &&
      weight9 &&
      weight10 &&
      weight1 > 0 &&
      weight2 > 0 &&
      weight3 > 0 &&
      weight4 > 0 &&
      weight5 > 0 &&
      weight6 > 0 &&
      weight7 > 0 &&
      weight8 > 0 &&
      weight9 > 0 &&
      weight10 > 0 &&
      weight1 !== "" &&
      weight2 !== "" &&
      weight3 !== "" &&
      weight4 !== "" &&
      weight5 !== "" &&
      weight6 !== "" &&
      weight7 !== "" &&
      weight8 !== "" &&
      weight9 !== "" &&
      weight10 !== ""
    ) {
      const data = [
        weight1,
        weight2,
        weight3,
        weight4,
        weight5,
        weight6,
        weight7,
        weight8,
        weight9,
        weight10,
      ];
      await setWeightArray(data);
      await setMinAndMax(data);
    } else {
      setWeightArray([]);
      setWeightMax("Faltan Datos");
      setWeightMin("Faltan Datos");
      setWeightDiff("Faltan Datos");
      setWeightAvg("Faltan Datos");
    }
  };

  const setMinAndMax = async (data) => {
    setWeightMax(Math.max(...data).toFixed(5));
    setWeightMin(Math.min(...data).toFixed(5));
  };

  useEffect(() => {
    if ((weightMin, weightMax)) {
      setWeightDiff(Math.abs(weightMin - weightMax).toFixed(5));
    } else {
      setWeightDiff("Faltan Datos");
    }
  }, [weightMin, weightMax]);

  useEffect(() => {
    if (weightArray.length === 10) {
      let values = weightArray;
      let sum = 0;
      values.map((item, current) => (sum += item));
      let avg = sum / values.length;
      setWeightAvg(avg.toFixed(5));
    } else {
      setWeightAvg("Faltan Datos");
    }
  }, [weightArray]);

  const lineChartOptionsWeight = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        show: false,
        enabled: false,
        position: "bottomLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 130,
        offsetX: 160,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "#008FFB",
        },
        labels: {
          show: false,
          style: {
            colors: "#008FFB",
            fontSize: "12px",
          },
        },
        title: {
          show: false,
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    legend: {
      show: false,
      color: "white",
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      borderColor: "#FEB019",
    },
    colors: ["#FEB019", "#FFFFFF", "#FEB019"],
  };

  const lineChartDataWeight = [
    {
      name: "Peso",
      type: "line",
      data: weightArray,
    },
  ];

  return {
    weight,
    setWeight,
    weightAvg,
    weightMin,
    weightMax,
    weightDiff,
    weightArray,
    lineChartOptionsWeight,
    lineChartDataWeight,
  };
};
