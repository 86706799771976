import React, { useEffect, useState } from "react";
import { Grid, Text } from "@chakra-ui/react";
import tecnicasPlasticasLogo from "./../../assets/img/collaborators/tp/tcplFooter.png";
import gintecLogo from "./../../assets/img/collaborators/gintec/gintecFooter.png";
import asacleanLogo from "./../../assets/img/collaborators/asaclean/asaCleanFooter.png";
import geaLogo from "./../../assets/img/collaborators/gea/geaFooter.png";
import rediasaLogo from "./../../assets/img/collaborators/rediassa/rediasaFooter.png";
import shelterLogo from "./../../assets/img/collaborators/shelter/shelter-logo.png";
import ldLogo from "../../assets/img/collaborators/ld/LD_LOGO_WHITE.avif";

import CollaboratorsBasicInfo1 from "./components/CollaboratorsBasicInfo1";
import CollaboratorsBasicInfo2 from "./components/CollaboratorsBasicInfo2";
import {
  asaHead1,
  asaHead2,
  asaHead3,
  asaHead4,
  asaText1,
  asaText2,
  asaText3,
  geaHead1,
  geaHead2,
  geaHead3,
  geaHead4,
  geaText1,
  geaText2,
  geaText3,
  gintecHead1,
  gintecHead2,
  gintecHead3,
  gintecHead4,
  gintecText1,
  gintecText2,
  gintecText3,
  ldHead1,
  ldHead2,
  ldHead3,
  ldHead4,
  ldText1,
  ldText2,
  ldText3,
  rediasaHead1,
  rediasaHead2,
  rediasaHead3,
  rediasaHead4,
  rediasaText1,
  rediasaText2,
  rediasaText3,
  shelterHead1,
  shelterHead2,
  shelterHead3,
  shelterHead4,
  shelterText1,
  shelterText2,
  shelterText3,
  tpHead1,
  tpHead2,
  tpHead3,
  tpHead4,
  tpText1,
  tpText2,
  tpText3,
} from "./utilities";
import HelmetComponent from "../../components/HelmetComponent";
import {
  metaDescriptionIndex,
  metaKeywordsIndex,
  metaTitleIndex,
} from "./utilities/metaVariables";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";

export const CollaboratorsIndex = () => {
  const textColor = "#265ab2";

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkAuthState();

    // Escuchar los cambios de estado de autenticación
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        setIsAuthenticated(true);
      } else if (payload.event === "signOut") {
        setIsAuthenticated(false);
      }
    });
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };
  return (
    <React.Fragment mt={"150px"}>
      <HelmetComponent
        description={metaDescriptionIndex}
        keywords={metaKeywordsIndex}
        title={metaTitleIndex}
      />
      <Grid
        mt={"180px"}
        color={"white"}
        justifyContent={"center"}
        gap={6}
        borderWidth={1}
        mx={5}
        p={5}
        borderRadius={15}
        opacity={0.9}
        backgroundColor={"gray.50"}
      >
        <Text fontSize={"2xl"} fontWeight={"bold"} color={textColor}>
          Colaboradores
        </Text>
      </Grid>
      <CollaboratorsBasicInfo1
        isAuthenticated={isAuthenticated}
        ariaLabel={"Lee mas acerca de Tecnicas Plasticas"}
        backGroungColor={"#ffcd35"}
        image={tecnicasPlasticasLogo}
        textColor={textColor}
        text1={tpText1}
        text2={tpText2}
        text3={tpText3}
        heading1={tpHead1}
        heading2={tpHead2}
        heading3={tpHead3}
        heading4={tpHead4}
        url={"tecnicas-plasticas"}
      />
      <CollaboratorsBasicInfo2
        isAuthenticated={isAuthenticated}
        ariaLabel={"Lee mas acerca de Gintec"}
        backGroungColor={"gray.200"}
        image={gintecLogo}
        textColor={"#36344d"}
        text1={gintecText1}
        text2={gintecText2}
        text3={gintecText3}
        heading1={gintecHead1}
        heading2={gintecHead2}
        heading3={gintecHead3}
        heading4={gintecHead4}
        url={"gintec"}
      />
      <CollaboratorsBasicInfo1
        isAuthenticated={isAuthenticated}
        ariaLabel={"Lee mas acerca de Asa Clean"}
        backGroungColor={"#f7f2ca"}
        image={asacleanLogo}
        textColor={"black"}
        text1={asaText1}
        text2={asaText2}
        text3={asaText3}
        heading1={asaHead1}
        heading2={asaHead2}
        heading3={asaHead3}
        heading4={asaHead4}
        url={"asaclean"}
      />
      <CollaboratorsBasicInfo2
        isAuthenticated={isAuthenticated}
        ariaLabel={"Lee mas acerca de Gea"}
        backGroungColor={"#388720"}
        image={geaLogo}
        textColor={"gray.50"}
        text1={geaText1}
        text2={geaText2}
        text3={geaText3}
        heading1={geaHead1}
        heading2={geaHead2}
        heading3={geaHead3}
        heading4={geaHead4}
        url={"gea"}
      />
      <CollaboratorsBasicInfo1
        isAuthenticated={isAuthenticated}
        ariaLabel={"Lee mas acerca de Rediasa"}
        backGroungColor={"#b2c2d9"}
        image={rediasaLogo}
        textColor={"#033769"}
        text1={rediasaText1}
        text2={rediasaText2}
        text3={rediasaText3}
        heading1={rediasaHead1}
        heading2={rediasaHead2}
        heading3={rediasaHead3}
        heading4={rediasaHead4}
        url={"rediassa"}
      />
      <CollaboratorsBasicInfo2
        isAuthenticated={isAuthenticated}
        ariaLabel={"Lee mas acerca de Somos Shelter"}
        backGroungColor={"#e8edf5"}
        image={shelterLogo}
        textColor={"#163766"}
        text1={shelterText1}
        text2={shelterText2}
        text3={shelterText3}
        heading1={shelterHead1}
        heading2={shelterHead2}
        heading3={shelterHead3}
        heading4={shelterHead4}
        url={"shelter"}
      />
      <CollaboratorsBasicInfo1
        isAuthenticated={isAuthenticated}
        ariaLabel={"Lee mas acerca de LD Plastic Solutions"}
        backGroungColor={"white"}
        image={ldLogo}
        textColor={"#04498b"}
        text1={ldText1}
        text2={ldText2}
        text3={ldText3}
        heading1={ldHead1}
        heading2={ldHead2}
        heading3={ldHead3}
        heading4={ldHead4}
        url={"ld-plastic-solutions"}
      />
    </React.Fragment>
  );
};

export default CollaboratorsIndex;
