import { Button, Grid, GridItem } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { useParams } from "react-router-dom";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import EditBase from "../../Cards/EditBase";
import { useHooperCapacityHooks } from "../../../../../../hooks/hooper-capacity/useHooperCapacityHooks";
import { badgeColor, borderColor, textColor } from "../../utilities/variables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import {
  lbHrKgHr,
  lbKg,
  ozGr,
  ozMinGrMin,
} from "../../../../individual/hooks/useUomTransform";

const EditHooperCapacity = () => {
  const { id } = useParams();
  const { project, tool, machine, updateProject, isLoading } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const { onSubmitHandler, showData } = useHooperCapacityHooks();

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        keywords={metaKeywords}
        title={metaTitle}
      />
      <EditBase
        disabled={!showData}
        isLoading={isLoading}
        title={"Capacidad de la Tolva"}
      >
        <GridItem colSpan={4}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            templateColumns="repeat(3, 1fr)"
            templateRows={showData ? "repeat(6, 1fr)" : "repeat(4, 1fr)"}
            mb={5}
            gap={4}
            borderWidth={1}
            p={5}
            borderRadius={15}
            borderColor={borderColor}
          >
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={"Pcs"}
              type={"pressure"}
              title={"Cantidad a Producir"}
              value={project?.quantityToProcess}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              type={"percentaje"}
              rightAddon={"%"}
              title={"Riesgo de Scrap"}
              value={project?.scrapRisk}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={"pcs"}
              type={"int"}
              title={"Cavidades de Molde"}
              value={tool?.numberOfCavities}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={"seg"}
              type={"time"}
              title={"Tiempo de Ciclo"}
              value={tool?.cycleTime}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={lbKg(project?.uom)}
              type={"time"}
              title={"Capacidad de la Tolva"}
              value={machine?.hopperCapacity}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={ozGr(project?.uom)}
              fix={5}
              title={"Peso de Disparo 100%"}
              value={project?.shotWeight100}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={"shots"}
              type={"pressure"}
              title={"Disparos Requeridos"}
              value={project?.reqShotsForProd}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={"min"}
              title={"Tiempo de Ciclo"}
              value={project?.cycleTime_min}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              rightAddon={"pcs/Hr"}
              type={"int"}
              title={"Piezas por Hora"}
              value={project?.pcs_x_Hour}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              type={"time"}
              rightAddon={ozMinGrMin(project?.uom)}
              title={"Consumo por Minuto"}
              value={project?.resinFlow_Oz_x_Min}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              type={"temp"}
              rightAddon={"Hr"}
              title={"Duracion de la Producción"}
              value={project?.productionDuration}
            />
            <DataDisplayBadge
              badgeColorScheme={badgeColor}
              color={textColor}
              type={"pressure"}
              rightAddon={lbKg(project?.uom)}
              title={"Resina Requerida para la Producción"}
              value={project?.reqResinForProduction}
            />

            {showData && (
              <>
                <DataDisplayBadge
                  badgeColorScheme={badgeColor}
                  color={textColor}
                  rightAddon={lbKg(project?.uom)}
                  title={"Tamaño de Recarga"}
                  value={project?.reloadSize}
                />
                <DataDisplayBadge
                  badgeColorScheme={badgeColor}
                  color={textColor}
                  rightAddon={"min"}
                  title={"Recorrido de la Resina"}
                  value={project?.resinTroughHopper}
                />
                <DataDisplayBadge
                  badgeColorScheme={badgeColor}
                  color={textColor}
                  rightAddon={"times"}
                  type={"int"}
                  title={"Recargas Requeridas despues de Carga"}
                  value={project?.reloadReqAfterStart}
                />
                <DataDisplayBadge
                  badgeColorScheme={badgeColor}
                  color={textColor}
                  type={"int"}
                  rightAddon={"pcs"}
                  title={"Capacidad de la Tolva en piezas"}
                  value={project?.hopperCapacityPcs}
                />
                <DataDisplayBadge
                  badgeColorScheme={badgeColor}
                  color={textColor}
                  rightAddon={lbHrKgHr(project?.uom)}
                  type={"time"}
                  title={"Consumo de Resina"}
                  value={project?.consumeLb_x_hr}
                />
                <DataDisplayBadge
                  badgeColorScheme={
                    project?.reloadTime > 60 || project?.reloadTime < 10
                      ? "red"
                      : badgeColor
                  }
                  color={textColor}
                  type={"time"}
                  rightAddon={"min"}
                  title={"Tiempo de Recarga"}
                  value={project?.reloadTime}
                />
              </>
            )}
          </Grid>
        </GridItem>

        {!showData && (
          <GridItem colSpan={4}>
            <Button
              colorScheme={"facebook"}
              isLoading={isLoading}
              onClick={onSubmitHandler}
            >
              Calcular Datos Faltantes
            </Button>
          </GridItem>
        )}
      </EditBase>
    </>
  );
};

export default EditHooperCapacity;
