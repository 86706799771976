import CustomGrid from "../../../components/CustomGrid";
import CustomInput from "../../../components/CustomInput";
import { useIndividualDryerSupplyHooks } from "../useIndividualDryerSupplyHooks";
import {
  lbHrKgHr,
  lbKg,
  ozGr,
  ozMinGrMin,
  temp,
} from "../../../hooks/useUomTransform";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { textColor } from "../../../utilities/variabled";
import IndividualFooter from "../../../components/IndividualFooter";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import instructions from "../utilities/instructions";
import description from "../utilities/description";
import { Grid } from "@chakra-ui/react";
import HorizontalDivider from "../../../../../../components/dividers/HorizontalDivider";

const IndividualDryerSupplyForm = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    header,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    checkIsValidForm,
  } = useIndividualDryerSupplyHooks(setIsDisabled);

  const isDisabled =
    !isObjectEmpty(result) ||
    !checkIsValidForm(data) ||
    parseFloat(data?.prodQuantity) < 1 ||
    parseFloat(data?.dryTime?.max) < parseFloat(data?.dryTime?.min) ||
    parseFloat(data?.shotWeight100) <= 0 ||
    parseFloat(data?.cycleTime) < 1;

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <HorizontalDivider mt={5} />
        <Grid
          templateColumns="repeat(4, 1fr)"
          gap={6}
          width={"100%"}
          mx={5}
          mb={10}
        >
          <CustomInput
            disabled={!isObjectEmpty(result)}
            label={"ID de Secador"}
            id={"dryerID"}
            value={data?.dryerID}
            onChange={(e) => onChangeHandler(e, 1)}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"scrapRisk"}
            value={data?.scrapRisk}
            onChange={(e) => onChangeHandler(e)}
            label={"Riesgo de Scrap"}
            addOnText={"%"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"prodQuantity"}
            value={data?.prodQuantity}
            onChange={(e) => onChangeHandler(e)}
            addOnText={"Pcs"}
            error={
              data?.prodQuantity &&
              parseFloat(data?.prodQuantity) < 1 &&
              "La Cantidad de Producción debe de ser mayor a 1."
            }
            label={"Cantidad de Producción"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"dryerCapacity"}
            value={data?.dryerCapacity}
            onChange={(e) => onChangeHandler(e)}
            addOnText={header.uom === "INGLES" ? "Lb" : "Kg"}
            label={"Capacidad de Secador"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"dryTempMin"}
            value={data?.dryTempMin}
            onChange={(e) => onChangeHandler(e)}
            addOnText={temp(header.uom)}
            label={"Mínima Temp de Secado"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"dryTime.min"}
            value={data?.dryTime?.min}
            onChange={(e) => onChangeHandler(e)}
            addOnText={"Hr"}
            label={"Mínimo Tiempo de Secado"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"dryTime.max"}
            error={
              data?.dryTime?.min &&
              data?.dryTime?.max &&
              parseFloat(data?.dryTime?.max) < parseFloat(data?.dryTime?.min) &&
              "El Máximo tiempo de secado, no puede ser menor al mínimo tiempo de secado."
            }
            value={data?.dryTime?.max}
            onChange={(e) => onChangeHandler(e)}
            addOnText={"Hr"}
            label={"Máximo Tiempo de Secado"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"maxMoisture"}
            value={data?.maxMoisture}
            onChange={(e) => onChangeHandler(e)}
            addOnText={"%"}
            label={"Máximo Contenido de Humedad"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            error={
              data?.numberOfCavities &&
              parseFloat(data?.numberOfCavities) < 1 &&
              "La Cantidad de Cavidades debe de ser mayor a 1."
            }
            id={"numberOfCavities"}
            value={data?.numberOfCavities}
            onChange={(e) => onChangeHandler(e)}
            addOnText={"Pcs"}
            label={"Número de Cavidades"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"cycleTime"}
            error={
              data?.cycleTime &&
              parseFloat(data?.cycleTime) < 1 &&
              "El tiempo de ciclo estimado debe de ser mayor a 1."
            }
            value={data?.cycleTime}
            onChange={(e) => onChangeHandler(e)}
            addOnText={"sec"}
            label={"Tiempo de Ciclo Estimado"}
          />
          <CustomInput
            disabled={!isObjectEmpty(result)}
            id={"shotWeight100"}
            value={data?.shotWeight100}
            onChange={(e) => onChangeHandler(e)}
            addOnText={ozGr(header.uom)}
            label={"Peso de Disparo al 100%"}
            error={
              data?.shotWeight100 &&
              parseFloat(data?.shotWeight100) <= 0 &&
              "El tiempo de ciclo estimado debe de ser mayor a 0."
            }
          />
        </Grid>
        {!isObjectEmpty(result) && (
          <CustomGrid repeat={4}>
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={"Pcs/Hr"}
              type={"int"}
              title={"Piezas Por Hora"}
              value={result.pcsPerHour}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={"min"}
              type={"time"}
              title={"Tiempo de Ciclo Estimado"}
              value={result.cycleTimeMin}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={"min"}
              type={"time"}
              title={"Mínimo Tiempo de Secado"}
              value={result.dryTimeMin}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={"shots"}
              type={"int"}
              title={"Disparos Requeridos"}
              value={result.reqShotsForProd}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={ozGr(header?.uom)}
              type={"weigth"}
              title={"Capacidad de Secador"}
              value={result.dryerCapacityOz}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={lbKg(header?.uom)}
              type={"weigth"}
              title={"Resina Requerida"}
              value={result.reqResinForProd}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={ozMinGrMin(header?.uom)}
              type={"weigth"}
              title={"Flujo de Resina"}
              value={result.resinFlowPerMin}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={"Hr"}
              type={"time"}
              title={"Tiempo de Producción"}
              value={result.productionDuration}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={
                parseFloat(result.resinTroughDryer) <
                  parseFloat(data?.dryTime?.min) ||
                parseFloat(result.resinTroughDryer) >
                  parseFloat(data?.dryTime?.max)
                  ? "red"
                  : "green"
              }
              rightAddon={"Hr"}
              type={"time"}
              title={"Recorrido de Resina"}
              value={result.resinTroughDryer}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={"Pcs"}
              type={"int"}
              title={"Abastecimiento de Secador"}
              value={result.dryerSupplying}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={lbHrKgHr(header?.uom)}
              type={"weigth"}
              title={"Mínima Recarga de Resina"}
              value={result.minDryerReload}
            />
            <DataDisplayBadge
              color={textColor}
              badgeColorScheme={"blue"}
              rightAddon={"times"}
              type={"int"}
              title={"Recargas Requeridas"}
              value={result.reloadsReq}
            />
          </CustomGrid>
        )}
        <IndividualFooter
          result={result}
          url={"dryer-supply"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={isDisabled}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualDryerSupplyForm;

//banner/projects/pdf/intensification-ratio/1e91863a-8c54-4f8b-b84f-5d5302be0694
