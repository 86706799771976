import CustomGrid from "../../../projects/individual/components/CustomGrid";
import { Button, Grid, GridItem, Text } from "@chakra-ui/react";
import Select from "react-select";
import React, { useContext } from "react";
import { machineColumnsData } from "../utilities/variables";
import CustomTable from "../../components/CustomTable";
import { isObjectEmpty } from "../../../../utilities/functions";
import HorizontalDivider from "../../../../components/dividers/HorizontalDivider";
import Data from "./Data";
import { useConvertMachineHooks } from "./useConvertMachineHooks";
import useMachineCount from "../hooks/useMachineCount";
import UserContext from "../../../../contexts/UserContext";
import ErrorCountComponent from "../components/ErrorCountComponent";
import HelmetComponent from "../../../../components/HelmetComponent";
import {
  metaDescriptionConvert,
  metaKeywordsConvert,
  metaTitleConvert,
} from "../utilities/metaVariables";

const ConvertMachine = () => {
  const {
    selectOptions,
    item,
    setUom,
    setItem,
    isLoading,
    items,
    isComplete,
    setIsComplete,
    onSelectItem,
    onSubmitHandler,
  } = useConvertMachineHooks();
  const { machineCount } = useMachineCount();

  const { user } = useContext(UserContext);

  const textColor = "gray.600";
  /*

  if (user && !user.isSubscribed) {
    if (machineCount >= 2) {
      return (
        <ErrorCountComponent
          machineCount={machineCount}
          limit={2}
          user={user}
        />
      );
    }
  }
  if (user && user.isSubscribed) {
    if (
      user.subscrptionType === "TECNICAS_PLASTICAS" ||
      user.subscrptionType === "BASICA"
    ) {
      if (machineCount >= 5) {
        return (
          <ErrorCountComponent
            machineCount={machineCount}
            limit={5}
            user={user}
          />
        );
      }
    }
  }

   */

  return (
    <>
      <HelmetComponent
        keywords={metaKeywordsConvert}
        title={metaTitleConvert}
        description={metaDescriptionConvert}
      />
      {isObjectEmpty(item) && (
        <CustomGrid mt={"150px"}>
          <Text fontSize={"xl"}>Selecciona una unidad de Medida (Origen)</Text>
          <Select
            placeholder={"Selecciona Una Unidad de Medida"}
            options={selectOptions}
            onChange={(e) => setUom(e.value)}
          />
        </CustomGrid>
      )}

      {!isObjectEmpty(items) && isObjectEmpty(item) && (
        <CustomGrid overflowX={"scroll"}>
          <Text fontSize={"xl"}>Selecciona Una Maquina</Text>
          <CustomTable
            tableData={items}
            columnsData={machineColumnsData}
            onSelect={(e) => onSelectItem(e)}
          />
        </CustomGrid>
      )}
      {!isObjectEmpty(item) && (
        <CustomGrid
          mt={"150px"}
          overflowX={"scroll"}
          repeat={4}
          gap={6}
          justifyItems={"space-between"}
          justifyContent={"center"}
        >
          <GridItem colSpan={4} justifyItems={"center"}>
            <Text fontSize={"xl"}>Data</Text>
          </GridItem>
          <Data textColor={textColor} item={item} />
          <GridItem colSpan={4}>
            <HorizontalDivider mt={5} />
          </GridItem>
          <GridItem colSpan={4}>
            <Grid
              templateColumns="repeat(2, 1fr)"
              justifyItems={"center"}
              justifyContent={"space-between"}
            >
              <GridItem colSpan={isComplete ? 2 : 1}>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  colorScheme={isComplete ? "blue" : "red"}
                  onClick={() => {
                    setItem([]);
                    setIsComplete(false);
                  }}
                >
                  {isComplete ? "Cerrar" : "Cancelar"}
                </Button>
              </GridItem>
              {!isComplete && (
                <GridItem alignItems={"center"}>
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    colorScheme={"facebook"}
                    onClick={onSubmitHandler}
                  >
                    Convertir
                  </Button>
                </GridItem>
              )}
            </Grid>
          </GridItem>
        </CustomGrid>
      )}
    </>
  );
};

export default ConvertMachine;
