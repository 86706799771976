import CustomInput from "../../../components/CustomInput";
import { inputData } from "../utilities/inputData";
import {Grid} from "@chakra-ui/react";

const Form = ({ onChange, data, disabled }) => {
  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={6} width={"100%"} my={10}>
      {inputData(data)?.map((item, index) => {
        return (
          <CustomInput
            disabled={disabled}
            key={`${item.id}-${index}-${item.addOn}`}
            addOnText={item.addOn}
            label={item.label}
            value={item.value}
            id={item.id}
            onChange={onChange}
            error={item.error}
          />
        );
      })}
    </Grid>
  );
};

export default Form;
