import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import React from "react";
import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import {
  formaOptions,
  textColor,
  customStyles,
} from "../../utilities/variabled";
import useFormattedNumber from "../../../../hooks/useFormattedNumbers";
import { useHeader } from "../../../../../../../contexts/IndividualContext/HeaderContext";
import {borderColor} from "../../../../../project-overview/components/utilities/variables";

const IndividualGapForm = ({
  data,
  showGapArea,
  setShowGapArea,
  errors,
  handleInputChange,
  isLoading,
  addGap,
  isLastGapComplete,
  removeGap,
  finishGaps,
}) => {
  const { uom } = useHeader();
  return (
    <GridItem
      gap={5}
      color={textColor}
      borderWidth={1}
      borderRadius={15}
      backgroundColor={"#f0f0f0"}
      borderColor={borderColor}
    >
      {!showGapArea ? (
        <>
          {data.gaps.map((gap, index) => (
            <Box p={5} key={index} width="100%">
              <Text fontSize={"xl"} fontWeight={"bold"} color={textColor}>
                Hueco {index + 1}
              </Text>
              <FormControl isInvalid={errors[index] && !!errors[index].form}>
                <FormLabel>
                  <Text color={textColor} my={1}>Forma</Text>
                </FormLabel>
                <Select
                  styles={customStyles}
                  color={textColor}
                  options={formaOptions}
                  value={formaOptions.find(
                    (option) => option.value === gap.form
                  )}
                  onChange={(selectedOption) =>
                    handleInputChange(index, "form", selectedOption.value)
                  }
                />
                <FormErrorMessage>
                  {errors[index] && errors[index].form}
                </FormErrorMessage>
              </FormControl>
              {gap.form && (
                <>
                  {gap.form === "CIRCULO" ? (
                    <>
                      <FormControl
                        isInvalid={errors[index] && !!errors[index].diameter}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor} mt={2}>Diámetro</Text>
                        </FormLabel>
                        <Input
                          borderColor={"gray.400"}
                          color={textColor}
                          value={gap.diameter}
                          onChange={(e) =>
                            handleInputChange(index, "diameter", e.target.value)
                          }
                          placeholder="Diámetro"
                        />
                        <FormErrorMessage>
                          {errors[index] && errors[index].diameter}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors[index] && !!errors[index].cavities}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor} mt={2}>Veces</Text>
                        </FormLabel>
                        <Input
                          borderColor={"gray.400"}
                          color={textColor}
                          value={gap.cavities}
                          onChange={(e) =>
                            handleInputChange(index, "cavities", e.target.value)
                          }
                          placeholder="Veces"
                        />
                        <FormErrorMessage>
                          {errors[index] && errors[index].cavities}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl
                        isInvalid={errors[index] && !!errors[index].base}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor} mt={2}>Base</Text>
                        </FormLabel>
                        <Input
                          borderColor={"gray.400"}
                          color={textColor}
                          value={gap.base}
                          onChange={(e) =>
                            handleInputChange(index, "base", e.target.value)
                          }
                          placeholder="Base"
                        />
                        <FormErrorMessage>
                          {errors[index] && errors[index].base}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors[index] && !!errors[index].height}
                      >
                        <FormLabel>
                          <Text color={textColor} mt={2}>Altura</Text>
                        </FormLabel>
                        <Input
                          borderColor={"gray.400"}
                          color={textColor}
                          value={gap.height}
                          onChange={(e) =>
                            handleInputChange(index, "height", e.target.value)
                          }
                          placeholder="Altura"
                        />
                        <FormErrorMessage>
                          {errors[index] && errors[index].height}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors[index] && !!errors[index].cavities}
                      >
                        <FormLabel>
                          <Text color={textColor} mt={2}>Veces</Text>
                        </FormLabel>
                        <Input
                          borderColor={"gray.400"}
                          color={textColor}
                          value={gap.cavities}
                          onChange={(e) =>
                            handleInputChange(index, "cavities", e.target.value)
                          }
                          placeholder="Veces"
                        />
                        <FormErrorMessage>
                          {errors[index] && errors[index].cavities}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}
                </>
              )}
            </Box>
          ))}
          <Flex p={5} justifyContent={"space-between"} direction={"column"}>
            <Flex justifyContent={"space-between"} mb={1}>
              <Button
                colorScheme={"whatsapp"}
                mx={1}
                w={data.gaps && data.gaps.length > 1 ? "50%" : "100%"}
                onClick={addGap}
                disabled={!isLastGapComplete()}
              >
                Añadir Hueco
              </Button>
              {data.gaps && data.gaps.length > 1 && (
                <Button colorScheme={"red"} mx={1} onClick={removeGap}>
                  Remover Hueco
                </Button>
              )}
            </Flex>
          </Flex>
        </>
      ) : (
        <DataDisplayBadge
          badgeColorScheme={"blue"}
          color={textColor}
          title={" Área de las Cavidades"}
          value={`${useFormattedNumber("", data.gapArea)}`}
          rightAddon={uom === "INGLES" ? "in²" : "mm²"}
          my={2}
          mx={5}
        />
      )}
      <Flex
        px={5}
        pb={5}
        justifyContent={"space-between"}
        mt={1}
        direction={"column"}
      >
        <Button
          colorScheme={"facebook"}
          isLoading={isLoading}
          disabled={!isLastGapComplete()}
          onClick={() => {
            if (showGapArea) {
              setShowGapArea(false);
            } else {
              finishGaps();
              setShowGapArea(true);
            }
          }}
        >
          {showGapArea ? "Editar" : "Terminar"}
        </Button>
      </Flex>
    </GridItem>
  );
};

export default IndividualGapForm;
