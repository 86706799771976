import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { initialResinState, paddingXinputs } from "./utilities/variables";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import {
  bar,
  celsius,
  fahrenheit,
  inches,
  millimeters,
  NEWTON,
  percentage,
  psi,
  REF,
  RELATION,
  RPM,
  time,
  TON,
} from "../../../variables/uomVariables";
import { resinHooks } from "./hooks/resinHooks";
import { validateForms } from "../../../variables/validateForms";
import { DataStore } from "aws-amplify/datastore";
import { Resin, ResinType } from "../../../models";
import { useHistory } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import useResinCount from "./hooks/useResinCount";
import { inSquareSecMmSquareSec } from "../../projects/individual/hooks/useUomTransform";
import CustomGrid from "../../projects/individual/components/CustomGrid";
import { useAlert } from "../../../contexts/useAlert";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionCreate,
  metaKeywordsCreate,
  metaTitleCreate,
} from "./utilities/metaVariables";

const CreateResin = () => {
  const { resin, setResine, parseFinalState } = resinHooks();

  const {
    validateFloat,
    validateString,
    validateDewPoint,
    validateFloatPosNeg,
  } = validateForms();

  const [options, setOptions] = useState([]);
  useEffect(() => {
    getResinTypes();
  }, []);

  useEffect(() => {
    if (options.length) {
      setResine({ ...resin, resinType: options[0].name });
    }
  }, [options]);

  const getResinTypes = async () => {
    const data = await DataStore.query(ResinType);

    data.map((item) => {
      setOptions((current) => [
        ...current,
        { value: item.id, label: item.name },
      ]);
    });
  };

  useEffect(() => {
    const f = filter(123.12);
  }, []);

  const filter = (__val__) => {
    let preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return false;
    } else {
      return true;
    }
  };
  const history = useHistory();
  const { showErrorAlert } = useAlert();

  const { resinCount, isLoading } = useResinCount(); // Usar el hook aquí

  const { user } = useContext(UserContext);

  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const textColor = "gray.600";
  const borderColor = "gray.400";
  /*

  if (user && !user.isSubscribed) {
    if (resinCount >= 2) {
      return (
        <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
          <Text fontSize={"2xl"} fontWeight={"bold"}>
            Has Excedido el limite de creacion de resinas para el plan gratuito
            (2).
          </Text>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            {" "}
            Actualiza tu suscripcion para crear mas resinas
          </Text>
          <Button
            color={"black"}
            onClick={() => history.push("/admin/profile/plans")}
          >
            Ver Planes
          </Button>
        </Grid>
      );
    }
  }
  if (user && user.isSubscribed) {
    if (
      user.subscrptionType === "TECNICAS_PLASTICAS" ||
      user.subscrptionType === "BASICA"
    ) {
      if (resinCount >= 5) {
        return (
          <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              Has Excedido el limite de creacion de resinas para el plan{" "}
              {formatString(user.subscrptionType)} (5).
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>
              {" "}
              Actualiza tu suscripcion para crear mas resinas
            </Text>
            <Button
              color={"black"}
              onClick={() => history.push("/admin/profile/plans")}
            >
              Ver Planes
            </Button>
          </Grid>
        );
      }
    }
  }

   */

  const bgColor = "white";

  return (
    <>
      <HelmetComponent
        keywords={metaKeywordsCreate}
        title={metaTitleCreate}
        description={metaDescriptionCreate}
      />
      <Grid mt={"180px"} color={"white"}>
        <Formik
          initialValues={initialResinState}
          onSubmit={async (values, actions) => {
            try {
              const finalState = await parseFinalState(resin, values);
              await DataStore.save(new Resin(finalState)).then(() => {
                history.push("/admin/resin/index");
              });
            } catch (err) {
              showErrorAlert(
                `${
                  err?.message
                    ? err.message
                    : "Ocurrio un Error desconocido, por favor, intente de nuevo"
                }`
              );
            }
            actions.setSubmitting(false);
          }}
        >
          {(props) => (
            <Flex direction="column" mb="50px" justify="center" align="center">
              <Form
                style={{
                  width: "90%",
                  justifyContent: "justify-between",
                }}
              >
                <CustomGrid repeat={3} gap={6}>
                  <GridItem colSpan={3} mb={"50px"}>
                    <Heading color={textColor}>CREAR NUEVA RESINA</Heading>
                  </GridItem>
                  <FormControl
                    id={"uom"}
                    as="fieldset"
                    mb={5}
                    isRequired
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FormLabel
                      textColor={textColor}
                      as="legend"
                      alignSelf={"center"}
                    >
                      Unidad de medida
                    </FormLabel>
                    <RadioGroup
                      onChange={(e) => setResine({ ...resin, uom: e })}
                      defaultValue="INGLES"
                      value={resin.uom}
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="INTERNACIONAL">
                          Internacional
                        </Radio>
                        <Radio bgColor={bgColor} value="INGLES" selected>
                          Ingles
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <Field name="resinName" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.resinName && form.touched.resinName
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="machineId">
                          ID de Resina
                        </FormLabel>
                        <Input
                          bgColor={bgColor}
                          borderColor={borderColor}
                          {...field}
                          id="resinName"
                          placeholder="ej. R-00001"
                        />
                        <FormErrorMessage>
                          {form.errors.resinName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <FormControl
                    id={"resinType"}
                    as="fieldset"
                    mb={5}
                    paddingRight={paddingXinputs}
                    isRequired
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FormLabel
                      textColor={textColor}
                      as="legend"
                      alignSelf={"center"}
                    >
                      Tipo de Resina
                    </FormLabel>
                    <Select
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          color: "#2C3E50",
                          backgroundColor: "#fff",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#2C3E50",
                        }),
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "white",
                          color: "#2C3E50",
                        }),
                      }}
                      defaultValue={options[0]}
                      options={options}
                      onChange={(e) =>
                        setResine({ ...resin, resinType: e.label })
                      }
                    />
                  </FormControl>
                  <FormControl
                    id={"chemicalStructure"}
                    as="fieldset"
                    mb={5}
                    isRequired
                    paddingLeft={paddingXinputs}
                    paddingRight={paddingXinputs}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FormLabel
                      textColor={textColor}
                      as="legend"
                      alignSelf={"center"}
                    >
                      Estructura Quimica
                    </FormLabel>
                    <RadioGroup
                      onChange={(e) =>
                        setResine({ ...resin, chemicalStructure: e })
                      }
                      defaultValue="SEMICRISTALINO"
                      value={resin.chemicalStructure}
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="SEMICRISTALINO">
                          SEMICRISTALINO
                        </Radio>
                        <Radio bgColor={bgColor} value="AMORFO">
                          AMORFO
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <Field name="resinCost" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.resinCost && form.touched.resinCost
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="resinCost">
                          Costo de Resina
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <InputLeftAddon children="$" color={"black"} />
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="resinCost"
                            placeholder="ej. 00.000"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.resinCost}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="meltTempMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.meltTempMin && form.touched.meltTempMin
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="meltTempMin">
                          Temperatura Melt Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="meltTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.meltTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="meltTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.meltTempMax && form.touched.meltTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="meltTempMax">
                          Temperatura Melt Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="meltTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.meltTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="nozzleTempMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.nozzleTempMin &&
                          form.touched.nozzleTempMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="nozzleTempMin"
                        >
                          Temperatura de Nariz Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="nozzleTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.nozzleTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="nozzleTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.nozzleTempMax &&
                          form.touched.nozzleTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="nozzleTempMax"
                        >
                          Temperatura de Nariz Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="nozzleTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.nozzleTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="FrontTempMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.FrontTempMin && form.touched.FrontTempMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="FrontTempMin">
                          Temperatura Frontal Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="FrontTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.FrontTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="FrontTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.FrontTempMax && form.touched.FrontTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="maxShotSize">
                          Temperatura Frontal Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="FrontTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.FrontTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="midTempMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.midTempMin && form.touched.midTempMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="midTempMin">
                          Temperatura Central Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="midTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.midTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="midTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.midTempMax && form.touched.midTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="midTempMax">
                          Temperatura Central Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="midTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.midTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="backTempMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.backTempMin && form.touched.backTempMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="backTempMin">
                          Temperatura Trasera Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="backTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.backTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="backTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.backTempMax && form.touched.backTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="backTempMax">
                          Temperatura Trasera Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="backTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.backTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="toolAsideTempMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.toolAsideTempMin &&
                          form.touched.toolAsideTempMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="toolAsideTempMin"
                        >
                          Temperatura Molde Lado A Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="toolAsideTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.toolAsideTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="toolAsideTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.toolAsideTempMax &&
                          form.touched.toolAsideTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="toolAsideTempMax"
                        >
                          Temperatura Molde Lado A Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="toolAsideTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.toolAsideTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="toolBsideTempMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.toolBsideTempMin &&
                          form.touched.toolBsideTempMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="toolBsideTempMin"
                        >
                          Temperatura Molde Lado B Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="toolBsideTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.toolBsideTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="toolBsideTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.toolBsideTempMax &&
                          form.touched.toolBsideTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="toolBsideTempMax"
                        >
                          Temperatura Molde Lado B Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="toolBsideTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.toolBsideTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="thermalDiffusion" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.thermalDiffusion &&
                          form.touched.thermalDiffusion
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="thermalDiffusion"
                        >
                          Difusividad Termica
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="thermalDiffusion"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={inSquareSecMmSquareSec(resin?.uom)}
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.thermalDiffusion}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="demoldingTemperature" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.demoldingTemperature &&
                          form.touched.demoldingTemperature
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="demoldingTemperature"
                        >
                          Temperatura de Desmoldeo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="demoldingTemperature"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.demoldingTemperature}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="dryTempMin" validate={validateFloatPosNeg}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.dryTempMin && form.touched.dryTempMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="dryTempMin">
                          Temperatura de Secado Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            {...field}
                            bgColor={bgColor}
                            id="dryTempMin"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.dryTempMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="dryTempMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.dryTempMax && form.touched.dryTempMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="dryTempMax">
                          Temperatura de Secado Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="dryTempMax"
                            placeholder="ej. 000.00"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.dryTempMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="dryTimeMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.dryTimeMin && form.touched.dryTimeMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="dryTimeMin">
                          Tiempo de Secado Mínimo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="dryTimeMin"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon children={time} color={"black"} />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.dryTimeMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="dryTimeMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.dryTimeMax && form.touched.dryTimeMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="dryTimeMax">
                          Tiempo de Secado Máximo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="dryTimeMax"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon children={time} color={"black"} />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.dryTimeMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <FormControl
                    id={"dryerType"}
                    as="fieldset"
                    mb={5}
                    isRequired
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FormLabel
                      textColor={textColor}
                      as="legend"
                      alignSelf={"center"}
                    >
                      Tipo de Secador Recomendado
                    </FormLabel>
                    <RadioGroup
                      onChange={(e) => setResine({ ...resin, dryerType: e })}
                      defaultValue="AIR_DRYER"
                      value={resin.dryerType}
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="AIR_DRYER">
                          AIR DRYER
                        </Radio>
                        <Radio bgColor={bgColor} value="DESICCANT_DRYER">
                          DESICCANT DRYER
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <Field name="dewPoint" validate={validateDewPoint}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.dewPoint && form.touched.dewPoint
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="dewPoint">
                          Dew Point
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            max={0}
                            {...field}
                            id="dewPoint"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? celsius
                                : fahrenheit
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.dewPoint}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maxMoisture" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxMoisture && form.touched.maxMoisture
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="maxMoisture">
                          Porcentaje Máximo de Humedad
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maxMoisture"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            children={percentage}
                            color={"black"}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxMoisture}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maximumShrinkage" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maximumShrinkage &&
                          form.touched.maximumShrinkage
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="maximumShrinkage"
                        >
                          Encogimiento Máximo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maximumShrinkage"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? `1/${millimeters}`
                                : `1/${inches}`
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maximumShrinkage}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="specificWeight" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.specificWeight &&
                          form.touched.specificWeight
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="specificWeight"
                        >
                          Peso Específico
                        </FormLabel>
                        <Input
                          bgColor={bgColor}
                          borderColor={borderColor}
                          {...field}
                          id="specificWeight"
                          placeholder="ej. 0.000"
                        />
                        <FormErrorMessage>
                          {form.errors.specificWeight}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="backPressureMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.backPressureMin &&
                          form.touched.backPressureMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="backPressureMin"
                        >
                          Contrapresión Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="backPressureMin"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={resin.uom === "INTERNACIONAL" ? bar : psi}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.backPressureMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="backPressureMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.backPressureMax &&
                          form.touched.backPressureMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="backPressureMax"
                        >
                          Contrapresión Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="backPressureMax"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={resin.uom === "INTERNACIONAL" ? bar : psi}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.backPressureMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="rpmMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.rpmMin && form.touched.rpmMin}
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="rpmMin">
                          RPM Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="rpmMin"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon children={RPM} color={"black"} />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.rpmMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="rpmMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.rpmMax && form.touched.rpmMax}
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="rpmMax">
                          RPM Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="rpmMax"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon children={RPM} color={"black"} />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.rpmMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="resinInjectionPressureMax"
                    validate={validateFloat}
                  >
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.resinInjectionPressureMax &&
                          form.touched.resinInjectionPressureMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="resinInjectionPressureMax"
                        >
                          Máxima Presión de Inyección
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="resinInjectionPressureMax"
                            placeholder="ej. 0,000.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? `P${bar}`
                                : `P${psi}`
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.resinInjectionPressureMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="minimumPackingPressure" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.minimumPackingPressure &&
                          form.touched.minimumPackingPressure
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="minimumPackingPressure"
                        >
                          Mínima Presión de Empaque
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="minimumPackingPressure"
                            placeholder="ej. 00.000"
                          />
                          <InputRightAddon color={"black"} children={"%"} />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.minimumPackingPressure}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="barrelOcupationMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.barrelOcupationMin &&
                          form.touched.barrelOcupationMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="barrelOcupationMin"
                        >
                          Ocupación de Barril Mínimo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="barrelOcupationMin"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            children={percentage}
                            color={"black"}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.barrelOcupationMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="barrelOcupationMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.barrelOcupationMax &&
                          form.touched.barrelOcupationMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="barrelOcupationMax"
                        >
                          Ocupacion de Barril Máximo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="barrelOcupationMax"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={percentage}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.barrelOcupationMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="tonnageFactorMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.tonnageFactorMin &&
                          form.touched.tonnageFactorMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="tonnageFactorMin"
                        >
                          Factor de Tonelaje Mínimo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="tonnageFactorMin"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? `${NEWTON} / ${millimeters}`
                                : `${TON} / ${inches}`
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.tonnageFactorMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="tonnageFactorMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.tonnageFactorMax &&
                          form.touched.tonnageFactorMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="tonnageFactorMax"
                        >
                          Factor de Tonelaje Máximo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="tonnageFactorMax"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? `${NEWTON} / ${millimeters}`
                                : `${TON} / ${inches}`
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.tonnageFactorMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="cushionPositionMin" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.cushionPositionMin &&
                          form.touched.cushionPositionMin
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="cushionPositionMin"
                        >
                          Posición de Colchón Mínimo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="cushionPositionMin"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            children={
                              resin.uom === "INTERNACIONAL" ? "mm" : "in"
                            }
                            color={"black"}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.cushionPositionMin}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="cushionPositionMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.cushionPositionMax &&
                          form.touched.cushionPositionMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="cushionPositionMax"
                        >
                          Posición de Colchón Máximo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="cushionPositionMax"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL" ? "mm" : "in"
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.cushionPositionMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="minimumScrewLDRatio" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.minimumScrewLDRatio &&
                          form.touched.minimumScrewLDRatio
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="minimumScrewLDRatio"
                        >
                          Relación L/D de Husillo Mínima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="minimumScrewLDRatio"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            children={RELATION}
                            color={"black"}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.minimumScrewLDRatio}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maxScrewLDRatio" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxScrewLDRatio &&
                          form.touched.maxScrewLDRatio
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="maxScrewLDRatio"
                        >
                          Relación L/D de Husillo Máxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="maxScrewLDRatio"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={RELATION}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxScrewLDRatio}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="minimumVentDepth" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.minimumVentDepth &&
                          form.touched.minimumVentDepth
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="minimumVentDepth"
                        >
                          Profundidad de Venteo Mínima (DEPTH)
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="minimumVentDepth"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.minimumVentDepth}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="ventLong" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.ventLong && form.touched.ventLong
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="ventLong">
                          Longitud de Venteo (LAND)
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="ventLong"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.ventLong}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="ventWidth" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.ventWidth && form.touched.ventWidth
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="ventWidth">
                          Ancho de Venteo (WIDTH)
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="ventWidth"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.ventWidth}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="reliefDeep" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.reliefDeep && form.touched.reliefDeep
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel textColor={textColor} htmlFor="reliefDeep">
                          Profundidad de Relief
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="reliefDeep"
                            placeholder="ej. 0.000"
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              resin.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.reliefDeep}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="regrindPercentageMax" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.regrindPercentageMax &&
                          form.touched.regrindPercentageMax
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          textColor={textColor}
                          htmlFor="regrindPercentageMax"
                        >
                          Porcentaje de Remolido Máximo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            {...field}
                            id="regrindPercentageMax"
                            placeholder="ej. 00.0"
                          />
                          <InputRightAddon
                            children={percentage}
                            color={"black"}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.regrindPercentageMax}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <GridItem colSpan={3} />
                  <Button
                    colorScheme="whatsapp"
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </Button>
                  <GridItem />
                  <Button
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    disabled
                  >
                    Limpiar
                  </Button>
                </CustomGrid>
              </Form>
            </Flex>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default CreateResin;
