import gintecBgImg from "../../../../assets/img/collaborators/gintec/gintec-bg-img.jpg";
import { Grid, Image, Text } from "@chakra-ui/react";
import React from "react";

export const NuestrasFabricaciones = () => {
  return (
    <Grid
      mt={5}
      borderWidth={1}
      mx={5}
      p={5}
      gap={6}
      borderRadius={15}
      backgroundImage={gintecBgImg}
      justifyContent={"center"}
      justifyItems={"center"}
    >
      <Text fontWeight={"bold"} fontSize={"5xl"} color={"white"}>
        NUESTRAS FABRICACIONES
      </Text>
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <Image
          width={"100%"}
          borderRadius={20}
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=800,h=906,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-20.09.20-AMqaOnv3lGf8LPGB.png"
          alt="Vision UI Dashboard"
        />
        <Image
          width={"100%"}
          borderRadius={20}
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=594,h=694,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-20.04.56-AVL3qJL8eyTXKLQR.png"
          alt="Vision UI Dashboard"
        />
        <Image
          width={"100%"}
          borderRadius={20}
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=860,h=920,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-22.27.08-YrD1jll20xh5WJby.png"
          alt="Vision UI Dashboard"
        />
        <Image
          width={"100%"}
          borderRadius={20}
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=800,h=906,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-20.09.20-AMqaOnv3lGf8LPGB.png"
          alt="Vision UI Dashboard"
        />
        <Image
          width={"100%"}
          objectFit="cover"
          borderRadius={20}
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=282,h=292,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-22.29.55-m6LP6wjl70SBMLMW.png"
          alt="Vision UI Dashboard"
        />
        <Image
          width={"100%"}
          borderRadius={20}
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=800,h=712,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-20.07.50-AGBMZGVlzzSnK5kb.png"
          alt="Vision UI Dashboard"
        />
        <Image
          width={"100%"}
          borderRadius={20}
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=276,h=266,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-22.31.47-dWxeNyvRDQtBklON.png"
          alt="Vision UI Dashboard"
        />
        <Grid gap={2}>
          <Image
            width={"80%"}
            borderRadius={20}
            src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=266,h=144,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-22.33.44-AVL3qpxvoWfVG5Zy.png"
            alt="Vision UI Dashboard"
          />
          <Image
            width={"80%"}
            borderRadius={20}
            src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=266,h=152,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-10-03-a-la-s-22.36.03-dOqlLypQoNhoNkv3.png"
            alt="Vision UI Dashboard"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NuestrasFabricaciones;
