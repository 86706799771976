import {
  Button,
  DarkMode,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import HorizontalDivider from "../../../components/dividers/HorizontalDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnchorCircleCheck,
  faCheckCircle,
  faCheckSquare,
  faCheckToSlot,
  faCircleCheck, faExclamationCircle, faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";

const SendEmailConfirmationModal = ({
  isOpen,
  onOpen,
  onClose,
  type,
  collaborator,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={type === "success" ? "green" : "red"} mt={10}>
            <Grid justifyItems={"center"} gap={6} >
              {type === "success" ? (
                <FontAwesomeIcon icon={faCheckCircle} size={"2x"} fade mb={5} />
              ) : (
                <FontAwesomeIcon icon={faXmarkCircle} size={"2x"} fade mb={5} />
              )}

              {type === "success"
                ? <Text> Mensaje Enviado Exitosamente</Text>
                : <Text> Fallo al Enviar el Mensaje</Text>}
            </Grid>
          </ModalHeader>
          <ModalCloseButton />
          <HorizontalDivider mt={5} />
          <ModalBody justifyContent={"center"}>
            <Grid justifyItems={"center"} gap={6}>
              {
                type === "success" ?
                  (
                    <>
                      <Text color={"gray.600"} fontSize={"xl"} fontStyle={"italic"}>Gracias por enviarnos tu mensaje!</Text>
                      <Text color={"gray.600"} fontSize={"xl"} fontStyle={"italic"}>
                        {" "}

                        MVPS Y {collaborator} agradecemos tu contacto.
                      </Text>
                      <Text color={"gray.600"} fontSize={"xl"} fontStyle={"italic"}>Saludos!</Text>
                    </>
                  ) : (
                    <>
                      <Text color={"red.600"} fontSize={"xl"} fontStyle={"italic"}>Se ha Producido un error!</Text>
                      <Text color={"red.600"} fontSize={"xl"} fontStyle={"italic"}>
                        {" "}

                        No hemos podido enviar tu mensaje.
                      </Text>
                      <Text color={"red.600"} fontSize={"xl"} fontStyle={"italic"}>Favor de intentarlo de nuevo!</Text>
                    </>
                  )

              }

            </Grid>
          </ModalBody>
          <HorizontalDivider mt={5} />

          <Grid justifyItems={"center"} my={5}>
            <Button colorScheme="blue" onClick={onClose}>
              Cerrar
            </Button>
          </Grid>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendEmailConfirmationModal;
