import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { CheckValveRepeatibility } from "../../../../../../../models";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  Text,
  View,
} from "@react-pdf/renderer";
import "moment/locale/es";
import { getUrl } from "aws-amplify/storage";
import PdfHeader from "../../../../components/PdfHeader";
import usePdfStyles from "../../../../hooks/usePdfstyles";
import { inMm, ozGr } from "../../../../hooks/useUomTransform";
import { delay } from "../../../../hooks/useDelay";

const IndividualCheckValveRepeatibilityPdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [href, setHref] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(CheckValveRepeatibility, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    url();
  }, [data]);

  const url = async () => {
    await delay(1000);
    const u = await getUrl({
      key: data?.chartImage,
      options: {
        accessLevel: "guest", // can be 'private', 'protected', or 'guest' but defaults to `guest`
      },
    });
    setHref(u.url.href);
  };

  const styles = usePdfStyles();
  const MyDocument = ({ data }) => (
    <Document
      pageLayout={"twoColumnLeft"}
      creator={"MVPS"}
      subject={`Check Valve Repeatibility Study ${data?.header?.productName}`}
      title={`Check Valve Repeatibility Study ${data?.header?.productName}`}
      author={"https://molding-vps.com"}
    >
      <Page bookmark={"MVPS"} size="LETTER">
        <PdfHeader data={data} title={"Check Valve Repeatibility Study"} />
        <Text style={styles.header}>{"Datos"}</Text>
        <View
          style={{
            border: 1,
            width: "90%",
            padding: "9px",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: "15px",
            borderRadius: 10,
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <View>
            <Text style={styles.text}>Secuencia</Text>
            {data?.shotWeight?.map((item) => {
              return <Text style={styles.text}>{item.secuence}</Text>;
            })}
            <Text style={styles.text}>Promedio</Text>
            <Text style={styles.text}>Mínimo</Text>
            <Text style={styles.text}>Máximo</Text>
            <Text style={styles.text}>Diferencia</Text>
          </View>
          <View>
            <Text style={styles.text}>Peso de Disparo</Text>
            {data?.shotWeight?.map((item) => {
              return (
                <Text style={styles.text}>
                  {parseFloat(item.value).toFixed(4)} {ozGr(data?.header?.uom)}
                </Text>
              );
            })}
            <Text style={styles.text}>
              {parseFloat(data?.shotWeightResults?.average).toFixed(4)} {ozGr(data?.header?.uom)}
            </Text>
            <Text style={styles.text}>
              {parseFloat(data?.shotWeightResults?.max).toFixed(4)} {ozGr(data?.header?.uom)}
            </Text>
            <Text style={styles.text}>
              {parseFloat(data?.shotWeightResults?.min).toFixed(4)} {ozGr(data?.header?.uom)}
            </Text>
            <Text style={styles.text}>
              {parseFloat(data?.shotWeightResults?.diff).toFixed(4)} {ozGr(data?.header?.uom)}
            </Text>
          </View>
          <View>
            <Text style={styles.text}>Tiempo de Llenado</Text>
            {data?.fillTime?.map((item) => {
              return <Text style={styles.text}>{parseFloat(item.value).toFixed(4)} sec</Text>;
            })}
            <Text style={styles.text}>
              {parseFloat(data?.fillTimeResults?.average).toFixed(4)} sec
            </Text>
            <Text style={styles.text}>{parseFloat(data?.fillTimeResults?.max).toFixed(4)} sec</Text>
            <Text style={styles.text}>{parseFloat(data?.fillTimeResults?.min).toFixed(4)} sec</Text>
            <Text style={styles.text}>{parseFloat(data?.fillTimeResults?.diff).toFixed(4)} sec</Text>
          </View>
          <View>
            <Text style={styles.text}>Transferencia V/P</Text>
            {data?.vpTransfer?.map((item) => {
              return (
                <Text style={styles.text}>
                  {parseFloat(item.value).toFixed(4)} {inMm(data?.header?.uom)}
                </Text>
              );
            })}
            <Text style={styles.text}>
              {parseFloat(data?.vpTransferResults?.average).toFixed(4)} {inMm(data?.header?.uom)}
            </Text>
            <Text style={styles.text}>
              {parseFloat(data?.vpTransferResults?.max).toFixed(4)} {inMm(data?.header?.uom)}
            </Text>
            <Text style={styles.text}>
              {parseFloat(data?.vpTransferResults?.min).toFixed(4)} {inMm(data?.header?.uom)}
            </Text>
            <Text style={styles.text}>
              {parseFloat(data?.vpTransferResults?.diff).toFixed(4)} {inMm(data?.header?.uom)}
            </Text>
          </View>
        </View>
        <View
          style={{
            border: 1,
            width: "90%",
            padding: "9px",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: "15px",
            borderRadius: 10,
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              marginBottom: 1,
              marginLeft: "20%",
              fontSize: 12,
              textAlign: "right",
              fontFamily: "Times-Roman",
            }}
          >
            Repetibilidad de Válvula Check:
          </Text>
          <Text
            style={{
              marginBottom: 1,
              marginRight: "25%",
              fontSize: 12,
              textAlign: "left",
              fontFamily: "Times-Roman",
            }}
          >
            {parseFloat(data?.checkValveRepeatibility).toFixed(4)}%
          </Text>
        </View>
        <Text style={[styles.header, {marginBottom: 5,}]}>{"Graficas"}</Text>
        <View style={[styles.view, {
          justifyContent: "center", padding: "0px",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "0px", }]}>
          <Image src={href} style={{
            marginVertical: 0,
            marginHorizontal: 20,
            width: "50%",
            padding: 0
          }} />
        </View>
        <Text
          style={[styles.pageNumber]}
          render={({ pageNumber, totalPages }) => (
            <>
              <Text style={styles.pageNumber}>https://molding-vps.com</Text>
            </>
          )}
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <>
      <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
        <MyDocument data={data} />
      </PDFViewer>
    </>
  );
};

export default IndividualCheckValveRepeatibilityPdf;
