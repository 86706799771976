import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { IntensificationRatio } from "../../../../../../models";
import { Page, Text, View, Document, PDFViewer } from "@react-pdf/renderer";
import "moment/locale/es";

import useFormattedNumber from "../../../hooks/useFormattedNumbers";
import PdfHeader from "../../../components/PdfHeader";
import usePdfStyles from "../../../hooks/usePdfstyles";
const IntensificationRatioPdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(IntensificationRatio, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  const styles = usePdfStyles();

  const MyDocument = ({ data }) => (
    <Document
      pageLayout={"twoColumnLeft"}
      creator={"MVPS"}
      subject={`intensification Ratio ${data?.header?.productName}`}
      title={`intensification Ratio ${data?.header?.productName}`}
      author={"https://molding-vps.com"}
    >
      <Page bookmark={"MVPS"} size="LETTER">
        <PdfHeader data={data} title={"Razón de Intensificación"} />
        <Text style={styles.header}>Entradas</Text>
        <View style={styles.view}>
          <View>
            <Text style={styles.text}>
              Piston Diameter:{" "}
              <Text style={styles.text}>
                {useFormattedNumber("", data?.pistonDiameter)}{" "}
                {data?.header?.uom === "INGLES" ? "in" : "mm"}
              </Text>
            </Text>
            <Text style={styles.text}>
              Máx Injection Pressure:{" "}
              <Text style={styles.text}>
                {useFormattedNumber("pressure", data?.maxInjectPressure)}{" "}
                {data?.header?.uom === "INGLES" ? "Hpsi" : "Hbar"}
              </Text>
            </Text>
          </View>
          <View>
            <Text style={styles.text}>
              Screw Diameter:{" "}
              <Text style={styles.text}>
                {useFormattedNumber("", data?.screwDiameter)}{" "}
                {data?.header?.uom === "INGLES" ? "in" : "mm"}
              </Text>
            </Text>
          </View>
        </View>
        <Text style={styles.header}>Salidas</Text>
        <View style={styles.view}>
          <View>
            <Text style={styles.text}>
              Intensification Ratio:{" "}
              <Text style={styles.text}>
                {useFormattedNumber("int", data?.intensificationRatio)} : 1
              </Text>{" "}
            </Text>
            <Text style={styles.text}>
              Max Plastic Pressure:{" "}
              <Text style={styles.text}>
                {useFormattedNumber("pressure", data?.maxPlasticPressure)}{" "}
                {data?.header?.uom === "INGLES" ? "Ppsi" : "Pbar"}
              </Text>
            </Text>
          </View>
          <View>
            <Text style={styles.text}>
              Piston Area:{" "}
              <Text style={styles.text}>
                {useFormattedNumber("", data?.pistonArea)}{" "}
                {data?.header?.uom === "INGLES" ? "in²" : "mm²"}
              </Text>
            </Text>

            <Text style={styles.text}>
              Screw Area:{" "}
              <Text style={styles.text}>
                {useFormattedNumber("", data?.screwArea)}{" "}
                {data?.header?.uom === "INGLES" ? "in²" : "mm²"}
              </Text>
            </Text>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            <>
              <Text style={styles.pageNumber}>https://molding-vps.com</Text>
            </>
          )}
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
      <MyDocument data={data} />
    </PDFViewer>
  );
};

export default IntensificationRatioPdf;
