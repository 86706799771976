import { Grid } from "@chakra-ui/react";
import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../utilities/variables";
import { useShotSizeHooks } from "../../../../../../../hooks/shot-size/useShotSizeHooks";
import {
  inCubeMmCube,
  inMm,
  ozGr,
  temp,
} from "../../../../../individual/hooks/useUomTransform";

const ShotSizeData = () => {
  const { project, resin, machine, tool } = useContext(ProjectContext);

  const {
    shotWeight100,
    intRatio,
    realMeltTemp,
    barrelTheoricVolume,
    barrelMaxWeight,
    overallShotSize,
    cushionPositionTheoric,
    shotSizePositionTheoric,
    shotPercentage,
    cutOffPositionTheoric,
    shotsInBarrel,
    barrelUsage,
    shotWeight1stStage,
  } = project;
  const { shootVolume } = tool;
  const { maxShotSize, screwDiameter, LDratio, barrelVolume } = machine;
  const {
    barrelOcupationMin,
    barrelOcupationMax,
    cushionPositionMin,
    cushionPositionMax,
    specificWeight,
  } = resin;

  const {
    validateCutOffPosition,
    validateShotSizePosition,
  } = useShotSizeHooks();

  const color = "gray.700";

  return (
    <Grid
      borderColor={borderColor}
      templateColumns="repeat(1, 4fr)"
      gap={6}
      mt={5}
      p={5}
      borderWidth={1}
      borderRadius={15}
    >
      <Grid templateColumns="repeat(4, 4fr)" gap={6}>
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"weight"}
          rightAddon={ozGr(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Peso de Disparo 100%"}
          value={shotWeight100}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"int"}
          rightAddon={":1"}
          size={"sm"}
          fix={2}
          title={"Razon de Intensificacion"}
          value={intRatio}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"percentaje"}
          rightAddon={"%"}
          size={"sm"}
          fix={2}
          title={"Ocupación de Barril Mínima"}
          value={barrelOcupationMin}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"percentaje"}
          rightAddon={"%"}
          size={"sm"}
          fix={2}
          title={"Ocupación de Barril Máxima"}
          value={barrelOcupationMax}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Colchon Mínimo"}
          value={cushionPositionMin}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Colchon Máximo"}
          value={cushionPositionMax}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"temp"}
          rightAddon={temp(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Temperatura de Masa Real"}
          value={realMeltTemp}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Tamaño Máximo de Disparo"}
          value={maxShotSize}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Diametro del Husillo"}
          value={screwDiameter}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"int"}
          rightAddon={":1"}
          size={"sm"}
          fix={2}
          title={"LD Ratio de Maquina"}
          value={LDratio}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"volume"}
          rightAddon={inCubeMmCube(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Volumen de Barril"}
          value={barrelVolume}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"volume"}
          rightAddon={inCubeMmCube(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Volumen de Disparo"}
          value={shootVolume}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"time"}
          rightAddon={":1"}
          size={"sm"}
          fix={2}
          title={"Peso Especifico"}
          value={specificWeight}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"volume"}
          rightAddon={inCubeMmCube(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Volumen Teorico de Barril"}
          value={barrelTheoricVolume}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"weight"}
          rightAddon={ozGr(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Capacidad de Barril Maximo"}
          value={barrelMaxWeight}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Longitud Total de Disparo"}
          value={overallShotSize}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            cushionPositionTheoric > resin.cushionPositionMin &&
            cushionPositionTheoric < resin.cushionPositionMax &&
            cushionPositionTheoric < machine.maxShotSize
              ? badgeColor
              : "red"
          }
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Posición de Colchón"}
          value={cushionPositionTheoric}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            validateShotSizePosition(project, resin, machine)
              ? badgeColor
              : "red"
          }
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Tamaño de Disparo Teórico"}
          value={shotSizePositionTheoric}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"percentaje"}
          rightAddon={"%"}
          size={"sm"}
          fix={2}
          title={"Porcentaje Disparo/Barril"}
          value={shotPercentage}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          type={"weight"}
          rightAddon={ozGr(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Peso de Primera Etapa"}
          value={shotWeight1stStage}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            project?.cutOffPositionTheoric < resin.cushionPositionMin ||
            project?.cutOffPositionTheoric > machine.maxShotSize
              ? "red"
              : validateCutOffPosition(project, resin, machine)
              ? "orange"
              : badgeColor
          }
          type={"long"}
          rightAddon={inMm(project?.uom)}
          size={"sm"}
          fix={2}
          title={"Posición de Corte V/P"}
          value={cutOffPositionTheoric}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={badgeColor}
          rightAddon={"shots"}
          size={"sm"}
          title={"Capacidad de Disparos"}
          value={shotsInBarrel}
        />
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={
            barrelOcupationMin < barrelUsage && barrelOcupationMax > barrelUsage
              ? badgeColor
              : "red"
          }
          type={"percentaje"}
          rightAddon={"%"}
          size={"sm"}
          fix={2}
          title={"Uso de Barril"}
          value={barrelUsage}
        />
      </Grid>
    </Grid>
  );
};

export default ShotSizeData;
