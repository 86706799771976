import { Grid } from "@chakra-ui/react";
import React from "react";
import { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../utilities/variables";
import {
  inMm,
  inSecMmsec,
  ozGr,
  ppsiToPbar,
} from "../../../../../individual/hooks/useUomTransform";

const DataLinearityStudy = () => {
  const { project, machine, tool } = useContext(ProjectContext);

  const {
    overallShotSize,
    shotSizePositionTheoric,
    maxPlasticPressure,
    decompressionSize,
    shotWeight100,
    max_Machine_Use,
  } = project;
  const { maxInjectionSpeed } = machine;
  const { gateForm, gateDiameter, widthGate, heightGate, deepGate } = tool;

  return (
    <>
      <Grid
        borderColor={borderColor}
        p={5}
        borderWidth={1}
        borderRadius={15}
        mx={5}
        gap={6}
        mb={5}
      >
        <Grid templateColumns="repeat(3, 1fr)" gap={4} color={"white"}>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={inMm(project?.uom)}
            title={"Longitud Total de Disparo"}
            value={overallShotSize}
            size={"sm"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={inMm(project?.uom)}
            title={"Tamaño de Disparo Teorico"}
            value={shotSizePositionTheoric}
            size={"sm"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"pressure"}
            rightAddon={ppsiToPbar(project?.uom)}
            title={"Presión Plastica Maxima"}
            value={maxPlasticPressure}
            size={"sm"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={inSecMmsec(project?.uom)}
            title={"Maxima Velocidad de Inyección"}
            value={maxInjectionSpeed}
            size={"sm"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={inMm(project?.uom)}
            title={"Tamaño de Descompresion"}
            value={decompressionSize}
            size={"sm"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"text"}
            title={"Forma de la Compuerta"}
            value={gateForm}
            size={"sm"}
          />
          {gateForm === "CIRCULAR" ? (
            <>
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                rightAddon={inMm(project?.uom)}
                title={"Diametro de la compuerta"}
                value={gateDiameter}
                size={"sm"}
              />
            </>
          ) : (
            <>
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                rightAddon={inMm(project?.uom)}
                title={"Ancho de la Compuerta"}
                value={widthGate}
                size={"sm"}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                rightAddon={inMm(project?.uom)}
                title={"Longitud de la compuerta"}
                value={heightGate}
                size={"sm"}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                rightAddon={inMm(project?.uom)}
                title={"Grosor de la compuerta"}
                value={deepGate}
                size={"sm"}
              />
            </>
          )}

          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={ozGr(project?.uom)}
            title={"Peso de disparo 100%"}
            value={shotWeight100}
            size={"sm"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"percentaje"}
            rightAddon={"%"}
            title={"Uso deseado de Maquina"}
            value={max_Machine_Use}
            size={"sm"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DataLinearityStudy;
