import { Grid, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import DataDisplayBadge from "../../../../../../../../components/DataDisplay/DatadisplayBadge";
import ProjectContext from "../../../../../../../../contexts/ProjectContext";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../../utilities/variables";
import {
  inMm,
  ozGr,
  ppsiToPbar,
  temp,
} from "../../../../../../individual/hooks/useUomTransform";

const LimitData = () => {
  const { project } = useContext(ProjectContext);
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      templateRows="repeat(4, 1fr)"
      gap={2}
      mt={5}
      p={5}
      color={textColor}
    >
      <Grid
        borderWidth={1}
        p={5}
        borderRadius={15}
        justifyItems={"center"}
        borderColor={borderColor}
      >
        <Grid templateColumns="repeat(1, 1fr)">
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Presion de Sostenimiento
          </Text>
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={ppsiToPbar(project?.uom)}
            type={"pressure"}
            value={project?.min_Hold_Press_Ppsi}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={ppsiToPbar(project?.uom)}
            type={"pressure"}
            value={project?.best_Hold_Press_Select}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={ppsiToPbar(project?.uom)}
            type={"pressure"}
            value={project?.max_Hold_Press_Ppsi}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
      <Grid
        borderWidth={1}
        borderColor={borderColor}
        p={5}
        borderRadius={15}
        justifyItems={"center"}
      >
        <Grid templateColumns="repeat(1, 1fr)">
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Temperatura
          </Text>
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={temp(project?.uom)}
            type={"temp"}
            value={project?.min_Window_Temp}
            fix={"0"}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={temp(project?.uom)}
            type={"temp"}
            value={project?.nom_Window_Temp}
            fix={"0"}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={temp(project?.uom)}
            type={"temp"}
            value={project?.max_Window_Temp}
            fix={"0"}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
      <Grid
        borderWidth={1}
        borderColor={borderColor}
        p={5}
        borderRadius={15}
        justifyItems={"center"}
      >
        <Grid
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(1, 1fr)"
          gap={3}
          mb={7}
        >
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Presion de Inyección
          </Text>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"percentaje"}
            justifyContent={"center"}
            title={"Rango"}
            value={project?.inj_Press_Required_Limit}
            fix={2}
            rightAddon={"%"}
          />
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"pressure"}
            rightAddon={ppsiToPbar(project?.uom)}
            value={project?.inj_Press_Required_Low_Limit}
            fix={"0"}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"pressure"}
            rightAddon={ppsiToPbar(project?.uom)}
            value={project?.inj_Press_Required}
            fix={"0"}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"pressure"}
            rightAddon={ppsiToPbar(project?.uom)}
            value={project?.inj_Press_Required_High_Limit}
            fix={"0"}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
      <Grid borderWidth={1} borderColor={borderColor} p={5} borderRadius={15}>
        <Grid
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(1, 1fr)"
          gap={6}
          mb={7}
          justifyItems={"center"}
        >
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Tiempo de Inyección
          </Text>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"percentaje"}
            justifyContent={"center"}
            title={"Rango"}
            value={project?.fill_Time_Limit}
            rightAddon={"%"}
            fix={2}
          />
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.fill_Time_Low_Limit}
            fix={5}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.speedSelected?.inyectionTime}
            fix={5}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.fill_Time_High_Limit}
            fix={5}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
      <Grid borderWidth={1} borderColor={borderColor} p={5} borderRadius={15}>
        <Grid
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(1, 1fr)"
          gap={6}
          mb={7}
          justifyItems={"center"}
        >
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Tiempo de Ciclo
          </Text>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"percentaje"}
            justifyContent={"center"}
            title={"Rango"}
            value={project?.cycle_Time_Limit}
            rightAddon={"%"}
            fix={2}
          />
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.cycle_Time_Low_Limit}
            fix={3}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.total_Cycle_Time_Real}
            fix={3}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.cycle_Time_High_Limit}
            fix={3}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
      <Grid borderWidth={1} borderColor={borderColor} p={5} borderRadius={15}>
        <Grid
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(1, 1fr)"
          gap={6}
          mb={7}
          justifyItems={"center"}
        >
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Colchon
          </Text>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"percentaje"}
            justifyContent={"center"}
            title={"Rango"}
            value={project?.cushion_Limit}
            rightAddon={"%"}
            fix={2}
          />
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={inMm(project?.uom)}
            fix={4}
            value={project?.cushion_Low_Limit}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={inMm(project?.uom)}
            fix={4}
            value={project?.maxGateSealingData?.cushion}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={inMm(project?.uom)}
            fix={4}
            value={project?.cushion_High_Limit}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
      <Grid borderWidth={1} borderColor={borderColor} p={5} borderRadius={15}>
        <Grid
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(1, 1fr)"
          gap={6}
          mb={7}
          justifyItems={"center"}
        >
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Tiempo de Recuperación
          </Text>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"percentaje"}
            justifyContent={"center"}
            title={"Rango"}
            value={project?.recovery_Time_Limit}
            rightAddon={"%"}
            fix={2}
          />
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.recovery_Time_Low_limit}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.recovery_Time}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"time"}
            rightAddon={"seg"}
            value={project?.recovery_Time_Hig_limit}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
      <Grid borderWidth={1} borderColor={borderColor} p={5} borderRadius={15}>
        <Grid
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(1, 1fr)"
          gap={6}
          mb={7}
          justifyItems={"center"}
        >
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Peso de Disparo
          </Text>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"percentaje"}
            justifyContent={"center"}
            title={"Rango"}
            value={project?.weight_Limit}
            rightAddon={"%"}
            fix={2}
          />
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)">
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={ozGr(project?.uom)}
            fix={4}
            value={project?.weight_Low_Limit}
            title={"Mínima"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={ozGr(project?.uom)}
            fix={4}
            value={project?.max_Total_Weight_Obtained}
            title={"Nominal"}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            rightAddon={ozGr(project?.uom)}
            fix={4}
            value={project?.weight_High_Limit}
            title={"Máxima"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LimitData;
