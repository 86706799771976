import { useContext, useEffect, useState } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";

export const useSustainingPressureHooks = () => {
  const [selectOptions, setSelectOptions] = useState({});
  const [pass, setPass] = useState(false);

  const { project, resin, updateProject, updateLoading } = useContext(
    ProjectContext
  );
  const { max_Pack_Press, cavityBalanceTable, speedSelected } = project;
  const { minimumPackingPressure } = resin;

  const onChangeSelect = (e) => {
    updateProject({ ...project, max_Pack_Press: e.value });
  };

  useEffect(() => {
    if (minimumPackingPressure) {
      const minValueVariable = 50; // Este es tu valor base
      const minPercentage = minValueVariable + 1; // El valor mínimo para selectOptions será 51
      const maxPercentage = 100; // Valor máximo
      const selectOptions = Array.from(
        { length: maxPercentage - minPercentage + 1 },
        (_, i) => {
          const value = minPercentage + i;
          return { value: value.toString(), label: `${value} %` };
        }
      );
      setSelectOptions(selectOptions);
    }
  }, [minimumPackingPressure]);

  useEffect(() => {
    if (cavityBalanceTable && cavityBalanceTable.maxInjectionPressure) {
      if (cavityBalanceTable.maxInjectionPressure[0].value) {
        const minMaxInjectionPressure =
          speedSelected && speedSelected?.maxInjectionPressure
            ? speedSelected.maxInjectionPressure
            : "";
        updateProject({
          ...project,
          inj_Press_Required: minMaxInjectionPressure,
        });
      }
    }
  }, [cavityBalanceTable]);

  const onSubmit = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "sustainingPressure",
      path: "/sustaining-pressure",
      options: {
        body: {
          minimumPackingPressure,
          max_Pack_Press,
          inj_Press_Required: speedSelected?.maxInjectionPressure,
        },
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.max_Pack_Press = res.max_Pack_Press.toString();
        updated.inj_Press_Required = res.inj_Press_Required.toString();
        updated.min_Hold_Press_Ppsi = res.min_Hold_Press_Ppsi.toString();
        updated.max_Hold_Press_Ppsi = res.max_Hold_Press_Ppsi.toString();
        updated.phase = "12";
      })
    )
      .then(async (res) => {
        await updateProject(res);
        setPass(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  return {
    selectOptions,
    onChangeSelect,
    onSubmit,
    pass,
    setPass,
  };
};
