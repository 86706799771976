import { useContext, useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";
import { isObjectEmpty } from "../../../../../../../utilities/functions";
import { textColor } from "../../../utilities/variables";

export const useLinearityStudyHooks = () => {
  const { project, tool, updateProject, updateLoading } = useContext(
    ProjectContext
  );

  const [showChart, setShowChart] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [dataXChart, setDataXChart] = useState([]);
  const [realSpeedChart, setRealSpeedChart] = useState([]);
  const [dataChartRange, setDataChartRange] = useState([]);

  const {
    shotSizePositionTheoric,
    decompressionSize,
    correct_Coef,
    resin_Max_Shear_Rate,
    low_Limit,
    high_Limit,
    lineavilityTable,
  } = project;
  const {
    gateForm,
    shootVolume,
    numberOfCavities,
    gateDiameter,
    widthGate,
    heightGate,
    deepGate,
  } = tool;

  useEffect(() => {
    if (
      lineavilityTable &&
      isObjectEmpty(dataXChart) &&
      isObjectEmpty(realSpeedChart)
    ) {
      const data = lineavilityTable
        .slice(0)
        .reverse()
        .map((item) => {
          return item.intervalSpeed;
        });
      const data2 = lineavilityTable
        .slice(0)
        .reverse()
        .map((item) => {
          return item.realSpeedMachine;
        });
      setDataXChart(data);
      setRealSpeedChart(data2);
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      if (
        project.lineavilityTable &&
        project.lineavilityTable[0] &&
        !project.lineavilityTable[0].fillingTime
      ) {
        const data = project.viscoscityStudyDataMatrix.map((item) => {
          return {
            sequence: item.sequence,
            intervalSpeed: item.intervalSpeed,
            vpCuttingPosition: item.vpCuttingPosition,
            fillingTime: item.fillingTime,
            realSpeedMachine: item.realSpeedMachine,
            shearRate: item.shearRate,
            relativeViscocity: item.relativeViscocity,
            maxPressurePpsi: item.maxPressurePpsi,
            plasticRheology: "",
            linearity: "",
            shotWeight: item.shotWeight,
            maxShearRate: "",
          };
        });
        updateProject({ ...project, lineavilityTable: data });
      }
    }
  }, [project]);

  useEffect(() => {
    changeMinMax();
  }, [low_Limit, high_Limit]);

  const onSubmitHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "linearityStudy",
      path: "/linearity-study",
      options: {
        body: {
          correct_Coef,
          decompressionSize,
          deepGate,
          gateDiameter,
          gateForm,
          heightGate,
          lineavilityTable,
          numberOfCavities,
          shootVolume,
          shotSizePositionTheoric,
          widthGate,
        },
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();

    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.lineavilityTable = res.lineavilityTable;
        updated.correct_Coef = correct_Coef.toString();
        updated.resin_Max_Shear_Rate = resin_Max_Shear_Rate.toString();
      })
    )
      .then(async (res) => {
        await updateProject(res);
        setShowTable(true);
        setShowChart(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateLoading(false);
      });
  };

  const onChangeLowLimitHandler = async (e, maxMin) => {
    if (maxMin === "min") {
      await updateProject({ ...project, low_Limit: e.value });
    } else {
      await updateProject({ ...project, high_Limit: e.value });
    }
  };

  const chartOptions = {
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      show: false,
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: dataXChart,
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: textColor,
        },
        labels: {
          show: true,
          style: {
            colors: textColor,
            fontSize: "12px",
          },
        },
        title: {
          show: false,
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: false,
          show: false,
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        //borderRadiusApplication: "around",
        columnWidth: "100%",
      },
    },
    legend: {
      show: false,
      color: "white",
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      borderColor: "#FEB019",
    },
    colors: ["#053ecc", "#008FFB", "#166534"],
  };
  //colors: ["#56577A", "#008FFB", "#FEB019"],

  const chartData = [
    {
      name: "Presión Máxima Ppsi",
      type: "line",
      data: dataXChart,
    },
    {
      name: "Presión Máxima Ppsi",
      type: "line",
      data: realSpeedChart,
    },
    {
      name: "Presión Máxima Ppsi",
      type: "area",
      data: dataChartRange,
    },
  ];

  const changeMinMax = async () => {
    await setShowChart(false);
    await updateLoading(true);
    if (project.low_Limit && project.high_Limit) {
      const reversedLinearityTable = [...project.lineavilityTable].reverse();
      let speedsInRange = [];
      const speeds = project.lineavilityTable.map((item) =>
        parseFloat(item.intervalSpeed)
      );
      const maxYspeeds = project.lineavilityTable.map((item) =>
        parseFloat(item.realSpeedMachine)
      );
      const maxSpeed = Math.max(...speeds); // El valor máximo de todos los intervalSpeed
      const maxy = Math.max(...maxYspeeds); // El valor máximo de todos los intervalSpeed

      const lowIntervalIndex = parseInt(project.low_Limit) - 1;
      const highIntervalIndex = parseInt(project.high_Limit) - 1;

      // Asegúrate de que los índices sean válidos
      if (
        lowIntervalIndex >= 0 &&
        highIntervalIndex < reversedLinearityTable.length
      ) {
        const lowIntervalSpeed =
          reversedLinearityTable[lowIntervalIndex].intervalSpeed;
        const maxIntervalSpeed =
          reversedLinearityTable[highIntervalIndex].intervalSpeed;

        const minIntervalSpeed = Math.min(lowIntervalSpeed, maxIntervalSpeed);
        const maxIntervalSpeedReal = Math.max(
          lowIntervalSpeed,
          maxIntervalSpeed
        );

        // Recorre la tabla de linealidad invertida para recolectar las velocidades dentro del rango
        reversedLinearityTable.forEach((item) => {
          const intervalSpeed = parseFloat(item.intervalSpeed);
          if (
            intervalSpeed >= minIntervalSpeed &&
            intervalSpeed <= maxIntervalSpeedReal
          ) {
            speedsInRange.push(intervalSpeed);
          }
        });

        // Genera la nueva data para la gráfica, considerando el orden invertido
        const newDataChartRange = project.lineavilityTable.map((item) => {
          const intervalSpeed = parseFloat(item.intervalSpeed);
          if (
            intervalSpeed >= minIntervalSpeed &&
            intervalSpeed <= maxIntervalSpeedReal
          ) {
            return Math.ceil(maxy / 5) * 5; // Usa el valor máximo de todos los intervalSpeeds
          } else {
            return null;
          }
        });
        setDataChartRange(newDataChartRange);
      }

      const p = await DataStore.query(Project, project.id);
      await DataStore.save(
        Project.copyOf(p, (updated) => {
          updated.low_Limit = low_Limit.toString();
          updated.high_Limit = high_Limit.toString();
          updated.phase = "10";
        })
      )
        .then((res) => {
          updateProject(res);
          setShowTable(true);
          setShowChart(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => updateLoading(false));
    } else {
      if (project.low_Limit || project.high_Limit) {
        setShowChart(true);
      }
      updateLoading(false);

    }
  };

  return {
    chartOptions,
    chartData,
    onSubmitHandler,
    onChangeLowLimitHandler,
    setShowChart,
    setShowTable,
    showChart,
    showTable,
  };
};
