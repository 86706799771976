import InputCustomFieldProjectRigthAddon from "../../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { Grid } from "@chakra-ui/react";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { borderColor, textColor } from "../../../utilities/variables";
import { hpsiToHbar } from "../../../../../individual/hooks/useUomTransform";

const PressureLossForm = ({ showChart }) => {
  const { project, machine, handleInputChange } = useContext(ProjectContext);
  const {
    pr_Air_Shot,
    pr_Press_to_Runner,
    pr_Press_to_Gate,
    pr_Press_to_PostGate,
    pr_Press_to_1st_Stage_End,
  } = project;

  return (
    <Grid
      p={5}
      borderWidth={1}
      borderRadius={15}
      gap={6}
      borderColor={borderColor}
    >
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={hpsiToHbar(project?.uom)}
        disabled={showChart}
        id={"pr_Air_Shot"}
        value={pr_Air_Shot}
        onChange={handleInputChange}
        label={"Disparo al aire"}
      />
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={hpsiToHbar(project?.uom)}
        disabled={showChart}
        id={"pr_Press_to_Runner"}
        value={pr_Press_to_Runner}
        onChange={handleInputChange}
        label={"Presión al runner"}
      />
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={hpsiToHbar(project?.uom)}
        disabled={showChart}
        id={"pr_Press_to_Gate"}
        value={pr_Press_to_Gate}
        onChange={handleInputChange}
        label={"Presión a la compuerta"}
      />
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={hpsiToHbar(project?.uom)}
        disabled={showChart}
        id={"pr_Press_to_PostGate"}
        value={pr_Press_to_PostGate}
        onChange={handleInputChange}
        label={"Presión Post-Gate"}
      />
      <InputCustomFieldProjectRigthAddon
        color={textColor}
        borderColor={borderColor}
        addOn={hpsiToHbar(project?.uom)}
        disabled={showChart}
        id={"pr_Press_to_1st_Stage_End"}
        value={pr_Press_to_1st_Stage_End}
        onChange={handleInputChange}
        label={"Presión Final de Primera Etapa"}
      />
    </Grid>
  );
};

export default PressureLossForm;
