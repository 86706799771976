import React, { useContext, useEffect } from "react";
import { Box, Button, Grid, GridItem } from "@chakra-ui/react";
import useGateSealingHooks from "./hooks/useGateSealingHooks";
import { useGateSealingState } from "./hooks/useGateSealingState";
import { selectOptions } from "./utilities/variables";
import { useGateSealingChartHooks } from "./hooks/useGateSealingChartHooks";
import SelectionSection from "./components/SelectionTable";
import EditBase from "../../Cards/EditBase";
import LineChart from "../../../../../../components/Charts/LineChart";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useParams } from "react-router-dom";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import GateSealingForm from "./components/GateSealingForm";
import GateSealingData from "./components/GateSealingData";
import GateSealingTable from "./components/GateSealingTable";
import GateSealingGeneratedData from "./components/GateSealingGeneratedData";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import { ppsiToPbar } from "../../../../individual/hooks/useUomTransform";

const EditGateSealing = () => {
  const { id } = useParams();
  const { project, updateProject, isLoading, handleInputChange } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    minPackSelectionError,
    maxPackSelectionError,
    onSubmitHandler,
    showData,
    setShowData,
    onSubmitFinal,
    setFinal,
    final,
    onSavePackTable,
  } = useGateSealingHooks();
  const { isButtonEnabled, showChart, setShowChart } = useGateSealingState();

  const {
    onChangeSelection,
    maxPackChartData,
    maxPackChartOptions,
    minPackChartData,
    minPackChartOptions,
  } = useGateSealingChartHooks(setShowChart);

  const {
    minGateSealingData,
    maxGateSealingData,
    best_Hold_Time_Select,
    best_Hold_Press_Select,
  } = project;

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        keywords={metaKeywords}
        title={metaTitle}
      />
      <EditBase title={"Sellado de Compuerta"} tittleButton={"Terminar"}>
        <GridItem colSpan={4}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <GateSealingForm showChart={showChart} />
          <GateSealingData />
          <GateSealingTable
            min_Hold_Selection={project?.min_Hold_Selection}
            onSavePackTable={onSavePackTable}
            setShowChart={setShowChart}
            showChart={showChart}
            minPackSelectionError={minPackSelectionError}
            maxPackSelectionError={maxPackSelectionError}
            isButtonEnabled={isButtonEnabled}
          />

          {showChart && (
            <Grid
              mx={5}
              p={5}
              borderWidth={1}
              borderRadius={15}
              borderColor={borderColor}
            >
              <Grid justifyItems={"center"}>
                <SelectionSection
                  color={textColor}
                  data={minGateSealingData}
                  selectOptions={selectOptions}
                  isDisabled={showData}
                  name={"min_Hold_Selection"}
                  title={"Seleccionar Secuencia Mínima"}
                  onChange={(e) => onChangeSelection(e, "min_Hold_Selection")}
                />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <Box w="100%" minH={{ sm: "300px" }}>
                  <LineChart
                    chartData={minPackChartData}
                    chartOptions={minPackChartOptions}
                  />
                </Box>
                <Box w="100%" minH={{ sm: "300px" }}>
                  <LineChart
                    chartData={maxPackChartData}
                    chartOptions={maxPackChartOptions}
                  />
                </Box>
              </Grid>
              <Grid mt={5}>
                <Button
                  colorScheme={"facebook"}
                  isLoading={isLoading}
                  onClick={
                    showData
                      ? () => setShowData(false)
                      : () => onSubmitHandler()
                  }
                  disabled={
                    maxGateSealingData?.shot ? false : !minGateSealingData?.shot
                  }
                >
                  {showData ? "Editar" : "Guardar Datos"}
                </Button>
              </Grid>
            </Grid>
          )}

          {showData && (
            <>
              <GateSealingGeneratedData />
              <Grid
                mt={5}
                p={5}
                mx={5}
                borderWidth={1}
                borderRadius={15}
                borderColor={borderColor}
              >
                <Grid
                  justifyItems={"center"}
                  templateColumns="repeat(2, 1fr)"
                  gap={6}
                >
                  <InputCustomFieldProjectRigthAddon
                    color={textColor}
                    borderColor={borderColor}
                    addOn={"seg"}
                    disabled={!showData}
                    mx={5}
                    label="Mejor Tiempo de Sostenimiento"
                    value={best_Hold_Time_Select}
                    onChange={handleInputChange}
                    id="best_Hold_Time_Select"
                  />
                  <InputCustomFieldProjectRigthAddon
                    color={textColor}
                    borderColor={borderColor}
                    addOn={ppsiToPbar(project?.uom)}
                    mx={5}
                    disabled={!showData}
                    label="Mejor Presion de Sostenimiento"
                    value={best_Hold_Press_Select}
                    onChange={handleInputChange}
                    id="best_Hold_Press_Select"
                  />
                </Grid>
                <Button
                  colorScheme={"facebook"}
                  mt={5}
                  isLoading={isLoading}
                  disabled={!best_Hold_Press_Select || !best_Hold_Time_Select}
                  onClick={
                    final ? () => setFinal(false) : () => onSubmitFinal()
                  }
                >
                  {final ? "Editar" : "Guardar"}
                </Button>
              </Grid>
            </>
          )}
        </GridItem>
      </EditBase>
    </>
  );
};

export default EditGateSealing;
