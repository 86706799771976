import { Grid, Text } from "@chakra-ui/react";
import DataDisplayBadge from "../../../../../../../../components/DataDisplay/DatadisplayBadge";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../../contexts/ProjectContext";
import {
  badgeColor,
  borderColor,
  textColor,
} from "../../../../utilities/variables";

const ResinData = () => {
  const { resin } = useContext(ProjectContext);

  const { resinName, resinType, chemicalStructure } = resin;
  return (
    <>
      <Grid
        borderColor={borderColor}
        textColor={textColor}
        p={5}
        mx={5}
        borderWidth={1}
        borderRadius={15}
        gap={6}
      >
        <Grid justifyItems={"center"}>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Datos de la resina
          </Text>
        </Grid>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"text"}
            title={"Nombre de la Resina"}
            value={resinName}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"text"}
            title={"Tipo de la Resina"}
            value={resinType}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"text"}
            title={"Estructura Quimica"}
            value={chemicalStructure}
          />
          <DataDisplayBadge
            color={textColor}
            badgeColorScheme={badgeColor}
            type={"text"}
            title={"Temperatura Critica"}
            value={chemicalStructure === "SEMICRISTALINO" ? "MOLDE" : "MELT"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ResinData;
