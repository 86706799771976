import { createContext, useState } from "react";
import { Project } from "../models";
import { useHistory } from "react-router-dom";
import {DataStore, Predicates} from "aws-amplify/datastore";
const ProjectContext = createContext([]);

const ProjectProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState([]);
  const [machine, setMachine] = useState([]);
  const [tool, setTool] = useState([]);
  const [resin, setResin] = useState([]);
  const [projects, setProjects] = useState([]);

  const history = useHistory();

  const updateProjects = async () => {
    const data = await DataStore.query(Project, Predicates.ALL, {
      sort: p => p.updatedAt("DESCENDING")
    });

    setProjects(data);
  };

  const updateLoading = (state) => {
    setIsLoading(state);
  };
  const updateProject = (newProject) => {
    setProject(newProject);
  };
  const updateMachine = (newMachine) => {
    setMachine(newMachine);
  };
  const updateTool = (newTool) => {
    setTool(newTool);
  };
  const updateResin = (newResin) => {
    setResin(newResin);
  };

  const onFinish = () => {
    history.push(`/admin/project/${project.id}`);
  };

  const handleInputChange = (e) => {
    updateProject({ ...project, [e.target.id]: e.target.value });
  };

  const deleteProject = async (id) => {
    const toDelete = await DataStore.query(Project, id);
    if (toDelete) {
      updateLoading(true);
      await DataStore.delete(toDelete);
      await updateProjects().finally(() => updateLoading(false));
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        project,
        deleteProject,
        updateProject,
        machine,
        updateMachine,
        tool,
        updateTool,
        resin,
        updateResin,
        isLoading,
        onFinish,
        updateLoading,
        handleInputChange,
        updateProjects,
        projects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectProvider, ProjectContext as default };
