export const headerImage =
  "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=800,h=468,fit=crop/A3QENvNaewUXORzD/captura-de-pantalla-2023-09-13-a-la-s-18.13.00-m2WB97BDrJtaJ3NA.png";

export const videoSrc =
  "https://drive.google.com/uc?id=1GFxyVGvzgqvR2c1O0SI55P9jPW9hfBm9";

export const lat = 19.42847;

export const lan = -99.12766;

export const contactEmail = "elfenokierefiesta@gmail.com";
