import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import { useEffect, useRef, useState } from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { DataStore } from "aws-amplify/datastore";
import { ShotSize } from "../../../../../../models";
import { initialState } from "../utilities/variables";
import { useAlert } from "../../../../../../contexts/useAlert";
import html2canvas from "html2canvas";
import { delay } from "../../../hooks/useDelay";
import { uploadData } from "aws-amplify/storage";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";

export const useIndividualShotSizeHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
    userID,
  } = useHeader();

  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const chartRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [first, setFirst] = useState(0);
  const [second, setSecond] = useState(0);
  const [third, setThird] = useState(0);
  const [fourth, setFourth] = useState(0);
  const [fifth, setFifth] = useState(0);

  useEffect(() => {
    const dat = parseFloat(parseFloat(data?.minCushion).toFixed(4)).toFixed(2);
    setFirst(dat);
  }, [data?.minCushion]);

  useEffect(() => {
    const dat = parseFloat(
      parseFloat(data?.maxCushion) - parseFloat(data?.minCushion)
    ).toFixed(2);
    setSecond(dat);
  }, [data?.maxCushion, data?.minCushion]);

  useEffect(() => {
    const dat = parseFloat(
      parseFloat(data?.maxShotSize).toFixed(4) *
        (parseFloat(data?.minBarrelUse).toFixed(4) / 100) -
        data?.maxCushion
    ).toFixed(2);
    setThird(dat);
  }, [data?.maxShotSize, data?.minBarrelUse, data?.maxCushion]);

  useEffect(() => {
    const dat = parseFloat(
      parseFloat(data?.maxShotSize) * (parseFloat(data?.maxBarrelUse) / 100) -
        parseFloat(data?.maxShotSize) * (parseFloat(data?.minBarrelUse) / 100)
    ).toFixed(2);
    setFourth(dat);
  }, [data?.maxShotSize, data?.maxBarrelUse, data?.minBarrelUse]);

  useEffect(() => {
    const dat = parseFloat(
      parseFloat(data?.maxShotSize) -
        parseFloat(
          parseFloat(data?.maxShotSize) * (parseFloat(data?.maxBarrelUse) / 100)
        )
    ).toFixed(2);
    setFifth(dat);
  }, [data?.maxShotSize, data?.maxBarrelUse]);

  const createImages = async () => {
    await delay(500);
    const canvas = await html2canvas(chartRef.current);

    // Convertir el canvas a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Falló la conversión de canvas a blob"));
        }
      }, "image/png");
    });
  };

  const barChartData = [
    {
      name: "Bad Zone",
      data: [first],
    },
    {
      name: "Good Zone",
      data: [second],
    },
    {
      name: "Bad Zone",
      data: [third],
    },
    {
      name: "Good Zone",
      data: [fourth],
    },
    {
      name: "Bad Zone",
      data: [fifth],
    },
  ];

  const barChartData2 = [
    {
      name: "",
      data: [parseFloat(data?.cushionPosTheoric).toFixed(4)],
    },
    {
      name: "Shot Zone",
      data: [
        parseFloat(
          parseFloat(data?.shotPositionTheoric) -
            parseFloat(data?.cushionPosTheoric)
        ).toFixed(4),
      ],
    },
    {
      name: "",
      data: [
        parseFloat(
          parseFloat(data?.maxShotSize) - parseFloat(data?.shotPositionTheoric)
        ).toFixed(4),
      ],
    },
  ];

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "Plus Jakarta Display",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: ["Data"],
      show: false,
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "#fff",
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
    },
    grid: {
      show: false,
    },
    fill: {
      colors: ["#FF0000", "#00FF00", "#FF0000", "#00FF00", "#FF0000"],
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
  };

  const barChartOptions2 = {
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "Plus Jakarta Display",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: ["Data"],
      show: true,
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "#fff",
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "12px",
          fontFamily: "Plus Jakarta Display",
        },
      },
    },
    grid: {
      show: false,
    },
    fill: {
      colors: ["transparent", "#ffff00", "transparent"],
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "140px",
        borderRadius: 20,
        borderRadiusWhenStacked: true,
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 20,
            },
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    if (id === "cavityQuantity") {
      let newValue = value ? Math.floor(parseFloat(value)) : "";
      setData((prevData) => ({
        ...prevData,
        [id]: newValue.toString(),
      }));
    } else {
      if (id.includes(".")) {
        const [outerKey, innerKey] = id.split(".");

        // Actualiza solo la propiedad anidada relevante dentro del objeto
        setData((prevData) => ({
          ...prevData,
          [outerKey]: {
            ...prevData[outerKey],
            [innerKey]: value,
          },
        }));
      } else {
        // Maneja la actualización para propiedades no anidadas
        setData((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      }
    }

    // Verifica si el ID tiene un formato que indica una propiedad anidada (por ejemplo, 'dryTime.min')
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    console.log(data);
    try {
      const restOperation = post({
        path: "/shot-size-individual",
        apiName: "shotSize",
        options: {
          body: {
            ...data,
          },
        },
      });
      const rest = await restOperation.response;
      const body = await rest.body.json();
      await setData({ ...data, ...body });

      // Espera a que la imagen sea creada antes de continuar
      const image = await createImages();

      const res = await uploadData({
        key: `check-valve-individual/${Date.now()}.png`,
        data: image,
      }).result;
      const response = await DataStore.save(
        new ShotSize({
          ...data,
          ...body,
          chartImage: res.key,
          userID,
        })
      );
      console.log(response);
      let text = "";
      let icon = "success";
      const minShot =
        parseFloat(response.maxShotSize) *
        (parseFloat(response.minBarrelUse) / 100);
      const maxShot =
        parseFloat(response.maxShotSize) *
        (parseFloat(response.maxBarrelUse) / 100);
      if (parseFloat(response.shotPositionTheoric) <= minShot) {
        text +=
          "El tamaño teorico de disparo, esta por debajo del uso recomendado por el fabricante de la resina.";
        icon = "warning";
      }
      if (parseFloat(response.shotPositionTheoric) >= maxShot) {
        text +=
          "<li>El tamaño teorico de disparo, esta por encima del uso recomendado por el fabricante de la resina.</li>";
        icon = "warning";
      }
      if (parseFloat(response.cutoffPositionTheoric) >= response.maxCushion) {
        text +=
          "<li>La posición de transferencia esta por encima de la máxima posición de colchon.</li>";
        icon = "warning";
      }
      if (parseFloat(response.cutoffPositionTheoric) <= response.minCushion) {
        text +=
          "<li>La posición de transferencia esta por debajo de la mínima posición de colchon.</li>";
        icon = "warning";
      }
      if (parseFloat(response.cushionPosTheoric) >= response.maxCushion) {
        text +=
          "<li>La posición teorica de colchon esta por encima de la mínima posición de colchon.</li>";
        icon = "warning";
      }
      if (parseFloat(response.cushionPosTheoric) <= response.minCushion) {
        text +=
          "<li>La posición teorica de colchon esta por debajo de la mínima posición de colchon.</li>";
        icon = "warning";
      }

      if (parseFloat(response.barrelUsage) >= response.maxBarrelUse) {
        text +=
          "<li>La ocupación de barril esta por encima del máximo uso de barril recomendado.</li>";
        icon = "warning";
      }
      if (parseFloat(response.cushionPosTheoric) <= response.minCushion) {
        text +=
          "<li>La ocupación de barril esta por debajo del mínimo uso de barril recomendado.</li>";
        icon = "warning";
      }

      setResult(response);
      conditionalsAlert(icon, text);
    } catch (error) {
      const e = error.message ? error.message : error;
      showErrorAlert(e);
    }
    setIsLoading(false);
  };

  return {
    uom,
    chartRef,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    barChartData,
    barChartData2,
    barChartOptions,
    barChartOptions2,
  };
};
