import { useIndividualMelt3030Hooks } from "../hooks/useIndividualMelt3030Hooks";
import IndividualFooter from "../../../components/IndividualFooter";
import HooperForm from "./Form";
import ShowResults from "../../../components/HooperResults";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { keysForForm } from "../utilities/variables";
import React from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import CustomGrid from "../../../components/CustomGrid";
import MeltChart from "../../../../project-overview/components/tools/melt3030/components/MeltChart";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "../utilities/description";
import instructions from "../utilities/instructions";
import {Grid} from "@chakra-ui/react";

const IndividualMelt3030Form = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    lineChartDataMelt, showData, chartRef
  } = useIndividualMelt3030Hooks(setIsDisabled);

  const isDisabled = !useVerifyForm(data, keysForForm);
  const isFormDisabled = !isObjectEmpty(result);

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <HooperForm
          onChange={onChangeHandler}
          data={data}
          disabled={isFormDisabled}
        />

        {showData && (
          <Grid width={"100%"}>
            <div ref={chartRef} style={{width: "100%"}}>
              <MeltChart lineChartDataMelt={lineChartDataMelt} />
            </div>
          </Grid>
        )}
        <IndividualFooter
          result={result}
          url={"melt-3030"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={isDisabled}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualMelt3030Form;
