import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { Melt3030 } from "../../../../../../../models";
import { PDFViewer, Image, View, Text } from "@react-pdf/renderer";
import "moment/locale/es";
import PdfBase from "../../../../components/Pdf/PdfBase";
import PdfEntries from "../../../../components/Pdf/PdfEntries";
import { temp } from "../../../../hooks/useUomTransform";
import usePdfStyles from "../../../../hooks/usePdfstyles";
import { delay } from "../../../../hooks/useDelay";
import { getUrl } from "aws-amplify/storage";
const IndividualMelt3030Pdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [chartImageUri, setChartImageUri] = useState("");
  const [href, setHref] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(Melt3030, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  console.log(data);

  useEffect(() => {
    url();
  }, [data]);

  const url = async () => {
    await delay(1000);
    const u = await getUrl({
      key: data?.chartImage,
      options: {
        accessLevel: "guest", // can be 'private', 'protected', or 'guest' but defaults to `guest`
      },
    });
    setHref(u.url.href);
  };

  const uom = data?.header?.uom;

  const dataEntries1 = [
    {
      label: "Temperatura de Masa",
      value: data?.realNozzleTemp,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "",

    },
    {
      label: ""
    },
    {
      label: "Temperatura Real de Nariz",
      value: data?.realMeltTemp,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "",
    },
    {
      label: ""
    },
    {
      label: "Temperatura Frontal Real",
      value: data?.realFrontTemp,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "",
    },
    {
      label: ""
    },
    {
      label: "Temperatura Central Real",
      value: data?.realMidTemp,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "",
    },
    {
      label: ""
    },
    {
      label: "Temperatura Trasera Real",
      value: data?.realBackTemp,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "",
    },
    {
      label: ""
    },
  ];

  const dataEntries2 = [
    {
      label: "Mínima Temperatura de Masa",
      value: data?.meltTempMin,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "Máxima Temperatura de Masa",
      value: data?.meltTempMax,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: ""
    },
    {
      label: "Mínima Temperatura de Nariz",
      value: data?.nozzleTempMin,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "Máxima Temperatura de Nariz",
      value: data?.nozzleTempMax,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: ""
    },
    {
      label: "Mínima Temperatura Frontal",
      value: data?.frontTempMin,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "Máxima Temperatura Frontal",
      value: data?.frontTempMax,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: ""
    },
    {
      label: "Mínima Temperatura Central",
      value: data?.midTempMin,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "Máxima Temperatura Central",
      value: data?.midTempMax,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: ""
    },

    {
      label: "Mínima Temperatura Trasera",
      value: data?.backTempMin,
      type: "temp",
      addOn: temp(uom),
    },
    {
      label: "Máxima Temperatura Trasera",
      value: data?.backTempMax,
      type: "temp",
      addOn: temp(uom),
    },
  ];

  const styles = usePdfStyles();
  const MyDocument = ({ data }) => (
    <PdfBase data={data} name={"Melt3030"}>
      <PdfEntries
        title={"Entradas"}
        column1={dataEntries1}
        column2={dataEntries2}
      />
      <Text style={styles.header}>{"Graficas"}</Text>
      <View style={[styles.view, { justifyContent: "center" }]}>
        <Image src={href} style={styles.image} />
      </View>
    </PdfBase>
  );

  return (
    <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
      <MyDocument data={data} chartImageUri={chartImageUri} />
    </PDFViewer>
  );
  // Luego, usarías este `id` para obtener tus datos, probablemente con un useEffect y fetch o alguna llamada a una API.
};

export default IndividualMelt3030Pdf;
