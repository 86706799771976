import {
  bodyContent1,
  bodyContent2,
  bodyContent3,
  bodyContent4,
  bodyContent5,
} from "./utilities";
import { Button, Flex, Grid, GridItem, Link, Text } from "@chakra-ui/react";
import React from "react";
import AsaCleanContact from "./components/AsaCleanContact";
import AsaCleanHeader from "./components/AsaCleanHeader";
const textColor = "white";
const bgColor = "#f7f2ca";
import bgImage from "./../../../assets/img/collaborators/asaclean/asaclean-bg-img.jpg";
import Video from "../../../components/collaborators/Video";
import video from "./../../../assets/video/asaclean-video.mp4";
import AsaCleanBodyComponent1 from "./components/AsaCleanBodyComponent1";
import AsaCleanBodyComponent2 from "./components/AsaCleanBodyComponent2";
import AsaCleanMapcomponent from "./components/AsaCleanMapComponent";
import { useHistory } from "react-router-dom";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionAsaClean,
  metaKeywordsClean,
  metaTitleAsaClean,
} from "../utilities/metaVariables";

const AsaClean = () => {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");
  return (
    <>
      <HelmetComponent
        keywords={metaKeywordsClean}
        title={metaTitleAsaClean}
        description={metaDescriptionAsaClean}
      />
      <Flex
        direction="column"
        pt={{ sm: "110px", lg: "125" }}
        width={isBannerPath ? "80%" : "auto"} // Aquí aplicamos el ancho condicionalmente
        mx={isBannerPath ? "auto" : "0"}
      >
        <AsaCleanHeader bgColor={bgColor} />
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={6}
          mt={5}
          mx={5}
          p={5}
          backgroundImage={bgImage}
          justifyItems={"center"}
          borderRadius={15}
        >
          <Grid justifyItems={"center"} gap={6}>
            <Text color={"white"} fontWeight={"bold"} fontSize={"2xl"}>
              Las máquinas limpias no son opcionales.
            </Text>
            <Text color={"white"} fontSize={"2xl"}>
              ...Son esenciales!
            </Text>
            <Text color={"white"} fontSize={"xl"}>
              ¿Su planta tiene problemas con los desperdicios y el tiempo muerto
              debido a cambios difíciles y problemas de contaminación?
            </Text>
            <Text color={"white"} fontSize={"xl"}>
              Sabemos lo frustrante que puede ser esto. Actualmente estamos
              ayudando a más de 2000 clientes a resolver estos problemas
              puntuales.
            </Text>
            <Text color={"white"} fontSize={"xl"}>
              Descubra cómo los compuestos de purga Asaclean® pueden ayudar a su
              equipo a tener una operación más libre de estrés, eficiente y
              rentable.{" "}
            </Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Button justifySelf={"left"} colorScheme={"gray"}>
                <Link
                  href={
                    "https://www.asaclean.com/es/compuestos-de-purga/sobre-compuestos-de-purga"
                  }
                  isExternal
                >
                  Conozca Más
                </Link>
              </Button>
              <Button justifySelf={"right"} colorScheme={"red"}>
                <Link
                  href={
                    "https://offers.asaclean.com/es/consulta-sobre-purga-gratuita?_gl=1*1u0qvm1*_ga*MTk0OTMzOTE0OC4xNzAxMDM3ODM2*_ga_CZTH2CZGEK*MTcwMTAzNzgzNi4xLjEuMTcwMTAzODI3My4wLjAuMA..*_fplc*Z2lMS25UQXVMTEhJMTlscTBSRFVJbkd2RHJnTUg2bDBLS25GMyUyRjlpTVNMemt2UVVKRGlpZkFRZDhud0xzR2I2ZDI1SUpSR3FiNXUzbTdTT0klMkJQY043eWVxeEtQb3BJZXkzZjRSTVk2cXVGa0IwWkw5QTRCRFpxY3JtbWhvZyUzRCUzRA.."
                  }
                  isExternal
                >
                  Consulta Gratis
                </Link>
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <Video videoSrc={video} autoplay={true} />
          </Grid>
        </Grid>
        <Grid
          gap={10}
          mt={5}
          mx={5}
          backgroundColor={bgColor}
          p={5}
          borderRadius={15}
        >
          <AsaCleanBodyComponent1 content={bodyContent1} />
          <AsaCleanBodyComponent2 content={bodyContent2} />
          <AsaCleanBodyComponent1 content={bodyContent3} />
          <AsaCleanBodyComponent2 content={bodyContent4} />

          <AsaCleanBodyComponent1 content={bodyContent5} />
        </Grid>
        <Grid
          justifyItems={"center"}
          alignItems={"center"}
          mt={5}
          mx={5}
          backgroundColor={"white"}
          p={5}
          borderRadius={15}
          templateColumns="repeat(5, 1fr)"
          gap={10}
        >
          <GridItem colSpan={4}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              Guía de selección de producto
            </Text>
            <Text>
              Es importante usar el grado correcto de Asaclean® si desea obtener
              los mejores resultados posibles. Responda las siguientes preguntas
              para averiguar qué grados maximizarán sus ahorros y eficiencia.
            </Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Link
              href={
                "https://www.asaclean.com/es/guia-de-seleccion-de-productos"
              }
              isExternal
            >
              <Button colorScheme={"red"}>ENTRAR</Button>
            </Link>
          </GridItem>

          <GridItem colSpan={4}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              Calculadora de ahorro de costos
            </Text>
            <Text>
              Esta calculadora de ahorro de costos utiliza datos reales de
              ahorro de clientes para estimar cuánto ahorrará con los compuestos
              de purga Asaclean®. Complete este formulario rápido y vea
              estimaciones realistas sobre sus ahorros en costos.
            </Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Link
              href={
                "https://www.asaclean.com/es/calculadora-de-ahorro-de-costos"
              }
              isExternal
            >
              <Button colorScheme={"red"}>ENTRAR</Button>
            </Link>
          </GridItem>

          <GridItem colSpan={4}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              Seminarios web populares de Asaclean®
            </Text>
            <Text>
              Entra y conoce nuestros seminarios web, entérate y conoce mas
              sobre nuestros productos y soluciones directamente con los
              expertos.
            </Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Link
              href={
                "https://www.asaclean.com/es/recursos/seminarios-web#widget_1642080558915-video-2"
              }
              isExternal
            >
              <Button colorScheme={"red"}>ENTRAR</Button>
            </Link>
          </GridItem>
        </Grid>
        <AsaCleanMapcomponent />
        <AsaCleanContact
          textColor={textColor}
          bgColor={bgColor}
          collaborator={"Asa Clean"}
        />
      </Flex>
    </>
  );
};

export default AsaClean;
