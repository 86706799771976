export const metaTitleIndex =
  "Administración de Moldes para Inyección: Vista General | MVPS";
export const metaDescriptionIndex =
  "Optimiza tu producción con una gestión eficiente de tus moldes para inyección. Nuestro sistema te permite acceder rápidamente a información detallada, gestionar el uso y el mantenimiento de cada molde.";

export const metaKeywordsIndex =
  "administración de moldes, gestión de moldes, moldeo por inyección, eficiencia productiva, mantenimiento de moldes, mvps";
export const metaTitleCreate =
  "Registro de Nuevo Molde en Sistema de Moldeo por Inyección | MVPS";
export const metaDescriptionCreate =
  "Integra nuevos moldes a tu línea de producción de forma sencilla con nuestro formulario de registro. Asegura la correcta documentación y especificaciones para facilitar su implementación y optimización.";

export const metaKeywordsCreate =
  "registro de molde, nuevo molde, sistema de moldeo, optimización de molde, implementación de moldes, mvps";
export const metaTitleConvert =
  "Conversión de Unidades para Moldes de Inyección | MVPS";
export const metaDescriptionConvert =
  "Asegura la precisión en las especificaciones de tus moldes con nuestra herramienta de conversión de unidades. Facilita la adaptación entre sistemas métrico e imperial para mantener la coherencia y exactitud en tus proyectos de moldeo.";

export const metaKeywordsConvert =
  "conversión de unidades de moldes, precisión en especificaciones, adaptación de moldes, sistema métrico, sistema imperial, mvps";
