import { Th, Thead, Tr } from "@chakra-ui/react";
import { textColor } from "../../../utilities/variables";

const CavityBalanceHeader = () => {
  return (
    <Thead color={textColor} justifyContent={"center"}>
      <Tr>
        <Th></Th>
        <Th>1 Mínimo</Th>
        <Th>2</Th>
        <Th>3</Th>
        <Th>4</Th>
        <Th>5 Máximo</Th>
        <Th>Consistencia de Cavidad</Th>
      </Tr>
    </Thead>
  );
};
export default CavityBalanceHeader;
