import { useIndividualHooks } from "../hooks/useIndividualHooks";
import IndividualFooter from "../../../components/IndividualFooter";
import React, { useEffect, useState } from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { useVerifyForm } from "../hooks/useVerifyForm";
import Form from "./Form";
import Results from "./Results";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import LineChart from "../../../../../../components/Charts/LineChart";
import {
  lineChartOptionsCushion,
  lineChartOptionsFillingTime,
  lineChartOptionsWeight,
} from "../utilities/chartOptions";
import { validateResultsAndCheckValveFilled } from "../utilities/validateResults";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";

const IndividualForm = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    isDisabled,
    formRef,
    resultRef,
    chartRef,
  } = useIndividualHooks(setIsDisabled);

  const [vpTransferData, setVpTransferData] = useState(null);
  const [shotWeightData, setShotWeightData] = useState(null);
  const [fillTimeData, setFillTimeData] = useState(null);

  const isFormDisabled = !isObjectEmpty(result);

  useEffect(() => {
    setFillTimeData(convertirAArrayDeValores(data?.fillTime));
    setShotWeightData(convertirAArrayDeValores(data?.shotWeight));
    setVpTransferData(convertirAArrayDeValores(data?.vpTransfer));
  }, [isDisabled]);

  const convertirAArrayDeValores = (datos) => {
    return datos.map((item) => parseFloat(item.value) || 0);
  };

  const lineChartDataWeight = [
    {
      name: "Peso",
      type: "line",
      data: shotWeightData,
    },
  ];
  const lineChartDataFillingTime = [
    {
      name: "Tiempo de Llenado",
      type: "line",
      data: fillTimeData,
    },
  ];
  const lineChartDataCushion = [
    {
      name: "Colchon",
      type: "line",
      data: vpTransferData,
    },
  ];
  const isValid = validateResultsAndCheckValveFilled(data);

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <div ref={formRef}>
          <Form
            data={data}
            disabled={isFormDisabled}
            onChange={onChangeHandler}
          />
        </div>
        {isValid && (
          <div ref={resultRef}>
            <Results data={data} result={data} />
            <Grid justifyContent={"center"} gap={6} my={5}>
              <DataDisplayBadge
                justifyContent={"center"}
                badgeColorScheme={
                  parseFloat(data?.checkValveRepeatibility) < 3
                    ? "green"
                    : "red"
                }
                type={"time"}
                color={"gray.600"}
                value={parseFloat(data?.checkValveRepeatibility).toFixed(4)}
                rightAddon={"%"}
                title={"Repetibilidad de Válvula Check"}
              />
              <Text color={"gray.600"} fontSize={"sm"}>
                Un resultado aceptable del estudio debe de ser menor a 3%. De lo
                contrario debemos deducir que existe algún tipo de desgaste en
                la Unidad de Inyección.
              </Text>
            </Grid>
          </div>
        )}
        {isValid && (
          <Grid width={"100%"}  backgroundColor={"white"} p={5} borderRadius={5}>
            <div ref={chartRef}>
                <Grid mt={5} justifyContent={"space-between"} templateColumns="repeat(3, 1fr)" px={10} gap={6} width={"100%"}>
                  <Text ml={10}  color={"#FEB019"} fontWeight={"bold"} >
                    Peso
                  </Text>
                  <Text color={"#008FFB"} fontWeight={"bold"} >
                    Tiempo de Llenado
                  </Text>
                  <Text color={"#203764"} fontWeight={"bold"} >
                    Transferencia VP
                  </Text>
                </Grid>
                <Grid justifyContent={"left"} width={"100%"}>
                  <Flex direction={"column"}>
                    <Flex direction={"column"} w={"95%"}>
                      <Flex color={"black"} direction={"column"} w={"95%"}>
                        <Flex
                          mt={5}
                          color={"black"}
                          direction={"column"}
                          w={"95%"}
                          minHeight={"300px"}
                        >
                          <Flex
                            direction={"row"}
                            mb={10}
                            justifyContent={"center"}
                            alignItems={"center"}
                          ></Flex>
                          <Flex
                            color={"black"}
                            direction={"column"}
                            w={"90%"}
                            borderColor={"green"}
                            position={"absolute"}
                          >
                            <Box w="95%" minH={{sm: "300px"}}>
                              <LineChart
                                chartOptions={lineChartOptionsWeight}
                                chartData={lineChartDataWeight}
                              />
                            </Box>
                          </Flex>
                          <Flex
                            color={"black"}
                            direction={"column"}
                            w={"90%"}
                            borderColor={"green"}
                            position={"absolute"}
                          >
                            <Box w="95%" minH={{sm: "300px"}}>
                              <LineChart
                                chartOptions={lineChartOptionsFillingTime}
                                chartData={lineChartDataFillingTime}
                              />
                            </Box>
                          </Flex>
                          <Flex
                            color={"black"}
                            direction={"column"}
                            w={"90%"}
                            borderColor={"green"}
                            position={"absolute"}
                          >
                            <Box w="95%" minH={{sm: "300px"}}>
                              <LineChart
                                chartOptions={lineChartOptionsCushion}
                                chartData={lineChartDataCushion}
                              />
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Grid>
            </div>
          </Grid>

        )}
        <IndividualFooter
          result={result}
          url={"check-valve-repeatibility"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={!useVerifyForm(data)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualForm;
