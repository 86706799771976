export const marginBottomDivider = 5;

export const initialProjectStateRadio = {
  uom: "INTERNACIONAL",
  resinId: "",
  machineId: "",
  toolId: "",
  machineName: "",
  resinName: "",
  toolName: "",
};

export const initialProjectState = {
  projectName: "",
};

export const projectColumnsData = [
  {
    Header: "Unidad de Medida",
    accessor: "uom",
    type: "radio",
  },
  {
    Header: "Nombre del proyecto",
    accessor: "projectName",
    type: "string",
  },
  {
    Header: "Molde",
    accessor: "machineName",
    type: "string",
  },
  {
    Header: "Resina",
    accessor: "resinName",
    type: "string",
  },
  {
    Header: "Maquina",
    accessor: "toolName",
    type: "string",
  },
  {
    Header: "Fase",
    accessor: "phase",
    type: "string",
  },
];
