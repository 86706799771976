import {delay} from "./useDelay";
import {getUrl} from "aws-amplify/storage";

export const useGetUrl = async (data, setHref) => {
  await delay(500)
  const u = await getUrl({
    key: data?.chartImage,
    options: {
      accessLevel: "guest", // can be 'private', 'protected', or 'guest' but defaults to `guest`
    },
  });
  setHref(u.url.href);
};