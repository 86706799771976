import {Button, FormLabel, Grid, GridItem} from "@chakra-ui/react";
import CustomInput from "../../../../../../projects/individual/components/CustomInput";
import React, {useEffect} from "react";

import moment from "moment-timezone"
import {
  isAllHeaderValid,
} from "../../../../utilities/utilitiesHeaderTool";
import HorizontalDivider from "../../../../../../../components/dividers/HorizontalDivider";
import {textColor} from "../../../../../../users/molds/utilities/variables";
import Select from "react-select";
const fechaCdmx = moment.tz('America/Mexico_City');

const HeaderTool = ({geaDataInput, setGeaDataInput, setErrors, errors, handleInputChange, onChangeSelect}) => {

  useEffect(() => {
    setGeaDataInput((prevState) => ({ ...prevState, geadate: fechaCdmx.format('YYYY-MM-DD') }))
  }, []);

  const handleSubmit = async () => {
    if (geaDataInput.phase === 1) {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 2}))
    } else {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 1}))
    }
  };

  const options = [
    {label: "Kgs", value: "Kgs"},
    {label: "Lbs", value: "Lbs"},
  ]

  return (
    <>
      {
        geaDataInput.phase === 1 && (
          <GridItem colSpan={4} width={"80%"}>
            <Grid templateColumns="repeat(4, 1fr)" width={"100%"} gap={3}>
              <CustomInput
                onChange={handleInputChange}
                id={"geaUserID"}
                label={"Usuario"}
                value={geaDataInput.geaUserID}
                error={errors.geaUserID}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaCompany"}
                label={"Empresa"}
                value={geaDataInput.geaCompany}
                error={errors.geaCompany}
              />
              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Unidad de Medida de Peso
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("geaUdM", e.value)}
                  id={"geaUdM"}
                  isSearchable
                  defaultValue={options[0]}
                  options={options}
                />
              </GridItem>
              <CustomInput
                disabled={true}
                onChange={handleInputChange}
                id={"geadate"}
                label={"Fecha"}
                value={geaDataInput.geadate}
                error={errors.geadate}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaProduct"}
                label={"Producto"}
                value={geaDataInput.geaProduct}
                error={errors.geaProduct}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                onChange={handleInputChange}
                id={"geaPartWeight"}
                label={"Peso de Pieza"}
                value={geaDataInput.geaPartWeight}
                error={errors.geaPartWeight}
              />
              <CustomInput
                onChange={handleInputChange}
                id={"geaMaterial"}
                label={"Tipo de Plastico"}
                value={geaDataInput.geaMaterial}
                error={errors.geaMaterial}
              />
              <CustomInput
                addOnText={"%"}
                onChange={handleInputChange}
                id={"geaRegrindPercent"}
                label={"Porcentaje de Remolido"}
                value={geaDataInput.geaRegrindPercent}
                error={errors.geaRegrindPercent}
              />
              <CustomInput
                addOnText={"Pzs"}
                onChange={handleInputChange}
                id={"geaSampleSize"}
                label={"Tamaño de Muestras"}
                value={geaDataInput.geaSampleSize}
                error={errors.geaSampleSize}
              />
              <CustomInput
                addOnText={"%"}
                onChange={handleInputChange}
                id={"geaEinarPercent"}
                label={"Porcentaje Einar 201"}
                value={geaDataInput.geaEinarPercent}
                error={errors.geaEinarPercent}
              />
              <CustomInput
                addOnText={"%"}
                onChange={handleInputChange}
                id={"geaOtherAdditives"}
                label={"Otros Aditivos"}
                value={geaDataInput.geaOtherAdditives}
                error={errors.geaOtherAdditives}
              />
              <CustomInput
                addOnText={"Pzs"}
                onChange={handleInputChange}
                id={"geaCavities"}
                label={"Cavidades"}
                value={geaDataInput.geaCavities}
                error={errors.geaCavities}
              />
            </Grid>
          </GridItem>
        )
      }
      <GridItem colSpan={4} width={"80%"} mt={5}>
        <Button
          onClick={() => handleSubmit()}
          width={"100%"}
          children={geaDataInput.phase === 1 ? "Continuar" : "Editar Header"}
          colorScheme={"whatsapp"}
          disabled={!isAllHeaderValid(geaDataInput, errors)}
        />
      </GridItem>
    </>
  );
};

export default HeaderTool;
