import { Grid, Image, Link, Text } from "@chakra-ui/react";
import { headerImage } from "../utilities";
import React from "react";
import asaCleanLogo from "./../../../../assets/img/collaborators/asaclean/asaclean-esp-2.svg"

const AsaCleanHeader = ({ bgColor }) => {
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      backgroundColor={bgColor}
      justifyItems={"center"}
      borderRadius={15}
    >
      <Image src={asaCleanLogo} />
      <Grid justifyItems={"left"} mt={10}>
        <Text as={"h1"} fontWeight={"bold"} fontSize={"4xl"}>
          Acerca de Asaclean®
        </Text>
        <Text as={"h2"}>
          Los compuestos de purga Asaclean® son la marca número uno del mundo en
          compuestos de purga.
        </Text>
        <Text as={"h2"}>
          Somos su mejor opción para maximizar la rentabilidad al reducir el
          desperdicio y el tiempo muerto.
        </Text>
        <Text as={"h2"}>Somos los líderes del mercado por una razón.</Text>
        <Text as={"h2"}>
          Con I + D de clase mundial, la línea de productos de alto rendimiento
          más completa de la industria, más de 200 años de experiencia en
          procesamiento combinado y envío el mismo día para entrega JIT, estamos
          en una posición única para ayudarlo a alcanzar y superar sus objetivos
          de eficiencia de procesos.{" "}
        </Text>
      </Grid>
      <Grid justifyItems={"left"} mt={10} gap={3}>
        <Text fontWeight={"bold"} fontSize={"4xl"}>
          Acerca de los compuestos de purga Asaclean®
        </Text>
        <Text fontWeight={"bold"} fontSize={"2xl"}>
          Innovación y experiencia en ciencia de materiales
        </Text>
        <Text>
          En 1990, los compuestos de purga Asaclean® se diseñaron e introdujeron
          inicialmente en Japón.
        </Text>
        <Text>
          En la actualidad, Asaclean® es el líder mundial en compuestos de purga
          comerciales. Los compuestos de purga Asaclean® se utilizan actualmente
          y los fabricantes de plásticos confían en ellos en más de 70 países.
        </Text>
        <Text>
          Nuestra línea de productos presenta grados{" "}
          <Link
            isExternal
            color={"red"}
            href={
              "https://www.asaclean.com/es/productos-de-purga/compuestos-de-purga-mecanica"
            }
          >
            mecánicos
          </Link>{" "}
          ,{" "}
          <Link
            isExternal
            color={"red"}
            href={
              "https://www.asaclean.com/purging-products/chemical-purging-compounds"
            }
          >
            químicos
          </Link>{" "}
          y{" "}
          <Link
            isExternal
            color={"red"}
            href={
              "https://www.asaclean.com/es/productos-de-purga/purga-concentrados"
            }
          >
            concentrados
          </Link>{" "}
          que están científicamente diseñados para un rendimiento óptimo en
          función de su proceso y resinas. Las purgas Asaclean® están
          optimizadas para aplicaciones de{" "}
          <Link
            isExternal
            color={"red"}
            href={"https://www.asaclean.com/es/proceso/inyeccion-moldeo"}
          >
            moldeo por inyección
          </Link>{" "}
          ,{" "}
          <Link
            isExternal
            color={"red"}
            href={"https://www.asaclean.com/es/proceso/extrusion-general"}
          >
            extrusión
          </Link>{" "}
          ,{" "}
          <Link
            isExternal
            color={"red"}
            href={"https://www.asaclean.com/es/proceso/extrusion-lamina"}
          >
            perfiles y láminas
          </Link>{" "}
          ,{" "}
          <Link
            isExternal
            color={"red"}
            href={"https://www.asaclean.com/es/proceso/moldeo-soplado"}
          >
            moldeo por soplado
          </Link>{" "}
          y{" "}
          <Link
            isExternal
            color={"red"}
            href={"https://www.asaclean.com/es/proceso/extrusion-pelicula"}
          >
            película soplada
          </Link>{" "}
          .
        </Text>
        <Text>
          Los beneficios de Asaclean® incluyen cambios más rápidos / reducción
          del tiempo muerto,{" "}
          <Link
            isExternal
            color={"red"}
            href={"https://www.asaclean.com/es/soluciones/carbon-contaminacion"}
          >
            eliminación eficaz de la contaminación de color
          </Link>{" "}
          /{" "}
          <Link
            isExternal
            color={"red"}
            href={"https://www.asaclean.com/es/soluciones/carbon-contaminacion"}
          >
            carbón
          </Link>
          , reducción de{" "}
          <Link
            isExternal
            color={"red"}
            href={
              "https://www.asaclean.com/es/soluciones/extraccion-de-tornillos"
            }
          >
            extracciones de tornillos
          </Link>
          , menor tasa de rechazo / desperdicio, mayor productividad y mayores
          ahorros de costos.
        </Text>
        <Text>
          Fabricados y distribuidos por Asahi Kasei Plastics North America, los
          productos Asaclean® están respaldados por la tecnología y los recursos
          de la organización matriz,{" "}
          <Link isExternal color={"red"} href={"https://www.ak-america.com/"}>
            Asahi Kasei Corporation
          </Link>
          .
        </Text>
      </Grid>
    </Grid>
  );
};

export default AsaCleanHeader;
