import { useEffect, useState } from "react";
import {
  convertAreaPerSecondInMm,
  convertInMm,
  convertPressureOrStress,
  convertPsiBar,
  convertTemperature,
} from "../../../projects/individual/hooks/useConvertUom";
import { DataStore } from "aws-amplify/datastore";
import { Resin } from "../../../../models";
import { useAlert } from "../../../../contexts/useAlert";

export const useConvertResinHooks = () => {
  const [uom, setUom] = useState("");
  const [items, setItems] = useState([]);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (uom) {
      getItems();
    }
  }, [uom]);

  const getItems = async () => {
    await DataStore.query(Resin, (c) => c.uom.eq(uom))
      .then((res) => {
        setItems(res);
      })
      .catch((err) => {
        showErrorAlert("Ha ocurrido un error al cargar las resinas");
      });
  };

  const onSelectItem = (value) => {
    setItem(value);
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const data = convertItem(item);

      const savedItem = await DataStore.save(
        new Resin({
          ...data,
        })
      );
      setItem(savedItem);
      showSuccessAlert();
      setIsComplete(true);
    } catch (err) {
      showErrorAlert(
        err?.message
          ? err.message
          : "Ha ocurrido un error, favor de contactar con el administrador"
      );
    }
    setIsLoading(false);
  };

  const selectOptions = [
    {
      label: "INGLES",
      value: "INGLES",
    },
    {
      label: "INTERNACIONAL",
      value: "INTERNACIONAL",
    },
  ];
  const convertItem = (item) => {
    const { uom } = item;
    const { createdAt, updatedAt, ...allItem } = item;
    return {
      ...allItem,
      uom: uom === "INGLES" ? "INTERNACIONAL" : "INGLES",
      meltTempMin: convertTemperature(uom, item.meltTempMin),
      meltTempMax: convertTemperature(uom, item.meltTempMax),
      nozzleTempMin: convertTemperature(uom, item.nozzleTempMin),
      nozzleTempMax: convertTemperature(uom, item.nozzleTempMax),
      FrontTempMin: convertTemperature(uom, item.FrontTempMin),
      FrontTempMax: convertTemperature(uom, item.FrontTempMax),
      midTempMin: convertTemperature(uom, item.midTempMin),
      midTempMax: convertTemperature(uom, item.midTempMax),
      backTempMin: convertTemperature(uom, item.backTempMin),
      backTempMax: convertTemperature(uom, item.backTempMax),
      toolAsideTempMin: convertTemperature(uom, item.toolAsideTempMin),
      toolAsideTempMax: convertTemperature(uom, item.toolAsideTempMax),
      toolBsideTempMin: convertTemperature(uom, item.toolBsideTempMin),
      toolBsideTempMax: convertTemperature(uom, item.toolBsideTempMax),
      thermalDiffusion: convertAreaPerSecondInMm(uom, item.thermalDiffusion),
      demoldingTemperature: convertTemperature(uom, item.demoldingTemperature),
      dryTempMin: convertTemperature(uom, item.dryTempMin),
      dryTempMax: convertTemperature(uom, item.dryTempMax),
      dewPoint: convertTemperature(uom, item.dewPoint),
      backPressureMin: convertPsiBar(uom, item.backPressureMin),
      backPressureMax: convertPsiBar(uom, item.backPressureMax),
      resinInjectionPressureMax: convertPsiBar(
        uom,
        item.resinInjectionPressureMax
      ),
      tonnageFactorMin: convertPressureOrStress(uom, item.tonnageFactorMin),
      tonnageFactorMax: convertPressureOrStress(uom, item.tonnageFactorMax),
      cushionPositionMin: convertInMm(uom, item.cushionPositionMin),
      cushionPositionMax: convertInMm(uom, item.cushionPositionMax),
      minimumVentDepth: convertInMm(uom, item.minimumVentDepth),
      ventLong: convertInMm(uom, item.ventLong),
      ventWidth: convertInMm(uom, item.ventWidth),
      reliefDeep: convertInMm(uom, item.reliefDeep),
    };
  };

  return {
    selectOptions,
    item,
    setUom,
    setItem,
    isLoading,
    items,
    isComplete,
    setIsComplete,
    onSelectItem,
    onSubmitHandler,
  };
};
