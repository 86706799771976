import React from "react";
import IndividualBase from "../../components/IndividualBase";
import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IndividualForm from "./components/IndividualForm";
import HelmetComponent from "../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import CustomGrid from "../../components/CustomGrid";
import { GridItem } from "@chakra-ui/react";
import instructions from "./utilities/instructions";
import description from "./utilities/description";
const IndividualLossPressure = () => {
  return (
    <>
      <HelmetComponent
        keywords={metaKeywords}
        title={metaTitle}
        description={metaDescription}
      />
      <HeaderProvider>
        <GridItem width={"100%"} mt={"120px"}>
          <CustomGrid>
            <IndividualBase
              description={description}
              instructions={instructions}
              title={"Analisis de Perdida de Presión"}
            >
              <IndividualForm />
            </IndividualBase>
          </CustomGrid>
        </GridItem>
      </HeaderProvider>
    </>
  );
};

export default IndividualLossPressure;
