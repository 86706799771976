export const useSetGeaPoints = (geaDataInput) => {
  let points = 0;
  if (geaDataInput.geaRearDelta &&
    geaDataInput.geaCenterDelta &&
    geaDataInput.geaFrontDelta &&
    geaDataInput.geaNozzleDelta &&
    geaDataInput.geaShotSizeDelta &&
    geaDataInput.geaTransferDelta &&
    geaDataInput.geaRpmDelta &&
    geaDataInput.geaBackpressDelta &&
    geaDataInput.geaInjSpeedDelta &&
    geaDataInput.geaInjPressDelta &&
    geaDataInput.geaTempMeltDelta &&
    geaDataInput.geaCushionDelta &&
    geaDataInput.geaMaxPressDelta &&
    geaDataInput.geaRecoveryTimeDelta &&
    geaDataInput.geaFillTimeDelta &&
    geaDataInput.geaCycleTimeDelta &&
    geaDataInput.geaRpmAmpDelta &&
    geaDataInput.geaInjectionAmpDelta &&
    geaDataInput.geaShotWeightDelta &&
    geaDataInput.geaMoldReleaseDelta &&
    geaDataInput.geaAntiDustDelta &&
    geaDataInput.geaAntiStaticDelta &&
    geaDataInput.geaDenestingDelta) {

  }
  if (
    geaDataInput.geaRearDelta &&
    parseFloat(geaDataInput.geaRearDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaCenterDelta &&
    parseFloat(geaDataInput.geaCenterDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaFrontDelta &&
    parseFloat(geaDataInput.geaFrontDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaNozzleDelta &&
    parseFloat(geaDataInput.geaNozzleDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaShotSizeDelta &&
    parseFloat(geaDataInput.geaShotSizeDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaTransferDelta &&
    parseFloat(geaDataInput.geaTransferDelta) > 0
  ) {
    points++;
  }
  if (geaDataInput.geaRpmDelta && parseFloat(geaDataInput.geaRpmDelta) < 0) {
    points++;
  }
  if (
    geaDataInput.geaBackpressDelta &&
    parseFloat(geaDataInput.geaBackpressDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaInjSpeedDelta &&
    parseFloat(geaDataInput.geaInjSpeedDelta) > 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaInjPressDelta &&
    parseFloat(geaDataInput.geaInjPressDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaTempMeltDelta &&
    parseFloat(geaDataInput.geaTempMeltDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaCushionDelta &&
    parseFloat(geaDataInput.geaCushionDelta) > 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaMaxPressDelta &&
    parseFloat(geaDataInput.geaMaxPressDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaRecoveryTimeDelta &&
    parseFloat(geaDataInput.geaRecoveryTimeDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaFillTimeDelta &&
    parseFloat(geaDataInput.geaFillTimeDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaCycleTimeDelta &&
    parseFloat(geaDataInput.geaCycleTimeDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaRpmAmpDelta &&
    parseFloat(geaDataInput.geaRpmAmpDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaInjectionAmpDelta &&
    parseFloat(geaDataInput.geaInjectionAmpDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaShotWeightDelta &&
    parseFloat(geaDataInput.geaShotWeightDelta) < 0
  ) {
    points++;
  }
  if (
    geaDataInput.geaMoldReleaseDelta &&
    geaDataInput.geaMoldReleaseDelta === "Mejorado"
  ) {
    points++;
  }
  if (
    geaDataInput.geaAntiDustDelta &&
    geaDataInput.geaAntiDustDelta === "Mejorado"
  ) {
    points++;
  }
  if (
    geaDataInput.geaAntiStaticDelta &&
    geaDataInput.geaAntiStaticDelta === "Mejorado"
  ) {
    points++;
  }
  if (
    geaDataInput.geaDenestingDelta &&
    geaDataInput.geaDenestingDelta === "Mejorado"
  ) {
    points++;
  }

  return points
}