import DataDisplayBadge from "../../../../components/DataDisplay/DatadisplayBadge";
import {
    inCubeCmCube,
    inMm,
    inSquareMmSquare,
} from "../../../projects/individual/hooks/useUomTransform";
import React from "react";
import {Grid, GridItem} from "@chakra-ui/react";

const Data = ({item, textColor = "gray.600"}) => {
  return (
    <>
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Sistema de medición"}
        color={textColor}
        value={item.uom}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Nombre del molde"}
        color={textColor}
        value={item.toolId}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Numero de Serie"}
        color={textColor}
        value={item.serialNumber}
        type={"text"}
      />
        <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tipo de Molde"}
        color={textColor}
        value={item.toolType}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Nombre del producto"}
        color={textColor}
        value={item.product}
        type={"text"}
      />

      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Numero de Cavidades"}
        color={textColor}
        value={item.numberOfCavities}
        type={"int"}
        rightAddon={"pzs"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Medida Horizontal"}
        color={textColor}
        value={item.horizontalMeasurement}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Medida Vertical"}
        color={textColor}
        value={item.verticalMeasurement}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Altura de Molde (STACK)"}
        color={textColor}
        value={item.stackMold}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Volumen de disparo"}
        color={textColor}
        value={item.shootVolume}
        type={"weight"}
        rightAddon={inCubeCmCube(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Volumen de cavidades"}
        color={textColor}
        value={item.cavityVolume}
        type={"weight"}
        rightAddon={inCubeCmCube(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Volumen de runner"}
        color={textColor}
        value={item.runnerVolume}
        type={"weight"}
        rightAddon={inCubeCmCube(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tiempo de ciclo"}
        color={textColor}
        value={item.cycleTime}
        type={"time"}
        rightAddon={"seg"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tamaño de compuerta"}
        color={textColor}
        value={item.gateSize}
        type={"weight"}
        rightAddon={inSquareMmSquare(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Expulsión de molde maxima"}
        color={textColor}
        value={item.maxMoldEject}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Forma de la compuerta"}
        color={textColor}
        value={item.gateForm}
        type={"text"}
      />
        {
            item?.gateForm === "CIRCULAR" ? (
              <DataDisplayBadge
                badgeColorScheme={"blue"}
                title={"Diametro de la compuerta"}
                color={textColor}
                value={item.gateDiameter}
                type={"weight"}
                rightAddon={inMm(item?.uom)}
              />
            ) : (
              <GridItem colSpan={3}>
                  <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                      <DataDisplayBadge
                        badgeColorScheme={"blue"}
                        title={"Base de la compuerta"}
                        color={textColor}
                        value={item.widthGate}
                        type={"weight"}
                        rightAddon={inMm(item?.uom)}
                      />
                      <DataDisplayBadge
                        badgeColorScheme={"blue"}
                        title={"Altura de la compuerta"}
                        color={textColor}
                        value={item.heightGate}
                        type={"weight"}
                        rightAddon={inMm(item?.uom)}
                      />
                      <DataDisplayBadge
                        badgeColorScheme={"blue"}
                        title={"Grosor de la compuerta"}
                        color={textColor}
                        value={item.deepGate}
                        type={"weight"}
                        rightAddon={inMm(item?.uom)}
                      />
                  </Grid>

              </GridItem>
            )
        }
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Sistema Hidraulico"}
        color={textColor}
        value={item.toolHydraulicSystem}
        type={"text"}
      />
        <DataDisplayBadge
          badgeColorScheme={"blue"}
          title={"Sistema Neumatico"}
          color={textColor}
          value={item.toolPneumaticSystem}
          type={"text"}
        />
        <DataDisplayBadge
          badgeColorScheme={"blue"}
          title={"Sistema Vacio"}
          color={textColor}
          value={item.toolVacummSystem}
          type={"text"}
        />
    </>
  )
}

export default Data