/*eslint-disable*/
import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import CollaboratorsFooter from "./CollaboratorsFooter";

export default function Footer(props) {
  return (
    <>
      <CollaboratorsFooter />
      <Flex
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        alignItems={{
          base: "center",
          xl: "start",
        }}
        justifyContent="space-between"
        px={{ base: "15px", md: "30px" }}
        pb="20px"
      >
        <Text
          fontSize="sm"
          color="white"
          textAlign={{
            base: "center",
            xl: "start",
          }}
          mb={{ base: "20px", xl: "0px" }}
        >
          &copy; {1900 + new Date().getYear()},{" "}
          <Text as="span">{"Made with ❤️ by "}</Text>
          {"GGAps "}&{" Fenox Corp"}
        </Text>
      </Flex>
    </>
  );
}
