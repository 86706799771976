import { useIndividualHooperCapacityHooks } from "../hooks/useIndividualHooperCapacityHooks";
import IndividualFooter from "../../../components/IndividualFooter";
import HooperForm from "./Form";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { keysForForm } from "../utilities/variables";
import React from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import ShowResults from "../../../components/ShowResults";
import { dataResults } from "../utilities/dataToShow";

const IndividualHooperCapacityForm = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
  } = useIndividualHooperCapacityHooks(setIsDisabled);

  const isDisabled =
    !useVerifyForm(data, keysForForm) ||
    parseFloat(data?.prodQuantity) < 1 ||
    parseFloat(data?.scrapRisk) < 0 ||
    parseFloat(data?.numberOfCavities) < 1 ||
    parseFloat(data?.hopperCapacity) <= 0 ||
    parseFloat(data?.estCycleTime) <= 0 ||
    parseFloat(data?.shotWeight100) <= 0;
  const isFormDisabled = !isObjectEmpty(result);

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <HooperForm
          onChange={onChangeHandler}
          data={data}
          disabled={isFormDisabled}
        />
        {!isObjectEmpty(result) && <ShowResults data={dataResults(result)} />}
        <IndividualFooter
          result={result}
          url={"hopper-capacity"}
          isLoading={isLoading}
          onSubmitHandler={onSubmitHandler}
          isDisabled={isDisabled}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualHooperCapacityForm;

//banner/projects/pdf/intensification-ratio/1e91863a-8c54-4f8b-b84f-5d5302be0694
