import { Grid, Image, Text, useColorModeValue } from "@chakra-ui/react";
import ldHeader from "../../../../assets/img/collaborators/ld/ld-logo.jpg";
import HorizontalDivider from "../../../../components/dividers/HorizontalDivider";

const LdHeader = ({ textColor }) => {
  const whiteGradient = useColorModeValue(
    "linear(to-r, #ffffff, #f0f0f0, #ffffff)", // Gradiente para el modo claro
    "linear(to-r, #e0e0e0, #c2c2c2, #e0e0e0)" // Gradiente para el modo oscuro (opcional)
  );
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      borderRadius={15}
      bgGradient={whiteGradient}
      justifyItems={"center"}
      gap={6}
    >
      <Image src={ldHeader} borderRadius={15} />
      <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
        LD PLASTIC SOLUTION somos uno de los principales fabricantes de moldes
        de inyección de plástico del mundo.
      </Text>
      <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
        Impulsamos el desarrollo, estableciendo puntos de referencia en
        automoción, moldes de alta precisión, Moldes 2k y distintas áreas de
        diseño.
      </Text>
      <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
        Con el apoyo de Administración de Proyectos, Diseño, Ingeniería,
        Maquinado y Fabricación de Prototipos trabajamos en conjunto con
        nuestros clientes para el desarrollo y lanzamiento de la mejor calidad
        de herramientas de la Industria.
      </Text>
      <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
        Tenemos presencia mundial en America, Europa y Asia, con talleres
        instalados en:
      </Text>
      <Grid justifyItems={"left"} gap={1}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          America
        </Text>
        <li style={{ color: textColor }}>México</li>
        <li style={{ color: textColor }}>Canada</li>
        <li style={{ color: textColor }}>Estados Unidos</li>
        <HorizontalDivider mt={2} mb={2} />
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Europa
        </Text>
        <li style={{ color: textColor }}>Alemania</li>
        <li style={{ color: textColor }}>Francia</li>
        <li style={{ color: textColor }}>España</li>
        <HorizontalDivider mt={2} mb={2} />
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Asia
        </Text>
        <li style={{ color: textColor }}>Dongguan China</li>
      </Grid>
      <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
        Instalados en México desde el 2022 para lograr una mejor atención y
        servicio a nuestros clientes en toda el territorio nacional, ubicados en
        el parque industrial de Queretaro, con un equipo de soporte altamente
        capacitado y maquinaria de la mejor calidad para el desarrollo de todos
        nuestros proyectos.
      </Text>
    </Grid>
  );
};

export default LdHeader;
