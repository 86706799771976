import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../contexts/UserContext";
import { DataStore } from "aws-amplify/datastore";
import { User } from "../../../../models";
import { useHeader } from "../../../../contexts/IndividualContext/HeaderContext";

export const useHeaderHooks = () => {
  const [isDisabled, setIsDisabled] = useState(false)
  const { user, updateUser } = useContext(UserContext);

  const {
    name,
    setName,
    isLoading,
    setIsLoading,
    uom,
    setUom,
    clearHeader,
    toolName,
    setToolName,
    machineName,
    setMachineName,
    resinName,
    setResinName,
    productName,
    setProductName,
  } = useHeader();

  const selectOptions = [
    { value: "INTERNACIONAL", label: "Internacional" },
    { value: "INGLES", label: "Inglés" },
  ];

  const onSubmitHandler = async () => {
    setIsLoading(true);
    try {
      let usr = await DataStore.query(User, user.id);
      usr = await DataStore.save(
        User.copyOf(usr, (updated) => {
          updated.name = name;
        })
      );
      console.log(usr);
      updateUser(usr);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    selectOptions,
    name,
    setName,
    onSubmitHandler,
    user,
    uom,
    clearHeader,
    toolName,
    setToolName,
    machineName,
    setMachineName,
    resinName,
    setResinName,
    productName,
    setProductName,
    setUom,
    isDisabled,
    setIsDisabled
  };
};
