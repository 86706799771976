import React, { useEffect, useState } from "react";
import { DataStore, Predicates } from "aws-amplify/datastore";
import { Melt3030 } from "../../../../../models";
import CustomGrid from "../../components/CustomGrid";
import { textColor } from "../../../../users/machines/utilities/variables";
import SearchTable1 from "../../../../../components/Tables/SearchTable1";
import { individualColumnsData } from "../../utilities/variabled";

const Melt3030Index = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const rawData = await DataStore.query(Melt3030, Predicates.ALL, {
        sort: (u) => u.createdAt("DESCENDING"),
      });

      const dataTransformed = rawData.map((item) => {
        const dateOptions = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const formattedDate = new Intl.DateTimeFormat(
          "es-ES",
          dateOptions
        ).format(new Date(item.createdAt));

        return {
          ...item,
          createdAt: formattedDate,
        };
      });

      setItems(dataTransformed);
    };
    getData();
  }, []);

  const showPdf = (item) => {
    const urlPath = `/banner/projects/pdf/melt-3030/${item?.id}`;
    window.open(urlPath, "_blank");
  };

  return (
    <CustomGrid
      mt={"150px"}
      templateColumns="repeat(1, 1fr)"
      alignItems={"center"}
      justifyItems={"center"}
      gap={6}
      borderWidth={1}
      mb={5}
      p={5}
      borderRadius={15}
    >
      <SearchTable1
        title={"de Melt 3030 (Individual)"}
        textColor={textColor}
        tableData={items}
        columnsData={individualColumnsData}
        onShowHandler={(id) => showPdf(id)}
      />
    </CustomGrid>
  );
};

export default Melt3030Index;
