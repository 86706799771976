import { Box, Flex } from "@chakra-ui/react";
import BarChart from "../../../../../../../components/Charts/BarChart";
import React from "react";

const ShotSizeChart = ({
  barChartOptions,
  barChartData,
  barChartOptions2,
  barChartData2,
}) => {

  return (
    <Flex color={"white"} direction={"column"} w={"100%"} minHeight={"300px"}>
      <Flex
        color={"white"}
        direction={"column"}
        w={"40%"}
        borderColor={"green"}
        position={"absolute"}
      >
        <Box w="100%" minH={{ sm: "300px" }}>
          <BarChart chartOptions={barChartOptions} chartData={barChartData} />
        </Box>
      </Flex>
      <Flex
        color={"white"}
        direction={"column"}
        w={"40%"}
        borderColor={"green"}
        position={"absolute"}
      >
        <Box w="100%" minH={{ sm: "300px" }}>
          <BarChart chartOptions={barChartOptions2} chartData={barChartData2} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ShotSizeChart;
