import { useState, useEffect } from 'react';
import {DataStore} from "aws-amplify/datastore";
import {Resin} from "../../../../models";

const useResinCount = () => {
  const [resinCount, setResinCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchResinCount = async () => {
      try {
        const resins = await DataStore.query(Resin);
        setResinCount(resins.length); // Usa la longitud de la lista
      } catch (error) {
        console.error('Error al obtener el conteo de resinas:', error);
      }
      setIsLoading(false);
    };

    fetchResinCount();
  }, []);

  return { resinCount, isLoading };
};

export default useResinCount;
