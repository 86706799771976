import React, { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import SearchTable1 from "../../../components/Tables/SearchTable1";
import { DataStore } from "aws-amplify/datastore";
import { Resin } from "../../../models";
import { resinColumnsData } from "./utilities/variables";
import ShowModal from "./components/ShowModal";
import CustomGrid from "../../projects/individual/components/CustomGrid";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionIndex,
  metaKeywordsIndex,
  metaTitleIndex,
} from "./utilities/metaVariables";

const MyResines = () => {
  const [resins, setResines] = useState([]);
  const [item, setItem] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getResins();
  }, []);

  const getResins = async () => {
    const data = await DataStore.query(Resin);
    setResines(data);
  };

  const onShowHandler = (data) => {
    setItem(data);
    onOpen();
  };

  const textColor = "gray.600";

  return (
    <>
      <HelmetComponent
        description={metaDescriptionIndex}
        title={metaTitleIndex}
        keywords={metaKeywordsIndex}
      />
      <ShowModal item={item} isOpen={isOpen} onClose={onClose} />
      <CustomGrid
        mt={"150px"}
        templateColumns="repeat(1, 1fr)"
        alignItems={"center"}
        justifyItems={"center"}
        gap={6}
        borderWidth={1}
        mb={5}
        p={5}
        borderRadius={15}
      >
        <SearchTable1
          title={"de mis resinas"}
          textColor={textColor}
          tableData={resins}
          columnsData={resinColumnsData}
          onShowHandler={(item) => onShowHandler(item)}
        />
      </CustomGrid>
    </>
  );
};

export default MyResines;
