import React, { Suspense, lazy } from "react";
const AuthNavbar = lazy(() => import("components/Navbars/AuthNavbar.js"));
const CollaboratorsFooter = lazy(() =>
  import("../components/Footer/CollaboratorsFooter")
);
import { Box, ChakraProvider, Portal } from "@chakra-ui/react";
import "assets/css/vud-dashboard-styles.css";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import theme from "theme/themeBanner.js";
import LoadingComponent from "../components/LoadingComponent";

export default function Pages() {
  const wrapper = React.createRef();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    return function cleanup() {};
  });

  const rutaContienePdf = () => {
    return window.location.href.includes("pdf");
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "MVPS";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/banner") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  const navRef = React.useRef();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "auth";
  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box ref={navRef} w="100%">
        {
          /*
          <Suspense fallback={<LoadingComponent />}>
        {!rutaContienePdf() && (
          <Portal containerRef={navRef}>
            <AuthNavbar secondary={getActiveNavbar(routes)} logoText='MVPS' />
          </Portal>
        )}
      </Suspense>

           */
        }
        <Suspense fallback={<LoadingComponent />}>
          {!rutaContienePdf() && (
            <Portal containerRef={navRef}>
              <AuthNavbar secondary={getActiveNavbar(routes)} logoText='MVPS' />
            </Portal>
          )}
        </Suspense>

        <Box w="100%">
          <Box ref={wrapper} w="100%">
            <Switch>
              {getRoutes(routes)}
              <Redirect
                from="/banner"
                to="/banner/authentication/sign-in/cover"
              />
            </Switch>
          </Box>
        </Box>
      </Box>
      <Suspense fallback={<LoadingComponent />}> {/* Otro Suspense para el pie de página */}
        {!rutaContienePdf() && <CollaboratorsFooter />}
      </Suspense>
    </ChakraProvider>
  );
}
