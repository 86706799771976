// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import "assets/css/vud-dashboard-styles.css";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import theme from "theme/themeAdmin.js";
import ProjectContext from "../contexts/ProjectContext";
import { Hub, I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);
I18n.setLanguage("es");
import "./../custom.css";
import UserContext from "../contexts/UserContext";
import { DataStore } from "aws-amplify/datastore";
import { User } from "../models";
import moment from "moment/moment";

import { generateClient } from "aws-amplify/api";
const client = generateClient();
import { getCurrentUser } from "aws-amplify/auth";

function Dashboard(props) {
  const { updateUser, user } = useContext(UserContext);
  const { updateMachine, updateTool, updateProject, updateResin } = useContext(
    ProjectContext
  );

  useEffect(() => {
    const initUser = async () => {
      const currentUser = await getCurrentUser();

      if (!currentUser) {
        console.log("No user is currently signed in.");
        return;
      }

      const userId = currentUser.userId;

      const subscription = DataStore.observe(User, userId).subscribe((msg) => {
        if (msg.opType === "UPDATE" || msg.opType === "INSERT") {
          updateUser(msg.element);
        }
      });

      const userFromDataStore = await DataStore.query(User, userId);
      if (!userFromDataStore) {
        createUser(currentUser);
      } else {
        updateUser(userFromDataStore);
      }

      return () => subscription.unsubscribe();
    };

    initUser();
  }, []);

  const createUser = async (currentUser) => {
    const id = currentUser.userId;
    const email = currentUser.signInDetails.loginId;
    const expiration = moment().add(10, "days").format("YYYY-MM-DD");
    const mutation = `
      mutation MyMutation($id: ID!, $email: AWSEmail!, $expiration: AWSDate!) {
        createUser(input: { id: $id, email: $email, isActive: true, isSubscribed: false, expirationDate: $expiration, notifications: true, subscrptionType: BASICO, name: "", lastname: "", city: "", state: "", country: "", notifications: true, newsLetter: true }) {
          id
          email
          isActive
          isSubscribed
          expirationDate
        }
      }
    `;

    try {
      const newUser = await client.graphql({
        query: mutation,
        variables: {
          id,
          email,
          expiration,
        },
      });
      updateUser(newUser);
    } catch (err) {
      console.error("Error creating user:", err);
    }
  };

  const listener = async (data) => {
    if (data.payload.event === "signedOut") {
      updateUser([]);
      updateMachine([]);
      updateTool([]);
      updateResin([]);
      updateProject([]);
    }
  };

  Hub.listen("auth", listener);

  const { ...rest } = props;

  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(275);
  const mainPanel = React.createRef();
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "MVPS";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "admin";
  // Chakra Color Mode
  return (
    <>
      <ChakraProvider theme={theme} resetCss={false} overflowX="hidden">
        <SidebarContext.Provider
          value={{
            sidebarWidth,
            setSidebarWidth,
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            routes={routes}
            logoText={"MVPS"}
            display="flex"
            sidebarVariant={sidebarVariant}
            {...rest}
          />
          <MainPanel
            ref={mainPanel}
            w={{
              base: "100%",
              xl: `calc(100% - ${sidebarWidth}px)`,
            }}
          >
            <Portal>
              <AdminNavbar
                onOpen={onOpen}
                logoText={"MVPS"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                fixed={fixed}
                {...rest}
              />
            </Portal>

            {getRoute() ? (
              <PanelContent>
                <PanelContainer>
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/profile/my-profile" />
                  </Switch>
                </PanelContainer>
              </PanelContent>
            ) : null}
            <Footer />
            <Configurator
              secondary={getActiveNavbar(routes)}
              isOpen={isOpen}
              onClose={onClose}
              isChecked={fixed}
              onSwitch={(value) => {
                setFixed(value);
              }}
              onOpaque={() => setSidebarVariant("opaque")}
              onTransparent={() => setSidebarVariant("transparent")}
            />
          </MainPanel>
        </SidebarContext.Provider>
      </ChakraProvider>
    </>
  );
}
export default Dashboard;
