import { Button, Grid, GridItem } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import {
  isFloatString,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import EditBase from "../../Cards/EditBase";
import CustomInputField from "../../../../../../components/inputs/CustomInputField";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import { useDryerSupplyHooks } from "../../../../../../hooks/dryer-supply/useDryerSupplyHooks";
import { borderColor, textColor } from "../../utilities/variables";
import {
  lbHrKgHr,
  lbKg,
  ozGr,
  ozMinGrMin,
} from "../../../../individual/hooks/useUomTransform";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";

const EditDryerSupply = () => {
  const { id } = useParams();
  const {
    project,
    updateProject,
    isLoading,
    handleInputChange,
    resin,
  } = useContext(ProjectContext);
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    showData,
    onFinishDryerSupplyHandler,
    setShowData,
  } = useDryerSupplyHooks();

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <EditBase
        disabled={!showData}
        isLoading={isLoading}
        title={"Abastecimiento de Secador"}
      >
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          {!showData ? (
            <GridItem
              colSpan={2}
              borderWidth={1}
              p={5}
              borderRadius={15}
              borderColor={borderColor}
              mb={5}
              width={"100%"}
            >
              <CustomInputField
                id={"dryerId"}
                value={project.dryerId}
                onChange={handleInputChange}
                label={"ID de secador"}
              />
              <InputCustomFieldProjectRigthAddon
                addOn={"Pcs"}
                id={"quantityToProcess"}
                value={project.quantityToProcess}
                onChange={handleInputChange}
                label={"Cantidad a Procesar"}
              />
              <InputCustomFieldProjectRigthAddon
                addOn={"%"}
                id={"scrapRisk"}
                value={project.scrapRisk}
                onChange={handleInputChange}
                label={"Riesgo de Scrap"}
                mb={2}
              />
              <InputCustomFieldProjectRigthAddon
                addOn={lbKg(project?.uom)}
                id={"dryerCapacity"}
                value={project.dryerCapacity}
                onChange={handleInputChange}
                label={"Capacidad de la tolva de secador"}
              />
            </GridItem>
          ) : (
            <GridItem colSpan={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={5}>
                <GridItem />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  size={"xl"}
                  type={"text"}
                  title={"ID de secador"}
                  value={project?.dryerId}
                />
                <GridItem />
              </Grid>
              <Grid
                borderWidth={1}
                p={5}
                borderRadius={15}
                mb={5}
                borderColor={borderColor}
                templateColumns="repeat(3, 1fr)"
                templateRows="repeat(5, 1fr)"
                gap={6}
              >
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"quantity"}
                  rightAddon={"Pzs"}
                  fix={"0"}
                  title={"Cantidad a Procesar"}
                  value={project?.quantityToProcess}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  fix={2}
                  rightAddon={"%"}
                  title={"Riesgo de Scrap"}
                  value={project?.scrapRisk}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  title={"Capacidad de la tolva de secador"}
                  value={project?.dryerCapacity}
                  fix={2}
                  rightAddon={lbKg(project?.uom)}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  fix={5}
                  rightAddon={ozGr(project?.uom)}
                  title={"Peso de disparo 100%"}
                  value={project?.shotWeight100}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"pressure"}
                  rightAddon={"shots"}
                  title={"Disparos Requeridos"}
                  value={project?.reqShotsForProd}
                  fix={2}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"quantity"}
                  rightAddon={"pcs/Hr"}
                  fix={2}
                  title={"Piezas por hora"}
                  value={project?.pcs_x_Hour}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={
                    resin.dryTimeMax > project?.resinTroughDryer &&
                    resin.dryTimeMin < project?.resinTroughDryer
                      ? "blue"
                      : "red"
                  }
                  type={"time"}
                  rightAddon={"Hrs"}
                  title={"Recorrido de resina"}
                  value={project?.resinTroughDryer}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  rightAddon={lbKg(project?.uom)}
                  type={"temp"}
                  title={"Resina Requerida para producción"}
                  value={project?.reqResinForProduction}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  rightAddon={lbHrKgHr(project?.uom)}
                  type={"time"}
                  title={"Recarga Minima de Secador"}
                  value={project?.minimumDryerReload}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"quantity"}
                  rightAddon={"Pcs"}
                  fix={2}
                  title={"Abastecimiento de Secador"}
                  value={project?.dryerSupplying}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"temp"}
                  rightAddon={"Hrs"}
                  title={"Duracion de la producción"}
                  value={project?.productionDuration}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"quantity"}
                  rightAddon={"times"}
                  fix={2}
                  title={"Recargas Requeridas"}
                  value={project?.reloadsRequired}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"pressure"}
                  rightAddon={ozGr(project?.uom)}
                  title={"Capacidad de Secador"}
                  value={project?.dryerCapacity_Oz}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"time"}
                  rightAddon={"min"}
                  title={"Tiempo de Secado Minimo"}
                  value={project?.dryTimeMin_min}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"time"}
                  rightAddon={ozMinGrMin(project?.uom)}
                  title={"Consumo de Resina"}
                  value={project?.resinFlow_Oz_x_Min}
                />
              </Grid>
            </GridItem>
          )}
          <GridItem
            colSpan={4}
            width={"100%"}
            justifyItems={"center"}
            justifyContent={"center"}
          >
            <Button
              width={"100%"}
              colorScheme={"facebook"}
              disabled={
                !project.dryerId ||
                !isFloatString(project.quantityToProcess) ||
                !isFloatString(project.scrapRisk) ||
                !isFloatString(project.dryerCapacity)
              }
              isLoading={isLoading}
              onClick={
                showData
                  ? () => {
                      setShowData(false);
                    }
                  : onFinishDryerSupplyHandler
              }
            >
              {showData ? "Editar" : "Guardar Cambios"}
            </Button>
          </GridItem>
        </GridItem>
      </EditBase>
    </>
  );
};

export default EditDryerSupply;
