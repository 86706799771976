import {lbHrKgHr, lbKg, ozGr, ozMinGrMin} from "../../../hooks/useUomTransform";
import {badgeColor} from "../../../../project-overview/components/utilities/variables";

export const dataResults = data => [
    {
      label: "Piezas Por Hora",
      value: data?.pcsPerHour,
      addOn: "Pcs/Hr",
      type: "pressure",
      badgeColor: badgeColor
    },
    {
      label: "Disparos Requeridos",
      value: data?.reqShotsForProd,
      addOn: "shots",
      type: "pressure",
      badgeColor: badgeColor
    },
    {
      label: "Tiempo de Ciclo",
      value: data?.cycleTimeMin,
      addOn: "min",
      type: "time",
      badgeColor: badgeColor
    },
    {
      label: "Consumo de Resina",
      value: data?.resinFlowPerMin,
      addOn: ozMinGrMin(data?.header?.uom),
      type: "time",
      badgeColor: badgeColor
    },
    {
      label: "Consumo de Resina",
      value: data?.consumeLbPerHr,
      addOn: lbHrKgHr(data?.header?.uom),
      type: "time",
      badgeColor: badgeColor
    },
    {
      label: "Capacidad de Tolva",
      value: data?.hopperCapacityPcs,
      addOn: "Pcs",
      type: "pressure",
      badgeColor: badgeColor
    },
    {
      label: "Duración de la Producción",
      value: data?.productionDuration,
      addOn: "Hr",
      type: "time",
      badgeColor: badgeColor
    },
    {
      label: "Resina Requerida",
      value: data?.reqResinForProd,
      addOn: lbKg(data?.header?.uom),
      type: "weight",
      badgeColor: badgeColor
    },
    {
      label: "Tiempo de Recarga",
      value: data?.reloadTime,
      addOn: "min",
      type: "min",
      badgeColor: parseFloat(data?.reloadTime) >= 10 && parseFloat(data?.reloadTime) <= 60 ? "green" : "red"
    },
    {
      label: "Tamaño de Recarga",
      value: data?.reloadSize,
      addOn: lbKg(data?.header?.uom),
      type: "weight",
      badgeColor: badgeColor
    },
    {
      label: "Recargas despues de Inicio",
      value: data?.reloadReqAfterStart,
      addOn: "times",
      type: "int",
      badgeColor: badgeColor
    },
    {
      label: "Recorrido de Resina",
      value: data?.resinTroughHopper,
      addOn: "min",
      type: "time",
      badgeColor: badgeColor
    },
  ]
