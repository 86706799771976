import { Badge, Button, Flex, Text } from "@chakra-ui/react";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../contexts/UserContext";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const { user } = useContext(UserContext);

  let navbarIcon = "white";

  if (secondary) {
    navbarIcon = "white";
  }

  const history = useHistory();

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
    >
      <Badge
        variant={"outline"}
        px={2}
        borderRadius={15}
        colorScheme={user?.isSubscribed ? "whatsapp" : "orange"}
        color={user?.isSubscribed ? "green" : "orange"}
        mr={5}
      >
        {user?.isSubscribed ? "Subscripcion Activa" : "Subscripcion Gratuita"}
      </Badge>
      {!user?.isSubscribed && (
        <Button
          mr={5}
          size={"sm"}
          onClick={() => history.push(`/admin/profile/plans`)}
        >
          Ver Planes
        </Button>
      )}
      <Button
        onClick={() => props.signOut()}
        ms="0px"
        px="0px"
        me={{ sm: "2px", md: "16px" }}
        color={navbarIcon}
        variant="transparent-with-icon"
        leftIcon={
          <FontAwesomeIcon
            icon={faSignOut}
            color={navbarIcon}
            w="22px"
            h="22px"
            me="0px"
          />
        }
      >
        <Text display={{ sm: "none", md: "flex" }} color="white">
          Cerrar Sesión
        </Text>
      </Button>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
