const instructions = [
  "Introduce los valores solicitados en las casillas en blanco, obtendrás el área del pistón de inyección, el área interna del Barril, el valor de Presión Plástica (Ppsi o Pbar) ejecutada por el Husillo al interior del Barril, así como el valor de la Razón de Intensificación (Ri) correspondiente a la Máquina de Moldeo.",
  "Formula ",
  "Ppsi = Hpsi * (HArea / PArea)",
  "Hárea: Área del Pistón Hidráulico ",
  "Hpsi/Hbar: Presión del Pistón Hidráulico ",
  "Párea: Área del Tornillo de Inyección ",
  "Ppsi/Pbar: Presión del Tornillo de Inyección"
];

export default instructions;
