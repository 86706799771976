import { Link as RouterLink } from 'react-router-dom'; // Importa Link de react-router-dom
import { Link as ChakraLink } from '@chakra-ui/react'; // Importa Link de Chakra UI

const CustomLink = ({url, children, label}) => {
  return (
    <ChakraLink
      as={RouterLink} // Usa ChakraLink como un wrapper para RouterLink
      to={url}
      aria-label={label}
    >
      {children}
    </ChakraLink>
  )
}

export default CustomLink