import { Grid, Image, Text } from "@chakra-ui/react";
import image from "./../../../../assets/img/collaborators/gea/Logo-GEAB-Color.png";

const GeaHeader = () => {

  return (
    <Grid
      mt={5}
      mx={5}
      backgroundColor={"white"}
      borderRadius={15}
      justifyItems={"center"}
    >
      <Image src={image} width={"20%"} />
      {/* Este Grid mantiene su justifyContent como "center" para centrar el contenido */}
      <Grid p={5} backgroundColor={"#388720"} borderBottomRightRadius={15} borderBottomLeftRadius={15}>
        <Text color={"white"}>
          Ofrecemos una amplia gama de soluciones para la industria del
          plástico, de empaques, de envases y de embalaje. Para nosotros, lo más
          importante es el cuidado y bienestar de nuestro planeta, así como la
          responsabilidad social ambiental basada en una correcta comunicación.
          Por esta razón, estas tres palabras nos Identifican:
        </Text>
        {/* Este Grid interior es el que necesita justifyContent="space-between" */}
        <Grid templateColumns="repeat(3, 1fr)" gap={6} justifyContent="space-between" mt={5}>
          <Grid justifyContent="center">
            <Text color={"white"} fontSize={"3xl"} fontWeight={"bold"}>ECOLOGÍA</Text>
          </Grid>
          <Grid justifyContent="center">
            <Text color={"white"} fontSize={"3xl"} fontWeight={"bold"}>INNOVACIÓN</Text>
          </Grid>
          <Grid justifyContent="center">
            <Text color={"white"} fontSize={"3xl"} fontWeight={"bold"}>SUSTENTABILIDAD</Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeaHeader;
