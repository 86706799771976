import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { LossPressure } from "../../../../../../../models";
import { Image, PDFViewer, Text, View } from "@react-pdf/renderer";
import "moment/locale/es";
import PdfBase from "../../../../components/Pdf/PdfBase";
import PdfEntries from "../../../../components/Pdf/PdfEntries";

import { dataEntries1, dataEntries2 } from "./utilities/dataEntries";
import { dataOutputs1, dataOutputs2 } from "./utilities/dataOutputs";
import { useGetUrl } from "../../../../hooks/useGetUrl";
import usePdfStyles from "../../../../hooks/usePdfstyles";
const IndividualLossPressurePdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [href, setHref] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(LossPressure, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    useGetUrl(data, setHref);
  }, [data]);

  const styles = usePdfStyles();

  const MyDocument = ({ data, chartImageUri }) => (
    <PdfBase data={data} name={"Perdida de Presion"} image={chartImageUri}>
      <PdfEntries
        title={"Entradas"}
        column1={dataEntries1(data)}
        column2={dataEntries2(data)}
      />

      <PdfEntries
        title={"Salidas"}
        column1={dataOutputs1(data)}
        column2={dataOutputs2(data)}
      />
      <Text style={styles.header}>{"Graficas"}</Text>
      <View style={[styles.view, { justifyContent: "center" }]}>
        <Image src={href} style={styles.image} />
      </View>
    </PdfBase>
  );

  return (
    <>
      <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
        <MyDocument data={data} />
      </PDFViewer>
    </>
  );
};

export default IndividualLossPressurePdf;
