import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { useParams } from "react-router-dom";
import { post } from "aws-amplify/api";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import basic from "../../../assets/img/pricing-background.png";
import { Card } from "@aws-amplify/ui-react";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#fff", // Cambiar a blanco o a un color claro según tu diseño
      // ... otros estilos base
    },
    invalid: {
      color: "#fa755a", // Color para cuando la entrada es inválida
      iconColor: "#fa755a",
    },
    // ... puedes agregar más estilos para otros estados si es necesario
  },
};
const stripePromise = loadStripe(
  "pk_live_51OOVvvAQWnDtoV2dokxEGh0freePeagOvd6jdl0D677Qa2Wytsr3tb8hBbbOWpCDYizfoThXDnupzE8qouV55LUD00no6hFdTI"
);

export default function Payment() {
  const { id } = useParams();
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getClientSecret();
  }, []);

  const getClientSecret = async () => {
    try {
      const operation = await post({
        apiName: "paySubscription",
        path: "/pay-subscription",
        options: {
          body: { subscriptionId: id },
        },
      });

      const response = await operation.response;
      const c = await response.body.json();

      setClientSecret(c.clientSecret);
    } catch (error) {
      console.error("Error al obtener el client secret:", error);
    }
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
    CARD_ELEMENT_OPTIONS,
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mt={"150px"}
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", xl: "60vh" }}
        w={{ sm: "calc(100vw - 25px)", md: "calc(100vw - 25px)" }}
        borderRadius={{ sm: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={basic}
        bgSize="cover"
        mx={{ sm: "auto" }}
        mt={{ sm: "14px" }}
      />

      {clientSecret && (
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <GridItem />
          <Card style={{ borderRadius: 15 }}>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm id={id} />
            </Elements>
          </Card>
        </Grid>
      )}
    </Flex>
  );
}
