import { useDisclosure } from "@chakra-ui/react";
import SearchTable1 from "../../../components/Tables/SearchTable1";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { Tool } from "../../../models";
import { moldColumnsData } from "./utilities/variables";
import CustomGrid from "../../projects/individual/components/CustomGrid";
import ShowModal from "./components/ShowModal";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionIndex,
  metaKeywordsIndex,
  metaTitleIndex,
} from "./utilities/metaVariables";

const MyMolds = () => {
  const [molds, setMolds] = useState([]);

  const [item, setItem] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getMolds();
  }, []);

  const getMolds = async () => {
    try {
      const data = await DataStore.query(Tool);
      setMolds(data);
    } catch (err) {
      console.log(err);
    }
  };
  const textColor = "gray.600";

  const onShowHandler = (data) => {
    setItem(data);
    onOpen();
  };

  return (
    <>
      <HelmetComponent
        description={metaDescriptionIndex}
        keywords={metaKeywordsIndex}
        title={metaTitleIndex}
      />
      <ShowModal item={item} isOpen={isOpen} onClose={onClose} />
      <CustomGrid
        mt={"150px"}
        templateColumns="repeat(1, 1fr)"
        alignItems={"center"}
        justifyItems={"center"}
        gap={6}
        borderWidth={1}
        mb={5}
        p={5}
        borderRadius={15}
      >
        <SearchTable1
          title={"de mis Moldes"}
          textColor={textColor}
          tableData={molds}
          columnsData={moldColumnsData}
          onShowHandler={(item) => onShowHandler(item)}
        />
      </CustomGrid>
    </>
  );
};

export default MyMolds;
