import {Button, Grid, GridItem, Image, Link, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";

import einarImage from "./../../../../assets/img/collaborators/gea/einar-img.jpg";
import ecoOneImage from "./../../../../assets/img/collaborators/gea/eco-one.jpg";
import aquaSonicImage from "./../../../../assets/img/collaborators/gea/aqua-sonic.jpg";
import einarPdf from "../../../../assets/pdf/einar-201.pdf";
import ecoOnePdf from "../../../../assets/pdf/eco-one.pdf";
import aquaSonicPdf from "../../../../assets/pdf/aquasonic.pdf";
import ecoOneVideo from "../../../../assets/video/eco-one-video.mp4";
import Video from "../../../../components/collaborators/Video";
import HorizontalDivider from "../../../../components/dividers/HorizontalDivider";

const GeaProducts = () => {
  const metallicSilverGradient = useColorModeValue(
    "linear(to-r, #e6e6e6, #b3b3b3)",
    "linear(to-r, #b3b3b3, #999999)"
  );

  const descargarPDF = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name; // Puedes personalizar el nombre del archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Grid
        p={10}
        mx={5}
        mt={5}
        borderRadius={15}
        bgGradient={metallicSilverGradient}
        justifyItems={"center"}

        gap={6}
      >
        <Text fontSize={"4xl"} fontWeight={"bold"}>
          NUESTROS PRODUCTOS
        </Text>
        <Image src={einarImage} width={"50%"} borderRadius={15} />
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Einar ® 201 es una tecnología verde que nos ayuda a mejorar el
          procesamiento de los plásticos vírgenes y recuperados, permitiendo
          usar hasta el 100% de reciclados, así como conseguir el aumento de la
          productividad mediante la reducción del tiempo de ciclo y temperatura
          de proceso.
        </Text>
        <Grid gap={6} templateColumns="repeat(2, 1fr)">
          <Button
            colorScheme={"telegram"}
            onClick={() => descargarPDF(einarPdf, "einar-201.pdf")}
          >
            Descargar Folleto
          </Button>
          <Link
            href={`/banner/collaborators/gea-tool`}
            isExternal={true}
          >
            <Button
              colorScheme={"whatsapp"}
            >
              Prueba Einar en Linea
            </Button>
          </Link>

        </Grid>
        <Link
          href={`/banner/collaborators/gea-tool`}
          isExternal={true}
        >
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Evalua el uso de Einar ® 201 en linea.
          </Text>
        </Link>


        <HorizontalDivider mt={5} />
        <Image src={ecoOneImage} width={"50%"} borderRadius={15} />
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Eco One ® es un agente permite que el plástico sea consumido por
          microorganismos que se encuentran en el vertedero naturalmente, no
          requiere pretratamientos para disminuir sus propiedades y tampoco
          disminuyen con el tiempo, exposición al sol, oxígeno o calor
        </Text>
        <Video videoSrc={ecoOneVideo} />
        <Button
          colorScheme={"telegram"}
          onClick={() => descargarPDF(ecoOnePdf, "eco-one.pdf")}
        >
          Descargar Folleto
        </Button>
        <HorizontalDivider mt={5} />
        <Image src={aquaSonicImage} width={"50%"} borderRadius={15} />
        <Text fontSize={"xl"} fontWeight={"bold"}>
          AquaSonic ® es un tratamiento ecológico innovador y natural para el
          agua, evita y neutraliza los problemas causados por las sales
          presentes en el agua, sin necesidad de detener procesos ni desmontar
          las tuberías para eliminar la cal.
        </Text>
        <Button
          colorScheme={"telegram"}
          onClick={() => descargarPDF(aquaSonicPdf, "aqua-sonic.pdf")}
        >
          Descargar Folleto
        </Button>
      </Grid>
    </>
  );
};

export default GeaProducts;
