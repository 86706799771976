import { Grid, Image, Text } from "@chakra-ui/react";
import ldImage1 from "./../../../../assets/img/collaborators/ld/ld-mold-3.jpg";
import ldImage4 from "./../../../../assets/img/collaborators/ld/ld-mold-4.jpg";
import ldImage5 from "./../../../../assets/img/collaborators/ld/ld-mold-5.jpg";
import ldImage6 from "./../../../../assets/img/collaborators/ld/ld-mold-6.jpg";
import ldImage7 from "./../../../../assets/img/collaborators/ld/ld-mold-7.jpg";
import ldImage8 from "./../../../../assets/img/collaborators/ld/ld-mold-8.jpg";
import ldImage9 from "./../../../../assets/img/collaborators/ld/ld-mold-9.jpg";
import ldImage10 from "./../../../../assets/img/collaborators/ld/ld-mold-10.jpg";
import ldImage11 from "./../../../../assets/img/collaborators/ld/ld-mold-11.jpg";
import HorizontalDivider from "../../../../components/dividers/HorizontalDivider";
const LdBody2 = ({ textColor }) => {
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      borderRadius={15}
      backgroundColor={"white"}
      justifyItems={"center"}
      justifyContent={"center"}
      gap={6}
    >
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <Image src={ldImage1} borderRadius={15} />
        <Image src={ldImage4} borderRadius={15} />
        <Grid gap={1} justifyItems={"center"}>
          <Image src={ldImage5} borderRadius={15} width={"60%"} />
          <Image src={ldImage6} borderRadius={15} width={"60%"} />
        </Grid>
        <Grid gap={1} justifyItems={"center"}>
          <Image src={ldImage7} borderRadius={15} width={"60%"} />
          <Image src={ldImage8} borderRadius={15} width={"60%"} />
        </Grid>
        <Image src={ldImage9} borderRadius={15} />
        <Image src={ldImage10} borderRadius={15} />
      </Grid>
      <Text fontSize={"2xl"} fontWeight={"bold"} color={textColor}>
        Administración de Proyectos
      </Text>
      <Text fontSize={"xl"} color={textColor}>
        Nuestro sistema de Administración de Proyectos ha sido establecido para
        facilitar el proceso de fabricación de moldes y esta enfocado
        principalmente en la optimización de los recursos y expectativas de
        calidad y funcionamiento requeridas por el cliente.
      </Text>
      <Text fontSize={"xl"} color={textColor}>
        Para nosotros es muy importante mantener una comunicación constante y
        supervisión exhaustiva durante cada etapa de la fabricación, muestreo y
        puesta a punto del producto final hasta lograr la satisfacción del
        cliente. Comprometidos con la entrega a tiempo y calidad del producto
        desde el inicio de cada proyecto, respaldados por personal calificado y
        experimentado en cada área.
      </Text>
      <HorizontalDivider mt={5} />
      <Image src={ldImage11} />
    </Grid>
  );
};

export default LdBody2;
