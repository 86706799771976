//import bg from "assets/img/backgrounds/fondo1.jpg";
//import bg2 from "assets/img/background-body-admin.png";

export const bgAdmin = {
  styles: {
    global: (props) => ({
      body: {
        bgColor: "#203764",
        //bgImage: bg2,
        bgSize: "expand",
        bgPosition: "center",
      },
    }),
  },
};

