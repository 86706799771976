import { initialStateHeader } from "../../../utilities/variabled";

export const initialState = {
  chartImage: "",
  header: initialStateHeader,
  specificWeight: "",
  pistonDiameter: "",
  screwDiameter: "",
  maxShotSize: "",
  minCushion: "",
  maxCushion: "",
  realMeltTemp: "",
  LDratio: "",
  minBarrelUse: "",
  maxBarrelUse: "",
  decompressionSize: "",
  shotWeight100: "",
  cutoffPosX100: "",
  cushionPosX100: "",
  runnerWeight: "",
  cavityQuantity: "",

  pistonArea: "",
  screwArea: "",
  intRatio: "",
  shotWeightFirstStage: "",
  overallShotSize: "",
  barrelMaxWeight: "",
  shotVolume: "",
  barrelTheoricVolume: "",
  shotsInBarrel: "",
  shotPositionTheoric: "",
  shotPercentage: "",
  cutoffPositionTheoric: "",
  barrelUsage: "",
  screwTotalPosition: "",
  cushionPosTheoric: "",
  runnerVolume: "",
  cavitiesFullWeight: "",
  singleCavityFullWeight: "",
  cavities1stStageWeight: "",
  cavity1stStageWeight: "",
  runnerFinishPosition: "",
};

export const keysForForm = [
  "specificWeight",
  "pistonDiameter",
  "screwDiameter",
  "maxShotSize",
  "minCushion",
  "maxCushion",
  "realMeltTemp",
  "LDratio",
  "minBarrelUse",
  "maxBarrelUse",
  "decompressionSize",
  "shotWeight100",
  "cutoffPosX100",
  "cushionPosX100",
  "runnerWeight",
  "cavityQuantity",
];

export const KeysForCheck = [
  "pistonArea",
  "screwArea",
  "intRatio",
  "shotWeightFirstStage",
  "overallShotSize",
  "barrelMaxWeight",
  "shotVolume",
  "barrelTheoricVolume",
  "shotsInBarrel",
  "shotPositionTheoric",
  "shotPercentage",
  "cutoffPositionTheoric",
  "barrelUsage",
  "screwTotalPosition",
  "cushionPosTheoric",
];
