import { Text, View } from "@react-pdf/renderer";
import React from "react";

const AnalisisResultsPdf = ({ data, styles }) => {
  return (
    <>
      <Text style={styles.header}>{"Analisis de Resultados"}</Text>
      <View
        style={{
          border: 1,
          width: "90%",
          padding: "9px",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "15px",
          borderRadius: 10,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View>
          <Text style={[styles.text, { fontWeight: "bold" }]}> </Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            Inputs de Proceso
          </Text>
          <Text style={styles.text}>Temperratura de Barril Zona Trasera</Text>
          <Text style={styles.text}>Temperratura de Barril Zona Central</Text>
          <Text style={styles.text}>Temperratura de Barril Zona Frontal</Text>
          <Text style={styles.text}>Temperratura de Barril Zona Nariz</Text>
          <Text style={styles.text}>Posición de Carga de Disparo</Text>
          <Text style={styles.text}>Posición Transferencia V/P</Text>
          <Text style={styles.text}>Velocidad de Rotación</Text>
          <Text style={styles.text}>Contrapresión</Text>
          <Text style={styles.text}>Velocidad de Inyección</Text>
          <Text style={styles.text}>Presión de Inyección</Text>
          <Text style={[styles.text, { marginBottom: 20 }]}></Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            Outputs de Proceso
          </Text>
          <Text style={styles.text}>Temperratura de Masa Fundida</Text>
          <Text style={styles.text}>Posición de Colchón</Text>
          <Text style={styles.text}>Máxima Presión de Inyección</Text>
          <Text style={styles.text}>Tiempo de Carga</Text>
          <Text style={styles.text}>Tiempo de Inyección</Text>
          <Text style={styles.text}>Tiempo de Ciclo</Text>
          <Text style={styles.text}>Max Amperaje de Rotación</Text>
          <Text style={styles.text}>Máx Amperaje de Inyección</Text>
          <Text style={styles.text}>Peso Real de Pieza</Text>
          <Text style={styles.text}>Propiedad Desmoldante</Text>
          <Text style={styles.text}>Propiedad Anti Polvo</Text>
          <Text style={styles.text}>Propiedad Anti estatica</Text>
          <Text style={styles.text}>Propiedad Denesting</Text>
        </View>
        <View>
          <Text style={[styles.text, { fontWeight: "bold" }]}> </Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            Sin Einar 201
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRearBefore).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCenterBefore).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaFrontBefore).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaNozzleBefore).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaShotSizeBefore).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaTransferBefore).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRpmBefore).toFixed(2)}{" "}
            {data?.addUomRotationSpeed}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaBackpressBefore).toFixed(2)}{" "}
            {data?.addUomPress}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjSpeedBefore).toFixed(2)} {data?.addUomSpeed}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjPressBefore).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={[styles.text, { marginBottom: 20 }]}></Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            {" "}
          </Text>

          <Text style={styles.text}>
            {parseFloat(data?.geaTempMeltBefore).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCushionBefore).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaMaxPressBefore).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRecoveryTimeBefore).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaFillTimeBefore).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCycleTimeBefore).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRpmAmpBefore).toFixed(2)} amp
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjectionAmpBefore).toFixed(2)} amp
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaShotWeightBefore).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>{data?.geaMoldReleaseBefore}</Text>
          <Text style={styles.text}>{data?.geaAntiDustBefore}</Text>
          <Text style={styles.text}>{data?.geaAntiStaticBefore}</Text>
          <Text style={styles.text}>{data?.geaDenestingBefore}</Text>
        </View>

        <View>
          <Text style={[styles.text, { fontWeight: "bold" }]}> </Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            Con Einar 201
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRearAfter).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCenterAfter).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaFrontAfter).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaNozzleAfter).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaShotSizeAfter).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaTransferAfter).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRpmAfter).toFixed(2)}{" "}
            {data?.addUomRotationSpeed}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaBackpressAfter).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjSpeedAfter).toFixed(2)} {data?.addUomSpeed}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjPressAfter).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={[styles.text, { marginBottom: 20 }]}></Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            {" "}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaTempMeltAfter).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCushionAfter).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaMaxPressAfter).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRecoveryTimeAfter).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaFillTimeAfter).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCycleTimeAfter).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRpmAmpAfter).toFixed(2)} amp
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjectionAmpAfter).toFixed(2)} amp
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaShotWeightAfter).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>{data?.geaMoldReleaseAfter}</Text>
          <Text style={styles.text}>{data?.geaAntiDustAfter}</Text>
          <Text style={styles.text}>{data?.geaAntiStaticAfter}</Text>
          <Text style={styles.text}>{data?.geaDenestingAfter}</Text>
        </View>

        <View>
          <Text style={[styles.text, { fontWeight: "bold" }]}> </Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            Delta
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRearDelta).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCenterDelta).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaFrontDelta).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaNozzleDelta).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaShotSizeDelta).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaTransferDelta).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRpmDelta).toFixed(2)}{" "}
            {data?.addUomRotationSpeed}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaBackpressDelta).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjSpeedDelta).toFixed(2)} {data?.addUomSpeed}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjPressDelta).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={[styles.text, { marginBottom: 20 }]}></Text>
          <Text
            style={{
              marginBottom: 2,
              marginHorizontal: 20,
              fontSize: 10,
              textAlign: "justify",
              fontWeight: "bold",
            }}
          >
            {" "}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaTempMeltDelta).toFixed(2)} {data?.addUomTemp}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCushionDelta).toFixed(2)} {data?.addUomLong}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaMaxPressDelta).toFixed(2)} {data?.addUomPress}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRecoveryTimeDelta).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaFillTimeDelta).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaCycleTimeDelta).toFixed(2)} seg
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRpmAmpDelta).toFixed(2)} amp
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaInjectionAmpDelta).toFixed(2)} amp
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaShotWeightDelta).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>{data?.geaMoldReleaseDelta}</Text>
          <Text style={styles.text}>{data?.geaAntiDustDelta}</Text>
          <Text style={styles.text}>{data?.geaAntiStaticDelta}</Text>
          <Text style={styles.text}>{data?.geaDenestingDelta}</Text>
        </View>
      </View>
    </>
  );
};

export default AnalisisResultsPdf;
