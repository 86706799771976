import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import {textColor} from "../../views/users/molds/utilities/variables";

const CustomInputProject = ({
  label,
  value,
  onChange,
  id,
  fontSize = "sm",
  mx,
  disabled,
  onBlur,
  error,
  fontWeight = "bold",
  fontStyle = "",
  color = textColor,
  backgroundColor = "white",
  borderColor = "gray.400",
  borderRadius = 5,
}) => {
  return (
    <FormControl isInvalid={error} mx={mx}>
      <FormLabel
        htmlFor={id}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
        color={color}
        fontSize={fontSize}
      >
        {label}
      </FormLabel>
      <Input
        borderColor={borderColor}
        color={color}
        size={"sm"}
        borderRadius={borderRadius}
        disabled={disabled}
        placeholder={label}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        backgroundColor={backgroundColor}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default CustomInputProject;
