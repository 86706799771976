import { Text, View } from "@react-pdf/renderer";
import React from "react";

const HeaderPdf = ({ styles, data, date }) => {
  return (
    <>
      <Text style={[styles.header, { marginTop: 10 }]}>{"Header"}</Text>
      <View
        style={{
          border: 1,
          width: "90%",
          padding: "9px",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "10px",
          borderRadius: 10,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View>
          <Text style={styles.text}>Usuario: {data?.geaUserID}</Text>
          <Text style={styles.text}>Producto: {data?.geaProduct}</Text>
          <Text style={styles.text}>
            Tamaño de muestra: {data?.geaSampleSize} Pzs
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            Empresa: <Text style={styles.text}>{data?.geaCompany}</Text>
          </Text>
          <Text style={styles.text}>
            Peso de Pieza:{" "}
            <Text style={styles.text}>
              {data?.geaPartWeight} {data?.geaUdM}
            </Text>
          </Text>
          <Text style={styles.text}>
            Porcentaje Einar 201:{" "}
            <Text style={styles.text}>{data?.geaEinarPercent} %</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            Unidad de Medida: <Text style={styles.text}>{data?.geaUdM}</Text>
          </Text>
          <Text style={styles.text}>
            Porcentaje de Remolido:{" "}
            <Text style={styles.text}>{data?.geaRegrindPercent} %</Text>
          </Text>
          <Text style={styles.text}>
            Cavidades: <Text style={styles.text}>{data?.geaCavities} Pzs</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            Fecha: <Text style={styles.text}>{date}</Text>
          </Text>
          <Text style={styles.text}>
            Tipo de Plástico:{" "}
            <Text style={styles.text}>{data?.geaMaterial}</Text>
          </Text>
          <Text style={styles.text}>
            Otros Aditivos:{" "}
            <Text style={styles.text}>{data?.geaOtherAdditives} %</Text>
          </Text>
        </View>
      </View>
    </>
  );
};

export default HeaderPdf;
