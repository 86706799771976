import { DataStore } from "aws-amplify/datastore";
import { useState, useContext } from "react";
import ProjectContext from "../../contexts/ProjectContext";
import { Project } from "../../models";
import ConditionalsAlert from "../../views/projects/hooks/ConditionalsAlert";

export const useMelt3030Hooks = () => {
  const [showChart, setShowChart] = useState(false);

  const { project, resin, updateLoading } = useContext(ProjectContext);

  const {
    realMeltTemp,
    realNozzleTemp,
    realFrontTemp,
    realMidTemp,
    realBackTemp,
  } = project;

  const {
    meltTempMin,
    nozzleTempMin,
    FrontTempMin,
    midTempMin,
    backTempMin,
    meltTempMax,
    nozzleTempMax,
    FrontTempMax,
    midTempMax,
    backTempMax,
  } = resin;

  const [lineChartDataMelt, setLineChartDataMelt] = useState([
    {
      name: "Maximo",
      data: [0, 0, 0, 0, 0],
      color: "#FF0000",
    },
    {
      name: "Minimo",
      data: [0, 0, 0, 0, 0],
      color: "#FF0000",
    },
    {
      name: "Real",
      data: [0, 0, 0, 0, 0],
      color: "#FF0000",
    },
  ]);
  const updateChart = async () => {
    updateLoading(true);
    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.realMeltTemp = realMeltTemp.toString();
        updated.realNozzleTemp = realNozzleTemp.toString();
        updated.realFrontTemp = realFrontTemp.toString();
        updated.realMidTemp = realMidTemp.toString();
        updated.realBackTemp = realBackTemp.toString();
        updated.phase = "5";
      })
    )
      .then(() => {
        setLineChartDataMelt([
          {
            name: "Maximo",
            data: [
              parseFloat(meltTempMax).toFixed(2),
              parseFloat(nozzleTempMax).toFixed(2),
              parseFloat(FrontTempMax).toFixed(2),
              parseFloat(midTempMax).toFixed(2),
              parseFloat(backTempMax).toFixed(2),
            ],
          },
          {
            name: "Minimo",
            data: [
              parseFloat(meltTempMin).toFixed(2),
              parseFloat(nozzleTempMin).toFixed(2),
              parseFloat(FrontTempMin).toFixed(2),
              parseFloat(midTempMin).toFixed(2),
              parseFloat(backTempMin).toFixed(2),
            ],
          },
          {
            name: "Real",
            data: [
              parseFloat(realMeltTemp).toFixed(2),
              parseFloat(realNozzleTemp).toFixed(2),
              parseFloat(realFrontTemp).toFixed(2),
              parseFloat(realMidTemp).toFixed(2),
              parseFloat(realBackTemp).toFixed(2),
            ],
          },
        ]);
        let icon = "success";
        let text = "";
        if (parseFloat(realMeltTemp) > parseFloat(meltTempMax)) {
          icon = "warning";
          text +=
            "<li>La Temperatura de Masa Real es mayor al máximo recomendado por el fabricante de la Resina.</li>";
        } else if (parseFloat(realMeltTemp) < parseFloat(meltTempMin)) {
          icon = "warning";
          text +=
            "<li>La Temperatura de Masa Real es menor al mínimo recomendado por el fabricante de la Resina.</li>";
        }
        if (parseFloat(realNozzleTemp) > parseFloat(nozzleTempMax)) {
          icon = "warning";
          text +=
            "<li>La Temperatura de Nariz Real es mayor al máximo recomendado por el fabricante de la Resina.</li>";
        } else if (parseFloat(realNozzleTemp) < parseFloat(nozzleTempMin)) {
          icon = "warning";
          text +=
            "<li>La Temperatura de Nariz Real es menor al mínimo recomendado por el fabricante de la Resina.</li>";
        }
        if (parseFloat(realFrontTemp) > parseFloat(FrontTempMax)) {
          icon = "warning";
          text +=
            "<li>La Temperatura Frontal Real es mayor al máximo recomendado por el fabricante de la Resina.</li>";
        } else if (parseFloat(realFrontTemp) < parseFloat(FrontTempMin)) {
          icon = "warning";
          text +=
            "<li>La Temperatura Frontal Real es menor al mínimo recomendado por el fabricante de la Resina.</li>";
        }
        if (parseFloat(realMidTemp) > parseFloat(midTempMax)) {
          icon = "warning";
          text +=
            "<li>La Temperatura Central Real es mayor al máximo recomendado por el fabricante de la Resina.</li>";
        } else if (parseFloat(realMidTemp) < parseFloat(midTempMin)) {
          icon = "warning";
          text +=
            "<li>La Temperatura Central Real es menor al mínimo recomendado por el fabricante de la Resina.</li>";
        }
        if (parseFloat(realBackTemp) > parseFloat(backTempMax)) {
          icon = "warning";
          text +=
            "<li>La Temperatura Trasera Real es mayor al máximo recomendado por el fabricante de la Resina.</li>";
        } else if (parseFloat(realBackTemp) < parseFloat(backTempMin)) {
          icon = "warning";
          text +=
            "<li>La Temperatura Trasera Real es menor al mínimo recomendado por el fabricante de la Resina.</li>";
        }

        ConditionalsAlert(icon, text);

        setShowChart(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  return {
    updateChart,
    showChart,
    setShowChart,
    lineChartDataMelt,
  };
};
