export const headerImage =
  "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=818,h=388,fit=crop/A3QENvNaewUXORzD/rediasa-png-AE02ZgpOnbUL7kL7.png";

export const videoSrc =
  "https://drive.google.com/uc?id=1GFxyVGvzgqvR2c1O0SI55P9jPW9hfBm9";

export const lat = 29.096258538866554;

export const lan = -110.9892080828264;

//29.096258538866554, -110.9892080828264
export const contactEmail = "elfenokierefiesta@gmail.com"