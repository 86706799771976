import {Button, FormLabel, Grid, GridItem, Heading, Text, Textarea} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../../../../projects/individual/components/CustomInput";
import axios from "axios";
import HorizontalDivider from "../../../../../../components/dividers/HorizontalDivider";
import {textColor} from "../../../../../users/molds/utilities/variables";
import Select from "react-select";
import {Label} from "@aws-amplify/ui-react";
const CostAnalisis = ({
  setGeaDataInput,
  handleInputChange,
  geaDataInput,
  errors,
  onSave,
                        onChangeSelect
}) => {
  const [pasa, setPasa] = useState(false);
  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(
          `https://v6.exchangerate-api.com/v6/9c7523cecbda495e71894a6b/latest/USD`
        );
        setGeaDataInput((prevState) => ({
          ...prevState,
          exchange: response.data.conversion_rates.MXN,
        }));
        // setExchangeRate(response.data.conversion_rates.MXN);
      } catch (error) {
        console.error("Error al obtener el tipo de cambio:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  const handleSubmit = async () => {
    if (!pasa) {
      setPasa(true);
    } else if (geaDataInput.phase === 4) {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 5 }));
      onSave()
    } else {
      setGeaDataInput((prevState) => ({ ...prevState, phase: 4 }));
      setPasa(false);
    }
  };

  const options = [
    {label: "MXN", value: "MXN"},
    {label: "USD", value: "USD"},
  ]

  return (
    <>
      {geaDataInput.phase === 4 && (
        <>
          <GridItem colSpan={4} mb={5}>
            <Heading as={"h1"}>ANÁLISIS DE COSTOS</Heading>
          </GridItem>
          <GridItem colSpan={4} mb={5} width={"80%"}>
            <Grid
              justifyItems={"center"}
              templateColumns="repeat(3, 1fr)"
              alignItems={"center"}
              gap={3}
              width={"100%"}
            >
              <GridItem width={"100%"}>
                <FormLabel color={textColor} fontSize={"sm"}>
                  Moneda
                </FormLabel>
                <Select
                  onChange={(e) => onChangeSelect("currency", e.value)}
                  id={"currency"}
                  isSearchable
                  defaultValue={options[0]}
                  options={options}
                />
              </GridItem>
              <CustomInput
                value={`$${geaDataInput.exchange}`}
                disabled={true}
                error={errors.currency}
                label={"Tipo de Cambio"}
                id={"currency"}
              />
              <CustomInput
                value={geaDataInput.geaPartWeight}
                onChange={handleInputChange}
                error={errors.geaPartWeight}
                label={"Peso de Pieza"}
                addOnText={geaDataInput.geaUdM}
                id={"geaPartWeight"}
                disabled={true}
              />
            </Grid>
          </GridItem>
          <GridItem colSpan={4} mb={5}>
            <Heading as={"h1"} fontSize={"4xl"} fontWeight={"normal"}>
              Requerimientos
            </Heading>
          </GridItem>
          <GridItem colSpan={4} width={"80%"}>
            <HorizontalDivider mt={5} />
            <Grid
              justifyItems={"center"}
              templateColumns="repeat(5, 1fr)"
              alignItems={"center"}
              gap={3}
              width={"100%"}
            >
              <GridItem />
              <GridItem />
              <Text fontWeight={"bold"}>Sin Einar</Text>
              <Text fontWeight={"bold"}>Con Einar</Text>
              <Text fontWeight={"bold"}>Costo Unitario</Text>
              <Text>Resina Virgen</Text>
              <CustomInput
                value={geaDataInput.geaVirginResin}
                disabled={true}
                addOnText={"%"}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.geaVirginPartWeight}
                disabled={true}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.geaVirginPartWeight}
                disabled={true}
              />
              <CustomInput
                addOnText={`${geaDataInput.currency}/${geaDataInput.geaUdM}`}
                value={geaDataInput.geaVirginResinCost}
                onChange={handleInputChange}
                id={"geaVirginResinCost"}
                error={errors.geaVirginResinCost}
                addOnLeft={"$"}
              />

              {/*  segundo  */}
              <Text>Material Recuperado</Text>
              <CustomInput
                value={geaDataInput.geaRegrindResin}
                disabled={true}
                addOnText={"%"}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.geaRegrindPartWeight_2}
                disabled={true}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.geaRegrindPartWeight}
                disabled={true}
              />
              <CustomInput
                value={geaDataInput.geaRegrindResinCost}
                onChange={handleInputChange}
                id={"geaRegrindResinCost"}
                error={errors.geaRegrindResinCost}
                addOnLeft={"$"}
                addOnText={`${geaDataInput.currency}/${geaDataInput.geaUdM}`}
              />

              {/*  third  */}
              <Text>Otros Aditivos</Text>
              <CustomInput
                value={geaDataInput.geaOtherAdditives}
                disabled={true}
                addOnText={"%"}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.geaAdditivePartWeight}
                disabled={true}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.geaAdditivePartWeight}
                disabled={true}
              />
              <CustomInput
                value={geaDataInput.geaOtherAdditivesCost}
                onChange={handleInputChange}
                id={"geaOtherAdditivesCost"}
                error={errors.geaOtherAdditivesCost}
                addOnLeft={"$"}
                addOnText={`${geaDataInput.currency}/${geaDataInput.geaUdM}`}
              />

              {/*  fourth  */}
              <Text>Aditivo Einar 201</Text>
              <CustomInput
                value={geaDataInput.geaEinarPercent}
                disabled={true}
                addOnText={"%"}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={0}
                disabled={true}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.geaEinarPartWeight}
                disabled={true}
              />
              <CustomInput
                value={parseFloat(geaDataInput.geaEinarPercentCost).toFixed(2)}
                disabled={true}
                id={"geaEinarPercentCost"}
                error={errors.geaEinarPercentCost}
                addOnLeft={"$"}
                addOnText={`${geaDataInput.currency}/${geaDataInput.geaUdM}`}
              />

              {/*  fifth  */}
              <Text>Total</Text>
              <CustomInput
                value={geaDataInput.totalCost}
                disabled={true}
                addOnText={"%"}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.totalCostWithOutEinar}
                disabled={true}
              />
              <CustomInput
                addOnText={geaDataInput.geaUdM}
                value={geaDataInput.totalCostWithEinar}
                disabled={true}
              />
              <GridItem />
            </Grid>
          </GridItem>
        </>
      )}

      {pasa && (
        <>
          <GridItem colSpan={4} width={"80%"}>
            <HorizontalDivider mt={5} />
            <Grid
              justifyItems={"center"}
              templateColumns="repeat(4, 1fr)"
              alignItems={"center"}
              gap={4}
              width={"100%"}
            >
              <CustomInput
                value={geaDataInput.geaSampleSize}
                disabled={true}
                label={"Volumen de Producción"}
                addOnText={"Pcs"}
              />
              <CustomInput
                onChange={handleInputChange}
                label={"Costo de Desmoldante"}
                value={geaDataInput.MoldReleaseCost}
                id={"MoldReleaseCost"}
                error={errors.MoldReleaseCost}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />
              <CustomInput
                onChange={handleInputChange}
                label={"Costo de Hora de Producción"}
                value={geaDataInput.ProdHourCost}
                id={"ProdHourCost"}
                error={errors.ProdHourCost}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />
              <CustomInput
                addOnText={geaDataInput.currency}
                onChange={handleInputChange}
                label={"Costo de Kilowatt Hora"}
                value={geaDataInput.kwCost}
                id={"kwCost"}
                error={errors.kwCost}
                addOnLeft={"$"}
              />
              <GridItem />
              <Text fontWeight={"bold"}>Sin Einar 201</Text>
              <Text fontWeight={"bold"}>Con Einar 201</Text>
              <Text fontWeight={"bold"}>COSTO DELTA TOTAL</Text>
              {/*  uno */}
              <Text fontWeight={"bold"}>Costo de Peso de Pieza</Text>
              <CustomInput
                onChange={handleInputChange}
                value={parseFloat(geaDataInput.geaPartCost).toFixed(4)}
                disabled={true}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />
              <CustomInput
                onChange={handleInputChange}
                value={parseFloat(geaDataInput.geaPartCostEinar).toFixed(4)}
                disabled={true}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />
              <CustomInput
                onChange={handleInputChange}
                value={parseFloat(geaDataInput.geaPartCostDelta).toFixed(4)}
                disabled={true}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />

              {/*  dos */}
              <Text fontWeight={"bold"}>Tiempo de Producción</Text>
              <CustomInput
                value={parseFloat(geaDataInput.geaProdTimeBefore).toFixed(2)}
                disabled={true}
                addOnText={"Hrs"}
              />
              <CustomInput
                value={parseFloat(geaDataInput.geaProdTimeAfter).toFixed(2)}
                disabled={true}
                addOnText={"Hrs"}
              />
              <CustomInput
                value={parseFloat(geaDataInput.geaProdTimeDelta).toFixed(2)}
                disabled={true}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />

              {/*  tres */}
              <Text fontWeight={"bold"}>Uso de Desmoldante</Text>
              <CustomInput
                value={geaDataInput.geaMoldReleaseQtyBefore}
                error={errors.geaMoldReleaseQtyBefore}
                onChange={handleInputChange}
                id={"geaMoldReleaseQtyBefore"}
                addOnText={"Latas"}
              />
              <CustomInput
                value={geaDataInput.geaMoldReleaseQtyAfter}
                error={errors.geaMoldReleaseQtyAfter}
                onChange={handleInputChange}
                id={"geaMoldReleaseQtyAfter"}
                addOnText={"Latas"}
              />
              <CustomInput
                value={parseFloat(geaDataInput.geaMoldReleaseQtyDelta).toFixed(2)}
                disabled={true}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />

              {/*  cuatro */}
              <Text fontWeight={"bold"}>Tiempo Muerto Estimado</Text>
              <CustomInput
                value={geaDataInput.geaDownTimeBefore}
                error={errors.geaDownTimeBefore}
                onChange={handleInputChange}
                id={"geaDownTimeBefore"}
                addOnText={"min"}
              />
              <CustomInput
                value={geaDataInput.geaDownTimeAfter}
                error={errors.geaDownTimeAfter}
                onChange={handleInputChange}
                id={"geaDownTimeAfter"}
                addOnText={"min"}
              />
              <CustomInput
                value={parseFloat(geaDataInput.geaDownTimeDelta).toFixed(2)}
                disabled={true}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />

              {/*  cinco */}
              <Text fontWeight={"bold"}>Scrap Estimado</Text>
              <CustomInput
                value={geaDataInput.geaScrapBefore}
                error={errors.geaScrapBefore}
                onChange={handleInputChange}
                id={"geaScrapBefore"}
                addOnText={"pzs"}
              />
              <CustomInput
                value={geaDataInput.geaScrapAfter}
                error={errors.geaScrapAfter}
                onChange={handleInputChange}
                id={"geaScrapAfter"}
                addOnText={"pzs"}
              />
              <CustomInput
                value={parseFloat(geaDataInput.geaScrapDelta).toFixed(2)}
                disabled={true}
                addOnText={geaDataInput.currency}
                addOnLeft={"$"}
              />

              {/*  seis */}
              <Text fontWeight={"bold"}>Consumo de Energía</Text>
              <CustomInput
                value={geaDataInput.geaKwCostBefore}
                error={errors.geaKwCostBefore}
                onChange={handleInputChange}
                id={"geaKwCostBefore"}
                addOnText={"Kwh"}
              />
              <CustomInput
                value={geaDataInput.geaKwCostAfter}
                error={errors.geaKwCostAfter}
                onChange={handleInputChange}
                id={"geaKwCostAfter"}
                addOnText={"Kwh"}
              />
              <CustomInput
                value={parseFloat(geaDataInput.geaKwCostDelta).toFixed(2)}
                disabled={true}
                addOnLeft={"$"}
                addOnText={geaDataInput.currency}
              />
              <GridItem />
              <GridItem />
              <GridItem />
              <CustomInput
                label={"Total"}
                value={parseFloat(geaDataInput.geaTotalCostDelta).toFixed(2)}
                disabled={true}
                addOnLeft={"$"}
                addOnText={geaDataInput.currency}
              />

            </Grid>
          </GridItem>
          <GridItem colSpan={4} mb={5} width={"80%"} justifyItems={"center"}>
            <Grid>
              <Label my={1} fontWeight={"bold"}>
                <Text
                  color={`${
                    geaDataInput.finalComments?.length > 500 && "red"
                  }`}
                >
                  {`Comentarios - ${geaDataInput.finalComments?.length} / 500 caracteres`}
                </Text>
              </Label>
              <Textarea
                value={geaDataInput.finalComments}
                mt={5}
                id={"finalComments"}
                onChange={handleInputChange}
                type={"textarea"}
                bgColor={"white"}
                placeholder={"Escribe aqui tus comentarios Finales..."}
              />
            </Grid>
          </GridItem>
        </>
      )}
      <GridItem colSpan={4} mb={5} width={"80%"} justifyItems={"center"}>
        <Grid>
          <Button
            onClick={() => handleSubmit()}
            children={
              geaDataInput.phase === 4 && !pasa
                ? "Continuar"
                : geaDataInput.phase === 4
                ? "Finalizar y Guardar"
                : "Editar Analisis de Costos"
            }
            colorScheme={"whatsapp"}
            width={"full"}
            mt={5}
          />
        </Grid>
      </GridItem>
    </>
  );
};

export default CostAnalisis;
