import {
  Grid,
  GridItem,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import shelterHeader from "../../../../assets/img/collaborators/shelter/shelter-logo.png";
import shelterHeader2 from "../../../../assets/img/collaborators/shelter/shelter-header-image.jpg";

const ShelterHeader = ({ textColor }) => {
  const whiteGradient = useColorModeValue(
    "linear(to-r, #ffffff, #f0f0f0, #ffffff)", // Gradiente para el modo claro
    "linear(to-r, #e0e0e0, #c2c2c2, #e0e0e0)" // Gradiente para el modo oscuro (opcional)
  );
  return (
    <Grid
      mt={5}
      mx={5}
      p={5}
      borderRadius={15}
      bgGradient={whiteGradient}
      justifyItems={"center"}
      gap={6}
    >
      <Image src={shelterHeader} width={"35%"} borderRadius={15} />
      <Grid
        mt={5}
        templateColumns="repeat(5, 1fr)"
        gap={1}
        alignItems={"center"}
      >
        <GridItem colSpan={3}>
          <Text fontSize={"xl"} color={textColor} fontWeight={"bold"}>
            Somos una agencia de Marketing que entrega soluciones de
            creatividad, imagen y producción a sus socios de negocio a través de
            un equipo multidisciplinario, talentoso y especializado en su área.
          </Text>
          <Text fontSize={"xl"} color={textColor} fontWeight={"bold"}>
            Nuestra meta principal es ayudar a las compañías a establecerse en
            el mercado con nuestra experiencia en estrategias de marketing,
            permitiéndoles crecer y alcanzar sus objetivos. A través de un
            enfoque personalizado, nos aseguramos de comprender las necesidades
            y deseos del cliente, desarrollando estrategias efectivas que
            generen resultados positivos. Utilizamos las últimas tendencias y
            herramientas del mercado para crear campañas publicitarias
            impactantes y exitosas.
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Image src={shelterHeader2} />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default ShelterHeader;
