export const colors = {
  bg: "#155FFC",
  white: "#fff",
  bgGradientHorizontal:
    "linear-gradient(90deg, rgba(26,91,230,0.9766500350140056) 0%, rgba(0,55,172,0.7245491946778712) 0%, rgba(17,79,210,1) 46%, rgba(0,50,156,0.7273503151260504) 100%)",
  bgGradientVertical180:
    "linear-gradient(145deg, rgba(26,91,230,0.1) 0%, rgba(0,55,172,0.1) 0%, rgba(17,79,210,0.1) 0%, rgba(0,50,156,0.1) 0%)",
  grayDefault: "gray.300",
  buttonColor: "",
};
