import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { post } from "aws-amplify/api";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  Text,
  View,
} from "@react-pdf/renderer";
import useGeaPdfStyles from "./useGeaPdfStyles";
import mvpsLogo from "../../../../assets/img/collaborators/gea/Logo-GEAB-Color.png";
import moment from "moment-timezone";
import HeaderPdf from "./pdf-components/HeaderPdf";
import GeaRequirementsPdf from "./pdf-components/GeaRequirementsPdf";
import AnalisisResultsPdf from "./pdf-components/AnalisisResultsPdf";
import CostAnalisisPdf from "./pdf-components/CostAnalisisPdf";

const GeaPdf = () => {
  const styles = useGeaPdfStyles();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const date = moment(data?.createdAt)
    .tz("America/Mexico_City")
    .format("dddd D [de] MMMM [del] YYYY");

  useEffect(() => {
    getTool();
  }, [id]);

  const getTool = async () => {
    try {
      const restOperation = post({
        apiName: "geaTool",
        path: "/get-gea-tool",
        options: {
          body: { id },
        },
      });

      const response = await restOperation.response;
      const { data } = await response.body.json();
      setData(data.getGeaTool);
    } catch (e) {
      console.log(e);
      console.log("GET call failed: ", JSON.parse(e));
    }
  };
  const MyDocument = ({ data }) => (
    <Document
      pageLayout={"twoColumnLeft"}
      creator={"MVPS & GeaTools"}
      subject={`MVPS & GeaTools`}
      title={`Analisis`}
      author={"https://molding-vps.com"}
    >
      <Page bookmark={"MVPS"} size="LETTER">
        <View
          fixed
          style={{
            position: "absolute",
            minWidth: "50%",
            alignSelf: "center",
            justifyContent: "center",
            top: "30%",
            display: "block",
            width: "50%",
            opacity: 0.05,
          }}
        >
          <Image src={mvpsLogo} />
        </View>

        <Image
          src={mvpsLogo}
          style={{
            marginTop: "15px",
            alignSelf: "center",
            width: "120px",
            justifyContent: "center",
            display: "block",
            marginBottom: "5px",
          }}
        />
        <HeaderPdf date={date} data={data} styles={styles} />
        <GeaRequirementsPdf data={data} styles={styles} />
        <AnalisisResultsPdf styles={styles} data={data} />

        {data && data?.analisisComments !== "" && (
          <>
            <Text style={[styles.header, { marginTop: "70px" }]}>
              {"Comentarios"}
            </Text>
            <View
              style={{
                border: 1,
                width: "90%",
                padding: "9px",
                marginLeft: "5%",
                marginRight: "5%",
                marginBottom: "10px",
                borderRadius: 10,
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Text style={[styles.text]}>{data?.analisisComments}</Text>
            </View>
          </>
        )}

        <CostAnalisisPdf styles={styles} data={data} />
        <Text style={styles.header}>{"Resumen"}</Text>
        <View
          style={{
            border: 1,
            width: "90%",
            padding: "9px",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: "10px",
            borderRadius: 10,
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <View style={{ justifyContent: "center" }}>
            <Text
              style={{
                fontSize: 14,
                color: "grey",
                justifyContent: "center",
                marginHorizontal: 20,
              }}
            >
              Testing
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              {`Tu muestra de Einar 201 al ${data?.geaEinarPercent}% muestra Resultados Satisfactorios en ${data?.geaPoints} de los 23 puntos analizados`}
            </Text>
            <Text style={styles.text}> </Text>
            <Text
              style={{
                fontSize: 14,
                color: "grey",
                justifyContent: "center",
                marginHorizontal: 20,
              }}
            >
              Eficiencia
            </Text>
            <Text style={styles.text}> </Text>
            <Text
              style={styles.text}
            >{`Se obtuvo una optimiación de tiempo de ${data?.cycleTimeOptimization} segundos por ciclo.`}</Text>

            <Text
              style={styles.text}
            >{`Se obtuvo una optimiación de tiempo de ${parseFloat(
              data?.prodTimeOptimization
            ).toFixed(2)} horas para el lote de producción.`}</Text>

            <Text style={styles.text}>
              {`Se obtuvo un ahorro de costo por pieza de $ ${parseFloat(
                data?.pieceSaveCost
              ).toFixed(4)} ${data?.currency}.`}
            </Text>
            <Text style={styles.text}>
              {`Se obtuvo un ahorro de costo por lote de $ ${parseFloat(
                data?.lotSaveCost
              ).toFixed(4)} ${data?.currency}.`}
            </Text>
            <Text style={styles.text}>
              {`Inversión requerida $ ${data?.investmentRequired} ${
                data?.currency
              } de aditivo Einar 201, con un total de ${parseFloat(
                data?.costPerKg
              ).toFixed(4)} kg para el lote de producción.`}
            </Text>
            <Text style={styles.text}>
              {`La Recuperación de Capital estimada para este lote de producción es de $ ${data?.capitalRecovery} ${data?.currency}, sobre la inversión del aditivo`}
            </Text>
            <Text style={styles.text}> </Text>
            <Text
              style={{
                fontSize: 12,
                color: "grey",
                justifyContent: "center",
                marginHorizontal: 20,
              }}
            >
              Consulta a un distribuidor autorizado para mejorar la evaluación
              de tu proceso, volumenes y costos. Envianos los resultados y
              conoce nuestro software avanzado para obtener mas detalles de tu
              Recuperacion de Inversión.{" "}
            </Text>
          </View>
        </View>
        {data?.finalComments !== "" && (
          <>
            <Text
              style={[
                styles.header,
                data?.analisisComments !== "" && { marginTop: "70px" },
              ]}
            >
              {"Comentarios Finales"}
            </Text>
            <View
              style={{
                border: 1,
                width: "90%",
                padding: "9px",
                marginLeft: "5%",
                marginRight: "5%",
                marginBottom: "10px",
                borderRadius: 10,
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Text style={[styles.text]}>{data?.finalComments}</Text>
            </View>
          </>
        )}

        <Text
          style={[styles.pageNumber]}
          render={({ pageNumber, totalPages }) => (
            <>
              <Text style={styles.pageNumber}>https://gea-abp.mx</Text>
            </>
          )}
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <>
      <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
        <MyDocument data={data} />
      </PDFViewer>
    </>
  );
};

export default GeaPdf;
