import { lbKg, ozGr } from "../../../hooks/useUomTransform";

export const inputData = (data) => {
  return [
    {
      label: "Cantidad de Producción",
      value: data?.prodQuantity,
      id: "prodQuantity",
      addOn: "Pcs",
      error:
        parseFloat(data?.prodQuantity) < 1 &&
        "La Cantidad de produccion debe  mayor o igual que 1.",
    },
    {
      label: "Riesgo de Scrap",
      value: data?.scrapRisk,
      id: "scrapRisk",
      addOn: "%",
      error:
        parseFloat(data?.scrapRisk) < 0 &&
        "El riesgo de scrap debe ser igual o mayor que 0.",
    },
    {
      label: "Número de Cavidades",
      value: data?.numberOfCavities,
      id: "numberOfCavities",
      addOn: "Pcs",
      error:
        parseFloat(data?.numberOfCavities) < 1 &&
        "El numero de cavidades debe de ser mayor o igual que 1.",
    },
    {
      label: "Capacidad de Tolva",
      value: data?.hopperCapacity,
      id: "hopperCapacity",
      addOn: lbKg(data?.header?.uom),
      error:
        parseFloat(data?.hopperCapacity) <= 0 &&
        "La Capacidad de la tolva debe de ser mayor que 0.",
    },
    {
      label: "Tiempo de Ciclo",
      value: data?.estCycleTime,
      id: "estCycleTime",
      addOn: "sec",
      error:
        parseFloat(data?.estCycleTime) <= 0 &&
        "El tiempo de ciclo debe de ser mayor que 0.",
    },
    {
      label: "Peso de Disparo al 100%",
      value: data?.shotWeight100,
      id: "shotWeight100",
      addOn: ozGr(data?.header?.uom),
      error:
        parseFloat(data?.shotWeight100) <= 0 &&
        "El peso de disparo debe de ser mayor que 0.",
    },
  ];
};
