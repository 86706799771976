import { initialStateHeader } from "../../../utilities/variabled";

const tableStructure = [
  {
    secuence: "1",
    value: "",
  },
  {
    secuence: "2",
    value: "",
  },
  {
    secuence: "3",
    value: "",
  },
  {
    secuence: "4",
    value: "",
  },
  {
    secuence: "5",
    value: "",
  },
  {
    secuence: "6",
    value: "",
  },
  {
    secuence: "7",
    value: "",
  },
  {
    secuence: "8",
    value: "",
  },
  {
    secuence: "9",
    value: "",
  },
  {
    secuence: "10",
    value: "",
  },
];

const resultStructure = {
  average: "",
  min: "",
  max: "",
  diff: "",
};

export const initialState = {
  header: initialStateHeader,
  shotWeight: tableStructure,
  fillTime: tableStructure,
  vpTransfer: tableStructure,
  shotWeightResults: resultStructure,
  fillTimeResults: resultStructure,
  vpTransferResults: resultStructure,
  checkValveRepeatibility: "",
  chartImage: "",
  setBackPressure: "",
  setRPM: "",
  injectionSpeedTest: "",
};

export const keysForForm = [
  "shotWeight",
  "fillTime",
  "vpTransfer",
  "setBackPressure",
  "setRPM",
  "injectionSpeedTest",
];

