import React, { createContext, useContext, useCallback } from "react";
import Swal from "sweetalert2";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

const footer =
  "MVPSⓇ desarrolla experimentos y funciones requeridas para el desarrollo de tus procesos de moldeo. Los parámetros ingresados, las desiciones y el correcto uso y mantenimiento de los equipos intervenidos son responsabilidad del usuario";
export const AlertProvider = ({ children }) => {
  const showSuccessAlert = useCallback(
    (message = "Tu operación se ha completado con éxito.", icon = "success", title = "Operación Exitosa") => {
      Swal.fire({
        title: title,
        text: message,
        icon: icon, // 'success' o 'error'
        confirmButtonText: "Ok",
        footer: footer
      });
    },
    []
  );
  const showErrorAlert = useCallback((error) => {
    Swal.fire({
      title: "Oops, ha habido un problema",
      //text: error,
      html: error,
      icon: "error", // 'success' o 'error'
      confirmButtonText: "Ok",
    });
  }, []);

  return (
    <AlertContext.Provider value={{ showSuccessAlert, showErrorAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
