import { useContext, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";
export const useEnduranceHooks = () => {
  const { project, updateLoading, updateProject } = useContext(ProjectContext);

  const [showData, setShowData] = useState(false);
  const {
    speedSelected,
    best_Hold_Time_Select,
    cooling_Time_Select,
    shotsInBarrel,
    clamp_and_Op_Times,
    protect_Time,
    recovery_Time,
  } = project;

  const onSubmitHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "enduranceTime",
      path: "/endurance-time",
      options: {
        body: {
          inyectionTime: speedSelected.inyectionTime,
          best_Hold_Time_Select,
          cooling_Time_Select,
          shotsInBarrel,
          clamp_and_Op_Times,
          protect_Time,
          recovery_Time,
        },
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();

    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.total_Cycle_Time_Real = res.total_Cycle_Time_Real.toString();
        updated.clamp_and_Op_Times = clamp_and_Op_Times.toString();
        updated.protect_Time = protect_Time.toString();
        updated.recovery_Time = recovery_Time.toString();
        updated.rotate_Delay_Suggested = res.rotate_Delay_Suggested.toString();
        updated.residence_by_Cycle = res.residence_by_Cycle.toString();
        updated.total_Residence_Time_of_Shot = res.total_Residence_Time_of_Shot.toString();
        updated.phase = "15";
      })
    )
      .then((res) => {
        updateProject(res);
        setShowData(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  return {
    onSubmitHandler,
    showData,
    setShowData,
  };
};
