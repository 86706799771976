import DataDisplayBadge from "../../../../components/DataDisplay/DatadisplayBadge";
import {
  hpsiToHbar,
  inCubeCmCube,
  inMm,
  inSecMmsec,
  lbKg,
  tonKn
} from "../../../projects/individual/hooks/useUomTransform";
import React from "react";

const Data = ({item, textColor = "gray.600"}) => {
  return (
    <>
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Sistema de medición"}
        color={textColor}
        value={item.uom}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"ID de Maquina"}
        color={textColor}
        value={item.machineId}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Numero de Serie"}
        color={textColor}
        value={item.serialNumber}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Fabricante"}
        color={textColor}
        value={item.brand}
        type={"text"}
      />

      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Año"}
        color={textColor}
        value={item.year}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Tipo de maquina"}
        color={textColor}
        value={item.machineType}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Fuerza de cierre"}
        color={textColor}
        value={item.machineTonnage}
        type={"weight"}
        rightAddon={tonKn(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Diametro del pistón"}
        color={textColor}
        value={item.pistonDiameter}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Diametro del husillo"}
        color={textColor}
        value={item.screwDiameter}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Maxima presión hidraulica"}
        color={textColor}
        value={item.maxHydraulicPressure}
        type={"pressure"}
        rightAddon={hpsiToHbar(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Capacidad de la tolva"}
        color={textColor}
        value={item.hopperCapacity}
        type={"weight"}
        rightAddon={lbKg(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Máxima longitud de disparo"}
        color={textColor}
        value={item.maxShotSize}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Relación L/D Husillo"}
        color={textColor}
        value={item.LDratio}
        type={"int"}
        rightAddon={":1"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Volumen de Barril"}
        color={textColor}
        value={item.barrelVolume}
        type={"volume"}
        rightAddon={inCubeCmCube(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Máxima Velocidad de Inyección"}
        color={textColor}
        value={item.maxInjectionSpeed}
        type={"time"}
        rightAddon={inSecMmsec(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Máxima expulsión de la máquina"}
        color={textColor}
        value={item.machineMaxEjection}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Distancia Vertical entre Barras"}
        color={textColor}
        value={item.tieBarVerticalDistance}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Distancia Horizontal entre Barras"}
        color={textColor}
        value={item.tieBarHorizontalDistance}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Máxima Apertura de Prensa"}
        color={textColor}
        value={item.maxOpenClamp}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Mínima Altura de Molde"}
        color={textColor}
        value={item.minStackMoldSize}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Máxima Altura de Molde"}
        color={textColor}
        value={item.maxStackMoldSize}
        type={"weight"}
        rightAddon={inMm(item?.uom)}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Sistema Hidraulico"}
        color={textColor}
        value={item.machineHydraulicSystem}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Sistema Neumatico"}
        color={textColor}
        value={item.machinPneumaticSystem}
        type={"text"}
      />
      <DataDisplayBadge
        badgeColorScheme={"blue"}
        title={"Sistema Vacio"}
        color={textColor}
        value={item.machineVacummSystem}
        type={"text"}
      />
    </>
  )
}

export default Data