import EditBase from "../../Cards/EditBase";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import {
  areAllFloats,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import { useViscoscityStudyHooks } from "./hooks/useViscoscityStudyHooks";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import LineChart from "../../../../../../components/Charts/LineChart";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import CardHeader from "../../../../../../components/Card/CardHeader";
import { cardHeaderStyles } from "../../../../../../theme/styles";
import ViscosityStudyData from "./components/ViscosityStudyData";
import ViscosityStudyHeader from "./components/ViscosityStudyHeader";
import { selectOptions } from "./utilities/variables";
import { borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";

const EditViscosityStudy = () => {
  const { id } = useParams();
  const { project, updateProject, isLoading, handleInputChange } = useContext(
    ProjectContext
  );
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    max_Machine_Use,
    sequence_Selection,
    viscoscityStudyDataMatrix,
  } = project;

  const {
    onSubmitGenerateDataHandler,
    isDisabledButtonDataMatrix,
    onSubmitMatrix,
    setShowChart,
    showChart,
    lineChartDataMatrix,
    lineChartOptionsMatrix,
    onChangeSequenceHandler,
    onChangeDataMatrix,
    showData,
    setShowData,
  } = useViscoscityStudyHooks();

  const thFontSize = "10px";

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <EditBase
        title={"Estudio de Viscosidad"}
        tittleButton={"Terminar"}
        disabled={!showChart}
      >
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid
            color={textColor}
            borderColor={borderColor}
            gap={6}
            borderRadius={15}
            borderWidth={1}
            p={5}
            mx={5}
          >
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              disabled={showData}
              type={"number"}
              addOn={"%"}
              id={"max_Machine_Use"}
              label={"Uso Deseado de la maquina"}
              onChange={handleInputChange}
              value={max_Machine_Use}
              min={1}
              max={100}
            />
            <Button
              colorScheme={"facebook"}
              onClick={
                showData
                  ? () => setShowData(false)
                  : () => onSubmitGenerateDataHandler()
              }
              disabled={!areAllFloats([max_Machine_Use])}
              isLoading={isLoading}
            >
              {showData ? "Editar" : "Generar y Guardar Datos"}
            </Button>
          </Grid>
        </GridItem>

        {showData && (
          <GridItem colSpan={4}>
            <ViscosityStudyData />
            <Grid
              borderColor={borderColor}
              color={textColor}
              p={5}
              borderWidth={1}
              borderRadius={15}
              mt={5}
              mx={5}
            >
              <Flex direction={"column"} gap="24px" w={"100%"}>
                <CardHeader {...cardHeaderStyles}>
                  <Grid justifyItems={"center"}>
                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                      Realiza los experimentos necesarios para completar el
                      estudio.
                    </Text>
                    <Text color={textColor} fontSize="sm">
                      Configura la Máquina de Inyección con la Velocidad de
                      Inyección, de acuerdo a la secuencia deseada y llena los
                      campos requeridos. Con mayor numero de decimales se
                      obtendrán resultados mas precisos.
                    </Text>
                  </Grid>
                </CardHeader>
                <ViscosityStudyHeader project={project} />
                {viscoscityStudyDataMatrix &&
                  viscoscityStudyDataMatrix.map((item) => {
                    return (
                      <Grid
                        alignItems={"center"}
                        justifyContent={"center"}
                        py={
                          parseInt(sequence_Selection) === item.sequence
                            ? 2
                            : ""
                        }
                        color={
                          parseInt(sequence_Selection) === item.sequence
                            ? "white"
                            : textColor
                        }
                        fontSize={thFontSize}
                        templateColumns="repeat(12, 1fr)"
                        justifyItems={"center"}
                        gap={1}
                        bgColor={
                          parseInt(sequence_Selection) === item.sequence
                            ? "green"
                            : ""
                        }
                      >
                        <Text fontSize={thFontSize}>{item.sequence}</Text>
                        <Text>{item.intervalSpeed}</Text>
                        <Input
                          borderColor={borderColor}
                          disabled={showChart}
                          onChange={(e) => onChangeDataMatrix(e, item.sequence)}
                          borderRadius={2}
                          type={"number"}
                          name={"fillingTime"}
                          value={item.fillingTime}
                          placeholder={"ej: 0.5"}
                          size={"xs"}
                        />
                        <Input
                          borderColor={borderColor}
                          onChange={(e) => onChangeDataMatrix(e, item.sequence)}
                          borderRadius={2}
                          type={"number"}
                          disabled={showChart}
                          name={"maxPressureHpsi"}
                          value={item.maxPressureHpsi}
                          placeholder={"ej: 1720"}
                          size={"xs"}
                        />
                        <Input
                          borderColor={borderColor}
                          onChange={(e) => onChangeDataMatrix(e, item.sequence)}
                          borderRadius={2}
                          type={"number"}
                          disabled={showChart}
                          name={"shotWeight"}
                          value={item.shotWeight}
                          placeholder={"ej: 0.675"}
                          size={"xs"}
                        />
                        <Input
                          borderColor={borderColor}
                          onChange={(e) => onChangeDataMatrix(e, item.sequence)}
                          borderRadius={2}
                          type={"number"}
                          disabled={showChart}
                          name={"vpCuttingPosition"}
                          value={item.vpCuttingPosition}
                          placeholder={"ej: 0.29"}
                          size={"xs"}
                        />
                        <Text>
                          {parseFloat(item.maxPressurePpsi).toFixed(2)}
                        </Text>
                        <Text>{parseFloat(item.flowRate).toFixed(4)}</Text>
                        <Text>
                          {parseFloat(item.realSpeedMachine).toFixed(4)}
                        </Text>
                        <Text>
                          {parseFloat(item.speedEfficiency).toFixed(4)}
                        </Text>
                        <Text>{parseFloat(item.shearRate).toFixed(4)}</Text>
                        <Text>
                          {parseFloat(item.relativeViscocity).toFixed(2)}
                        </Text>
                      </Grid>
                    );
                  })}
              </Flex>
            </Grid>
            {showChart && (
              <Grid
                p={5}
                borderWidth={1}
                borderColor={borderColor}
                borderRadius={15}
                mt={5}
                my={5}
                mx={5}
              >
                <Flex justifyContent={"center"}>
                  <Flex
                    w={"25%"}
                    color={"black"}
                    direction={"column"}
                    justifyContent={"center"}
                  >
                    <FormLabel color={textColor}>
                      Seleccion de Secuencia
                    </FormLabel>
                    <Select
                      value={selectOptions[parseInt(sequence_Selection) - 1]}
                      defaultValue={
                        selectOptions[parseInt(sequence_Selection) - 1]
                      }
                      onChange={(e) => onChangeSequenceHandler(e)}
                      options={selectOptions}
                      placeholder={"Selecciona una Secuencia"}
                    />
                  </Flex>
                </Flex>
                <Flex
                  mt={10}
                  color={textColor}
                  direction={"column"}
                  w={"80%"}
                  minHeight={"300px"}
                  alignSelf={"center"}
                >
                  <Flex
                    color={textColor}
                    direction={"column"}
                    w={"100%"}
                    borderColor={"green"}
                    position={"relative"}
                  >
                    <Box w="100%" minH={{ sm: "450px" }}>
                      <LineChart
                        chartOptions={lineChartOptionsMatrix}
                        chartData={lineChartDataMatrix}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Grid>
            )}
            <Grid mx={5} mt={5}>
              <Button
                colorScheme={"facebook"}
                onClick={
                  showChart ? () => setShowChart(false) : () => onSubmitMatrix()
                }
                isLoading={isLoading}
                disabled={isDisabledButtonDataMatrix}
              >
                {showChart ? "EDITAR EL FORMULARIO" : "GENERAR Y GUARDAR DATOS"}
              </Button>
            </Grid>
          </GridItem>
        )}
      </EditBase>
    </>
  );
};

export default EditViscosityStudy;
