import React from "react";
import IndividualBase from "../../components/IndividualBase";
import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IndividualForm from "./components/IndividualForm";
const IndividualViscosityStudy = () => {

  return (
    <HeaderProvider>
      <IndividualBase>
        <IndividualForm />
      </IndividualBase>
    </HeaderProvider>
  );
};

export default IndividualViscosityStudy;
