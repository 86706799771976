import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { formaOptions } from "../utilities/variables";
import React, { useContext } from "react";
import { useRunnersHooks } from "../../../../../../../hooks/projected-area-forms/useRunnersHooks";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import DataDisplayBadge from "../../../../../../../components/DataDisplay/DatadisplayBadge";
import { inMm } from "../../../../../individual/hooks/useUomTransform";

const RunnerForm = ({ showRunnerArea, setShowRunnerArea }) => {
  const { project, isLoading } = useContext(ProjectContext);
  const {
    errors,
    finishRunners,
    handleInputChange,
    addRunner,
    removeRunner,
    isLastRunnerComplete,
    customStyles,
  } = useRunnersHooks();

  const textColor = "gray.600";
  const borderColor = "gray.400";
  return (
    <GridItem
      gap={5}
      color={"gray.700"}
      borderWidth={1}
      borderRadius={15}
      borderColor={borderColor}
    >
      {!showRunnerArea ? (
        <>
          {project.runners.map((runner, index) => (
            <Box p={5} key={index} width="100%">
              <Text
                fontSize={"xl"}
                fontWeight={"bold"}
                color={textColor}
                mb={2}
              >
                Runner {index + 1}
              </Text>
              <FormControl
                isInvalid={errors[index] && !!errors[index].form}
                mb={2}
              >
                <FormLabel mb={2}>
                  <Text color={textColor}>Forma</Text>
                </FormLabel>
                <Select
                  isClearable={true}
                  mb={2}
                  styles={customStyles}
                  color={"gray.700"}
                  options={formaOptions}
                  value={formaOptions.find(
                    (option) => option.value === runner.form
                  )}
                  onChange={(selectedOption) => {
                    handleInputChange(index, "form", selectedOption?.value);
                  }}
                />
                <FormErrorMessage>
                  {errors[index] && errors[index].form}
                </FormErrorMessage>
              </FormControl>
              {runner.form && (
                <>
                  {runner.form === "CIRCULO" ? (
                    <>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].diameter}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor}>Diámetro</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            borderColor={borderColor}
                            color={textColor}
                            value={runner.diameter}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "diameter",
                                e.target.value
                              )
                            }
                            placeholder="Diámetro"
                          />
                          <InputRightAddon children={inMm(project?.uom)} />
                        </InputGroup>

                        <FormErrorMessage>
                          {errors[index] && errors[index].diameter}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].cavities}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor}>Repeticiones</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            color={textColor}
                            value={runner.cavities}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "cavities",
                                e.target.value
                              )
                            }
                            placeholder="Cavidades"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {errors[index] && errors[index].cavities}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].base}
                      >
                        <FormLabel>
                          {" "}
                          <Text color={textColor}>Base</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            color={textColor}
                            value={runner.base}
                            onChange={(e) =>
                              handleInputChange(index, "base", e.target.value)
                            }
                            placeholder="Base"
                          />
                          <InputRightAddon children={inMm(project?.uom)} />
                        </InputGroup>

                        <FormErrorMessage>
                          {errors[index] && errors[index].base}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].height}
                      >
                        <FormLabel>
                          <Text color={textColor}>Altura</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            color={textColor}
                            value={runner.height}
                            onChange={(e) =>
                              handleInputChange(index, "height", e.target.value)
                            }
                            placeholder="Altura"
                          />
                          <InputRightAddon children={inMm(project?.uom)} />
                        </InputGroup>

                        <FormErrorMessage>
                          {errors[index] && errors[index].height}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb={2}
                        isInvalid={errors[index] && !!errors[index].cavities}
                      >
                        <FormLabel>
                          <Text color={textColor}>Repeticiones</Text>
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            backgroundColor={"white"}
                            borderColor={borderColor}
                            color={textColor}
                            value={runner.cavities}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "cavities",
                                e.target.value
                              )
                            }
                            placeholder="Cavidades"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {errors[index] && errors[index].cavities}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}
                </>
              )}
            </Box>
          ))}
          <Flex p={5} justifyContent={"space-between"} direction={"column"}>
            <Flex justifyContent={"space-between"} mb={1}>
              <Button
                colorScheme={"whatsapp"}
                mx={1}
                w={
                  project.runners && project.runners.length > 1 ? "50%" : "100%"
                }
                onClick={addRunner}
                disabled={!isLastRunnerComplete()}
              >
                Añadir Runner
              </Button>
              {project.runners && project.runners.length > 1 && (
                <Button colorScheme={"red"} mx={1} onClick={removeRunner}>
                  Remover Runner
                </Button>
              )}
            </Flex>
          </Flex>
        </>
      ) : (
        <DataDisplayBadge
          color={textColor}
          badgeColorScheme={"blue"}
          title={" Área del Runner"}
          value={project.runnerArea}
          my={2}
          mx={5}
        />
      )}
      <Flex
        px={5}
        pb={5}
        justifyContent={"space-between"}
        mt={1}
        direction={"column"}
      >
        <Button
          colorScheme={"facebook"}
          isLoading={isLoading}
          disabled={!isLastRunnerComplete()}
          onClick={() => {
            if (showRunnerArea) {
              setShowRunnerArea(false);
            } else {
              finishRunners();
              setShowRunnerArea(true);
            }
          }}
        >
          {showRunnerArea ? "Editar" : "Terminar"}
        </Button>
      </Flex>
    </GridItem>
  );
};

export default RunnerForm;
