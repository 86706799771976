import { Button, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import headerImage from "./../../../../assets/img/collaborators/rediassa/rediasa-header-3.png";
import img1 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-1.jpg";
import img2 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-2.jpg";
import img3 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-3.jpg";
import img4 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-4.jpg";
import img5 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-5.jpg";
import img6 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-6.jpg";
import img7 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-7.jpg";
import img8 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-8.jpg";
import img9 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-9.jpg";
import img10 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-10.jpg";
import img11 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-11.jpg";
import img12 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-12.jpg";
import img13 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-13.jpg";
import img14 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-14.jpg";
import img15 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-15.jpg";
import img16 from "./../../../../assets/img/collaborators/rediassa/rediasa-img-16.jpg";
const RediasaBodyComponent = () => {
  const textColor = "#033769";
  return (
    <Grid
      gap={6}
      mt={5}
      p={5}
      mx={5}
      borderRadius={15}
      backgroundColor={"white"}
      justifyItems={"center"}
    >
      <Text fontSize={"xl"} fontWeight={"bold"} color={textColor}>
        REDIASA somos un distribuidor autorizado de PIAB®, dedicados a dar
        soluciones al mundo de la automatización, desarrollando diseños de EOAT
        y facilidades de transporte, sujeción y levantamiento de los componentes
        y productos en los sistemas de producción.{" "}
      </Text>
      <Text fontSize={"xl"} fontWeight={"bold"} color={textColor}>
        Contamos con amplia experiencia en el ramo automotriz, industrial y
        electrónico en la región noroeste de México.{" "}
      </Text>
      <Image alt={"rediasa-img"} src={headerImage} width={"40%"} />
      <Text fontSize={"xl"} fontWeight={"bold"} color={textColor}>
        Con nosotros podrás encontrar una amplia gama de adaptadores, perfiles,
        sujetadores, componentes y diversos suministros para la fabricación y
        mejora de tus Herramientas de Fin de Brazo de Robot (EOAT).{" "}
      </Text>
      <Grid templateColumns="repeat(5, 1fr)" justifyItems={"center"}>
        <GridItem colSpan={2}>
          <Image alt={"rediasa-img-2"} src={img1} />
        </GridItem>
        <Image alt={"rediasa-img-3"} src={img2} />
        <GridItem colSpan={2}>
          <Image alt={"rediasa-img-4"} src={img3} />
        </GridItem>
        <Image alt={"rediasa-img-5"} src={img4} />
        <Image alt={"rediasa-img-6"} src={img5} />
        <Image alt={"rediasa-img-7"} src={img6} />
        <Image alt={"rediasa-img-8"} src={img7} />
        <Image alt={"rediasa-img-9"} src={img8} />
        <Image alt={"rediasa-img-10"} src={img9} />
        <GridItem colSpan={2}>
          <Image alt={"rediasa-img-19"} src={img10} />
        </GridItem>
        <Image alt={"rediasa-img-11"} src={img11} />
        <Image alt={"rediasa-img-12"} src={img12} />
      </Grid>
      <Button
        as="a"
        href="https://www.piab.com/es-es/"
        target="_blank"
        rel="noopener noreferrer"
        backgroundColor="rgb(3, 118, 79)"
        color="white"
        _hover={{
          bg: "rgb(35, 170, 63)",
        }}
        borderRadius="5px"
        fontWeight="bold"
      >
        Conoce nuestros productos
      </Button>
      <Grid templateColumns="repeat(4, 1fr)" justifyItems={"center"} gap={4}>
        <GridItem colSpan={4}>
          <Image
            alt={"rediasa-img-13"}
            src={img13}
            width={"100%"}
            borderRadius={15}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Image
            alt={"rediasa-img-14"}
            src={img14}
            width={"100%"}
            borderRadius={15}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Image
            alt={"rediasa-img-15"}
            src={img15}
            width={"87%"}
            borderRadius={15}
          />
        </GridItem>
        <GridItem colSpan={4}>
          <Image
            alt={"rediasa-img-16"}
            src={img16}
            width={"100%"}
            borderRadius={15}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default RediasaBodyComponent;
