// usePdfStyles.js
import { StyleSheet } from "@react-pdf/renderer";

const useGeaPdfStyles = () => {
  return StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Oswald",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 3,
    },
    subtitle: {
      position: "absolute",
      fontSize: 18,
      margin: 12,
      fontFamily: "Oswald",
    },
    text: {
      marginBottom: 2,
      marginHorizontal: 20,
      fontSize: 8,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 20,
      width: "50%",
    },
    header: {
      fontSize: 16,
      marginBottom: 5,
      textAlign: "center",
      color: "grey",
    },
    page: {
      paddingTop: 35,
      alignItems: "center",
      paddingBottom: 65, // Espacio suficiente para el pie de página
      paddingHorizontal: 35,
      minHeight: "100%", // Asegúrate de que la página tenga una altura mínima
      // ...otros estilos...
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    view: {
      border: 1,
      width: "90%",
      padding: "10px",
      marginLeft: "5%",
      marginRight: "5%",
      marginBottom: "20px",
      borderRadius: 10,
      justifyContent: "space-between",
      flexDirection: "row",
    },
    viewContainer: {
      border: 1,
      width: "90%",
      padding: "10px",
      marginLeft: "5%",
      marginRight: "5%",
      marginBottom: "20px",
      borderRadius: 10,
      justifyContent: "space-between",
      flexDirection: "column",
    },
    // ...otros estilos que necesites...
  });
};

export default useGeaPdfStyles;
