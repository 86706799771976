import { Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ShelterHeader from "./components/ShelterHeader";
import ShelterBody from "./components/ShelterBody";
import ShelterBody2 from "./components/ShelterBody2";
import ShelterMapComponent from "./components/ShelterMapComponent";
import ShelterContactComponent from "./components/ShelterContactComponent";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionShelter,
  metaKeywordsShelter,
  metaTitleShelter,
} from "../utilities/metaVariables";

const ShelterComponent = () => {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");

  const textColor = "#4478bd";

  return (
    <>
      <HelmetComponent
        description={metaDescriptionShelter}
        keywords={metaKeywordsShelter}
        title={metaTitleShelter}
      />
      <Flex
        direction="column"
        pt={{ sm: "125px", lg: "125" }}
        width={isBannerPath ? "80%" : "auto"} // Aquí aplicamos el ancho condicionalmente
        mx={isBannerPath ? "auto" : "0"}
      >
        <ShelterHeader textColor={textColor} />
        <ShelterBody textColor={textColor} />
        <ShelterBody2 textColor={textColor} />
        <ShelterMapComponent />
        <ShelterContactComponent collaborator={"Shelter"} />
      </Flex>
    </>
  );
};

export default ShelterComponent;
