import { useState, useEffect } from 'react';

const useFormattedNumber = (type, numberString) => {
  const [formattedNumber, setFormattedNumber] = useState(numberString);

  useEffect(() => {
    let number = parseFloat(numberString);
    if (isNaN(number)) {
      setFormattedNumber(numberString);
      return;
    }

    switch (type) {
      case 'int':
        setFormattedNumber(Math.round(number).toString());
        break;
      case 'temp':
        setFormattedNumber(number.toFixed(2));
        break;
      case 'pressure':
        // Redondea el número y lo formatea con comas para los miles
        setFormattedNumber(Math.round(number).toLocaleString('en-US'));
        break;
      default:
        setFormattedNumber(number.toFixed(3));
    }
  }, [type, numberString]);

  return formattedNumber;
};

export default useFormattedNumber;
