const instructions = [
  "Introduce los valores solicitados en las casillas en blanco, obtendrás el área del pistón de inyección, el área interna del Barril, el valor de Ppsi (Presión Plástica) ejecutada por el Husillo al interior del Barril, así como el valor de la Razón de Intensificación correspondiente a la Máquina de Moldeo.",
  "Formula",
  "Ppsi = Hpsi * (HArea / PArea)",
  "Hárea: Área del Pistón Hidráulico",
  "Hpsi: Presión del Pistón Hidráulico",
  "Párea: Área del Tornillo de Inyección",
  "Ppsi: Presión del Tornillo de Inyección"
];

export default instructions;
