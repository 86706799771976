import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

const InstagramGradient = styled.div`
  border-radius: 5px;
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
  radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
  radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
  linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;  // Ajusta según el tamaño deseado
  height: 2rem; // Ajusta según el tamaño deseado
`;

export function InstagramIcon() {
  return (
    <InstagramGradient>
      <FontAwesomeIcon color={"white"} icon={faInstagram} size={"xl"} />
    </InstagramGradient>
  );
}