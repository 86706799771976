import { Text, View } from "@react-pdf/renderer";
import React from "react";

const GeaRequirementsPdf = ({ styles, data }) => {
  return (
    <>
      <Text style={styles.header}>{"Requerimiento"}</Text>
      <View
        style={{
          border: 1,
          width: "90%",
          padding: "9px",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "10px",
          borderRadius: 10,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View>
          <Text style={styles.text}> </Text>

          <Text style={styles.text}>Resina Virgen</Text>
          <Text style={styles.text}>Material Recuperado</Text>
          <Text style={styles.text}>Otros Aditivos</Text>
          <Text style={styles.text}>Aditivo Einar 201</Text>
          <Text style={styles.text}>Total</Text>
        </View>
        <View>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaVirginResin).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRegrindResin).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaOtherAdditives).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaEinarPercent).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.total).toFixed(2)} %
          </Text>
        </View>
        <View>
          <Text style={styles.text}>{"POR PIEZA"}</Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaVirginPartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRegrindPartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaAdditivePartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaEinarPartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.totalPerPiece).toFixed(4)} {data?.geaUdM}
          </Text>
        </View>
        <View>
          <Text style={styles.text}>{"POR LOTE"}</Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaVirginLotWeight).toFixed(2)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRegrindLotWeight).toFixed(2)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaAdditiveLotWeight).toFixed(2)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaEinarLotWeight).toFixed(2)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.totalPerLote).toFixed(2)} {data?.geaUdM}
          </Text>
        </View>
      </View>
    </>
  );
};

export default GeaRequirementsPdf;
