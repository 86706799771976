import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../contexts/ProjectContext";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";

import Select from "react-select";
import { useNewProjectHooks } from "./hooks/useNewProjectHooks";
import EditBase from "../project-overview/components/Cards/EditBase";
import DataDisplayBadge from "../../../components/DataDisplay/DatadisplayBadge";
import { useHistory } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import useProjectCount from "./hooks/useProjectCount";
import CustomGrid from "../individual/components/CustomGrid";
import { borderColor } from "../project-overview/components/utilities/variables";
import {
  inSquareMmSquare,
  ppsiToPbar,
} from "../individual/hooks/useUomTransform";

import DescriptionAndInstructionAcordeon from "../../../components/acordeon/DescriptionAndInstructionAcordeon";
import { Helmet } from "react-helmet";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import { getCurrentUser } from "aws-amplify/auth";

const NewProject = () => {
  const {
    name,
    tools,
    resins,
    machines,
    errors,
    onSubmitHandler,
    tool,
    setMachine,
    setResin,
    setTool,
    resin,
    machine,
    errorsInitialState,
    setErrors,
    setName,
    isLoadingMachines,
    isLoadingResins,
    isLoadingTools,
    hasProjectData,
    isSubmitDisabled,
    setUom,
    uom,
  } = useNewProjectHooks();

  const history = useHistory();

  const { project, isLoading, updateProject } = useContext(ProjectContext);
  //const { projectCount } = useProjectCount();

  useEffect(() => {
    updateProject([]);
    return () => {
      updateProject([]);
    };
  }, []);

  if (isLoadingResins || isLoadingMachines || isLoadingTools) {
    return <Flex>Cargando datos...</Flex>;
  }

  const { user } = useContext(UserContext);

  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const textColor = "gray.600";
  /*

  if (user && !user.isSubscribed) {
    if (projectCount >= 2) {
      return (
        <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
          <Text fontSize={"2xl"} fontWeight={"bold"}>
            Has Excedido el limite de creacion de proyectos para el plan
            gratuito (2).
          </Text>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            {" "}
            Actualiza tu suscripcion para crear mas proyectos
          </Text>
          <Button
            color={"black"}
            onClick={() => history.push("/admin/profile/plans")}
          >
            Ver Planes
          </Button>
        </Grid>
      );
    }
  }
  if (user && user.isSubscribed) {
    if (
      user.subscrptionType === "TECNICAS_PLASTICAS" ||
      user.subscrptionType === "BASICA"
    ) {
      if (projectCount >= 60) {
        return (
          <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              Has Excedido el limite de creacion de proyectos para el plan{" "}
              {formatString(user.subscrptionType)} (60).
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>
              {" "}
              Actualiza tu suscripcion para crear mas proyectos
            </Text>
            <Button
              color={"black"}
              onClick={() => history.push("/admin/profile/plans")}
            >
              Ver Planes
            </Button>
          </Grid>
        );
      }
    }
  }

   */

  if (resins.length === 0 || machines.length === 0 || tools.length === 0) {
    return (
      <>
        {resins.length === 0 && (
          <CustomGrid
            repeat={2}
            mt={"150px"}
            templateColumns="repeat(2, 1fr)"
            alignItems={"center"}
            justifyItems={"center"}
            gap={6}
            borderWidth={1}
            mb={5}
            p={5}
            borderRadius={15}
          >
            <Text color={"gray.600"} fontSize={"xl"} fontWeight={"bold"}>
              No Tienes Resinas
            </Text>
            <Button
              colorScheme={"facebook"}
              onClick={() => history.push("/admin/resin/create")}
            >
              Crear Nueva Resina
            </Button>
          </CustomGrid>
        )}
        {machines.length === 0 && (
          <CustomGrid
            repeat={2}
            mt={"150px"}
            templateColumns="repeat(2, 1fr)"
            alignItems={"center"}
            justifyItems={"center"}
            gap={6}
            borderWidth={1}
            mb={5}
            p={5}
            borderRadius={15}
          >
            <Text color={"gray.600"} fontSize={"xl"} fontWeight={"bold"}>
              No Tienes Maquinas
            </Text>
            <Button
              colorScheme={"facebook"}
              onClick={() => history.push("/admin/machine/create")}
            >
              Crear Nueva Maquina
            </Button>
          </CustomGrid>
        )}
        {tools.length === 0 && (
          <CustomGrid
            repeat={2}
            mt={"150px"}
            templateColumns="repeat(2, 1fr)"
            alignItems={"center"}
            justifyItems={"center"}
            gap={6}
            borderWidth={1}
            mb={5}
            p={5}
            borderRadius={15}
          >
            <Text color={"gray.600"} fontSize={"xl"} fontWeight={"bold"}>
              No Tienes Moldes
            </Text>
            <Button
              colorScheme={"facebook"}
              onClick={() => history.push("/admin/tools/create")}
            >
              Crear Nuevo Molde
            </Button>
          </CustomGrid>
        )}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Crear Nuevo Proyecto de Moldeo con Enfoque en Razón de Intensificación
          - MVPS
        </title>
        <meta
          name={"description"}
          content={
            "Inicia tu próximo proyecto de moldeo con MVPS y accede a herramientas avanzadas para la optimización y validación de procesos. Desde la selección de resinas hasta la configuración de máquinas y moldes, nuestra plataforma te guía en cada paso para asegurar el éxito de tu proyecto. Aprovecha nuestra experiencia y tecnología para mejorar la calidad y eficiencia de tus procesos de moldeo."
          }
        />
      </Helmet>
      <EditBase
        title={"Crear un Nuevo Proyecto - Razon de Intensificacion"}
        disabled={!hasProjectData()}
      >
        <GridItem colSpan={4} width={"100%"} p={5}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
          <Grid mt={5} templateColumns="repeat(2, 1fr)" gap={6} colSpan={4}>
            <FormControl alignSelf={"center"}>
              <FormLabel color={"gray.600"}>Nombre del Proyecto</FormLabel>
              <Input
                bg={"white"}
                value={name}
                placeholder={"Nombre del Proyecto"}
                borderColor={"gray.400"}
                onChange={(e) => {
                  setErrors(errorsInitialState);
                  setName(e.target.value);
                }}
              />
              {errors.name && (
                <FormHelperText color={"red.500"}>
                  El Nombre del Proyecto es Obligatorio
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id={"uom"} mb={5}>
              <FormLabel color={"gray.600"} as="legend" alignSelf={"center"}>
                Unidad de medida
              </FormLabel>
              <RadioGroup
                defaultValue="INGLES"
                value={uom}
                onChange={(e) => setUom(e)}
              >
                <HStack spacing="24px">
                  <Radio
                    borderColor={borderColor}
                    backgroundColor={"white"}
                    value="INGLES"
                  >
                    Ingles
                  </Radio>
                  <Radio
                    borderColor={borderColor}
                    backgroundColor={"white"}
                    value="INTERNACIONAL"
                  >
                    Internacional
                  </Radio>
                </HStack>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid mt={5} templateColumns="repeat(3, 1fr)" gap={6} colSpan={4}>
            <FormControl
              id={"resinId"}
              as="fieldset"
              mb={5}
              borderColor={"red"}
            >
              <FormLabel as="legend" color={"gray.600"}>
                Resina
              </FormLabel>
              <Select
                isLoading={isLoading}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: errors.resin ? "red" : "gray",
                    color: "black",
                  }),
                }}
                value={resin}
                fontColor={"gray.700"}
                defaultValue={resins[0]}
                options={resins}
                onChange={(e) => {
                  setErrors(errorsInitialState);
                  setResin(e);
                }}
              />
              {errors.resin && (
                <FormHelperText color={"red.500"}>
                  Selecciona una Resina
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id={"machineId"} as="fieldset" mb={5}>
              <FormLabel as="legend" alignSelf={"center"} color={"gray.600"}>
                Maquina
              </FormLabel>
              <Select
                isLoading={isLoading}
                value={machine}
                defaultValue={machines[0]}
                options={machines}
                onChange={(e) => {
                  setMachine(e);
                  setErrors(errorsInitialState);
                }}
              />
              {errors.machine && (
                <FormHelperText color={"red.500"}>
                  Selecciona una Maquina
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id={"toolId"} as="fieldset" mb={5}>
              <FormLabel as="legend" color={"gray.600"}>
                Molde - Producto
              </FormLabel>
              <Select
                isLoading={isLoading}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: errors.tool ? "red" : "gray",
                    color: "gray.900",
                  }),
                }}
                value={tool}
                defaultValue={tools[0]}
                options={tools}
                onChange={(e) => {
                  setTool(e);
                  setErrors(errorsInitialState);
                }}
              />
              {errors.tool && (
                <FormHelperText color={"red.500"}>
                  Selecciona un Molde
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <GridItem />
          <GridItem colSpan={4}>
            <Text color={"red.500"}>
              NOTA: Una vez creado el proyecto, no se pueden cambiar estos
              datos.
            </Text>
          </GridItem>

          {!hasProjectData() && (
            <GridItem colSpan={4}>
              <Button
                mt={5}
                width={"100%"}
                colorScheme={"facebook"}
                isLoading={isLoading}
                disabled={isSubmitDisabled || isLoading}
                onClick={onSubmitHandler}
              >
                COMENZAMOS
              </Button>
            </GridItem>
          )}

          {hasProjectData() && (
            <Grid mt={5} templateColumns="repeat(2, 1fr)" gap={6} colSpan={4}>
              <DataDisplayBadge
                badgeColorScheme={"blue"}
                color={textColor}
                title={"Área del Pistón"}
                value={project.pistonArea}
                rightAddon={inSquareMmSquare(project?.uom)}
              />
              <DataDisplayBadge
                badgeColorScheme={"blue"}
                color={textColor}
                title={"Área de Tornillo"}
                value={project.screwArea}
                rightAddon={inSquareMmSquare(project?.uom)}
              />
              <DataDisplayBadge
                badgeColorScheme={"blue"}
                color={textColor}
                type={"pressure"}
                title={"Presión Máxima del Plástico"}
                rightAddon={ppsiToPbar(project?.uom)}
                value={project.maxPlasticPressure}
              />
              <DataDisplayBadge
                badgeColorScheme={"blue"}
                color={textColor}
                type={"int"}
                title={"Razón de Intensificación"}
                value={project.intRatio}
                rightAddon={":1"}
              />
            </Grid>
          )}
        </GridItem>
      </EditBase>
    </>
  );
};

export default NewProject;
