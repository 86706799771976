import IndividualBase from "../../components/IndividualBase";
import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IndividualDryerSupplyForm from "./components/IndividualDryerSupplyForm";
import HelmetComponent from "../../../../../components/HelmetComponent";
import React from "react";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import { GridItem } from "@chakra-ui/react";
import CustomGrid from "../../components/CustomGrid";
import instructions from "./utilities/instructions";
import description from "./utilities/description";
const IndividualDryerSupply = () => {
  return (
    <>
      <HelmetComponent
        keywords={metaKeywords}
        title={metaTitle}
        description={metaDescription}
      />
      <HeaderProvider>
        <GridItem width={"100%"} mt={"120px"}>
          <CustomGrid>
            <IndividualBase description={description} instructions={instructions} title={"Abastecimiento de Secador"}>
              <IndividualDryerSupplyForm />
            </IndividualBase>
          </CustomGrid>
        </GridItem>
      </HeaderProvider>
    </>
  );
};

export default IndividualDryerSupply;
