import InputCustomFieldProjectRigthAddon from "../../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { Grid } from "@chakra-ui/react";
import React, { useContext } from "react";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { borderColor, textColor } from "../../../utilities/variables";
import { inMm } from "../../../../../individual/hooks/useUomTransform";

const ShotSizeForm = ({ showChart }) => {
  const { project, handleInputChange } = useContext(ProjectContext);

  const {
    cutOffPositionPercentage_95_98,
    cushionPositionPercentage,
    decompressionSize,
  } = project;
  return (
    <>
      <Grid
        borderColor={borderColor}
        templateColumns="repeat(1, 1fr)"
        templateRows="repeat(3, 1fr)"
        gap={6}
        p={5}
        borderWidth={1}
        borderRadius={15}
      >
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          addOn={"%"}
          disabled={showChart}
          id={"cutOffPositionPercentage_95_98"}
          value={cutOffPositionPercentage_95_98}
          onChange={handleInputChange}
          label={"Porcentaje de Primera Etapa"}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          addOn={"%"}
          disabled={showChart}
          id={"cushionPositionPercentage"}
          value={cushionPositionPercentage}
          onChange={handleInputChange}
          label={"Porcentaje de Colchon"}
        />
        <InputCustomFieldProjectRigthAddon
          color={textColor}
          borderColor={borderColor}
          addOn={inMm(project?.uom)}
          disabled={showChart}
          id={"decompressionSize"}
          value={decompressionSize}
          onChange={handleInputChange}
          label={"Tamaño de Descompresión"}
        />
      </Grid>
    </>
  );
};

export default ShotSizeForm;
