import { Grid, GridItem, Link, Text } from "@chakra-ui/react";
import CollaboratorsMap from "../../../../components/collaborators/Map";
import { googleMapURL } from "../../../../variables/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { lat, lan } from "../utilities";
import React from "react";

const LdMapComponent = () => {
  return (
    <Grid
      backgroundColor={"#808a99"}
      templateColumns="repeat(2, 1fr)"
      gap={1}
      mx={5}
      p={5}
      mt={5}
      borderWidth={1}
      borderRadius={15}
    >
      <Grid>
        <CollaboratorsMap
          zoom={16}
          marker={true}
          lat={lat}
          lan={lan}
          isMarkerShown={false}
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Grid>
      <Grid justifyItems={"center"}>
        <Text color={"white"} fontSize={"4xl"} fontWeight={"bold"}>
          Conócenos
        </Text>
        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Ubicación
        </Text>
        <Text color={"white"} fontSize={"md"}>
          Benito Juarez 113 A, Parque Industrial
        </Text>
        <Text color={"white"} fontSize={"md"}>
          Querétaro, Mex
        </Text>
        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Horario
        </Text>
        <Text fontSize={"md"} color={"white"}>
          Lunes - Sábado
        </Text>
        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Contacto
        </Text>
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Ing. Fernando López
        </Text>
        <Text color={"white"} fontSize={"md"}>
          +52 55 5331 3907
        </Text>
        <Text color={"white"} fontSize={"md"}>
          flopez@ldplastic-solution.com.mx
        </Text>
        <Link href={"https://ldplastic-solution.com/"} isExternal>
          <Text color={"white"} fontSize={"md"}>
            www.ldplastic-solution.com
          </Text>
        </Link>

        <GridItem />
        <Text color={"white"} fontSize={"md"} fontWeight={"bold"}>
          Redes Sociales
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <Link
            colorScheme={"facebook"}
            style={{ paddingRight: 15 }}
            href="https://www.facebook.com/fernlops00?mibextid=LQQJ4d"
            isExternal
          >
            <FontAwesomeIcon color={"#3b5998"} icon={faFacebook} size={"2xl"} />{" "}
          </Link>
          <Link
            style={{ paddingLeft: 15 }}
            href="https://www.linkedin.com/company/ld-plastic-solution/"
            isExternal
          >
            <FontAwesomeIcon color={"#0A66C2"} icon={faLinkedin} size={"2xl"} />{" "}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LdMapComponent;
