import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { initialToolState } from "./utilities/variables";
import { Field, Form, Formik } from "formik";
import { paddingXinputs } from "../resines/utilities/variables";
import {
  CENTIMETERS,
  inches,
  millimeters,
  SEC,
  SEG,
} from "../../../variables/uomVariables";
import React, { useContext } from "react";
import { moldHooks } from "./hooks/moldHooks";
import { validateForms } from "../../../variables/validateForms";
import { useHistory } from "react-router-dom";
import useMoldCount from "./hooks/useMoldCount";
import UserContext from "../../../contexts/UserContext";
import CustomGrid from "../../projects/individual/components/CustomGrid";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescriptionCreate,
  metaKeywordsCreate,
  metaTitleCreate,
} from "./utilities/metaVariables";

const CreateMold = () => {
  const { onSubmitHandler, tool, setTool, parseFinalState } = moldHooks();
  const { validateString, validateFloat } = validateForms();
  const history = useHistory();
  const { moldCount, isLoading } = useMoldCount(); // Usar el hook aquí

  const { user } = useContext(UserContext);

  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  /*
  if (user && !user.isSubscribed) {
    if (moldCount >= 2) {
      return (
        <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
          <Text fontSize={"2xl"} fontWeight={"bold"}>
            Has Excedido el limite de creacion de moldes para el plan gratuito
            (2).
          </Text>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            {" "}
            Actualiza tu suscripcion para crear mas moldes
          </Text>
          <Button
            color={"black"}
            onClick={() => history.push("/admin/profile/plans")}
          >
            Ver Planes
          </Button>
        </Grid>
      );
    }
  }
  if (user && user.isSubscribed) {
    if (
      user.subscrptionType === "TECNICAS_PLASTICAS" ||
      user.subscrptionType === "BASICA"
    ) {
      if (moldCount >= 5) {
        return (
          <Grid mt={"180px"} color={"white"} justifyItems={"center"} gap={6}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              Has Excedido el limite de creacion de moldes para el plan{" "}
              {formatString(user.subscrptionType)} (5).
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>
              {" "}
              Actualiza tu suscripcion para crear mas moldes
            </Text>
            <Button
              color={"black"}
              onClick={() => history.push("/admin/profile/plans")}
            >
              Ver Planes
            </Button>
          </Grid>
        );
      }
    }
  }

   */

  const textColor = "gray.600";
  const borderColor = "gray.400";
  const bgColor = "white";

  return (
    <>
      <HelmetComponent
        description={metaDescriptionCreate}
        title={metaTitleCreate}
        keywords={metaKeywordsCreate}
      />
      <Grid mt={"180px"} color={"white"}>
        <Formik
          initialValues={initialToolState}
          onSubmit={async (values, actions) => {
            try {
              const finalState = parseFinalState(tool, values);
              await onSubmitHandler(finalState).then(() => {
                history.push("/admin/tools/index");
              });
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {(props) => (
            <Flex direction="column" mb="50px" justify="center" align="center">
              <Form
                style={{
                  width: "90%",
                  justifyContent: "justify-between",
                }}
              >
                <CustomGrid repeat={3} gap={6}>
                  <GridItem colSpan={3} mb={"50px"}>
                    <Heading color={textColor}>CREAR NUEVO MOLDE</Heading>
                  </GridItem>
                  <FormControl
                    color={textColor}
                    id={"uom"}
                    as="fieldset"
                    mb={5}
                    paddingRigth={paddingXinputs}
                    isRequired
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FormLabel as="legend" color={"gray.600"}>
                      Unidad de medida
                    </FormLabel>
                    <RadioGroup
                      onChange={(e) => setTool({ ...tool, uom: e })}
                      defaultValue="INGLES"
                      value={tool.uom}
                    >
                      <HStack spacing="24px">
                        <Radio
                          bgColor={bgColor}
                          value="INTERNACIONAL"
                          children={"INTERNACIONAL"}
                        />
                        <Radio
                          bgColor={bgColor}
                          value="INGLES"
                          selected
                          children={"INGLES"}
                        />
                      </HStack>
                    </RadioGroup>
                  </FormControl>

                  <Field
                    name="toolId"
                    validate={validateString}
                    color={textColor}
                  >
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.toolId && form.touched.toolId}
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="toolId">
                          Nombre del molde
                        </FormLabel>
                        <Input
                          bgColor={bgColor}
                          borderColor={borderColor}
                          {...field}
                          id="toolId"
                          placeholder="toolId"
                        />
                        <FormErrorMessage>
                          {form.errors.toolId}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="serialNumber" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.serialNumber && form.touched.serialNumber
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="uom">
                          Número de Serie
                        </FormLabel>
                        <Input
                          bgColor={bgColor}
                          borderColor={borderColor}
                          {...field}
                          id="serialNumber"
                          placeholder={"ej: ID-123"}
                        />
                        <FormErrorMessage>
                          {form.errors.serialNumber}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <FormControl
                    color={textColor}
                    id={"uom"}
                    as="fieldset"
                    mb={5}
                    paddingRigth={paddingXinputs}
                    isRequired
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <FormLabel as="legend" color={"gray.600"}>
                      Tipo de molde
                    </FormLabel>
                    <RadioGroup
                      onChange={(e) => setTool({ ...tool, toolType: e })}
                      defaultValue="HORIZONTAL"
                      value={tool.toolType}
                    >
                      <HStack spacing="24px">
                        <Radio
                          bgColor={bgColor}
                          color={"gray.600"}
                          value="HORIZONTAL"
                          children={"HORIZONTAL"}
                        />
                        <Radio
                          bgColor={bgColor}
                          value="VERTICAL"
                          selected
                          children={"VERTICAL"}
                        />
                        <Radio
                          bgColor={bgColor}
                          value="SHUTTLE"
                          selected
                          children={"SHUTTLE"}
                        />
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <Field name="product" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.product && form.touched.product}
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="product">
                          Nombre del producto
                        </FormLabel>
                        <Input
                          bgColor={bgColor}
                          borderColor={borderColor}
                          type={"text"}
                          {...field}
                          id="product"
                          placeholder={"ej: 3"}
                        />
                        <FormErrorMessage>
                          {form.errors.product}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="numberOfCavities" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.numberOfCavities &&
                          form.touched.numberOfCavities
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="numberOfCavities">
                          Numero de Cavidades
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            step=".01"
                            {...field}
                            id="numberOfCavities"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"gray.600"}
                            children={"pzs"}
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.numberOfCavities}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="horizontalMeasurement" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.horizontalMeasurement &&
                          form.touched.horizontalMeasurement
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel
                          color={textColor}
                          htmlFor="horizontalMeasurement"
                        >
                          Medida Horizontal
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            {...field}
                            id="horizontalMeasurement"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              tool.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.horizontalMeasurement}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/* Medida Vertical verticalMeasurement */}

                  <Field name="verticalMeasurement" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.verticalMeasurement &&
                          form.touched.verticalMeasurement
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel
                          color={textColor}
                          htmlFor="verticalMeasurement"
                        >
                          Medida Vertical
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            {...field}
                            id="verticalMeasurement"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              tool.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.verticalMeasurement}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="stackMold" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.stackMold && form.touched.stackMold
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="stackMold">
                          Altura de Molde (STACK)
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            {...field}
                            id="stackMold"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              tool.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.stackMold}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="cavityVolume" validate={validateString}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.cavityVolume && form.touched.cavityVolume
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="cavityVolume">
                          Volumen de cavidades
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            {...field}
                            id="cavityVolume"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              tool.uom === "INTERNACIONAL"
                                ? `${CENTIMETERS}3`
                                : `${inches}3`
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.cavityVolume}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="runnerVolume" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.runnerVolume && form.touched.runnerVolume
                        }
                        mb={5}
                        paddingLeft={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="runnerVolume">
                          Volumen de runner
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            {...field}
                            id="runnerVolume"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              tool.uom === "INTERNACIONAL"
                                ? `${CENTIMETERS}3`
                                : `${inches}3`
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.runnerVolume}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="cycleTime" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.cycleTime && form.touched.cycleTime
                        }
                        mb={5}
                        paddingRight={paddingXinputs}
                      >
                        <FormLabel color={textColor} htmlFor="cycleTime">
                          Tiempo de ciclo
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"number"}
                            {...field}
                            id="cycleTime"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"black"}
                            children={tool.uom === "INTERNACIONAL" ? SEG : SEC}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.cycleTime}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/*
                  <Field name="gateSize" validate={validateFloat}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.gateSize && form.touched.gateSize}
                      mb={5}
                      paddingLeft={paddingXinputs}
                    >
                      <FormLabel htmlFor="gateSize">
                        Tamaño de compuerta
                      </FormLabel>
                      <InputGroup>
                        <Input
                          type={"gateSize"}
                          {...field}
                          id="gateSize"
                          placeholder={"ej: 3"}
                        />
                        <InputRightAddon
                          color={"black"}
                          children={
                            tool.uom === "INTERNACIONAL"
                              ? `${millimeters}2`
                              : `${inches}2`
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.gateSize}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                   */}

                  <Field name="maxMoldEject" validate={validateFloat}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.maxMoldEject && form.touched.maxMoldEject
                        }
                        mb={5}
                      >
                        <FormLabel color={textColor} htmlFor="maxMoldEject">
                          Expulsión de molde maxima
                        </FormLabel>
                        <InputGroup borderColor={borderColor}>
                          <Input
                            bgColor={bgColor}
                            type={"maxMoldEject"}
                            {...field}
                            id="maxMoldEject"
                            placeholder={"ej: 3"}
                          />
                          <InputRightAddon
                            color={"black"}
                            children={
                              tool.uom === "INTERNACIONAL"
                                ? millimeters
                                : inches
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.maxMoldEject}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <FormControl id={"gateForm"} as="fieldset" mb={5} isRequired>
                    <FormLabel color={textColor} as="legend">
                      Forma de la compuerta
                    </FormLabel>
                    <RadioGroup
                      color={textColor}
                      onChange={(e) => setTool({ ...tool, gateForm: e })}
                      defaultValue="CIRCULAR"
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="CIRCULAR">
                          Circular
                        </Radio>
                        <Radio bgColor={bgColor} value="RECTANGULAR">
                          Rectangular
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  {tool.gateForm === "CIRCULAR" && (
                    <Field name="gateDiameter" validate={validateFloat}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.gateDiameter &&
                            form.touched.gateDiameter
                          }
                          mb={5}
                        >
                          <FormLabel color={textColor} htmlFor="gateDiameter">
                            Diametro de la compuerta
                          </FormLabel>
                          <InputGroup borderColor={borderColor}>
                            <Input
                              bgColor={bgColor}
                              type={"gateDiameter"}
                              {...field}
                              id="gateDiameter"
                              placeholder={"ej: 3"}
                            />
                            <InputRightAddon
                              color={"black"}
                              children={
                                tool.uom === "INTERNACIONAL"
                                  ? millimeters
                                  : inches
                              }
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.gateDiameter}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {tool.gateForm === "RECTANGULAR" && (
                    <Field name="widthGate" validate={validateFloat}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.widthGate && form.touched.widthGate
                          }
                          mb={5}
                          paddingRight={paddingXinputs}
                        >
                          <FormLabel color={textColor} htmlFor="widthGate">
                            Base de la compuerta
                          </FormLabel>
                          <InputGroup borderColor={borderColor}>
                            <Input
                              bgColor={bgColor}
                              type={"widthGate"}
                              {...field}
                              id="widthGate"
                              placeholder={"ej: 3"}
                            />
                            <InputRightAddon
                              color={"black"}
                              children={
                                tool.uom === "INTERNACIONAL"
                                  ? millimeters
                                  : inches
                              }
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.widthGate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {tool.gateForm === "RECTANGULAR" && (
                    <Field name="heightGate" validate={validateFloat}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.heightGate && form.touched.heightGate
                          }
                          mb={5}
                          paddingLeft={paddingXinputs}
                          paddingRight={paddingXinputs}
                        >
                          <FormLabel color={textColor} htmlFor="heightGate">
                            Altura de la compuerta
                          </FormLabel>
                          <InputGroup borderColor={borderColor}>
                            <Input
                              bgColor={bgColor}
                              type={"heightGate"}
                              {...field}
                              id="heightGate"
                              placeholder={"ej: 3"}
                            />
                            <InputRightAddon
                              color={"black"}
                              children={
                                tool.uom === "INTERNACIONAL"
                                  ? millimeters
                                  : inches
                              }
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.heightGate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {tool.gateForm === "RECTANGULAR" && (
                    <Field name="deepGate" validate={validateFloat}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.deepGate && form.touched.deepGate
                          }
                          mb={5}
                          paddingLeft={paddingXinputs}
                        >
                          <FormLabel color={textColor} htmlFor="deepGate">
                            Grosor de la compuerta
                          </FormLabel>
                          <InputGroup borderColor={borderColor}>
                            <Input
                              bgColor={bgColor}
                              type={"deepGate"}
                              {...field}
                              id="deepGate"
                              placeholder={"ej: 3"}
                            />
                            <InputRightAddon
                              color={"black"}
                              children={
                                tool.uom === "INTERNACIONAL"
                                  ? millimeters
                                  : inches
                              }
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.deepGate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  <GridItem colSpan={3}></GridItem>
                  <FormControl
                    id={"toolHydraulicSystem"}
                    as="fieldset"
                    mb={5}
                    isRequired
                  >
                    <FormLabel color={textColor} as="legend">
                      Sistema Hidraulico
                    </FormLabel>
                    <RadioGroup
                      color={textColor}
                      onChange={(e) =>
                        setTool({ ...tool, toolHydraulicSystem: e })
                      }
                      defaultValue="SI"
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="SI">
                          Si
                        </Radio>
                        <Radio bgColor={bgColor} value="NO">
                          No
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  {/*  Sistema Neumatico toolPneumaticSystem */}
                  <FormControl
                    id={"toolPneumaticSystem"}
                    as="fieldset"
                    mb={5}
                    isRequired
                  >
                    <FormLabel color={textColor} as="legend">
                      Sistema Neumatico
                    </FormLabel>
                    <RadioGroup
                      color={textColor}
                      onChange={(e) =>
                        setTool({ ...tool, toolPneumaticSystem: e })
                      }
                      defaultValue="SI"
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="SI">
                          Si
                        </Radio>
                        <Radio bgColor={bgColor} value="NO">
                          No
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    id={"toolVacummSystem"}
                    as="fieldset"
                    mb={5}
                    isRequired
                  >
                    <FormLabel color={textColor} as="legend">
                      Sistema Vacio
                    </FormLabel>
                    <RadioGroup
                      color={textColor}
                      onChange={(e) =>
                        setTool({ ...tool, toolVacummSystem: e })
                      }
                      defaultValue="SI"
                    >
                      <HStack spacing="24px">
                        <Radio bgColor={bgColor} value="SI">
                          Si
                        </Radio>
                        <Radio bgColor={bgColor} value="NO">
                          No
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>

                  <GridItem colSpan={3}></GridItem>
                  <Button
                    colorScheme="whatsapp"
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </Button>
                  <GridItem />
                  <Button
                    colorScheme="teal"
                    disabled
                    isLoading={props.isSubmitting}
                  >
                    Limpiar
                  </Button>
                </CustomGrid>
              </Form>
            </Flex>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default CreateMold;
