import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { Button, Grid, GridItem, Spinner } from "@chakra-ui/react";
import EditBase from "../../Cards/EditBase";
import RunnerForm from "./forms/RunnerForm";
import CavityForm from "./forms/CavityForm";
import GapForm from "./forms/GapForm";
import { useEditProjectedAreaHooks } from "../../../../../../hooks/projected-area-forms/useEditProjectedAreaHooks";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import {
  inSquareMmSquare,
  tonInSquareKnMmSquare,
  tonKn,
} from "../../../../individual/hooks/useUomTransform";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import { Helmet } from "react-helmet";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {metaDescription, metaKeywords, metaTitle} from "./utilities/metaVariables";

const EditProjectedArea = () => {
  const [conditionals, setConditionals] = useState({
    min: "",
    nom: "",
    max: "",
  });
  const { id } = useParams();
  const {
    project,
    updateProject,
    resin,
    isLoading,
    handleInputChange,
    machine,
  } = useContext(ProjectContext);
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);
  const selectColor = (suggested, type) => {
    let suggestedInt = parseInt(suggested);
    let machineValueInt = parseInt(machine.machineTonnage);
    if (suggestedInt < machineValueInt) {
      return { color: "green", error: "" };
    } else if (machineValueInt === suggestedInt) {
      return {
        color: "yellow",
        error: `El tonelaje de la Maquina (${machine.machineTonnage}), es muy cercano al tonelaje ${type} sugerido`,
      };
    } else {
      return {
        color: "red",
        error: `El tonelaje de la Maquina (${machine.machineTonnage}), es menor al tonelaje ${type} sugerido`,
      };
    }
  };

  useEffect(() => {
    // Calcula el color y el error para el tonelaje mínimo sugerido
    const minResult = selectColor(project.minTonSug, "minimo");
    // Calcula el color y el error para el tonelaje nominal sugerido
    const nomResult = selectColor(project.nomTonSug, "nominal");
    // Calcula el color y el error para el tonelaje máximo sugerido
    const maxResult = selectColor(project.maxTonSug, "maximo");

    // Actualiza el estado conditionals con los nuevos errores, si los hay
    setConditionals((prevConditionals) => ({
      ...prevConditionals,
      min: minResult.error || "", // Si no hay error, se establece una cadena vacía
      nom: nomResult.error || "", // Si no hay error, se establece una cadena vacía
      max: maxResult.error || "", // Si no hay error, se establece una cadena vacía
    }));
  }, [
    project.minTonSug,
    project.nomTonSug,
    project.maxTonSug,
    machine.machineTonnage,
  ]); // Incluye todas las dependencias relevantes

  const {
    setShowRunnerArea,
    setShowCavityArea,
    showGapArea,
    showRunnerArea,
    showCavityArea,
    setShowGapArea,
    disabled,
    canFinish,
    showData,
    onSaveInsuranceFactor,
    handleClick,
    isFloatString,
    setShowData,
    finish,
    setFinish,
  } = useEditProjectedAreaHooks();

  const textColor = "gray.600";
  const borderColor = "gray.400";

  if (!project || !project.runners) {
    return <Spinner />;
  }

  const ShowErrorAlert = () => {
    let text = "";
    let icon = "success";
    if (conditionals.min) {
      text += `<li>${conditionals.min}</li>`;
      icon = "warning";
    }
    if (conditionals.nom) {
      text += `<li>${conditionals.nom}</li>`;
      icon = "warning";
    }
    if (conditionals.max) {
      text += `<li>${conditionals.max}</li>`;
      icon = "warning";
    }
    conditionalsAlert(icon, text);
  };

  const onFinish = () => {
    onSaveInsuranceFactor();
    ShowErrorAlert();
  };

  const cancelShowDataHandler = () => {
    setShowData(false);
    setFinish(false);
  };

  return (
    <>
      <HelmetComponent description={metaDescription} keywords={metaKeywords} title={metaTitle} />
      <EditBase
        title={"Optimización de Área Proyectada y Análisis de Tonelaje"}
        disabled={!canFinish}
      >
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
        </GridItem>
        {showData ? (
          <>
            <GridItem colSpan={4}>
              <Grid
                templateColumns="repeat(3, 1fr)"
                justifyItems={"space-between"}
                gap={6}
                color={textColor}
              >
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  rightAddon={inSquareMmSquare(project?.uom)}
                  value={project.projectedArea}
                  type={"weight"}
                  title={"Área Proyectada"}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"weight"}
                  rightAddon={tonInSquareKnMmSquare(project?.uom)}
                  value={resin.tonnageFactorMin}
                  title={"Factor de tonelaje Mínimo"}
                />
                <DataDisplayBadge
                  color={textColor}
                  badgeColorScheme={"blue"}
                  type={"weight"}
                  rightAddon={tonInSquareKnMmSquare(project?.uom)}
                  value={resin.tonnageFactorMax}
                  title={"Factor de Tonelaje Máximo"}
                />
              </Grid>
            </GridItem>
          </>
        ) : (
          <>
            <GridItem colSpan={4} width={"100%"}>
              <Grid
                templateColumns="repeat(3, 1fr)"
                justifyItems={"space-between"}
                gap={6}
                color={textColor}
              >
                <RunnerForm
                  showRunnerArea={showRunnerArea}
                  setShowRunnerArea={setShowRunnerArea}
                />
                <CavityForm
                  showCavityArea={showCavityArea}
                  setShowCavityArea={setShowCavityArea}
                />
                <GapForm
                  showGapArea={showGapArea}
                  setShowGapArea={setShowGapArea}
                />
              </Grid>
            </GridItem>
          </>
        )}

        <GridItem colSpan={4}>
          <Button
            colorScheme={"facebook"}
            disabled={disabled}
            onClick={showData ? () => cancelShowDataHandler() : handleClick}
          >
            {showData ? "Editar" : "Continuar"}
          </Button>
        </GridItem>

        {showData && (
          <>
            <GridItem colSpan={4} mt={"30px"}>
              <InputCustomFieldProjectRigthAddon
                color={textColor}
                id={"insuranceFactor"}
                value={project.insuranceFactor}
                onChange={handleInputChange}
                type={"text"}
                addOn={"%"}
                label={"Factor de Aseguramiento"}
                borderColor={borderColor}
                disabled={finish}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <Button
                colorScheme={"facebook"}
                isLoading={isLoading}
                onClick={finish ? () => setFinish(false) : onFinish}
                disabled={!isFloatString(project.insuranceFactor) || isLoading}
                mt={5}
              >
                {finish ? "Editar" : "Calcular y Guardar"}
              </Button>
            </GridItem>
            {finish && (
              <GridItem colSpan={4} my={5}>
                <Grid
                  templateColumns="repeat(3, 1fr)"
                  justifyItems={"space-between"}
                  gap={6}
                  color={textColor}
                >
                  <DataDisplayBadge
                    color={textColor}
                    badgeColorScheme={() =>
                      selectColor(project.minTonSug, "minimo").color
                    }
                    title={"Tonelaje Mínimo Sugerido"}
                    value={project.minTonSug}
                    rightAddon={tonKn(project?.uom)}
                  />
                  <DataDisplayBadge
                    color={textColor}
                    badgeColorScheme={() =>
                      selectColor(project.nomTonSug, "minimo").color
                    }
                    title={"Tonelaje Nominal Sugerido"}
                    value={project.nomTonSug}
                    rightAddon={tonKn(project?.uom)}
                  />
                  <DataDisplayBadge
                    color={textColor}
                    badgeColorScheme={() =>
                      selectColor(project.maxTonSug, "minimo").color
                    }
                    title={"Tonelaje Máximo Sugerido"}
                    value={project.maxTonSug}
                    rightAddon={tonKn(project?.uom)}
                  />
                </Grid>
              </GridItem>
            )}
          </>
        )}
      </EditBase>
    </>
  );
};

export default EditProjectedArea;
