export const customStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    borderColor: '#CBD5E0', // Color de borde basado en Chakra UI
    '&:hover': {
      borderColor: '#A0AEC0', // Color de borde al pasar el mouse, basado en Chakra UI
    }
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? '#EBF8FF' : null, // Color de fondo de la opción enfocada
    color: '#1A202C' // Color del texto de las opciones
  }),
};