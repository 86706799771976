import { Button, Grid, Image, Link, Text } from "@chakra-ui/react";

const AsaCleanBodyComponent2 = ({ content }) => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} justifyItems={"center"} my={10} justifyContent={"center"} alignItems={"center"}>
      <Grid>
        {content && (
          <>
            <Text fontWeight={"bold"} fontSize={"2xl"}>
              {content.title}
            </Text>
            <Text>{content.content}</Text>
            <Text fontWeight={"bold"} fontSize={"2xl"}>
              {content.footer}
            </Text>
          </>
        )}
      </Grid>
      <Grid position="relative">
        <Image src={content.image} borderRadius={15} />
        <Link href={content.url} isExternal>
          <Button
            position="absolute"
            top="90%"
            left="80%"
            transform="translate(-50%, -50%)"
            colorScheme="red"
          >
            Conozca Más
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default AsaCleanBodyComponent2;
