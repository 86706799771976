import IndividualBase from "../../components/IndividualBase";
import { HeaderProvider } from "../../../../../contexts/IndividualContext/HeaderContext";
import IntensificationRatioForm from "./components/IntensificationRatioForm";
import HelmetComponent from "../../../../../components/HelmetComponent";
import React from "react";
import {
  metaDescription,
  metaTitle,
  metaKeywords,
} from "./utilities/metaVariables";
import { GridItem } from "@chakra-ui/react";
import CustomGrid from "../../components/CustomGrid";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import DescriptionAndInstructionAcordeon from "../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
const IndividualIntensificationRatio = () => {
  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <HeaderProvider>
        <GridItem width={"100%"} mt={"120px"}>
          <CustomGrid>
            <IndividualBase instructions={instructions} description={description} title={"Razón de Intensificación"}>
              <IntensificationRatioForm />
            </IndividualBase>
          </CustomGrid>
        </GridItem>
      </HeaderProvider>
    </>
  );
};

export default IndividualIntensificationRatio;
