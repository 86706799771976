import CustomInput from "../../../components/CustomInput";
import { Grid, GridItem, Text } from "@chakra-ui/react";
import { inMm, ozGr } from "../../../hooks/useUomTransform";
import React from "react";

const Form = ({ onChange, data, disabled }) => {
  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      gap={6}
      width={"100%"}
      my={10}
      justifyContent={"center"}
    >
      <GridItem colSpan={4}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CustomInput
            error={
              parseFloat(data?.setBackPressure) <= 0 &&
              "Este Campo debe ser mayor a 0"
            }
            label={"Contrapresión"}
            value={data?.setBackPressure}
            id={"setBackPressure"}
            onChange={onChange}
          />
          <CustomInput
            label={"Velocidad de Roración"}
            value={data?.setRPM}
            id={"setRPM"}
            onChange={onChange}
            error={
              parseFloat(data?.setRPM) <= 0 && "Este Campo debe ser mayor a 0"
            }
          />
          <CustomInput
            label={"Velocidad de Inyección Test"}
            value={data?.injectionSpeedTest}
            id={"injectionSpeedTest"}
            onChange={onChange}
            error={
              parseFloat(data?.injectionSpeedTest) <= 0 &&
              "Este Campo debe ser mayor a 0"
            }
          />
        </Grid>
      </GridItem>
      <GridItem>
        <Text>Secuencia</Text>
        {data?.shotWeight.map((item, index) => {
          return (
            <CustomInput
              key={`input-repeatibility-${index}`}
              value={item.secuence}
              disabled={true}
            />
          );
        })}
      </GridItem>
      <GridItem>
        <Text>Peso de 1a Etapa con Runner</Text>
        {data?.shotWeight.map((item, index) => {
          return (
            <CustomInput
              disabled={disabled}
              name={`shotWeight-${index}`}
              placeholder={`shotWeight-${index + 1}`}
              value={item.value}
              onChange={onChange}
              addOnText={ozGr(data?.header?.uom)}
              error={
                parseFloat(item.value) <= 0 && "Este Campo debe ser mayor a 0"
              }
            />
          );
        })}
      </GridItem>
      <GridItem>
        <Text>Tiempo de Llenado</Text>
        {data?.fillTime.map((item, index) => {
          return (
            <CustomInput
              disabled={disabled}
              name={`fillTime-${index}`}
              placeholder={`fillTime-${index + 1}`}
              value={item.value}
              onChange={onChange}
              addOnText={"sec"}
              error={
                parseFloat(item.value) <= 0 && "Este Campo debe ser mayor a 0"
              }
            />
          );
        })}
      </GridItem>
      <GridItem>
        <Text>Transferencia V/P</Text>
        {data?.vpTransfer.map((item, index) => {
          return (
            <CustomInput
              disabled={disabled}
              name={`vpTransfer-${index}`}
              placeholder={`vpTransfer-${index + 1}`}
              value={item.value}
              onChange={onChange}
              addOnText={inMm(data?.header?.uom)}
              error={parseFloat(item.value) <= 0 && true}
            />
          );
        })}
      </GridItem>
    </Grid>
  );
};

export default Form;
