const instructions = [
  "1. Previamente definido el 95-98% de Inyección en Primera Etapa de Llenado, debemos reducir a cero, o al mínimo permitido por la Máquina de Moldeo, la Segunda Etapa de Inyección.",
  "2. Verificar que el Tiempo de Enfriamiento sea suficiente para expulsar la pieza. Se recomienda agregar el tiempo que de la Segunda Etapa de Inyección que se eliminó al Tiempo de Enfriamiento, para asegurar el enfriamiento de la Pieza Moldeada.",
  "3. Ajuste la Velocidad de Inyección conforme los resultados del Estudio de Viscosidad. Utilizando el mejor rango de resultados capturando el valor minimo y maximo, para poder crear una nueva discriminación de resultados.",
  "4. Inicie el Proceso de Moldeo, espere de 3 a 5 tiros y pese el tiro completo por Cavidades separadas y anótelo en el espacio asignado para cada cavidad. Registre los resultados en la columna correspondiente. El Runner puede ser agregado al experimento para conocer el Peso Total de Disparo y tomar consideraciones en dado caso de que esté presente inconsistencias.",
  "5. Registre el Tiempo de Inyección resultante en la casilla correspondiente.",
  "6. Deberá seleccionar la Velocidad de Inyección con mejor balance obtenido, el cual será marcado con celda en Color Verde.",
  "Balance de Disparo: La velocidad óptima para mantener la similitud de llenado en cada Cavidad y la afectación al grupo de Cavidades dependiente de la variación de la Velocidad de Inyección.",
  "Balance de Cavidad: La variación individual de cada una de las Cavidades, para poder decidir si es necesario un ajuste individual, así como la sensibilidad individual al cambio de Velocidad de Inyección",
  "La Gráfica nos permitirá observar el equilibrio en ambos tipos de Balance.",
];

export default instructions;
