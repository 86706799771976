import React from "react";
import { Flex, Grid, Heading, Image, Text } from "@chakra-ui/react";
import mvpsPiezas from "../../assets/img/mvpspiezas5.avif";
import HorizontalDivider from "../../components/dividers/HorizontalDivider";
import toolsAndResourcesImage from "../../assets/img/herramientas_y_recursos.avif";
import moldingProcessImage from "../../assets/img/molding_process.avif";
import processValidationImage from "../../assets/img/validacion_de_procesos.avif";
import mvpsLogo from "../../variables/s3-images";
import { Helmet } from "react-helmet";

function LandingPage() {
  return (
    <>
      <Helmet>
        <title>Soluciones de Validación de Procesos de Moldeo | MVPS</title>
        <link rel="canonical" href="https://molding-vps.com/banner/pages/landing"/>

        <meta
          name="description"
          content="MVPS ofrece soluciones integrales para la validación de procesos de moldeo en la industria del plástico. Descubre nuestras herramientas, capacitaciones y recursos especializados para optimizar tus operaciones de moldeo."
        />
        <meta
          name="keywords"
          content="validación de procesos de moldeo, industria del plástico, herramientas de moldeo, capacitación en moldeo, soluciones de moldeo, mvps"
        />
        <meta
          property="og:title"
          content="Soluciones de Validación de Procesos de Moldeo | MVPS"
        />
        <meta
          property="og:description"
          content="Descubre cómo MVPS puede transformar tus procesos de moldeo con herramientas avanzadas y capacitación especializada en la industria del plástico."
        />
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://molding-vps.com"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta
          name="twitter:title"
          content="Soluciones de Validación de Procesos de Moldeo | MVPS"
        />
        <meta
          name="twitter:description"
          content="MVPS es tu socio para la optimización de procesos de moldeo en la industria del plástico. Explora nuestras herramientas y programas de capacitación."
        />
      </Helmet>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Grid
          mt={"150px"}
          p={5}
          mx={20}
          borderWidth={1}
          color={"white"}
          borderRadius={15}
          alignItems={"center"}
          justifyItems={"center"}
          opacity={0.9}
        >
          <Heading mt={5}>MOLDING VALIDATION PROCESS SOLUTIONS</Heading>
          <Grid height={"200"} alignItems={"center"} justifyItems={"center"}>
            <Image
              alt={"mvps-logo"}
              src={mvpsLogo}
              mt={5}
              width={"40%"}
              min-height={0}
            />
          </Grid>

          <Heading as={"h1"} fontWeight={"bold"} fontSize={"xl"} mt={2}>
            Somos la solución a tus problemas de moldeo
          </Heading>
          <Grid height={"500"} alignItems={"center"} justifyItems={"center"}>
            <Image
              alt={"mvps-piezas"}
              src={mvpsPiezas}
              mt={5}
              width={"600"}
              height={"400"}
            />
          </Grid>
          <HorizontalDivider mt={5} />
          <Text fontWeight={"bold"} fontSize={"xl"}>
            Técnicas Plásticas
          </Text>
          <HorizontalDivider mt={5} />
          <Text fontWeight={"bold"} fontSize={"xl"}>
            Mapa del Sitio
          </Text>
          <Text fontSize={"xl"}>En construccion</Text>
          <HorizontalDivider mt={5} />
          <Grid
            borderWidth={1}
            py={5}
            templateColumns="repeat(2, 1fr)"
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            borderRadius={15}
          >
            <Grid justifyItems={"center"} alignItems={"center"} height={"300"}>
              <Image
                alt={"mvps-tools-and-resources"}
                src={toolsAndResourcesImage}
                width={"60%"}
                borderRadius={15}
              />
            </Grid>

            <Grid justifyItems={"center"} alignItems={"center"}>
              <Text fontWeight={"bold"} fontSize={"xl"}>
                Herramientas y Recursos
              </Text>
              <br />
              <Text fontSize={"xl"}>
                Ofrecemos una amplia variedad de herramientas y recursos para el
                mundo del plástico, incluyendo fórmulas, ejercicios y
                actualizaciones. Todo lo que necesitas para crear procesos de
                moldeo de alta calidad.{" "}
              </Text>
            </Grid>
          </Grid>
          <HorizontalDivider mt={5} />
          <Grid
            borderWidth={1}
            py={5}
            templateColumns="repeat(2, 1fr)"
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            borderRadius={15}
          >
            <Grid justifyItems={"center"} alignItems={"center"}>
              <Text fontWeight={"bold"} fontSize={"xl"}>
                Procesos de Moldeo
              </Text>
              <br />
              <Text fontSize={"xl"}>
                Nuestro software es la solución perfecta para los problemas de
                moldeo. Con herramientas, ejercicios y actualizaciones, podemos
                ayudarte a lograr la elaboración del proceso perfecto.
              </Text>
            </Grid>
            <Grid justifyItems={"center"} alignItems={"center"} height={"300"}>
              <Image
                alt={"mvps-molding-process"}
                src={moldingProcessImage}
                mt={5}
                width={"60%"}
                borderRadius={15}
              />
            </Grid>
          </Grid>
          <Grid
            borderWidth={1}
            py={5}
            templateColumns="repeat(2, 1fr)"
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            borderRadius={15}
          >
            <Grid justifyItems={"center"} alignItems={"center"} height={"300"}>
              <Image
                alt={"mvps-process-validation"}
                src={processValidationImage}
                width={"60%"}
                borderRadius={15}
              />
            </Grid>

            <Grid justifyItems={"center"} alignItems={"center"}>
              <Text fontWeight={"bold"} fontSize={"xl"}>
                Validación de Procesos
              </Text>
              <br />
              <Text fontSize={"xl"}>
                Nuestro software de validación de procesos de moldeo es la
                respuesta a tus necesidades de validación de procesos. Con estas
                herramientas y ejercicios, podemos ayudarte a validar tus
                procesos de manera efectiva.
              </Text>
            </Grid>
          </Grid>
          <HorizontalDivider mt={5} />
          <Grid
            borderWidth={1}
            py={5}
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            borderRadius={15}
            height={"170"}
          >
            <Text fontWeight={"bold"} fontSize={"xl"}>
              Acerca de Nosotros
            </Text>
            <br />
            <Text fontSize={"xl"}>
              Somos una página web dedicada a colaborar con los profesionales
              del plástico para desarrollar procesos de moldeo eficientes.
              Ofrecemos herramientas, ejercicios y capacitación para la
              validación de procesos y solución de problemas.
            </Text>
          </Grid>
          <Grid
            borderWidth={1}
            py={5}
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            borderRadius={15}
            height={"350"}
          >
            <Text fontWeight={"bold"} fontSize={"xl"}>
              OBJETIVO
            </Text>
            <br />
            <Text fontSize={"xl"}>
              La página moldingvps.com ha sido creada con la finalidad de ayudar
              a la familia de moldeadores de todo el mundo, mediante ejercicios
              prácticos, explicaciones breves y concretas, así como el análisis
              y creación de formatos que nos hagan más eficiente el desarrollo
              de procesos de moldeo en la Industria del Plástico.
            </Text>
            <br />
            <Text fontSize={"xl"}>
              El desarrollo de Programas, Formatos y Reportes totalmente
              personalizados para cada empresa particular, comprometidos con la
              eficiencia de los procesos internos y el control de la información
              y herramientas relacionadas con los Procesos de Moldeo.
            </Text>
          </Grid>
          <Grid
            borderWidth={1}
            py={5}
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            height={"210"}
            borderRadius={15}
          >
            <Text fontWeight={"bold"} fontSize={"xl"}>
              META
            </Text>
            <br />
            <Text fontSize={"xl"}>
              Ofrecer servicios de capacitación y desarrollo de calidad mediante
              el desarrollo ejercicios Teóricos y Prácticos y Empresas de
              Capacitación Independientes especializadas en el ramo, ofreciendo
              también servicios de atención personalizada para Lanzamientos y
              Solución de Problemas en las empresas.
            </Text>
          </Grid>
          <Grid
            borderWidth={1}
            py={5}
            gap={2}
            justifyItems={"center"}
            alignItems={"center"}
            mx={5}
            px={5}
            my={5}
            borderRadius={15}
            height={"180"}
          >
            <Text fontWeight={"bold"} fontSize={"xl"}>
              VISIÓN
            </Text>
            <br />
            <Text fontSize={"xl"}>
              Ser una empresa con alcance global, líder en la solución de
              problemas en la Industria del Plástico, desarrollando la
              excelencia de los usuarios mediante la capacitación, práctica y
              sistematización de las técnicas y análisis científico de los
              Procesos de Moldeo.
            </Text>
          </Grid>
        </Grid>
      </Flex>
    </>
  );
}

export default LandingPage;
