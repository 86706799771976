import { Text, View } from "@react-pdf/renderer";
import React from "react";

const CostAnalisisPdf = ({ data, styles }) => {
  return (
    <>
      <Text
        style={[
          styles.header,
          data?.analisisComments === "" && { marginTop: "70px" },
        ]}
      >
        {"Analisis de Costos"}
      </Text>
      <View
        style={{
          borderLeft: 1,
          borderTop: 1,
          borderRight: 1,
          width: "90%",
          padding: "9px",
          marginLeft: "5%",
          marginRight: "5%",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View>
          <Text
            style={[styles.text, { fontSize: 10 }]}
          >{`Moneda: ${data?.currency}`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={[styles.text, { fontSize: 9 }]}>{`Requerimiento`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>{`Resina Virgen:`}</Text>
          <Text style={styles.text}>{`Material Recuperado:`}</Text>
          <Text style={styles.text}>{`Otros Aditivos:`}</Text>
          <Text style={styles.text}>{`Aditivo Einar 201:`}</Text>
          <Text style={styles.text}>{`Total:`}</Text>
        </View>
        <View>
          <Text style={[styles.text, { fontSize: 12 }]}> </Text>
          <Text style={[styles.text, { fontSize: 10 }]}> </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaVirginResin).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRegrindResin).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaOtherAdditives).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaEinarPercent).toFixed(2)} %
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.totalCost).toFixed(2)} %
          </Text>
        </View>
        <View>
          <Text
            style={[styles.text, { fontSize: 10 }]}
          >{`Tipo de Cambio: $${data?.exchange}`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={[styles.text, { fontSize: 9 }]}>{`Sin Einar`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaVirginPartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRegrindPartWeight_2).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaAdditivePartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {`0.0000`} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.totalCostWithOutEinar).toFixed(4)} {data?.geaUdM}
          </Text>
        </View>
        <View>
          <Text style={[styles.text, { fontSize: 10 }]}>
            {`Peso de Pieza: ${data?.geaPartWeight} ${data?.geaUdM}`}{" "}
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={[styles.text, { fontSize: 9 }]}>{`Con Einar`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaVirginPartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRegrindPartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaAdditivePartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaEinarPartWeight).toFixed(4)} {data?.geaUdM}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.totalCostWithEinar).toFixed(4)} {data?.geaUdM}
          </Text>
        </View>
        <View>
          <Text style={[styles.text, { fontSize: 10 }]}> </Text>
          <Text style={styles.text}> </Text>

          <Text style={[styles.text, { fontSize: 9 }]}>{`Costo Unitario`}</Text>
          <Text style={styles.text}> </Text>

          <Text style={styles.text}>
            {parseFloat(data?.geaVirginResinCost).toFixed(2)}{" "}
            {`${data?.currency}/${data?.geaUdM}`}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaRegrindResinCost).toFixed(2)}{" "}
            {`${data?.currency}/${data?.geaUdM}`}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaOtherAdditivesCost).toFixed(2)}{" "}
            {`${data?.currency}/${data?.geaUdM}`}
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaEinarPercentCost).toFixed(2)}{" "}
            {`${data?.currency}/${data?.geaUdM}`}
          </Text>
          <Text style={styles.text}> </Text>
        </View>
      </View>
      <View
        style={{
          borderBottom: 1,
          borderLeft: 1,
          borderRight: 1,
          width: "90%",
          padding: "9px",
          marginLeft: "5%",
          marginRight: "5%",
          marginBottom: "10px",
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View>
          <Text style={[styles.text, { fontSize: 10 }]}>
            Volumen de producción
          </Text>
          <Text style={styles.text}>{data?.geaSampleSize} pzs</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>{"Costo de Peso de Pieza"}</Text>
          <Text style={styles.text}>{"Tiempo de Producción"}</Text>
          <Text style={styles.text}>{"Uso de Desmoldante"}</Text>
          <Text style={styles.text}>{"Tiempo Muerto Estimado"}</Text>
          <Text style={styles.text}>{"Scrap Estimado"}</Text>
          <Text style={styles.text}>{"Consumo de Energía"}</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>{"Ahorro Total"}</Text>
        </View>
        <View>
          <Text style={[styles.text, { fontSize: 10 }]}>
            Costo de Desmoldante
          </Text>
          <Text
            style={styles.text}
          >{`${data?.MoldReleaseCost} ${data?.currency}`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaPartCost).toFixed(4)} {data?.currency}.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaProdTimeBefore).toFixed(2)} Hrs.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaMoldReleaseQtyBefore).toFixed(1)} Latas.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaDownTimeBefore).toFixed(2)} min.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaScrapBefore).toFixed(1)} pzs.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaKwCostBefore).toFixed(2)} kWh.
          </Text>
        </View>
        <View>
          <Text style={[styles.text, { fontSize: 10 }]}>
            Costo de Hora de Producción
          </Text>
          <Text
            style={styles.text}
          >{`${data?.ProdHourCost} ${data?.currency}`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaPartCostEinar).toFixed(4)} {data?.currency}.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaProdTimeAfter).toFixed(2)} Hrs.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaMoldReleaseQtyAfter).toFixed(1)} Latas.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaDownTimeAfter).toFixed(2)} min.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaScrapAfter).toFixed(1)} pzs.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaKwCostAfter).toFixed(2)} kWh.
          </Text>
        </View>
        <View>
          <Text style={[styles.text, { fontSize: 10 }]}>
            Costo de Kilowatt Hora
          </Text>
          <Text style={styles.text}>{`${data?.kwCost} ${data?.currency}`}</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaPartCostDelta).toFixed(4)} {data?.currency}.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaProdTimeDelta).toFixed(2)} Hrs.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaMoldReleaseQtyDelta).toFixed(1)} Latas.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaDownTimeDelta).toFixed(2)} min.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaScrapDelta).toFixed(1)} pzs.
          </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaKwCostDelta).toFixed(2)} kWh.
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            {parseFloat(data?.geaTotalCostDelta).toFixed(2)} {data?.currency}.
          </Text>
        </View>
      </View>
    </>
  );
};

export default CostAnalisisPdf;
