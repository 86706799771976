import { useContext, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";

export const useCoolingTimeHooks = () => {
  const [showData, setShowData] = useState(false);
  const [final, setFinal] = useState(false);

  const { project, resin, updateProject, updateLoading } = useContext(
    ProjectContext
  );

  const { thermalDiffusion } = resin;
  const {
    realMeltTemp,
    max_Wall_Thickness,
    tool_A_Side_Real_Temp,
    tool_B_Side_Real_Temp,
    ejection_Temp_Required,
    cooling_Time_Select,
  } = project;

  const onSubmitHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "coolingTime",
      path: "/cooling-time",
      options: {
        body: {
          thermalDiffusion,
          realMeltTemp,
          max_Wall_Thickness,
          tool_B_Side_Real_Temp,
          ejection_Temp_Required,
        },
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();

    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.cooling_Time_Suggested = res.cooling_Time_Suggested.toString();
        updated.realMeltTemp = realMeltTemp.toString();
        updated.max_Wall_Thickness = max_Wall_Thickness.toString();
        updated.tool_B_Side_Real_Temp = tool_B_Side_Real_Temp.toString();
        updated.tool_A_Side_Real_Temp = tool_A_Side_Real_Temp.toString();
        updated.ejection_Temp_Required = ejection_Temp_Required.toString();
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowData(true);
        updateLoading(false);
      });
  };

  const onSubmitFinal = async () => {
    updateLoading(true);
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.cooling_Time_Select = cooling_Time_Select.toString();
        updated.phase = "14";
      })
    )
      .then((res) => {
        updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFinal(true);
        updateLoading(false);
      });
  };
  return {
    onSubmitFinal,
    showData,
    onSubmitHandler,
    setShowData,
    setFinal,
    final,
  };
};
