import { Grid, GridItem, Text } from "@chakra-ui/react";
import { inMm } from "../../../hooks/useUomTransform";
import React from "react";
import { badgeColor } from "../../../../project-overview/components/utilities/variables";
import { textColor } from "../../../utilities/variabled";

const Results = ({ result, data }) => {
  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={6} alignItems={"center"}>
      <GridItem>
        <Text
          mb={2}
          pb={1}
          textAlign={"center"}
          color={textColor}
          fontWeight={"bold"}
        >
          Promedio
        </Text>
        <Text
          pb={1}
          textAlign={"center"}
          color={textColor}
          fontWeight={"bold"}
          mb={2}
        >
          Mínimo
        </Text>
        <Text
          pb={1}
          textAlign={"center"}
          color={textColor}
          fontWeight={"bold"}
          mb={2}
        >
          Máximo
        </Text>
        <Text
          pb={1}
          textAlign={"center"}
          color={textColor}
          fontWeight={"bold"}
          mb={2}
        >
          Diferencia
        </Text>
      </GridItem>
      <GridItem>
        <GridItem />
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.shotWeightResults?.average).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.shotWeightResults?.min).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.shotWeightResults?.max).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.shotWeightResults?.diff).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
      </GridItem>
      <GridItem>
        <GridItem />
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.fillTimeResults?.average).toFixed(4)} sec
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.fillTimeResults?.min).toFixed(4)} sec
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.fillTimeResults?.max).toFixed(4)} sec
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.fillTimeResults?.diff).toFixed(4)} sec
        </Text>
      </GridItem>
      <GridItem>
        <GridItem />
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.vpTransferResults?.average).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.vpTransferResults?.min).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.vpTransferResults?.max).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
        <Text
          textAlign={"center"}
          mb={2}
          px={2}
          borderWidth={2}
          bgColor={`${badgeColor}.100`}
          borderColor={"blue"}
          color={textColor}
          borderRadius={15}
        >
          {parseFloat(result?.vpTransferResults?.diff).toFixed(4)} {inMm(data?.header.uom)}
        </Text>
      </GridItem>
    </Grid>
  );
};

export default Results;
