import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Grid,
  Heading,
  Text,
} from "@chakra-ui/react";
import { HSeparator } from "../../../components/Separator/Separator";
import React from "react";

const Temario = () => {
  const textColor = "gray.600";
  return (
    <>
      <Accordion
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
        px={10}
        allowToggle
        mb="16px"
      >
        <Grid justifyContent={"center"}>
          <Heading color={"#3d6399"} alignSelf={"center"}>
            CONOCE NUESTROS TEMARIOS PRINCIPALES
          </Heading>
        </Grid>
        <AccordionItem color={"white"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                ARRANQUE DE PROCESO DE INYECCIÓN DE PLÁSTICOS
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación esta dirigida para Operadores, Técnicos,
              Supervisores y cualquier persona interesada en el correcto
              funcionamiento, arranque y puesta a punto de los Procesos de
              Moldeo por Inyección.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Bases Matemáticas para el cálculo de operaciones.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Tener un conocimiento asertivo para desarrollar un arranque de
              máquina correcto y ajustes efectivos para los Proceso de Moldeo
              por Inyección de los principales tipos de plásticos.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Arranque de Proceso de Inyección de Plásticos
              tiene como objetivo proporcionar una descripción detallada de las
              técnicas y los procedimientos necesarios para poder iniciar
              correctamente el Proceso de Inyección de Plásticos.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante esta capacitación, todos los participantes aprenderán los
              conceptos básicos del Proceso de Moldeo por Inyección, incluyendo
              la selección de materiales, diseño de moldes, ajuste de parámetros
              de la máquina y control de calidad. Se explorarán diversas
              técnicas de inyección, así como los posibles problemas y
              soluciones durante el proceso.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Al finalizar el curso, todos los participantes estarán
              familiarizados con los fundamentos teóricos y prácticos del
              proceso de inyección de plásticos, podrán aplicar sus
              conocimientos en el desarrollo y mejora de sus propios procesos de
              producción.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                DETECCIÓN DE FALLAS EN MOLDES
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Técnicos de Moldeo, Técnicos
              de Taller, Tool Makers, Supervisores y personal involucrado con la
              producción y calidad de las Piezas Moldeadas.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Bases Matemáticas para el cálculo de operaciones.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Conocer y entender los principios básicos para el diseño del Plan
              de Mantenimeinto de moldes de inyección, detección de fallas
              comunes y análisis de causa raíz de fallas relacionadas con los
              Moldes y Herramientas.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación en Detección de Fallas en Moldes es fundamental
              para garantizar la calidad y eficiencia en los procesos de
              fabricación.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante el entrenamiento, los participantes aprenderán a
              identificar a tiempo y solucionar los problemas más comunes que
              pueden surgir en los moldes utilizados en la producción de Piezas
              Moldeadas. Estos conocimientos les permitirán ahorrar tiempo y
              recursos al evitar retrasos en la producción y reparaciones
              costosas.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Además, la capacitación también abordará técnicas de Mantenimiento
              Preventivo y Predictivo para prolongar la vida útil de los moldes
              y maximizar su rendimiento ya que les brinda las herramientas
              necesarias para mantener la calidad y eficiencia en sus procesos
              de producción.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                INYECCIÓN PARA PRINCIPIANTES
              </Text>
            </Box>
            <AccordionIcon color="#00" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Personal Técnico,
              Supervisores, Calidad, Finanzas, Operadores y cualquier persona
              interesada en las propiedades, ventajas, requerimientos,
              herramientas y recursos relacionados con los Procesos de Moldeo
              por Inyección.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer Bases Matemáticas para el cálculo de
              operaciones relacionadas con presiones, dimensiones, temperaturas
              y costos.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Tener un conocimiento con bases técnicas para desarrollar
              procesos, mejoras y ajustes efectivos para los Proceso de Moldeo
              por Inyección de los principales tipos de plásticos.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Inyección para Principiantes es una excelente
              oportunidad para aprender los fundamentos de la inyección de
              plástico. Durante este curso, los participantes adquirirán
              conocimientos básicos sobre los diferentes tipos de Máquinas de
              Inyección, los Materiales utilizados, los Procesos de Moldeo y las
              técnicas de manipulación de Moldes.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Además, se enseñarán las medidas de seguridad necesarias para
              operar estas máquinas de forma eficiente y segura. Los
              principiantes aprenderán cómo preparar el material, ajustar los
              parámetros de inyección y realizar las pruebas de calidad
              adecuadas. Esta capacitación proporcionará una base sólida para
              aquellos que deseen incursionar en la industria de la inyección de
              plástico y les dará las herramientas necesarias para comenzar su
              carrera con confianza y conocimiento.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                INYECCIÓN DE PLÁSTICOS Y MOLDEO CIENTÍFICO
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Técnicos de Moldeo,
              Ingenieros y Toolroom que dominen los conocimientos básicos
              Procesos de Moldeo por Inyección.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Bases Matemáticas para el cálculo de operaciones.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Al termino de esta capacitación los participantes aplicaran las
              técnicas avanzadas y conceptos para el proceso de inyección y
              moldeo de plásticos, para analizar y resolver los problemas que se
              presenten durante y después del proceso.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación en Inyección de Plásticos y Moldeo Científico es
              fundamental para aquellos que deseen adquirir mayores
              conocimientos y habilidades en este campo. Este tipo de
              capacitación proporciona a los participantes las herramientas
              necesarias para comprender los Procesos de Inyección de plásticos
              y moldeo, así como las técnicas científicas utilizadas en el
              diseño y fabricación de productos. Durante la capacitación, los
              participantes aprenderán sobre los diferentes tipos de plásticos
              utilizados en el proceso, así como sobre las técnicas de moldeo
              más eficientes. Aprenderán a utilizar maquinaria especializada,
              desarrollar Experimentos Específicos para cada etapa del proceso
              de inyección y a realizar pruebas de calidad para garantizar la
              producción de productos de alta calidad.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                CAMBIO DE MOLDE Y SMED
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Técnicos de Set Up, Técnicos
              de Moldeo, Operadores y todos los involucrados en el Ajuste
              Inicial de Herramientas.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Bases Matemáticas para el cálculo de operaciones.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Al término de esta capacitación, los participantes aplicaran las
              técnicas y conceptos para el Montaje de Moldes de Inyección, para
              analizar y solucionar los problemas que puedan presentarse durante
              el montaje y desmontaje de herramientas.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Montaje de Moldes y SMED está enfocada al
              desarrollo de aptitudes y técnicas que optimicen el uso de
              herramientas y recursos, reduciendo los tiempos de cambio de
              herramientas al mínimo posible.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              El montaje de moldes es una tarea precisa y delicada, que requiere
              de Conocimientos Técnicos Especializados. A través de esta
              capacitación, los trabajadores adquieren las habilidades
              necesarias para desmontar, limpiar y montar los moldes de manera
              eficiente y segura.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Además, profundizaremos en el sistema SMED (Single Minute Exchange
              of Die) es una metodología que busca reducir al mínimo el tiempo
              de cambio de moldes, lo que permite una mayor flexibilidad en la
              producción y una reducción de tiempo muerto.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                MANTENIMIENTO DE MOLDES
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Técnicos de Tool Room, Tool
              Makers y Reparadores de Moldes, asi como todo el personal tecnico
              que tenga relación directa con el cuidado y manejo de Moldes y
              Herramientas.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Caracteristicas de Moldes y Herramientas.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Tener un conocimiento sobre Técnicas de Trabajo y Características
              de Moldes, así como implementar nuevos sistemas administrativos
              que mejoren las condiciones del mantenimiento de las Herramientas
              y Componentes de Moldes.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Mantenimiento de Moldes es fundamental para
              garantizar la eficiencia y durabilidad de todos sus componentes
              esenciales para su correcto funcionamiento.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante esta capacitación, los participantes aprenderán las
              técnicas y procedimientos necesarios para realizar un
              mantenimiento adecuado, como limpieza, lubricación y reparación de
              los moldes. Además, se les enseñará a identificar y solucionar los
              problemas más comunes que pueden surgir durante el uso de los
              moldes.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Al adquirir estos conocimientos, los participantes podrán prevenir
              daños y desgaste prematuro, lo que permitirá optimizar la calidad
              de los productos fabricados y minimizar los tiempos de parada de
              la producción.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                DISEÑO DE MOLDES
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Operadores, Técnicos,
              Supervisores, Ingenieros de Diseño y todo el personal que pueda
              estar involucrado en el proceso de diseño y lanzamiento de nuevos
              Productos Moldeados y Sobre Moldeados.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Bases Matemáticas para el cálculo de operaciones.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Tener un conocimiento y entendimiento de los principios básicos
              para el Diseño de Moldes de Inyección de Plásticos, conocer los
              requisitos y limitantes de un molde para poder desarrollar un
              Proceso de Moldeo eficiente.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Diseño de Moldes está enfocado para aquellos que
              deseen incursionar en la industria de la manufactura de productos.
              Esta capacitación ofrece a los participantes los conocimientos y
              habilidades necesarios para Diseñar Moldes de manera eficiente y
              efectiva.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante el curso, los participantes aprenderán sobre los
              diferentes tipos de moldes, las técnicas de diseño y fabricación,
              así como las consideraciones necesarias para la creación de moldes
              de calidad. Se proporcionará información sobre los materiales
              utilizados en la fabricación de moldes y cómo seleccionar el más
              adecuado para cada aplicación.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Al finalizar la capacitación, los participantes estarán preparados
              para enfrentar los desafíos y demandas del diseño de moldes en la
              industria, lo que les brindará una ventaja competitiva en el
              mercado laboral. ¡No pierdas la oportunidad de adquirir estas
              habilidades y destacarte en el campo del diseño de moldes!
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                BUENAS PRACTICAS DE MOLDEO
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Operadores, Técnicos,
              Supervisores y cualquier persona interesada en el desarrollo y
              optimización de los recursos involucrados en los Procesos de
              Fabricación mediante Moldeo por Inyección.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y actividades relacionadas con la producción y
              estaciones de trabajo.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Conocer y aplicar los conocimientos para proponer mejoras en los
              métodos de trabajo ser más eficientes mediante Técnicas y
              Conceptos de Buenas Prácticas.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Buenas Prácticas de Moldeo es muy importante para
              garantizar la calidad y eficiencia de los Procesos de Moldeo, los
              subprocesos y el cuidado de los recursos involucrados en la
              fabricación, embalaje y transporte.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante esta capacitación, los participantes aprenderán técnicas y
              procedimientos óptimos para el moldeo de piezas, desde la
              preparación del material, la operación de la maquinaria hasta la
              liberación de las piezas terminadas .
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Se hará énfasis en la importancia de seguir las normas de
              seguridad y de mantenimiento de los equipos. Así como la
              importancia del correcto llenado de la documentación y el
              seguimiento disciplinado de los procedimientos.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                INYECCIÓN DE SILICÓN LÍQUIDO (LSR)
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Técnicos, Supervisores,
              Ingenieros de Producto, Calidad y de Proyectos, y cualquier
              integrante del equipo relacionado con el funcionamiento y arranque
              del Proceso de Moldeo de LSR.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Bases Matemáticas para el cálculo de operaciones.{" "}
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Adquirir conocimiento para el manejo y ajuste de proceso de
              inyección de LSR, así como aplicar técnicas que ayuden a mejorar
              el aprovechamiento de los recursos.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación en inyección de LSR está dirigido para aquellos
              que deseen adquirir habilidades en la manipulación de este
              material y en el funcionamiento de las máquinas de inyección.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante el programa de capacitación, los participantes aprenderán
              sobre las propiedades del LSR y cómo afectan el proceso de
              inyección. También se les enseñará cómo preparar el material,
              configurar la máquina y realizar ajustes adecuados para obtener
              resultados óptimos.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Además, se abordarán temas como la prevención de defectos y
              solución de problemas, así como las mejores prácticas de
              mantenimiento de las máquinas. Al finalizar la capacitación, los
              participantes estarán equipados con los conocimientos necesarios
              para realizar inyecciones de LSR de manera eficiente y segura.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                TIPOS DE ADITIVOS Y USO
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Operadores, Técnicos,
              Supervisores y cualquier integrante del equipo de trabajo
              relacionado con la administración y uso de Aditivos y sus efectos
              en los Procesos de Moldeo por Inyección.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección.{" "}
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Tener un conocimiento sobre los diferentes tipos de aditivos que
              se utilizan para obtener mejores propiedades y optimizar el
              procesamiento de los polímeros, por medio del conocimiento de
              sustancias químicas, su funcionamiento y concentración.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Aditivos y Uso en Procesos de Manufactura Plástica
              es fundamental para garantizar la calidad y eficiencia en la
              producción.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Los aditivos son sustancias que se añaden a los materiales
              plásticos para mejorar sus propiedades físicas, químicas o
              mecánicas.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Mediante esta capacitación, los profesionales del sector podrán
              adquirir los conocimientos necesarios para seleccionar y utilizar
              los aditivos adecuados en cada etapa del proceso de fabricación.
              Aprenderán a evaluar y controlar los efectos que estos aditivos
              tienen sobre las propiedades del producto final.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta formación permitirá optimizar los recursos utilizados,
              reducir los costos de producción y mejorar la competitividad de la
              industria plástica.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                LIBERACIÓN DE MOLDE Y PROCESO
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Ingenieros y Técnicos de
              Moldeo, Técnicos de Taller y el equipo de trabajo involucrado en
              la liberación de Moldes y Procesos de Moldeo por Inyección.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Es necesario conocer los Fundamentos Básicos del Moldeo por
              Inyección y Bases Matemáticas para el cálculo de operaciones.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Adquirir conocimientos que ayuden a efectuar validaciones
              efectivas de herramientas y procesos, así como establecer
              procedimientos que aseguren y evidencien el correcto seguimiento
              de los lineamientos establecidos.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Liberación de Moldes y Procesos es fundamental
              para garantizar la eficiencia y calidad en la producción desde el
              lanzamiento hasta la pieza final.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              A través de esta capacitación, los participantes aprenden técnicas
              y procedimientos para liberar moldes de manera segura y eficaz,
              evitando daños al producto final y optimizando los tiempos de
              producción. Aprenderán métodos para mejorar los procesos de
              fabricación, identificar posibles problemas y tomar medidas
              correctivas.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación también se enfoca en el manejo adecuado de
              materiales y herramientas, así como en el cumplimiento de las
              normas de seguridad e higiene.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                MOLDEO POR COMPRESIÓN
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Operadores, Técnicos,
              Supervisores y el equipo de trabajo involucrado en la liberación
              de Procesos de Moldeo por Compresión.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Adquirir conocimiento teórico y aplicaciones técnicas para el
              manejo y ajuste de los Procesos de Moldeo por Compresión de los
              principales tipos de plásticos.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Moldeo por Compresión es fundamental para aquellos
              que deseen adquirir conocimientos y habilidades en esta técnica de
              fabricación.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              El moldeo por compresión se utiliza ampliamente en la industria
              para producir piezas de plástico, caucho y otros materiales.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante la capacitación, los participantes aprenderán los
              fundamentos del proceso de moldeo por compresión, incluyendo el
              diseño de moldes, la selección de materiales y los parámetros de
              operación. También se les enseñarán técnicas avanzadas para
              garantizar la calidad y la eficiencia en la producción. Al
              finalizar la capacitación, estarán preparados para aplicar sus
              conocimientos en la industria y contribuir al éxito de la empresa.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                MOLDEO POR EXTRUSIÓN SOPLADO
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Operadores, Técnicos,
              Supervisores y el equipo de trabajo involucrado en la liberación
              de Procesos de Moldeo por Extrusión Soplado.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Tener un conocimiento teórico y de aplicaciones técnicas útiles
              para el desarrollo y optimización de los Procesos de Moldeo por
              Extrusión Soplado.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Moldeo por Extrusión Soplado está enfocada al
              desarrollo y optimización de productos específicos que pueden ser
              fabricados mediante este proceso.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              En este proceso, se utiliza una máquina especializada para fundir
              y moldear plástico en forma de tubos o botellas huecas.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante la capacitación, los participantes aprenderán los
              principios básicos del Moldeo por Extrusión Soplado, incluyendo el
              funcionamiento de la máquina, los diferentes tipos de plásticos
              utilizados y las técnicas de enfriamiento y acabado. También se
              les enseñarán las medidas de seguridad necesarias para este tipo
              de trabajo.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación es ideal para aquellos que deseen adquirir
              habilidades en la fabricación de productos plásticos y para
              aquellos que busquen oportunidades laborales en la industria del
              plástico.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                MOLDEO POR SOPLADO BIORIENTADO
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Esta capacitación está dirigida para Operadores, Técnicos,
              Supervisores y el equipo de trabajo involucrado en la liberación
              de Procesos de Moldeo por Soplado Biorientado.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Tener un conocimiento asertivo para desarrollar un arranque de
              máquina correcto y ajustes efectivos para los Proceso de Moldeo
              por Soplado Biorientado de los principales tipos de plásticos.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              La capacitación Soplado Biorientado es una oportunidad de
              aprendizaje fundamental para aquellos interesados en la industria
              del plástico.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Este proceso se utiliza para fabricar envases de plástico
              resistentes y de alta calidad.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Durante la capacitación, los participantes aprenderán sobre las
              diferentes técnicas y herramientas utilizadas en el soplado
              biorientado, así como las mejores prácticas en términos de
              seguridad y calidad. También aprenderán el cómo utilizar
              correctamente las máquinas y equipos necesarios para llevar a cabo
              este proceso. Al finalizar la capacitación, los participantes
              podrán aplicar sus conocimientos en el campo laboral y contribuir
              al desarrollo de la industria del plástico.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                PROGRAMAS DE FORMACIÓN
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Los Programas de Formación están dirigidos a todo el personal
              involucrado con cualquier actividad relacionada al Proceso de
              Moldeo en cualquiera de sus vertientes, estos son diseñados y
              personalizados para las necesidades de cada empresa. Pueden
              asistir desde Operarios, Almacenistas, Técnicos, Ingenieros,
              Supervisores hasta equipo de Staff Gerencial.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Formar equipos de trabajo multidisciplinarios enfocados al éxito
              de resultados en el desarrollo de actividades relacionadas al
              Proceso de Moldeo. Involucrar a todos los responsables directos e
              indirectos en el conocimiento del Proceso de Moldeo.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Dar continuidad y seguimiento a las mejoras obtenidas, evaluar
              resultados y buscar nuevas áreas de oportunidad, así como incluir
              a nuevos integrantes, cada que sea necesario.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Los Programas de Formación han sido desarrollados en base a la
              demanda de la Industria de moldeo, por el creciente numero de
              empleados y el nivel requerido de conocimientos.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              En Técnicas Plásticas nos preocupamos por que todos los
              colaboradores de la empresa estén relacionado con el alcance y
              limitaciones de los Procesos de Moldeo. Es así como creamos en
              conjunto con diferentes compañías una Capacitación Continua y
              ascendente de Todos los Niveles de la Empresa. De tal manera que
              absorba los movimientos de puesto, la rotación de personal y las
              promociones en el equipo de trabajo.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Procuramos siempre dar continuidad y seguimiento al desarrollo de
              cada empresa y proyectos específicos, enfocados siempre en la
              Mejora Continua, Eficiencia de los Procesos, así como
              incrementando la Disciplina y el Sentido de Pertenencia de todos
              los participantes.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
        <AccordionItem color={"black"} border="none">
          <AccordionButton _focus="none" _hover="none" p="40px 0px 20px 0px">
            <Box flex="1" textAlign="left">
              <Text color="#000" fontWeight="bold" fontSize="lg">
                ENTRENAMIENTOS ONLINE
              </Text>
            </Box>
            <AccordionIcon color="#000" />
          </AccordionButton>
          <AccordionPanel p="18px 0px 40px 0px">
            <Text my={5}>PERFIL DEL PARTICIPANTE</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Los Programas de Formación están dirigidos a todo el personal
              involucrado con cualquier actividad relacionada al Proceso de
              Moldeo en cualquiera de sus vertientes, estos son diseñados y
              personalizados para las necesidades de cada empresa. Pueden
              asistir desde Operarios, Almacenistas, Técnicos, Ingenieros,
              Supervisores hasta equipo de Staff Gerencial.
            </Text>
            <Text my={5}>OBJETIVO GENERAL</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Formar equipos de trabajo multidisciplinarios enfocados al éxito
              de resultados en el desarrollo de actividades relacionadas al
              Proceso de Moldeo. Involucrar a todos los responsables directos e
              indirectos en el conocimiento del Proceso de Moldeo.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Dar continuidad y seguimiento a las mejoras obtenidas, evaluar
              resultados y buscar nuevas áreas de oportunidad, así como incluir
              a nuevos integrantes, cada que sea necesario.
            </Text>
            <Text my={5}>DESCRIPCIÓN</Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Los Programas de Formación han sido desarrollados en base a la
              demanda de la Industria de moldeo, por el creciente numero de
              empleados y el nivel requerido de conocimientos.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              En Técnicas Plásticas nos preocupamos por que todos los
              colaboradores de la empresa estén relacionado con el alcance y
              limitaciones de los Procesos de Moldeo. Es así como creamos en
              conjunto con diferentes compañías una Capacitación Continua y
              ascendente de Todos los Niveles de la Empresa. De tal manera que
              absorba los movimientos de puesto, la rotación de personal y las
              promociones en el equipo de trabajo.
            </Text>
            <Text
              color={textColor}
              fontSize="md"
              textAlign="left"
              alignSelf="flex-start"
              justifySelf="flex-start"
            >
              Procuramos siempre dar continuidad y seguimiento al desarrollo de
              cada empresa y proyectos específicos, enfocados siempre en la
              Mejora Continua, Eficiencia de los Procesos, así como
              incrementando la Disciplina y el Sentido de Pertenencia de todos
              los participantes.
            </Text>
            <Button colorScheme="blue" size="md" my={5}>
              Click para ver el temario
            </Button>
          </AccordionPanel>
        </AccordionItem>
        <HSeparator my="12px" />
      </Accordion>
    </>
  );
};

export default Temario;
