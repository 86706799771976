import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import ProjectContext from "../../../../../../contexts/ProjectContext";
import { useGetProjectByIdHooks } from "../../../../hooks/useGetProjectByIdHooks";
import {
  areAllFloats,
  isObjectEmpty,
} from "../../../../../../utilities/functions";
import EditBase from "../../Cards/EditBase";
import { Button, Grid, GridItem } from "@chakra-ui/react";
import DataDisplayBadge from "../../../../../../components/DataDisplay/DatadisplayBadge";
import InputCustomFieldProjectRigthAddon from "../../../../../../components/inputs/InputCustomFieldProjectRigthAddon";
import { useMelt3030Hooks } from "../../../../../../hooks/melt3030/useMelt3030Hooks";
import MeltChart from "./components/MeltChart";
import { badgeColor, borderColor, textColor } from "../../utilities/variables";
import HelmetComponent from "../../../../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
import DescriptionAndInstructionAcordeon from "../../../../../../components/acordeon/DescriptionAndInstructionAcordeon";
import description from "./utilities/description";
import instructions from "./utilities/instructions";
import { lbKg, temp } from "../../../../individual/hooks/useUomTransform";

const EditMelt3030 = () => {
  const { id } = useParams();
  const {
    project,
    updateProject,
    isLoading,
    resin,
    onFinish,
    handleInputChange,
  } = useContext(ProjectContext);
  const { getProject } = useGetProjectByIdHooks();

  useEffect(() => {
    if (isObjectEmpty(project)) {
      getProject(id);
    }
  }, [project]);
  useEffect(() => {
    return () => {
      updateProject([]);
    };
  }, []);

  const {
    realMeltTemp,
    realNozzleTemp,
    realFrontTemp,
    realMidTemp,
    realBackTemp,
  } = project;

  const {
    meltTempMin,
    nozzleTempMin,
    FrontTempMin,
    midTempMin,
    backTempMin,
    meltTempMax,
    nozzleTempMax,
    FrontTempMax,
    midTempMax,
    backTempMax,
  } = resin;

  const {
    updateChart,
    showChart,
    setShowChart,
    lineChartDataMelt,
  } = useMelt3030Hooks();

  return (
    <>
      <HelmetComponent
        title={metaTitle}
        keywords={metaKeywords}
        description={metaDescription}
      />
      <EditBase
        isLoading={isLoading}
        title={"Melt 30 / 30"}
        onFinish={onFinish}
        tittleButton={"Terminar"}
        disabled={!showChart}
      >
        <GridItem colSpan={4} width={"100%"}>
          <DescriptionAndInstructionAcordeon
            description={description}
            instructions={instructions}
          />
        </GridItem>
        <GridItem colSpan={2} width={"100%"}>
          <Grid
            templateColumns="repeat(1, 4fr)"
            gap={6}
            templateRows="repeat(5, 4fr)"
            mx={5}
            borderWidth={1}
            borderRadius={15}
            p={5}
            borderColor={borderColor}
          >
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              addOn={temp(project?.uom)}
              disabled={showChart}
              id={"realMeltTemp"}
              value={project.realMeltTemp}
              onChange={handleInputChange}
              label={"Temperatura de Masa Real"}
            />
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              addOn={temp(project?.uom)}
              disabled={showChart}
              id={"realNozzleTemp"}
              value={project.realNozzleTemp}
              onChange={handleInputChange}
              label={"Temperatura de Nariz Real"}
            />
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              addOn={temp(project?.uom)}
              disabled={showChart}
              id={"realFrontTemp"}
              value={project.realFrontTemp}
              onChange={handleInputChange}
              label={"Temperatura Frontal Real"}
            />
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              addOn={temp(project?.uom)}
              disabled={showChart}
              id={"realMidTemp"}
              value={project.realMidTemp}
              onChange={handleInputChange}
              label={"Temperatura Central Real"}
            />
            <InputCustomFieldProjectRigthAddon
              color={textColor}
              borderColor={borderColor}
              addOn={temp(project?.uom)}
              disabled={showChart}
              id={"realBackTemp"}
              value={project.realBackTemp}
              onChange={handleInputChange}
              label={"Temperatura Trasera Real"}
            />
          </Grid>
        </GridItem>
        <GridItem colSpan={2} width={"100%"} height={"100%"}>
          <Grid
            height={"100%"}
            templateColumns="repeat(1, 4fr)"
            gap={6}
            borderWidth={1}
            p={5}
            borderRadius={15}
            borderColor={borderColor}
          >
            <Grid templateColumns="repeat(2, 4fr)" gap={6}>
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura de Masa Minima"}
                value={meltTempMin}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura de Masa Máxima"}
                value={meltTempMax}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura de Nariz Minima"}
                value={nozzleTempMin}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura de Nariz Máxima"}
                value={nozzleTempMax}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura Frontal Mínima"}
                value={FrontTempMin}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                fix={2}
                size={"xs"}
                title={"Temperatura Frontal Máxima"}
                value={FrontTempMax}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura Central Mínima"}
                value={midTempMin}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura Central Máxima"}
                value={midTempMax}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura Trasera Mínima"}
                value={backTempMin}
              />
              <DataDisplayBadge
                color={textColor}
                badgeColorScheme={badgeColor}
                type={"temp"}
                rightAddon={temp(project?.uom)}
                size={"xs"}
                fix={2}
                title={"Temperatura Trasera Máxima"}
                value={backTempMax}
              />
            </Grid>
          </Grid>
        </GridItem>
        <GridItem colSpan={4}>
          <Button
            colorScheme={"facebook"}
            onClick={
              showChart ? () => setShowChart(false) : () => updateChart()
            }
            disabled={
              !areAllFloats([
                realMeltTemp,
                realNozzleTemp,
                realFrontTemp,
                realMidTemp,
                realBackTemp,
              ]) || isLoading
            }
          >
            {showChart ? "Editar" : "Graficar y Guardar"}
          </Button>
        </GridItem>
        {showChart && (
          <GridItem
            width={"100%"}
            colSpan={4}
            borderWidth={1}
            borderRadius={15}
            p={5}
            mx={5}
            gap={6}
            mt={5}
            borderColor={borderColor}
            templateColumns="repeat(1, 2fr)"
          >
            <MeltChart lineChartDataMelt={lineChartDataMelt} />
          </GridItem>
        )}
      </EditBase>
    </>
  );
};

export default EditMelt3030;
