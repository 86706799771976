import { Grid } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

// Mueve GradientGrid fuera de CustomGrid para evitar la recreación en cada render
const GradientGrid = styled(Grid)`
  background: linear-gradient(to right, #bfcbd2, #e8eaed, #bfcbd2);
  color: #333; // Ajusta el color del texto si es necesario
  // Añade aquí más propiedades de estilo si lo necesitas
`;

const CustomGrid = ({
  children,
  justifyItems = "center",
  gap = 6,
  repeat = 1,
  justifyContent = "center",
  mx = 5,
  px = 0,
  mt = 5,
  maxWidth = "",
  overflowX = "",
  p=5,
  ...props
}) => {
  return (
    <GradientGrid
      {...props}
      maxWidth={maxWidth}
      overflowX={overflowX}
      px={px}
      p={p}
      mx={mx}
      mt={mt}
      borderRadius="15px"
      justifyItems={justifyItems}
      justifyContent={justifyContent}
      templateColumns={`repeat(${repeat}, 1fr)`}
      gap={gap}
    >
      {children}
    </GradientGrid>
  );
};

export default CustomGrid;
