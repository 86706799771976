import { isObjectEmpty } from "../../../../utilities/functions";
import GoBackButton from "./Buttons/GoBackButton";
import {Button, Grid} from "@chakra-ui/react";
import LinkToPdfButton from "./Buttons/LinkToPdfButton";
import CustomGrid from "./CustomGrid";

const IndividualFooter = ({
  result,
  isDisabled,
  isLoading,
  onSubmitHandler,
  url,
}) => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={10} mt={5}>
      {!isObjectEmpty(result) ? (
        <>
          <GoBackButton />
          <LinkToPdfButton result={result} url={url} />
        </>
      ) : (
        <>
          <GoBackButton />
          <Button
            disabled={isDisabled}
            colorScheme={"facebook"}
            isLoading={isLoading}
            onClick={onSubmitHandler}
          >
            Calcular y Guardar
          </Button>
        </>
      )}
    </Grid>
  );
};

export default IndividualFooter;
