import {Button, Link} from "@chakra-ui/react";

const LinkToPdfButton = ({result, url}) => {

  return (
    <Link
      isExternal
      href={`/banner/projects/pdf/${url}/${result?.id}`}
    >
      <Button colorScheme={"facebook"}>Generar PDF</Button>
    </Link>
  )
}

export default LinkToPdfButton